/* tslint:disable */
/* eslint-disable */
/**
 * MIT Learn API
 * MIT public API
 *
 * The version of the OpenAPI document: 0.0.1 (v1)
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type { Configuration } from "./configuration"
import type { AxiosPromise, AxiosInstance, RawAxiosRequestConfig } from "axios"
import globalAxios from "axios"
// Some imports not used depending on template conditions
// @ts-ignore
import {
  DUMMY_BASE_URL,
  assertParamExists,
  setApiKeyToObject,
  setBasicAuthToObject,
  setBearerAuthToObject,
  setOAuthToObject,
  setSearchParams,
  serializeDataIfNeeded,
  toPathString,
  createRequestFunction,
} from "./common"
import type { RequestArgs } from "./base"
// @ts-ignore
import {
  BASE_PATH,
  COLLECTION_FORMATS,
  BaseAPI,
  RequiredError,
  operationServerMap,
} from "./base"

/**
 * * `resource_type` - resource_type * `certification` - certification * `certification_type` - certification_type * `offered_by` - offered_by * `platform` - platform * `topic` - topic * `department` - department * `level` - level * `course_feature` - course_feature * `professional` - professional * `free` - free * `delivery` - delivery * `resource_category` - resource_category
 * @export
 * @enum {string}
 */

export const AggregationsEnumDescriptions = {
  resource_type: "resource_type",
  certification: "certification",
  certification_type: "certification_type",
  offered_by: "offered_by",
  platform: "platform",
  topic: "topic",
  department: "department",
  level: "level",
  course_feature: "course_feature",
  professional: "professional",
  free: "free",
  delivery: "delivery",
  resource_category: "resource_category",
} as const

export const AggregationsEnum = {
  /**
   * resource_type
   */
  ResourceType: "resource_type",
  /**
   * certification
   */
  Certification: "certification",
  /**
   * certification_type
   */
  CertificationType: "certification_type",
  /**
   * offered_by
   */
  OfferedBy: "offered_by",
  /**
   * platform
   */
  Platform: "platform",
  /**
   * topic
   */
  Topic: "topic",
  /**
   * department
   */
  Department: "department",
  /**
   * level
   */
  Level: "level",
  /**
   * course_feature
   */
  CourseFeature: "course_feature",
  /**
   * professional
   */
  Professional: "professional",
  /**
   * free
   */
  Free: "free",
  /**
   * delivery
   */
  Delivery: "delivery",
  /**
   * resource_category
   */
  ResourceCategory: "resource_category",
} as const

export type AggregationsEnum =
  (typeof AggregationsEnum)[keyof typeof AggregationsEnum]

/**
 * Serializer for LearningResourceInstructor model
 * @export
 * @interface Article
 */
export interface Article {
  /**
   *
   * @type {string}
   * @memberof Article
   */
  html: string
  /**
   *
   * @type {number}
   * @memberof Article
   */
  id: number
  /**
   *
   * @type {string}
   * @memberof Article
   */
  title: string
}
/**
 * Serializer for LearningResourceInstructor model
 * @export
 * @interface ArticleRequest
 */
export interface ArticleRequest {
  /**
   *
   * @type {string}
   * @memberof ArticleRequest
   */
  html: string
  /**
   *
   * @type {string}
   * @memberof ArticleRequest
   */
  title: string
}
/**
 * * `dated` - Dated * `anytime` - Anytime
 * @export
 * @enum {string}
 */

export const AvailabilityEnumDescriptions = {
  dated: "Dated",
  anytime: "Anytime",
} as const

export const AvailabilityEnum = {
  /**
   * Dated
   */
  Dated: "dated",
  /**
   * Anytime
   */
  Anytime: "anytime",
} as const

export type AvailabilityEnum =
  (typeof AvailabilityEnum)[keyof typeof AvailabilityEnum]

/**
 * * `micromasters` - MicroMasters Credential * `professional` - Professional Certificate * `completion` - Certificate of Completion * `none` - No Certificate
 * @export
 * @enum {string}
 */

export const CertificationTypeEnumDescriptions = {
  micromasters: "MicroMasters Credential",
  professional: "Professional Certificate",
  completion: "Certificate of Completion",
  none: "No Certificate",
} as const

export const CertificationTypeEnum = {
  /**
   * MicroMasters Credential
   */
  Micromasters: "micromasters",
  /**
   * Professional Certificate
   */
  Professional: "professional",
  /**
   * Certificate of Completion
   */
  Completion: "completion",
  /**
   * No Certificate
   */
  None: "none",
} as const

export type CertificationTypeEnum =
  (typeof CertificationTypeEnum)[keyof typeof CertificationTypeEnum]

/**
 * Serializer class for course run ContentFiles
 * @export
 * @interface ContentFile
 */
export interface ContentFile {
  /**
   *
   * @type {number}
   * @memberof ContentFile
   */
  id: number
  /**
   *
   * @type {number}
   * @memberof ContentFile
   */
  run_id: number
  /**
   *
   * @type {string}
   * @memberof ContentFile
   */
  run_title: string
  /**
   *
   * @type {string}
   * @memberof ContentFile
   */
  run_slug: string
  /**
   *
   * @type {Array<LearningResourceDepartment>}
   * @memberof ContentFile
   */
  departments: Array<LearningResourceDepartment>
  /**
   *
   * @type {string}
   * @memberof ContentFile
   */
  semester: string
  /**
   *
   * @type {number}
   * @memberof ContentFile
   */
  year: number
  /**
   *
   * @type {Array<LearningResourceTopic>}
   * @memberof ContentFile
   */
  topics: Array<LearningResourceTopic>
  /**
   *
   * @type {string}
   * @memberof ContentFile
   */
  key?: string | null
  /**
   *
   * @type {string}
   * @memberof ContentFile
   */
  uid?: string | null
  /**
   *
   * @type {string}
   * @memberof ContentFile
   */
  title?: string | null
  /**
   *
   * @type {string}
   * @memberof ContentFile
   */
  description?: string | null
  /**
   *
   * @type {string}
   * @memberof ContentFile
   */
  url?: string | null
  /**
   *
   * @type {Array<string>}
   * @memberof ContentFile
   */
  content_feature_type: Array<string>
  /**
   *
   * @type {ContentTypeEnum}
   * @memberof ContentFile
   */
  content_type?: ContentTypeEnum
  /**
   *
   * @type {string}
   * @memberof ContentFile
   */
  content?: string | null
  /**
   *
   * @type {string}
   * @memberof ContentFile
   */
  content_title?: string | null
  /**
   *
   * @type {string}
   * @memberof ContentFile
   */
  content_author?: string | null
  /**
   *
   * @type {string}
   * @memberof ContentFile
   */
  content_language?: string | null
  /**
   *
   * @type {string}
   * @memberof ContentFile
   */
  image_src?: string | null
  /**
   *
   * @type {string}
   * @memberof ContentFile
   */
  resource_id: string
  /**
   *
   * @type {string}
   * @memberof ContentFile
   */
  resource_readable_id: string
  /**
   * Extract the course number(s) from the associated course
   * @type {Array<string>}
   * @memberof ContentFile
   */
  course_number: Array<string>
  /**
   *
   * @type {string}
   * @memberof ContentFile
   */
  file_type?: string | null
  /**
   *
   * @type {string}
   * @memberof ContentFile
   */
  file_extension?: string | null
  /**
   *
   * @type {LearningResourceOfferor}
   * @memberof ContentFile
   */
  offered_by: LearningResourceOfferor
  /**
   *
   * @type {LearningResourcePlatform}
   * @memberof ContentFile
   */
  platform: LearningResourcePlatform
  /**
   *
   * @type {string}
   * @memberof ContentFile
   */
  run_readable_id: string
}

/**
 * SearchResponseSerializer with OpenAPI annotations for Content Files search
 * @export
 * @interface ContentFileSearchResponse
 */
export interface ContentFileSearchResponse {
  /**
   *
   * @type {number}
   * @memberof ContentFileSearchResponse
   */
  count: number
  /**
   *
   * @type {string}
   * @memberof ContentFileSearchResponse
   */
  next: string | null
  /**
   *
   * @type {string}
   * @memberof ContentFileSearchResponse
   */
  previous: string | null
  /**
   *
   * @type {Array<ContentFile>}
   * @memberof ContentFileSearchResponse
   */
  results: Array<ContentFile>
  /**
   *
   * @type {ContentFileSearchResponseMetadata}
   * @memberof ContentFileSearchResponse
   */
  metadata: ContentFileSearchResponseMetadata
}
/**
 *
 * @export
 * @interface ContentFileSearchResponseMetadata
 */
export interface ContentFileSearchResponseMetadata {
  /**
   *
   * @type {{ [key: string]: Array<ContentFileSearchResponseMetadataAggregationsValueInner>; }}
   * @memberof ContentFileSearchResponseMetadata
   */
  aggregations: {
    [
      key: string
    ]: Array<ContentFileSearchResponseMetadataAggregationsValueInner>
  }
  /**
   *
   * @type {Array<string>}
   * @memberof ContentFileSearchResponseMetadata
   */
  suggestions: Array<string>
}
/**
 *
 * @export
 * @interface ContentFileSearchResponseMetadataAggregationsValueInner
 */
export interface ContentFileSearchResponseMetadataAggregationsValueInner {
  /**
   *
   * @type {string}
   * @memberof ContentFileSearchResponseMetadataAggregationsValueInner
   */
  key: string
  /**
   *
   * @type {number}
   * @memberof ContentFileSearchResponseMetadataAggregationsValueInner
   */
  doc_count: number
}
/**
 * * `page` - page * `file` - file * `video` - video * `pdf` - pdf
 * @export
 * @enum {string}
 */

export const ContentTypeEnumDescriptions = {
  page: "page",
  file: "file",
  video: "video",
  pdf: "pdf",
} as const

export const ContentTypeEnum = {
  /**
   * page
   */
  Page: "page",
  /**
   * file
   */
  File: "file",
  /**
   * video
   */
  Video: "video",
  /**
   * pdf
   */
  Pdf: "pdf",
} as const

export type ContentTypeEnum =
  (typeof ContentTypeEnum)[keyof typeof ContentTypeEnum]

/**
 * Serializer for the Course model
 * @export
 * @interface Course
 */
export interface Course {
  /**
   *
   * @type {Array<CourseNumber>}
   * @memberof Course
   */
  course_numbers: Array<CourseNumber> | null
}
/**
 * Serializer for CourseNumber
 * @export
 * @interface CourseNumber
 */
export interface CourseNumber {
  /**
   *
   * @type {string}
   * @memberof CourseNumber
   */
  value: string
  /**
   *
   * @type {LearningResourceDepartment}
   * @memberof CourseNumber
   */
  department: LearningResourceDepartment
  /**
   *
   * @type {string}
   * @memberof CourseNumber
   */
  listing_type: string
}
/**
 * Serializer for CourseNumber
 * @export
 * @interface CourseNumberRequest
 */
export interface CourseNumberRequest {
  /**
   *
   * @type {string}
   * @memberof CourseNumberRequest
   */
  value: string
  /**
   *
   * @type {LearningResourceDepartmentRequest}
   * @memberof CourseNumberRequest
   */
  department: LearningResourceDepartmentRequest
  /**
   *
   * @type {string}
   * @memberof CourseNumberRequest
   */
  listing_type: string
}
/**
 * Serializer for the Course model
 * @export
 * @interface CourseRequest
 */
export interface CourseRequest {
  /**
   *
   * @type {Array<CourseNumberRequest>}
   * @memberof CourseRequest
   */
  course_numbers: Array<CourseNumberRequest> | null
}
/**
 * Serializer for course resources
 * @export
 * @interface CourseResource
 */
export interface CourseResource {
  /**
   *
   * @type {number}
   * @memberof CourseResource
   */
  id: number
  /**
   *
   * @type {Array<LearningResourceTopic>}
   * @memberof CourseResource
   */
  topics?: Array<LearningResourceTopic>
  /**
   *
   * @type {number}
   * @memberof CourseResource
   */
  position: number | null
  /**
   *
   * @type {LearningResourceOfferor}
   * @memberof CourseResource
   */
  offered_by: LearningResourceOfferor | null
  /**
   *
   * @type {LearningResourcePlatform}
   * @memberof CourseResource
   */
  platform: LearningResourcePlatform | null
  /**
   *
   * @type {Array<string>}
   * @memberof CourseResource
   */
  course_feature: Array<string> | null
  /**
   *
   * @type {Array<LearningResourceDepartment>}
   * @memberof CourseResource
   */
  departments: Array<LearningResourceDepartment> | null
  /**
   *
   * @type {boolean}
   * @memberof CourseResource
   */
  certification: boolean
  /**
   *
   * @type {CourseResourceCertificationType}
   * @memberof CourseResource
   */
  certification_type: CourseResourceCertificationType
  /**
   *
   * @type {Array<string>}
   * @memberof CourseResource
   */
  prices: Array<string>
  /**
   *
   * @type {Array<LearningResourcePrice>}
   * @memberof CourseResource
   */
  resource_prices: Array<LearningResourcePrice>
  /**
   *
   * @type {Array<LearningResourceRun>}
   * @memberof CourseResource
   */
  runs: Array<LearningResourceRun> | null
  /**
   *
   * @type {LearningResourceImage}
   * @memberof CourseResource
   */
  image: LearningResourceImage | null
  /**
   *
   * @type {Array<MicroLearningPathRelationship>}
   * @memberof CourseResource
   */
  learning_path_parents: Array<MicroLearningPathRelationship>
  /**
   *
   * @type {Array<MicroUserListRelationship>}
   * @memberof CourseResource
   */
  user_list_parents: Array<MicroUserListRelationship>
  /**
   *
   * @type {number}
   * @memberof CourseResource
   */
  views: number
  /**
   *
   * @type {Array<CourseResourceDeliveryInner>}
   * @memberof CourseResource
   */
  delivery: Array<CourseResourceDeliveryInner>
  /**
   * Return true if the resource is free/has a free option
   * @type {boolean}
   * @memberof CourseResource
   */
  free: boolean
  /**
   * Return the resource category of the resource
   * @type {string}
   * @memberof CourseResource
   */
  resource_category: string
  /**
   *
   * @type {Array<CourseResourceFormatInner>}
   * @memberof CourseResource
   */
  format: Array<CourseResourceFormatInner>
  /**
   *
   * @type {Array<CourseResourcePaceInner>}
   * @memberof CourseResource
   */
  pace: Array<CourseResourcePaceInner>
  /**
   *
   * @type {CourseResourceResourceTypeEnum}
   * @memberof CourseResource
   */
  resource_type: CourseResourceResourceTypeEnum
  /**
   *
   * @type {Course}
   * @memberof CourseResource
   */
  course: Course
  /**
   *
   * @type {string}
   * @memberof CourseResource
   */
  readable_id: string
  /**
   *
   * @type {string}
   * @memberof CourseResource
   */
  title: string
  /**
   *
   * @type {string}
   * @memberof CourseResource
   */
  description?: string | null
  /**
   *
   * @type {string}
   * @memberof CourseResource
   */
  full_description?: string | null
  /**
   *
   * @type {string}
   * @memberof CourseResource
   */
  last_modified?: string | null
  /**
   *
   * @type {boolean}
   * @memberof CourseResource
   */
  published?: boolean
  /**
   *
   * @type {Array<string>}
   * @memberof CourseResource
   */
  languages?: Array<string> | null
  /**
   *
   * @type {string}
   * @memberof CourseResource
   */
  url?: string | null
  /**
   *
   * @type {Array<string>}
   * @memberof CourseResource
   */
  ocw_topics?: Array<string>
  /**
   *
   * @type {boolean}
   * @memberof CourseResource
   */
  professional: boolean
  /**
   *
   * @type {string}
   * @memberof CourseResource
   */
  next_start_date?: string | null
  /**
   *
   * @type {AvailabilityEnum}
   * @memberof CourseResource
   */
  availability?: AvailabilityEnum | null
  /**
   *
   * @type {number}
   * @memberof CourseResource
   */
  completeness?: number
  /**
   *
   * @type {boolean}
   * @memberof CourseResource
   */
  license_cc?: boolean
  /**
   *
   * @type {string}
   * @memberof CourseResource
   */
  continuing_ed_credits?: string | null
  /**
   *
   * @type {string}
   * @memberof CourseResource
   */
  location?: string
  /**
   *
   * @type {string}
   * @memberof CourseResource
   */
  duration?: string
  /**
   *
   * @type {number}
   * @memberof CourseResource
   */
  min_weeks?: number | null
  /**
   *
   * @type {number}
   * @memberof CourseResource
   */
  max_weeks?: number | null
  /**
   *
   * @type {string}
   * @memberof CourseResource
   */
  time_commitment?: string
  /**
   *
   * @type {number}
   * @memberof CourseResource
   */
  min_weekly_hours?: number | null
  /**
   *
   * @type {number}
   * @memberof CourseResource
   */
  max_weekly_hours?: number | null
}

/**
 *
 * @export
 * @interface CourseResourceCertificationType
 */
export interface CourseResourceCertificationType {
  /**
   *
   * @type {string}
   * @memberof CourseResourceCertificationType
   */
  code: CourseResourceCertificationTypeCodeEnum
  /**
   *
   * @type {string}
   * @memberof CourseResourceCertificationType
   */
  name: string
}

export const CourseResourceCertificationTypeCodeEnum = {
  Micromasters: "micromasters",
  Professional: "professional",
  Completion: "completion",
  None: "none",
} as const

export type CourseResourceCertificationTypeCodeEnum =
  (typeof CourseResourceCertificationTypeCodeEnum)[keyof typeof CourseResourceCertificationTypeCodeEnum]

/**
 *
 * @export
 * @interface CourseResourceDeliveryInner
 */
export interface CourseResourceDeliveryInner {
  /**
   *
   * @type {string}
   * @memberof CourseResourceDeliveryInner
   */
  code: CourseResourceDeliveryInnerCodeEnum
  /**
   *
   * @type {string}
   * @memberof CourseResourceDeliveryInner
   */
  name: string
}

export const CourseResourceDeliveryInnerCodeEnum = {
  Online: "online",
  Hybrid: "hybrid",
  InPerson: "in_person",
  Offline: "offline",
} as const

export type CourseResourceDeliveryInnerCodeEnum =
  (typeof CourseResourceDeliveryInnerCodeEnum)[keyof typeof CourseResourceDeliveryInnerCodeEnum]

/**
 *
 * @export
 * @interface CourseResourceFormatInner
 */
export interface CourseResourceFormatInner {
  /**
   *
   * @type {string}
   * @memberof CourseResourceFormatInner
   */
  code: CourseResourceFormatInnerCodeEnum
  /**
   *
   * @type {string}
   * @memberof CourseResourceFormatInner
   */
  name: string
}

export const CourseResourceFormatInnerCodeEnum = {
  Synchronous: "synchronous",
  Asynchronous: "asynchronous",
} as const

export type CourseResourceFormatInnerCodeEnum =
  (typeof CourseResourceFormatInnerCodeEnum)[keyof typeof CourseResourceFormatInnerCodeEnum]

/**
 *
 * @export
 * @interface CourseResourcePaceInner
 */
export interface CourseResourcePaceInner {
  /**
   *
   * @type {string}
   * @memberof CourseResourcePaceInner
   */
  code: CourseResourcePaceInnerCodeEnum
  /**
   *
   * @type {string}
   * @memberof CourseResourcePaceInner
   */
  name: string
}

export const CourseResourcePaceInnerCodeEnum = {
  SelfPaced: "self_paced",
  InstructorPaced: "instructor_paced",
} as const

export type CourseResourcePaceInnerCodeEnum =
  (typeof CourseResourcePaceInnerCodeEnum)[keyof typeof CourseResourcePaceInnerCodeEnum]

/**
 * Serializer for course resources
 * @export
 * @interface CourseResourceRequest
 */
export interface CourseResourceRequest {
  /**
   *
   * @type {Array<LearningResourceTopic>}
   * @memberof CourseResourceRequest
   */
  topics?: Array<LearningResourceTopic>
  /**
   *
   * @type {string}
   * @memberof CourseResourceRequest
   */
  readable_id: string
  /**
   *
   * @type {string}
   * @memberof CourseResourceRequest
   */
  title: string
  /**
   *
   * @type {string}
   * @memberof CourseResourceRequest
   */
  description?: string | null
  /**
   *
   * @type {string}
   * @memberof CourseResourceRequest
   */
  full_description?: string | null
  /**
   *
   * @type {string}
   * @memberof CourseResourceRequest
   */
  last_modified?: string | null
  /**
   *
   * @type {boolean}
   * @memberof CourseResourceRequest
   */
  published?: boolean
  /**
   *
   * @type {Array<string>}
   * @memberof CourseResourceRequest
   */
  languages?: Array<string> | null
  /**
   *
   * @type {string}
   * @memberof CourseResourceRequest
   */
  url?: string | null
  /**
   *
   * @type {Array<string>}
   * @memberof CourseResourceRequest
   */
  ocw_topics?: Array<string>
  /**
   *
   * @type {string}
   * @memberof CourseResourceRequest
   */
  next_start_date?: string | null
  /**
   *
   * @type {AvailabilityEnum}
   * @memberof CourseResourceRequest
   */
  availability?: AvailabilityEnum | null
  /**
   *
   * @type {number}
   * @memberof CourseResourceRequest
   */
  completeness?: number
  /**
   *
   * @type {boolean}
   * @memberof CourseResourceRequest
   */
  license_cc?: boolean
  /**
   *
   * @type {string}
   * @memberof CourseResourceRequest
   */
  continuing_ed_credits?: string | null
  /**
   *
   * @type {string}
   * @memberof CourseResourceRequest
   */
  location?: string
  /**
   *
   * @type {string}
   * @memberof CourseResourceRequest
   */
  duration?: string
  /**
   *
   * @type {number}
   * @memberof CourseResourceRequest
   */
  min_weeks?: number | null
  /**
   *
   * @type {number}
   * @memberof CourseResourceRequest
   */
  max_weeks?: number | null
  /**
   *
   * @type {string}
   * @memberof CourseResourceRequest
   */
  time_commitment?: string
  /**
   *
   * @type {number}
   * @memberof CourseResourceRequest
   */
  min_weekly_hours?: number | null
  /**
   *
   * @type {number}
   * @memberof CourseResourceRequest
   */
  max_weekly_hours?: number | null
}

/**
 *
 * @export
 * @enum {string}
 */

export const CourseResourceResourceTypeEnumDescriptions = {
  course: "",
} as const

export const CourseResourceResourceTypeEnum = {
  Course: "course",
} as const

export type CourseResourceResourceTypeEnum =
  (typeof CourseResourceResourceTypeEnum)[keyof typeof CourseResourceResourceTypeEnum]

/**
 * * `online` - Online * `hybrid` - Hybrid * `in_person` - In person * `offline` - Offline
 * @export
 * @enum {string}
 */

export const DeliveryEnumDescriptions = {
  online: "Online",
  hybrid: "Hybrid",
  in_person: "In person",
  offline: "Offline",
} as const

export const DeliveryEnum = {
  /**
   * Online
   */
  Online: "online",
  /**
   * Hybrid
   */
  Hybrid: "hybrid",
  /**
   * In person
   */
  InPerson: "in_person",
  /**
   * Offline
   */
  Offline: "offline",
} as const

export type DeliveryEnum = (typeof DeliveryEnum)[keyof typeof DeliveryEnum]

/**
 * * `1` - Civil and Environmental Engineering * `2` - Mechanical Engineering * `3` - Materials Science and Engineering * `4` - Architecture * `5` - Chemistry * `6` - Electrical Engineering and Computer Science * `7` - Biology * `8` - Physics * `9` - Brain and Cognitive Sciences * `10` - Chemical Engineering * `11` - Urban Studies and Planning * `12` - Earth, Atmospheric, and Planetary Sciences * `14` - Economics * `15` - Management * `16` - Aeronautics and Astronautics * `17` - Political Science * `18` - Mathematics * `20` - Biological Engineering * `21A` - Anthropology * `21G` - Global Languages * `21H` - History * `21L` - Literature * `21M` - Music and Theater Arts * `22` - Nuclear Science and Engineering * `24` - Linguistics and Philosophy * `CC` - Concourse * `CMS-W` - Comparative Media Studies/Writing * `EC` - Edgerton Center * `ES` - Experimental Study Group * `ESD` - Engineering Systems Division * `HST` - Medical Engineering and Science * `IDS` - Data, Systems, and Society * `MAS` - Media Arts and Sciences * `PE` - Athletics, Physical Education and Recreation * `SP` - Special Programs * `STS` - Science, Technology, and Society * `WGS` - Women\'s and Gender Studies
 * @export
 * @enum {string}
 */

export const DepartmentEnumDescriptions = {
  "1": "Civil and Environmental Engineering",
  "2": "Mechanical Engineering",
  "3": "Materials Science and Engineering",
  "4": "Architecture",
  "5": "Chemistry",
  "6": "Electrical Engineering and Computer Science",
  "7": "Biology",
  "8": "Physics",
  "9": "Brain and Cognitive Sciences",
  "10": "Chemical Engineering",
  "11": "Urban Studies and Planning",
  "12": "Earth, Atmospheric, and Planetary Sciences",
  "14": "Economics",
  "15": "Management",
  "16": "Aeronautics and Astronautics",
  "17": "Political Science",
  "18": "Mathematics",
  "20": "Biological Engineering",
  "21A": "Anthropology",
  "21G": "Global Languages",
  "21H": "History",
  "21L": "Literature",
  "21M": "Music and Theater Arts",
  "22": "Nuclear Science and Engineering",
  "24": "Linguistics and Philosophy",
  CC: "Concourse",
  "CMS-W": "Comparative Media Studies/Writing",
  EC: "Edgerton Center",
  ES: "Experimental Study Group",
  ESD: "Engineering Systems Division",
  HST: "Medical Engineering and Science",
  IDS: "Data, Systems, and Society",
  MAS: "Media Arts and Sciences",
  PE: "Athletics, Physical Education and Recreation",
  SP: "Special Programs",
  STS: "Science, Technology, and Society",
  WGS: "Women's and Gender Studies",
} as const

export const DepartmentEnum = {
  /**
   * Civil and Environmental Engineering
   */
  _1: "1",
  /**
   * Mechanical Engineering
   */
  _2: "2",
  /**
   * Materials Science and Engineering
   */
  _3: "3",
  /**
   * Architecture
   */
  _4: "4",
  /**
   * Chemistry
   */
  _5: "5",
  /**
   * Electrical Engineering and Computer Science
   */
  _6: "6",
  /**
   * Biology
   */
  _7: "7",
  /**
   * Physics
   */
  _8: "8",
  /**
   * Brain and Cognitive Sciences
   */
  _9: "9",
  /**
   * Chemical Engineering
   */
  _10: "10",
  /**
   * Urban Studies and Planning
   */
  _11: "11",
  /**
   * Earth, Atmospheric, and Planetary Sciences
   */
  _12: "12",
  /**
   * Economics
   */
  _14: "14",
  /**
   * Management
   */
  _15: "15",
  /**
   * Aeronautics and Astronautics
   */
  _16: "16",
  /**
   * Political Science
   */
  _17: "17",
  /**
   * Mathematics
   */
  _18: "18",
  /**
   * Biological Engineering
   */
  _20: "20",
  /**
   * Anthropology
   */
  _21A: "21A",
  /**
   * Global Languages
   */
  _21G: "21G",
  /**
   * History
   */
  _21H: "21H",
  /**
   * Literature
   */
  _21L: "21L",
  /**
   * Music and Theater Arts
   */
  _21M: "21M",
  /**
   * Nuclear Science and Engineering
   */
  _22: "22",
  /**
   * Linguistics and Philosophy
   */
  _24: "24",
  /**
   * Concourse
   */
  Cc: "CC",
  /**
   * Comparative Media Studies/Writing
   */
  CmsW: "CMS-W",
  /**
   * Edgerton Center
   */
  Ec: "EC",
  /**
   * Experimental Study Group
   */
  Es: "ES",
  /**
   * Engineering Systems Division
   */
  Esd: "ESD",
  /**
   * Medical Engineering and Science
   */
  Hst: "HST",
  /**
   * Data, Systems, and Society
   */
  Ids: "IDS",
  /**
   * Media Arts and Sciences
   */
  Mas: "MAS",
  /**
   * Athletics, Physical Education and Recreation
   */
  Pe: "PE",
  /**
   * Special Programs
   */
  Sp: "SP",
  /**
   * Science, Technology, and Society
   */
  Sts: "STS",
  /**
   * Women&#39;s and Gender Studies
   */
  Wgs: "WGS",
} as const

export type DepartmentEnum =
  (typeof DepartmentEnum)[keyof typeof DepartmentEnum]

/**
 * Serializer for the LearningPath model
 * @export
 * @interface LearningPath
 */
export interface LearningPath {
  /**
   *
   * @type {number}
   * @memberof LearningPath
   */
  id: number
  /**
   * Return the number of items in the list
   * @type {number}
   * @memberof LearningPath
   */
  item_count: number
}
/**
 * Specialized serializer for a LearningPath relationship
 * @export
 * @interface LearningPathRelationship
 */
export interface LearningPathRelationship {
  /**
   *
   * @type {number}
   * @memberof LearningPathRelationship
   */
  id: number
  /**
   *
   * @type {LearningResource}
   * @memberof LearningPathRelationship
   */
  resource: LearningResource
  /**
   *
   * @type {number}
   * @memberof LearningPathRelationship
   */
  position?: number
  /**
   *
   * @type {number}
   * @memberof LearningPathRelationship
   */
  parent: number
  /**
   *
   * @type {number}
   * @memberof LearningPathRelationship
   */
  child: number
}
/**
 * Specialized serializer for a LearningPath relationship
 * @export
 * @interface LearningPathRelationshipRequest
 */
export interface LearningPathRelationshipRequest {
  /**
   *
   * @type {number}
   * @memberof LearningPathRelationshipRequest
   */
  position?: number
  /**
   *
   * @type {number}
   * @memberof LearningPathRelationshipRequest
   */
  parent: number
  /**
   *
   * @type {number}
   * @memberof LearningPathRelationshipRequest
   */
  child: number
}
/**
 * CRUD serializer for LearningPath resources
 * @export
 * @interface LearningPathResource
 */
export interface LearningPathResource {
  /**
   *
   * @type {number}
   * @memberof LearningPathResource
   */
  id: number
  /**
   *
   * @type {Array<LearningResourceTopic>}
   * @memberof LearningPathResource
   */
  topics?: Array<LearningResourceTopic>
  /**
   *
   * @type {number}
   * @memberof LearningPathResource
   */
  position: number | null
  /**
   *
   * @type {LearningResourceOfferor}
   * @memberof LearningPathResource
   */
  offered_by: LearningResourceOfferor | null
  /**
   *
   * @type {LearningResourcePlatform}
   * @memberof LearningPathResource
   */
  platform: LearningResourcePlatform | null
  /**
   *
   * @type {Array<string>}
   * @memberof LearningPathResource
   */
  course_feature: Array<string> | null
  /**
   *
   * @type {Array<LearningResourceDepartment>}
   * @memberof LearningPathResource
   */
  departments: Array<LearningResourceDepartment> | null
  /**
   *
   * @type {boolean}
   * @memberof LearningPathResource
   */
  certification: boolean
  /**
   *
   * @type {CourseResourceCertificationType}
   * @memberof LearningPathResource
   */
  certification_type: CourseResourceCertificationType
  /**
   *
   * @type {Array<string>}
   * @memberof LearningPathResource
   */
  prices: Array<string>
  /**
   *
   * @type {Array<LearningResourcePrice>}
   * @memberof LearningPathResource
   */
  resource_prices: Array<LearningResourcePrice>
  /**
   *
   * @type {Array<LearningResourceRun>}
   * @memberof LearningPathResource
   */
  runs: Array<LearningResourceRun> | null
  /**
   *
   * @type {LearningResourceImage}
   * @memberof LearningPathResource
   */
  image: LearningResourceImage | null
  /**
   *
   * @type {Array<MicroLearningPathRelationship>}
   * @memberof LearningPathResource
   */
  learning_path_parents: Array<MicroLearningPathRelationship>
  /**
   *
   * @type {Array<MicroUserListRelationship>}
   * @memberof LearningPathResource
   */
  user_list_parents: Array<MicroUserListRelationship>
  /**
   *
   * @type {number}
   * @memberof LearningPathResource
   */
  views: number
  /**
   *
   * @type {Array<CourseResourceDeliveryInner>}
   * @memberof LearningPathResource
   */
  delivery: Array<CourseResourceDeliveryInner>
  /**
   * Return true if the resource is free/has a free option
   * @type {boolean}
   * @memberof LearningPathResource
   */
  free: boolean
  /**
   * Return the resource category of the resource
   * @type {string}
   * @memberof LearningPathResource
   */
  resource_category: string
  /**
   *
   * @type {Array<CourseResourceFormatInner>}
   * @memberof LearningPathResource
   */
  format: Array<CourseResourceFormatInner>
  /**
   *
   * @type {Array<CourseResourcePaceInner>}
   * @memberof LearningPathResource
   */
  pace: Array<CourseResourcePaceInner>
  /**
   *
   * @type {LearningPathResourceResourceTypeEnum}
   * @memberof LearningPathResource
   */
  resource_type: LearningPathResourceResourceTypeEnum
  /**
   *
   * @type {LearningPath}
   * @memberof LearningPathResource
   */
  learning_path: LearningPath
  /**
   *
   * @type {string}
   * @memberof LearningPathResource
   */
  readable_id: string
  /**
   *
   * @type {string}
   * @memberof LearningPathResource
   */
  title: string
  /**
   *
   * @type {string}
   * @memberof LearningPathResource
   */
  description?: string | null
  /**
   *
   * @type {string}
   * @memberof LearningPathResource
   */
  full_description?: string | null
  /**
   *
   * @type {string}
   * @memberof LearningPathResource
   */
  last_modified?: string | null
  /**
   *
   * @type {boolean}
   * @memberof LearningPathResource
   */
  published?: boolean
  /**
   *
   * @type {Array<string>}
   * @memberof LearningPathResource
   */
  languages?: Array<string> | null
  /**
   *
   * @type {string}
   * @memberof LearningPathResource
   */
  url?: string | null
  /**
   *
   * @type {Array<string>}
   * @memberof LearningPathResource
   */
  ocw_topics?: Array<string>
  /**
   *
   * @type {boolean}
   * @memberof LearningPathResource
   */
  professional?: boolean
  /**
   *
   * @type {string}
   * @memberof LearningPathResource
   */
  next_start_date?: string | null
  /**
   *
   * @type {AvailabilityEnum}
   * @memberof LearningPathResource
   */
  availability?: AvailabilityEnum | null
  /**
   *
   * @type {number}
   * @memberof LearningPathResource
   */
  completeness?: number
  /**
   *
   * @type {boolean}
   * @memberof LearningPathResource
   */
  license_cc?: boolean
  /**
   *
   * @type {string}
   * @memberof LearningPathResource
   */
  continuing_ed_credits?: string | null
  /**
   *
   * @type {string}
   * @memberof LearningPathResource
   */
  location?: string
  /**
   *
   * @type {string}
   * @memberof LearningPathResource
   */
  duration?: string
  /**
   *
   * @type {number}
   * @memberof LearningPathResource
   */
  min_weeks?: number | null
  /**
   *
   * @type {number}
   * @memberof LearningPathResource
   */
  max_weeks?: number | null
  /**
   *
   * @type {string}
   * @memberof LearningPathResource
   */
  time_commitment?: string
  /**
   *
   * @type {number}
   * @memberof LearningPathResource
   */
  min_weekly_hours?: number | null
  /**
   *
   * @type {number}
   * @memberof LearningPathResource
   */
  max_weekly_hours?: number | null
}

/**
 * CRUD serializer for LearningPath resources
 * @export
 * @interface LearningPathResourceRequest
 */
export interface LearningPathResourceRequest {
  /**
   *
   * @type {Array<LearningResourceTopic>}
   * @memberof LearningPathResourceRequest
   */
  topics?: Array<LearningResourceTopic>
  /**
   *
   * @type {string}
   * @memberof LearningPathResourceRequest
   */
  title: string
  /**
   *
   * @type {string}
   * @memberof LearningPathResourceRequest
   */
  description?: string | null
  /**
   *
   * @type {string}
   * @memberof LearningPathResourceRequest
   */
  full_description?: string | null
  /**
   *
   * @type {string}
   * @memberof LearningPathResourceRequest
   */
  last_modified?: string | null
  /**
   *
   * @type {boolean}
   * @memberof LearningPathResourceRequest
   */
  published?: boolean
  /**
   *
   * @type {Array<string>}
   * @memberof LearningPathResourceRequest
   */
  languages?: Array<string> | null
  /**
   *
   * @type {string}
   * @memberof LearningPathResourceRequest
   */
  url?: string | null
  /**
   *
   * @type {Array<string>}
   * @memberof LearningPathResourceRequest
   */
  ocw_topics?: Array<string>
  /**
   *
   * @type {boolean}
   * @memberof LearningPathResourceRequest
   */
  professional?: boolean
  /**
   *
   * @type {string}
   * @memberof LearningPathResourceRequest
   */
  next_start_date?: string | null
  /**
   *
   * @type {AvailabilityEnum}
   * @memberof LearningPathResourceRequest
   */
  availability?: AvailabilityEnum | null
  /**
   *
   * @type {number}
   * @memberof LearningPathResourceRequest
   */
  completeness?: number
  /**
   *
   * @type {boolean}
   * @memberof LearningPathResourceRequest
   */
  license_cc?: boolean
  /**
   *
   * @type {string}
   * @memberof LearningPathResourceRequest
   */
  continuing_ed_credits?: string | null
  /**
   *
   * @type {string}
   * @memberof LearningPathResourceRequest
   */
  location?: string
  /**
   *
   * @type {string}
   * @memberof LearningPathResourceRequest
   */
  duration?: string
  /**
   *
   * @type {number}
   * @memberof LearningPathResourceRequest
   */
  min_weeks?: number | null
  /**
   *
   * @type {number}
   * @memberof LearningPathResourceRequest
   */
  max_weeks?: number | null
  /**
   *
   * @type {string}
   * @memberof LearningPathResourceRequest
   */
  time_commitment?: string
  /**
   *
   * @type {number}
   * @memberof LearningPathResourceRequest
   */
  min_weekly_hours?: number | null
  /**
   *
   * @type {number}
   * @memberof LearningPathResourceRequest
   */
  max_weekly_hours?: number | null
}

/**
 *
 * @export
 * @enum {string}
 */

export const LearningPathResourceResourceTypeEnumDescriptions = {
  learning_path: "",
} as const

export const LearningPathResourceResourceTypeEnum = {
  LearningPath: "learning_path",
} as const

export type LearningPathResourceResourceTypeEnum =
  (typeof LearningPathResourceResourceTypeEnum)[keyof typeof LearningPathResourceResourceTypeEnum]

/**
 * @type LearningResource
 * @export
 */
export type LearningResource =
  | ({ resource_type: "course" } & CourseResource)
  | ({ resource_type: "learning_path" } & LearningPathResource)
  | ({ resource_type: "podcast" } & PodcastResource)
  | ({ resource_type: "podcast_episode" } & PodcastEpisodeResource)
  | ({ resource_type: "program" } & ProgramResource)
  | ({ resource_type: "video" } & VideoResource)
  | ({ resource_type: "video_playlist" } & VideoPlaylistResource)

/**
 * Serializer for LearningResourceDepartment, minus school  The absence of the departments list is to avoid a circular serialization structure.
 * @export
 * @interface LearningResourceBaseDepartment
 */
export interface LearningResourceBaseDepartment {
  /**
   *
   * @type {string}
   * @memberof LearningResourceBaseDepartment
   */
  department_id: string
  /**
   *
   * @type {string}
   * @memberof LearningResourceBaseDepartment
   */
  name: string
  /**
   *
   * @type {string}
   * @memberof LearningResourceBaseDepartment
   */
  channel_url: string | null
}
/**
 * Base serializer for LearningResourceSchool model, minus departments list  The absence of the departments list is to avoid a circular serialization structure.
 * @export
 * @interface LearningResourceBaseSchool
 */
export interface LearningResourceBaseSchool {
  /**
   *
   * @type {number}
   * @memberof LearningResourceBaseSchool
   */
  id: number
  /**
   *
   * @type {string}
   * @memberof LearningResourceBaseSchool
   */
  name: string
  /**
   *
   * @type {string}
   * @memberof LearningResourceBaseSchool
   */
  url: string
}
/**
 * Base serializer for LearningResourceSchool model, minus departments list  The absence of the departments list is to avoid a circular serialization structure.
 * @export
 * @interface LearningResourceBaseSchoolRequest
 */
export interface LearningResourceBaseSchoolRequest {
  /**
   *
   * @type {string}
   * @memberof LearningResourceBaseSchoolRequest
   */
  name: string
  /**
   *
   * @type {string}
   * @memberof LearningResourceBaseSchoolRequest
   */
  url: string
}
/**
 * Serializer for LearningResourceContentTag
 * @export
 * @interface LearningResourceContentTag
 */
export interface LearningResourceContentTag {
  /**
   *
   * @type {number}
   * @memberof LearningResourceContentTag
   */
  id: number
  /**
   *
   * @type {string}
   * @memberof LearningResourceContentTag
   */
  name: string
}
/**
 * Full serializer for LearningResourceDepartment, including school
 * @export
 * @interface LearningResourceDepartment
 */
export interface LearningResourceDepartment {
  /**
   *
   * @type {string}
   * @memberof LearningResourceDepartment
   */
  department_id: string
  /**
   *
   * @type {string}
   * @memberof LearningResourceDepartment
   */
  name: string
  /**
   *
   * @type {string}
   * @memberof LearningResourceDepartment
   */
  channel_url: string | null
  /**
   *
   * @type {LearningResourceBaseSchool}
   * @memberof LearningResourceDepartment
   */
  school: LearningResourceBaseSchool | null
}
/**
 * Full serializer for LearningResourceDepartment, including school
 * @export
 * @interface LearningResourceDepartmentRequest
 */
export interface LearningResourceDepartmentRequest {
  /**
   *
   * @type {string}
   * @memberof LearningResourceDepartmentRequest
   */
  department_id: string
  /**
   *
   * @type {string}
   * @memberof LearningResourceDepartmentRequest
   */
  name: string
}
/**
 * Serializer for LearningResourceImage
 * @export
 * @interface LearningResourceImage
 */
export interface LearningResourceImage {
  /**
   *
   * @type {number}
   * @memberof LearningResourceImage
   */
  id: number
  /**
   *
   * @type {string}
   * @memberof LearningResourceImage
   */
  url: string
  /**
   *
   * @type {string}
   * @memberof LearningResourceImage
   */
  description?: string | null
  /**
   *
   * @type {string}
   * @memberof LearningResourceImage
   */
  alt?: string | null
}
/**
 * Serializer for LearningResourceImage
 * @export
 * @interface LearningResourceImageRequest
 */
export interface LearningResourceImageRequest {
  /**
   *
   * @type {string}
   * @memberof LearningResourceImageRequest
   */
  url: string
  /**
   *
   * @type {string}
   * @memberof LearningResourceImageRequest
   */
  description?: string | null
  /**
   *
   * @type {string}
   * @memberof LearningResourceImageRequest
   */
  alt?: string | null
}
/**
 * Serializer for LearningResourceInstructor model
 * @export
 * @interface LearningResourceInstructor
 */
export interface LearningResourceInstructor {
  /**
   *
   * @type {number}
   * @memberof LearningResourceInstructor
   */
  id: number
  /**
   *
   * @type {string}
   * @memberof LearningResourceInstructor
   */
  first_name?: string | null
  /**
   *
   * @type {string}
   * @memberof LearningResourceInstructor
   */
  last_name?: string | null
  /**
   *
   * @type {string}
   * @memberof LearningResourceInstructor
   */
  full_name?: string | null
}
/**
 * Serializer for LearningResourceInstructor model
 * @export
 * @interface LearningResourceInstructorRequest
 */
export interface LearningResourceInstructorRequest {
  /**
   *
   * @type {string}
   * @memberof LearningResourceInstructorRequest
   */
  first_name?: string | null
  /**
   *
   * @type {string}
   * @memberof LearningResourceInstructorRequest
   */
  last_name?: string | null
  /**
   *
   * @type {string}
   * @memberof LearningResourceInstructorRequest
   */
  full_name?: string | null
}
/**
 * Serializer for LearningResourceOfferor with basic details
 * @export
 * @interface LearningResourceOfferor
 */
export interface LearningResourceOfferor {
  /**
   *
   * @type {string}
   * @memberof LearningResourceOfferor
   */
  code: string
  /**
   *
   * @type {string}
   * @memberof LearningResourceOfferor
   */
  name: string
  /**
   *
   * @type {string}
   * @memberof LearningResourceOfferor
   */
  channel_url: string | null
}
/**
 * Serializer for LearningResourceOfferor with all details
 * @export
 * @interface LearningResourceOfferorDetail
 */
export interface LearningResourceOfferorDetail {
  /**
   *
   * @type {string}
   * @memberof LearningResourceOfferorDetail
   */
  code: string
  /**
   *
   * @type {string}
   * @memberof LearningResourceOfferorDetail
   */
  channel_url: string | null
  /**
   *
   * @type {string}
   * @memberof LearningResourceOfferorDetail
   */
  name: string
  /**
   *
   * @type {boolean}
   * @memberof LearningResourceOfferorDetail
   */
  professional?: boolean
  /**
   *
   * @type {Array<string>}
   * @memberof LearningResourceOfferorDetail
   */
  offerings?: Array<string>
  /**
   *
   * @type {Array<string>}
   * @memberof LearningResourceOfferorDetail
   */
  audience?: Array<string>
  /**
   *
   * @type {Array<string>}
   * @memberof LearningResourceOfferorDetail
   */
  formats?: Array<string>
  /**
   *
   * @type {Array<string>}
   * @memberof LearningResourceOfferorDetail
   */
  fee?: Array<string>
  /**
   *
   * @type {Array<string>}
   * @memberof LearningResourceOfferorDetail
   */
  certifications?: Array<string>
  /**
   *
   * @type {Array<string>}
   * @memberof LearningResourceOfferorDetail
   */
  content_types?: Array<string>
  /**
   *
   * @type {string}
   * @memberof LearningResourceOfferorDetail
   */
  more_information?: string
  /**
   *
   * @type {string}
   * @memberof LearningResourceOfferorDetail
   */
  value_prop?: string
}
/**
 * Serializer for LearningResourceOfferor with basic details
 * @export
 * @interface LearningResourceOfferorRequest
 */
export interface LearningResourceOfferorRequest {
  /**
   *
   * @type {string}
   * @memberof LearningResourceOfferorRequest
   */
  code: string
  /**
   *
   * @type {string}
   * @memberof LearningResourceOfferorRequest
   */
  name: string
}
/**
 * Serializer for LearningResourcePlatform
 * @export
 * @interface LearningResourcePlatform
 */
export interface LearningResourcePlatform {
  /**
   *
   * @type {string}
   * @memberof LearningResourcePlatform
   */
  code: string
  /**
   *
   * @type {string}
   * @memberof LearningResourcePlatform
   */
  name?: string
}
/**
 * Serializer for LearningResourcePlatform
 * @export
 * @interface LearningResourcePlatformRequest
 */
export interface LearningResourcePlatformRequest {
  /**
   *
   * @type {string}
   * @memberof LearningResourcePlatformRequest
   */
  code: string
  /**
   *
   * @type {string}
   * @memberof LearningResourcePlatformRequest
   */
  name?: string
}
/**
 * Serializer for LearningResourcePrice model
 * @export
 * @interface LearningResourcePrice
 */
export interface LearningResourcePrice {
  /**
   *
   * @type {string}
   * @memberof LearningResourcePrice
   */
  amount: string
  /**
   *
   * @type {string}
   * @memberof LearningResourcePrice
   */
  currency: string
}
/**
 * Serializer for LearningResourcePrice model
 * @export
 * @interface LearningResourcePriceRequest
 */
export interface LearningResourcePriceRequest {
  /**
   *
   * @type {string}
   * @memberof LearningResourcePriceRequest
   */
  amount: string
  /**
   *
   * @type {string}
   * @memberof LearningResourcePriceRequest
   */
  currency: string
}
/**
 * CRUD serializer for LearningResourceRelationship
 * @export
 * @interface LearningResourceRelationship
 */
export interface LearningResourceRelationship {
  /**
   *
   * @type {number}
   * @memberof LearningResourceRelationship
   */
  id: number
  /**
   *
   * @type {LearningResource}
   * @memberof LearningResourceRelationship
   */
  resource: LearningResource
  /**
   *
   * @type {number}
   * @memberof LearningResourceRelationship
   */
  position?: number
  /**
   *
   * @type {RelationTypeEnum}
   * @memberof LearningResourceRelationship
   */
  relation_type?: RelationTypeEnum
  /**
   *
   * @type {number}
   * @memberof LearningResourceRelationship
   */
  parent: number
  /**
   *
   * @type {number}
   * @memberof LearningResourceRelationship
   */
  child: number
}

/**
 * @type LearningResourceRequest
 * @export
 */
export type LearningResourceRequest =
  | ({ resource_type: "course" } & CourseResourceRequest)
  | ({ resource_type: "learning_path" } & LearningPathResourceRequest)
  | ({ resource_type: "podcast" } & PodcastResourceRequest)
  | ({ resource_type: "podcast_episode" } & PodcastEpisodeResourceRequest)
  | ({ resource_type: "program" } & ProgramResourceRequest)
  | ({ resource_type: "video" } & VideoResourceRequest)
  | ({ resource_type: "video_playlist" } & VideoPlaylistResourceRequest)

/**
 * Serializer for the LearningResourceRun model
 * @export
 * @interface LearningResourceRun
 */
export interface LearningResourceRun {
  /**
   *
   * @type {number}
   * @memberof LearningResourceRun
   */
  id: number
  /**
   *
   * @type {Array<LearningResourceInstructor>}
   * @memberof LearningResourceRun
   */
  instructors: Array<LearningResourceInstructor> | null
  /**
   *
   * @type {LearningResourceImage}
   * @memberof LearningResourceRun
   */
  image: LearningResourceImage | null
  /**
   *
   * @type {Array<LearningResourceRunLevelInner>}
   * @memberof LearningResourceRun
   */
  level: Array<LearningResourceRunLevelInner>
  /**
   *
   * @type {Array<CourseResourceDeliveryInner>}
   * @memberof LearningResourceRun
   */
  delivery: Array<CourseResourceDeliveryInner>
  /**
   *
   * @type {Array<CourseResourceFormatInner>}
   * @memberof LearningResourceRun
   */
  format: Array<CourseResourceFormatInner>
  /**
   *
   * @type {Array<CourseResourcePaceInner>}
   * @memberof LearningResourceRun
   */
  pace: Array<CourseResourcePaceInner>
  /**
   *
   * @type {Array<LearningResourcePrice>}
   * @memberof LearningResourceRun
   */
  resource_prices: Array<LearningResourcePrice>
  /**
   *
   * @type {string}
   * @memberof LearningResourceRun
   */
  run_id: string
  /**
   *
   * @type {string}
   * @memberof LearningResourceRun
   */
  title: string
  /**
   *
   * @type {string}
   * @memberof LearningResourceRun
   */
  description?: string | null
  /**
   *
   * @type {string}
   * @memberof LearningResourceRun
   */
  full_description?: string | null
  /**
   *
   * @type {string}
   * @memberof LearningResourceRun
   */
  last_modified?: string | null
  /**
   *
   * @type {boolean}
   * @memberof LearningResourceRun
   */
  published?: boolean
  /**
   *
   * @type {Array<string>}
   * @memberof LearningResourceRun
   */
  languages?: Array<string> | null
  /**
   *
   * @type {string}
   * @memberof LearningResourceRun
   */
  url?: string | null
  /**
   *
   * @type {string}
   * @memberof LearningResourceRun
   */
  slug?: string | null
  /**
   *
   * @type {string}
   * @memberof LearningResourceRun
   */
  semester?: string | null
  /**
   *
   * @type {number}
   * @memberof LearningResourceRun
   */
  year?: number | null
  /**
   *
   * @type {string}
   * @memberof LearningResourceRun
   */
  start_date?: string | null
  /**
   *
   * @type {string}
   * @memberof LearningResourceRun
   */
  end_date?: string | null
  /**
   *
   * @type {string}
   * @memberof LearningResourceRun
   */
  enrollment_start?: string | null
  /**
   *
   * @type {string}
   * @memberof LearningResourceRun
   */
  enrollment_end?: string | null
  /**
   *
   * @type {Array<string>}
   * @memberof LearningResourceRun
   */
  prices?: Array<string> | null
  /**
   *
   * @type {string}
   * @memberof LearningResourceRun
   */
  checksum?: string | null
  /**
   *
   * @type {AvailabilityEnum}
   * @memberof LearningResourceRun
   */
  availability?: AvailabilityEnum | null
  /**
   *
   * @type {string}
   * @memberof LearningResourceRun
   */
  location?: string
  /**
   *
   * @type {string}
   * @memberof LearningResourceRun
   */
  duration?: string
  /**
   *
   * @type {number}
   * @memberof LearningResourceRun
   */
  min_weeks?: number | null
  /**
   *
   * @type {number}
   * @memberof LearningResourceRun
   */
  max_weeks?: number | null
  /**
   *
   * @type {string}
   * @memberof LearningResourceRun
   */
  time_commitment?: string
  /**
   *
   * @type {number}
   * @memberof LearningResourceRun
   */
  min_weekly_hours?: number | null
  /**
   *
   * @type {number}
   * @memberof LearningResourceRun
   */
  max_weekly_hours?: number | null
}

/**
 *
 * @export
 * @interface LearningResourceRunLevelInner
 */
export interface LearningResourceRunLevelInner {
  /**
   *
   * @type {string}
   * @memberof LearningResourceRunLevelInner
   */
  code: LearningResourceRunLevelInnerCodeEnum
  /**
   *
   * @type {string}
   * @memberof LearningResourceRunLevelInner
   */
  name: string
}

export const LearningResourceRunLevelInnerCodeEnum = {
  Undergraduate: "undergraduate",
  Graduate: "graduate",
  HighSchool: "high_school",
  Noncredit: "noncredit",
  Advanced: "advanced",
  Intermediate: "intermediate",
  Introductory: "introductory",
} as const

export type LearningResourceRunLevelInnerCodeEnum =
  (typeof LearningResourceRunLevelInnerCodeEnum)[keyof typeof LearningResourceRunLevelInnerCodeEnum]

/**
 * Serializer for the LearningResourceRun model
 * @export
 * @interface LearningResourceRunRequest
 */
export interface LearningResourceRunRequest {
  /**
   *
   * @type {Array<LearningResourceRunLevelInner>}
   * @memberof LearningResourceRunRequest
   */
  level: Array<LearningResourceRunLevelInner>
  /**
   *
   * @type {string}
   * @memberof LearningResourceRunRequest
   */
  run_id: string
  /**
   *
   * @type {string}
   * @memberof LearningResourceRunRequest
   */
  title: string
  /**
   *
   * @type {string}
   * @memberof LearningResourceRunRequest
   */
  description?: string | null
  /**
   *
   * @type {string}
   * @memberof LearningResourceRunRequest
   */
  full_description?: string | null
  /**
   *
   * @type {string}
   * @memberof LearningResourceRunRequest
   */
  last_modified?: string | null
  /**
   *
   * @type {boolean}
   * @memberof LearningResourceRunRequest
   */
  published?: boolean
  /**
   *
   * @type {Array<string>}
   * @memberof LearningResourceRunRequest
   */
  languages?: Array<string> | null
  /**
   *
   * @type {string}
   * @memberof LearningResourceRunRequest
   */
  url?: string | null
  /**
   *
   * @type {string}
   * @memberof LearningResourceRunRequest
   */
  slug?: string | null
  /**
   *
   * @type {string}
   * @memberof LearningResourceRunRequest
   */
  semester?: string | null
  /**
   *
   * @type {number}
   * @memberof LearningResourceRunRequest
   */
  year?: number | null
  /**
   *
   * @type {string}
   * @memberof LearningResourceRunRequest
   */
  start_date?: string | null
  /**
   *
   * @type {string}
   * @memberof LearningResourceRunRequest
   */
  end_date?: string | null
  /**
   *
   * @type {string}
   * @memberof LearningResourceRunRequest
   */
  enrollment_start?: string | null
  /**
   *
   * @type {string}
   * @memberof LearningResourceRunRequest
   */
  enrollment_end?: string | null
  /**
   *
   * @type {Array<string>}
   * @memberof LearningResourceRunRequest
   */
  prices?: Array<string> | null
  /**
   *
   * @type {string}
   * @memberof LearningResourceRunRequest
   */
  checksum?: string | null
  /**
   *
   * @type {AvailabilityEnum}
   * @memberof LearningResourceRunRequest
   */
  availability?: AvailabilityEnum | null
  /**
   *
   * @type {string}
   * @memberof LearningResourceRunRequest
   */
  location?: string
  /**
   *
   * @type {string}
   * @memberof LearningResourceRunRequest
   */
  duration?: string
  /**
   *
   * @type {number}
   * @memberof LearningResourceRunRequest
   */
  min_weeks?: number | null
  /**
   *
   * @type {number}
   * @memberof LearningResourceRunRequest
   */
  max_weeks?: number | null
  /**
   *
   * @type {string}
   * @memberof LearningResourceRunRequest
   */
  time_commitment?: string
  /**
   *
   * @type {number}
   * @memberof LearningResourceRunRequest
   */
  min_weekly_hours?: number | null
  /**
   *
   * @type {number}
   * @memberof LearningResourceRunRequest
   */
  max_weekly_hours?: number | null
}

/**
 * Serializer for LearningResourceSchool model, including list of departments
 * @export
 * @interface LearningResourceSchool
 */
export interface LearningResourceSchool {
  /**
   *
   * @type {number}
   * @memberof LearningResourceSchool
   */
  id: number
  /**
   *
   * @type {string}
   * @memberof LearningResourceSchool
   */
  name: string
  /**
   *
   * @type {string}
   * @memberof LearningResourceSchool
   */
  url: string
  /**
   *
   * @type {Array<LearningResourceBaseDepartment>}
   * @memberof LearningResourceSchool
   */
  departments: Array<LearningResourceBaseDepartment>
}
/**
 * Serializer for LearningResourceTopic model
 * @export
 * @interface LearningResourceTopic
 */
export interface LearningResourceTopic {
  /**
   *
   * @type {number}
   * @memberof LearningResourceTopic
   */
  id: number
  /**
   *
   * @type {string}
   * @memberof LearningResourceTopic
   */
  name: string
  /**
   * The icon to display for the topic.
   * @type {string}
   * @memberof LearningResourceTopic
   */
  icon?: string
  /**
   *
   * @type {number}
   * @memberof LearningResourceTopic
   */
  parent?: number | null
  /**
   *
   * @type {string}
   * @memberof LearningResourceTopic
   */
  channel_url: string | null
}
/**
 * SearchResponseSerializer with OpenAPI annotations for Learning Resources search
 * @export
 * @interface LearningResourcesSearchResponse
 */
export interface LearningResourcesSearchResponse {
  /**
   *
   * @type {number}
   * @memberof LearningResourcesSearchResponse
   */
  count: number
  /**
   *
   * @type {string}
   * @memberof LearningResourcesSearchResponse
   */
  next: string | null
  /**
   *
   * @type {string}
   * @memberof LearningResourcesSearchResponse
   */
  previous: string | null
  /**
   *
   * @type {Array<LearningResource>}
   * @memberof LearningResourcesSearchResponse
   */
  results: Array<LearningResource>
  /**
   *
   * @type {ContentFileSearchResponseMetadata}
   * @memberof LearningResourcesSearchResponse
   */
  metadata: ContentFileSearchResponseMetadata
}
/**
 * * `undergraduate` - Undergraduate * `graduate` - Graduate * `high_school` - High School * `noncredit` - Non-Credit * `advanced` - Advanced * `intermediate` - Intermediate * `introductory` - Introductory
 * @export
 * @enum {string}
 */

export const LevelEnumDescriptions = {
  undergraduate: "Undergraduate",
  graduate: "Graduate",
  high_school: "High School",
  noncredit: "Non-Credit",
  advanced: "Advanced",
  intermediate: "Intermediate",
  introductory: "Introductory",
} as const

export const LevelEnum = {
  /**
   * Undergraduate
   */
  Undergraduate: "undergraduate",
  /**
   * Graduate
   */
  Graduate: "graduate",
  /**
   * High School
   */
  HighSchool: "high_school",
  /**
   * Non-Credit
   */
  Noncredit: "noncredit",
  /**
   * Advanced
   */
  Advanced: "advanced",
  /**
   * Intermediate
   */
  Intermediate: "intermediate",
  /**
   * Introductory
   */
  Introductory: "introductory",
} as const

export type LevelEnum = (typeof LevelEnum)[keyof typeof LevelEnum]

/**
 * Serializer containing only parent and child ids for a learning path relationship
 * @export
 * @interface MicroLearningPathRelationship
 */
export interface MicroLearningPathRelationship {
  /**
   *
   * @type {number}
   * @memberof MicroLearningPathRelationship
   */
  id: number
  /**
   * The id of the parent learning resource
   * @type {number}
   * @memberof MicroLearningPathRelationship
   */
  parent: number
  /**
   *
   * @type {number}
   * @memberof MicroLearningPathRelationship
   */
  child: number
}
/**
 * Serializer containing only parent and child ids for a user list relationship
 * @export
 * @interface MicroUserListRelationship
 */
export interface MicroUserListRelationship {
  /**
   *
   * @type {number}
   * @memberof MicroUserListRelationship
   */
  id: number
  /**
   * The id of the parent learning resource
   * @type {number}
   * @memberof MicroUserListRelationship
   */
  parent: number
  /**
   *
   * @type {number}
   * @memberof MicroUserListRelationship
   */
  child: number
}
/**
 *
 * @export
 * @enum {string}
 */

export const NullEnumDescriptions = {
  null: "",
} as const

export const NullEnum = {
  Null: "null",
} as const

export type NullEnum = (typeof NullEnum)[keyof typeof NullEnum]

/**
 * * `mitx` - MITx * `ocw` - MIT OpenCourseWare * `bootcamps` - Bootcamps * `xpro` - MIT xPRO * `mitpe` - MIT Professional Education * `see` - MIT Sloan Executive Education
 * @export
 * @enum {string}
 */

export const OfferedByEnumDescriptions = {
  mitx: "MITx",
  ocw: "MIT OpenCourseWare",
  bootcamps: "Bootcamps",
  xpro: "MIT xPRO",
  mitpe: "MIT Professional Education",
  see: "MIT Sloan Executive Education",
} as const

export const OfferedByEnum = {
  /**
   * MITx
   */
  Mitx: "mitx",
  /**
   * MIT OpenCourseWare
   */
  Ocw: "ocw",
  /**
   * Bootcamps
   */
  Bootcamps: "bootcamps",
  /**
   * MIT xPRO
   */
  Xpro: "xpro",
  /**
   * MIT Professional Education
   */
  Mitpe: "mitpe",
  /**
   * MIT Sloan Executive Education
   */
  See: "see",
} as const

export type OfferedByEnum = (typeof OfferedByEnum)[keyof typeof OfferedByEnum]

/**
 *
 * @export
 * @interface PaginatedArticleList
 */
export interface PaginatedArticleList {
  /**
   *
   * @type {number}
   * @memberof PaginatedArticleList
   */
  count: number
  /**
   *
   * @type {string}
   * @memberof PaginatedArticleList
   */
  next?: string | null
  /**
   *
   * @type {string}
   * @memberof PaginatedArticleList
   */
  previous?: string | null
  /**
   *
   * @type {Array<Article>}
   * @memberof PaginatedArticleList
   */
  results: Array<Article>
}
/**
 *
 * @export
 * @interface PaginatedContentFileList
 */
export interface PaginatedContentFileList {
  /**
   *
   * @type {number}
   * @memberof PaginatedContentFileList
   */
  count: number
  /**
   *
   * @type {string}
   * @memberof PaginatedContentFileList
   */
  next?: string | null
  /**
   *
   * @type {string}
   * @memberof PaginatedContentFileList
   */
  previous?: string | null
  /**
   *
   * @type {Array<ContentFile>}
   * @memberof PaginatedContentFileList
   */
  results: Array<ContentFile>
}
/**
 *
 * @export
 * @interface PaginatedCourseResourceList
 */
export interface PaginatedCourseResourceList {
  /**
   *
   * @type {number}
   * @memberof PaginatedCourseResourceList
   */
  count: number
  /**
   *
   * @type {string}
   * @memberof PaginatedCourseResourceList
   */
  next?: string | null
  /**
   *
   * @type {string}
   * @memberof PaginatedCourseResourceList
   */
  previous?: string | null
  /**
   *
   * @type {Array<CourseResource>}
   * @memberof PaginatedCourseResourceList
   */
  results: Array<CourseResource>
}
/**
 *
 * @export
 * @interface PaginatedLearningPathRelationshipList
 */
export interface PaginatedLearningPathRelationshipList {
  /**
   *
   * @type {number}
   * @memberof PaginatedLearningPathRelationshipList
   */
  count: number
  /**
   *
   * @type {string}
   * @memberof PaginatedLearningPathRelationshipList
   */
  next?: string | null
  /**
   *
   * @type {string}
   * @memberof PaginatedLearningPathRelationshipList
   */
  previous?: string | null
  /**
   *
   * @type {Array<LearningPathRelationship>}
   * @memberof PaginatedLearningPathRelationshipList
   */
  results: Array<LearningPathRelationship>
}
/**
 *
 * @export
 * @interface PaginatedLearningPathResourceList
 */
export interface PaginatedLearningPathResourceList {
  /**
   *
   * @type {number}
   * @memberof PaginatedLearningPathResourceList
   */
  count: number
  /**
   *
   * @type {string}
   * @memberof PaginatedLearningPathResourceList
   */
  next?: string | null
  /**
   *
   * @type {string}
   * @memberof PaginatedLearningPathResourceList
   */
  previous?: string | null
  /**
   *
   * @type {Array<LearningPathResource>}
   * @memberof PaginatedLearningPathResourceList
   */
  results: Array<LearningPathResource>
}
/**
 *
 * @export
 * @interface PaginatedLearningResourceContentTagList
 */
export interface PaginatedLearningResourceContentTagList {
  /**
   *
   * @type {number}
   * @memberof PaginatedLearningResourceContentTagList
   */
  count: number
  /**
   *
   * @type {string}
   * @memberof PaginatedLearningResourceContentTagList
   */
  next?: string | null
  /**
   *
   * @type {string}
   * @memberof PaginatedLearningResourceContentTagList
   */
  previous?: string | null
  /**
   *
   * @type {Array<LearningResourceContentTag>}
   * @memberof PaginatedLearningResourceContentTagList
   */
  results: Array<LearningResourceContentTag>
}
/**
 *
 * @export
 * @interface PaginatedLearningResourceDepartmentList
 */
export interface PaginatedLearningResourceDepartmentList {
  /**
   *
   * @type {number}
   * @memberof PaginatedLearningResourceDepartmentList
   */
  count: number
  /**
   *
   * @type {string}
   * @memberof PaginatedLearningResourceDepartmentList
   */
  next?: string | null
  /**
   *
   * @type {string}
   * @memberof PaginatedLearningResourceDepartmentList
   */
  previous?: string | null
  /**
   *
   * @type {Array<LearningResourceDepartment>}
   * @memberof PaginatedLearningResourceDepartmentList
   */
  results: Array<LearningResourceDepartment>
}
/**
 *
 * @export
 * @interface PaginatedLearningResourceList
 */
export interface PaginatedLearningResourceList {
  /**
   *
   * @type {number}
   * @memberof PaginatedLearningResourceList
   */
  count: number
  /**
   *
   * @type {string}
   * @memberof PaginatedLearningResourceList
   */
  next?: string | null
  /**
   *
   * @type {string}
   * @memberof PaginatedLearningResourceList
   */
  previous?: string | null
  /**
   *
   * @type {Array<LearningResource>}
   * @memberof PaginatedLearningResourceList
   */
  results: Array<LearningResource>
}
/**
 *
 * @export
 * @interface PaginatedLearningResourceOfferorDetailList
 */
export interface PaginatedLearningResourceOfferorDetailList {
  /**
   *
   * @type {number}
   * @memberof PaginatedLearningResourceOfferorDetailList
   */
  count: number
  /**
   *
   * @type {string}
   * @memberof PaginatedLearningResourceOfferorDetailList
   */
  next?: string | null
  /**
   *
   * @type {string}
   * @memberof PaginatedLearningResourceOfferorDetailList
   */
  previous?: string | null
  /**
   *
   * @type {Array<LearningResourceOfferorDetail>}
   * @memberof PaginatedLearningResourceOfferorDetailList
   */
  results: Array<LearningResourceOfferorDetail>
}
/**
 *
 * @export
 * @interface PaginatedLearningResourcePlatformList
 */
export interface PaginatedLearningResourcePlatformList {
  /**
   *
   * @type {number}
   * @memberof PaginatedLearningResourcePlatformList
   */
  count: number
  /**
   *
   * @type {string}
   * @memberof PaginatedLearningResourcePlatformList
   */
  next?: string | null
  /**
   *
   * @type {string}
   * @memberof PaginatedLearningResourcePlatformList
   */
  previous?: string | null
  /**
   *
   * @type {Array<LearningResourcePlatform>}
   * @memberof PaginatedLearningResourcePlatformList
   */
  results: Array<LearningResourcePlatform>
}
/**
 *
 * @export
 * @interface PaginatedLearningResourceRelationshipList
 */
export interface PaginatedLearningResourceRelationshipList {
  /**
   *
   * @type {number}
   * @memberof PaginatedLearningResourceRelationshipList
   */
  count: number
  /**
   *
   * @type {string}
   * @memberof PaginatedLearningResourceRelationshipList
   */
  next?: string | null
  /**
   *
   * @type {string}
   * @memberof PaginatedLearningResourceRelationshipList
   */
  previous?: string | null
  /**
   *
   * @type {Array<LearningResourceRelationship>}
   * @memberof PaginatedLearningResourceRelationshipList
   */
  results: Array<LearningResourceRelationship>
}
/**
 *
 * @export
 * @interface PaginatedLearningResourceSchoolList
 */
export interface PaginatedLearningResourceSchoolList {
  /**
   *
   * @type {number}
   * @memberof PaginatedLearningResourceSchoolList
   */
  count: number
  /**
   *
   * @type {string}
   * @memberof PaginatedLearningResourceSchoolList
   */
  next?: string | null
  /**
   *
   * @type {string}
   * @memberof PaginatedLearningResourceSchoolList
   */
  previous?: string | null
  /**
   *
   * @type {Array<LearningResourceSchool>}
   * @memberof PaginatedLearningResourceSchoolList
   */
  results: Array<LearningResourceSchool>
}
/**
 *
 * @export
 * @interface PaginatedLearningResourceTopicList
 */
export interface PaginatedLearningResourceTopicList {
  /**
   *
   * @type {number}
   * @memberof PaginatedLearningResourceTopicList
   */
  count: number
  /**
   *
   * @type {string}
   * @memberof PaginatedLearningResourceTopicList
   */
  next?: string | null
  /**
   *
   * @type {string}
   * @memberof PaginatedLearningResourceTopicList
   */
  previous?: string | null
  /**
   *
   * @type {Array<LearningResourceTopic>}
   * @memberof PaginatedLearningResourceTopicList
   */
  results: Array<LearningResourceTopic>
}
/**
 *
 * @export
 * @interface PaginatedPodcastEpisodeResourceList
 */
export interface PaginatedPodcastEpisodeResourceList {
  /**
   *
   * @type {number}
   * @memberof PaginatedPodcastEpisodeResourceList
   */
  count: number
  /**
   *
   * @type {string}
   * @memberof PaginatedPodcastEpisodeResourceList
   */
  next?: string | null
  /**
   *
   * @type {string}
   * @memberof PaginatedPodcastEpisodeResourceList
   */
  previous?: string | null
  /**
   *
   * @type {Array<PodcastEpisodeResource>}
   * @memberof PaginatedPodcastEpisodeResourceList
   */
  results: Array<PodcastEpisodeResource>
}
/**
 *
 * @export
 * @interface PaginatedPodcastResourceList
 */
export interface PaginatedPodcastResourceList {
  /**
   *
   * @type {number}
   * @memberof PaginatedPodcastResourceList
   */
  count: number
  /**
   *
   * @type {string}
   * @memberof PaginatedPodcastResourceList
   */
  next?: string | null
  /**
   *
   * @type {string}
   * @memberof PaginatedPodcastResourceList
   */
  previous?: string | null
  /**
   *
   * @type {Array<PodcastResource>}
   * @memberof PaginatedPodcastResourceList
   */
  results: Array<PodcastResource>
}
/**
 *
 * @export
 * @interface PaginatedProgramResourceList
 */
export interface PaginatedProgramResourceList {
  /**
   *
   * @type {number}
   * @memberof PaginatedProgramResourceList
   */
  count: number
  /**
   *
   * @type {string}
   * @memberof PaginatedProgramResourceList
   */
  next?: string | null
  /**
   *
   * @type {string}
   * @memberof PaginatedProgramResourceList
   */
  previous?: string | null
  /**
   *
   * @type {Array<ProgramResource>}
   * @memberof PaginatedProgramResourceList
   */
  results: Array<ProgramResource>
}
/**
 *
 * @export
 * @interface PaginatedUserListList
 */
export interface PaginatedUserListList {
  /**
   *
   * @type {number}
   * @memberof PaginatedUserListList
   */
  count: number
  /**
   *
   * @type {string}
   * @memberof PaginatedUserListList
   */
  next?: string | null
  /**
   *
   * @type {string}
   * @memberof PaginatedUserListList
   */
  previous?: string | null
  /**
   *
   * @type {Array<UserList>}
   * @memberof PaginatedUserListList
   */
  results: Array<UserList>
}
/**
 *
 * @export
 * @interface PaginatedUserListRelationshipList
 */
export interface PaginatedUserListRelationshipList {
  /**
   *
   * @type {number}
   * @memberof PaginatedUserListRelationshipList
   */
  count: number
  /**
   *
   * @type {string}
   * @memberof PaginatedUserListRelationshipList
   */
  next?: string | null
  /**
   *
   * @type {string}
   * @memberof PaginatedUserListRelationshipList
   */
  previous?: string | null
  /**
   *
   * @type {Array<UserListRelationship>}
   * @memberof PaginatedUserListRelationshipList
   */
  results: Array<UserListRelationship>
}
/**
 *
 * @export
 * @interface PaginatedVideoPlaylistResourceList
 */
export interface PaginatedVideoPlaylistResourceList {
  /**
   *
   * @type {number}
   * @memberof PaginatedVideoPlaylistResourceList
   */
  count: number
  /**
   *
   * @type {string}
   * @memberof PaginatedVideoPlaylistResourceList
   */
  next?: string | null
  /**
   *
   * @type {string}
   * @memberof PaginatedVideoPlaylistResourceList
   */
  previous?: string | null
  /**
   *
   * @type {Array<VideoPlaylistResource>}
   * @memberof PaginatedVideoPlaylistResourceList
   */
  results: Array<VideoPlaylistResource>
}
/**
 *
 * @export
 * @interface PaginatedVideoResourceList
 */
export interface PaginatedVideoResourceList {
  /**
   *
   * @type {number}
   * @memberof PaginatedVideoResourceList
   */
  count: number
  /**
   *
   * @type {string}
   * @memberof PaginatedVideoResourceList
   */
  next?: string | null
  /**
   *
   * @type {string}
   * @memberof PaginatedVideoResourceList
   */
  previous?: string | null
  /**
   *
   * @type {Array<VideoResource>}
   * @memberof PaginatedVideoResourceList
   */
  results: Array<VideoResource>
}
/**
 * Serializer for LearningResourceInstructor model
 * @export
 * @interface PatchedArticleRequest
 */
export interface PatchedArticleRequest {
  /**
   *
   * @type {string}
   * @memberof PatchedArticleRequest
   */
  html?: string
  /**
   *
   * @type {string}
   * @memberof PatchedArticleRequest
   */
  title?: string
}
/**
 * Specialized serializer for a LearningPath relationship
 * @export
 * @interface PatchedLearningPathRelationshipRequest
 */
export interface PatchedLearningPathRelationshipRequest {
  /**
   *
   * @type {number}
   * @memberof PatchedLearningPathRelationshipRequest
   */
  position?: number
  /**
   *
   * @type {number}
   * @memberof PatchedLearningPathRelationshipRequest
   */
  parent?: number
  /**
   *
   * @type {number}
   * @memberof PatchedLearningPathRelationshipRequest
   */
  child?: number
}
/**
 * CRUD serializer for LearningPath resources
 * @export
 * @interface PatchedLearningPathResourceRequest
 */
export interface PatchedLearningPathResourceRequest {
  /**
   *
   * @type {Array<LearningResourceTopic>}
   * @memberof PatchedLearningPathResourceRequest
   */
  topics?: Array<LearningResourceTopic>
  /**
   *
   * @type {string}
   * @memberof PatchedLearningPathResourceRequest
   */
  title?: string
  /**
   *
   * @type {string}
   * @memberof PatchedLearningPathResourceRequest
   */
  description?: string | null
  /**
   *
   * @type {string}
   * @memberof PatchedLearningPathResourceRequest
   */
  full_description?: string | null
  /**
   *
   * @type {string}
   * @memberof PatchedLearningPathResourceRequest
   */
  last_modified?: string | null
  /**
   *
   * @type {boolean}
   * @memberof PatchedLearningPathResourceRequest
   */
  published?: boolean
  /**
   *
   * @type {Array<string>}
   * @memberof PatchedLearningPathResourceRequest
   */
  languages?: Array<string> | null
  /**
   *
   * @type {string}
   * @memberof PatchedLearningPathResourceRequest
   */
  url?: string | null
  /**
   *
   * @type {Array<string>}
   * @memberof PatchedLearningPathResourceRequest
   */
  ocw_topics?: Array<string>
  /**
   *
   * @type {boolean}
   * @memberof PatchedLearningPathResourceRequest
   */
  professional?: boolean
  /**
   *
   * @type {string}
   * @memberof PatchedLearningPathResourceRequest
   */
  next_start_date?: string | null
  /**
   *
   * @type {AvailabilityEnum}
   * @memberof PatchedLearningPathResourceRequest
   */
  availability?: AvailabilityEnum | null
  /**
   *
   * @type {number}
   * @memberof PatchedLearningPathResourceRequest
   */
  completeness?: number
  /**
   *
   * @type {boolean}
   * @memberof PatchedLearningPathResourceRequest
   */
  license_cc?: boolean
  /**
   *
   * @type {string}
   * @memberof PatchedLearningPathResourceRequest
   */
  continuing_ed_credits?: string | null
  /**
   *
   * @type {string}
   * @memberof PatchedLearningPathResourceRequest
   */
  location?: string
  /**
   *
   * @type {string}
   * @memberof PatchedLearningPathResourceRequest
   */
  duration?: string
  /**
   *
   * @type {number}
   * @memberof PatchedLearningPathResourceRequest
   */
  min_weeks?: number | null
  /**
   *
   * @type {number}
   * @memberof PatchedLearningPathResourceRequest
   */
  max_weeks?: number | null
  /**
   *
   * @type {string}
   * @memberof PatchedLearningPathResourceRequest
   */
  time_commitment?: string
  /**
   *
   * @type {number}
   * @memberof PatchedLearningPathResourceRequest
   */
  min_weekly_hours?: number | null
  /**
   *
   * @type {number}
   * @memberof PatchedLearningPathResourceRequest
   */
  max_weekly_hours?: number | null
}

/**
 * CRUD serializer for LearningResourceRelationship
 * @export
 * @interface PatchedLearningResourceRelationshipRequest
 */
export interface PatchedLearningResourceRelationshipRequest {
  /**
   *
   * @type {number}
   * @memberof PatchedLearningResourceRelationshipRequest
   */
  position?: number
  /**
   *
   * @type {RelationTypeEnum}
   * @memberof PatchedLearningResourceRelationshipRequest
   */
  relation_type?: RelationTypeEnum
  /**
   *
   * @type {number}
   * @memberof PatchedLearningResourceRelationshipRequest
   */
  parent?: number
  /**
   *
   * @type {number}
   * @memberof PatchedLearningResourceRelationshipRequest
   */
  child?: number
}

/**
 * Serializer for UserListRelationship model
 * @export
 * @interface PatchedUserListRelationshipRequest
 */
export interface PatchedUserListRelationshipRequest {
  /**
   *
   * @type {number}
   * @memberof PatchedUserListRelationshipRequest
   */
  position?: number
  /**
   *
   * @type {number}
   * @memberof PatchedUserListRelationshipRequest
   */
  parent?: number
  /**
   *
   * @type {number}
   * @memberof PatchedUserListRelationshipRequest
   */
  child?: number
}
/**
 * Simplified serializer for UserList model.
 * @export
 * @interface PatchedUserListRequest
 */
export interface PatchedUserListRequest {
  /**
   *
   * @type {Array<LearningResourceTopic>}
   * @memberof PatchedUserListRequest
   */
  topics?: Array<LearningResourceTopic>
  /**
   *
   * @type {string}
   * @memberof PatchedUserListRequest
   */
  title?: string
  /**
   *
   * @type {string}
   * @memberof PatchedUserListRequest
   */
  description?: string
  /**
   *
   * @type {PrivacyLevelEnum}
   * @memberof PatchedUserListRequest
   */
  privacy_level?: PrivacyLevelEnum
}

/**
 * Serializer for PercolateQuery objects
 * @export
 * @interface PercolateQuery
 */
export interface PercolateQuery {
  /**
   *
   * @type {number}
   * @memberof PercolateQuery
   */
  id: number
  /**
   *
   * @type {string}
   * @memberof PercolateQuery
   */
  source_description: string
  /**
   *
   * @type {string}
   * @memberof PercolateQuery
   */
  source_label: string
  /**
   *
   * @type {any}
   * @memberof PercolateQuery
   */
  original_query: any
  /**
   *
   * @type {any}
   * @memberof PercolateQuery
   */
  query: any
  /**
   *
   * @type {SourceTypeEnum}
   * @memberof PercolateQuery
   */
  source_type: SourceTypeEnum
  /**
   * Friendly display label for the query
   * @type {string}
   * @memberof PercolateQuery
   */
  display_label?: string
}

/**
 * PercolateQuerySubscriptionRequestSerializer with OpenAPI annotations for Percolate Subscription requests
 * @export
 * @interface PercolateQuerySubscriptionRequestRequest
 */
export interface PercolateQuerySubscriptionRequestRequest {
  /**
   * The search text
   * @type {string}
   * @memberof PercolateQuerySubscriptionRequestRequest
   */
  q?: string
  /**
   * The initial index from which to return the results
   * @type {number}
   * @memberof PercolateQuerySubscriptionRequestRequest
   */
  offset?: number
  /**
   * Number of results to return per page
   * @type {number}
   * @memberof PercolateQuerySubscriptionRequestRequest
   */
  limit?: number
  /**
   * The organization that offers the learning resource               * `mitx` - MITx * `ocw` - MIT OpenCourseWare * `bootcamps` - Bootcamps * `xpro` - MIT xPRO * `mitpe` - MIT Professional Education * `see` - MIT Sloan Executive Education
   * @type {Array<OfferedByEnum>}
   * @memberof PercolateQuerySubscriptionRequestRequest
   */
  offered_by?: Array<OfferedByEnum>
  /**
   * The platform on which the learning resource is offered               * `edx` - edX * `ocw` - MIT OpenCourseWare * `oll` - Open Learning Library * `mitxonline` - MITx Online * `bootcamps` - Bootcamps * `xpro` - MIT xPRO * `csail` - CSAIL * `mitpe` - MIT Professional Education * `see` - MIT Sloan Executive Education * `scc` - Schwarzman College of Computing * `ctl` - Center for Transportation & Logistics * `whu` - WHU * `susskind` - Susskind * `globalalumni` - Global Alumni * `simplilearn` - Simplilearn * `emeritus` - Emeritus * `podcast` - Podcast * `youtube` - YouTube
   * @type {Array<PlatformEnum>}
   * @memberof PercolateQuerySubscriptionRequestRequest
   */
  platform?: Array<PlatformEnum>
  /**
   * The topic name. To see a list of options go to api/v1/topics/
   * @type {Array<string>}
   * @memberof PercolateQuerySubscriptionRequestRequest
   */
  topic?: Array<string>
  /**
   * The ocw topic name.
   * @type {Array<string>}
   * @memberof PercolateQuerySubscriptionRequestRequest
   */
  ocw_topic?: Array<string>
  /**
   * If true return raw open search results with score explanations
   * @type {boolean}
   * @memberof PercolateQuerySubscriptionRequestRequest
   */
  dev_mode?: boolean | null
  /**
   * The id value for the learning resource
   * @type {Array<number>}
   * @memberof PercolateQuerySubscriptionRequestRequest
   */
  id?: Array<number>
  /**
   *
   * @type {SortbyEnum}
   * @memberof PercolateQuerySubscriptionRequestRequest
   */
  sortby?: SortbyEnum
  /**
   * The type of learning resource               * `course` - course * `program` - program * `learning_path` - learning path * `podcast` - podcast * `podcast_episode` - podcast episode * `video` - video * `video_playlist` - video playlist
   * @type {Array<PercolateQuerySubscriptionRequestResourceTypeEnum>}
   * @memberof PercolateQuerySubscriptionRequestRequest
   */
  resource_type?: Array<PercolateQuerySubscriptionRequestResourceTypeEnum>
  /**
   *
   * @type {boolean}
   * @memberof PercolateQuerySubscriptionRequestRequest
   */
  free?: boolean | null
  /**
   *
   * @type {boolean}
   * @memberof PercolateQuerySubscriptionRequestRequest
   */
  professional?: boolean | null
  /**
   * Relevance score penalty percent per year for for resources without upcoming runs. Only affects results if there is a search term.
   * @type {number}
   * @memberof PercolateQuerySubscriptionRequestRequest
   */
  yearly_decay_percent?: number | null
  /**
   * True if the learning resource offers a certificate
   * @type {boolean}
   * @memberof PercolateQuerySubscriptionRequestRequest
   */
  certification?: boolean | null
  /**
   * The type of certificate               * `micromasters` - MicroMasters Credential * `professional` - Professional Certificate * `completion` - Certificate of Completion * `none` - No Certificate
   * @type {Array<CertificationTypeEnum>}
   * @memberof PercolateQuerySubscriptionRequestRequest
   */
  certification_type?: Array<CertificationTypeEnum>
  /**
   * The department that offers the learning resource               * `1` - Civil and Environmental Engineering * `2` - Mechanical Engineering * `3` - Materials Science and Engineering * `4` - Architecture * `5` - Chemistry * `6` - Electrical Engineering and Computer Science * `7` - Biology * `8` - Physics * `9` - Brain and Cognitive Sciences * `10` - Chemical Engineering * `11` - Urban Studies and Planning * `12` - Earth, Atmospheric, and Planetary Sciences * `14` - Economics * `15` - Management * `16` - Aeronautics and Astronautics * `17` - Political Science * `18` - Mathematics * `20` - Biological Engineering * `21A` - Anthropology * `21G` - Global Languages * `21H` - History * `21L` - Literature * `21M` - Music and Theater Arts * `22` - Nuclear Science and Engineering * `24` - Linguistics and Philosophy * `CC` - Concourse * `CMS-W` - Comparative Media Studies/Writing * `EC` - Edgerton Center * `ES` - Experimental Study Group * `ESD` - Engineering Systems Division * `HST` - Medical Engineering and Science * `IDS` - Data, Systems, and Society * `MAS` - Media Arts and Sciences * `PE` - Athletics, Physical Education and Recreation * `SP` - Special Programs * `STS` - Science, Technology, and Society * `WGS` - Women\'s and Gender Studies
   * @type {Array<DepartmentEnum>}
   * @memberof PercolateQuerySubscriptionRequestRequest
   */
  department?: Array<DepartmentEnum>
  /**
   *
   * @type {Array<LevelEnum>}
   * @memberof PercolateQuerySubscriptionRequestRequest
   */
  level?: Array<LevelEnum>
  /**
   * The course feature. Possible options are at api/v1/course_features/
   * @type {Array<string>}
   * @memberof PercolateQuerySubscriptionRequestRequest
   */
  course_feature?: Array<string>
  /**
   * Show resource counts by category
   * @type {Array<AggregationsEnum>}
   * @memberof PercolateQuerySubscriptionRequestRequest
   */
  aggregations?: Array<AggregationsEnum>
  /**
   * The delivery options in which the learning resource is offered               * `online` - Online * `hybrid` - Hybrid * `in_person` - In person * `offline` - Offline
   * @type {Array<DeliveryEnum>}
   * @memberof PercolateQuerySubscriptionRequestRequest
   */
  delivery?: Array<DeliveryEnum>
  /**
   * The category of learning resource               * `course` - Course * `program` - Program * `learning_material` - Learning Material
   * @type {Array<ResourceCategoryEnum>}
   * @memberof PercolateQuerySubscriptionRequestRequest
   */
  resource_category?: Array<ResourceCategoryEnum>
  /**
   *
   * @type {SearchModeEnum}
   * @memberof PercolateQuerySubscriptionRequestRequest
   */
  search_mode?: SearchModeEnum
  /**
   * Allowed distance for phrase search
   * @type {number}
   * @memberof PercolateQuerySubscriptionRequestRequest
   */
  slop?: number | null
  /**
   * Minimum score value a text query result needs to have to be displayed
   * @type {number}
   * @memberof PercolateQuerySubscriptionRequestRequest
   */
  min_score?: number | null
  /**
   * Maximum score penalty for incomplete OCW courses in percent. An OCW course with completeness = 0 will have this score penalty. Partially complete courses have a linear penalty proportional to the degree of incompleteness. Only affects results if there is a search term.
   * @type {number}
   * @memberof PercolateQuerySubscriptionRequestRequest
   */
  max_incompleteness_penalty?: number | null
  /**
   * Score weight for content file data.  1 is the default. 0 means content files are ignored
   * @type {number}
   * @memberof PercolateQuerySubscriptionRequestRequest
   */
  content_file_score_weight?: number | null
  /**
   *
   * @type {SourceTypeEnum}
   * @memberof PercolateQuerySubscriptionRequestRequest
   */
  source_type?: SourceTypeEnum
}

/**
 * * `course` - course * `program` - program * `learning_path` - learning path * `podcast` - podcast * `podcast_episode` - podcast episode * `video` - video * `video_playlist` - video playlist
 * @export
 * @enum {string}
 */

export const PercolateQuerySubscriptionRequestResourceTypeEnumDescriptions = {
  course: "course",
  program: "program",
  learning_path: "learning path",
  podcast: "podcast",
  podcast_episode: "podcast episode",
  video: "video",
  video_playlist: "video playlist",
} as const

export const PercolateQuerySubscriptionRequestResourceTypeEnum = {
  /**
   * course
   */
  Course: "course",
  /**
   * program
   */
  Program: "program",
  /**
   * learning path
   */
  LearningPath: "learning_path",
  /**
   * podcast
   */
  Podcast: "podcast",
  /**
   * podcast episode
   */
  PodcastEpisode: "podcast_episode",
  /**
   * video
   */
  Video: "video",
  /**
   * video playlist
   */
  VideoPlaylist: "video_playlist",
} as const

export type PercolateQuerySubscriptionRequestResourceTypeEnum =
  (typeof PercolateQuerySubscriptionRequestResourceTypeEnum)[keyof typeof PercolateQuerySubscriptionRequestResourceTypeEnum]

/**
 * * `edx` - edX * `ocw` - MIT OpenCourseWare * `oll` - Open Learning Library * `mitxonline` - MITx Online * `bootcamps` - Bootcamps * `xpro` - MIT xPRO * `csail` - CSAIL * `mitpe` - MIT Professional Education * `see` - MIT Sloan Executive Education * `scc` - Schwarzman College of Computing * `ctl` - Center for Transportation & Logistics * `whu` - WHU * `susskind` - Susskind * `globalalumni` - Global Alumni * `simplilearn` - Simplilearn * `emeritus` - Emeritus * `podcast` - Podcast * `youtube` - YouTube
 * @export
 * @enum {string}
 */

export const PlatformEnumDescriptions = {
  edx: "edX",
  ocw: "MIT OpenCourseWare",
  oll: "Open Learning Library",
  mitxonline: "MITx Online",
  bootcamps: "Bootcamps",
  xpro: "MIT xPRO",
  csail: "CSAIL",
  mitpe: "MIT Professional Education",
  see: "MIT Sloan Executive Education",
  scc: "Schwarzman College of Computing",
  ctl: "Center for Transportation & Logistics",
  whu: "WHU",
  susskind: "Susskind",
  globalalumni: "Global Alumni",
  simplilearn: "Simplilearn",
  emeritus: "Emeritus",
  podcast: "Podcast",
  youtube: "YouTube",
} as const

export const PlatformEnum = {
  /**
   * edX
   */
  Edx: "edx",
  /**
   * MIT OpenCourseWare
   */
  Ocw: "ocw",
  /**
   * Open Learning Library
   */
  Oll: "oll",
  /**
   * MITx Online
   */
  Mitxonline: "mitxonline",
  /**
   * Bootcamps
   */
  Bootcamps: "bootcamps",
  /**
   * MIT xPRO
   */
  Xpro: "xpro",
  /**
   * CSAIL
   */
  Csail: "csail",
  /**
   * MIT Professional Education
   */
  Mitpe: "mitpe",
  /**
   * MIT Sloan Executive Education
   */
  See: "see",
  /**
   * Schwarzman College of Computing
   */
  Scc: "scc",
  /**
   * Center for Transportation &amp; Logistics
   */
  Ctl: "ctl",
  /**
   * WHU
   */
  Whu: "whu",
  /**
   * Susskind
   */
  Susskind: "susskind",
  /**
   * Global Alumni
   */
  Globalalumni: "globalalumni",
  /**
   * Simplilearn
   */
  Simplilearn: "simplilearn",
  /**
   * Emeritus
   */
  Emeritus: "emeritus",
  /**
   * Podcast
   */
  Podcast: "podcast",
  /**
   * YouTube
   */
  Youtube: "youtube",
} as const

export type PlatformEnum = (typeof PlatformEnum)[keyof typeof PlatformEnum]

/**
 * Serializer for Podcasts
 * @export
 * @interface Podcast
 */
export interface Podcast {
  /**
   *
   * @type {number}
   * @memberof Podcast
   */
  id: number
  /**
   *
   * @type {number}
   * @memberof Podcast
   */
  episode_count: number
  /**
   *
   * @type {string}
   * @memberof Podcast
   */
  apple_podcasts_url?: string | null
  /**
   *
   * @type {string}
   * @memberof Podcast
   */
  google_podcasts_url?: string | null
  /**
   *
   * @type {string}
   * @memberof Podcast
   */
  rss_url?: string | null
}
/**
 * Serializer for PodcastEpisode
 * @export
 * @interface PodcastEpisode
 */
export interface PodcastEpisode {
  /**
   *
   * @type {number}
   * @memberof PodcastEpisode
   */
  id: number
  /**
   * Get the podcast id(s) the episode belongs to
   * @type {Array<string>}
   * @memberof PodcastEpisode
   */
  podcasts: Array<string>
  /**
   *
   * @type {string}
   * @memberof PodcastEpisode
   */
  transcript?: string
  /**
   *
   * @type {string}
   * @memberof PodcastEpisode
   */
  audio_url: string
  /**
   *
   * @type {string}
   * @memberof PodcastEpisode
   */
  episode_link?: string | null
  /**
   *
   * @type {string}
   * @memberof PodcastEpisode
   */
  duration?: string | null
  /**
   *
   * @type {string}
   * @memberof PodcastEpisode
   */
  rss?: string | null
}
/**
 * Serializer for PodcastEpisode
 * @export
 * @interface PodcastEpisodeRequest
 */
export interface PodcastEpisodeRequest {
  /**
   *
   * @type {string}
   * @memberof PodcastEpisodeRequest
   */
  transcript?: string
  /**
   *
   * @type {string}
   * @memberof PodcastEpisodeRequest
   */
  audio_url: string
  /**
   *
   * @type {string}
   * @memberof PodcastEpisodeRequest
   */
  episode_link?: string | null
  /**
   *
   * @type {string}
   * @memberof PodcastEpisodeRequest
   */
  duration?: string | null
  /**
   *
   * @type {string}
   * @memberof PodcastEpisodeRequest
   */
  rss?: string | null
}
/**
 * Serializer for podcast episode resources
 * @export
 * @interface PodcastEpisodeResource
 */
export interface PodcastEpisodeResource {
  /**
   *
   * @type {number}
   * @memberof PodcastEpisodeResource
   */
  id: number
  /**
   *
   * @type {Array<LearningResourceTopic>}
   * @memberof PodcastEpisodeResource
   */
  topics?: Array<LearningResourceTopic>
  /**
   *
   * @type {number}
   * @memberof PodcastEpisodeResource
   */
  position: number | null
  /**
   *
   * @type {LearningResourceOfferor}
   * @memberof PodcastEpisodeResource
   */
  offered_by: LearningResourceOfferor | null
  /**
   *
   * @type {LearningResourcePlatform}
   * @memberof PodcastEpisodeResource
   */
  platform: LearningResourcePlatform | null
  /**
   *
   * @type {Array<string>}
   * @memberof PodcastEpisodeResource
   */
  course_feature: Array<string> | null
  /**
   *
   * @type {Array<LearningResourceDepartment>}
   * @memberof PodcastEpisodeResource
   */
  departments: Array<LearningResourceDepartment> | null
  /**
   *
   * @type {boolean}
   * @memberof PodcastEpisodeResource
   */
  certification: boolean
  /**
   *
   * @type {CourseResourceCertificationType}
   * @memberof PodcastEpisodeResource
   */
  certification_type: CourseResourceCertificationType
  /**
   *
   * @type {Array<string>}
   * @memberof PodcastEpisodeResource
   */
  prices: Array<string>
  /**
   *
   * @type {Array<LearningResourcePrice>}
   * @memberof PodcastEpisodeResource
   */
  resource_prices: Array<LearningResourcePrice>
  /**
   *
   * @type {Array<LearningResourceRun>}
   * @memberof PodcastEpisodeResource
   */
  runs: Array<LearningResourceRun> | null
  /**
   *
   * @type {LearningResourceImage}
   * @memberof PodcastEpisodeResource
   */
  image: LearningResourceImage | null
  /**
   *
   * @type {Array<MicroLearningPathRelationship>}
   * @memberof PodcastEpisodeResource
   */
  learning_path_parents: Array<MicroLearningPathRelationship>
  /**
   *
   * @type {Array<MicroUserListRelationship>}
   * @memberof PodcastEpisodeResource
   */
  user_list_parents: Array<MicroUserListRelationship>
  /**
   *
   * @type {number}
   * @memberof PodcastEpisodeResource
   */
  views: number
  /**
   *
   * @type {Array<CourseResourceDeliveryInner>}
   * @memberof PodcastEpisodeResource
   */
  delivery: Array<CourseResourceDeliveryInner>
  /**
   * Return true if the resource is free/has a free option
   * @type {boolean}
   * @memberof PodcastEpisodeResource
   */
  free: boolean
  /**
   * Return the resource category of the resource
   * @type {string}
   * @memberof PodcastEpisodeResource
   */
  resource_category: string
  /**
   *
   * @type {Array<CourseResourceFormatInner>}
   * @memberof PodcastEpisodeResource
   */
  format: Array<CourseResourceFormatInner>
  /**
   *
   * @type {Array<CourseResourcePaceInner>}
   * @memberof PodcastEpisodeResource
   */
  pace: Array<CourseResourcePaceInner>
  /**
   *
   * @type {PodcastEpisodeResourceResourceTypeEnum}
   * @memberof PodcastEpisodeResource
   */
  resource_type: PodcastEpisodeResourceResourceTypeEnum
  /**
   *
   * @type {PodcastEpisode}
   * @memberof PodcastEpisodeResource
   */
  podcast_episode: PodcastEpisode
  /**
   *
   * @type {string}
   * @memberof PodcastEpisodeResource
   */
  readable_id: string
  /**
   *
   * @type {string}
   * @memberof PodcastEpisodeResource
   */
  title: string
  /**
   *
   * @type {string}
   * @memberof PodcastEpisodeResource
   */
  description?: string | null
  /**
   *
   * @type {string}
   * @memberof PodcastEpisodeResource
   */
  full_description?: string | null
  /**
   *
   * @type {string}
   * @memberof PodcastEpisodeResource
   */
  last_modified?: string | null
  /**
   *
   * @type {boolean}
   * @memberof PodcastEpisodeResource
   */
  published?: boolean
  /**
   *
   * @type {Array<string>}
   * @memberof PodcastEpisodeResource
   */
  languages?: Array<string> | null
  /**
   *
   * @type {string}
   * @memberof PodcastEpisodeResource
   */
  url?: string | null
  /**
   *
   * @type {Array<string>}
   * @memberof PodcastEpisodeResource
   */
  ocw_topics?: Array<string>
  /**
   *
   * @type {boolean}
   * @memberof PodcastEpisodeResource
   */
  professional: boolean
  /**
   *
   * @type {string}
   * @memberof PodcastEpisodeResource
   */
  next_start_date?: string | null
  /**
   *
   * @type {AvailabilityEnum}
   * @memberof PodcastEpisodeResource
   */
  availability?: AvailabilityEnum | null
  /**
   *
   * @type {number}
   * @memberof PodcastEpisodeResource
   */
  completeness?: number
  /**
   *
   * @type {boolean}
   * @memberof PodcastEpisodeResource
   */
  license_cc?: boolean
  /**
   *
   * @type {string}
   * @memberof PodcastEpisodeResource
   */
  continuing_ed_credits?: string | null
  /**
   *
   * @type {string}
   * @memberof PodcastEpisodeResource
   */
  location?: string
  /**
   *
   * @type {string}
   * @memberof PodcastEpisodeResource
   */
  duration?: string
  /**
   *
   * @type {number}
   * @memberof PodcastEpisodeResource
   */
  min_weeks?: number | null
  /**
   *
   * @type {number}
   * @memberof PodcastEpisodeResource
   */
  max_weeks?: number | null
  /**
   *
   * @type {string}
   * @memberof PodcastEpisodeResource
   */
  time_commitment?: string
  /**
   *
   * @type {number}
   * @memberof PodcastEpisodeResource
   */
  min_weekly_hours?: number | null
  /**
   *
   * @type {number}
   * @memberof PodcastEpisodeResource
   */
  max_weekly_hours?: number | null
}

/**
 * Serializer for podcast episode resources
 * @export
 * @interface PodcastEpisodeResourceRequest
 */
export interface PodcastEpisodeResourceRequest {
  /**
   *
   * @type {Array<LearningResourceTopic>}
   * @memberof PodcastEpisodeResourceRequest
   */
  topics?: Array<LearningResourceTopic>
  /**
   *
   * @type {string}
   * @memberof PodcastEpisodeResourceRequest
   */
  readable_id: string
  /**
   *
   * @type {string}
   * @memberof PodcastEpisodeResourceRequest
   */
  title: string
  /**
   *
   * @type {string}
   * @memberof PodcastEpisodeResourceRequest
   */
  description?: string | null
  /**
   *
   * @type {string}
   * @memberof PodcastEpisodeResourceRequest
   */
  full_description?: string | null
  /**
   *
   * @type {string}
   * @memberof PodcastEpisodeResourceRequest
   */
  last_modified?: string | null
  /**
   *
   * @type {boolean}
   * @memberof PodcastEpisodeResourceRequest
   */
  published?: boolean
  /**
   *
   * @type {Array<string>}
   * @memberof PodcastEpisodeResourceRequest
   */
  languages?: Array<string> | null
  /**
   *
   * @type {string}
   * @memberof PodcastEpisodeResourceRequest
   */
  url?: string | null
  /**
   *
   * @type {Array<string>}
   * @memberof PodcastEpisodeResourceRequest
   */
  ocw_topics?: Array<string>
  /**
   *
   * @type {string}
   * @memberof PodcastEpisodeResourceRequest
   */
  next_start_date?: string | null
  /**
   *
   * @type {AvailabilityEnum}
   * @memberof PodcastEpisodeResourceRequest
   */
  availability?: AvailabilityEnum | null
  /**
   *
   * @type {number}
   * @memberof PodcastEpisodeResourceRequest
   */
  completeness?: number
  /**
   *
   * @type {boolean}
   * @memberof PodcastEpisodeResourceRequest
   */
  license_cc?: boolean
  /**
   *
   * @type {string}
   * @memberof PodcastEpisodeResourceRequest
   */
  continuing_ed_credits?: string | null
  /**
   *
   * @type {string}
   * @memberof PodcastEpisodeResourceRequest
   */
  location?: string
  /**
   *
   * @type {string}
   * @memberof PodcastEpisodeResourceRequest
   */
  duration?: string
  /**
   *
   * @type {number}
   * @memberof PodcastEpisodeResourceRequest
   */
  min_weeks?: number | null
  /**
   *
   * @type {number}
   * @memberof PodcastEpisodeResourceRequest
   */
  max_weeks?: number | null
  /**
   *
   * @type {string}
   * @memberof PodcastEpisodeResourceRequest
   */
  time_commitment?: string
  /**
   *
   * @type {number}
   * @memberof PodcastEpisodeResourceRequest
   */
  min_weekly_hours?: number | null
  /**
   *
   * @type {number}
   * @memberof PodcastEpisodeResourceRequest
   */
  max_weekly_hours?: number | null
}

/**
 *
 * @export
 * @enum {string}
 */

export const PodcastEpisodeResourceResourceTypeEnumDescriptions = {
  podcast_episode: "",
} as const

export const PodcastEpisodeResourceResourceTypeEnum = {
  PodcastEpisode: "podcast_episode",
} as const

export type PodcastEpisodeResourceResourceTypeEnum =
  (typeof PodcastEpisodeResourceResourceTypeEnum)[keyof typeof PodcastEpisodeResourceResourceTypeEnum]

/**
 * Serializer for Podcasts
 * @export
 * @interface PodcastRequest
 */
export interface PodcastRequest {
  /**
   *
   * @type {number}
   * @memberof PodcastRequest
   */
  episode_count: number
  /**
   *
   * @type {string}
   * @memberof PodcastRequest
   */
  apple_podcasts_url?: string | null
  /**
   *
   * @type {string}
   * @memberof PodcastRequest
   */
  google_podcasts_url?: string | null
  /**
   *
   * @type {string}
   * @memberof PodcastRequest
   */
  rss_url?: string | null
}
/**
 * Serializer for podcast resources
 * @export
 * @interface PodcastResource
 */
export interface PodcastResource {
  /**
   *
   * @type {number}
   * @memberof PodcastResource
   */
  id: number
  /**
   *
   * @type {Array<LearningResourceTopic>}
   * @memberof PodcastResource
   */
  topics?: Array<LearningResourceTopic>
  /**
   *
   * @type {number}
   * @memberof PodcastResource
   */
  position: number | null
  /**
   *
   * @type {LearningResourceOfferor}
   * @memberof PodcastResource
   */
  offered_by: LearningResourceOfferor | null
  /**
   *
   * @type {LearningResourcePlatform}
   * @memberof PodcastResource
   */
  platform: LearningResourcePlatform | null
  /**
   *
   * @type {Array<string>}
   * @memberof PodcastResource
   */
  course_feature: Array<string> | null
  /**
   *
   * @type {Array<LearningResourceDepartment>}
   * @memberof PodcastResource
   */
  departments: Array<LearningResourceDepartment> | null
  /**
   *
   * @type {boolean}
   * @memberof PodcastResource
   */
  certification: boolean
  /**
   *
   * @type {CourseResourceCertificationType}
   * @memberof PodcastResource
   */
  certification_type: CourseResourceCertificationType
  /**
   *
   * @type {Array<string>}
   * @memberof PodcastResource
   */
  prices: Array<string>
  /**
   *
   * @type {Array<LearningResourcePrice>}
   * @memberof PodcastResource
   */
  resource_prices: Array<LearningResourcePrice>
  /**
   *
   * @type {Array<LearningResourceRun>}
   * @memberof PodcastResource
   */
  runs: Array<LearningResourceRun> | null
  /**
   *
   * @type {LearningResourceImage}
   * @memberof PodcastResource
   */
  image: LearningResourceImage | null
  /**
   *
   * @type {Array<MicroLearningPathRelationship>}
   * @memberof PodcastResource
   */
  learning_path_parents: Array<MicroLearningPathRelationship>
  /**
   *
   * @type {Array<MicroUserListRelationship>}
   * @memberof PodcastResource
   */
  user_list_parents: Array<MicroUserListRelationship>
  /**
   *
   * @type {number}
   * @memberof PodcastResource
   */
  views: number
  /**
   *
   * @type {Array<CourseResourceDeliveryInner>}
   * @memberof PodcastResource
   */
  delivery: Array<CourseResourceDeliveryInner>
  /**
   * Return true if the resource is free/has a free option
   * @type {boolean}
   * @memberof PodcastResource
   */
  free: boolean
  /**
   * Return the resource category of the resource
   * @type {string}
   * @memberof PodcastResource
   */
  resource_category: string
  /**
   *
   * @type {Array<CourseResourceFormatInner>}
   * @memberof PodcastResource
   */
  format: Array<CourseResourceFormatInner>
  /**
   *
   * @type {Array<CourseResourcePaceInner>}
   * @memberof PodcastResource
   */
  pace: Array<CourseResourcePaceInner>
  /**
   *
   * @type {PodcastResourceResourceTypeEnum}
   * @memberof PodcastResource
   */
  resource_type: PodcastResourceResourceTypeEnum
  /**
   *
   * @type {Podcast}
   * @memberof PodcastResource
   */
  podcast: Podcast
  /**
   *
   * @type {string}
   * @memberof PodcastResource
   */
  readable_id: string
  /**
   *
   * @type {string}
   * @memberof PodcastResource
   */
  title: string
  /**
   *
   * @type {string}
   * @memberof PodcastResource
   */
  description?: string | null
  /**
   *
   * @type {string}
   * @memberof PodcastResource
   */
  full_description?: string | null
  /**
   *
   * @type {string}
   * @memberof PodcastResource
   */
  last_modified?: string | null
  /**
   *
   * @type {boolean}
   * @memberof PodcastResource
   */
  published?: boolean
  /**
   *
   * @type {Array<string>}
   * @memberof PodcastResource
   */
  languages?: Array<string> | null
  /**
   *
   * @type {string}
   * @memberof PodcastResource
   */
  url?: string | null
  /**
   *
   * @type {Array<string>}
   * @memberof PodcastResource
   */
  ocw_topics?: Array<string>
  /**
   *
   * @type {boolean}
   * @memberof PodcastResource
   */
  professional: boolean
  /**
   *
   * @type {string}
   * @memberof PodcastResource
   */
  next_start_date?: string | null
  /**
   *
   * @type {AvailabilityEnum}
   * @memberof PodcastResource
   */
  availability?: AvailabilityEnum | null
  /**
   *
   * @type {number}
   * @memberof PodcastResource
   */
  completeness?: number
  /**
   *
   * @type {boolean}
   * @memberof PodcastResource
   */
  license_cc?: boolean
  /**
   *
   * @type {string}
   * @memberof PodcastResource
   */
  continuing_ed_credits?: string | null
  /**
   *
   * @type {string}
   * @memberof PodcastResource
   */
  location?: string
  /**
   *
   * @type {string}
   * @memberof PodcastResource
   */
  duration?: string
  /**
   *
   * @type {number}
   * @memberof PodcastResource
   */
  min_weeks?: number | null
  /**
   *
   * @type {number}
   * @memberof PodcastResource
   */
  max_weeks?: number | null
  /**
   *
   * @type {string}
   * @memberof PodcastResource
   */
  time_commitment?: string
  /**
   *
   * @type {number}
   * @memberof PodcastResource
   */
  min_weekly_hours?: number | null
  /**
   *
   * @type {number}
   * @memberof PodcastResource
   */
  max_weekly_hours?: number | null
}

/**
 * Serializer for podcast resources
 * @export
 * @interface PodcastResourceRequest
 */
export interface PodcastResourceRequest {
  /**
   *
   * @type {Array<LearningResourceTopic>}
   * @memberof PodcastResourceRequest
   */
  topics?: Array<LearningResourceTopic>
  /**
   *
   * @type {string}
   * @memberof PodcastResourceRequest
   */
  readable_id: string
  /**
   *
   * @type {string}
   * @memberof PodcastResourceRequest
   */
  title: string
  /**
   *
   * @type {string}
   * @memberof PodcastResourceRequest
   */
  description?: string | null
  /**
   *
   * @type {string}
   * @memberof PodcastResourceRequest
   */
  full_description?: string | null
  /**
   *
   * @type {string}
   * @memberof PodcastResourceRequest
   */
  last_modified?: string | null
  /**
   *
   * @type {boolean}
   * @memberof PodcastResourceRequest
   */
  published?: boolean
  /**
   *
   * @type {Array<string>}
   * @memberof PodcastResourceRequest
   */
  languages?: Array<string> | null
  /**
   *
   * @type {string}
   * @memberof PodcastResourceRequest
   */
  url?: string | null
  /**
   *
   * @type {Array<string>}
   * @memberof PodcastResourceRequest
   */
  ocw_topics?: Array<string>
  /**
   *
   * @type {string}
   * @memberof PodcastResourceRequest
   */
  next_start_date?: string | null
  /**
   *
   * @type {AvailabilityEnum}
   * @memberof PodcastResourceRequest
   */
  availability?: AvailabilityEnum | null
  /**
   *
   * @type {number}
   * @memberof PodcastResourceRequest
   */
  completeness?: number
  /**
   *
   * @type {boolean}
   * @memberof PodcastResourceRequest
   */
  license_cc?: boolean
  /**
   *
   * @type {string}
   * @memberof PodcastResourceRequest
   */
  continuing_ed_credits?: string | null
  /**
   *
   * @type {string}
   * @memberof PodcastResourceRequest
   */
  location?: string
  /**
   *
   * @type {string}
   * @memberof PodcastResourceRequest
   */
  duration?: string
  /**
   *
   * @type {number}
   * @memberof PodcastResourceRequest
   */
  min_weeks?: number | null
  /**
   *
   * @type {number}
   * @memberof PodcastResourceRequest
   */
  max_weeks?: number | null
  /**
   *
   * @type {string}
   * @memberof PodcastResourceRequest
   */
  time_commitment?: string
  /**
   *
   * @type {number}
   * @memberof PodcastResourceRequest
   */
  min_weekly_hours?: number | null
  /**
   *
   * @type {number}
   * @memberof PodcastResourceRequest
   */
  max_weekly_hours?: number | null
}

/**
 *
 * @export
 * @enum {string}
 */

export const PodcastResourceResourceTypeEnumDescriptions = {
  podcast: "",
} as const

export const PodcastResourceResourceTypeEnum = {
  Podcast: "podcast",
} as const

export type PodcastResourceResourceTypeEnum =
  (typeof PodcastResourceResourceTypeEnum)[keyof typeof PodcastResourceResourceTypeEnum]

/**
 * * `private` - private * `unlisted` - unlisted
 * @export
 * @enum {string}
 */

export const PrivacyLevelEnumDescriptions = {
  private: "private",
  unlisted: "unlisted",
} as const

export const PrivacyLevelEnum = {
  /**
   * private
   */
  Private: "private",
  /**
   * unlisted
   */
  Unlisted: "unlisted",
} as const

export type PrivacyLevelEnum =
  (typeof PrivacyLevelEnum)[keyof typeof PrivacyLevelEnum]

/**
 * Serializer for the Program model
 * @export
 * @interface Program
 */
export interface Program {
  /**
   *
   * @type {number}
   * @memberof Program
   */
  course_count: number
}
/**
 * Serializer for Program Certificates
 * @export
 * @interface ProgramCertificate
 */
export interface ProgramCertificate {
  /**
   *
   * @type {string}
   * @memberof ProgramCertificate
   */
  record_hash: string
  /**
   *
   * @type {string}
   * @memberof ProgramCertificate
   */
  program_letter_generate_url: string
  /**
   *
   * @type {string}
   * @memberof ProgramCertificate
   */
  program_letter_share_url: string
  /**
   *
   * @type {string}
   * @memberof ProgramCertificate
   */
  program_title: string
  /**
   *
   * @type {string}
   * @memberof ProgramCertificate
   */
  user_full_name?: string
  /**
   *
   * @type {string}
   * @memberof ProgramCertificate
   */
  user_email: string
  /**
   *
   * @type {number}
   * @memberof ProgramCertificate
   */
  user_edxorg_id?: number | null
  /**
   *
   * @type {number}
   * @memberof ProgramCertificate
   */
  micromasters_program_id?: number | null
  /**
   *
   * @type {number}
   * @memberof ProgramCertificate
   */
  mitxonline_program_id?: number | null
  /**
   *
   * @type {string}
   * @memberof ProgramCertificate
   */
  user_edxorg_username?: string | null
  /**
   *
   * @type {string}
   * @memberof ProgramCertificate
   */
  user_gender?: string | null
  /**
   *
   * @type {string}
   * @memberof ProgramCertificate
   */
  user_address_city?: string | null
  /**
   *
   * @type {string}
   * @memberof ProgramCertificate
   */
  user_first_name?: string | null
  /**
   *
   * @type {string}
   * @memberof ProgramCertificate
   */
  user_last_name?: string | null
  /**
   *
   * @type {string}
   * @memberof ProgramCertificate
   */
  user_year_of_birth?: string | null
  /**
   *
   * @type {string}
   * @memberof ProgramCertificate
   */
  user_country?: string | null
  /**
   *
   * @type {string}
   * @memberof ProgramCertificate
   */
  user_address_postal_code?: string | null
  /**
   *
   * @type {string}
   * @memberof ProgramCertificate
   */
  user_street_address?: string | null
  /**
   *
   * @type {string}
   * @memberof ProgramCertificate
   */
  user_address_state_or_territory?: string | null
  /**
   *
   * @type {string}
   * @memberof ProgramCertificate
   */
  user_mitxonline_username?: string | null
  /**
   *
   * @type {string}
   * @memberof ProgramCertificate
   */
  program_completion_timestamp?: string | null
}
/**
 * Serializer for Program Letters
 * @export
 * @interface ProgramLetter
 */
export interface ProgramLetter {
  /**
   *
   * @type {string}
   * @memberof ProgramLetter
   */
  id: string
  /**
   *
   * @type {ProgramLetterTemplateField}
   * @memberof ProgramLetter
   */
  template_fields: ProgramLetterTemplateField
  /**
   *
   * @type {ProgramCertificate}
   * @memberof ProgramLetter
   */
  certificate: ProgramCertificate
}
/**
 * Seriializer for program letter template data which is configured in micromasters
 * @export
 * @interface ProgramLetterTemplateField
 */
export interface ProgramLetterTemplateField {
  /**
   *
   * @type {number}
   * @memberof ProgramLetterTemplateField
   */
  id: number
  /**
   *
   * @type {any}
   * @memberof ProgramLetterTemplateField
   */
  meta: any
  /**
   *
   * @type {string}
   * @memberof ProgramLetterTemplateField
   */
  title: string
  /**
   *
   * @type {number}
   * @memberof ProgramLetterTemplateField
   */
  program_id: number
  /**
   *
   * @type {any}
   * @memberof ProgramLetterTemplateField
   */
  program_letter_footer: any
  /**
   *
   * @type {string}
   * @memberof ProgramLetterTemplateField
   */
  program_letter_footer_text: string
  /**
   *
   * @type {string}
   * @memberof ProgramLetterTemplateField
   */
  program_letter_header_text: string
  /**
   *
   * @type {string}
   * @memberof ProgramLetterTemplateField
   */
  program_letter_text: string
  /**
   *
   * @type {any}
   * @memberof ProgramLetterTemplateField
   */
  program_letter_logo: any
  /**
   *
   * @type {Array<any>}
   * @memberof ProgramLetterTemplateField
   */
  program_letter_signatories: Array<any>
}
/**
 * Serializer for program resources
 * @export
 * @interface ProgramResource
 */
export interface ProgramResource {
  /**
   *
   * @type {number}
   * @memberof ProgramResource
   */
  id: number
  /**
   *
   * @type {Array<LearningResourceTopic>}
   * @memberof ProgramResource
   */
  topics?: Array<LearningResourceTopic>
  /**
   *
   * @type {number}
   * @memberof ProgramResource
   */
  position: number | null
  /**
   *
   * @type {LearningResourceOfferor}
   * @memberof ProgramResource
   */
  offered_by: LearningResourceOfferor | null
  /**
   *
   * @type {LearningResourcePlatform}
   * @memberof ProgramResource
   */
  platform: LearningResourcePlatform | null
  /**
   *
   * @type {Array<string>}
   * @memberof ProgramResource
   */
  course_feature: Array<string> | null
  /**
   *
   * @type {Array<LearningResourceDepartment>}
   * @memberof ProgramResource
   */
  departments: Array<LearningResourceDepartment> | null
  /**
   *
   * @type {boolean}
   * @memberof ProgramResource
   */
  certification: boolean
  /**
   *
   * @type {CourseResourceCertificationType}
   * @memberof ProgramResource
   */
  certification_type: CourseResourceCertificationType
  /**
   *
   * @type {Array<string>}
   * @memberof ProgramResource
   */
  prices: Array<string>
  /**
   *
   * @type {Array<LearningResourcePrice>}
   * @memberof ProgramResource
   */
  resource_prices: Array<LearningResourcePrice>
  /**
   *
   * @type {Array<LearningResourceRun>}
   * @memberof ProgramResource
   */
  runs: Array<LearningResourceRun> | null
  /**
   *
   * @type {LearningResourceImage}
   * @memberof ProgramResource
   */
  image: LearningResourceImage | null
  /**
   *
   * @type {Array<MicroLearningPathRelationship>}
   * @memberof ProgramResource
   */
  learning_path_parents: Array<MicroLearningPathRelationship>
  /**
   *
   * @type {Array<MicroUserListRelationship>}
   * @memberof ProgramResource
   */
  user_list_parents: Array<MicroUserListRelationship>
  /**
   *
   * @type {number}
   * @memberof ProgramResource
   */
  views: number
  /**
   *
   * @type {Array<CourseResourceDeliveryInner>}
   * @memberof ProgramResource
   */
  delivery: Array<CourseResourceDeliveryInner>
  /**
   * Return true if the resource is free/has a free option
   * @type {boolean}
   * @memberof ProgramResource
   */
  free: boolean
  /**
   * Return the resource category of the resource
   * @type {string}
   * @memberof ProgramResource
   */
  resource_category: string
  /**
   *
   * @type {Array<CourseResourceFormatInner>}
   * @memberof ProgramResource
   */
  format: Array<CourseResourceFormatInner>
  /**
   *
   * @type {Array<CourseResourcePaceInner>}
   * @memberof ProgramResource
   */
  pace: Array<CourseResourcePaceInner>
  /**
   *
   * @type {ProgramResourceResourceTypeEnum}
   * @memberof ProgramResource
   */
  resource_type: ProgramResourceResourceTypeEnum
  /**
   *
   * @type {Program}
   * @memberof ProgramResource
   */
  program: Program
  /**
   *
   * @type {string}
   * @memberof ProgramResource
   */
  readable_id: string
  /**
   *
   * @type {string}
   * @memberof ProgramResource
   */
  title: string
  /**
   *
   * @type {string}
   * @memberof ProgramResource
   */
  description?: string | null
  /**
   *
   * @type {string}
   * @memberof ProgramResource
   */
  full_description?: string | null
  /**
   *
   * @type {string}
   * @memberof ProgramResource
   */
  last_modified?: string | null
  /**
   *
   * @type {boolean}
   * @memberof ProgramResource
   */
  published?: boolean
  /**
   *
   * @type {Array<string>}
   * @memberof ProgramResource
   */
  languages?: Array<string> | null
  /**
   *
   * @type {string}
   * @memberof ProgramResource
   */
  url?: string | null
  /**
   *
   * @type {Array<string>}
   * @memberof ProgramResource
   */
  ocw_topics?: Array<string>
  /**
   *
   * @type {boolean}
   * @memberof ProgramResource
   */
  professional: boolean
  /**
   *
   * @type {string}
   * @memberof ProgramResource
   */
  next_start_date?: string | null
  /**
   *
   * @type {AvailabilityEnum}
   * @memberof ProgramResource
   */
  availability?: AvailabilityEnum | null
  /**
   *
   * @type {number}
   * @memberof ProgramResource
   */
  completeness?: number
  /**
   *
   * @type {boolean}
   * @memberof ProgramResource
   */
  license_cc?: boolean
  /**
   *
   * @type {string}
   * @memberof ProgramResource
   */
  continuing_ed_credits?: string | null
  /**
   *
   * @type {string}
   * @memberof ProgramResource
   */
  location?: string
  /**
   *
   * @type {string}
   * @memberof ProgramResource
   */
  duration?: string
  /**
   *
   * @type {number}
   * @memberof ProgramResource
   */
  min_weeks?: number | null
  /**
   *
   * @type {number}
   * @memberof ProgramResource
   */
  max_weeks?: number | null
  /**
   *
   * @type {string}
   * @memberof ProgramResource
   */
  time_commitment?: string
  /**
   *
   * @type {number}
   * @memberof ProgramResource
   */
  min_weekly_hours?: number | null
  /**
   *
   * @type {number}
   * @memberof ProgramResource
   */
  max_weekly_hours?: number | null
}

/**
 * Serializer for program resources
 * @export
 * @interface ProgramResourceRequest
 */
export interface ProgramResourceRequest {
  /**
   *
   * @type {Array<LearningResourceTopic>}
   * @memberof ProgramResourceRequest
   */
  topics?: Array<LearningResourceTopic>
  /**
   *
   * @type {string}
   * @memberof ProgramResourceRequest
   */
  readable_id: string
  /**
   *
   * @type {string}
   * @memberof ProgramResourceRequest
   */
  title: string
  /**
   *
   * @type {string}
   * @memberof ProgramResourceRequest
   */
  description?: string | null
  /**
   *
   * @type {string}
   * @memberof ProgramResourceRequest
   */
  full_description?: string | null
  /**
   *
   * @type {string}
   * @memberof ProgramResourceRequest
   */
  last_modified?: string | null
  /**
   *
   * @type {boolean}
   * @memberof ProgramResourceRequest
   */
  published?: boolean
  /**
   *
   * @type {Array<string>}
   * @memberof ProgramResourceRequest
   */
  languages?: Array<string> | null
  /**
   *
   * @type {string}
   * @memberof ProgramResourceRequest
   */
  url?: string | null
  /**
   *
   * @type {Array<string>}
   * @memberof ProgramResourceRequest
   */
  ocw_topics?: Array<string>
  /**
   *
   * @type {string}
   * @memberof ProgramResourceRequest
   */
  next_start_date?: string | null
  /**
   *
   * @type {AvailabilityEnum}
   * @memberof ProgramResourceRequest
   */
  availability?: AvailabilityEnum | null
  /**
   *
   * @type {number}
   * @memberof ProgramResourceRequest
   */
  completeness?: number
  /**
   *
   * @type {boolean}
   * @memberof ProgramResourceRequest
   */
  license_cc?: boolean
  /**
   *
   * @type {string}
   * @memberof ProgramResourceRequest
   */
  continuing_ed_credits?: string | null
  /**
   *
   * @type {string}
   * @memberof ProgramResourceRequest
   */
  location?: string
  /**
   *
   * @type {string}
   * @memberof ProgramResourceRequest
   */
  duration?: string
  /**
   *
   * @type {number}
   * @memberof ProgramResourceRequest
   */
  min_weeks?: number | null
  /**
   *
   * @type {number}
   * @memberof ProgramResourceRequest
   */
  max_weeks?: number | null
  /**
   *
   * @type {string}
   * @memberof ProgramResourceRequest
   */
  time_commitment?: string
  /**
   *
   * @type {number}
   * @memberof ProgramResourceRequest
   */
  min_weekly_hours?: number | null
  /**
   *
   * @type {number}
   * @memberof ProgramResourceRequest
   */
  max_weekly_hours?: number | null
}

/**
 *
 * @export
 * @enum {string}
 */

export const ProgramResourceResourceTypeEnumDescriptions = {
  program: "",
} as const

export const ProgramResourceResourceTypeEnum = {
  Program: "program",
} as const

export type ProgramResourceResourceTypeEnum =
  (typeof ProgramResourceResourceTypeEnum)[keyof typeof ProgramResourceResourceTypeEnum]

/**
 * * `PROGRAM_COURSES` - Program Courses * `LEARNING_PATH_ITEMS` - Learning Path Items * `PODCAST_EPISODES` - Podcast Episodes * `PLAYLIST_VIDEOS` - Playlist Videos
 * @export
 * @enum {string}
 */

export const RelationTypeEnumDescriptions = {
  PROGRAM_COURSES: "Program Courses",
  LEARNING_PATH_ITEMS: "Learning Path Items",
  PODCAST_EPISODES: "Podcast Episodes",
  PLAYLIST_VIDEOS: "Playlist Videos",
} as const

export const RelationTypeEnum = {
  /**
   * Program Courses
   */
  ProgramCourses: "PROGRAM_COURSES",
  /**
   * Learning Path Items
   */
  LearningPathItems: "LEARNING_PATH_ITEMS",
  /**
   * Podcast Episodes
   */
  PodcastEpisodes: "PODCAST_EPISODES",
  /**
   * Playlist Videos
   */
  PlaylistVideos: "PLAYLIST_VIDEOS",
} as const

export type RelationTypeEnum =
  (typeof RelationTypeEnum)[keyof typeof RelationTypeEnum]

/**
 * * `course` - Course * `program` - Program * `learning_material` - Learning Material
 * @export
 * @enum {string}
 */

export const ResourceCategoryEnumDescriptions = {
  course: "Course",
  program: "Program",
  learning_material: "Learning Material",
} as const

export const ResourceCategoryEnum = {
  /**
   * Course
   */
  Course: "course",
  /**
   * Program
   */
  Program: "program",
  /**
   * Learning Material
   */
  LearningMaterial: "learning_material",
} as const

export type ResourceCategoryEnum =
  (typeof ResourceCategoryEnum)[keyof typeof ResourceCategoryEnum]

/**
 * * `course` - course * `program` - program * `learning_path` - learning_path * `podcast` - podcast * `podcast_episode` - podcast_episode * `video` - video * `video_playlist` - video_playlist
 * @export
 * @enum {string}
 */

export const ResourceTypeEnumDescriptions = {
  course: "course",
  program: "program",
  learning_path: "learning_path",
  podcast: "podcast",
  podcast_episode: "podcast_episode",
  video: "video",
  video_playlist: "video_playlist",
} as const

export const ResourceTypeEnum = {
  /**
   * course
   */
  Course: "course",
  /**
   * program
   */
  Program: "program",
  /**
   * learning_path
   */
  LearningPath: "learning_path",
  /**
   * podcast
   */
  Podcast: "podcast",
  /**
   * podcast_episode
   */
  PodcastEpisode: "podcast_episode",
  /**
   * video
   */
  Video: "video",
  /**
   * video_playlist
   */
  VideoPlaylist: "video_playlist",
} as const

export type ResourceTypeEnum =
  (typeof ResourceTypeEnum)[keyof typeof ResourceTypeEnum]

/**
 * * `best_fields` - best_fields * `most_fields` - most_fields * `phrase` - phrase  * `best_fields` - best_fields * `most_fields` - most_fields * `phrase` - phrase
 * @export
 * @enum {string}
 */

export const SearchModeEnumDescriptions = {
  best_fields: "best_fields",
  most_fields: "most_fields",
  phrase: "phrase",
} as const

export const SearchModeEnum = {
  /**
   * best_fields
   */
  BestFields: "best_fields",
  /**
   * most_fields
   */
  MostFields: "most_fields",
  /**
   * phrase
   */
  Phrase: "phrase",
} as const

export type SearchModeEnum =
  (typeof SearchModeEnum)[keyof typeof SearchModeEnum]

/**
 * * `featured` - Featured * `id` - Object ID ascending * `-id` - Object ID descending * `readable_id` - Readable ID ascending * `-readable_id` - Readable ID descending * `last_modified` - Last Modified Date ascending * `-last_modified` - Last Modified Date descending * `new` - Newest resources first * `start_date` - Start Date ascending * `-start_date` - Start Date descending * `mitcoursenumber` - MIT course number ascending * `-mitcoursenumber` - MIT course number descending * `views` - Popularity ascending * `-views` - Popularity descending * `upcoming` - Next start date ascending
 * @export
 * @enum {string}
 */

export const SortbyEnumDescriptions = {
  featured: "Featured",
  id: "Object ID ascending",
  "-id": "Object ID descending",
  readable_id: "Readable ID ascending",
  "-readable_id": "Readable ID descending",
  last_modified: "Last Modified Date ascending",
  "-last_modified": "Last Modified Date descending",
  new: "Newest resources first",
  start_date: "Start Date ascending",
  "-start_date": "Start Date descending",
  mitcoursenumber: "MIT course number ascending",
  "-mitcoursenumber": "MIT course number descending",
  views: "Popularity ascending",
  "-views": "Popularity descending",
  upcoming: "Next start date ascending",
} as const

export const SortbyEnum = {
  /**
   * Featured
   */
  Featured: "featured",
  /**
   * Object ID ascending
   */
  Id: "id",
  /**
   * Object ID descending
   */
  Id2: "-id",
  /**
   * Readable ID ascending
   */
  ReadableId: "readable_id",
  /**
   * Readable ID descending
   */
  ReadableId2: "-readable_id",
  /**
   * Last Modified Date ascending
   */
  LastModified: "last_modified",
  /**
   * Last Modified Date descending
   */
  LastModified2: "-last_modified",
  /**
   * Newest resources first
   */
  New: "new",
  /**
   * Start Date ascending
   */
  StartDate: "start_date",
  /**
   * Start Date descending
   */
  StartDate2: "-start_date",
  /**
   * MIT course number ascending
   */
  Mitcoursenumber: "mitcoursenumber",
  /**
   * MIT course number descending
   */
  Mitcoursenumber2: "-mitcoursenumber",
  /**
   * Popularity ascending
   */
  Views: "views",
  /**
   * Popularity descending
   */
  Views2: "-views",
  /**
   * Next start date ascending
   */
  Upcoming: "upcoming",
} as const

export type SortbyEnum = (typeof SortbyEnum)[keyof typeof SortbyEnum]

/**
 * * `search_subscription_type` - search_subscription_type * `channel_subscription_type` - channel_subscription_type
 * @export
 * @enum {string}
 */

export const SourceTypeEnumDescriptions = {
  search_subscription_type: "search_subscription_type",
  channel_subscription_type: "channel_subscription_type",
} as const

export const SourceTypeEnum = {
  /**
   * search_subscription_type
   */
  SearchSubscriptionType: "search_subscription_type",
  /**
   * channel_subscription_type
   */
  ChannelSubscriptionType: "channel_subscription_type",
} as const

export type SourceTypeEnum =
  (typeof SourceTypeEnum)[keyof typeof SourceTypeEnum]

/**
 * Simplified serializer for UserList model.
 * @export
 * @interface UserList
 */
export interface UserList {
  /**
   *
   * @type {number}
   * @memberof UserList
   */
  id: number
  /**
   *
   * @type {Array<LearningResourceTopic>}
   * @memberof UserList
   */
  topics?: Array<LearningResourceTopic>
  /**
   * Return the number of items in the list
   * @type {number}
   * @memberof UserList
   */
  item_count: number
  /**
   * Return the image of the first item
   * @type {{ [key: string]: any; }}
   * @memberof UserList
   */
  image: { [key: string]: any }
  /**
   *
   * @type {string}
   * @memberof UserList
   */
  title: string
  /**
   *
   * @type {string}
   * @memberof UserList
   */
  description?: string
  /**
   *
   * @type {PrivacyLevelEnum}
   * @memberof UserList
   */
  privacy_level?: PrivacyLevelEnum
  /**
   *
   * @type {number}
   * @memberof UserList
   */
  author: number
}

/**
 * Serializer for UserListRelationship model
 * @export
 * @interface UserListRelationship
 */
export interface UserListRelationship {
  /**
   *
   * @type {number}
   * @memberof UserListRelationship
   */
  id: number
  /**
   *
   * @type {LearningResource}
   * @memberof UserListRelationship
   */
  resource: LearningResource
  /**
   *
   * @type {number}
   * @memberof UserListRelationship
   */
  position?: number
  /**
   *
   * @type {number}
   * @memberof UserListRelationship
   */
  parent: number
  /**
   *
   * @type {number}
   * @memberof UserListRelationship
   */
  child: number
}
/**
 * Serializer for UserListRelationship model
 * @export
 * @interface UserListRelationshipRequest
 */
export interface UserListRelationshipRequest {
  /**
   *
   * @type {number}
   * @memberof UserListRelationshipRequest
   */
  position?: number
  /**
   *
   * @type {number}
   * @memberof UserListRelationshipRequest
   */
  parent: number
  /**
   *
   * @type {number}
   * @memberof UserListRelationshipRequest
   */
  child: number
}
/**
 * Simplified serializer for UserList model.
 * @export
 * @interface UserListRequest
 */
export interface UserListRequest {
  /**
   *
   * @type {Array<LearningResourceTopic>}
   * @memberof UserListRequest
   */
  topics?: Array<LearningResourceTopic>
  /**
   *
   * @type {string}
   * @memberof UserListRequest
   */
  title: string
  /**
   *
   * @type {string}
   * @memberof UserListRequest
   */
  description?: string
  /**
   *
   * @type {PrivacyLevelEnum}
   * @memberof UserListRequest
   */
  privacy_level?: PrivacyLevelEnum
}

/**
 * Serializer for the Video model
 * @export
 * @interface Video
 */
export interface Video {
  /**
   *
   * @type {number}
   * @memberof Video
   */
  id: number
  /**
   *
   * @type {string}
   * @memberof Video
   */
  duration: string
  /**
   *
   * @type {string}
   * @memberof Video
   */
  transcript?: string
}
/**
 * Serializer for the VideoChannel model
 * @export
 * @interface VideoChannel
 */
export interface VideoChannel {
  /**
   *
   * @type {string}
   * @memberof VideoChannel
   */
  channel_id: string
  /**
   *
   * @type {string}
   * @memberof VideoChannel
   */
  title: string
}
/**
 * Serializer for the VideoChannel model
 * @export
 * @interface VideoChannelRequest
 */
export interface VideoChannelRequest {
  /**
   *
   * @type {string}
   * @memberof VideoChannelRequest
   */
  channel_id: string
  /**
   *
   * @type {string}
   * @memberof VideoChannelRequest
   */
  title: string
}
/**
 * Serializer for the VideoPlaylist model
 * @export
 * @interface VideoPlaylist
 */
export interface VideoPlaylist {
  /**
   *
   * @type {number}
   * @memberof VideoPlaylist
   */
  id: number
  /**
   *
   * @type {VideoChannel}
   * @memberof VideoPlaylist
   */
  channel: VideoChannel | null
  /**
   *
   * @type {number}
   * @memberof VideoPlaylist
   */
  video_count: number
}
/**
 * Serializer for the VideoPlaylist model
 * @export
 * @interface VideoPlaylistRequest
 */
export interface VideoPlaylistRequest {
  /**
   *
   * @type {number}
   * @memberof VideoPlaylistRequest
   */
  video_count: number
}
/**
 * Serializer for video playlist resources
 * @export
 * @interface VideoPlaylistResource
 */
export interface VideoPlaylistResource {
  /**
   *
   * @type {number}
   * @memberof VideoPlaylistResource
   */
  id: number
  /**
   *
   * @type {Array<LearningResourceTopic>}
   * @memberof VideoPlaylistResource
   */
  topics?: Array<LearningResourceTopic>
  /**
   *
   * @type {number}
   * @memberof VideoPlaylistResource
   */
  position: number | null
  /**
   *
   * @type {LearningResourceOfferor}
   * @memberof VideoPlaylistResource
   */
  offered_by: LearningResourceOfferor | null
  /**
   *
   * @type {LearningResourcePlatform}
   * @memberof VideoPlaylistResource
   */
  platform: LearningResourcePlatform | null
  /**
   *
   * @type {Array<string>}
   * @memberof VideoPlaylistResource
   */
  course_feature: Array<string> | null
  /**
   *
   * @type {Array<LearningResourceDepartment>}
   * @memberof VideoPlaylistResource
   */
  departments: Array<LearningResourceDepartment> | null
  /**
   *
   * @type {boolean}
   * @memberof VideoPlaylistResource
   */
  certification: boolean
  /**
   *
   * @type {CourseResourceCertificationType}
   * @memberof VideoPlaylistResource
   */
  certification_type: CourseResourceCertificationType
  /**
   *
   * @type {Array<string>}
   * @memberof VideoPlaylistResource
   */
  prices: Array<string>
  /**
   *
   * @type {Array<LearningResourcePrice>}
   * @memberof VideoPlaylistResource
   */
  resource_prices: Array<LearningResourcePrice>
  /**
   *
   * @type {Array<LearningResourceRun>}
   * @memberof VideoPlaylistResource
   */
  runs: Array<LearningResourceRun> | null
  /**
   *
   * @type {LearningResourceImage}
   * @memberof VideoPlaylistResource
   */
  image: LearningResourceImage | null
  /**
   *
   * @type {Array<MicroLearningPathRelationship>}
   * @memberof VideoPlaylistResource
   */
  learning_path_parents: Array<MicroLearningPathRelationship>
  /**
   *
   * @type {Array<MicroUserListRelationship>}
   * @memberof VideoPlaylistResource
   */
  user_list_parents: Array<MicroUserListRelationship>
  /**
   *
   * @type {number}
   * @memberof VideoPlaylistResource
   */
  views: number
  /**
   *
   * @type {Array<CourseResourceDeliveryInner>}
   * @memberof VideoPlaylistResource
   */
  delivery: Array<CourseResourceDeliveryInner>
  /**
   * Return true if the resource is free/has a free option
   * @type {boolean}
   * @memberof VideoPlaylistResource
   */
  free: boolean
  /**
   * Return the resource category of the resource
   * @type {string}
   * @memberof VideoPlaylistResource
   */
  resource_category: string
  /**
   *
   * @type {Array<CourseResourceFormatInner>}
   * @memberof VideoPlaylistResource
   */
  format: Array<CourseResourceFormatInner>
  /**
   *
   * @type {Array<CourseResourcePaceInner>}
   * @memberof VideoPlaylistResource
   */
  pace: Array<CourseResourcePaceInner>
  /**
   *
   * @type {VideoPlaylistResourceResourceTypeEnum}
   * @memberof VideoPlaylistResource
   */
  resource_type: VideoPlaylistResourceResourceTypeEnum
  /**
   *
   * @type {VideoPlaylist}
   * @memberof VideoPlaylistResource
   */
  video_playlist: VideoPlaylist
  /**
   *
   * @type {string}
   * @memberof VideoPlaylistResource
   */
  readable_id: string
  /**
   *
   * @type {string}
   * @memberof VideoPlaylistResource
   */
  title: string
  /**
   *
   * @type {string}
   * @memberof VideoPlaylistResource
   */
  description?: string | null
  /**
   *
   * @type {string}
   * @memberof VideoPlaylistResource
   */
  full_description?: string | null
  /**
   *
   * @type {string}
   * @memberof VideoPlaylistResource
   */
  last_modified?: string | null
  /**
   *
   * @type {boolean}
   * @memberof VideoPlaylistResource
   */
  published?: boolean
  /**
   *
   * @type {Array<string>}
   * @memberof VideoPlaylistResource
   */
  languages?: Array<string> | null
  /**
   *
   * @type {string}
   * @memberof VideoPlaylistResource
   */
  url?: string | null
  /**
   *
   * @type {Array<string>}
   * @memberof VideoPlaylistResource
   */
  ocw_topics?: Array<string>
  /**
   *
   * @type {boolean}
   * @memberof VideoPlaylistResource
   */
  professional: boolean
  /**
   *
   * @type {string}
   * @memberof VideoPlaylistResource
   */
  next_start_date?: string | null
  /**
   *
   * @type {AvailabilityEnum}
   * @memberof VideoPlaylistResource
   */
  availability?: AvailabilityEnum | null
  /**
   *
   * @type {number}
   * @memberof VideoPlaylistResource
   */
  completeness?: number
  /**
   *
   * @type {boolean}
   * @memberof VideoPlaylistResource
   */
  license_cc?: boolean
  /**
   *
   * @type {string}
   * @memberof VideoPlaylistResource
   */
  continuing_ed_credits?: string | null
  /**
   *
   * @type {string}
   * @memberof VideoPlaylistResource
   */
  location?: string
  /**
   *
   * @type {string}
   * @memberof VideoPlaylistResource
   */
  duration?: string
  /**
   *
   * @type {number}
   * @memberof VideoPlaylistResource
   */
  min_weeks?: number | null
  /**
   *
   * @type {number}
   * @memberof VideoPlaylistResource
   */
  max_weeks?: number | null
  /**
   *
   * @type {string}
   * @memberof VideoPlaylistResource
   */
  time_commitment?: string
  /**
   *
   * @type {number}
   * @memberof VideoPlaylistResource
   */
  min_weekly_hours?: number | null
  /**
   *
   * @type {number}
   * @memberof VideoPlaylistResource
   */
  max_weekly_hours?: number | null
}

/**
 * Serializer for video playlist resources
 * @export
 * @interface VideoPlaylistResourceRequest
 */
export interface VideoPlaylistResourceRequest {
  /**
   *
   * @type {Array<LearningResourceTopic>}
   * @memberof VideoPlaylistResourceRequest
   */
  topics?: Array<LearningResourceTopic>
  /**
   *
   * @type {string}
   * @memberof VideoPlaylistResourceRequest
   */
  readable_id: string
  /**
   *
   * @type {string}
   * @memberof VideoPlaylistResourceRequest
   */
  title: string
  /**
   *
   * @type {string}
   * @memberof VideoPlaylistResourceRequest
   */
  description?: string | null
  /**
   *
   * @type {string}
   * @memberof VideoPlaylistResourceRequest
   */
  full_description?: string | null
  /**
   *
   * @type {string}
   * @memberof VideoPlaylistResourceRequest
   */
  last_modified?: string | null
  /**
   *
   * @type {boolean}
   * @memberof VideoPlaylistResourceRequest
   */
  published?: boolean
  /**
   *
   * @type {Array<string>}
   * @memberof VideoPlaylistResourceRequest
   */
  languages?: Array<string> | null
  /**
   *
   * @type {string}
   * @memberof VideoPlaylistResourceRequest
   */
  url?: string | null
  /**
   *
   * @type {Array<string>}
   * @memberof VideoPlaylistResourceRequest
   */
  ocw_topics?: Array<string>
  /**
   *
   * @type {string}
   * @memberof VideoPlaylistResourceRequest
   */
  next_start_date?: string | null
  /**
   *
   * @type {AvailabilityEnum}
   * @memberof VideoPlaylistResourceRequest
   */
  availability?: AvailabilityEnum | null
  /**
   *
   * @type {number}
   * @memberof VideoPlaylistResourceRequest
   */
  completeness?: number
  /**
   *
   * @type {boolean}
   * @memberof VideoPlaylistResourceRequest
   */
  license_cc?: boolean
  /**
   *
   * @type {string}
   * @memberof VideoPlaylistResourceRequest
   */
  continuing_ed_credits?: string | null
  /**
   *
   * @type {string}
   * @memberof VideoPlaylistResourceRequest
   */
  location?: string
  /**
   *
   * @type {string}
   * @memberof VideoPlaylistResourceRequest
   */
  duration?: string
  /**
   *
   * @type {number}
   * @memberof VideoPlaylistResourceRequest
   */
  min_weeks?: number | null
  /**
   *
   * @type {number}
   * @memberof VideoPlaylistResourceRequest
   */
  max_weeks?: number | null
  /**
   *
   * @type {string}
   * @memberof VideoPlaylistResourceRequest
   */
  time_commitment?: string
  /**
   *
   * @type {number}
   * @memberof VideoPlaylistResourceRequest
   */
  min_weekly_hours?: number | null
  /**
   *
   * @type {number}
   * @memberof VideoPlaylistResourceRequest
   */
  max_weekly_hours?: number | null
}

/**
 *
 * @export
 * @enum {string}
 */

export const VideoPlaylistResourceResourceTypeEnumDescriptions = {
  video_playlist: "",
} as const

export const VideoPlaylistResourceResourceTypeEnum = {
  VideoPlaylist: "video_playlist",
} as const

export type VideoPlaylistResourceResourceTypeEnum =
  (typeof VideoPlaylistResourceResourceTypeEnum)[keyof typeof VideoPlaylistResourceResourceTypeEnum]

/**
 * Serializer for the Video model
 * @export
 * @interface VideoRequest
 */
export interface VideoRequest {
  /**
   *
   * @type {string}
   * @memberof VideoRequest
   */
  duration: string
  /**
   *
   * @type {string}
   * @memberof VideoRequest
   */
  transcript?: string
}
/**
 * Serializer for video resources
 * @export
 * @interface VideoResource
 */
export interface VideoResource {
  /**
   *
   * @type {number}
   * @memberof VideoResource
   */
  id: number
  /**
   *
   * @type {Array<LearningResourceTopic>}
   * @memberof VideoResource
   */
  topics?: Array<LearningResourceTopic>
  /**
   *
   * @type {number}
   * @memberof VideoResource
   */
  position: number | null
  /**
   *
   * @type {LearningResourceOfferor}
   * @memberof VideoResource
   */
  offered_by: LearningResourceOfferor | null
  /**
   *
   * @type {LearningResourcePlatform}
   * @memberof VideoResource
   */
  platform: LearningResourcePlatform | null
  /**
   *
   * @type {Array<string>}
   * @memberof VideoResource
   */
  course_feature: Array<string> | null
  /**
   *
   * @type {Array<LearningResourceDepartment>}
   * @memberof VideoResource
   */
  departments: Array<LearningResourceDepartment> | null
  /**
   *
   * @type {boolean}
   * @memberof VideoResource
   */
  certification: boolean
  /**
   *
   * @type {CourseResourceCertificationType}
   * @memberof VideoResource
   */
  certification_type: CourseResourceCertificationType
  /**
   *
   * @type {Array<string>}
   * @memberof VideoResource
   */
  prices: Array<string>
  /**
   *
   * @type {Array<LearningResourcePrice>}
   * @memberof VideoResource
   */
  resource_prices: Array<LearningResourcePrice>
  /**
   *
   * @type {Array<LearningResourceRun>}
   * @memberof VideoResource
   */
  runs: Array<LearningResourceRun> | null
  /**
   *
   * @type {LearningResourceImage}
   * @memberof VideoResource
   */
  image: LearningResourceImage | null
  /**
   *
   * @type {Array<MicroLearningPathRelationship>}
   * @memberof VideoResource
   */
  learning_path_parents: Array<MicroLearningPathRelationship>
  /**
   *
   * @type {Array<MicroUserListRelationship>}
   * @memberof VideoResource
   */
  user_list_parents: Array<MicroUserListRelationship>
  /**
   *
   * @type {number}
   * @memberof VideoResource
   */
  views: number
  /**
   *
   * @type {Array<CourseResourceDeliveryInner>}
   * @memberof VideoResource
   */
  delivery: Array<CourseResourceDeliveryInner>
  /**
   * Return true if the resource is free/has a free option
   * @type {boolean}
   * @memberof VideoResource
   */
  free: boolean
  /**
   * Return the resource category of the resource
   * @type {string}
   * @memberof VideoResource
   */
  resource_category: string
  /**
   *
   * @type {Array<CourseResourceFormatInner>}
   * @memberof VideoResource
   */
  format: Array<CourseResourceFormatInner>
  /**
   *
   * @type {Array<CourseResourcePaceInner>}
   * @memberof VideoResource
   */
  pace: Array<CourseResourcePaceInner>
  /**
   *
   * @type {VideoResourceResourceTypeEnum}
   * @memberof VideoResource
   */
  resource_type: VideoResourceResourceTypeEnum
  /**
   *
   * @type {Video}
   * @memberof VideoResource
   */
  video: Video
  /**
   * Get the playlist id(s) the video belongs to
   * @type {Array<string>}
   * @memberof VideoResource
   */
  playlists: Array<string>
  /**
   *
   * @type {string}
   * @memberof VideoResource
   */
  readable_id: string
  /**
   *
   * @type {string}
   * @memberof VideoResource
   */
  title: string
  /**
   *
   * @type {string}
   * @memberof VideoResource
   */
  description?: string | null
  /**
   *
   * @type {string}
   * @memberof VideoResource
   */
  full_description?: string | null
  /**
   *
   * @type {string}
   * @memberof VideoResource
   */
  last_modified?: string | null
  /**
   *
   * @type {boolean}
   * @memberof VideoResource
   */
  published?: boolean
  /**
   *
   * @type {Array<string>}
   * @memberof VideoResource
   */
  languages?: Array<string> | null
  /**
   *
   * @type {string}
   * @memberof VideoResource
   */
  url?: string | null
  /**
   *
   * @type {Array<string>}
   * @memberof VideoResource
   */
  ocw_topics?: Array<string>
  /**
   *
   * @type {boolean}
   * @memberof VideoResource
   */
  professional: boolean
  /**
   *
   * @type {string}
   * @memberof VideoResource
   */
  next_start_date?: string | null
  /**
   *
   * @type {AvailabilityEnum}
   * @memberof VideoResource
   */
  availability?: AvailabilityEnum | null
  /**
   *
   * @type {number}
   * @memberof VideoResource
   */
  completeness?: number
  /**
   *
   * @type {boolean}
   * @memberof VideoResource
   */
  license_cc?: boolean
  /**
   *
   * @type {string}
   * @memberof VideoResource
   */
  continuing_ed_credits?: string | null
  /**
   *
   * @type {string}
   * @memberof VideoResource
   */
  location?: string
  /**
   *
   * @type {string}
   * @memberof VideoResource
   */
  duration?: string
  /**
   *
   * @type {number}
   * @memberof VideoResource
   */
  min_weeks?: number | null
  /**
   *
   * @type {number}
   * @memberof VideoResource
   */
  max_weeks?: number | null
  /**
   *
   * @type {string}
   * @memberof VideoResource
   */
  time_commitment?: string
  /**
   *
   * @type {number}
   * @memberof VideoResource
   */
  min_weekly_hours?: number | null
  /**
   *
   * @type {number}
   * @memberof VideoResource
   */
  max_weekly_hours?: number | null
}

/**
 * Serializer for video resources
 * @export
 * @interface VideoResourceRequest
 */
export interface VideoResourceRequest {
  /**
   *
   * @type {Array<LearningResourceTopic>}
   * @memberof VideoResourceRequest
   */
  topics?: Array<LearningResourceTopic>
  /**
   *
   * @type {string}
   * @memberof VideoResourceRequest
   */
  readable_id: string
  /**
   *
   * @type {string}
   * @memberof VideoResourceRequest
   */
  title: string
  /**
   *
   * @type {string}
   * @memberof VideoResourceRequest
   */
  description?: string | null
  /**
   *
   * @type {string}
   * @memberof VideoResourceRequest
   */
  full_description?: string | null
  /**
   *
   * @type {string}
   * @memberof VideoResourceRequest
   */
  last_modified?: string | null
  /**
   *
   * @type {boolean}
   * @memberof VideoResourceRequest
   */
  published?: boolean
  /**
   *
   * @type {Array<string>}
   * @memberof VideoResourceRequest
   */
  languages?: Array<string> | null
  /**
   *
   * @type {string}
   * @memberof VideoResourceRequest
   */
  url?: string | null
  /**
   *
   * @type {Array<string>}
   * @memberof VideoResourceRequest
   */
  ocw_topics?: Array<string>
  /**
   *
   * @type {string}
   * @memberof VideoResourceRequest
   */
  next_start_date?: string | null
  /**
   *
   * @type {AvailabilityEnum}
   * @memberof VideoResourceRequest
   */
  availability?: AvailabilityEnum | null
  /**
   *
   * @type {number}
   * @memberof VideoResourceRequest
   */
  completeness?: number
  /**
   *
   * @type {boolean}
   * @memberof VideoResourceRequest
   */
  license_cc?: boolean
  /**
   *
   * @type {string}
   * @memberof VideoResourceRequest
   */
  continuing_ed_credits?: string | null
  /**
   *
   * @type {string}
   * @memberof VideoResourceRequest
   */
  location?: string
  /**
   *
   * @type {string}
   * @memberof VideoResourceRequest
   */
  duration?: string
  /**
   *
   * @type {number}
   * @memberof VideoResourceRequest
   */
  min_weeks?: number | null
  /**
   *
   * @type {number}
   * @memberof VideoResourceRequest
   */
  max_weeks?: number | null
  /**
   *
   * @type {string}
   * @memberof VideoResourceRequest
   */
  time_commitment?: string
  /**
   *
   * @type {number}
   * @memberof VideoResourceRequest
   */
  min_weekly_hours?: number | null
  /**
   *
   * @type {number}
   * @memberof VideoResourceRequest
   */
  max_weekly_hours?: number | null
}

/**
 *
 * @export
 * @enum {string}
 */

export const VideoResourceResourceTypeEnumDescriptions = {
  video: "",
} as const

export const VideoResourceResourceTypeEnum = {
  Video: "video",
} as const

export type VideoResourceResourceTypeEnum =
  (typeof VideoResourceResourceTypeEnum)[keyof typeof VideoResourceResourceTypeEnum]

/**
 * ArticlesApi - axios parameter creator
 * @export
 */
export const ArticlesApiAxiosParamCreator = function (
  configuration?: Configuration,
) {
  return {
    /**
     * Create a new article
     * @summary Create
     * @param {ArticleRequest} ArticleRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    articlesCreate: async (
      ArticleRequest: ArticleRequest,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'ArticleRequest' is not null or undefined
      assertParamExists("articlesCreate", "ArticleRequest", ArticleRequest)
      const localVarPath = `/api/v1/articles/`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = {
        method: "POST",
        ...baseOptions,
        ...options,
      }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      localVarHeaderParameter["Content-Type"] = "application/json"

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }
      localVarRequestOptions.data = serializeDataIfNeeded(
        ArticleRequest,
        localVarRequestOptions,
        configuration,
      )

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     * Delete an article
     * @summary Destroy
     * @param {number} id A unique integer value identifying this article.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    articlesDestroy: async (
      id: number,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists("articlesDestroy", "id", id)
      const localVarPath = `/api/v1/articles/{id}/`.replace(
        `{${"id"}}`,
        encodeURIComponent(String(id)),
      )
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = {
        method: "DELETE",
        ...baseOptions,
        ...options,
      }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     * Get a paginated list of articles
     * @summary List
     * @param {number} [limit] Number of results to return per page.
     * @param {number} [offset] The initial index from which to return the results.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    articlesList: async (
      limit?: number,
      offset?: number,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/api/v1/articles/`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      if (limit !== undefined) {
        localVarQueryParameter["limit"] = limit
      }

      if (offset !== undefined) {
        localVarQueryParameter["offset"] = offset
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     * Update an article
     * @summary Update
     * @param {number} id A unique integer value identifying this article.
     * @param {PatchedArticleRequest} [PatchedArticleRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    articlesPartialUpdate: async (
      id: number,
      PatchedArticleRequest?: PatchedArticleRequest,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists("articlesPartialUpdate", "id", id)
      const localVarPath = `/api/v1/articles/{id}/`.replace(
        `{${"id"}}`,
        encodeURIComponent(String(id)),
      )
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = {
        method: "PATCH",
        ...baseOptions,
        ...options,
      }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      localVarHeaderParameter["Content-Type"] = "application/json"

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }
      localVarRequestOptions.data = serializeDataIfNeeded(
        PatchedArticleRequest,
        localVarRequestOptions,
        configuration,
      )

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     * Retrieve a single article
     * @summary Retrieve
     * @param {number} id A unique integer value identifying this article.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    articlesRetrieve: async (
      id: number,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists("articlesRetrieve", "id", id)
      const localVarPath = `/api/v1/articles/{id}/`.replace(
        `{${"id"}}`,
        encodeURIComponent(String(id)),
      )
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
  }
}

/**
 * ArticlesApi - functional programming interface
 * @export
 */
export const ArticlesApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = ArticlesApiAxiosParamCreator(configuration)
  return {
    /**
     * Create a new article
     * @summary Create
     * @param {ArticleRequest} ArticleRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async articlesCreate(
      ArticleRequest: ArticleRequest,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Article>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.articlesCreate(
        ArticleRequest,
        options,
      )
      const index = configuration?.serverIndex ?? 0
      const operationBasePath =
        operationServerMap["ArticlesApi.articlesCreate"]?.[index]?.url
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, operationBasePath || basePath)
    },
    /**
     * Delete an article
     * @summary Destroy
     * @param {number} id A unique integer value identifying this article.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async articlesDestroy(
      id: number,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.articlesDestroy(
        id,
        options,
      )
      const index = configuration?.serverIndex ?? 0
      const operationBasePath =
        operationServerMap["ArticlesApi.articlesDestroy"]?.[index]?.url
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, operationBasePath || basePath)
    },
    /**
     * Get a paginated list of articles
     * @summary List
     * @param {number} [limit] Number of results to return per page.
     * @param {number} [offset] The initial index from which to return the results.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async articlesList(
      limit?: number,
      offset?: number,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<PaginatedArticleList>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.articlesList(
        limit,
        offset,
        options,
      )
      const index = configuration?.serverIndex ?? 0
      const operationBasePath =
        operationServerMap["ArticlesApi.articlesList"]?.[index]?.url
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, operationBasePath || basePath)
    },
    /**
     * Update an article
     * @summary Update
     * @param {number} id A unique integer value identifying this article.
     * @param {PatchedArticleRequest} [PatchedArticleRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async articlesPartialUpdate(
      id: number,
      PatchedArticleRequest?: PatchedArticleRequest,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Article>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.articlesPartialUpdate(
          id,
          PatchedArticleRequest,
          options,
        )
      const index = configuration?.serverIndex ?? 0
      const operationBasePath =
        operationServerMap["ArticlesApi.articlesPartialUpdate"]?.[index]?.url
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, operationBasePath || basePath)
    },
    /**
     * Retrieve a single article
     * @summary Retrieve
     * @param {number} id A unique integer value identifying this article.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async articlesRetrieve(
      id: number,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Article>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.articlesRetrieve(id, options)
      const index = configuration?.serverIndex ?? 0
      const operationBasePath =
        operationServerMap["ArticlesApi.articlesRetrieve"]?.[index]?.url
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, operationBasePath || basePath)
    },
  }
}

/**
 * ArticlesApi - factory interface
 * @export
 */
export const ArticlesApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = ArticlesApiFp(configuration)
  return {
    /**
     * Create a new article
     * @summary Create
     * @param {ArticlesApiArticlesCreateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    articlesCreate(
      requestParameters: ArticlesApiArticlesCreateRequest,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<Article> {
      return localVarFp
        .articlesCreate(requestParameters.ArticleRequest, options)
        .then((request) => request(axios, basePath))
    },
    /**
     * Delete an article
     * @summary Destroy
     * @param {ArticlesApiArticlesDestroyRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    articlesDestroy(
      requestParameters: ArticlesApiArticlesDestroyRequest,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<void> {
      return localVarFp
        .articlesDestroy(requestParameters.id, options)
        .then((request) => request(axios, basePath))
    },
    /**
     * Get a paginated list of articles
     * @summary List
     * @param {ArticlesApiArticlesListRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    articlesList(
      requestParameters: ArticlesApiArticlesListRequest = {},
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<PaginatedArticleList> {
      return localVarFp
        .articlesList(
          requestParameters.limit,
          requestParameters.offset,
          options,
        )
        .then((request) => request(axios, basePath))
    },
    /**
     * Update an article
     * @summary Update
     * @param {ArticlesApiArticlesPartialUpdateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    articlesPartialUpdate(
      requestParameters: ArticlesApiArticlesPartialUpdateRequest,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<Article> {
      return localVarFp
        .articlesPartialUpdate(
          requestParameters.id,
          requestParameters.PatchedArticleRequest,
          options,
        )
        .then((request) => request(axios, basePath))
    },
    /**
     * Retrieve a single article
     * @summary Retrieve
     * @param {ArticlesApiArticlesRetrieveRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    articlesRetrieve(
      requestParameters: ArticlesApiArticlesRetrieveRequest,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<Article> {
      return localVarFp
        .articlesRetrieve(requestParameters.id, options)
        .then((request) => request(axios, basePath))
    },
  }
}

/**
 * Request parameters for articlesCreate operation in ArticlesApi.
 * @export
 * @interface ArticlesApiArticlesCreateRequest
 */
export interface ArticlesApiArticlesCreateRequest {
  /**
   *
   * @type {ArticleRequest}
   * @memberof ArticlesApiArticlesCreate
   */
  readonly ArticleRequest: ArticleRequest
}

/**
 * Request parameters for articlesDestroy operation in ArticlesApi.
 * @export
 * @interface ArticlesApiArticlesDestroyRequest
 */
export interface ArticlesApiArticlesDestroyRequest {
  /**
   * A unique integer value identifying this article.
   * @type {number}
   * @memberof ArticlesApiArticlesDestroy
   */
  readonly id: number
}

/**
 * Request parameters for articlesList operation in ArticlesApi.
 * @export
 * @interface ArticlesApiArticlesListRequest
 */
export interface ArticlesApiArticlesListRequest {
  /**
   * Number of results to return per page.
   * @type {number}
   * @memberof ArticlesApiArticlesList
   */
  readonly limit?: number

  /**
   * The initial index from which to return the results.
   * @type {number}
   * @memberof ArticlesApiArticlesList
   */
  readonly offset?: number
}

/**
 * Request parameters for articlesPartialUpdate operation in ArticlesApi.
 * @export
 * @interface ArticlesApiArticlesPartialUpdateRequest
 */
export interface ArticlesApiArticlesPartialUpdateRequest {
  /**
   * A unique integer value identifying this article.
   * @type {number}
   * @memberof ArticlesApiArticlesPartialUpdate
   */
  readonly id: number

  /**
   *
   * @type {PatchedArticleRequest}
   * @memberof ArticlesApiArticlesPartialUpdate
   */
  readonly PatchedArticleRequest?: PatchedArticleRequest
}

/**
 * Request parameters for articlesRetrieve operation in ArticlesApi.
 * @export
 * @interface ArticlesApiArticlesRetrieveRequest
 */
export interface ArticlesApiArticlesRetrieveRequest {
  /**
   * A unique integer value identifying this article.
   * @type {number}
   * @memberof ArticlesApiArticlesRetrieve
   */
  readonly id: number
}

/**
 * ArticlesApi - object-oriented interface
 * @export
 * @class ArticlesApi
 * @extends {BaseAPI}
 */
export class ArticlesApi extends BaseAPI {
  /**
   * Create a new article
   * @summary Create
   * @param {ArticlesApiArticlesCreateRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ArticlesApi
   */
  public articlesCreate(
    requestParameters: ArticlesApiArticlesCreateRequest,
    options?: RawAxiosRequestConfig,
  ) {
    return ArticlesApiFp(this.configuration)
      .articlesCreate(requestParameters.ArticleRequest, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   * Delete an article
   * @summary Destroy
   * @param {ArticlesApiArticlesDestroyRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ArticlesApi
   */
  public articlesDestroy(
    requestParameters: ArticlesApiArticlesDestroyRequest,
    options?: RawAxiosRequestConfig,
  ) {
    return ArticlesApiFp(this.configuration)
      .articlesDestroy(requestParameters.id, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   * Get a paginated list of articles
   * @summary List
   * @param {ArticlesApiArticlesListRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ArticlesApi
   */
  public articlesList(
    requestParameters: ArticlesApiArticlesListRequest = {},
    options?: RawAxiosRequestConfig,
  ) {
    return ArticlesApiFp(this.configuration)
      .articlesList(requestParameters.limit, requestParameters.offset, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   * Update an article
   * @summary Update
   * @param {ArticlesApiArticlesPartialUpdateRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ArticlesApi
   */
  public articlesPartialUpdate(
    requestParameters: ArticlesApiArticlesPartialUpdateRequest,
    options?: RawAxiosRequestConfig,
  ) {
    return ArticlesApiFp(this.configuration)
      .articlesPartialUpdate(
        requestParameters.id,
        requestParameters.PatchedArticleRequest,
        options,
      )
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   * Retrieve a single article
   * @summary Retrieve
   * @param {ArticlesApiArticlesRetrieveRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ArticlesApi
   */
  public articlesRetrieve(
    requestParameters: ArticlesApiArticlesRetrieveRequest,
    options?: RawAxiosRequestConfig,
  ) {
    return ArticlesApiFp(this.configuration)
      .articlesRetrieve(requestParameters.id, options)
      .then((request) => request(this.axios, this.basePath))
  }
}

/**
 * ContentFileSearchApi - axios parameter creator
 * @export
 */
export const ContentFileSearchApiAxiosParamCreator = function (
  configuration?: Configuration,
) {
  return {
    /**
     * Search for content files
     * @summary Search
     * @param {Array<ContentFileSearchRetrieveAggregationsEnum>} [aggregations] Show resource counts by category
     * @param {Array<string>} [content_feature_type] The feature type of the content file. Possible options are at api/v1/course_features/
     * @param {boolean | null} [dev_mode] If true return raw open search results with score explanations
     * @param {Array<number>} [id] The id value for the content file
     * @param {number} [limit] Number of results to return per page
     * @param {Array<string>} [ocw_topic] The ocw topic name.
     * @param {Array<ContentFileSearchRetrieveOfferedByEnum>} [offered_by] The organization that offers the learning resource               * &#x60;mitx&#x60; - MITx * &#x60;ocw&#x60; - MIT OpenCourseWare * &#x60;bootcamps&#x60; - Bootcamps * &#x60;xpro&#x60; - MIT xPRO * &#x60;mitpe&#x60; - MIT Professional Education * &#x60;see&#x60; - MIT Sloan Executive Education
     * @param {number} [offset] The initial index from which to return the results
     * @param {Array<ContentFileSearchRetrievePlatformEnum>} [platform] The platform on which the learning resource is offered               * &#x60;edx&#x60; - edX * &#x60;ocw&#x60; - MIT OpenCourseWare * &#x60;oll&#x60; - Open Learning Library * &#x60;mitxonline&#x60; - MITx Online * &#x60;bootcamps&#x60; - Bootcamps * &#x60;xpro&#x60; - MIT xPRO * &#x60;csail&#x60; - CSAIL * &#x60;mitpe&#x60; - MIT Professional Education * &#x60;see&#x60; - MIT Sloan Executive Education * &#x60;scc&#x60; - Schwarzman College of Computing * &#x60;ctl&#x60; - Center for Transportation &amp; Logistics * &#x60;whu&#x60; - WHU * &#x60;susskind&#x60; - Susskind * &#x60;globalalumni&#x60; - Global Alumni * &#x60;simplilearn&#x60; - Simplilearn * &#x60;emeritus&#x60; - Emeritus * &#x60;podcast&#x60; - Podcast * &#x60;youtube&#x60; - YouTube
     * @param {string} [q] The search text
     * @param {Array<number>} [resource_id] The id value of the parent learning resource for the content file
     * @param {Array<number>} [run_id] The id value of the run that the content file belongs to
     * @param {ContentFileSearchRetrieveSortbyEnum} [sortby] if the parameter starts with \&#39;-\&#39; the sort is in descending order  * &#x60;id&#x60; - id * &#x60;-id&#x60; - -id * &#x60;resource_readable_id&#x60; - resource_readable_id * &#x60;-resource_readable_id&#x60; - -resource_readable_id
     * @param {Array<string>} [topic] The topic name. To see a list of options go to api/v1/topics/
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    contentFileSearchRetrieve: async (
      aggregations?: Array<ContentFileSearchRetrieveAggregationsEnum>,
      content_feature_type?: Array<string>,
      dev_mode?: boolean | null,
      id?: Array<number>,
      limit?: number,
      ocw_topic?: Array<string>,
      offered_by?: Array<ContentFileSearchRetrieveOfferedByEnum>,
      offset?: number,
      platform?: Array<ContentFileSearchRetrievePlatformEnum>,
      q?: string,
      resource_id?: Array<number>,
      run_id?: Array<number>,
      sortby?: ContentFileSearchRetrieveSortbyEnum,
      topic?: Array<string>,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/api/v1/content_file_search/`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      if (aggregations) {
        localVarQueryParameter["aggregations"] = aggregations
      }

      if (content_feature_type) {
        localVarQueryParameter["content_feature_type"] = content_feature_type
      }

      if (dev_mode !== undefined) {
        localVarQueryParameter["dev_mode"] = dev_mode
      }

      if (id) {
        localVarQueryParameter["id"] = id
      }

      if (limit !== undefined) {
        localVarQueryParameter["limit"] = limit
      }

      if (ocw_topic) {
        localVarQueryParameter["ocw_topic"] = ocw_topic
      }

      if (offered_by) {
        localVarQueryParameter["offered_by"] = offered_by
      }

      if (offset !== undefined) {
        localVarQueryParameter["offset"] = offset
      }

      if (platform) {
        localVarQueryParameter["platform"] = platform
      }

      if (q !== undefined) {
        localVarQueryParameter["q"] = q
      }

      if (resource_id) {
        localVarQueryParameter["resource_id"] = resource_id
      }

      if (run_id) {
        localVarQueryParameter["run_id"] = run_id
      }

      if (sortby !== undefined) {
        localVarQueryParameter["sortby"] = sortby
      }

      if (topic) {
        localVarQueryParameter["topic"] = topic
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
  }
}

/**
 * ContentFileSearchApi - functional programming interface
 * @export
 */
export const ContentFileSearchApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator =
    ContentFileSearchApiAxiosParamCreator(configuration)
  return {
    /**
     * Search for content files
     * @summary Search
     * @param {Array<ContentFileSearchRetrieveAggregationsEnum>} [aggregations] Show resource counts by category
     * @param {Array<string>} [content_feature_type] The feature type of the content file. Possible options are at api/v1/course_features/
     * @param {boolean | null} [dev_mode] If true return raw open search results with score explanations
     * @param {Array<number>} [id] The id value for the content file
     * @param {number} [limit] Number of results to return per page
     * @param {Array<string>} [ocw_topic] The ocw topic name.
     * @param {Array<ContentFileSearchRetrieveOfferedByEnum>} [offered_by] The organization that offers the learning resource               * &#x60;mitx&#x60; - MITx * &#x60;ocw&#x60; - MIT OpenCourseWare * &#x60;bootcamps&#x60; - Bootcamps * &#x60;xpro&#x60; - MIT xPRO * &#x60;mitpe&#x60; - MIT Professional Education * &#x60;see&#x60; - MIT Sloan Executive Education
     * @param {number} [offset] The initial index from which to return the results
     * @param {Array<ContentFileSearchRetrievePlatformEnum>} [platform] The platform on which the learning resource is offered               * &#x60;edx&#x60; - edX * &#x60;ocw&#x60; - MIT OpenCourseWare * &#x60;oll&#x60; - Open Learning Library * &#x60;mitxonline&#x60; - MITx Online * &#x60;bootcamps&#x60; - Bootcamps * &#x60;xpro&#x60; - MIT xPRO * &#x60;csail&#x60; - CSAIL * &#x60;mitpe&#x60; - MIT Professional Education * &#x60;see&#x60; - MIT Sloan Executive Education * &#x60;scc&#x60; - Schwarzman College of Computing * &#x60;ctl&#x60; - Center for Transportation &amp; Logistics * &#x60;whu&#x60; - WHU * &#x60;susskind&#x60; - Susskind * &#x60;globalalumni&#x60; - Global Alumni * &#x60;simplilearn&#x60; - Simplilearn * &#x60;emeritus&#x60; - Emeritus * &#x60;podcast&#x60; - Podcast * &#x60;youtube&#x60; - YouTube
     * @param {string} [q] The search text
     * @param {Array<number>} [resource_id] The id value of the parent learning resource for the content file
     * @param {Array<number>} [run_id] The id value of the run that the content file belongs to
     * @param {ContentFileSearchRetrieveSortbyEnum} [sortby] if the parameter starts with \&#39;-\&#39; the sort is in descending order  * &#x60;id&#x60; - id * &#x60;-id&#x60; - -id * &#x60;resource_readable_id&#x60; - resource_readable_id * &#x60;-resource_readable_id&#x60; - -resource_readable_id
     * @param {Array<string>} [topic] The topic name. To see a list of options go to api/v1/topics/
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async contentFileSearchRetrieve(
      aggregations?: Array<ContentFileSearchRetrieveAggregationsEnum>,
      content_feature_type?: Array<string>,
      dev_mode?: boolean | null,
      id?: Array<number>,
      limit?: number,
      ocw_topic?: Array<string>,
      offered_by?: Array<ContentFileSearchRetrieveOfferedByEnum>,
      offset?: number,
      platform?: Array<ContentFileSearchRetrievePlatformEnum>,
      q?: string,
      resource_id?: Array<number>,
      run_id?: Array<number>,
      sortby?: ContentFileSearchRetrieveSortbyEnum,
      topic?: Array<string>,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<ContentFileSearchResponse>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.contentFileSearchRetrieve(
          aggregations,
          content_feature_type,
          dev_mode,
          id,
          limit,
          ocw_topic,
          offered_by,
          offset,
          platform,
          q,
          resource_id,
          run_id,
          sortby,
          topic,
          options,
        )
      const index = configuration?.serverIndex ?? 0
      const operationBasePath =
        operationServerMap["ContentFileSearchApi.contentFileSearchRetrieve"]?.[
          index
        ]?.url
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, operationBasePath || basePath)
    },
  }
}

/**
 * ContentFileSearchApi - factory interface
 * @export
 */
export const ContentFileSearchApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = ContentFileSearchApiFp(configuration)
  return {
    /**
     * Search for content files
     * @summary Search
     * @param {ContentFileSearchApiContentFileSearchRetrieveRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    contentFileSearchRetrieve(
      requestParameters: ContentFileSearchApiContentFileSearchRetrieveRequest = {},
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<ContentFileSearchResponse> {
      return localVarFp
        .contentFileSearchRetrieve(
          requestParameters.aggregations,
          requestParameters.content_feature_type,
          requestParameters.dev_mode,
          requestParameters.id,
          requestParameters.limit,
          requestParameters.ocw_topic,
          requestParameters.offered_by,
          requestParameters.offset,
          requestParameters.platform,
          requestParameters.q,
          requestParameters.resource_id,
          requestParameters.run_id,
          requestParameters.sortby,
          requestParameters.topic,
          options,
        )
        .then((request) => request(axios, basePath))
    },
  }
}

/**
 * Request parameters for contentFileSearchRetrieve operation in ContentFileSearchApi.
 * @export
 * @interface ContentFileSearchApiContentFileSearchRetrieveRequest
 */
export interface ContentFileSearchApiContentFileSearchRetrieveRequest {
  /**
   * Show resource counts by category
   * @type {Array<'topic' | 'content_feature_type' | 'platform' | 'offered_by'>}
   * @memberof ContentFileSearchApiContentFileSearchRetrieve
   */
  readonly aggregations?: Array<ContentFileSearchRetrieveAggregationsEnum>

  /**
   * The feature type of the content file. Possible options are at api/v1/course_features/
   * @type {Array<string>}
   * @memberof ContentFileSearchApiContentFileSearchRetrieve
   */
  readonly content_feature_type?: Array<string>

  /**
   * If true return raw open search results with score explanations
   * @type {boolean}
   * @memberof ContentFileSearchApiContentFileSearchRetrieve
   */
  readonly dev_mode?: boolean | null

  /**
   * The id value for the content file
   * @type {Array<number>}
   * @memberof ContentFileSearchApiContentFileSearchRetrieve
   */
  readonly id?: Array<number>

  /**
   * Number of results to return per page
   * @type {number}
   * @memberof ContentFileSearchApiContentFileSearchRetrieve
   */
  readonly limit?: number

  /**
   * The ocw topic name.
   * @type {Array<string>}
   * @memberof ContentFileSearchApiContentFileSearchRetrieve
   */
  readonly ocw_topic?: Array<string>

  /**
   * The organization that offers the learning resource               * &#x60;mitx&#x60; - MITx * &#x60;ocw&#x60; - MIT OpenCourseWare * &#x60;bootcamps&#x60; - Bootcamps * &#x60;xpro&#x60; - MIT xPRO * &#x60;mitpe&#x60; - MIT Professional Education * &#x60;see&#x60; - MIT Sloan Executive Education
   * @type {Array<'mitx' | 'ocw' | 'bootcamps' | 'xpro' | 'mitpe' | 'see'>}
   * @memberof ContentFileSearchApiContentFileSearchRetrieve
   */
  readonly offered_by?: Array<ContentFileSearchRetrieveOfferedByEnum>

  /**
   * The initial index from which to return the results
   * @type {number}
   * @memberof ContentFileSearchApiContentFileSearchRetrieve
   */
  readonly offset?: number

  /**
   * The platform on which the learning resource is offered               * &#x60;edx&#x60; - edX * &#x60;ocw&#x60; - MIT OpenCourseWare * &#x60;oll&#x60; - Open Learning Library * &#x60;mitxonline&#x60; - MITx Online * &#x60;bootcamps&#x60; - Bootcamps * &#x60;xpro&#x60; - MIT xPRO * &#x60;csail&#x60; - CSAIL * &#x60;mitpe&#x60; - MIT Professional Education * &#x60;see&#x60; - MIT Sloan Executive Education * &#x60;scc&#x60; - Schwarzman College of Computing * &#x60;ctl&#x60; - Center for Transportation &amp; Logistics * &#x60;whu&#x60; - WHU * &#x60;susskind&#x60; - Susskind * &#x60;globalalumni&#x60; - Global Alumni * &#x60;simplilearn&#x60; - Simplilearn * &#x60;emeritus&#x60; - Emeritus * &#x60;podcast&#x60; - Podcast * &#x60;youtube&#x60; - YouTube
   * @type {Array<'edx' | 'ocw' | 'oll' | 'mitxonline' | 'bootcamps' | 'xpro' | 'csail' | 'mitpe' | 'see' | 'scc' | 'ctl' | 'whu' | 'susskind' | 'globalalumni' | 'simplilearn' | 'emeritus' | 'podcast' | 'youtube'>}
   * @memberof ContentFileSearchApiContentFileSearchRetrieve
   */
  readonly platform?: Array<ContentFileSearchRetrievePlatformEnum>

  /**
   * The search text
   * @type {string}
   * @memberof ContentFileSearchApiContentFileSearchRetrieve
   */
  readonly q?: string

  /**
   * The id value of the parent learning resource for the content file
   * @type {Array<number>}
   * @memberof ContentFileSearchApiContentFileSearchRetrieve
   */
  readonly resource_id?: Array<number>

  /**
   * The id value of the run that the content file belongs to
   * @type {Array<number>}
   * @memberof ContentFileSearchApiContentFileSearchRetrieve
   */
  readonly run_id?: Array<number>

  /**
   * if the parameter starts with \&#39;-\&#39; the sort is in descending order  * &#x60;id&#x60; - id * &#x60;-id&#x60; - -id * &#x60;resource_readable_id&#x60; - resource_readable_id * &#x60;-resource_readable_id&#x60; - -resource_readable_id
   * @type {'id' | '-id' | 'resource_readable_id' | '-resource_readable_id'}
   * @memberof ContentFileSearchApiContentFileSearchRetrieve
   */
  readonly sortby?: ContentFileSearchRetrieveSortbyEnum

  /**
   * The topic name. To see a list of options go to api/v1/topics/
   * @type {Array<string>}
   * @memberof ContentFileSearchApiContentFileSearchRetrieve
   */
  readonly topic?: Array<string>
}

/**
 * ContentFileSearchApi - object-oriented interface
 * @export
 * @class ContentFileSearchApi
 * @extends {BaseAPI}
 */
export class ContentFileSearchApi extends BaseAPI {
  /**
   * Search for content files
   * @summary Search
   * @param {ContentFileSearchApiContentFileSearchRetrieveRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ContentFileSearchApi
   */
  public contentFileSearchRetrieve(
    requestParameters: ContentFileSearchApiContentFileSearchRetrieveRequest = {},
    options?: RawAxiosRequestConfig,
  ) {
    return ContentFileSearchApiFp(this.configuration)
      .contentFileSearchRetrieve(
        requestParameters.aggregations,
        requestParameters.content_feature_type,
        requestParameters.dev_mode,
        requestParameters.id,
        requestParameters.limit,
        requestParameters.ocw_topic,
        requestParameters.offered_by,
        requestParameters.offset,
        requestParameters.platform,
        requestParameters.q,
        requestParameters.resource_id,
        requestParameters.run_id,
        requestParameters.sortby,
        requestParameters.topic,
        options,
      )
      .then((request) => request(this.axios, this.basePath))
  }
}

/**
 * @export
 */
export const ContentFileSearchRetrieveAggregationsEnum = {
  Topic: "topic",
  ContentFeatureType: "content_feature_type",
  Platform: "platform",
  OfferedBy: "offered_by",
} as const
export type ContentFileSearchRetrieveAggregationsEnum =
  (typeof ContentFileSearchRetrieveAggregationsEnum)[keyof typeof ContentFileSearchRetrieveAggregationsEnum]
/**
 * @export
 */
export const ContentFileSearchRetrieveOfferedByEnum = {
  Mitx: "mitx",
  Ocw: "ocw",
  Bootcamps: "bootcamps",
  Xpro: "xpro",
  Mitpe: "mitpe",
  See: "see",
} as const
export type ContentFileSearchRetrieveOfferedByEnum =
  (typeof ContentFileSearchRetrieveOfferedByEnum)[keyof typeof ContentFileSearchRetrieveOfferedByEnum]
/**
 * @export
 */
export const ContentFileSearchRetrievePlatformEnum = {
  Edx: "edx",
  Ocw: "ocw",
  Oll: "oll",
  Mitxonline: "mitxonline",
  Bootcamps: "bootcamps",
  Xpro: "xpro",
  Csail: "csail",
  Mitpe: "mitpe",
  See: "see",
  Scc: "scc",
  Ctl: "ctl",
  Whu: "whu",
  Susskind: "susskind",
  Globalalumni: "globalalumni",
  Simplilearn: "simplilearn",
  Emeritus: "emeritus",
  Podcast: "podcast",
  Youtube: "youtube",
} as const
export type ContentFileSearchRetrievePlatformEnum =
  (typeof ContentFileSearchRetrievePlatformEnum)[keyof typeof ContentFileSearchRetrievePlatformEnum]
/**
 * @export
 */
export const ContentFileSearchRetrieveSortbyEnum = {
  Id: "id",
  Id2: "-id",
  ResourceReadableId: "resource_readable_id",
  ResourceReadableId2: "-resource_readable_id",
} as const
export type ContentFileSearchRetrieveSortbyEnum =
  (typeof ContentFileSearchRetrieveSortbyEnum)[keyof typeof ContentFileSearchRetrieveSortbyEnum]

/**
 * ContentfilesApi - axios parameter creator
 * @export
 */
export const ContentfilesApiAxiosParamCreator = function (
  configuration?: Configuration,
) {
  return {
    /**
     * Viewset for ContentFiles
     * @summary List
     * @param {number} learning_resource_id id of the parent learning resource
     * @param {Array<string>} [content_feature_type] Multiple values may be separated by commas.
     * @param {number} [limit] Number of results to return per page.
     * @param {Array<ContentfilesListOfferedByEnum>} [offered_by] The organization that offers a learning resource the content file belongs to  * &#x60;mitx&#x60; - MITx * &#x60;ocw&#x60; - MIT OpenCourseWare * &#x60;bootcamps&#x60; - Bootcamps * &#x60;xpro&#x60; - MIT xPRO * &#x60;mitpe&#x60; - MIT Professional Education * &#x60;see&#x60; - MIT Sloan Executive Education
     * @param {number} [offset] The initial index from which to return the results.
     * @param {Array<ContentfilesListPlatformEnum>} [platform] The platform on which learning resources the content file belongs to is offered  * &#x60;edx&#x60; - edX * &#x60;ocw&#x60; - MIT OpenCourseWare * &#x60;oll&#x60; - Open Learning Library * &#x60;mitxonline&#x60; - MITx Online * &#x60;bootcamps&#x60; - Bootcamps * &#x60;xpro&#x60; - MIT xPRO * &#x60;csail&#x60; - CSAIL * &#x60;mitpe&#x60; - MIT Professional Education * &#x60;see&#x60; - MIT Sloan Executive Education * &#x60;scc&#x60; - Schwarzman College of Computing * &#x60;ctl&#x60; - Center for Transportation &amp; Logistics * &#x60;whu&#x60; - WHU * &#x60;susskind&#x60; - Susskind * &#x60;globalalumni&#x60; - Global Alumni * &#x60;simplilearn&#x60; - Simplilearn * &#x60;emeritus&#x60; - Emeritus * &#x60;podcast&#x60; - Podcast * &#x60;youtube&#x60; - YouTube
     * @param {Array<number>} [resource_id] Multiple values may be separated by commas.
     * @param {Array<number>} [run_id] Multiple values may be separated by commas.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    contentfilesList: async (
      learning_resource_id: number,
      content_feature_type?: Array<string>,
      limit?: number,
      offered_by?: Array<ContentfilesListOfferedByEnum>,
      offset?: number,
      platform?: Array<ContentfilesListPlatformEnum>,
      resource_id?: Array<number>,
      run_id?: Array<number>,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'learning_resource_id' is not null or undefined
      assertParamExists(
        "contentfilesList",
        "learning_resource_id",
        learning_resource_id,
      )
      const localVarPath = `/api/v1/contentfiles/`.replace(
        `{${"learning_resource_id"}}`,
        encodeURIComponent(String(learning_resource_id)),
      )
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      if (content_feature_type) {
        localVarQueryParameter["content_feature_type"] =
          content_feature_type.join(COLLECTION_FORMATS.csv)
      }

      if (limit !== undefined) {
        localVarQueryParameter["limit"] = limit
      }

      if (offered_by) {
        localVarQueryParameter["offered_by"] = offered_by
      }

      if (offset !== undefined) {
        localVarQueryParameter["offset"] = offset
      }

      if (platform) {
        localVarQueryParameter["platform"] = platform
      }

      if (resource_id) {
        localVarQueryParameter["resource_id"] = resource_id.join(
          COLLECTION_FORMATS.csv,
        )
      }

      if (run_id) {
        localVarQueryParameter["run_id"] = run_id.join(COLLECTION_FORMATS.csv)
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     * Viewset for ContentFiles
     * @summary Retrieve
     * @param {number} id A unique integer value identifying this contentfile.
     * @param {number} learning_resource_id id of the parent learning resource
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    contentfilesRetrieve: async (
      id: number,
      learning_resource_id: number,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists("contentfilesRetrieve", "id", id)
      // verify required parameter 'learning_resource_id' is not null or undefined
      assertParamExists(
        "contentfilesRetrieve",
        "learning_resource_id",
        learning_resource_id,
      )
      const localVarPath = `/api/v1/contentfiles/{id}/`
        .replace(`{${"id"}}`, encodeURIComponent(String(id)))
        .replace(
          `{${"learning_resource_id"}}`,
          encodeURIComponent(String(learning_resource_id)),
        )
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
  }
}

/**
 * ContentfilesApi - functional programming interface
 * @export
 */
export const ContentfilesApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator =
    ContentfilesApiAxiosParamCreator(configuration)
  return {
    /**
     * Viewset for ContentFiles
     * @summary List
     * @param {number} learning_resource_id id of the parent learning resource
     * @param {Array<string>} [content_feature_type] Multiple values may be separated by commas.
     * @param {number} [limit] Number of results to return per page.
     * @param {Array<ContentfilesListOfferedByEnum>} [offered_by] The organization that offers a learning resource the content file belongs to  * &#x60;mitx&#x60; - MITx * &#x60;ocw&#x60; - MIT OpenCourseWare * &#x60;bootcamps&#x60; - Bootcamps * &#x60;xpro&#x60; - MIT xPRO * &#x60;mitpe&#x60; - MIT Professional Education * &#x60;see&#x60; - MIT Sloan Executive Education
     * @param {number} [offset] The initial index from which to return the results.
     * @param {Array<ContentfilesListPlatformEnum>} [platform] The platform on which learning resources the content file belongs to is offered  * &#x60;edx&#x60; - edX * &#x60;ocw&#x60; - MIT OpenCourseWare * &#x60;oll&#x60; - Open Learning Library * &#x60;mitxonline&#x60; - MITx Online * &#x60;bootcamps&#x60; - Bootcamps * &#x60;xpro&#x60; - MIT xPRO * &#x60;csail&#x60; - CSAIL * &#x60;mitpe&#x60; - MIT Professional Education * &#x60;see&#x60; - MIT Sloan Executive Education * &#x60;scc&#x60; - Schwarzman College of Computing * &#x60;ctl&#x60; - Center for Transportation &amp; Logistics * &#x60;whu&#x60; - WHU * &#x60;susskind&#x60; - Susskind * &#x60;globalalumni&#x60; - Global Alumni * &#x60;simplilearn&#x60; - Simplilearn * &#x60;emeritus&#x60; - Emeritus * &#x60;podcast&#x60; - Podcast * &#x60;youtube&#x60; - YouTube
     * @param {Array<number>} [resource_id] Multiple values may be separated by commas.
     * @param {Array<number>} [run_id] Multiple values may be separated by commas.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async contentfilesList(
      learning_resource_id: number,
      content_feature_type?: Array<string>,
      limit?: number,
      offered_by?: Array<ContentfilesListOfferedByEnum>,
      offset?: number,
      platform?: Array<ContentfilesListPlatformEnum>,
      resource_id?: Array<number>,
      run_id?: Array<number>,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<PaginatedContentFileList>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.contentfilesList(
          learning_resource_id,
          content_feature_type,
          limit,
          offered_by,
          offset,
          platform,
          resource_id,
          run_id,
          options,
        )
      const index = configuration?.serverIndex ?? 0
      const operationBasePath =
        operationServerMap["ContentfilesApi.contentfilesList"]?.[index]?.url
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, operationBasePath || basePath)
    },
    /**
     * Viewset for ContentFiles
     * @summary Retrieve
     * @param {number} id A unique integer value identifying this contentfile.
     * @param {number} learning_resource_id id of the parent learning resource
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async contentfilesRetrieve(
      id: number,
      learning_resource_id: number,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<ContentFile>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.contentfilesRetrieve(
          id,
          learning_resource_id,
          options,
        )
      const index = configuration?.serverIndex ?? 0
      const operationBasePath =
        operationServerMap["ContentfilesApi.contentfilesRetrieve"]?.[index]?.url
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, operationBasePath || basePath)
    },
  }
}

/**
 * ContentfilesApi - factory interface
 * @export
 */
export const ContentfilesApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = ContentfilesApiFp(configuration)
  return {
    /**
     * Viewset for ContentFiles
     * @summary List
     * @param {ContentfilesApiContentfilesListRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    contentfilesList(
      requestParameters: ContentfilesApiContentfilesListRequest,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<PaginatedContentFileList> {
      return localVarFp
        .contentfilesList(
          requestParameters.learning_resource_id,
          requestParameters.content_feature_type,
          requestParameters.limit,
          requestParameters.offered_by,
          requestParameters.offset,
          requestParameters.platform,
          requestParameters.resource_id,
          requestParameters.run_id,
          options,
        )
        .then((request) => request(axios, basePath))
    },
    /**
     * Viewset for ContentFiles
     * @summary Retrieve
     * @param {ContentfilesApiContentfilesRetrieveRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    contentfilesRetrieve(
      requestParameters: ContentfilesApiContentfilesRetrieveRequest,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<ContentFile> {
      return localVarFp
        .contentfilesRetrieve(
          requestParameters.id,
          requestParameters.learning_resource_id,
          options,
        )
        .then((request) => request(axios, basePath))
    },
  }
}

/**
 * Request parameters for contentfilesList operation in ContentfilesApi.
 * @export
 * @interface ContentfilesApiContentfilesListRequest
 */
export interface ContentfilesApiContentfilesListRequest {
  /**
   * id of the parent learning resource
   * @type {number}
   * @memberof ContentfilesApiContentfilesList
   */
  readonly learning_resource_id: number

  /**
   * Multiple values may be separated by commas.
   * @type {Array<string>}
   * @memberof ContentfilesApiContentfilesList
   */
  readonly content_feature_type?: Array<string>

  /**
   * Number of results to return per page.
   * @type {number}
   * @memberof ContentfilesApiContentfilesList
   */
  readonly limit?: number

  /**
   * The organization that offers a learning resource the content file belongs to  * &#x60;mitx&#x60; - MITx * &#x60;ocw&#x60; - MIT OpenCourseWare * &#x60;bootcamps&#x60; - Bootcamps * &#x60;xpro&#x60; - MIT xPRO * &#x60;mitpe&#x60; - MIT Professional Education * &#x60;see&#x60; - MIT Sloan Executive Education
   * @type {Array<'bootcamps' | 'mitpe' | 'mitx' | 'ocw' | 'see' | 'xpro'>}
   * @memberof ContentfilesApiContentfilesList
   */
  readonly offered_by?: Array<ContentfilesListOfferedByEnum>

  /**
   * The initial index from which to return the results.
   * @type {number}
   * @memberof ContentfilesApiContentfilesList
   */
  readonly offset?: number

  /**
   * The platform on which learning resources the content file belongs to is offered  * &#x60;edx&#x60; - edX * &#x60;ocw&#x60; - MIT OpenCourseWare * &#x60;oll&#x60; - Open Learning Library * &#x60;mitxonline&#x60; - MITx Online * &#x60;bootcamps&#x60; - Bootcamps * &#x60;xpro&#x60; - MIT xPRO * &#x60;csail&#x60; - CSAIL * &#x60;mitpe&#x60; - MIT Professional Education * &#x60;see&#x60; - MIT Sloan Executive Education * &#x60;scc&#x60; - Schwarzman College of Computing * &#x60;ctl&#x60; - Center for Transportation &amp; Logistics * &#x60;whu&#x60; - WHU * &#x60;susskind&#x60; - Susskind * &#x60;globalalumni&#x60; - Global Alumni * &#x60;simplilearn&#x60; - Simplilearn * &#x60;emeritus&#x60; - Emeritus * &#x60;podcast&#x60; - Podcast * &#x60;youtube&#x60; - YouTube
   * @type {Array<'bootcamps' | 'csail' | 'ctl' | 'edx' | 'emeritus' | 'globalalumni' | 'mitpe' | 'mitxonline' | 'ocw' | 'oll' | 'podcast' | 'scc' | 'see' | 'simplilearn' | 'susskind' | 'whu' | 'xpro' | 'youtube'>}
   * @memberof ContentfilesApiContentfilesList
   */
  readonly platform?: Array<ContentfilesListPlatformEnum>

  /**
   * Multiple values may be separated by commas.
   * @type {Array<number>}
   * @memberof ContentfilesApiContentfilesList
   */
  readonly resource_id?: Array<number>

  /**
   * Multiple values may be separated by commas.
   * @type {Array<number>}
   * @memberof ContentfilesApiContentfilesList
   */
  readonly run_id?: Array<number>
}

/**
 * Request parameters for contentfilesRetrieve operation in ContentfilesApi.
 * @export
 * @interface ContentfilesApiContentfilesRetrieveRequest
 */
export interface ContentfilesApiContentfilesRetrieveRequest {
  /**
   * A unique integer value identifying this contentfile.
   * @type {number}
   * @memberof ContentfilesApiContentfilesRetrieve
   */
  readonly id: number

  /**
   * id of the parent learning resource
   * @type {number}
   * @memberof ContentfilesApiContentfilesRetrieve
   */
  readonly learning_resource_id: number
}

/**
 * ContentfilesApi - object-oriented interface
 * @export
 * @class ContentfilesApi
 * @extends {BaseAPI}
 */
export class ContentfilesApi extends BaseAPI {
  /**
   * Viewset for ContentFiles
   * @summary List
   * @param {ContentfilesApiContentfilesListRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ContentfilesApi
   */
  public contentfilesList(
    requestParameters: ContentfilesApiContentfilesListRequest,
    options?: RawAxiosRequestConfig,
  ) {
    return ContentfilesApiFp(this.configuration)
      .contentfilesList(
        requestParameters.learning_resource_id,
        requestParameters.content_feature_type,
        requestParameters.limit,
        requestParameters.offered_by,
        requestParameters.offset,
        requestParameters.platform,
        requestParameters.resource_id,
        requestParameters.run_id,
        options,
      )
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   * Viewset for ContentFiles
   * @summary Retrieve
   * @param {ContentfilesApiContentfilesRetrieveRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ContentfilesApi
   */
  public contentfilesRetrieve(
    requestParameters: ContentfilesApiContentfilesRetrieveRequest,
    options?: RawAxiosRequestConfig,
  ) {
    return ContentfilesApiFp(this.configuration)
      .contentfilesRetrieve(
        requestParameters.id,
        requestParameters.learning_resource_id,
        options,
      )
      .then((request) => request(this.axios, this.basePath))
  }
}

/**
 * @export
 */
export const ContentfilesListOfferedByEnum = {
  Bootcamps: "bootcamps",
  Mitpe: "mitpe",
  Mitx: "mitx",
  Ocw: "ocw",
  See: "see",
  Xpro: "xpro",
} as const
export type ContentfilesListOfferedByEnum =
  (typeof ContentfilesListOfferedByEnum)[keyof typeof ContentfilesListOfferedByEnum]
/**
 * @export
 */
export const ContentfilesListPlatformEnum = {
  Bootcamps: "bootcamps",
  Csail: "csail",
  Ctl: "ctl",
  Edx: "edx",
  Emeritus: "emeritus",
  Globalalumni: "globalalumni",
  Mitpe: "mitpe",
  Mitxonline: "mitxonline",
  Ocw: "ocw",
  Oll: "oll",
  Podcast: "podcast",
  Scc: "scc",
  See: "see",
  Simplilearn: "simplilearn",
  Susskind: "susskind",
  Whu: "whu",
  Xpro: "xpro",
  Youtube: "youtube",
} as const
export type ContentfilesListPlatformEnum =
  (typeof ContentfilesListPlatformEnum)[keyof typeof ContentfilesListPlatformEnum]

/**
 * CourseFeaturesApi - axios parameter creator
 * @export
 */
export const CourseFeaturesApiAxiosParamCreator = function (
  configuration?: Configuration,
) {
  return {
    /**
     * Course Features and Content Feature Types
     * @summary List
     * @param {number} [limit] Number of results to return per page.
     * @param {number} [offset] The initial index from which to return the results.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    courseFeaturesList: async (
      limit?: number,
      offset?: number,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/api/v1/course_features/`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      if (limit !== undefined) {
        localVarQueryParameter["limit"] = limit
      }

      if (offset !== undefined) {
        localVarQueryParameter["offset"] = offset
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     * Course Features and Content Feature Types
     * @summary Retrieve
     * @param {number} id A unique integer value identifying this learning resource content tag.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    courseFeaturesRetrieve: async (
      id: number,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists("courseFeaturesRetrieve", "id", id)
      const localVarPath = `/api/v1/course_features/{id}/`.replace(
        `{${"id"}}`,
        encodeURIComponent(String(id)),
      )
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
  }
}

/**
 * CourseFeaturesApi - functional programming interface
 * @export
 */
export const CourseFeaturesApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator =
    CourseFeaturesApiAxiosParamCreator(configuration)
  return {
    /**
     * Course Features and Content Feature Types
     * @summary List
     * @param {number} [limit] Number of results to return per page.
     * @param {number} [offset] The initial index from which to return the results.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async courseFeaturesList(
      limit?: number,
      offset?: number,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<PaginatedLearningResourceContentTagList>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.courseFeaturesList(
          limit,
          offset,
          options,
        )
      const index = configuration?.serverIndex ?? 0
      const operationBasePath =
        operationServerMap["CourseFeaturesApi.courseFeaturesList"]?.[index]?.url
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, operationBasePath || basePath)
    },
    /**
     * Course Features and Content Feature Types
     * @summary Retrieve
     * @param {number} id A unique integer value identifying this learning resource content tag.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async courseFeaturesRetrieve(
      id: number,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<LearningResourceContentTag>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.courseFeaturesRetrieve(id, options)
      const index = configuration?.serverIndex ?? 0
      const operationBasePath =
        operationServerMap["CourseFeaturesApi.courseFeaturesRetrieve"]?.[index]
          ?.url
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, operationBasePath || basePath)
    },
  }
}

/**
 * CourseFeaturesApi - factory interface
 * @export
 */
export const CourseFeaturesApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = CourseFeaturesApiFp(configuration)
  return {
    /**
     * Course Features and Content Feature Types
     * @summary List
     * @param {CourseFeaturesApiCourseFeaturesListRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    courseFeaturesList(
      requestParameters: CourseFeaturesApiCourseFeaturesListRequest = {},
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<PaginatedLearningResourceContentTagList> {
      return localVarFp
        .courseFeaturesList(
          requestParameters.limit,
          requestParameters.offset,
          options,
        )
        .then((request) => request(axios, basePath))
    },
    /**
     * Course Features and Content Feature Types
     * @summary Retrieve
     * @param {CourseFeaturesApiCourseFeaturesRetrieveRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    courseFeaturesRetrieve(
      requestParameters: CourseFeaturesApiCourseFeaturesRetrieveRequest,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<LearningResourceContentTag> {
      return localVarFp
        .courseFeaturesRetrieve(requestParameters.id, options)
        .then((request) => request(axios, basePath))
    },
  }
}

/**
 * Request parameters for courseFeaturesList operation in CourseFeaturesApi.
 * @export
 * @interface CourseFeaturesApiCourseFeaturesListRequest
 */
export interface CourseFeaturesApiCourseFeaturesListRequest {
  /**
   * Number of results to return per page.
   * @type {number}
   * @memberof CourseFeaturesApiCourseFeaturesList
   */
  readonly limit?: number

  /**
   * The initial index from which to return the results.
   * @type {number}
   * @memberof CourseFeaturesApiCourseFeaturesList
   */
  readonly offset?: number
}

/**
 * Request parameters for courseFeaturesRetrieve operation in CourseFeaturesApi.
 * @export
 * @interface CourseFeaturesApiCourseFeaturesRetrieveRequest
 */
export interface CourseFeaturesApiCourseFeaturesRetrieveRequest {
  /**
   * A unique integer value identifying this learning resource content tag.
   * @type {number}
   * @memberof CourseFeaturesApiCourseFeaturesRetrieve
   */
  readonly id: number
}

/**
 * CourseFeaturesApi - object-oriented interface
 * @export
 * @class CourseFeaturesApi
 * @extends {BaseAPI}
 */
export class CourseFeaturesApi extends BaseAPI {
  /**
   * Course Features and Content Feature Types
   * @summary List
   * @param {CourseFeaturesApiCourseFeaturesListRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CourseFeaturesApi
   */
  public courseFeaturesList(
    requestParameters: CourseFeaturesApiCourseFeaturesListRequest = {},
    options?: RawAxiosRequestConfig,
  ) {
    return CourseFeaturesApiFp(this.configuration)
      .courseFeaturesList(
        requestParameters.limit,
        requestParameters.offset,
        options,
      )
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   * Course Features and Content Feature Types
   * @summary Retrieve
   * @param {CourseFeaturesApiCourseFeaturesRetrieveRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CourseFeaturesApi
   */
  public courseFeaturesRetrieve(
    requestParameters: CourseFeaturesApiCourseFeaturesRetrieveRequest,
    options?: RawAxiosRequestConfig,
  ) {
    return CourseFeaturesApiFp(this.configuration)
      .courseFeaturesRetrieve(requestParameters.id, options)
      .then((request) => request(this.axios, this.basePath))
  }
}

/**
 * CoursesApi - axios parameter creator
 * @export
 */
export const CoursesApiAxiosParamCreator = function (
  configuration?: Configuration,
) {
  return {
    /**
     * Show content files for a learning resource
     * @summary Learning Resource Content File List
     * @param {number} learning_resource_id id of the parent learning resource
     * @param {Array<string>} [content_feature_type] Multiple values may be separated by commas.
     * @param {number} [limit] Number of results to return per page.
     * @param {Array<CoursesContentfilesListOfferedByEnum>} [offered_by] The organization that offers a learning resource the content file belongs to  * &#x60;mitx&#x60; - MITx * &#x60;ocw&#x60; - MIT OpenCourseWare * &#x60;bootcamps&#x60; - Bootcamps * &#x60;xpro&#x60; - MIT xPRO * &#x60;mitpe&#x60; - MIT Professional Education * &#x60;see&#x60; - MIT Sloan Executive Education
     * @param {number} [offset] The initial index from which to return the results.
     * @param {Array<CoursesContentfilesListPlatformEnum>} [platform] The platform on which learning resources the content file belongs to is offered  * &#x60;edx&#x60; - edX * &#x60;ocw&#x60; - MIT OpenCourseWare * &#x60;oll&#x60; - Open Learning Library * &#x60;mitxonline&#x60; - MITx Online * &#x60;bootcamps&#x60; - Bootcamps * &#x60;xpro&#x60; - MIT xPRO * &#x60;csail&#x60; - CSAIL * &#x60;mitpe&#x60; - MIT Professional Education * &#x60;see&#x60; - MIT Sloan Executive Education * &#x60;scc&#x60; - Schwarzman College of Computing * &#x60;ctl&#x60; - Center for Transportation &amp; Logistics * &#x60;whu&#x60; - WHU * &#x60;susskind&#x60; - Susskind * &#x60;globalalumni&#x60; - Global Alumni * &#x60;simplilearn&#x60; - Simplilearn * &#x60;emeritus&#x60; - Emeritus * &#x60;podcast&#x60; - Podcast * &#x60;youtube&#x60; - YouTube
     * @param {Array<number>} [resource_id] Multiple values may be separated by commas.
     * @param {Array<number>} [run_id] Multiple values may be separated by commas.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    coursesContentfilesList: async (
      learning_resource_id: number,
      content_feature_type?: Array<string>,
      limit?: number,
      offered_by?: Array<CoursesContentfilesListOfferedByEnum>,
      offset?: number,
      platform?: Array<CoursesContentfilesListPlatformEnum>,
      resource_id?: Array<number>,
      run_id?: Array<number>,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'learning_resource_id' is not null or undefined
      assertParamExists(
        "coursesContentfilesList",
        "learning_resource_id",
        learning_resource_id,
      )
      const localVarPath =
        `/api/v1/courses/{learning_resource_id}/contentfiles/`.replace(
          `{${"learning_resource_id"}}`,
          encodeURIComponent(String(learning_resource_id)),
        )
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      if (content_feature_type) {
        localVarQueryParameter["content_feature_type"] =
          content_feature_type.join(COLLECTION_FORMATS.csv)
      }

      if (limit !== undefined) {
        localVarQueryParameter["limit"] = limit
      }

      if (offered_by) {
        localVarQueryParameter["offered_by"] = offered_by
      }

      if (offset !== undefined) {
        localVarQueryParameter["offset"] = offset
      }

      if (platform) {
        localVarQueryParameter["platform"] = platform
      }

      if (resource_id) {
        localVarQueryParameter["resource_id"] = resource_id.join(
          COLLECTION_FORMATS.csv,
        )
      }

      if (run_id) {
        localVarQueryParameter["run_id"] = run_id.join(COLLECTION_FORMATS.csv)
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     * Show content files for a learning resource
     * @summary Learning Resource Content File Retrieve
     * @param {number} id A unique integer value identifying this contentfile.
     * @param {number} learning_resource_id id of the parent learning resource
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    coursesContentfilesRetrieve: async (
      id: number,
      learning_resource_id: number,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists("coursesContentfilesRetrieve", "id", id)
      // verify required parameter 'learning_resource_id' is not null or undefined
      assertParamExists(
        "coursesContentfilesRetrieve",
        "learning_resource_id",
        learning_resource_id,
      )
      const localVarPath =
        `/api/v1/courses/{learning_resource_id}/contentfiles/{id}/`
          .replace(`{${"id"}}`, encodeURIComponent(String(id)))
          .replace(
            `{${"learning_resource_id"}}`,
            encodeURIComponent(String(learning_resource_id)),
          )
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     * Get a paginated list of courses
     * @summary List
     * @param {boolean} [certification]
     * @param {Array<CoursesListCertificationTypeEnum>} [certification_type] The type of certification offered  * &#x60;micromasters&#x60; - MicroMasters Credential * &#x60;professional&#x60; - Professional Certificate * &#x60;completion&#x60; - Certificate of Completion * &#x60;none&#x60; - No Certificate
     * @param {Array<string>} [course_feature] Multiple values may be separated by commas.
     * @param {Array<Array<CoursesListDeliveryEnum>>} [delivery] The delivery of course/program resources  * &#x60;online&#x60; - Online * &#x60;hybrid&#x60; - Hybrid * &#x60;in_person&#x60; - In person * &#x60;offline&#x60; - Offline
     * @param {Array<CoursesListDepartmentEnum>} [department] The department that offers learning resources  * &#x60;1&#x60; - Civil and Environmental Engineering * &#x60;2&#x60; - Mechanical Engineering * &#x60;3&#x60; - Materials Science and Engineering * &#x60;4&#x60; - Architecture * &#x60;5&#x60; - Chemistry * &#x60;6&#x60; - Electrical Engineering and Computer Science * &#x60;7&#x60; - Biology * &#x60;8&#x60; - Physics * &#x60;9&#x60; - Brain and Cognitive Sciences * &#x60;10&#x60; - Chemical Engineering * &#x60;11&#x60; - Urban Studies and Planning * &#x60;12&#x60; - Earth, Atmospheric, and Planetary Sciences * &#x60;14&#x60; - Economics * &#x60;15&#x60; - Management * &#x60;16&#x60; - Aeronautics and Astronautics * &#x60;17&#x60; - Political Science * &#x60;18&#x60; - Mathematics * &#x60;20&#x60; - Biological Engineering * &#x60;21A&#x60; - Anthropology * &#x60;21G&#x60; - Global Languages * &#x60;21H&#x60; - History * &#x60;21L&#x60; - Literature * &#x60;21M&#x60; - Music and Theater Arts * &#x60;22&#x60; - Nuclear Science and Engineering * &#x60;24&#x60; - Linguistics and Philosophy * &#x60;CC&#x60; - Concourse * &#x60;CMS-W&#x60; - Comparative Media Studies/Writing * &#x60;EC&#x60; - Edgerton Center * &#x60;ES&#x60; - Experimental Study Group * &#x60;ESD&#x60; - Engineering Systems Division * &#x60;HST&#x60; - Medical Engineering and Science * &#x60;IDS&#x60; - Data, Systems, and Society * &#x60;MAS&#x60; - Media Arts and Sciences * &#x60;PE&#x60; - Athletics, Physical Education and Recreation * &#x60;SP&#x60; - Special Programs * &#x60;STS&#x60; - Science, Technology, and Society * &#x60;WGS&#x60; - Women\&#39;s and Gender Studies
     * @param {boolean} [free] The course/program is offered for free
     * @param {Array<CoursesListLevelEnum>} [level] The academic level of the resources  * &#x60;undergraduate&#x60; - Undergraduate * &#x60;graduate&#x60; - Graduate * &#x60;high_school&#x60; - High School * &#x60;noncredit&#x60; - Non-Credit * &#x60;advanced&#x60; - Advanced * &#x60;intermediate&#x60; - Intermediate * &#x60;introductory&#x60; - Introductory
     * @param {number} [limit] Number of results to return per page.
     * @param {Array<CoursesListOfferedByEnum>} [offered_by] The organization that offers a learning resource  * &#x60;mitx&#x60; - MITx * &#x60;ocw&#x60; - MIT OpenCourseWare * &#x60;bootcamps&#x60; - Bootcamps * &#x60;xpro&#x60; - MIT xPRO * &#x60;mitpe&#x60; - MIT Professional Education * &#x60;see&#x60; - MIT Sloan Executive Education
     * @param {number} [offset] The initial index from which to return the results.
     * @param {Array<CoursesListPlatformEnum>} [platform] The platform on which learning resources are offered  * &#x60;edx&#x60; - edX * &#x60;ocw&#x60; - MIT OpenCourseWare * &#x60;oll&#x60; - Open Learning Library * &#x60;mitxonline&#x60; - MITx Online * &#x60;bootcamps&#x60; - Bootcamps * &#x60;xpro&#x60; - MIT xPRO * &#x60;csail&#x60; - CSAIL * &#x60;mitpe&#x60; - MIT Professional Education * &#x60;see&#x60; - MIT Sloan Executive Education * &#x60;scc&#x60; - Schwarzman College of Computing * &#x60;ctl&#x60; - Center for Transportation &amp; Logistics * &#x60;whu&#x60; - WHU * &#x60;susskind&#x60; - Susskind * &#x60;globalalumni&#x60; - Global Alumni * &#x60;simplilearn&#x60; - Simplilearn * &#x60;emeritus&#x60; - Emeritus * &#x60;podcast&#x60; - Podcast * &#x60;youtube&#x60; - YouTube
     * @param {boolean} [professional]
     * @param {Array<string>} [readable_id] Multiple values may be separated by commas.
     * @param {Array<CoursesListResourceCategoryEnum>} [resource_category] The resource category of the learning resources  * &#x60;course&#x60; - Course * &#x60;program&#x60; - Program * &#x60;learning_material&#x60; - Learning Material
     * @param {Array<CoursesListResourceTypeEnum>} [resource_type] The type of learning resource  * &#x60;course&#x60; - Course * &#x60;program&#x60; - Program * &#x60;learning_path&#x60; - Learning Path * &#x60;podcast&#x60; - Podcast * &#x60;podcast_episode&#x60; - Podcast Episode * &#x60;video&#x60; - Video * &#x60;video_playlist&#x60; - Video Playlist
     * @param {CoursesListSortbyEnum} [sortby] Sort By  * &#x60;id&#x60; - Object ID ascending * &#x60;-id&#x60; - Object ID descending * &#x60;readable_id&#x60; - Readable ID ascending * &#x60;-readable_id&#x60; - Readable ID descending * &#x60;last_modified&#x60; - Last Modified Date ascending * &#x60;-last_modified&#x60; - Last Modified Date descending * &#x60;new&#x60; - Newest resources first * &#x60;start_date&#x60; - Start Date ascending * &#x60;-start_date&#x60; - Start Date descending * &#x60;mitcoursenumber&#x60; - MIT course number ascending * &#x60;-mitcoursenumber&#x60; - MIT course number descending * &#x60;views&#x60; - Popularity ascending * &#x60;-views&#x60; - Popularity descending * &#x60;upcoming&#x60; - Next start date ascending
     * @param {Array<string>} [topic] Multiple values may be separated by commas.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    coursesList: async (
      certification?: boolean,
      certification_type?: Array<CoursesListCertificationTypeEnum>,
      course_feature?: Array<string>,
      delivery?: Array<Array<CoursesListDeliveryEnum>>,
      department?: Array<CoursesListDepartmentEnum>,
      free?: boolean,
      level?: Array<CoursesListLevelEnum>,
      limit?: number,
      offered_by?: Array<CoursesListOfferedByEnum>,
      offset?: number,
      platform?: Array<CoursesListPlatformEnum>,
      professional?: boolean,
      readable_id?: Array<string>,
      resource_category?: Array<CoursesListResourceCategoryEnum>,
      resource_type?: Array<CoursesListResourceTypeEnum>,
      sortby?: CoursesListSortbyEnum,
      topic?: Array<string>,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/api/v1/courses/`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      if (certification !== undefined) {
        localVarQueryParameter["certification"] = certification
      }

      if (certification_type) {
        localVarQueryParameter["certification_type"] = certification_type
      }

      if (course_feature) {
        localVarQueryParameter["course_feature"] = course_feature.join(
          COLLECTION_FORMATS.csv,
        )
      }

      if (delivery) {
        localVarQueryParameter["delivery"] = delivery
      }

      if (department) {
        localVarQueryParameter["department"] = department
      }

      if (free !== undefined) {
        localVarQueryParameter["free"] = free
      }

      if (level) {
        localVarQueryParameter["level"] = level
      }

      if (limit !== undefined) {
        localVarQueryParameter["limit"] = limit
      }

      if (offered_by) {
        localVarQueryParameter["offered_by"] = offered_by
      }

      if (offset !== undefined) {
        localVarQueryParameter["offset"] = offset
      }

      if (platform) {
        localVarQueryParameter["platform"] = platform
      }

      if (professional !== undefined) {
        localVarQueryParameter["professional"] = professional
      }

      if (readable_id) {
        localVarQueryParameter["readable_id"] = readable_id.join(
          COLLECTION_FORMATS.csv,
        )
      }

      if (resource_category) {
        localVarQueryParameter["resource_category"] = resource_category
      }

      if (resource_type) {
        localVarQueryParameter["resource_type"] = resource_type
      }

      if (sortby !== undefined) {
        localVarQueryParameter["sortby"] = sortby
      }

      if (topic) {
        localVarQueryParameter["topic"] = topic.join(COLLECTION_FORMATS.csv)
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     * Retrieve a single course
     * @summary Retrieve
     * @param {number} id A unique integer value identifying this learning resource.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    coursesRetrieve: async (
      id: number,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists("coursesRetrieve", "id", id)
      const localVarPath = `/api/v1/courses/{id}/`.replace(
        `{${"id"}}`,
        encodeURIComponent(String(id)),
      )
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
  }
}

/**
 * CoursesApi - functional programming interface
 * @export
 */
export const CoursesApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = CoursesApiAxiosParamCreator(configuration)
  return {
    /**
     * Show content files for a learning resource
     * @summary Learning Resource Content File List
     * @param {number} learning_resource_id id of the parent learning resource
     * @param {Array<string>} [content_feature_type] Multiple values may be separated by commas.
     * @param {number} [limit] Number of results to return per page.
     * @param {Array<CoursesContentfilesListOfferedByEnum>} [offered_by] The organization that offers a learning resource the content file belongs to  * &#x60;mitx&#x60; - MITx * &#x60;ocw&#x60; - MIT OpenCourseWare * &#x60;bootcamps&#x60; - Bootcamps * &#x60;xpro&#x60; - MIT xPRO * &#x60;mitpe&#x60; - MIT Professional Education * &#x60;see&#x60; - MIT Sloan Executive Education
     * @param {number} [offset] The initial index from which to return the results.
     * @param {Array<CoursesContentfilesListPlatformEnum>} [platform] The platform on which learning resources the content file belongs to is offered  * &#x60;edx&#x60; - edX * &#x60;ocw&#x60; - MIT OpenCourseWare * &#x60;oll&#x60; - Open Learning Library * &#x60;mitxonline&#x60; - MITx Online * &#x60;bootcamps&#x60; - Bootcamps * &#x60;xpro&#x60; - MIT xPRO * &#x60;csail&#x60; - CSAIL * &#x60;mitpe&#x60; - MIT Professional Education * &#x60;see&#x60; - MIT Sloan Executive Education * &#x60;scc&#x60; - Schwarzman College of Computing * &#x60;ctl&#x60; - Center for Transportation &amp; Logistics * &#x60;whu&#x60; - WHU * &#x60;susskind&#x60; - Susskind * &#x60;globalalumni&#x60; - Global Alumni * &#x60;simplilearn&#x60; - Simplilearn * &#x60;emeritus&#x60; - Emeritus * &#x60;podcast&#x60; - Podcast * &#x60;youtube&#x60; - YouTube
     * @param {Array<number>} [resource_id] Multiple values may be separated by commas.
     * @param {Array<number>} [run_id] Multiple values may be separated by commas.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async coursesContentfilesList(
      learning_resource_id: number,
      content_feature_type?: Array<string>,
      limit?: number,
      offered_by?: Array<CoursesContentfilesListOfferedByEnum>,
      offset?: number,
      platform?: Array<CoursesContentfilesListPlatformEnum>,
      resource_id?: Array<number>,
      run_id?: Array<number>,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<PaginatedContentFileList>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.coursesContentfilesList(
          learning_resource_id,
          content_feature_type,
          limit,
          offered_by,
          offset,
          platform,
          resource_id,
          run_id,
          options,
        )
      const index = configuration?.serverIndex ?? 0
      const operationBasePath =
        operationServerMap["CoursesApi.coursesContentfilesList"]?.[index]?.url
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, operationBasePath || basePath)
    },
    /**
     * Show content files for a learning resource
     * @summary Learning Resource Content File Retrieve
     * @param {number} id A unique integer value identifying this contentfile.
     * @param {number} learning_resource_id id of the parent learning resource
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async coursesContentfilesRetrieve(
      id: number,
      learning_resource_id: number,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<ContentFile>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.coursesContentfilesRetrieve(
          id,
          learning_resource_id,
          options,
        )
      const index = configuration?.serverIndex ?? 0
      const operationBasePath =
        operationServerMap["CoursesApi.coursesContentfilesRetrieve"]?.[index]
          ?.url
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, operationBasePath || basePath)
    },
    /**
     * Get a paginated list of courses
     * @summary List
     * @param {boolean} [certification]
     * @param {Array<CoursesListCertificationTypeEnum>} [certification_type] The type of certification offered  * &#x60;micromasters&#x60; - MicroMasters Credential * &#x60;professional&#x60; - Professional Certificate * &#x60;completion&#x60; - Certificate of Completion * &#x60;none&#x60; - No Certificate
     * @param {Array<string>} [course_feature] Multiple values may be separated by commas.
     * @param {Array<Array<CoursesListDeliveryEnum>>} [delivery] The delivery of course/program resources  * &#x60;online&#x60; - Online * &#x60;hybrid&#x60; - Hybrid * &#x60;in_person&#x60; - In person * &#x60;offline&#x60; - Offline
     * @param {Array<CoursesListDepartmentEnum>} [department] The department that offers learning resources  * &#x60;1&#x60; - Civil and Environmental Engineering * &#x60;2&#x60; - Mechanical Engineering * &#x60;3&#x60; - Materials Science and Engineering * &#x60;4&#x60; - Architecture * &#x60;5&#x60; - Chemistry * &#x60;6&#x60; - Electrical Engineering and Computer Science * &#x60;7&#x60; - Biology * &#x60;8&#x60; - Physics * &#x60;9&#x60; - Brain and Cognitive Sciences * &#x60;10&#x60; - Chemical Engineering * &#x60;11&#x60; - Urban Studies and Planning * &#x60;12&#x60; - Earth, Atmospheric, and Planetary Sciences * &#x60;14&#x60; - Economics * &#x60;15&#x60; - Management * &#x60;16&#x60; - Aeronautics and Astronautics * &#x60;17&#x60; - Political Science * &#x60;18&#x60; - Mathematics * &#x60;20&#x60; - Biological Engineering * &#x60;21A&#x60; - Anthropology * &#x60;21G&#x60; - Global Languages * &#x60;21H&#x60; - History * &#x60;21L&#x60; - Literature * &#x60;21M&#x60; - Music and Theater Arts * &#x60;22&#x60; - Nuclear Science and Engineering * &#x60;24&#x60; - Linguistics and Philosophy * &#x60;CC&#x60; - Concourse * &#x60;CMS-W&#x60; - Comparative Media Studies/Writing * &#x60;EC&#x60; - Edgerton Center * &#x60;ES&#x60; - Experimental Study Group * &#x60;ESD&#x60; - Engineering Systems Division * &#x60;HST&#x60; - Medical Engineering and Science * &#x60;IDS&#x60; - Data, Systems, and Society * &#x60;MAS&#x60; - Media Arts and Sciences * &#x60;PE&#x60; - Athletics, Physical Education and Recreation * &#x60;SP&#x60; - Special Programs * &#x60;STS&#x60; - Science, Technology, and Society * &#x60;WGS&#x60; - Women\&#39;s and Gender Studies
     * @param {boolean} [free] The course/program is offered for free
     * @param {Array<CoursesListLevelEnum>} [level] The academic level of the resources  * &#x60;undergraduate&#x60; - Undergraduate * &#x60;graduate&#x60; - Graduate * &#x60;high_school&#x60; - High School * &#x60;noncredit&#x60; - Non-Credit * &#x60;advanced&#x60; - Advanced * &#x60;intermediate&#x60; - Intermediate * &#x60;introductory&#x60; - Introductory
     * @param {number} [limit] Number of results to return per page.
     * @param {Array<CoursesListOfferedByEnum>} [offered_by] The organization that offers a learning resource  * &#x60;mitx&#x60; - MITx * &#x60;ocw&#x60; - MIT OpenCourseWare * &#x60;bootcamps&#x60; - Bootcamps * &#x60;xpro&#x60; - MIT xPRO * &#x60;mitpe&#x60; - MIT Professional Education * &#x60;see&#x60; - MIT Sloan Executive Education
     * @param {number} [offset] The initial index from which to return the results.
     * @param {Array<CoursesListPlatformEnum>} [platform] The platform on which learning resources are offered  * &#x60;edx&#x60; - edX * &#x60;ocw&#x60; - MIT OpenCourseWare * &#x60;oll&#x60; - Open Learning Library * &#x60;mitxonline&#x60; - MITx Online * &#x60;bootcamps&#x60; - Bootcamps * &#x60;xpro&#x60; - MIT xPRO * &#x60;csail&#x60; - CSAIL * &#x60;mitpe&#x60; - MIT Professional Education * &#x60;see&#x60; - MIT Sloan Executive Education * &#x60;scc&#x60; - Schwarzman College of Computing * &#x60;ctl&#x60; - Center for Transportation &amp; Logistics * &#x60;whu&#x60; - WHU * &#x60;susskind&#x60; - Susskind * &#x60;globalalumni&#x60; - Global Alumni * &#x60;simplilearn&#x60; - Simplilearn * &#x60;emeritus&#x60; - Emeritus * &#x60;podcast&#x60; - Podcast * &#x60;youtube&#x60; - YouTube
     * @param {boolean} [professional]
     * @param {Array<string>} [readable_id] Multiple values may be separated by commas.
     * @param {Array<CoursesListResourceCategoryEnum>} [resource_category] The resource category of the learning resources  * &#x60;course&#x60; - Course * &#x60;program&#x60; - Program * &#x60;learning_material&#x60; - Learning Material
     * @param {Array<CoursesListResourceTypeEnum>} [resource_type] The type of learning resource  * &#x60;course&#x60; - Course * &#x60;program&#x60; - Program * &#x60;learning_path&#x60; - Learning Path * &#x60;podcast&#x60; - Podcast * &#x60;podcast_episode&#x60; - Podcast Episode * &#x60;video&#x60; - Video * &#x60;video_playlist&#x60; - Video Playlist
     * @param {CoursesListSortbyEnum} [sortby] Sort By  * &#x60;id&#x60; - Object ID ascending * &#x60;-id&#x60; - Object ID descending * &#x60;readable_id&#x60; - Readable ID ascending * &#x60;-readable_id&#x60; - Readable ID descending * &#x60;last_modified&#x60; - Last Modified Date ascending * &#x60;-last_modified&#x60; - Last Modified Date descending * &#x60;new&#x60; - Newest resources first * &#x60;start_date&#x60; - Start Date ascending * &#x60;-start_date&#x60; - Start Date descending * &#x60;mitcoursenumber&#x60; - MIT course number ascending * &#x60;-mitcoursenumber&#x60; - MIT course number descending * &#x60;views&#x60; - Popularity ascending * &#x60;-views&#x60; - Popularity descending * &#x60;upcoming&#x60; - Next start date ascending
     * @param {Array<string>} [topic] Multiple values may be separated by commas.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async coursesList(
      certification?: boolean,
      certification_type?: Array<CoursesListCertificationTypeEnum>,
      course_feature?: Array<string>,
      delivery?: Array<Array<CoursesListDeliveryEnum>>,
      department?: Array<CoursesListDepartmentEnum>,
      free?: boolean,
      level?: Array<CoursesListLevelEnum>,
      limit?: number,
      offered_by?: Array<CoursesListOfferedByEnum>,
      offset?: number,
      platform?: Array<CoursesListPlatformEnum>,
      professional?: boolean,
      readable_id?: Array<string>,
      resource_category?: Array<CoursesListResourceCategoryEnum>,
      resource_type?: Array<CoursesListResourceTypeEnum>,
      sortby?: CoursesListSortbyEnum,
      topic?: Array<string>,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<PaginatedCourseResourceList>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.coursesList(
        certification,
        certification_type,
        course_feature,
        delivery,
        department,
        free,
        level,
        limit,
        offered_by,
        offset,
        platform,
        professional,
        readable_id,
        resource_category,
        resource_type,
        sortby,
        topic,
        options,
      )
      const index = configuration?.serverIndex ?? 0
      const operationBasePath =
        operationServerMap["CoursesApi.coursesList"]?.[index]?.url
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, operationBasePath || basePath)
    },
    /**
     * Retrieve a single course
     * @summary Retrieve
     * @param {number} id A unique integer value identifying this learning resource.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async coursesRetrieve(
      id: number,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<CourseResource>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.coursesRetrieve(
        id,
        options,
      )
      const index = configuration?.serverIndex ?? 0
      const operationBasePath =
        operationServerMap["CoursesApi.coursesRetrieve"]?.[index]?.url
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, operationBasePath || basePath)
    },
  }
}

/**
 * CoursesApi - factory interface
 * @export
 */
export const CoursesApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = CoursesApiFp(configuration)
  return {
    /**
     * Show content files for a learning resource
     * @summary Learning Resource Content File List
     * @param {CoursesApiCoursesContentfilesListRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    coursesContentfilesList(
      requestParameters: CoursesApiCoursesContentfilesListRequest,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<PaginatedContentFileList> {
      return localVarFp
        .coursesContentfilesList(
          requestParameters.learning_resource_id,
          requestParameters.content_feature_type,
          requestParameters.limit,
          requestParameters.offered_by,
          requestParameters.offset,
          requestParameters.platform,
          requestParameters.resource_id,
          requestParameters.run_id,
          options,
        )
        .then((request) => request(axios, basePath))
    },
    /**
     * Show content files for a learning resource
     * @summary Learning Resource Content File Retrieve
     * @param {CoursesApiCoursesContentfilesRetrieveRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    coursesContentfilesRetrieve(
      requestParameters: CoursesApiCoursesContentfilesRetrieveRequest,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<ContentFile> {
      return localVarFp
        .coursesContentfilesRetrieve(
          requestParameters.id,
          requestParameters.learning_resource_id,
          options,
        )
        .then((request) => request(axios, basePath))
    },
    /**
     * Get a paginated list of courses
     * @summary List
     * @param {CoursesApiCoursesListRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    coursesList(
      requestParameters: CoursesApiCoursesListRequest = {},
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<PaginatedCourseResourceList> {
      return localVarFp
        .coursesList(
          requestParameters.certification,
          requestParameters.certification_type,
          requestParameters.course_feature,
          requestParameters.delivery,
          requestParameters.department,
          requestParameters.free,
          requestParameters.level,
          requestParameters.limit,
          requestParameters.offered_by,
          requestParameters.offset,
          requestParameters.platform,
          requestParameters.professional,
          requestParameters.readable_id,
          requestParameters.resource_category,
          requestParameters.resource_type,
          requestParameters.sortby,
          requestParameters.topic,
          options,
        )
        .then((request) => request(axios, basePath))
    },
    /**
     * Retrieve a single course
     * @summary Retrieve
     * @param {CoursesApiCoursesRetrieveRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    coursesRetrieve(
      requestParameters: CoursesApiCoursesRetrieveRequest,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<CourseResource> {
      return localVarFp
        .coursesRetrieve(requestParameters.id, options)
        .then((request) => request(axios, basePath))
    },
  }
}

/**
 * Request parameters for coursesContentfilesList operation in CoursesApi.
 * @export
 * @interface CoursesApiCoursesContentfilesListRequest
 */
export interface CoursesApiCoursesContentfilesListRequest {
  /**
   * id of the parent learning resource
   * @type {number}
   * @memberof CoursesApiCoursesContentfilesList
   */
  readonly learning_resource_id: number

  /**
   * Multiple values may be separated by commas.
   * @type {Array<string>}
   * @memberof CoursesApiCoursesContentfilesList
   */
  readonly content_feature_type?: Array<string>

  /**
   * Number of results to return per page.
   * @type {number}
   * @memberof CoursesApiCoursesContentfilesList
   */
  readonly limit?: number

  /**
   * The organization that offers a learning resource the content file belongs to  * &#x60;mitx&#x60; - MITx * &#x60;ocw&#x60; - MIT OpenCourseWare * &#x60;bootcamps&#x60; - Bootcamps * &#x60;xpro&#x60; - MIT xPRO * &#x60;mitpe&#x60; - MIT Professional Education * &#x60;see&#x60; - MIT Sloan Executive Education
   * @type {Array<'bootcamps' | 'mitpe' | 'mitx' | 'ocw' | 'see' | 'xpro'>}
   * @memberof CoursesApiCoursesContentfilesList
   */
  readonly offered_by?: Array<CoursesContentfilesListOfferedByEnum>

  /**
   * The initial index from which to return the results.
   * @type {number}
   * @memberof CoursesApiCoursesContentfilesList
   */
  readonly offset?: number

  /**
   * The platform on which learning resources the content file belongs to is offered  * &#x60;edx&#x60; - edX * &#x60;ocw&#x60; - MIT OpenCourseWare * &#x60;oll&#x60; - Open Learning Library * &#x60;mitxonline&#x60; - MITx Online * &#x60;bootcamps&#x60; - Bootcamps * &#x60;xpro&#x60; - MIT xPRO * &#x60;csail&#x60; - CSAIL * &#x60;mitpe&#x60; - MIT Professional Education * &#x60;see&#x60; - MIT Sloan Executive Education * &#x60;scc&#x60; - Schwarzman College of Computing * &#x60;ctl&#x60; - Center for Transportation &amp; Logistics * &#x60;whu&#x60; - WHU * &#x60;susskind&#x60; - Susskind * &#x60;globalalumni&#x60; - Global Alumni * &#x60;simplilearn&#x60; - Simplilearn * &#x60;emeritus&#x60; - Emeritus * &#x60;podcast&#x60; - Podcast * &#x60;youtube&#x60; - YouTube
   * @type {Array<'bootcamps' | 'csail' | 'ctl' | 'edx' | 'emeritus' | 'globalalumni' | 'mitpe' | 'mitxonline' | 'ocw' | 'oll' | 'podcast' | 'scc' | 'see' | 'simplilearn' | 'susskind' | 'whu' | 'xpro' | 'youtube'>}
   * @memberof CoursesApiCoursesContentfilesList
   */
  readonly platform?: Array<CoursesContentfilesListPlatformEnum>

  /**
   * Multiple values may be separated by commas.
   * @type {Array<number>}
   * @memberof CoursesApiCoursesContentfilesList
   */
  readonly resource_id?: Array<number>

  /**
   * Multiple values may be separated by commas.
   * @type {Array<number>}
   * @memberof CoursesApiCoursesContentfilesList
   */
  readonly run_id?: Array<number>
}

/**
 * Request parameters for coursesContentfilesRetrieve operation in CoursesApi.
 * @export
 * @interface CoursesApiCoursesContentfilesRetrieveRequest
 */
export interface CoursesApiCoursesContentfilesRetrieveRequest {
  /**
   * A unique integer value identifying this contentfile.
   * @type {number}
   * @memberof CoursesApiCoursesContentfilesRetrieve
   */
  readonly id: number

  /**
   * id of the parent learning resource
   * @type {number}
   * @memberof CoursesApiCoursesContentfilesRetrieve
   */
  readonly learning_resource_id: number
}

/**
 * Request parameters for coursesList operation in CoursesApi.
 * @export
 * @interface CoursesApiCoursesListRequest
 */
export interface CoursesApiCoursesListRequest {
  /**
   *
   * @type {boolean}
   * @memberof CoursesApiCoursesList
   */
  readonly certification?: boolean

  /**
   * The type of certification offered  * &#x60;micromasters&#x60; - MicroMasters Credential * &#x60;professional&#x60; - Professional Certificate * &#x60;completion&#x60; - Certificate of Completion * &#x60;none&#x60; - No Certificate
   * @type {Array<'completion' | 'micromasters' | 'none' | 'professional'>}
   * @memberof CoursesApiCoursesList
   */
  readonly certification_type?: Array<CoursesListCertificationTypeEnum>

  /**
   * Multiple values may be separated by commas.
   * @type {Array<string>}
   * @memberof CoursesApiCoursesList
   */
  readonly course_feature?: Array<string>

  /**
   * The delivery of course/program resources  * &#x60;online&#x60; - Online * &#x60;hybrid&#x60; - Hybrid * &#x60;in_person&#x60; - In person * &#x60;offline&#x60; - Offline
   * @type {Array<Array<'online' | 'hybrid' | 'in_person' | 'offline'>>}
   * @memberof CoursesApiCoursesList
   */
  readonly delivery?: Array<Array<CoursesListDeliveryEnum>>

  /**
   * The department that offers learning resources  * &#x60;1&#x60; - Civil and Environmental Engineering * &#x60;2&#x60; - Mechanical Engineering * &#x60;3&#x60; - Materials Science and Engineering * &#x60;4&#x60; - Architecture * &#x60;5&#x60; - Chemistry * &#x60;6&#x60; - Electrical Engineering and Computer Science * &#x60;7&#x60; - Biology * &#x60;8&#x60; - Physics * &#x60;9&#x60; - Brain and Cognitive Sciences * &#x60;10&#x60; - Chemical Engineering * &#x60;11&#x60; - Urban Studies and Planning * &#x60;12&#x60; - Earth, Atmospheric, and Planetary Sciences * &#x60;14&#x60; - Economics * &#x60;15&#x60; - Management * &#x60;16&#x60; - Aeronautics and Astronautics * &#x60;17&#x60; - Political Science * &#x60;18&#x60; - Mathematics * &#x60;20&#x60; - Biological Engineering * &#x60;21A&#x60; - Anthropology * &#x60;21G&#x60; - Global Languages * &#x60;21H&#x60; - History * &#x60;21L&#x60; - Literature * &#x60;21M&#x60; - Music and Theater Arts * &#x60;22&#x60; - Nuclear Science and Engineering * &#x60;24&#x60; - Linguistics and Philosophy * &#x60;CC&#x60; - Concourse * &#x60;CMS-W&#x60; - Comparative Media Studies/Writing * &#x60;EC&#x60; - Edgerton Center * &#x60;ES&#x60; - Experimental Study Group * &#x60;ESD&#x60; - Engineering Systems Division * &#x60;HST&#x60; - Medical Engineering and Science * &#x60;IDS&#x60; - Data, Systems, and Society * &#x60;MAS&#x60; - Media Arts and Sciences * &#x60;PE&#x60; - Athletics, Physical Education and Recreation * &#x60;SP&#x60; - Special Programs * &#x60;STS&#x60; - Science, Technology, and Society * &#x60;WGS&#x60; - Women\&#39;s and Gender Studies
   * @type {Array<'1' | '10' | '11' | '12' | '14' | '15' | '16' | '17' | '18' | '2' | '20' | '21A' | '21G' | '21H' | '21L' | '21M' | '22' | '24' | '3' | '4' | '5' | '6' | '7' | '8' | '9' | 'CC' | 'CMS-W' | 'EC' | 'ES' | 'ESD' | 'HST' | 'IDS' | 'MAS' | 'PE' | 'SP' | 'STS' | 'WGS'>}
   * @memberof CoursesApiCoursesList
   */
  readonly department?: Array<CoursesListDepartmentEnum>

  /**
   * The course/program is offered for free
   * @type {boolean}
   * @memberof CoursesApiCoursesList
   */
  readonly free?: boolean

  /**
   * The academic level of the resources  * &#x60;undergraduate&#x60; - Undergraduate * &#x60;graduate&#x60; - Graduate * &#x60;high_school&#x60; - High School * &#x60;noncredit&#x60; - Non-Credit * &#x60;advanced&#x60; - Advanced * &#x60;intermediate&#x60; - Intermediate * &#x60;introductory&#x60; - Introductory
   * @type {Array<'advanced' | 'graduate' | 'high_school' | 'intermediate' | 'introductory' | 'noncredit' | 'undergraduate'>}
   * @memberof CoursesApiCoursesList
   */
  readonly level?: Array<CoursesListLevelEnum>

  /**
   * Number of results to return per page.
   * @type {number}
   * @memberof CoursesApiCoursesList
   */
  readonly limit?: number

  /**
   * The organization that offers a learning resource  * &#x60;mitx&#x60; - MITx * &#x60;ocw&#x60; - MIT OpenCourseWare * &#x60;bootcamps&#x60; - Bootcamps * &#x60;xpro&#x60; - MIT xPRO * &#x60;mitpe&#x60; - MIT Professional Education * &#x60;see&#x60; - MIT Sloan Executive Education
   * @type {Array<'bootcamps' | 'mitpe' | 'mitx' | 'ocw' | 'see' | 'xpro'>}
   * @memberof CoursesApiCoursesList
   */
  readonly offered_by?: Array<CoursesListOfferedByEnum>

  /**
   * The initial index from which to return the results.
   * @type {number}
   * @memberof CoursesApiCoursesList
   */
  readonly offset?: number

  /**
   * The platform on which learning resources are offered  * &#x60;edx&#x60; - edX * &#x60;ocw&#x60; - MIT OpenCourseWare * &#x60;oll&#x60; - Open Learning Library * &#x60;mitxonline&#x60; - MITx Online * &#x60;bootcamps&#x60; - Bootcamps * &#x60;xpro&#x60; - MIT xPRO * &#x60;csail&#x60; - CSAIL * &#x60;mitpe&#x60; - MIT Professional Education * &#x60;see&#x60; - MIT Sloan Executive Education * &#x60;scc&#x60; - Schwarzman College of Computing * &#x60;ctl&#x60; - Center for Transportation &amp; Logistics * &#x60;whu&#x60; - WHU * &#x60;susskind&#x60; - Susskind * &#x60;globalalumni&#x60; - Global Alumni * &#x60;simplilearn&#x60; - Simplilearn * &#x60;emeritus&#x60; - Emeritus * &#x60;podcast&#x60; - Podcast * &#x60;youtube&#x60; - YouTube
   * @type {Array<'bootcamps' | 'csail' | 'ctl' | 'edx' | 'emeritus' | 'globalalumni' | 'mitpe' | 'mitxonline' | 'ocw' | 'oll' | 'podcast' | 'scc' | 'see' | 'simplilearn' | 'susskind' | 'whu' | 'xpro' | 'youtube'>}
   * @memberof CoursesApiCoursesList
   */
  readonly platform?: Array<CoursesListPlatformEnum>

  /**
   *
   * @type {boolean}
   * @memberof CoursesApiCoursesList
   */
  readonly professional?: boolean

  /**
   * Multiple values may be separated by commas.
   * @type {Array<string>}
   * @memberof CoursesApiCoursesList
   */
  readonly readable_id?: Array<string>

  /**
   * The resource category of the learning resources  * &#x60;course&#x60; - Course * &#x60;program&#x60; - Program * &#x60;learning_material&#x60; - Learning Material
   * @type {Array<'course' | 'learning_material' | 'program'>}
   * @memberof CoursesApiCoursesList
   */
  readonly resource_category?: Array<CoursesListResourceCategoryEnum>

  /**
   * The type of learning resource  * &#x60;course&#x60; - Course * &#x60;program&#x60; - Program * &#x60;learning_path&#x60; - Learning Path * &#x60;podcast&#x60; - Podcast * &#x60;podcast_episode&#x60; - Podcast Episode * &#x60;video&#x60; - Video * &#x60;video_playlist&#x60; - Video Playlist
   * @type {Array<'course' | 'learning_path' | 'podcast' | 'podcast_episode' | 'program' | 'video' | 'video_playlist'>}
   * @memberof CoursesApiCoursesList
   */
  readonly resource_type?: Array<CoursesListResourceTypeEnum>

  /**
   * Sort By  * &#x60;id&#x60; - Object ID ascending * &#x60;-id&#x60; - Object ID descending * &#x60;readable_id&#x60; - Readable ID ascending * &#x60;-readable_id&#x60; - Readable ID descending * &#x60;last_modified&#x60; - Last Modified Date ascending * &#x60;-last_modified&#x60; - Last Modified Date descending * &#x60;new&#x60; - Newest resources first * &#x60;start_date&#x60; - Start Date ascending * &#x60;-start_date&#x60; - Start Date descending * &#x60;mitcoursenumber&#x60; - MIT course number ascending * &#x60;-mitcoursenumber&#x60; - MIT course number descending * &#x60;views&#x60; - Popularity ascending * &#x60;-views&#x60; - Popularity descending * &#x60;upcoming&#x60; - Next start date ascending
   * @type {'-id' | '-last_modified' | '-mitcoursenumber' | '-readable_id' | '-start_date' | '-views' | 'id' | 'last_modified' | 'mitcoursenumber' | 'new' | 'readable_id' | 'start_date' | 'upcoming' | 'views'}
   * @memberof CoursesApiCoursesList
   */
  readonly sortby?: CoursesListSortbyEnum

  /**
   * Multiple values may be separated by commas.
   * @type {Array<string>}
   * @memberof CoursesApiCoursesList
   */
  readonly topic?: Array<string>
}

/**
 * Request parameters for coursesRetrieve operation in CoursesApi.
 * @export
 * @interface CoursesApiCoursesRetrieveRequest
 */
export interface CoursesApiCoursesRetrieveRequest {
  /**
   * A unique integer value identifying this learning resource.
   * @type {number}
   * @memberof CoursesApiCoursesRetrieve
   */
  readonly id: number
}

/**
 * CoursesApi - object-oriented interface
 * @export
 * @class CoursesApi
 * @extends {BaseAPI}
 */
export class CoursesApi extends BaseAPI {
  /**
   * Show content files for a learning resource
   * @summary Learning Resource Content File List
   * @param {CoursesApiCoursesContentfilesListRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CoursesApi
   */
  public coursesContentfilesList(
    requestParameters: CoursesApiCoursesContentfilesListRequest,
    options?: RawAxiosRequestConfig,
  ) {
    return CoursesApiFp(this.configuration)
      .coursesContentfilesList(
        requestParameters.learning_resource_id,
        requestParameters.content_feature_type,
        requestParameters.limit,
        requestParameters.offered_by,
        requestParameters.offset,
        requestParameters.platform,
        requestParameters.resource_id,
        requestParameters.run_id,
        options,
      )
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   * Show content files for a learning resource
   * @summary Learning Resource Content File Retrieve
   * @param {CoursesApiCoursesContentfilesRetrieveRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CoursesApi
   */
  public coursesContentfilesRetrieve(
    requestParameters: CoursesApiCoursesContentfilesRetrieveRequest,
    options?: RawAxiosRequestConfig,
  ) {
    return CoursesApiFp(this.configuration)
      .coursesContentfilesRetrieve(
        requestParameters.id,
        requestParameters.learning_resource_id,
        options,
      )
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   * Get a paginated list of courses
   * @summary List
   * @param {CoursesApiCoursesListRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CoursesApi
   */
  public coursesList(
    requestParameters: CoursesApiCoursesListRequest = {},
    options?: RawAxiosRequestConfig,
  ) {
    return CoursesApiFp(this.configuration)
      .coursesList(
        requestParameters.certification,
        requestParameters.certification_type,
        requestParameters.course_feature,
        requestParameters.delivery,
        requestParameters.department,
        requestParameters.free,
        requestParameters.level,
        requestParameters.limit,
        requestParameters.offered_by,
        requestParameters.offset,
        requestParameters.platform,
        requestParameters.professional,
        requestParameters.readable_id,
        requestParameters.resource_category,
        requestParameters.resource_type,
        requestParameters.sortby,
        requestParameters.topic,
        options,
      )
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   * Retrieve a single course
   * @summary Retrieve
   * @param {CoursesApiCoursesRetrieveRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CoursesApi
   */
  public coursesRetrieve(
    requestParameters: CoursesApiCoursesRetrieveRequest,
    options?: RawAxiosRequestConfig,
  ) {
    return CoursesApiFp(this.configuration)
      .coursesRetrieve(requestParameters.id, options)
      .then((request) => request(this.axios, this.basePath))
  }
}

/**
 * @export
 */
export const CoursesContentfilesListOfferedByEnum = {
  Bootcamps: "bootcamps",
  Mitpe: "mitpe",
  Mitx: "mitx",
  Ocw: "ocw",
  See: "see",
  Xpro: "xpro",
} as const
export type CoursesContentfilesListOfferedByEnum =
  (typeof CoursesContentfilesListOfferedByEnum)[keyof typeof CoursesContentfilesListOfferedByEnum]
/**
 * @export
 */
export const CoursesContentfilesListPlatformEnum = {
  Bootcamps: "bootcamps",
  Csail: "csail",
  Ctl: "ctl",
  Edx: "edx",
  Emeritus: "emeritus",
  Globalalumni: "globalalumni",
  Mitpe: "mitpe",
  Mitxonline: "mitxonline",
  Ocw: "ocw",
  Oll: "oll",
  Podcast: "podcast",
  Scc: "scc",
  See: "see",
  Simplilearn: "simplilearn",
  Susskind: "susskind",
  Whu: "whu",
  Xpro: "xpro",
  Youtube: "youtube",
} as const
export type CoursesContentfilesListPlatformEnum =
  (typeof CoursesContentfilesListPlatformEnum)[keyof typeof CoursesContentfilesListPlatformEnum]
/**
 * @export
 */
export const CoursesListCertificationTypeEnum = {
  Completion: "completion",
  Micromasters: "micromasters",
  None: "none",
  Professional: "professional",
} as const
export type CoursesListCertificationTypeEnum =
  (typeof CoursesListCertificationTypeEnum)[keyof typeof CoursesListCertificationTypeEnum]
/**
 * @export
 */
export const CoursesListDeliveryEnum = {
  Online: "online",
  Hybrid: "hybrid",
  InPerson: "in_person",
  Offline: "offline",
} as const
export type CoursesListDeliveryEnum =
  (typeof CoursesListDeliveryEnum)[keyof typeof CoursesListDeliveryEnum]
/**
 * @export
 */
export const CoursesListDepartmentEnum = {
  _1: "1",
  _10: "10",
  _11: "11",
  _12: "12",
  _14: "14",
  _15: "15",
  _16: "16",
  _17: "17",
  _18: "18",
  _2: "2",
  _20: "20",
  _21A: "21A",
  _21G: "21G",
  _21H: "21H",
  _21L: "21L",
  _21M: "21M",
  _22: "22",
  _24: "24",
  _3: "3",
  _4: "4",
  _5: "5",
  _6: "6",
  _7: "7",
  _8: "8",
  _9: "9",
  Cc: "CC",
  CmsW: "CMS-W",
  Ec: "EC",
  Es: "ES",
  Esd: "ESD",
  Hst: "HST",
  Ids: "IDS",
  Mas: "MAS",
  Pe: "PE",
  Sp: "SP",
  Sts: "STS",
  Wgs: "WGS",
} as const
export type CoursesListDepartmentEnum =
  (typeof CoursesListDepartmentEnum)[keyof typeof CoursesListDepartmentEnum]
/**
 * @export
 */
export const CoursesListLevelEnum = {
  Advanced: "advanced",
  Graduate: "graduate",
  HighSchool: "high_school",
  Intermediate: "intermediate",
  Introductory: "introductory",
  Noncredit: "noncredit",
  Undergraduate: "undergraduate",
} as const
export type CoursesListLevelEnum =
  (typeof CoursesListLevelEnum)[keyof typeof CoursesListLevelEnum]
/**
 * @export
 */
export const CoursesListOfferedByEnum = {
  Bootcamps: "bootcamps",
  Mitpe: "mitpe",
  Mitx: "mitx",
  Ocw: "ocw",
  See: "see",
  Xpro: "xpro",
} as const
export type CoursesListOfferedByEnum =
  (typeof CoursesListOfferedByEnum)[keyof typeof CoursesListOfferedByEnum]
/**
 * @export
 */
export const CoursesListPlatformEnum = {
  Bootcamps: "bootcamps",
  Csail: "csail",
  Ctl: "ctl",
  Edx: "edx",
  Emeritus: "emeritus",
  Globalalumni: "globalalumni",
  Mitpe: "mitpe",
  Mitxonline: "mitxonline",
  Ocw: "ocw",
  Oll: "oll",
  Podcast: "podcast",
  Scc: "scc",
  See: "see",
  Simplilearn: "simplilearn",
  Susskind: "susskind",
  Whu: "whu",
  Xpro: "xpro",
  Youtube: "youtube",
} as const
export type CoursesListPlatformEnum =
  (typeof CoursesListPlatformEnum)[keyof typeof CoursesListPlatformEnum]
/**
 * @export
 */
export const CoursesListResourceCategoryEnum = {
  Course: "course",
  LearningMaterial: "learning_material",
  Program: "program",
} as const
export type CoursesListResourceCategoryEnum =
  (typeof CoursesListResourceCategoryEnum)[keyof typeof CoursesListResourceCategoryEnum]
/**
 * @export
 */
export const CoursesListResourceTypeEnum = {
  Course: "course",
  LearningPath: "learning_path",
  Podcast: "podcast",
  PodcastEpisode: "podcast_episode",
  Program: "program",
  Video: "video",
  VideoPlaylist: "video_playlist",
} as const
export type CoursesListResourceTypeEnum =
  (typeof CoursesListResourceTypeEnum)[keyof typeof CoursesListResourceTypeEnum]
/**
 * @export
 */
export const CoursesListSortbyEnum = {
  Id: "-id",
  LastModified: "-last_modified",
  Mitcoursenumber: "-mitcoursenumber",
  ReadableId: "-readable_id",
  StartDate: "-start_date",
  Views: "-views",
  Id2: "id",
  LastModified2: "last_modified",
  Mitcoursenumber2: "mitcoursenumber",
  New: "new",
  ReadableId2: "readable_id",
  StartDate2: "start_date",
  Upcoming: "upcoming",
  Views2: "views",
} as const
export type CoursesListSortbyEnum =
  (typeof CoursesListSortbyEnum)[keyof typeof CoursesListSortbyEnum]

/**
 * DepartmentsApi - axios parameter creator
 * @export
 */
export const DepartmentsApiAxiosParamCreator = function (
  configuration?: Configuration,
) {
  return {
    /**
     * MIT academic departments
     * @summary List
     * @param {number} [limit] Number of results to return per page.
     * @param {number} [offset] The initial index from which to return the results.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    departmentsList: async (
      limit?: number,
      offset?: number,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/api/v1/departments/`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      if (limit !== undefined) {
        localVarQueryParameter["limit"] = limit
      }

      if (offset !== undefined) {
        localVarQueryParameter["offset"] = offset
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     * MIT academic departments
     * @summary Retrieve
     * @param {string} department_id A unique value identifying this learning resource department.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    departmentsRetrieve: async (
      department_id: string,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'department_id' is not null or undefined
      assertParamExists("departmentsRetrieve", "department_id", department_id)
      const localVarPath = `/api/v1/departments/{department_id}/`.replace(
        `{${"department_id"}}`,
        encodeURIComponent(String(department_id)),
      )
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
  }
}

/**
 * DepartmentsApi - functional programming interface
 * @export
 */
export const DepartmentsApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator =
    DepartmentsApiAxiosParamCreator(configuration)
  return {
    /**
     * MIT academic departments
     * @summary List
     * @param {number} [limit] Number of results to return per page.
     * @param {number} [offset] The initial index from which to return the results.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async departmentsList(
      limit?: number,
      offset?: number,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<PaginatedLearningResourceDepartmentList>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.departmentsList(
        limit,
        offset,
        options,
      )
      const index = configuration?.serverIndex ?? 0
      const operationBasePath =
        operationServerMap["DepartmentsApi.departmentsList"]?.[index]?.url
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, operationBasePath || basePath)
    },
    /**
     * MIT academic departments
     * @summary Retrieve
     * @param {string} department_id A unique value identifying this learning resource department.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async departmentsRetrieve(
      department_id: string,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<LearningResourceDepartment>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.departmentsRetrieve(
          department_id,
          options,
        )
      const index = configuration?.serverIndex ?? 0
      const operationBasePath =
        operationServerMap["DepartmentsApi.departmentsRetrieve"]?.[index]?.url
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, operationBasePath || basePath)
    },
  }
}

/**
 * DepartmentsApi - factory interface
 * @export
 */
export const DepartmentsApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = DepartmentsApiFp(configuration)
  return {
    /**
     * MIT academic departments
     * @summary List
     * @param {DepartmentsApiDepartmentsListRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    departmentsList(
      requestParameters: DepartmentsApiDepartmentsListRequest = {},
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<PaginatedLearningResourceDepartmentList> {
      return localVarFp
        .departmentsList(
          requestParameters.limit,
          requestParameters.offset,
          options,
        )
        .then((request) => request(axios, basePath))
    },
    /**
     * MIT academic departments
     * @summary Retrieve
     * @param {DepartmentsApiDepartmentsRetrieveRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    departmentsRetrieve(
      requestParameters: DepartmentsApiDepartmentsRetrieveRequest,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<LearningResourceDepartment> {
      return localVarFp
        .departmentsRetrieve(requestParameters.department_id, options)
        .then((request) => request(axios, basePath))
    },
  }
}

/**
 * Request parameters for departmentsList operation in DepartmentsApi.
 * @export
 * @interface DepartmentsApiDepartmentsListRequest
 */
export interface DepartmentsApiDepartmentsListRequest {
  /**
   * Number of results to return per page.
   * @type {number}
   * @memberof DepartmentsApiDepartmentsList
   */
  readonly limit?: number

  /**
   * The initial index from which to return the results.
   * @type {number}
   * @memberof DepartmentsApiDepartmentsList
   */
  readonly offset?: number
}

/**
 * Request parameters for departmentsRetrieve operation in DepartmentsApi.
 * @export
 * @interface DepartmentsApiDepartmentsRetrieveRequest
 */
export interface DepartmentsApiDepartmentsRetrieveRequest {
  /**
   * A unique value identifying this learning resource department.
   * @type {string}
   * @memberof DepartmentsApiDepartmentsRetrieve
   */
  readonly department_id: string
}

/**
 * DepartmentsApi - object-oriented interface
 * @export
 * @class DepartmentsApi
 * @extends {BaseAPI}
 */
export class DepartmentsApi extends BaseAPI {
  /**
   * MIT academic departments
   * @summary List
   * @param {DepartmentsApiDepartmentsListRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DepartmentsApi
   */
  public departmentsList(
    requestParameters: DepartmentsApiDepartmentsListRequest = {},
    options?: RawAxiosRequestConfig,
  ) {
    return DepartmentsApiFp(this.configuration)
      .departmentsList(
        requestParameters.limit,
        requestParameters.offset,
        options,
      )
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   * MIT academic departments
   * @summary Retrieve
   * @param {DepartmentsApiDepartmentsRetrieveRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DepartmentsApi
   */
  public departmentsRetrieve(
    requestParameters: DepartmentsApiDepartmentsRetrieveRequest,
    options?: RawAxiosRequestConfig,
  ) {
    return DepartmentsApiFp(this.configuration)
      .departmentsRetrieve(requestParameters.department_id, options)
      .then((request) => request(this.axios, this.basePath))
  }
}

/**
 * FeaturedApi - axios parameter creator
 * @export
 */
export const FeaturedApiAxiosParamCreator = function (
  configuration?: Configuration,
) {
  return {
    /**
     * Get a paginated list of featured resources
     * @summary List
     * @param {boolean} [certification]
     * @param {Array<FeaturedListCertificationTypeEnum>} [certification_type] The type of certification offered  * &#x60;micromasters&#x60; - MicroMasters Credential * &#x60;professional&#x60; - Professional Certificate * &#x60;completion&#x60; - Certificate of Completion * &#x60;none&#x60; - No Certificate
     * @param {Array<string>} [course_feature] Multiple values may be separated by commas.
     * @param {Array<Array<FeaturedListDeliveryEnum>>} [delivery] The delivery of course/program resources  * &#x60;online&#x60; - Online * &#x60;hybrid&#x60; - Hybrid * &#x60;in_person&#x60; - In person * &#x60;offline&#x60; - Offline
     * @param {Array<FeaturedListDepartmentEnum>} [department] The department that offers learning resources  * &#x60;1&#x60; - Civil and Environmental Engineering * &#x60;2&#x60; - Mechanical Engineering * &#x60;3&#x60; - Materials Science and Engineering * &#x60;4&#x60; - Architecture * &#x60;5&#x60; - Chemistry * &#x60;6&#x60; - Electrical Engineering and Computer Science * &#x60;7&#x60; - Biology * &#x60;8&#x60; - Physics * &#x60;9&#x60; - Brain and Cognitive Sciences * &#x60;10&#x60; - Chemical Engineering * &#x60;11&#x60; - Urban Studies and Planning * &#x60;12&#x60; - Earth, Atmospheric, and Planetary Sciences * &#x60;14&#x60; - Economics * &#x60;15&#x60; - Management * &#x60;16&#x60; - Aeronautics and Astronautics * &#x60;17&#x60; - Political Science * &#x60;18&#x60; - Mathematics * &#x60;20&#x60; - Biological Engineering * &#x60;21A&#x60; - Anthropology * &#x60;21G&#x60; - Global Languages * &#x60;21H&#x60; - History * &#x60;21L&#x60; - Literature * &#x60;21M&#x60; - Music and Theater Arts * &#x60;22&#x60; - Nuclear Science and Engineering * &#x60;24&#x60; - Linguistics and Philosophy * &#x60;CC&#x60; - Concourse * &#x60;CMS-W&#x60; - Comparative Media Studies/Writing * &#x60;EC&#x60; - Edgerton Center * &#x60;ES&#x60; - Experimental Study Group * &#x60;ESD&#x60; - Engineering Systems Division * &#x60;HST&#x60; - Medical Engineering and Science * &#x60;IDS&#x60; - Data, Systems, and Society * &#x60;MAS&#x60; - Media Arts and Sciences * &#x60;PE&#x60; - Athletics, Physical Education and Recreation * &#x60;SP&#x60; - Special Programs * &#x60;STS&#x60; - Science, Technology, and Society * &#x60;WGS&#x60; - Women\&#39;s and Gender Studies
     * @param {boolean} [free] The course/program is offered for free
     * @param {Array<FeaturedListLevelEnum>} [level] The academic level of the resources  * &#x60;undergraduate&#x60; - Undergraduate * &#x60;graduate&#x60; - Graduate * &#x60;high_school&#x60; - High School * &#x60;noncredit&#x60; - Non-Credit * &#x60;advanced&#x60; - Advanced * &#x60;intermediate&#x60; - Intermediate * &#x60;introductory&#x60; - Introductory
     * @param {number} [limit] Number of results to return per page.
     * @param {Array<FeaturedListOfferedByEnum>} [offered_by] The organization that offers a learning resource  * &#x60;mitx&#x60; - MITx * &#x60;ocw&#x60; - MIT OpenCourseWare * &#x60;bootcamps&#x60; - Bootcamps * &#x60;xpro&#x60; - MIT xPRO * &#x60;mitpe&#x60; - MIT Professional Education * &#x60;see&#x60; - MIT Sloan Executive Education
     * @param {number} [offset] The initial index from which to return the results.
     * @param {Array<FeaturedListPlatformEnum>} [platform] The platform on which learning resources are offered  * &#x60;edx&#x60; - edX * &#x60;ocw&#x60; - MIT OpenCourseWare * &#x60;oll&#x60; - Open Learning Library * &#x60;mitxonline&#x60; - MITx Online * &#x60;bootcamps&#x60; - Bootcamps * &#x60;xpro&#x60; - MIT xPRO * &#x60;csail&#x60; - CSAIL * &#x60;mitpe&#x60; - MIT Professional Education * &#x60;see&#x60; - MIT Sloan Executive Education * &#x60;scc&#x60; - Schwarzman College of Computing * &#x60;ctl&#x60; - Center for Transportation &amp; Logistics * &#x60;whu&#x60; - WHU * &#x60;susskind&#x60; - Susskind * &#x60;globalalumni&#x60; - Global Alumni * &#x60;simplilearn&#x60; - Simplilearn * &#x60;emeritus&#x60; - Emeritus * &#x60;podcast&#x60; - Podcast * &#x60;youtube&#x60; - YouTube
     * @param {boolean} [professional]
     * @param {Array<string>} [readable_id] Multiple values may be separated by commas.
     * @param {Array<FeaturedListResourceCategoryEnum>} [resource_category] The resource category of the learning resources  * &#x60;course&#x60; - Course * &#x60;program&#x60; - Program * &#x60;learning_material&#x60; - Learning Material
     * @param {Array<FeaturedListResourceTypeEnum>} [resource_type] The type of learning resource  * &#x60;course&#x60; - Course * &#x60;program&#x60; - Program * &#x60;learning_path&#x60; - Learning Path * &#x60;podcast&#x60; - Podcast * &#x60;podcast_episode&#x60; - Podcast Episode * &#x60;video&#x60; - Video * &#x60;video_playlist&#x60; - Video Playlist
     * @param {FeaturedListSortbyEnum} [sortby] Sort By  * &#x60;id&#x60; - Object ID ascending * &#x60;-id&#x60; - Object ID descending * &#x60;readable_id&#x60; - Readable ID ascending * &#x60;-readable_id&#x60; - Readable ID descending * &#x60;last_modified&#x60; - Last Modified Date ascending * &#x60;-last_modified&#x60; - Last Modified Date descending * &#x60;new&#x60; - Newest resources first * &#x60;start_date&#x60; - Start Date ascending * &#x60;-start_date&#x60; - Start Date descending * &#x60;mitcoursenumber&#x60; - MIT course number ascending * &#x60;-mitcoursenumber&#x60; - MIT course number descending * &#x60;views&#x60; - Popularity ascending * &#x60;-views&#x60; - Popularity descending * &#x60;upcoming&#x60; - Next start date ascending
     * @param {Array<string>} [topic] Multiple values may be separated by commas.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    featuredList: async (
      certification?: boolean,
      certification_type?: Array<FeaturedListCertificationTypeEnum>,
      course_feature?: Array<string>,
      delivery?: Array<Array<FeaturedListDeliveryEnum>>,
      department?: Array<FeaturedListDepartmentEnum>,
      free?: boolean,
      level?: Array<FeaturedListLevelEnum>,
      limit?: number,
      offered_by?: Array<FeaturedListOfferedByEnum>,
      offset?: number,
      platform?: Array<FeaturedListPlatformEnum>,
      professional?: boolean,
      readable_id?: Array<string>,
      resource_category?: Array<FeaturedListResourceCategoryEnum>,
      resource_type?: Array<FeaturedListResourceTypeEnum>,
      sortby?: FeaturedListSortbyEnum,
      topic?: Array<string>,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/api/v1/featured/`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      if (certification !== undefined) {
        localVarQueryParameter["certification"] = certification
      }

      if (certification_type) {
        localVarQueryParameter["certification_type"] = certification_type
      }

      if (course_feature) {
        localVarQueryParameter["course_feature"] = course_feature.join(
          COLLECTION_FORMATS.csv,
        )
      }

      if (delivery) {
        localVarQueryParameter["delivery"] = delivery
      }

      if (department) {
        localVarQueryParameter["department"] = department
      }

      if (free !== undefined) {
        localVarQueryParameter["free"] = free
      }

      if (level) {
        localVarQueryParameter["level"] = level
      }

      if (limit !== undefined) {
        localVarQueryParameter["limit"] = limit
      }

      if (offered_by) {
        localVarQueryParameter["offered_by"] = offered_by
      }

      if (offset !== undefined) {
        localVarQueryParameter["offset"] = offset
      }

      if (platform) {
        localVarQueryParameter["platform"] = platform
      }

      if (professional !== undefined) {
        localVarQueryParameter["professional"] = professional
      }

      if (readable_id) {
        localVarQueryParameter["readable_id"] = readable_id.join(
          COLLECTION_FORMATS.csv,
        )
      }

      if (resource_category) {
        localVarQueryParameter["resource_category"] = resource_category
      }

      if (resource_type) {
        localVarQueryParameter["resource_type"] = resource_type
      }

      if (sortby !== undefined) {
        localVarQueryParameter["sortby"] = sortby
      }

      if (topic) {
        localVarQueryParameter["topic"] = topic.join(COLLECTION_FORMATS.csv)
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     * Retrieve a single featured resource
     * @summary Retrieve
     * @param {number} id A unique integer value identifying this learning resource.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    featuredRetrieve: async (
      id: number,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists("featuredRetrieve", "id", id)
      const localVarPath = `/api/v1/featured/{id}/`.replace(
        `{${"id"}}`,
        encodeURIComponent(String(id)),
      )
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
  }
}

/**
 * FeaturedApi - functional programming interface
 * @export
 */
export const FeaturedApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = FeaturedApiAxiosParamCreator(configuration)
  return {
    /**
     * Get a paginated list of featured resources
     * @summary List
     * @param {boolean} [certification]
     * @param {Array<FeaturedListCertificationTypeEnum>} [certification_type] The type of certification offered  * &#x60;micromasters&#x60; - MicroMasters Credential * &#x60;professional&#x60; - Professional Certificate * &#x60;completion&#x60; - Certificate of Completion * &#x60;none&#x60; - No Certificate
     * @param {Array<string>} [course_feature] Multiple values may be separated by commas.
     * @param {Array<Array<FeaturedListDeliveryEnum>>} [delivery] The delivery of course/program resources  * &#x60;online&#x60; - Online * &#x60;hybrid&#x60; - Hybrid * &#x60;in_person&#x60; - In person * &#x60;offline&#x60; - Offline
     * @param {Array<FeaturedListDepartmentEnum>} [department] The department that offers learning resources  * &#x60;1&#x60; - Civil and Environmental Engineering * &#x60;2&#x60; - Mechanical Engineering * &#x60;3&#x60; - Materials Science and Engineering * &#x60;4&#x60; - Architecture * &#x60;5&#x60; - Chemistry * &#x60;6&#x60; - Electrical Engineering and Computer Science * &#x60;7&#x60; - Biology * &#x60;8&#x60; - Physics * &#x60;9&#x60; - Brain and Cognitive Sciences * &#x60;10&#x60; - Chemical Engineering * &#x60;11&#x60; - Urban Studies and Planning * &#x60;12&#x60; - Earth, Atmospheric, and Planetary Sciences * &#x60;14&#x60; - Economics * &#x60;15&#x60; - Management * &#x60;16&#x60; - Aeronautics and Astronautics * &#x60;17&#x60; - Political Science * &#x60;18&#x60; - Mathematics * &#x60;20&#x60; - Biological Engineering * &#x60;21A&#x60; - Anthropology * &#x60;21G&#x60; - Global Languages * &#x60;21H&#x60; - History * &#x60;21L&#x60; - Literature * &#x60;21M&#x60; - Music and Theater Arts * &#x60;22&#x60; - Nuclear Science and Engineering * &#x60;24&#x60; - Linguistics and Philosophy * &#x60;CC&#x60; - Concourse * &#x60;CMS-W&#x60; - Comparative Media Studies/Writing * &#x60;EC&#x60; - Edgerton Center * &#x60;ES&#x60; - Experimental Study Group * &#x60;ESD&#x60; - Engineering Systems Division * &#x60;HST&#x60; - Medical Engineering and Science * &#x60;IDS&#x60; - Data, Systems, and Society * &#x60;MAS&#x60; - Media Arts and Sciences * &#x60;PE&#x60; - Athletics, Physical Education and Recreation * &#x60;SP&#x60; - Special Programs * &#x60;STS&#x60; - Science, Technology, and Society * &#x60;WGS&#x60; - Women\&#39;s and Gender Studies
     * @param {boolean} [free] The course/program is offered for free
     * @param {Array<FeaturedListLevelEnum>} [level] The academic level of the resources  * &#x60;undergraduate&#x60; - Undergraduate * &#x60;graduate&#x60; - Graduate * &#x60;high_school&#x60; - High School * &#x60;noncredit&#x60; - Non-Credit * &#x60;advanced&#x60; - Advanced * &#x60;intermediate&#x60; - Intermediate * &#x60;introductory&#x60; - Introductory
     * @param {number} [limit] Number of results to return per page.
     * @param {Array<FeaturedListOfferedByEnum>} [offered_by] The organization that offers a learning resource  * &#x60;mitx&#x60; - MITx * &#x60;ocw&#x60; - MIT OpenCourseWare * &#x60;bootcamps&#x60; - Bootcamps * &#x60;xpro&#x60; - MIT xPRO * &#x60;mitpe&#x60; - MIT Professional Education * &#x60;see&#x60; - MIT Sloan Executive Education
     * @param {number} [offset] The initial index from which to return the results.
     * @param {Array<FeaturedListPlatformEnum>} [platform] The platform on which learning resources are offered  * &#x60;edx&#x60; - edX * &#x60;ocw&#x60; - MIT OpenCourseWare * &#x60;oll&#x60; - Open Learning Library * &#x60;mitxonline&#x60; - MITx Online * &#x60;bootcamps&#x60; - Bootcamps * &#x60;xpro&#x60; - MIT xPRO * &#x60;csail&#x60; - CSAIL * &#x60;mitpe&#x60; - MIT Professional Education * &#x60;see&#x60; - MIT Sloan Executive Education * &#x60;scc&#x60; - Schwarzman College of Computing * &#x60;ctl&#x60; - Center for Transportation &amp; Logistics * &#x60;whu&#x60; - WHU * &#x60;susskind&#x60; - Susskind * &#x60;globalalumni&#x60; - Global Alumni * &#x60;simplilearn&#x60; - Simplilearn * &#x60;emeritus&#x60; - Emeritus * &#x60;podcast&#x60; - Podcast * &#x60;youtube&#x60; - YouTube
     * @param {boolean} [professional]
     * @param {Array<string>} [readable_id] Multiple values may be separated by commas.
     * @param {Array<FeaturedListResourceCategoryEnum>} [resource_category] The resource category of the learning resources  * &#x60;course&#x60; - Course * &#x60;program&#x60; - Program * &#x60;learning_material&#x60; - Learning Material
     * @param {Array<FeaturedListResourceTypeEnum>} [resource_type] The type of learning resource  * &#x60;course&#x60; - Course * &#x60;program&#x60; - Program * &#x60;learning_path&#x60; - Learning Path * &#x60;podcast&#x60; - Podcast * &#x60;podcast_episode&#x60; - Podcast Episode * &#x60;video&#x60; - Video * &#x60;video_playlist&#x60; - Video Playlist
     * @param {FeaturedListSortbyEnum} [sortby] Sort By  * &#x60;id&#x60; - Object ID ascending * &#x60;-id&#x60; - Object ID descending * &#x60;readable_id&#x60; - Readable ID ascending * &#x60;-readable_id&#x60; - Readable ID descending * &#x60;last_modified&#x60; - Last Modified Date ascending * &#x60;-last_modified&#x60; - Last Modified Date descending * &#x60;new&#x60; - Newest resources first * &#x60;start_date&#x60; - Start Date ascending * &#x60;-start_date&#x60; - Start Date descending * &#x60;mitcoursenumber&#x60; - MIT course number ascending * &#x60;-mitcoursenumber&#x60; - MIT course number descending * &#x60;views&#x60; - Popularity ascending * &#x60;-views&#x60; - Popularity descending * &#x60;upcoming&#x60; - Next start date ascending
     * @param {Array<string>} [topic] Multiple values may be separated by commas.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async featuredList(
      certification?: boolean,
      certification_type?: Array<FeaturedListCertificationTypeEnum>,
      course_feature?: Array<string>,
      delivery?: Array<Array<FeaturedListDeliveryEnum>>,
      department?: Array<FeaturedListDepartmentEnum>,
      free?: boolean,
      level?: Array<FeaturedListLevelEnum>,
      limit?: number,
      offered_by?: Array<FeaturedListOfferedByEnum>,
      offset?: number,
      platform?: Array<FeaturedListPlatformEnum>,
      professional?: boolean,
      readable_id?: Array<string>,
      resource_category?: Array<FeaturedListResourceCategoryEnum>,
      resource_type?: Array<FeaturedListResourceTypeEnum>,
      sortby?: FeaturedListSortbyEnum,
      topic?: Array<string>,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<PaginatedLearningResourceList>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.featuredList(
        certification,
        certification_type,
        course_feature,
        delivery,
        department,
        free,
        level,
        limit,
        offered_by,
        offset,
        platform,
        professional,
        readable_id,
        resource_category,
        resource_type,
        sortby,
        topic,
        options,
      )
      const index = configuration?.serverIndex ?? 0
      const operationBasePath =
        operationServerMap["FeaturedApi.featuredList"]?.[index]?.url
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, operationBasePath || basePath)
    },
    /**
     * Retrieve a single featured resource
     * @summary Retrieve
     * @param {number} id A unique integer value identifying this learning resource.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async featuredRetrieve(
      id: number,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<LearningResource>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.featuredRetrieve(id, options)
      const index = configuration?.serverIndex ?? 0
      const operationBasePath =
        operationServerMap["FeaturedApi.featuredRetrieve"]?.[index]?.url
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, operationBasePath || basePath)
    },
  }
}

/**
 * FeaturedApi - factory interface
 * @export
 */
export const FeaturedApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = FeaturedApiFp(configuration)
  return {
    /**
     * Get a paginated list of featured resources
     * @summary List
     * @param {FeaturedApiFeaturedListRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    featuredList(
      requestParameters: FeaturedApiFeaturedListRequest = {},
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<PaginatedLearningResourceList> {
      return localVarFp
        .featuredList(
          requestParameters.certification,
          requestParameters.certification_type,
          requestParameters.course_feature,
          requestParameters.delivery,
          requestParameters.department,
          requestParameters.free,
          requestParameters.level,
          requestParameters.limit,
          requestParameters.offered_by,
          requestParameters.offset,
          requestParameters.platform,
          requestParameters.professional,
          requestParameters.readable_id,
          requestParameters.resource_category,
          requestParameters.resource_type,
          requestParameters.sortby,
          requestParameters.topic,
          options,
        )
        .then((request) => request(axios, basePath))
    },
    /**
     * Retrieve a single featured resource
     * @summary Retrieve
     * @param {FeaturedApiFeaturedRetrieveRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    featuredRetrieve(
      requestParameters: FeaturedApiFeaturedRetrieveRequest,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<LearningResource> {
      return localVarFp
        .featuredRetrieve(requestParameters.id, options)
        .then((request) => request(axios, basePath))
    },
  }
}

/**
 * Request parameters for featuredList operation in FeaturedApi.
 * @export
 * @interface FeaturedApiFeaturedListRequest
 */
export interface FeaturedApiFeaturedListRequest {
  /**
   *
   * @type {boolean}
   * @memberof FeaturedApiFeaturedList
   */
  readonly certification?: boolean

  /**
   * The type of certification offered  * &#x60;micromasters&#x60; - MicroMasters Credential * &#x60;professional&#x60; - Professional Certificate * &#x60;completion&#x60; - Certificate of Completion * &#x60;none&#x60; - No Certificate
   * @type {Array<'completion' | 'micromasters' | 'none' | 'professional'>}
   * @memberof FeaturedApiFeaturedList
   */
  readonly certification_type?: Array<FeaturedListCertificationTypeEnum>

  /**
   * Multiple values may be separated by commas.
   * @type {Array<string>}
   * @memberof FeaturedApiFeaturedList
   */
  readonly course_feature?: Array<string>

  /**
   * The delivery of course/program resources  * &#x60;online&#x60; - Online * &#x60;hybrid&#x60; - Hybrid * &#x60;in_person&#x60; - In person * &#x60;offline&#x60; - Offline
   * @type {Array<Array<'online' | 'hybrid' | 'in_person' | 'offline'>>}
   * @memberof FeaturedApiFeaturedList
   */
  readonly delivery?: Array<Array<FeaturedListDeliveryEnum>>

  /**
   * The department that offers learning resources  * &#x60;1&#x60; - Civil and Environmental Engineering * &#x60;2&#x60; - Mechanical Engineering * &#x60;3&#x60; - Materials Science and Engineering * &#x60;4&#x60; - Architecture * &#x60;5&#x60; - Chemistry * &#x60;6&#x60; - Electrical Engineering and Computer Science * &#x60;7&#x60; - Biology * &#x60;8&#x60; - Physics * &#x60;9&#x60; - Brain and Cognitive Sciences * &#x60;10&#x60; - Chemical Engineering * &#x60;11&#x60; - Urban Studies and Planning * &#x60;12&#x60; - Earth, Atmospheric, and Planetary Sciences * &#x60;14&#x60; - Economics * &#x60;15&#x60; - Management * &#x60;16&#x60; - Aeronautics and Astronautics * &#x60;17&#x60; - Political Science * &#x60;18&#x60; - Mathematics * &#x60;20&#x60; - Biological Engineering * &#x60;21A&#x60; - Anthropology * &#x60;21G&#x60; - Global Languages * &#x60;21H&#x60; - History * &#x60;21L&#x60; - Literature * &#x60;21M&#x60; - Music and Theater Arts * &#x60;22&#x60; - Nuclear Science and Engineering * &#x60;24&#x60; - Linguistics and Philosophy * &#x60;CC&#x60; - Concourse * &#x60;CMS-W&#x60; - Comparative Media Studies/Writing * &#x60;EC&#x60; - Edgerton Center * &#x60;ES&#x60; - Experimental Study Group * &#x60;ESD&#x60; - Engineering Systems Division * &#x60;HST&#x60; - Medical Engineering and Science * &#x60;IDS&#x60; - Data, Systems, and Society * &#x60;MAS&#x60; - Media Arts and Sciences * &#x60;PE&#x60; - Athletics, Physical Education and Recreation * &#x60;SP&#x60; - Special Programs * &#x60;STS&#x60; - Science, Technology, and Society * &#x60;WGS&#x60; - Women\&#39;s and Gender Studies
   * @type {Array<'1' | '10' | '11' | '12' | '14' | '15' | '16' | '17' | '18' | '2' | '20' | '21A' | '21G' | '21H' | '21L' | '21M' | '22' | '24' | '3' | '4' | '5' | '6' | '7' | '8' | '9' | 'CC' | 'CMS-W' | 'EC' | 'ES' | 'ESD' | 'HST' | 'IDS' | 'MAS' | 'PE' | 'SP' | 'STS' | 'WGS'>}
   * @memberof FeaturedApiFeaturedList
   */
  readonly department?: Array<FeaturedListDepartmentEnum>

  /**
   * The course/program is offered for free
   * @type {boolean}
   * @memberof FeaturedApiFeaturedList
   */
  readonly free?: boolean

  /**
   * The academic level of the resources  * &#x60;undergraduate&#x60; - Undergraduate * &#x60;graduate&#x60; - Graduate * &#x60;high_school&#x60; - High School * &#x60;noncredit&#x60; - Non-Credit * &#x60;advanced&#x60; - Advanced * &#x60;intermediate&#x60; - Intermediate * &#x60;introductory&#x60; - Introductory
   * @type {Array<'advanced' | 'graduate' | 'high_school' | 'intermediate' | 'introductory' | 'noncredit' | 'undergraduate'>}
   * @memberof FeaturedApiFeaturedList
   */
  readonly level?: Array<FeaturedListLevelEnum>

  /**
   * Number of results to return per page.
   * @type {number}
   * @memberof FeaturedApiFeaturedList
   */
  readonly limit?: number

  /**
   * The organization that offers a learning resource  * &#x60;mitx&#x60; - MITx * &#x60;ocw&#x60; - MIT OpenCourseWare * &#x60;bootcamps&#x60; - Bootcamps * &#x60;xpro&#x60; - MIT xPRO * &#x60;mitpe&#x60; - MIT Professional Education * &#x60;see&#x60; - MIT Sloan Executive Education
   * @type {Array<'bootcamps' | 'mitpe' | 'mitx' | 'ocw' | 'see' | 'xpro'>}
   * @memberof FeaturedApiFeaturedList
   */
  readonly offered_by?: Array<FeaturedListOfferedByEnum>

  /**
   * The initial index from which to return the results.
   * @type {number}
   * @memberof FeaturedApiFeaturedList
   */
  readonly offset?: number

  /**
   * The platform on which learning resources are offered  * &#x60;edx&#x60; - edX * &#x60;ocw&#x60; - MIT OpenCourseWare * &#x60;oll&#x60; - Open Learning Library * &#x60;mitxonline&#x60; - MITx Online * &#x60;bootcamps&#x60; - Bootcamps * &#x60;xpro&#x60; - MIT xPRO * &#x60;csail&#x60; - CSAIL * &#x60;mitpe&#x60; - MIT Professional Education * &#x60;see&#x60; - MIT Sloan Executive Education * &#x60;scc&#x60; - Schwarzman College of Computing * &#x60;ctl&#x60; - Center for Transportation &amp; Logistics * &#x60;whu&#x60; - WHU * &#x60;susskind&#x60; - Susskind * &#x60;globalalumni&#x60; - Global Alumni * &#x60;simplilearn&#x60; - Simplilearn * &#x60;emeritus&#x60; - Emeritus * &#x60;podcast&#x60; - Podcast * &#x60;youtube&#x60; - YouTube
   * @type {Array<'bootcamps' | 'csail' | 'ctl' | 'edx' | 'emeritus' | 'globalalumni' | 'mitpe' | 'mitxonline' | 'ocw' | 'oll' | 'podcast' | 'scc' | 'see' | 'simplilearn' | 'susskind' | 'whu' | 'xpro' | 'youtube'>}
   * @memberof FeaturedApiFeaturedList
   */
  readonly platform?: Array<FeaturedListPlatformEnum>

  /**
   *
   * @type {boolean}
   * @memberof FeaturedApiFeaturedList
   */
  readonly professional?: boolean

  /**
   * Multiple values may be separated by commas.
   * @type {Array<string>}
   * @memberof FeaturedApiFeaturedList
   */
  readonly readable_id?: Array<string>

  /**
   * The resource category of the learning resources  * &#x60;course&#x60; - Course * &#x60;program&#x60; - Program * &#x60;learning_material&#x60; - Learning Material
   * @type {Array<'course' | 'learning_material' | 'program'>}
   * @memberof FeaturedApiFeaturedList
   */
  readonly resource_category?: Array<FeaturedListResourceCategoryEnum>

  /**
   * The type of learning resource  * &#x60;course&#x60; - Course * &#x60;program&#x60; - Program * &#x60;learning_path&#x60; - Learning Path * &#x60;podcast&#x60; - Podcast * &#x60;podcast_episode&#x60; - Podcast Episode * &#x60;video&#x60; - Video * &#x60;video_playlist&#x60; - Video Playlist
   * @type {Array<'course' | 'learning_path' | 'podcast' | 'podcast_episode' | 'program' | 'video' | 'video_playlist'>}
   * @memberof FeaturedApiFeaturedList
   */
  readonly resource_type?: Array<FeaturedListResourceTypeEnum>

  /**
   * Sort By  * &#x60;id&#x60; - Object ID ascending * &#x60;-id&#x60; - Object ID descending * &#x60;readable_id&#x60; - Readable ID ascending * &#x60;-readable_id&#x60; - Readable ID descending * &#x60;last_modified&#x60; - Last Modified Date ascending * &#x60;-last_modified&#x60; - Last Modified Date descending * &#x60;new&#x60; - Newest resources first * &#x60;start_date&#x60; - Start Date ascending * &#x60;-start_date&#x60; - Start Date descending * &#x60;mitcoursenumber&#x60; - MIT course number ascending * &#x60;-mitcoursenumber&#x60; - MIT course number descending * &#x60;views&#x60; - Popularity ascending * &#x60;-views&#x60; - Popularity descending * &#x60;upcoming&#x60; - Next start date ascending
   * @type {'-id' | '-last_modified' | '-mitcoursenumber' | '-readable_id' | '-start_date' | '-views' | 'id' | 'last_modified' | 'mitcoursenumber' | 'new' | 'readable_id' | 'start_date' | 'upcoming' | 'views'}
   * @memberof FeaturedApiFeaturedList
   */
  readonly sortby?: FeaturedListSortbyEnum

  /**
   * Multiple values may be separated by commas.
   * @type {Array<string>}
   * @memberof FeaturedApiFeaturedList
   */
  readonly topic?: Array<string>
}

/**
 * Request parameters for featuredRetrieve operation in FeaturedApi.
 * @export
 * @interface FeaturedApiFeaturedRetrieveRequest
 */
export interface FeaturedApiFeaturedRetrieveRequest {
  /**
   * A unique integer value identifying this learning resource.
   * @type {number}
   * @memberof FeaturedApiFeaturedRetrieve
   */
  readonly id: number
}

/**
 * FeaturedApi - object-oriented interface
 * @export
 * @class FeaturedApi
 * @extends {BaseAPI}
 */
export class FeaturedApi extends BaseAPI {
  /**
   * Get a paginated list of featured resources
   * @summary List
   * @param {FeaturedApiFeaturedListRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof FeaturedApi
   */
  public featuredList(
    requestParameters: FeaturedApiFeaturedListRequest = {},
    options?: RawAxiosRequestConfig,
  ) {
    return FeaturedApiFp(this.configuration)
      .featuredList(
        requestParameters.certification,
        requestParameters.certification_type,
        requestParameters.course_feature,
        requestParameters.delivery,
        requestParameters.department,
        requestParameters.free,
        requestParameters.level,
        requestParameters.limit,
        requestParameters.offered_by,
        requestParameters.offset,
        requestParameters.platform,
        requestParameters.professional,
        requestParameters.readable_id,
        requestParameters.resource_category,
        requestParameters.resource_type,
        requestParameters.sortby,
        requestParameters.topic,
        options,
      )
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   * Retrieve a single featured resource
   * @summary Retrieve
   * @param {FeaturedApiFeaturedRetrieveRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof FeaturedApi
   */
  public featuredRetrieve(
    requestParameters: FeaturedApiFeaturedRetrieveRequest,
    options?: RawAxiosRequestConfig,
  ) {
    return FeaturedApiFp(this.configuration)
      .featuredRetrieve(requestParameters.id, options)
      .then((request) => request(this.axios, this.basePath))
  }
}

/**
 * @export
 */
export const FeaturedListCertificationTypeEnum = {
  Completion: "completion",
  Micromasters: "micromasters",
  None: "none",
  Professional: "professional",
} as const
export type FeaturedListCertificationTypeEnum =
  (typeof FeaturedListCertificationTypeEnum)[keyof typeof FeaturedListCertificationTypeEnum]
/**
 * @export
 */
export const FeaturedListDeliveryEnum = {
  Online: "online",
  Hybrid: "hybrid",
  InPerson: "in_person",
  Offline: "offline",
} as const
export type FeaturedListDeliveryEnum =
  (typeof FeaturedListDeliveryEnum)[keyof typeof FeaturedListDeliveryEnum]
/**
 * @export
 */
export const FeaturedListDepartmentEnum = {
  _1: "1",
  _10: "10",
  _11: "11",
  _12: "12",
  _14: "14",
  _15: "15",
  _16: "16",
  _17: "17",
  _18: "18",
  _2: "2",
  _20: "20",
  _21A: "21A",
  _21G: "21G",
  _21H: "21H",
  _21L: "21L",
  _21M: "21M",
  _22: "22",
  _24: "24",
  _3: "3",
  _4: "4",
  _5: "5",
  _6: "6",
  _7: "7",
  _8: "8",
  _9: "9",
  Cc: "CC",
  CmsW: "CMS-W",
  Ec: "EC",
  Es: "ES",
  Esd: "ESD",
  Hst: "HST",
  Ids: "IDS",
  Mas: "MAS",
  Pe: "PE",
  Sp: "SP",
  Sts: "STS",
  Wgs: "WGS",
} as const
export type FeaturedListDepartmentEnum =
  (typeof FeaturedListDepartmentEnum)[keyof typeof FeaturedListDepartmentEnum]
/**
 * @export
 */
export const FeaturedListLevelEnum = {
  Advanced: "advanced",
  Graduate: "graduate",
  HighSchool: "high_school",
  Intermediate: "intermediate",
  Introductory: "introductory",
  Noncredit: "noncredit",
  Undergraduate: "undergraduate",
} as const
export type FeaturedListLevelEnum =
  (typeof FeaturedListLevelEnum)[keyof typeof FeaturedListLevelEnum]
/**
 * @export
 */
export const FeaturedListOfferedByEnum = {
  Bootcamps: "bootcamps",
  Mitpe: "mitpe",
  Mitx: "mitx",
  Ocw: "ocw",
  See: "see",
  Xpro: "xpro",
} as const
export type FeaturedListOfferedByEnum =
  (typeof FeaturedListOfferedByEnum)[keyof typeof FeaturedListOfferedByEnum]
/**
 * @export
 */
export const FeaturedListPlatformEnum = {
  Bootcamps: "bootcamps",
  Csail: "csail",
  Ctl: "ctl",
  Edx: "edx",
  Emeritus: "emeritus",
  Globalalumni: "globalalumni",
  Mitpe: "mitpe",
  Mitxonline: "mitxonline",
  Ocw: "ocw",
  Oll: "oll",
  Podcast: "podcast",
  Scc: "scc",
  See: "see",
  Simplilearn: "simplilearn",
  Susskind: "susskind",
  Whu: "whu",
  Xpro: "xpro",
  Youtube: "youtube",
} as const
export type FeaturedListPlatformEnum =
  (typeof FeaturedListPlatformEnum)[keyof typeof FeaturedListPlatformEnum]
/**
 * @export
 */
export const FeaturedListResourceCategoryEnum = {
  Course: "course",
  LearningMaterial: "learning_material",
  Program: "program",
} as const
export type FeaturedListResourceCategoryEnum =
  (typeof FeaturedListResourceCategoryEnum)[keyof typeof FeaturedListResourceCategoryEnum]
/**
 * @export
 */
export const FeaturedListResourceTypeEnum = {
  Course: "course",
  LearningPath: "learning_path",
  Podcast: "podcast",
  PodcastEpisode: "podcast_episode",
  Program: "program",
  Video: "video",
  VideoPlaylist: "video_playlist",
} as const
export type FeaturedListResourceTypeEnum =
  (typeof FeaturedListResourceTypeEnum)[keyof typeof FeaturedListResourceTypeEnum]
/**
 * @export
 */
export const FeaturedListSortbyEnum = {
  Id: "-id",
  LastModified: "-last_modified",
  Mitcoursenumber: "-mitcoursenumber",
  ReadableId: "-readable_id",
  StartDate: "-start_date",
  Views: "-views",
  Id2: "id",
  LastModified2: "last_modified",
  Mitcoursenumber2: "mitcoursenumber",
  New: "new",
  ReadableId2: "readable_id",
  StartDate2: "start_date",
  Upcoming: "upcoming",
  Views2: "views",
} as const
export type FeaturedListSortbyEnum =
  (typeof FeaturedListSortbyEnum)[keyof typeof FeaturedListSortbyEnum]

/**
 * LearningResourcesApi - axios parameter creator
 * @export
 */
export const LearningResourcesApiAxiosParamCreator = function (
  configuration?: Configuration,
) {
  return {
    /**
     * Show content files for a learning resource
     * @summary Learning Resource Content File List
     * @param {number} learning_resource_id id of the parent learning resource
     * @param {Array<string>} [content_feature_type] Multiple values may be separated by commas.
     * @param {number} [limit] Number of results to return per page.
     * @param {Array<LearningResourcesContentfilesListOfferedByEnum>} [offered_by] The organization that offers a learning resource the content file belongs to  * &#x60;mitx&#x60; - MITx * &#x60;ocw&#x60; - MIT OpenCourseWare * &#x60;bootcamps&#x60; - Bootcamps * &#x60;xpro&#x60; - MIT xPRO * &#x60;mitpe&#x60; - MIT Professional Education * &#x60;see&#x60; - MIT Sloan Executive Education
     * @param {number} [offset] The initial index from which to return the results.
     * @param {Array<LearningResourcesContentfilesListPlatformEnum>} [platform] The platform on which learning resources the content file belongs to is offered  * &#x60;edx&#x60; - edX * &#x60;ocw&#x60; - MIT OpenCourseWare * &#x60;oll&#x60; - Open Learning Library * &#x60;mitxonline&#x60; - MITx Online * &#x60;bootcamps&#x60; - Bootcamps * &#x60;xpro&#x60; - MIT xPRO * &#x60;csail&#x60; - CSAIL * &#x60;mitpe&#x60; - MIT Professional Education * &#x60;see&#x60; - MIT Sloan Executive Education * &#x60;scc&#x60; - Schwarzman College of Computing * &#x60;ctl&#x60; - Center for Transportation &amp; Logistics * &#x60;whu&#x60; - WHU * &#x60;susskind&#x60; - Susskind * &#x60;globalalumni&#x60; - Global Alumni * &#x60;simplilearn&#x60; - Simplilearn * &#x60;emeritus&#x60; - Emeritus * &#x60;podcast&#x60; - Podcast * &#x60;youtube&#x60; - YouTube
     * @param {Array<number>} [resource_id] Multiple values may be separated by commas.
     * @param {Array<number>} [run_id] Multiple values may be separated by commas.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    learningResourcesContentfilesList: async (
      learning_resource_id: number,
      content_feature_type?: Array<string>,
      limit?: number,
      offered_by?: Array<LearningResourcesContentfilesListOfferedByEnum>,
      offset?: number,
      platform?: Array<LearningResourcesContentfilesListPlatformEnum>,
      resource_id?: Array<number>,
      run_id?: Array<number>,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'learning_resource_id' is not null or undefined
      assertParamExists(
        "learningResourcesContentfilesList",
        "learning_resource_id",
        learning_resource_id,
      )
      const localVarPath =
        `/api/v1/learning_resources/{learning_resource_id}/contentfiles/`.replace(
          `{${"learning_resource_id"}}`,
          encodeURIComponent(String(learning_resource_id)),
        )
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      if (content_feature_type) {
        localVarQueryParameter["content_feature_type"] =
          content_feature_type.join(COLLECTION_FORMATS.csv)
      }

      if (limit !== undefined) {
        localVarQueryParameter["limit"] = limit
      }

      if (offered_by) {
        localVarQueryParameter["offered_by"] = offered_by
      }

      if (offset !== undefined) {
        localVarQueryParameter["offset"] = offset
      }

      if (platform) {
        localVarQueryParameter["platform"] = platform
      }

      if (resource_id) {
        localVarQueryParameter["resource_id"] = resource_id.join(
          COLLECTION_FORMATS.csv,
        )
      }

      if (run_id) {
        localVarQueryParameter["run_id"] = run_id.join(COLLECTION_FORMATS.csv)
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     * Show content files for a learning resource
     * @summary Learning Resource Content File Retrieve
     * @param {number} id A unique integer value identifying this contentfile.
     * @param {number} learning_resource_id id of the parent learning resource
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    learningResourcesContentfilesRetrieve: async (
      id: number,
      learning_resource_id: number,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists("learningResourcesContentfilesRetrieve", "id", id)
      // verify required parameter 'learning_resource_id' is not null or undefined
      assertParamExists(
        "learningResourcesContentfilesRetrieve",
        "learning_resource_id",
        learning_resource_id,
      )
      const localVarPath =
        `/api/v1/learning_resources/{learning_resource_id}/contentfiles/{id}/`
          .replace(`{${"id"}}`, encodeURIComponent(String(id)))
          .replace(
            `{${"learning_resource_id"}}`,
            encodeURIComponent(String(learning_resource_id)),
          )
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     * Get a list of related learning resources for a learning resource.
     * @summary Nested Learning Resource List
     * @param {number} learning_resource_id id of the parent learning resource
     * @param {number} [limit] Number of results to return per page.
     * @param {number} [offset] The initial index from which to return the results.
     * @param {string} [sortby] Which field to use when ordering the results.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    learningResourcesItemsList: async (
      learning_resource_id: number,
      limit?: number,
      offset?: number,
      sortby?: string,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'learning_resource_id' is not null or undefined
      assertParamExists(
        "learningResourcesItemsList",
        "learning_resource_id",
        learning_resource_id,
      )
      const localVarPath =
        `/api/v1/learning_resources/{learning_resource_id}/items/`.replace(
          `{${"learning_resource_id"}}`,
          encodeURIComponent(String(learning_resource_id)),
        )
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      if (limit !== undefined) {
        localVarQueryParameter["limit"] = limit
      }

      if (offset !== undefined) {
        localVarQueryParameter["offset"] = offset
      }

      if (sortby !== undefined) {
        localVarQueryParameter["sortby"] = sortby
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     * Get a singe related learning resource for a learning resource.
     * @summary Nested Learning Resource Retrieve
     * @param {number} id A unique integer value identifying this learning resource relationship.
     * @param {number} learning_resource_id id of the parent learning resource
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    learningResourcesItemsRetrieve: async (
      id: number,
      learning_resource_id: number,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists("learningResourcesItemsRetrieve", "id", id)
      // verify required parameter 'learning_resource_id' is not null or undefined
      assertParamExists(
        "learningResourcesItemsRetrieve",
        "learning_resource_id",
        learning_resource_id,
      )
      const localVarPath =
        `/api/v1/learning_resources/{learning_resource_id}/items/{id}/`
          .replace(`{${"id"}}`, encodeURIComponent(String(id)))
          .replace(
            `{${"learning_resource_id"}}`,
            encodeURIComponent(String(learning_resource_id)),
          )
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     * Set Learning Path Relationships on a given Learning Resource.
     * @summary Set Learning Path Relationships
     * @param {number} id id of the learning resource
     * @param {Array<number>} [learning_path_id] id of the parent learning path
     * @param {PatchedLearningResourceRelationshipRequest} [PatchedLearningResourceRelationshipRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    learningResourcesLearningPathsPartialUpdate: async (
      id: number,
      learning_path_id?: Array<number>,
      PatchedLearningResourceRelationshipRequest?: PatchedLearningResourceRelationshipRequest,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists("learningResourcesLearningPathsPartialUpdate", "id", id)
      const localVarPath =
        `/api/v1/learning_resources/{id}/learning_paths/`.replace(
          `{${"id"}}`,
          encodeURIComponent(String(id)),
        )
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = {
        method: "PATCH",
        ...baseOptions,
        ...options,
      }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      if (learning_path_id) {
        localVarQueryParameter["learning_path_id"] = learning_path_id
      }

      localVarHeaderParameter["Content-Type"] = "application/json"

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }
      localVarRequestOptions.data = serializeDataIfNeeded(
        PatchedLearningResourceRelationshipRequest,
        localVarRequestOptions,
        configuration,
      )

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     * Get a paginated list of learning resources.
     * @summary List
     * @param {boolean} [certification]
     * @param {Array<LearningResourcesListCertificationTypeEnum>} [certification_type] The type of certification offered  * &#x60;micromasters&#x60; - MicroMasters Credential * &#x60;professional&#x60; - Professional Certificate * &#x60;completion&#x60; - Certificate of Completion * &#x60;none&#x60; - No Certificate
     * @param {Array<string>} [course_feature] Multiple values may be separated by commas.
     * @param {Array<Array<LearningResourcesListDeliveryEnum>>} [delivery] The delivery of course/program resources  * &#x60;online&#x60; - Online * &#x60;hybrid&#x60; - Hybrid * &#x60;in_person&#x60; - In person * &#x60;offline&#x60; - Offline
     * @param {Array<LearningResourcesListDepartmentEnum>} [department] The department that offers learning resources  * &#x60;1&#x60; - Civil and Environmental Engineering * &#x60;2&#x60; - Mechanical Engineering * &#x60;3&#x60; - Materials Science and Engineering * &#x60;4&#x60; - Architecture * &#x60;5&#x60; - Chemistry * &#x60;6&#x60; - Electrical Engineering and Computer Science * &#x60;7&#x60; - Biology * &#x60;8&#x60; - Physics * &#x60;9&#x60; - Brain and Cognitive Sciences * &#x60;10&#x60; - Chemical Engineering * &#x60;11&#x60; - Urban Studies and Planning * &#x60;12&#x60; - Earth, Atmospheric, and Planetary Sciences * &#x60;14&#x60; - Economics * &#x60;15&#x60; - Management * &#x60;16&#x60; - Aeronautics and Astronautics * &#x60;17&#x60; - Political Science * &#x60;18&#x60; - Mathematics * &#x60;20&#x60; - Biological Engineering * &#x60;21A&#x60; - Anthropology * &#x60;21G&#x60; - Global Languages * &#x60;21H&#x60; - History * &#x60;21L&#x60; - Literature * &#x60;21M&#x60; - Music and Theater Arts * &#x60;22&#x60; - Nuclear Science and Engineering * &#x60;24&#x60; - Linguistics and Philosophy * &#x60;CC&#x60; - Concourse * &#x60;CMS-W&#x60; - Comparative Media Studies/Writing * &#x60;EC&#x60; - Edgerton Center * &#x60;ES&#x60; - Experimental Study Group * &#x60;ESD&#x60; - Engineering Systems Division * &#x60;HST&#x60; - Medical Engineering and Science * &#x60;IDS&#x60; - Data, Systems, and Society * &#x60;MAS&#x60; - Media Arts and Sciences * &#x60;PE&#x60; - Athletics, Physical Education and Recreation * &#x60;SP&#x60; - Special Programs * &#x60;STS&#x60; - Science, Technology, and Society * &#x60;WGS&#x60; - Women\&#39;s and Gender Studies
     * @param {boolean} [free] The course/program is offered for free
     * @param {Array<LearningResourcesListLevelEnum>} [level] The academic level of the resources  * &#x60;undergraduate&#x60; - Undergraduate * &#x60;graduate&#x60; - Graduate * &#x60;high_school&#x60; - High School * &#x60;noncredit&#x60; - Non-Credit * &#x60;advanced&#x60; - Advanced * &#x60;intermediate&#x60; - Intermediate * &#x60;introductory&#x60; - Introductory
     * @param {number} [limit] Number of results to return per page.
     * @param {Array<LearningResourcesListOfferedByEnum>} [offered_by] The organization that offers a learning resource  * &#x60;mitx&#x60; - MITx * &#x60;ocw&#x60; - MIT OpenCourseWare * &#x60;bootcamps&#x60; - Bootcamps * &#x60;xpro&#x60; - MIT xPRO * &#x60;mitpe&#x60; - MIT Professional Education * &#x60;see&#x60; - MIT Sloan Executive Education
     * @param {number} [offset] The initial index from which to return the results.
     * @param {Array<LearningResourcesListPlatformEnum>} [platform] The platform on which learning resources are offered  * &#x60;edx&#x60; - edX * &#x60;ocw&#x60; - MIT OpenCourseWare * &#x60;oll&#x60; - Open Learning Library * &#x60;mitxonline&#x60; - MITx Online * &#x60;bootcamps&#x60; - Bootcamps * &#x60;xpro&#x60; - MIT xPRO * &#x60;csail&#x60; - CSAIL * &#x60;mitpe&#x60; - MIT Professional Education * &#x60;see&#x60; - MIT Sloan Executive Education * &#x60;scc&#x60; - Schwarzman College of Computing * &#x60;ctl&#x60; - Center for Transportation &amp; Logistics * &#x60;whu&#x60; - WHU * &#x60;susskind&#x60; - Susskind * &#x60;globalalumni&#x60; - Global Alumni * &#x60;simplilearn&#x60; - Simplilearn * &#x60;emeritus&#x60; - Emeritus * &#x60;podcast&#x60; - Podcast * &#x60;youtube&#x60; - YouTube
     * @param {boolean} [professional]
     * @param {Array<string>} [readable_id] Multiple values may be separated by commas.
     * @param {Array<LearningResourcesListResourceCategoryEnum>} [resource_category] The resource category of the learning resources  * &#x60;course&#x60; - Course * &#x60;program&#x60; - Program * &#x60;learning_material&#x60; - Learning Material
     * @param {Array<LearningResourcesListResourceTypeEnum>} [resource_type] The type of learning resource  * &#x60;course&#x60; - Course * &#x60;program&#x60; - Program * &#x60;learning_path&#x60; - Learning Path * &#x60;podcast&#x60; - Podcast * &#x60;podcast_episode&#x60; - Podcast Episode * &#x60;video&#x60; - Video * &#x60;video_playlist&#x60; - Video Playlist
     * @param {LearningResourcesListSortbyEnum} [sortby] Sort By  * &#x60;id&#x60; - Object ID ascending * &#x60;-id&#x60; - Object ID descending * &#x60;readable_id&#x60; - Readable ID ascending * &#x60;-readable_id&#x60; - Readable ID descending * &#x60;last_modified&#x60; - Last Modified Date ascending * &#x60;-last_modified&#x60; - Last Modified Date descending * &#x60;new&#x60; - Newest resources first * &#x60;start_date&#x60; - Start Date ascending * &#x60;-start_date&#x60; - Start Date descending * &#x60;mitcoursenumber&#x60; - MIT course number ascending * &#x60;-mitcoursenumber&#x60; - MIT course number descending * &#x60;views&#x60; - Popularity ascending * &#x60;-views&#x60; - Popularity descending * &#x60;upcoming&#x60; - Next start date ascending
     * @param {Array<string>} [topic] Multiple values may be separated by commas.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    learningResourcesList: async (
      certification?: boolean,
      certification_type?: Array<LearningResourcesListCertificationTypeEnum>,
      course_feature?: Array<string>,
      delivery?: Array<Array<LearningResourcesListDeliveryEnum>>,
      department?: Array<LearningResourcesListDepartmentEnum>,
      free?: boolean,
      level?: Array<LearningResourcesListLevelEnum>,
      limit?: number,
      offered_by?: Array<LearningResourcesListOfferedByEnum>,
      offset?: number,
      platform?: Array<LearningResourcesListPlatformEnum>,
      professional?: boolean,
      readable_id?: Array<string>,
      resource_category?: Array<LearningResourcesListResourceCategoryEnum>,
      resource_type?: Array<LearningResourcesListResourceTypeEnum>,
      sortby?: LearningResourcesListSortbyEnum,
      topic?: Array<string>,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/api/v1/learning_resources/`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      if (certification !== undefined) {
        localVarQueryParameter["certification"] = certification
      }

      if (certification_type) {
        localVarQueryParameter["certification_type"] = certification_type
      }

      if (course_feature) {
        localVarQueryParameter["course_feature"] = course_feature.join(
          COLLECTION_FORMATS.csv,
        )
      }

      if (delivery) {
        localVarQueryParameter["delivery"] = delivery
      }

      if (department) {
        localVarQueryParameter["department"] = department
      }

      if (free !== undefined) {
        localVarQueryParameter["free"] = free
      }

      if (level) {
        localVarQueryParameter["level"] = level
      }

      if (limit !== undefined) {
        localVarQueryParameter["limit"] = limit
      }

      if (offered_by) {
        localVarQueryParameter["offered_by"] = offered_by
      }

      if (offset !== undefined) {
        localVarQueryParameter["offset"] = offset
      }

      if (platform) {
        localVarQueryParameter["platform"] = platform
      }

      if (professional !== undefined) {
        localVarQueryParameter["professional"] = professional
      }

      if (readable_id) {
        localVarQueryParameter["readable_id"] = readable_id.join(
          COLLECTION_FORMATS.csv,
        )
      }

      if (resource_category) {
        localVarQueryParameter["resource_category"] = resource_category
      }

      if (resource_type) {
        localVarQueryParameter["resource_type"] = resource_type
      }

      if (sortby !== undefined) {
        localVarQueryParameter["sortby"] = sortby
      }

      if (topic) {
        localVarQueryParameter["topic"] = topic.join(COLLECTION_FORMATS.csv)
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     * Retrieve a single learning resource.
     * @summary Retrieve
     * @param {number} id A unique integer value identifying this learning resource.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    learningResourcesRetrieve: async (
      id: number,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists("learningResourcesRetrieve", "id", id)
      const localVarPath = `/api/v1/learning_resources/{id}/`.replace(
        `{${"id"}}`,
        encodeURIComponent(String(id)),
      )
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     * Fetch similar learning resources  Args: id (integer): The id of the learning resource  Returns: QuerySet of similar LearningResource for the resource matching the id parameter
     * @summary Get similar resources
     * @param {number} id
     * @param {boolean} [certification]
     * @param {Array<LearningResourcesSimilarListCertificationTypeEnum>} [certification_type] The type of certification offered  * &#x60;micromasters&#x60; - MicroMasters Credential * &#x60;professional&#x60; - Professional Certificate * &#x60;completion&#x60; - Certificate of Completion * &#x60;none&#x60; - No Certificate
     * @param {Array<string>} [course_feature] Multiple values may be separated by commas.
     * @param {Array<Array<LearningResourcesSimilarListDeliveryEnum>>} [delivery] The delivery of course/program resources  * &#x60;online&#x60; - Online * &#x60;hybrid&#x60; - Hybrid * &#x60;in_person&#x60; - In person * &#x60;offline&#x60; - Offline
     * @param {Array<LearningResourcesSimilarListDepartmentEnum>} [department] The department that offers learning resources  * &#x60;1&#x60; - Civil and Environmental Engineering * &#x60;2&#x60; - Mechanical Engineering * &#x60;3&#x60; - Materials Science and Engineering * &#x60;4&#x60; - Architecture * &#x60;5&#x60; - Chemistry * &#x60;6&#x60; - Electrical Engineering and Computer Science * &#x60;7&#x60; - Biology * &#x60;8&#x60; - Physics * &#x60;9&#x60; - Brain and Cognitive Sciences * &#x60;10&#x60; - Chemical Engineering * &#x60;11&#x60; - Urban Studies and Planning * &#x60;12&#x60; - Earth, Atmospheric, and Planetary Sciences * &#x60;14&#x60; - Economics * &#x60;15&#x60; - Management * &#x60;16&#x60; - Aeronautics and Astronautics * &#x60;17&#x60; - Political Science * &#x60;18&#x60; - Mathematics * &#x60;20&#x60; - Biological Engineering * &#x60;21A&#x60; - Anthropology * &#x60;21G&#x60; - Global Languages * &#x60;21H&#x60; - History * &#x60;21L&#x60; - Literature * &#x60;21M&#x60; - Music and Theater Arts * &#x60;22&#x60; - Nuclear Science and Engineering * &#x60;24&#x60; - Linguistics and Philosophy * &#x60;CC&#x60; - Concourse * &#x60;CMS-W&#x60; - Comparative Media Studies/Writing * &#x60;EC&#x60; - Edgerton Center * &#x60;ES&#x60; - Experimental Study Group * &#x60;ESD&#x60; - Engineering Systems Division * &#x60;HST&#x60; - Medical Engineering and Science * &#x60;IDS&#x60; - Data, Systems, and Society * &#x60;MAS&#x60; - Media Arts and Sciences * &#x60;PE&#x60; - Athletics, Physical Education and Recreation * &#x60;SP&#x60; - Special Programs * &#x60;STS&#x60; - Science, Technology, and Society * &#x60;WGS&#x60; - Women\&#39;s and Gender Studies
     * @param {boolean} [free] The course/program is offered for free
     * @param {Array<LearningResourcesSimilarListLevelEnum>} [level] The academic level of the resources  * &#x60;undergraduate&#x60; - Undergraduate * &#x60;graduate&#x60; - Graduate * &#x60;high_school&#x60; - High School * &#x60;noncredit&#x60; - Non-Credit * &#x60;advanced&#x60; - Advanced * &#x60;intermediate&#x60; - Intermediate * &#x60;introductory&#x60; - Introductory
     * @param {number} [limit]
     * @param {Array<LearningResourcesSimilarListOfferedByEnum>} [offered_by] The organization that offers a learning resource  * &#x60;mitx&#x60; - MITx * &#x60;ocw&#x60; - MIT OpenCourseWare * &#x60;bootcamps&#x60; - Bootcamps * &#x60;xpro&#x60; - MIT xPRO * &#x60;mitpe&#x60; - MIT Professional Education * &#x60;see&#x60; - MIT Sloan Executive Education
     * @param {Array<LearningResourcesSimilarListPlatformEnum>} [platform] The platform on which learning resources are offered  * &#x60;edx&#x60; - edX * &#x60;ocw&#x60; - MIT OpenCourseWare * &#x60;oll&#x60; - Open Learning Library * &#x60;mitxonline&#x60; - MITx Online * &#x60;bootcamps&#x60; - Bootcamps * &#x60;xpro&#x60; - MIT xPRO * &#x60;csail&#x60; - CSAIL * &#x60;mitpe&#x60; - MIT Professional Education * &#x60;see&#x60; - MIT Sloan Executive Education * &#x60;scc&#x60; - Schwarzman College of Computing * &#x60;ctl&#x60; - Center for Transportation &amp; Logistics * &#x60;whu&#x60; - WHU * &#x60;susskind&#x60; - Susskind * &#x60;globalalumni&#x60; - Global Alumni * &#x60;simplilearn&#x60; - Simplilearn * &#x60;emeritus&#x60; - Emeritus * &#x60;podcast&#x60; - Podcast * &#x60;youtube&#x60; - YouTube
     * @param {boolean} [professional]
     * @param {Array<string>} [readable_id] Multiple values may be separated by commas.
     * @param {Array<LearningResourcesSimilarListResourceCategoryEnum>} [resource_category] The resource category of the learning resources  * &#x60;course&#x60; - Course * &#x60;program&#x60; - Program * &#x60;learning_material&#x60; - Learning Material
     * @param {Array<LearningResourcesSimilarListResourceTypeEnum>} [resource_type] The type of learning resource  * &#x60;course&#x60; - Course * &#x60;program&#x60; - Program * &#x60;learning_path&#x60; - Learning Path * &#x60;podcast&#x60; - Podcast * &#x60;podcast_episode&#x60; - Podcast Episode * &#x60;video&#x60; - Video * &#x60;video_playlist&#x60; - Video Playlist
     * @param {LearningResourcesSimilarListSortbyEnum} [sortby] Sort By  * &#x60;id&#x60; - Object ID ascending * &#x60;-id&#x60; - Object ID descending * &#x60;readable_id&#x60; - Readable ID ascending * &#x60;-readable_id&#x60; - Readable ID descending * &#x60;last_modified&#x60; - Last Modified Date ascending * &#x60;-last_modified&#x60; - Last Modified Date descending * &#x60;new&#x60; - Newest resources first * &#x60;start_date&#x60; - Start Date ascending * &#x60;-start_date&#x60; - Start Date descending * &#x60;mitcoursenumber&#x60; - MIT course number ascending * &#x60;-mitcoursenumber&#x60; - MIT course number descending * &#x60;views&#x60; - Popularity ascending * &#x60;-views&#x60; - Popularity descending * &#x60;upcoming&#x60; - Next start date ascending
     * @param {Array<string>} [topic] Multiple values may be separated by commas.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    learningResourcesSimilarList: async (
      id: number,
      certification?: boolean,
      certification_type?: Array<LearningResourcesSimilarListCertificationTypeEnum>,
      course_feature?: Array<string>,
      delivery?: Array<Array<LearningResourcesSimilarListDeliveryEnum>>,
      department?: Array<LearningResourcesSimilarListDepartmentEnum>,
      free?: boolean,
      level?: Array<LearningResourcesSimilarListLevelEnum>,
      limit?: number,
      offered_by?: Array<LearningResourcesSimilarListOfferedByEnum>,
      platform?: Array<LearningResourcesSimilarListPlatformEnum>,
      professional?: boolean,
      readable_id?: Array<string>,
      resource_category?: Array<LearningResourcesSimilarListResourceCategoryEnum>,
      resource_type?: Array<LearningResourcesSimilarListResourceTypeEnum>,
      sortby?: LearningResourcesSimilarListSortbyEnum,
      topic?: Array<string>,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists("learningResourcesSimilarList", "id", id)
      const localVarPath = `/api/v1/learning_resources/{id}/similar/`.replace(
        `{${"id"}}`,
        encodeURIComponent(String(id)),
      )
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      if (certification !== undefined) {
        localVarQueryParameter["certification"] = certification
      }

      if (certification_type) {
        localVarQueryParameter["certification_type"] = certification_type
      }

      if (course_feature) {
        localVarQueryParameter["course_feature"] = course_feature.join(
          COLLECTION_FORMATS.csv,
        )
      }

      if (delivery) {
        localVarQueryParameter["delivery"] = delivery
      }

      if (department) {
        localVarQueryParameter["department"] = department
      }

      if (free !== undefined) {
        localVarQueryParameter["free"] = free
      }

      if (level) {
        localVarQueryParameter["level"] = level
      }

      if (limit !== undefined) {
        localVarQueryParameter["limit"] = limit
      }

      if (offered_by) {
        localVarQueryParameter["offered_by"] = offered_by
      }

      if (platform) {
        localVarQueryParameter["platform"] = platform
      }

      if (professional !== undefined) {
        localVarQueryParameter["professional"] = professional
      }

      if (readable_id) {
        localVarQueryParameter["readable_id"] = readable_id.join(
          COLLECTION_FORMATS.csv,
        )
      }

      if (resource_category) {
        localVarQueryParameter["resource_category"] = resource_category
      }

      if (resource_type) {
        localVarQueryParameter["resource_type"] = resource_type
      }

      if (sortby !== undefined) {
        localVarQueryParameter["sortby"] = sortby
      }

      if (topic) {
        localVarQueryParameter["topic"] = topic.join(COLLECTION_FORMATS.csv)
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     * Set User List Relationships on a given Learning Resource.
     * @summary Set User List Relationships
     * @param {number} id id of the learning resource
     * @param {Array<number>} [userlist_id] id of the parent user list
     * @param {PatchedUserListRelationshipRequest} [PatchedUserListRelationshipRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    learningResourcesUserlistsPartialUpdate: async (
      id: number,
      userlist_id?: Array<number>,
      PatchedUserListRelationshipRequest?: PatchedUserListRelationshipRequest,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists("learningResourcesUserlistsPartialUpdate", "id", id)
      const localVarPath = `/api/v1/learning_resources/{id}/userlists/`.replace(
        `{${"id"}}`,
        encodeURIComponent(String(id)),
      )
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = {
        method: "PATCH",
        ...baseOptions,
        ...options,
      }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      if (userlist_id) {
        localVarQueryParameter["userlist_id"] = userlist_id
      }

      localVarHeaderParameter["Content-Type"] = "application/json"

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }
      localVarRequestOptions.data = serializeDataIfNeeded(
        PatchedUserListRelationshipRequest,
        localVarRequestOptions,
        configuration,
      )

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     * Fetch similar learning resources  Args: id (integer): The id of the learning resource  Returns: QuerySet of similar LearningResource for the resource matching the id parameter
     * @summary Get similar resources using vector embeddings
     * @param {number} id
     * @param {boolean} [certification]
     * @param {Array<LearningResourcesVectorSimilarListCertificationTypeEnum>} [certification_type] The type of certification offered  * &#x60;micromasters&#x60; - MicroMasters Credential * &#x60;professional&#x60; - Professional Certificate * &#x60;completion&#x60; - Certificate of Completion * &#x60;none&#x60; - No Certificate
     * @param {Array<string>} [course_feature] Multiple values may be separated by commas.
     * @param {Array<Array<LearningResourcesVectorSimilarListDeliveryEnum>>} [delivery] The delivery of course/program resources  * &#x60;online&#x60; - Online * &#x60;hybrid&#x60; - Hybrid * &#x60;in_person&#x60; - In person * &#x60;offline&#x60; - Offline
     * @param {Array<LearningResourcesVectorSimilarListDepartmentEnum>} [department] The department that offers learning resources  * &#x60;1&#x60; - Civil and Environmental Engineering * &#x60;2&#x60; - Mechanical Engineering * &#x60;3&#x60; - Materials Science and Engineering * &#x60;4&#x60; - Architecture * &#x60;5&#x60; - Chemistry * &#x60;6&#x60; - Electrical Engineering and Computer Science * &#x60;7&#x60; - Biology * &#x60;8&#x60; - Physics * &#x60;9&#x60; - Brain and Cognitive Sciences * &#x60;10&#x60; - Chemical Engineering * &#x60;11&#x60; - Urban Studies and Planning * &#x60;12&#x60; - Earth, Atmospheric, and Planetary Sciences * &#x60;14&#x60; - Economics * &#x60;15&#x60; - Management * &#x60;16&#x60; - Aeronautics and Astronautics * &#x60;17&#x60; - Political Science * &#x60;18&#x60; - Mathematics * &#x60;20&#x60; - Biological Engineering * &#x60;21A&#x60; - Anthropology * &#x60;21G&#x60; - Global Languages * &#x60;21H&#x60; - History * &#x60;21L&#x60; - Literature * &#x60;21M&#x60; - Music and Theater Arts * &#x60;22&#x60; - Nuclear Science and Engineering * &#x60;24&#x60; - Linguistics and Philosophy * &#x60;CC&#x60; - Concourse * &#x60;CMS-W&#x60; - Comparative Media Studies/Writing * &#x60;EC&#x60; - Edgerton Center * &#x60;ES&#x60; - Experimental Study Group * &#x60;ESD&#x60; - Engineering Systems Division * &#x60;HST&#x60; - Medical Engineering and Science * &#x60;IDS&#x60; - Data, Systems, and Society * &#x60;MAS&#x60; - Media Arts and Sciences * &#x60;PE&#x60; - Athletics, Physical Education and Recreation * &#x60;SP&#x60; - Special Programs * &#x60;STS&#x60; - Science, Technology, and Society * &#x60;WGS&#x60; - Women\&#39;s and Gender Studies
     * @param {boolean} [free] The course/program is offered for free
     * @param {Array<LearningResourcesVectorSimilarListLevelEnum>} [level] The academic level of the resources  * &#x60;undergraduate&#x60; - Undergraduate * &#x60;graduate&#x60; - Graduate * &#x60;high_school&#x60; - High School * &#x60;noncredit&#x60; - Non-Credit * &#x60;advanced&#x60; - Advanced * &#x60;intermediate&#x60; - Intermediate * &#x60;introductory&#x60; - Introductory
     * @param {number} [limit]
     * @param {Array<LearningResourcesVectorSimilarListOfferedByEnum>} [offered_by] The organization that offers a learning resource  * &#x60;mitx&#x60; - MITx * &#x60;ocw&#x60; - MIT OpenCourseWare * &#x60;bootcamps&#x60; - Bootcamps * &#x60;xpro&#x60; - MIT xPRO * &#x60;mitpe&#x60; - MIT Professional Education * &#x60;see&#x60; - MIT Sloan Executive Education
     * @param {Array<LearningResourcesVectorSimilarListPlatformEnum>} [platform] The platform on which learning resources are offered  * &#x60;edx&#x60; - edX * &#x60;ocw&#x60; - MIT OpenCourseWare * &#x60;oll&#x60; - Open Learning Library * &#x60;mitxonline&#x60; - MITx Online * &#x60;bootcamps&#x60; - Bootcamps * &#x60;xpro&#x60; - MIT xPRO * &#x60;csail&#x60; - CSAIL * &#x60;mitpe&#x60; - MIT Professional Education * &#x60;see&#x60; - MIT Sloan Executive Education * &#x60;scc&#x60; - Schwarzman College of Computing * &#x60;ctl&#x60; - Center for Transportation &amp; Logistics * &#x60;whu&#x60; - WHU * &#x60;susskind&#x60; - Susskind * &#x60;globalalumni&#x60; - Global Alumni * &#x60;simplilearn&#x60; - Simplilearn * &#x60;emeritus&#x60; - Emeritus * &#x60;podcast&#x60; - Podcast * &#x60;youtube&#x60; - YouTube
     * @param {boolean} [professional]
     * @param {Array<string>} [readable_id] Multiple values may be separated by commas.
     * @param {Array<LearningResourcesVectorSimilarListResourceCategoryEnum>} [resource_category] The resource category of the learning resources  * &#x60;course&#x60; - Course * &#x60;program&#x60; - Program * &#x60;learning_material&#x60; - Learning Material
     * @param {Array<LearningResourcesVectorSimilarListResourceTypeEnum>} [resource_type] The type of learning resource  * &#x60;course&#x60; - Course * &#x60;program&#x60; - Program * &#x60;learning_path&#x60; - Learning Path * &#x60;podcast&#x60; - Podcast * &#x60;podcast_episode&#x60; - Podcast Episode * &#x60;video&#x60; - Video * &#x60;video_playlist&#x60; - Video Playlist
     * @param {LearningResourcesVectorSimilarListSortbyEnum} [sortby] Sort By  * &#x60;id&#x60; - Object ID ascending * &#x60;-id&#x60; - Object ID descending * &#x60;readable_id&#x60; - Readable ID ascending * &#x60;-readable_id&#x60; - Readable ID descending * &#x60;last_modified&#x60; - Last Modified Date ascending * &#x60;-last_modified&#x60; - Last Modified Date descending * &#x60;new&#x60; - Newest resources first * &#x60;start_date&#x60; - Start Date ascending * &#x60;-start_date&#x60; - Start Date descending * &#x60;mitcoursenumber&#x60; - MIT course number ascending * &#x60;-mitcoursenumber&#x60; - MIT course number descending * &#x60;views&#x60; - Popularity ascending * &#x60;-views&#x60; - Popularity descending * &#x60;upcoming&#x60; - Next start date ascending
     * @param {Array<string>} [topic] Multiple values may be separated by commas.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    learningResourcesVectorSimilarList: async (
      id: number,
      certification?: boolean,
      certification_type?: Array<LearningResourcesVectorSimilarListCertificationTypeEnum>,
      course_feature?: Array<string>,
      delivery?: Array<Array<LearningResourcesVectorSimilarListDeliveryEnum>>,
      department?: Array<LearningResourcesVectorSimilarListDepartmentEnum>,
      free?: boolean,
      level?: Array<LearningResourcesVectorSimilarListLevelEnum>,
      limit?: number,
      offered_by?: Array<LearningResourcesVectorSimilarListOfferedByEnum>,
      platform?: Array<LearningResourcesVectorSimilarListPlatformEnum>,
      professional?: boolean,
      readable_id?: Array<string>,
      resource_category?: Array<LearningResourcesVectorSimilarListResourceCategoryEnum>,
      resource_type?: Array<LearningResourcesVectorSimilarListResourceTypeEnum>,
      sortby?: LearningResourcesVectorSimilarListSortbyEnum,
      topic?: Array<string>,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists("learningResourcesVectorSimilarList", "id", id)
      const localVarPath =
        `/api/v1/learning_resources/{id}/vector_similar/`.replace(
          `{${"id"}}`,
          encodeURIComponent(String(id)),
        )
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      if (certification !== undefined) {
        localVarQueryParameter["certification"] = certification
      }

      if (certification_type) {
        localVarQueryParameter["certification_type"] = certification_type
      }

      if (course_feature) {
        localVarQueryParameter["course_feature"] = course_feature.join(
          COLLECTION_FORMATS.csv,
        )
      }

      if (delivery) {
        localVarQueryParameter["delivery"] = delivery
      }

      if (department) {
        localVarQueryParameter["department"] = department
      }

      if (free !== undefined) {
        localVarQueryParameter["free"] = free
      }

      if (level) {
        localVarQueryParameter["level"] = level
      }

      if (limit !== undefined) {
        localVarQueryParameter["limit"] = limit
      }

      if (offered_by) {
        localVarQueryParameter["offered_by"] = offered_by
      }

      if (platform) {
        localVarQueryParameter["platform"] = platform
      }

      if (professional !== undefined) {
        localVarQueryParameter["professional"] = professional
      }

      if (readable_id) {
        localVarQueryParameter["readable_id"] = readable_id.join(
          COLLECTION_FORMATS.csv,
        )
      }

      if (resource_category) {
        localVarQueryParameter["resource_category"] = resource_category
      }

      if (resource_type) {
        localVarQueryParameter["resource_type"] = resource_type
      }

      if (sortby !== undefined) {
        localVarQueryParameter["sortby"] = sortby
      }

      if (topic) {
        localVarQueryParameter["topic"] = topic.join(COLLECTION_FORMATS.csv)
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
  }
}

/**
 * LearningResourcesApi - functional programming interface
 * @export
 */
export const LearningResourcesApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator =
    LearningResourcesApiAxiosParamCreator(configuration)
  return {
    /**
     * Show content files for a learning resource
     * @summary Learning Resource Content File List
     * @param {number} learning_resource_id id of the parent learning resource
     * @param {Array<string>} [content_feature_type] Multiple values may be separated by commas.
     * @param {number} [limit] Number of results to return per page.
     * @param {Array<LearningResourcesContentfilesListOfferedByEnum>} [offered_by] The organization that offers a learning resource the content file belongs to  * &#x60;mitx&#x60; - MITx * &#x60;ocw&#x60; - MIT OpenCourseWare * &#x60;bootcamps&#x60; - Bootcamps * &#x60;xpro&#x60; - MIT xPRO * &#x60;mitpe&#x60; - MIT Professional Education * &#x60;see&#x60; - MIT Sloan Executive Education
     * @param {number} [offset] The initial index from which to return the results.
     * @param {Array<LearningResourcesContentfilesListPlatformEnum>} [platform] The platform on which learning resources the content file belongs to is offered  * &#x60;edx&#x60; - edX * &#x60;ocw&#x60; - MIT OpenCourseWare * &#x60;oll&#x60; - Open Learning Library * &#x60;mitxonline&#x60; - MITx Online * &#x60;bootcamps&#x60; - Bootcamps * &#x60;xpro&#x60; - MIT xPRO * &#x60;csail&#x60; - CSAIL * &#x60;mitpe&#x60; - MIT Professional Education * &#x60;see&#x60; - MIT Sloan Executive Education * &#x60;scc&#x60; - Schwarzman College of Computing * &#x60;ctl&#x60; - Center for Transportation &amp; Logistics * &#x60;whu&#x60; - WHU * &#x60;susskind&#x60; - Susskind * &#x60;globalalumni&#x60; - Global Alumni * &#x60;simplilearn&#x60; - Simplilearn * &#x60;emeritus&#x60; - Emeritus * &#x60;podcast&#x60; - Podcast * &#x60;youtube&#x60; - YouTube
     * @param {Array<number>} [resource_id] Multiple values may be separated by commas.
     * @param {Array<number>} [run_id] Multiple values may be separated by commas.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async learningResourcesContentfilesList(
      learning_resource_id: number,
      content_feature_type?: Array<string>,
      limit?: number,
      offered_by?: Array<LearningResourcesContentfilesListOfferedByEnum>,
      offset?: number,
      platform?: Array<LearningResourcesContentfilesListPlatformEnum>,
      resource_id?: Array<number>,
      run_id?: Array<number>,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<PaginatedContentFileList>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.learningResourcesContentfilesList(
          learning_resource_id,
          content_feature_type,
          limit,
          offered_by,
          offset,
          platform,
          resource_id,
          run_id,
          options,
        )
      const index = configuration?.serverIndex ?? 0
      const operationBasePath =
        operationServerMap[
          "LearningResourcesApi.learningResourcesContentfilesList"
        ]?.[index]?.url
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, operationBasePath || basePath)
    },
    /**
     * Show content files for a learning resource
     * @summary Learning Resource Content File Retrieve
     * @param {number} id A unique integer value identifying this contentfile.
     * @param {number} learning_resource_id id of the parent learning resource
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async learningResourcesContentfilesRetrieve(
      id: number,
      learning_resource_id: number,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<ContentFile>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.learningResourcesContentfilesRetrieve(
          id,
          learning_resource_id,
          options,
        )
      const index = configuration?.serverIndex ?? 0
      const operationBasePath =
        operationServerMap[
          "LearningResourcesApi.learningResourcesContentfilesRetrieve"
        ]?.[index]?.url
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, operationBasePath || basePath)
    },
    /**
     * Get a list of related learning resources for a learning resource.
     * @summary Nested Learning Resource List
     * @param {number} learning_resource_id id of the parent learning resource
     * @param {number} [limit] Number of results to return per page.
     * @param {number} [offset] The initial index from which to return the results.
     * @param {string} [sortby] Which field to use when ordering the results.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async learningResourcesItemsList(
      learning_resource_id: number,
      limit?: number,
      offset?: number,
      sortby?: string,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<PaginatedLearningResourceRelationshipList>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.learningResourcesItemsList(
          learning_resource_id,
          limit,
          offset,
          sortby,
          options,
        )
      const index = configuration?.serverIndex ?? 0
      const operationBasePath =
        operationServerMap["LearningResourcesApi.learningResourcesItemsList"]?.[
          index
        ]?.url
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, operationBasePath || basePath)
    },
    /**
     * Get a singe related learning resource for a learning resource.
     * @summary Nested Learning Resource Retrieve
     * @param {number} id A unique integer value identifying this learning resource relationship.
     * @param {number} learning_resource_id id of the parent learning resource
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async learningResourcesItemsRetrieve(
      id: number,
      learning_resource_id: number,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<LearningResourceRelationship>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.learningResourcesItemsRetrieve(
          id,
          learning_resource_id,
          options,
        )
      const index = configuration?.serverIndex ?? 0
      const operationBasePath =
        operationServerMap[
          "LearningResourcesApi.learningResourcesItemsRetrieve"
        ]?.[index]?.url
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, operationBasePath || basePath)
    },
    /**
     * Set Learning Path Relationships on a given Learning Resource.
     * @summary Set Learning Path Relationships
     * @param {number} id id of the learning resource
     * @param {Array<number>} [learning_path_id] id of the parent learning path
     * @param {PatchedLearningResourceRelationshipRequest} [PatchedLearningResourceRelationshipRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async learningResourcesLearningPathsPartialUpdate(
      id: number,
      learning_path_id?: Array<number>,
      PatchedLearningResourceRelationshipRequest?: PatchedLearningResourceRelationshipRequest,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<Array<LearningResourceRelationship>>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.learningResourcesLearningPathsPartialUpdate(
          id,
          learning_path_id,
          PatchedLearningResourceRelationshipRequest,
          options,
        )
      const index = configuration?.serverIndex ?? 0
      const operationBasePath =
        operationServerMap[
          "LearningResourcesApi.learningResourcesLearningPathsPartialUpdate"
        ]?.[index]?.url
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, operationBasePath || basePath)
    },
    /**
     * Get a paginated list of learning resources.
     * @summary List
     * @param {boolean} [certification]
     * @param {Array<LearningResourcesListCertificationTypeEnum>} [certification_type] The type of certification offered  * &#x60;micromasters&#x60; - MicroMasters Credential * &#x60;professional&#x60; - Professional Certificate * &#x60;completion&#x60; - Certificate of Completion * &#x60;none&#x60; - No Certificate
     * @param {Array<string>} [course_feature] Multiple values may be separated by commas.
     * @param {Array<Array<LearningResourcesListDeliveryEnum>>} [delivery] The delivery of course/program resources  * &#x60;online&#x60; - Online * &#x60;hybrid&#x60; - Hybrid * &#x60;in_person&#x60; - In person * &#x60;offline&#x60; - Offline
     * @param {Array<LearningResourcesListDepartmentEnum>} [department] The department that offers learning resources  * &#x60;1&#x60; - Civil and Environmental Engineering * &#x60;2&#x60; - Mechanical Engineering * &#x60;3&#x60; - Materials Science and Engineering * &#x60;4&#x60; - Architecture * &#x60;5&#x60; - Chemistry * &#x60;6&#x60; - Electrical Engineering and Computer Science * &#x60;7&#x60; - Biology * &#x60;8&#x60; - Physics * &#x60;9&#x60; - Brain and Cognitive Sciences * &#x60;10&#x60; - Chemical Engineering * &#x60;11&#x60; - Urban Studies and Planning * &#x60;12&#x60; - Earth, Atmospheric, and Planetary Sciences * &#x60;14&#x60; - Economics * &#x60;15&#x60; - Management * &#x60;16&#x60; - Aeronautics and Astronautics * &#x60;17&#x60; - Political Science * &#x60;18&#x60; - Mathematics * &#x60;20&#x60; - Biological Engineering * &#x60;21A&#x60; - Anthropology * &#x60;21G&#x60; - Global Languages * &#x60;21H&#x60; - History * &#x60;21L&#x60; - Literature * &#x60;21M&#x60; - Music and Theater Arts * &#x60;22&#x60; - Nuclear Science and Engineering * &#x60;24&#x60; - Linguistics and Philosophy * &#x60;CC&#x60; - Concourse * &#x60;CMS-W&#x60; - Comparative Media Studies/Writing * &#x60;EC&#x60; - Edgerton Center * &#x60;ES&#x60; - Experimental Study Group * &#x60;ESD&#x60; - Engineering Systems Division * &#x60;HST&#x60; - Medical Engineering and Science * &#x60;IDS&#x60; - Data, Systems, and Society * &#x60;MAS&#x60; - Media Arts and Sciences * &#x60;PE&#x60; - Athletics, Physical Education and Recreation * &#x60;SP&#x60; - Special Programs * &#x60;STS&#x60; - Science, Technology, and Society * &#x60;WGS&#x60; - Women\&#39;s and Gender Studies
     * @param {boolean} [free] The course/program is offered for free
     * @param {Array<LearningResourcesListLevelEnum>} [level] The academic level of the resources  * &#x60;undergraduate&#x60; - Undergraduate * &#x60;graduate&#x60; - Graduate * &#x60;high_school&#x60; - High School * &#x60;noncredit&#x60; - Non-Credit * &#x60;advanced&#x60; - Advanced * &#x60;intermediate&#x60; - Intermediate * &#x60;introductory&#x60; - Introductory
     * @param {number} [limit] Number of results to return per page.
     * @param {Array<LearningResourcesListOfferedByEnum>} [offered_by] The organization that offers a learning resource  * &#x60;mitx&#x60; - MITx * &#x60;ocw&#x60; - MIT OpenCourseWare * &#x60;bootcamps&#x60; - Bootcamps * &#x60;xpro&#x60; - MIT xPRO * &#x60;mitpe&#x60; - MIT Professional Education * &#x60;see&#x60; - MIT Sloan Executive Education
     * @param {number} [offset] The initial index from which to return the results.
     * @param {Array<LearningResourcesListPlatformEnum>} [platform] The platform on which learning resources are offered  * &#x60;edx&#x60; - edX * &#x60;ocw&#x60; - MIT OpenCourseWare * &#x60;oll&#x60; - Open Learning Library * &#x60;mitxonline&#x60; - MITx Online * &#x60;bootcamps&#x60; - Bootcamps * &#x60;xpro&#x60; - MIT xPRO * &#x60;csail&#x60; - CSAIL * &#x60;mitpe&#x60; - MIT Professional Education * &#x60;see&#x60; - MIT Sloan Executive Education * &#x60;scc&#x60; - Schwarzman College of Computing * &#x60;ctl&#x60; - Center for Transportation &amp; Logistics * &#x60;whu&#x60; - WHU * &#x60;susskind&#x60; - Susskind * &#x60;globalalumni&#x60; - Global Alumni * &#x60;simplilearn&#x60; - Simplilearn * &#x60;emeritus&#x60; - Emeritus * &#x60;podcast&#x60; - Podcast * &#x60;youtube&#x60; - YouTube
     * @param {boolean} [professional]
     * @param {Array<string>} [readable_id] Multiple values may be separated by commas.
     * @param {Array<LearningResourcesListResourceCategoryEnum>} [resource_category] The resource category of the learning resources  * &#x60;course&#x60; - Course * &#x60;program&#x60; - Program * &#x60;learning_material&#x60; - Learning Material
     * @param {Array<LearningResourcesListResourceTypeEnum>} [resource_type] The type of learning resource  * &#x60;course&#x60; - Course * &#x60;program&#x60; - Program * &#x60;learning_path&#x60; - Learning Path * &#x60;podcast&#x60; - Podcast * &#x60;podcast_episode&#x60; - Podcast Episode * &#x60;video&#x60; - Video * &#x60;video_playlist&#x60; - Video Playlist
     * @param {LearningResourcesListSortbyEnum} [sortby] Sort By  * &#x60;id&#x60; - Object ID ascending * &#x60;-id&#x60; - Object ID descending * &#x60;readable_id&#x60; - Readable ID ascending * &#x60;-readable_id&#x60; - Readable ID descending * &#x60;last_modified&#x60; - Last Modified Date ascending * &#x60;-last_modified&#x60; - Last Modified Date descending * &#x60;new&#x60; - Newest resources first * &#x60;start_date&#x60; - Start Date ascending * &#x60;-start_date&#x60; - Start Date descending * &#x60;mitcoursenumber&#x60; - MIT course number ascending * &#x60;-mitcoursenumber&#x60; - MIT course number descending * &#x60;views&#x60; - Popularity ascending * &#x60;-views&#x60; - Popularity descending * &#x60;upcoming&#x60; - Next start date ascending
     * @param {Array<string>} [topic] Multiple values may be separated by commas.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async learningResourcesList(
      certification?: boolean,
      certification_type?: Array<LearningResourcesListCertificationTypeEnum>,
      course_feature?: Array<string>,
      delivery?: Array<Array<LearningResourcesListDeliveryEnum>>,
      department?: Array<LearningResourcesListDepartmentEnum>,
      free?: boolean,
      level?: Array<LearningResourcesListLevelEnum>,
      limit?: number,
      offered_by?: Array<LearningResourcesListOfferedByEnum>,
      offset?: number,
      platform?: Array<LearningResourcesListPlatformEnum>,
      professional?: boolean,
      readable_id?: Array<string>,
      resource_category?: Array<LearningResourcesListResourceCategoryEnum>,
      resource_type?: Array<LearningResourcesListResourceTypeEnum>,
      sortby?: LearningResourcesListSortbyEnum,
      topic?: Array<string>,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<PaginatedLearningResourceList>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.learningResourcesList(
          certification,
          certification_type,
          course_feature,
          delivery,
          department,
          free,
          level,
          limit,
          offered_by,
          offset,
          platform,
          professional,
          readable_id,
          resource_category,
          resource_type,
          sortby,
          topic,
          options,
        )
      const index = configuration?.serverIndex ?? 0
      const operationBasePath =
        operationServerMap["LearningResourcesApi.learningResourcesList"]?.[
          index
        ]?.url
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, operationBasePath || basePath)
    },
    /**
     * Retrieve a single learning resource.
     * @summary Retrieve
     * @param {number} id A unique integer value identifying this learning resource.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async learningResourcesRetrieve(
      id: number,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<LearningResource>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.learningResourcesRetrieve(id, options)
      const index = configuration?.serverIndex ?? 0
      const operationBasePath =
        operationServerMap["LearningResourcesApi.learningResourcesRetrieve"]?.[
          index
        ]?.url
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, operationBasePath || basePath)
    },
    /**
     * Fetch similar learning resources  Args: id (integer): The id of the learning resource  Returns: QuerySet of similar LearningResource for the resource matching the id parameter
     * @summary Get similar resources
     * @param {number} id
     * @param {boolean} [certification]
     * @param {Array<LearningResourcesSimilarListCertificationTypeEnum>} [certification_type] The type of certification offered  * &#x60;micromasters&#x60; - MicroMasters Credential * &#x60;professional&#x60; - Professional Certificate * &#x60;completion&#x60; - Certificate of Completion * &#x60;none&#x60; - No Certificate
     * @param {Array<string>} [course_feature] Multiple values may be separated by commas.
     * @param {Array<Array<LearningResourcesSimilarListDeliveryEnum>>} [delivery] The delivery of course/program resources  * &#x60;online&#x60; - Online * &#x60;hybrid&#x60; - Hybrid * &#x60;in_person&#x60; - In person * &#x60;offline&#x60; - Offline
     * @param {Array<LearningResourcesSimilarListDepartmentEnum>} [department] The department that offers learning resources  * &#x60;1&#x60; - Civil and Environmental Engineering * &#x60;2&#x60; - Mechanical Engineering * &#x60;3&#x60; - Materials Science and Engineering * &#x60;4&#x60; - Architecture * &#x60;5&#x60; - Chemistry * &#x60;6&#x60; - Electrical Engineering and Computer Science * &#x60;7&#x60; - Biology * &#x60;8&#x60; - Physics * &#x60;9&#x60; - Brain and Cognitive Sciences * &#x60;10&#x60; - Chemical Engineering * &#x60;11&#x60; - Urban Studies and Planning * &#x60;12&#x60; - Earth, Atmospheric, and Planetary Sciences * &#x60;14&#x60; - Economics * &#x60;15&#x60; - Management * &#x60;16&#x60; - Aeronautics and Astronautics * &#x60;17&#x60; - Political Science * &#x60;18&#x60; - Mathematics * &#x60;20&#x60; - Biological Engineering * &#x60;21A&#x60; - Anthropology * &#x60;21G&#x60; - Global Languages * &#x60;21H&#x60; - History * &#x60;21L&#x60; - Literature * &#x60;21M&#x60; - Music and Theater Arts * &#x60;22&#x60; - Nuclear Science and Engineering * &#x60;24&#x60; - Linguistics and Philosophy * &#x60;CC&#x60; - Concourse * &#x60;CMS-W&#x60; - Comparative Media Studies/Writing * &#x60;EC&#x60; - Edgerton Center * &#x60;ES&#x60; - Experimental Study Group * &#x60;ESD&#x60; - Engineering Systems Division * &#x60;HST&#x60; - Medical Engineering and Science * &#x60;IDS&#x60; - Data, Systems, and Society * &#x60;MAS&#x60; - Media Arts and Sciences * &#x60;PE&#x60; - Athletics, Physical Education and Recreation * &#x60;SP&#x60; - Special Programs * &#x60;STS&#x60; - Science, Technology, and Society * &#x60;WGS&#x60; - Women\&#39;s and Gender Studies
     * @param {boolean} [free] The course/program is offered for free
     * @param {Array<LearningResourcesSimilarListLevelEnum>} [level] The academic level of the resources  * &#x60;undergraduate&#x60; - Undergraduate * &#x60;graduate&#x60; - Graduate * &#x60;high_school&#x60; - High School * &#x60;noncredit&#x60; - Non-Credit * &#x60;advanced&#x60; - Advanced * &#x60;intermediate&#x60; - Intermediate * &#x60;introductory&#x60; - Introductory
     * @param {number} [limit]
     * @param {Array<LearningResourcesSimilarListOfferedByEnum>} [offered_by] The organization that offers a learning resource  * &#x60;mitx&#x60; - MITx * &#x60;ocw&#x60; - MIT OpenCourseWare * &#x60;bootcamps&#x60; - Bootcamps * &#x60;xpro&#x60; - MIT xPRO * &#x60;mitpe&#x60; - MIT Professional Education * &#x60;see&#x60; - MIT Sloan Executive Education
     * @param {Array<LearningResourcesSimilarListPlatformEnum>} [platform] The platform on which learning resources are offered  * &#x60;edx&#x60; - edX * &#x60;ocw&#x60; - MIT OpenCourseWare * &#x60;oll&#x60; - Open Learning Library * &#x60;mitxonline&#x60; - MITx Online * &#x60;bootcamps&#x60; - Bootcamps * &#x60;xpro&#x60; - MIT xPRO * &#x60;csail&#x60; - CSAIL * &#x60;mitpe&#x60; - MIT Professional Education * &#x60;see&#x60; - MIT Sloan Executive Education * &#x60;scc&#x60; - Schwarzman College of Computing * &#x60;ctl&#x60; - Center for Transportation &amp; Logistics * &#x60;whu&#x60; - WHU * &#x60;susskind&#x60; - Susskind * &#x60;globalalumni&#x60; - Global Alumni * &#x60;simplilearn&#x60; - Simplilearn * &#x60;emeritus&#x60; - Emeritus * &#x60;podcast&#x60; - Podcast * &#x60;youtube&#x60; - YouTube
     * @param {boolean} [professional]
     * @param {Array<string>} [readable_id] Multiple values may be separated by commas.
     * @param {Array<LearningResourcesSimilarListResourceCategoryEnum>} [resource_category] The resource category of the learning resources  * &#x60;course&#x60; - Course * &#x60;program&#x60; - Program * &#x60;learning_material&#x60; - Learning Material
     * @param {Array<LearningResourcesSimilarListResourceTypeEnum>} [resource_type] The type of learning resource  * &#x60;course&#x60; - Course * &#x60;program&#x60; - Program * &#x60;learning_path&#x60; - Learning Path * &#x60;podcast&#x60; - Podcast * &#x60;podcast_episode&#x60; - Podcast Episode * &#x60;video&#x60; - Video * &#x60;video_playlist&#x60; - Video Playlist
     * @param {LearningResourcesSimilarListSortbyEnum} [sortby] Sort By  * &#x60;id&#x60; - Object ID ascending * &#x60;-id&#x60; - Object ID descending * &#x60;readable_id&#x60; - Readable ID ascending * &#x60;-readable_id&#x60; - Readable ID descending * &#x60;last_modified&#x60; - Last Modified Date ascending * &#x60;-last_modified&#x60; - Last Modified Date descending * &#x60;new&#x60; - Newest resources first * &#x60;start_date&#x60; - Start Date ascending * &#x60;-start_date&#x60; - Start Date descending * &#x60;mitcoursenumber&#x60; - MIT course number ascending * &#x60;-mitcoursenumber&#x60; - MIT course number descending * &#x60;views&#x60; - Popularity ascending * &#x60;-views&#x60; - Popularity descending * &#x60;upcoming&#x60; - Next start date ascending
     * @param {Array<string>} [topic] Multiple values may be separated by commas.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async learningResourcesSimilarList(
      id: number,
      certification?: boolean,
      certification_type?: Array<LearningResourcesSimilarListCertificationTypeEnum>,
      course_feature?: Array<string>,
      delivery?: Array<Array<LearningResourcesSimilarListDeliveryEnum>>,
      department?: Array<LearningResourcesSimilarListDepartmentEnum>,
      free?: boolean,
      level?: Array<LearningResourcesSimilarListLevelEnum>,
      limit?: number,
      offered_by?: Array<LearningResourcesSimilarListOfferedByEnum>,
      platform?: Array<LearningResourcesSimilarListPlatformEnum>,
      professional?: boolean,
      readable_id?: Array<string>,
      resource_category?: Array<LearningResourcesSimilarListResourceCategoryEnum>,
      resource_type?: Array<LearningResourcesSimilarListResourceTypeEnum>,
      sortby?: LearningResourcesSimilarListSortbyEnum,
      topic?: Array<string>,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<Array<LearningResource>>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.learningResourcesSimilarList(
          id,
          certification,
          certification_type,
          course_feature,
          delivery,
          department,
          free,
          level,
          limit,
          offered_by,
          platform,
          professional,
          readable_id,
          resource_category,
          resource_type,
          sortby,
          topic,
          options,
        )
      const index = configuration?.serverIndex ?? 0
      const operationBasePath =
        operationServerMap[
          "LearningResourcesApi.learningResourcesSimilarList"
        ]?.[index]?.url
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, operationBasePath || basePath)
    },
    /**
     * Set User List Relationships on a given Learning Resource.
     * @summary Set User List Relationships
     * @param {number} id id of the learning resource
     * @param {Array<number>} [userlist_id] id of the parent user list
     * @param {PatchedUserListRelationshipRequest} [PatchedUserListRelationshipRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async learningResourcesUserlistsPartialUpdate(
      id: number,
      userlist_id?: Array<number>,
      PatchedUserListRelationshipRequest?: PatchedUserListRelationshipRequest,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<Array<UserListRelationship>>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.learningResourcesUserlistsPartialUpdate(
          id,
          userlist_id,
          PatchedUserListRelationshipRequest,
          options,
        )
      const index = configuration?.serverIndex ?? 0
      const operationBasePath =
        operationServerMap[
          "LearningResourcesApi.learningResourcesUserlistsPartialUpdate"
        ]?.[index]?.url
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, operationBasePath || basePath)
    },
    /**
     * Fetch similar learning resources  Args: id (integer): The id of the learning resource  Returns: QuerySet of similar LearningResource for the resource matching the id parameter
     * @summary Get similar resources using vector embeddings
     * @param {number} id
     * @param {boolean} [certification]
     * @param {Array<LearningResourcesVectorSimilarListCertificationTypeEnum>} [certification_type] The type of certification offered  * &#x60;micromasters&#x60; - MicroMasters Credential * &#x60;professional&#x60; - Professional Certificate * &#x60;completion&#x60; - Certificate of Completion * &#x60;none&#x60; - No Certificate
     * @param {Array<string>} [course_feature] Multiple values may be separated by commas.
     * @param {Array<Array<LearningResourcesVectorSimilarListDeliveryEnum>>} [delivery] The delivery of course/program resources  * &#x60;online&#x60; - Online * &#x60;hybrid&#x60; - Hybrid * &#x60;in_person&#x60; - In person * &#x60;offline&#x60; - Offline
     * @param {Array<LearningResourcesVectorSimilarListDepartmentEnum>} [department] The department that offers learning resources  * &#x60;1&#x60; - Civil and Environmental Engineering * &#x60;2&#x60; - Mechanical Engineering * &#x60;3&#x60; - Materials Science and Engineering * &#x60;4&#x60; - Architecture * &#x60;5&#x60; - Chemistry * &#x60;6&#x60; - Electrical Engineering and Computer Science * &#x60;7&#x60; - Biology * &#x60;8&#x60; - Physics * &#x60;9&#x60; - Brain and Cognitive Sciences * &#x60;10&#x60; - Chemical Engineering * &#x60;11&#x60; - Urban Studies and Planning * &#x60;12&#x60; - Earth, Atmospheric, and Planetary Sciences * &#x60;14&#x60; - Economics * &#x60;15&#x60; - Management * &#x60;16&#x60; - Aeronautics and Astronautics * &#x60;17&#x60; - Political Science * &#x60;18&#x60; - Mathematics * &#x60;20&#x60; - Biological Engineering * &#x60;21A&#x60; - Anthropology * &#x60;21G&#x60; - Global Languages * &#x60;21H&#x60; - History * &#x60;21L&#x60; - Literature * &#x60;21M&#x60; - Music and Theater Arts * &#x60;22&#x60; - Nuclear Science and Engineering * &#x60;24&#x60; - Linguistics and Philosophy * &#x60;CC&#x60; - Concourse * &#x60;CMS-W&#x60; - Comparative Media Studies/Writing * &#x60;EC&#x60; - Edgerton Center * &#x60;ES&#x60; - Experimental Study Group * &#x60;ESD&#x60; - Engineering Systems Division * &#x60;HST&#x60; - Medical Engineering and Science * &#x60;IDS&#x60; - Data, Systems, and Society * &#x60;MAS&#x60; - Media Arts and Sciences * &#x60;PE&#x60; - Athletics, Physical Education and Recreation * &#x60;SP&#x60; - Special Programs * &#x60;STS&#x60; - Science, Technology, and Society * &#x60;WGS&#x60; - Women\&#39;s and Gender Studies
     * @param {boolean} [free] The course/program is offered for free
     * @param {Array<LearningResourcesVectorSimilarListLevelEnum>} [level] The academic level of the resources  * &#x60;undergraduate&#x60; - Undergraduate * &#x60;graduate&#x60; - Graduate * &#x60;high_school&#x60; - High School * &#x60;noncredit&#x60; - Non-Credit * &#x60;advanced&#x60; - Advanced * &#x60;intermediate&#x60; - Intermediate * &#x60;introductory&#x60; - Introductory
     * @param {number} [limit]
     * @param {Array<LearningResourcesVectorSimilarListOfferedByEnum>} [offered_by] The organization that offers a learning resource  * &#x60;mitx&#x60; - MITx * &#x60;ocw&#x60; - MIT OpenCourseWare * &#x60;bootcamps&#x60; - Bootcamps * &#x60;xpro&#x60; - MIT xPRO * &#x60;mitpe&#x60; - MIT Professional Education * &#x60;see&#x60; - MIT Sloan Executive Education
     * @param {Array<LearningResourcesVectorSimilarListPlatformEnum>} [platform] The platform on which learning resources are offered  * &#x60;edx&#x60; - edX * &#x60;ocw&#x60; - MIT OpenCourseWare * &#x60;oll&#x60; - Open Learning Library * &#x60;mitxonline&#x60; - MITx Online * &#x60;bootcamps&#x60; - Bootcamps * &#x60;xpro&#x60; - MIT xPRO * &#x60;csail&#x60; - CSAIL * &#x60;mitpe&#x60; - MIT Professional Education * &#x60;see&#x60; - MIT Sloan Executive Education * &#x60;scc&#x60; - Schwarzman College of Computing * &#x60;ctl&#x60; - Center for Transportation &amp; Logistics * &#x60;whu&#x60; - WHU * &#x60;susskind&#x60; - Susskind * &#x60;globalalumni&#x60; - Global Alumni * &#x60;simplilearn&#x60; - Simplilearn * &#x60;emeritus&#x60; - Emeritus * &#x60;podcast&#x60; - Podcast * &#x60;youtube&#x60; - YouTube
     * @param {boolean} [professional]
     * @param {Array<string>} [readable_id] Multiple values may be separated by commas.
     * @param {Array<LearningResourcesVectorSimilarListResourceCategoryEnum>} [resource_category] The resource category of the learning resources  * &#x60;course&#x60; - Course * &#x60;program&#x60; - Program * &#x60;learning_material&#x60; - Learning Material
     * @param {Array<LearningResourcesVectorSimilarListResourceTypeEnum>} [resource_type] The type of learning resource  * &#x60;course&#x60; - Course * &#x60;program&#x60; - Program * &#x60;learning_path&#x60; - Learning Path * &#x60;podcast&#x60; - Podcast * &#x60;podcast_episode&#x60; - Podcast Episode * &#x60;video&#x60; - Video * &#x60;video_playlist&#x60; - Video Playlist
     * @param {LearningResourcesVectorSimilarListSortbyEnum} [sortby] Sort By  * &#x60;id&#x60; - Object ID ascending * &#x60;-id&#x60; - Object ID descending * &#x60;readable_id&#x60; - Readable ID ascending * &#x60;-readable_id&#x60; - Readable ID descending * &#x60;last_modified&#x60; - Last Modified Date ascending * &#x60;-last_modified&#x60; - Last Modified Date descending * &#x60;new&#x60; - Newest resources first * &#x60;start_date&#x60; - Start Date ascending * &#x60;-start_date&#x60; - Start Date descending * &#x60;mitcoursenumber&#x60; - MIT course number ascending * &#x60;-mitcoursenumber&#x60; - MIT course number descending * &#x60;views&#x60; - Popularity ascending * &#x60;-views&#x60; - Popularity descending * &#x60;upcoming&#x60; - Next start date ascending
     * @param {Array<string>} [topic] Multiple values may be separated by commas.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async learningResourcesVectorSimilarList(
      id: number,
      certification?: boolean,
      certification_type?: Array<LearningResourcesVectorSimilarListCertificationTypeEnum>,
      course_feature?: Array<string>,
      delivery?: Array<Array<LearningResourcesVectorSimilarListDeliveryEnum>>,
      department?: Array<LearningResourcesVectorSimilarListDepartmentEnum>,
      free?: boolean,
      level?: Array<LearningResourcesVectorSimilarListLevelEnum>,
      limit?: number,
      offered_by?: Array<LearningResourcesVectorSimilarListOfferedByEnum>,
      platform?: Array<LearningResourcesVectorSimilarListPlatformEnum>,
      professional?: boolean,
      readable_id?: Array<string>,
      resource_category?: Array<LearningResourcesVectorSimilarListResourceCategoryEnum>,
      resource_type?: Array<LearningResourcesVectorSimilarListResourceTypeEnum>,
      sortby?: LearningResourcesVectorSimilarListSortbyEnum,
      topic?: Array<string>,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<Array<LearningResource>>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.learningResourcesVectorSimilarList(
          id,
          certification,
          certification_type,
          course_feature,
          delivery,
          department,
          free,
          level,
          limit,
          offered_by,
          platform,
          professional,
          readable_id,
          resource_category,
          resource_type,
          sortby,
          topic,
          options,
        )
      const index = configuration?.serverIndex ?? 0
      const operationBasePath =
        operationServerMap[
          "LearningResourcesApi.learningResourcesVectorSimilarList"
        ]?.[index]?.url
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, operationBasePath || basePath)
    },
  }
}

/**
 * LearningResourcesApi - factory interface
 * @export
 */
export const LearningResourcesApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = LearningResourcesApiFp(configuration)
  return {
    /**
     * Show content files for a learning resource
     * @summary Learning Resource Content File List
     * @param {LearningResourcesApiLearningResourcesContentfilesListRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    learningResourcesContentfilesList(
      requestParameters: LearningResourcesApiLearningResourcesContentfilesListRequest,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<PaginatedContentFileList> {
      return localVarFp
        .learningResourcesContentfilesList(
          requestParameters.learning_resource_id,
          requestParameters.content_feature_type,
          requestParameters.limit,
          requestParameters.offered_by,
          requestParameters.offset,
          requestParameters.platform,
          requestParameters.resource_id,
          requestParameters.run_id,
          options,
        )
        .then((request) => request(axios, basePath))
    },
    /**
     * Show content files for a learning resource
     * @summary Learning Resource Content File Retrieve
     * @param {LearningResourcesApiLearningResourcesContentfilesRetrieveRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    learningResourcesContentfilesRetrieve(
      requestParameters: LearningResourcesApiLearningResourcesContentfilesRetrieveRequest,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<ContentFile> {
      return localVarFp
        .learningResourcesContentfilesRetrieve(
          requestParameters.id,
          requestParameters.learning_resource_id,
          options,
        )
        .then((request) => request(axios, basePath))
    },
    /**
     * Get a list of related learning resources for a learning resource.
     * @summary Nested Learning Resource List
     * @param {LearningResourcesApiLearningResourcesItemsListRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    learningResourcesItemsList(
      requestParameters: LearningResourcesApiLearningResourcesItemsListRequest,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<PaginatedLearningResourceRelationshipList> {
      return localVarFp
        .learningResourcesItemsList(
          requestParameters.learning_resource_id,
          requestParameters.limit,
          requestParameters.offset,
          requestParameters.sortby,
          options,
        )
        .then((request) => request(axios, basePath))
    },
    /**
     * Get a singe related learning resource for a learning resource.
     * @summary Nested Learning Resource Retrieve
     * @param {LearningResourcesApiLearningResourcesItemsRetrieveRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    learningResourcesItemsRetrieve(
      requestParameters: LearningResourcesApiLearningResourcesItemsRetrieveRequest,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<LearningResourceRelationship> {
      return localVarFp
        .learningResourcesItemsRetrieve(
          requestParameters.id,
          requestParameters.learning_resource_id,
          options,
        )
        .then((request) => request(axios, basePath))
    },
    /**
     * Set Learning Path Relationships on a given Learning Resource.
     * @summary Set Learning Path Relationships
     * @param {LearningResourcesApiLearningResourcesLearningPathsPartialUpdateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    learningResourcesLearningPathsPartialUpdate(
      requestParameters: LearningResourcesApiLearningResourcesLearningPathsPartialUpdateRequest,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<Array<LearningResourceRelationship>> {
      return localVarFp
        .learningResourcesLearningPathsPartialUpdate(
          requestParameters.id,
          requestParameters.learning_path_id,
          requestParameters.PatchedLearningResourceRelationshipRequest,
          options,
        )
        .then((request) => request(axios, basePath))
    },
    /**
     * Get a paginated list of learning resources.
     * @summary List
     * @param {LearningResourcesApiLearningResourcesListRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    learningResourcesList(
      requestParameters: LearningResourcesApiLearningResourcesListRequest = {},
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<PaginatedLearningResourceList> {
      return localVarFp
        .learningResourcesList(
          requestParameters.certification,
          requestParameters.certification_type,
          requestParameters.course_feature,
          requestParameters.delivery,
          requestParameters.department,
          requestParameters.free,
          requestParameters.level,
          requestParameters.limit,
          requestParameters.offered_by,
          requestParameters.offset,
          requestParameters.platform,
          requestParameters.professional,
          requestParameters.readable_id,
          requestParameters.resource_category,
          requestParameters.resource_type,
          requestParameters.sortby,
          requestParameters.topic,
          options,
        )
        .then((request) => request(axios, basePath))
    },
    /**
     * Retrieve a single learning resource.
     * @summary Retrieve
     * @param {LearningResourcesApiLearningResourcesRetrieveRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    learningResourcesRetrieve(
      requestParameters: LearningResourcesApiLearningResourcesRetrieveRequest,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<LearningResource> {
      return localVarFp
        .learningResourcesRetrieve(requestParameters.id, options)
        .then((request) => request(axios, basePath))
    },
    /**
     * Fetch similar learning resources  Args: id (integer): The id of the learning resource  Returns: QuerySet of similar LearningResource for the resource matching the id parameter
     * @summary Get similar resources
     * @param {LearningResourcesApiLearningResourcesSimilarListRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    learningResourcesSimilarList(
      requestParameters: LearningResourcesApiLearningResourcesSimilarListRequest,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<Array<LearningResource>> {
      return localVarFp
        .learningResourcesSimilarList(
          requestParameters.id,
          requestParameters.certification,
          requestParameters.certification_type,
          requestParameters.course_feature,
          requestParameters.delivery,
          requestParameters.department,
          requestParameters.free,
          requestParameters.level,
          requestParameters.limit,
          requestParameters.offered_by,
          requestParameters.platform,
          requestParameters.professional,
          requestParameters.readable_id,
          requestParameters.resource_category,
          requestParameters.resource_type,
          requestParameters.sortby,
          requestParameters.topic,
          options,
        )
        .then((request) => request(axios, basePath))
    },
    /**
     * Set User List Relationships on a given Learning Resource.
     * @summary Set User List Relationships
     * @param {LearningResourcesApiLearningResourcesUserlistsPartialUpdateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    learningResourcesUserlistsPartialUpdate(
      requestParameters: LearningResourcesApiLearningResourcesUserlistsPartialUpdateRequest,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<Array<UserListRelationship>> {
      return localVarFp
        .learningResourcesUserlistsPartialUpdate(
          requestParameters.id,
          requestParameters.userlist_id,
          requestParameters.PatchedUserListRelationshipRequest,
          options,
        )
        .then((request) => request(axios, basePath))
    },
    /**
     * Fetch similar learning resources  Args: id (integer): The id of the learning resource  Returns: QuerySet of similar LearningResource for the resource matching the id parameter
     * @summary Get similar resources using vector embeddings
     * @param {LearningResourcesApiLearningResourcesVectorSimilarListRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    learningResourcesVectorSimilarList(
      requestParameters: LearningResourcesApiLearningResourcesVectorSimilarListRequest,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<Array<LearningResource>> {
      return localVarFp
        .learningResourcesVectorSimilarList(
          requestParameters.id,
          requestParameters.certification,
          requestParameters.certification_type,
          requestParameters.course_feature,
          requestParameters.delivery,
          requestParameters.department,
          requestParameters.free,
          requestParameters.level,
          requestParameters.limit,
          requestParameters.offered_by,
          requestParameters.platform,
          requestParameters.professional,
          requestParameters.readable_id,
          requestParameters.resource_category,
          requestParameters.resource_type,
          requestParameters.sortby,
          requestParameters.topic,
          options,
        )
        .then((request) => request(axios, basePath))
    },
  }
}

/**
 * Request parameters for learningResourcesContentfilesList operation in LearningResourcesApi.
 * @export
 * @interface LearningResourcesApiLearningResourcesContentfilesListRequest
 */
export interface LearningResourcesApiLearningResourcesContentfilesListRequest {
  /**
   * id of the parent learning resource
   * @type {number}
   * @memberof LearningResourcesApiLearningResourcesContentfilesList
   */
  readonly learning_resource_id: number

  /**
   * Multiple values may be separated by commas.
   * @type {Array<string>}
   * @memberof LearningResourcesApiLearningResourcesContentfilesList
   */
  readonly content_feature_type?: Array<string>

  /**
   * Number of results to return per page.
   * @type {number}
   * @memberof LearningResourcesApiLearningResourcesContentfilesList
   */
  readonly limit?: number

  /**
   * The organization that offers a learning resource the content file belongs to  * &#x60;mitx&#x60; - MITx * &#x60;ocw&#x60; - MIT OpenCourseWare * &#x60;bootcamps&#x60; - Bootcamps * &#x60;xpro&#x60; - MIT xPRO * &#x60;mitpe&#x60; - MIT Professional Education * &#x60;see&#x60; - MIT Sloan Executive Education
   * @type {Array<'bootcamps' | 'mitpe' | 'mitx' | 'ocw' | 'see' | 'xpro'>}
   * @memberof LearningResourcesApiLearningResourcesContentfilesList
   */
  readonly offered_by?: Array<LearningResourcesContentfilesListOfferedByEnum>

  /**
   * The initial index from which to return the results.
   * @type {number}
   * @memberof LearningResourcesApiLearningResourcesContentfilesList
   */
  readonly offset?: number

  /**
   * The platform on which learning resources the content file belongs to is offered  * &#x60;edx&#x60; - edX * &#x60;ocw&#x60; - MIT OpenCourseWare * &#x60;oll&#x60; - Open Learning Library * &#x60;mitxonline&#x60; - MITx Online * &#x60;bootcamps&#x60; - Bootcamps * &#x60;xpro&#x60; - MIT xPRO * &#x60;csail&#x60; - CSAIL * &#x60;mitpe&#x60; - MIT Professional Education * &#x60;see&#x60; - MIT Sloan Executive Education * &#x60;scc&#x60; - Schwarzman College of Computing * &#x60;ctl&#x60; - Center for Transportation &amp; Logistics * &#x60;whu&#x60; - WHU * &#x60;susskind&#x60; - Susskind * &#x60;globalalumni&#x60; - Global Alumni * &#x60;simplilearn&#x60; - Simplilearn * &#x60;emeritus&#x60; - Emeritus * &#x60;podcast&#x60; - Podcast * &#x60;youtube&#x60; - YouTube
   * @type {Array<'bootcamps' | 'csail' | 'ctl' | 'edx' | 'emeritus' | 'globalalumni' | 'mitpe' | 'mitxonline' | 'ocw' | 'oll' | 'podcast' | 'scc' | 'see' | 'simplilearn' | 'susskind' | 'whu' | 'xpro' | 'youtube'>}
   * @memberof LearningResourcesApiLearningResourcesContentfilesList
   */
  readonly platform?: Array<LearningResourcesContentfilesListPlatformEnum>

  /**
   * Multiple values may be separated by commas.
   * @type {Array<number>}
   * @memberof LearningResourcesApiLearningResourcesContentfilesList
   */
  readonly resource_id?: Array<number>

  /**
   * Multiple values may be separated by commas.
   * @type {Array<number>}
   * @memberof LearningResourcesApiLearningResourcesContentfilesList
   */
  readonly run_id?: Array<number>
}

/**
 * Request parameters for learningResourcesContentfilesRetrieve operation in LearningResourcesApi.
 * @export
 * @interface LearningResourcesApiLearningResourcesContentfilesRetrieveRequest
 */
export interface LearningResourcesApiLearningResourcesContentfilesRetrieveRequest {
  /**
   * A unique integer value identifying this contentfile.
   * @type {number}
   * @memberof LearningResourcesApiLearningResourcesContentfilesRetrieve
   */
  readonly id: number

  /**
   * id of the parent learning resource
   * @type {number}
   * @memberof LearningResourcesApiLearningResourcesContentfilesRetrieve
   */
  readonly learning_resource_id: number
}

/**
 * Request parameters for learningResourcesItemsList operation in LearningResourcesApi.
 * @export
 * @interface LearningResourcesApiLearningResourcesItemsListRequest
 */
export interface LearningResourcesApiLearningResourcesItemsListRequest {
  /**
   * id of the parent learning resource
   * @type {number}
   * @memberof LearningResourcesApiLearningResourcesItemsList
   */
  readonly learning_resource_id: number

  /**
   * Number of results to return per page.
   * @type {number}
   * @memberof LearningResourcesApiLearningResourcesItemsList
   */
  readonly limit?: number

  /**
   * The initial index from which to return the results.
   * @type {number}
   * @memberof LearningResourcesApiLearningResourcesItemsList
   */
  readonly offset?: number

  /**
   * Which field to use when ordering the results.
   * @type {string}
   * @memberof LearningResourcesApiLearningResourcesItemsList
   */
  readonly sortby?: string
}

/**
 * Request parameters for learningResourcesItemsRetrieve operation in LearningResourcesApi.
 * @export
 * @interface LearningResourcesApiLearningResourcesItemsRetrieveRequest
 */
export interface LearningResourcesApiLearningResourcesItemsRetrieveRequest {
  /**
   * A unique integer value identifying this learning resource relationship.
   * @type {number}
   * @memberof LearningResourcesApiLearningResourcesItemsRetrieve
   */
  readonly id: number

  /**
   * id of the parent learning resource
   * @type {number}
   * @memberof LearningResourcesApiLearningResourcesItemsRetrieve
   */
  readonly learning_resource_id: number
}

/**
 * Request parameters for learningResourcesLearningPathsPartialUpdate operation in LearningResourcesApi.
 * @export
 * @interface LearningResourcesApiLearningResourcesLearningPathsPartialUpdateRequest
 */
export interface LearningResourcesApiLearningResourcesLearningPathsPartialUpdateRequest {
  /**
   * id of the learning resource
   * @type {number}
   * @memberof LearningResourcesApiLearningResourcesLearningPathsPartialUpdate
   */
  readonly id: number

  /**
   * id of the parent learning path
   * @type {Array<number>}
   * @memberof LearningResourcesApiLearningResourcesLearningPathsPartialUpdate
   */
  readonly learning_path_id?: Array<number>

  /**
   *
   * @type {PatchedLearningResourceRelationshipRequest}
   * @memberof LearningResourcesApiLearningResourcesLearningPathsPartialUpdate
   */
  readonly PatchedLearningResourceRelationshipRequest?: PatchedLearningResourceRelationshipRequest
}

/**
 * Request parameters for learningResourcesList operation in LearningResourcesApi.
 * @export
 * @interface LearningResourcesApiLearningResourcesListRequest
 */
export interface LearningResourcesApiLearningResourcesListRequest {
  /**
   *
   * @type {boolean}
   * @memberof LearningResourcesApiLearningResourcesList
   */
  readonly certification?: boolean

  /**
   * The type of certification offered  * &#x60;micromasters&#x60; - MicroMasters Credential * &#x60;professional&#x60; - Professional Certificate * &#x60;completion&#x60; - Certificate of Completion * &#x60;none&#x60; - No Certificate
   * @type {Array<'completion' | 'micromasters' | 'none' | 'professional'>}
   * @memberof LearningResourcesApiLearningResourcesList
   */
  readonly certification_type?: Array<LearningResourcesListCertificationTypeEnum>

  /**
   * Multiple values may be separated by commas.
   * @type {Array<string>}
   * @memberof LearningResourcesApiLearningResourcesList
   */
  readonly course_feature?: Array<string>

  /**
   * The delivery of course/program resources  * &#x60;online&#x60; - Online * &#x60;hybrid&#x60; - Hybrid * &#x60;in_person&#x60; - In person * &#x60;offline&#x60; - Offline
   * @type {Array<Array<'online' | 'hybrid' | 'in_person' | 'offline'>>}
   * @memberof LearningResourcesApiLearningResourcesList
   */
  readonly delivery?: Array<Array<LearningResourcesListDeliveryEnum>>

  /**
   * The department that offers learning resources  * &#x60;1&#x60; - Civil and Environmental Engineering * &#x60;2&#x60; - Mechanical Engineering * &#x60;3&#x60; - Materials Science and Engineering * &#x60;4&#x60; - Architecture * &#x60;5&#x60; - Chemistry * &#x60;6&#x60; - Electrical Engineering and Computer Science * &#x60;7&#x60; - Biology * &#x60;8&#x60; - Physics * &#x60;9&#x60; - Brain and Cognitive Sciences * &#x60;10&#x60; - Chemical Engineering * &#x60;11&#x60; - Urban Studies and Planning * &#x60;12&#x60; - Earth, Atmospheric, and Planetary Sciences * &#x60;14&#x60; - Economics * &#x60;15&#x60; - Management * &#x60;16&#x60; - Aeronautics and Astronautics * &#x60;17&#x60; - Political Science * &#x60;18&#x60; - Mathematics * &#x60;20&#x60; - Biological Engineering * &#x60;21A&#x60; - Anthropology * &#x60;21G&#x60; - Global Languages * &#x60;21H&#x60; - History * &#x60;21L&#x60; - Literature * &#x60;21M&#x60; - Music and Theater Arts * &#x60;22&#x60; - Nuclear Science and Engineering * &#x60;24&#x60; - Linguistics and Philosophy * &#x60;CC&#x60; - Concourse * &#x60;CMS-W&#x60; - Comparative Media Studies/Writing * &#x60;EC&#x60; - Edgerton Center * &#x60;ES&#x60; - Experimental Study Group * &#x60;ESD&#x60; - Engineering Systems Division * &#x60;HST&#x60; - Medical Engineering and Science * &#x60;IDS&#x60; - Data, Systems, and Society * &#x60;MAS&#x60; - Media Arts and Sciences * &#x60;PE&#x60; - Athletics, Physical Education and Recreation * &#x60;SP&#x60; - Special Programs * &#x60;STS&#x60; - Science, Technology, and Society * &#x60;WGS&#x60; - Women\&#39;s and Gender Studies
   * @type {Array<'1' | '10' | '11' | '12' | '14' | '15' | '16' | '17' | '18' | '2' | '20' | '21A' | '21G' | '21H' | '21L' | '21M' | '22' | '24' | '3' | '4' | '5' | '6' | '7' | '8' | '9' | 'CC' | 'CMS-W' | 'EC' | 'ES' | 'ESD' | 'HST' | 'IDS' | 'MAS' | 'PE' | 'SP' | 'STS' | 'WGS'>}
   * @memberof LearningResourcesApiLearningResourcesList
   */
  readonly department?: Array<LearningResourcesListDepartmentEnum>

  /**
   * The course/program is offered for free
   * @type {boolean}
   * @memberof LearningResourcesApiLearningResourcesList
   */
  readonly free?: boolean

  /**
   * The academic level of the resources  * &#x60;undergraduate&#x60; - Undergraduate * &#x60;graduate&#x60; - Graduate * &#x60;high_school&#x60; - High School * &#x60;noncredit&#x60; - Non-Credit * &#x60;advanced&#x60; - Advanced * &#x60;intermediate&#x60; - Intermediate * &#x60;introductory&#x60; - Introductory
   * @type {Array<'advanced' | 'graduate' | 'high_school' | 'intermediate' | 'introductory' | 'noncredit' | 'undergraduate'>}
   * @memberof LearningResourcesApiLearningResourcesList
   */
  readonly level?: Array<LearningResourcesListLevelEnum>

  /**
   * Number of results to return per page.
   * @type {number}
   * @memberof LearningResourcesApiLearningResourcesList
   */
  readonly limit?: number

  /**
   * The organization that offers a learning resource  * &#x60;mitx&#x60; - MITx * &#x60;ocw&#x60; - MIT OpenCourseWare * &#x60;bootcamps&#x60; - Bootcamps * &#x60;xpro&#x60; - MIT xPRO * &#x60;mitpe&#x60; - MIT Professional Education * &#x60;see&#x60; - MIT Sloan Executive Education
   * @type {Array<'bootcamps' | 'mitpe' | 'mitx' | 'ocw' | 'see' | 'xpro'>}
   * @memberof LearningResourcesApiLearningResourcesList
   */
  readonly offered_by?: Array<LearningResourcesListOfferedByEnum>

  /**
   * The initial index from which to return the results.
   * @type {number}
   * @memberof LearningResourcesApiLearningResourcesList
   */
  readonly offset?: number

  /**
   * The platform on which learning resources are offered  * &#x60;edx&#x60; - edX * &#x60;ocw&#x60; - MIT OpenCourseWare * &#x60;oll&#x60; - Open Learning Library * &#x60;mitxonline&#x60; - MITx Online * &#x60;bootcamps&#x60; - Bootcamps * &#x60;xpro&#x60; - MIT xPRO * &#x60;csail&#x60; - CSAIL * &#x60;mitpe&#x60; - MIT Professional Education * &#x60;see&#x60; - MIT Sloan Executive Education * &#x60;scc&#x60; - Schwarzman College of Computing * &#x60;ctl&#x60; - Center for Transportation &amp; Logistics * &#x60;whu&#x60; - WHU * &#x60;susskind&#x60; - Susskind * &#x60;globalalumni&#x60; - Global Alumni * &#x60;simplilearn&#x60; - Simplilearn * &#x60;emeritus&#x60; - Emeritus * &#x60;podcast&#x60; - Podcast * &#x60;youtube&#x60; - YouTube
   * @type {Array<'bootcamps' | 'csail' | 'ctl' | 'edx' | 'emeritus' | 'globalalumni' | 'mitpe' | 'mitxonline' | 'ocw' | 'oll' | 'podcast' | 'scc' | 'see' | 'simplilearn' | 'susskind' | 'whu' | 'xpro' | 'youtube'>}
   * @memberof LearningResourcesApiLearningResourcesList
   */
  readonly platform?: Array<LearningResourcesListPlatformEnum>

  /**
   *
   * @type {boolean}
   * @memberof LearningResourcesApiLearningResourcesList
   */
  readonly professional?: boolean

  /**
   * Multiple values may be separated by commas.
   * @type {Array<string>}
   * @memberof LearningResourcesApiLearningResourcesList
   */
  readonly readable_id?: Array<string>

  /**
   * The resource category of the learning resources  * &#x60;course&#x60; - Course * &#x60;program&#x60; - Program * &#x60;learning_material&#x60; - Learning Material
   * @type {Array<'course' | 'learning_material' | 'program'>}
   * @memberof LearningResourcesApiLearningResourcesList
   */
  readonly resource_category?: Array<LearningResourcesListResourceCategoryEnum>

  /**
   * The type of learning resource  * &#x60;course&#x60; - Course * &#x60;program&#x60; - Program * &#x60;learning_path&#x60; - Learning Path * &#x60;podcast&#x60; - Podcast * &#x60;podcast_episode&#x60; - Podcast Episode * &#x60;video&#x60; - Video * &#x60;video_playlist&#x60; - Video Playlist
   * @type {Array<'course' | 'learning_path' | 'podcast' | 'podcast_episode' | 'program' | 'video' | 'video_playlist'>}
   * @memberof LearningResourcesApiLearningResourcesList
   */
  readonly resource_type?: Array<LearningResourcesListResourceTypeEnum>

  /**
   * Sort By  * &#x60;id&#x60; - Object ID ascending * &#x60;-id&#x60; - Object ID descending * &#x60;readable_id&#x60; - Readable ID ascending * &#x60;-readable_id&#x60; - Readable ID descending * &#x60;last_modified&#x60; - Last Modified Date ascending * &#x60;-last_modified&#x60; - Last Modified Date descending * &#x60;new&#x60; - Newest resources first * &#x60;start_date&#x60; - Start Date ascending * &#x60;-start_date&#x60; - Start Date descending * &#x60;mitcoursenumber&#x60; - MIT course number ascending * &#x60;-mitcoursenumber&#x60; - MIT course number descending * &#x60;views&#x60; - Popularity ascending * &#x60;-views&#x60; - Popularity descending * &#x60;upcoming&#x60; - Next start date ascending
   * @type {'-id' | '-last_modified' | '-mitcoursenumber' | '-readable_id' | '-start_date' | '-views' | 'id' | 'last_modified' | 'mitcoursenumber' | 'new' | 'readable_id' | 'start_date' | 'upcoming' | 'views'}
   * @memberof LearningResourcesApiLearningResourcesList
   */
  readonly sortby?: LearningResourcesListSortbyEnum

  /**
   * Multiple values may be separated by commas.
   * @type {Array<string>}
   * @memberof LearningResourcesApiLearningResourcesList
   */
  readonly topic?: Array<string>
}

/**
 * Request parameters for learningResourcesRetrieve operation in LearningResourcesApi.
 * @export
 * @interface LearningResourcesApiLearningResourcesRetrieveRequest
 */
export interface LearningResourcesApiLearningResourcesRetrieveRequest {
  /**
   * A unique integer value identifying this learning resource.
   * @type {number}
   * @memberof LearningResourcesApiLearningResourcesRetrieve
   */
  readonly id: number
}

/**
 * Request parameters for learningResourcesSimilarList operation in LearningResourcesApi.
 * @export
 * @interface LearningResourcesApiLearningResourcesSimilarListRequest
 */
export interface LearningResourcesApiLearningResourcesSimilarListRequest {
  /**
   *
   * @type {number}
   * @memberof LearningResourcesApiLearningResourcesSimilarList
   */
  readonly id: number

  /**
   *
   * @type {boolean}
   * @memberof LearningResourcesApiLearningResourcesSimilarList
   */
  readonly certification?: boolean

  /**
   * The type of certification offered  * &#x60;micromasters&#x60; - MicroMasters Credential * &#x60;professional&#x60; - Professional Certificate * &#x60;completion&#x60; - Certificate of Completion * &#x60;none&#x60; - No Certificate
   * @type {Array<'completion' | 'micromasters' | 'none' | 'professional'>}
   * @memberof LearningResourcesApiLearningResourcesSimilarList
   */
  readonly certification_type?: Array<LearningResourcesSimilarListCertificationTypeEnum>

  /**
   * Multiple values may be separated by commas.
   * @type {Array<string>}
   * @memberof LearningResourcesApiLearningResourcesSimilarList
   */
  readonly course_feature?: Array<string>

  /**
   * The delivery of course/program resources  * &#x60;online&#x60; - Online * &#x60;hybrid&#x60; - Hybrid * &#x60;in_person&#x60; - In person * &#x60;offline&#x60; - Offline
   * @type {Array<Array<'online' | 'hybrid' | 'in_person' | 'offline'>>}
   * @memberof LearningResourcesApiLearningResourcesSimilarList
   */
  readonly delivery?: Array<Array<LearningResourcesSimilarListDeliveryEnum>>

  /**
   * The department that offers learning resources  * &#x60;1&#x60; - Civil and Environmental Engineering * &#x60;2&#x60; - Mechanical Engineering * &#x60;3&#x60; - Materials Science and Engineering * &#x60;4&#x60; - Architecture * &#x60;5&#x60; - Chemistry * &#x60;6&#x60; - Electrical Engineering and Computer Science * &#x60;7&#x60; - Biology * &#x60;8&#x60; - Physics * &#x60;9&#x60; - Brain and Cognitive Sciences * &#x60;10&#x60; - Chemical Engineering * &#x60;11&#x60; - Urban Studies and Planning * &#x60;12&#x60; - Earth, Atmospheric, and Planetary Sciences * &#x60;14&#x60; - Economics * &#x60;15&#x60; - Management * &#x60;16&#x60; - Aeronautics and Astronautics * &#x60;17&#x60; - Political Science * &#x60;18&#x60; - Mathematics * &#x60;20&#x60; - Biological Engineering * &#x60;21A&#x60; - Anthropology * &#x60;21G&#x60; - Global Languages * &#x60;21H&#x60; - History * &#x60;21L&#x60; - Literature * &#x60;21M&#x60; - Music and Theater Arts * &#x60;22&#x60; - Nuclear Science and Engineering * &#x60;24&#x60; - Linguistics and Philosophy * &#x60;CC&#x60; - Concourse * &#x60;CMS-W&#x60; - Comparative Media Studies/Writing * &#x60;EC&#x60; - Edgerton Center * &#x60;ES&#x60; - Experimental Study Group * &#x60;ESD&#x60; - Engineering Systems Division * &#x60;HST&#x60; - Medical Engineering and Science * &#x60;IDS&#x60; - Data, Systems, and Society * &#x60;MAS&#x60; - Media Arts and Sciences * &#x60;PE&#x60; - Athletics, Physical Education and Recreation * &#x60;SP&#x60; - Special Programs * &#x60;STS&#x60; - Science, Technology, and Society * &#x60;WGS&#x60; - Women\&#39;s and Gender Studies
   * @type {Array<'1' | '10' | '11' | '12' | '14' | '15' | '16' | '17' | '18' | '2' | '20' | '21A' | '21G' | '21H' | '21L' | '21M' | '22' | '24' | '3' | '4' | '5' | '6' | '7' | '8' | '9' | 'CC' | 'CMS-W' | 'EC' | 'ES' | 'ESD' | 'HST' | 'IDS' | 'MAS' | 'PE' | 'SP' | 'STS' | 'WGS'>}
   * @memberof LearningResourcesApiLearningResourcesSimilarList
   */
  readonly department?: Array<LearningResourcesSimilarListDepartmentEnum>

  /**
   * The course/program is offered for free
   * @type {boolean}
   * @memberof LearningResourcesApiLearningResourcesSimilarList
   */
  readonly free?: boolean

  /**
   * The academic level of the resources  * &#x60;undergraduate&#x60; - Undergraduate * &#x60;graduate&#x60; - Graduate * &#x60;high_school&#x60; - High School * &#x60;noncredit&#x60; - Non-Credit * &#x60;advanced&#x60; - Advanced * &#x60;intermediate&#x60; - Intermediate * &#x60;introductory&#x60; - Introductory
   * @type {Array<'advanced' | 'graduate' | 'high_school' | 'intermediate' | 'introductory' | 'noncredit' | 'undergraduate'>}
   * @memberof LearningResourcesApiLearningResourcesSimilarList
   */
  readonly level?: Array<LearningResourcesSimilarListLevelEnum>

  /**
   *
   * @type {number}
   * @memberof LearningResourcesApiLearningResourcesSimilarList
   */
  readonly limit?: number

  /**
   * The organization that offers a learning resource  * &#x60;mitx&#x60; - MITx * &#x60;ocw&#x60; - MIT OpenCourseWare * &#x60;bootcamps&#x60; - Bootcamps * &#x60;xpro&#x60; - MIT xPRO * &#x60;mitpe&#x60; - MIT Professional Education * &#x60;see&#x60; - MIT Sloan Executive Education
   * @type {Array<'bootcamps' | 'mitpe' | 'mitx' | 'ocw' | 'see' | 'xpro'>}
   * @memberof LearningResourcesApiLearningResourcesSimilarList
   */
  readonly offered_by?: Array<LearningResourcesSimilarListOfferedByEnum>

  /**
   * The platform on which learning resources are offered  * &#x60;edx&#x60; - edX * &#x60;ocw&#x60; - MIT OpenCourseWare * &#x60;oll&#x60; - Open Learning Library * &#x60;mitxonline&#x60; - MITx Online * &#x60;bootcamps&#x60; - Bootcamps * &#x60;xpro&#x60; - MIT xPRO * &#x60;csail&#x60; - CSAIL * &#x60;mitpe&#x60; - MIT Professional Education * &#x60;see&#x60; - MIT Sloan Executive Education * &#x60;scc&#x60; - Schwarzman College of Computing * &#x60;ctl&#x60; - Center for Transportation &amp; Logistics * &#x60;whu&#x60; - WHU * &#x60;susskind&#x60; - Susskind * &#x60;globalalumni&#x60; - Global Alumni * &#x60;simplilearn&#x60; - Simplilearn * &#x60;emeritus&#x60; - Emeritus * &#x60;podcast&#x60; - Podcast * &#x60;youtube&#x60; - YouTube
   * @type {Array<'bootcamps' | 'csail' | 'ctl' | 'edx' | 'emeritus' | 'globalalumni' | 'mitpe' | 'mitxonline' | 'ocw' | 'oll' | 'podcast' | 'scc' | 'see' | 'simplilearn' | 'susskind' | 'whu' | 'xpro' | 'youtube'>}
   * @memberof LearningResourcesApiLearningResourcesSimilarList
   */
  readonly platform?: Array<LearningResourcesSimilarListPlatformEnum>

  /**
   *
   * @type {boolean}
   * @memberof LearningResourcesApiLearningResourcesSimilarList
   */
  readonly professional?: boolean

  /**
   * Multiple values may be separated by commas.
   * @type {Array<string>}
   * @memberof LearningResourcesApiLearningResourcesSimilarList
   */
  readonly readable_id?: Array<string>

  /**
   * The resource category of the learning resources  * &#x60;course&#x60; - Course * &#x60;program&#x60; - Program * &#x60;learning_material&#x60; - Learning Material
   * @type {Array<'course' | 'learning_material' | 'program'>}
   * @memberof LearningResourcesApiLearningResourcesSimilarList
   */
  readonly resource_category?: Array<LearningResourcesSimilarListResourceCategoryEnum>

  /**
   * The type of learning resource  * &#x60;course&#x60; - Course * &#x60;program&#x60; - Program * &#x60;learning_path&#x60; - Learning Path * &#x60;podcast&#x60; - Podcast * &#x60;podcast_episode&#x60; - Podcast Episode * &#x60;video&#x60; - Video * &#x60;video_playlist&#x60; - Video Playlist
   * @type {Array<'course' | 'learning_path' | 'podcast' | 'podcast_episode' | 'program' | 'video' | 'video_playlist'>}
   * @memberof LearningResourcesApiLearningResourcesSimilarList
   */
  readonly resource_type?: Array<LearningResourcesSimilarListResourceTypeEnum>

  /**
   * Sort By  * &#x60;id&#x60; - Object ID ascending * &#x60;-id&#x60; - Object ID descending * &#x60;readable_id&#x60; - Readable ID ascending * &#x60;-readable_id&#x60; - Readable ID descending * &#x60;last_modified&#x60; - Last Modified Date ascending * &#x60;-last_modified&#x60; - Last Modified Date descending * &#x60;new&#x60; - Newest resources first * &#x60;start_date&#x60; - Start Date ascending * &#x60;-start_date&#x60; - Start Date descending * &#x60;mitcoursenumber&#x60; - MIT course number ascending * &#x60;-mitcoursenumber&#x60; - MIT course number descending * &#x60;views&#x60; - Popularity ascending * &#x60;-views&#x60; - Popularity descending * &#x60;upcoming&#x60; - Next start date ascending
   * @type {'-id' | '-last_modified' | '-mitcoursenumber' | '-readable_id' | '-start_date' | '-views' | 'id' | 'last_modified' | 'mitcoursenumber' | 'new' | 'readable_id' | 'start_date' | 'upcoming' | 'views'}
   * @memberof LearningResourcesApiLearningResourcesSimilarList
   */
  readonly sortby?: LearningResourcesSimilarListSortbyEnum

  /**
   * Multiple values may be separated by commas.
   * @type {Array<string>}
   * @memberof LearningResourcesApiLearningResourcesSimilarList
   */
  readonly topic?: Array<string>
}

/**
 * Request parameters for learningResourcesUserlistsPartialUpdate operation in LearningResourcesApi.
 * @export
 * @interface LearningResourcesApiLearningResourcesUserlistsPartialUpdateRequest
 */
export interface LearningResourcesApiLearningResourcesUserlistsPartialUpdateRequest {
  /**
   * id of the learning resource
   * @type {number}
   * @memberof LearningResourcesApiLearningResourcesUserlistsPartialUpdate
   */
  readonly id: number

  /**
   * id of the parent user list
   * @type {Array<number>}
   * @memberof LearningResourcesApiLearningResourcesUserlistsPartialUpdate
   */
  readonly userlist_id?: Array<number>

  /**
   *
   * @type {PatchedUserListRelationshipRequest}
   * @memberof LearningResourcesApiLearningResourcesUserlistsPartialUpdate
   */
  readonly PatchedUserListRelationshipRequest?: PatchedUserListRelationshipRequest
}

/**
 * Request parameters for learningResourcesVectorSimilarList operation in LearningResourcesApi.
 * @export
 * @interface LearningResourcesApiLearningResourcesVectorSimilarListRequest
 */
export interface LearningResourcesApiLearningResourcesVectorSimilarListRequest {
  /**
   *
   * @type {number}
   * @memberof LearningResourcesApiLearningResourcesVectorSimilarList
   */
  readonly id: number

  /**
   *
   * @type {boolean}
   * @memberof LearningResourcesApiLearningResourcesVectorSimilarList
   */
  readonly certification?: boolean

  /**
   * The type of certification offered  * &#x60;micromasters&#x60; - MicroMasters Credential * &#x60;professional&#x60; - Professional Certificate * &#x60;completion&#x60; - Certificate of Completion * &#x60;none&#x60; - No Certificate
   * @type {Array<'completion' | 'micromasters' | 'none' | 'professional'>}
   * @memberof LearningResourcesApiLearningResourcesVectorSimilarList
   */
  readonly certification_type?: Array<LearningResourcesVectorSimilarListCertificationTypeEnum>

  /**
   * Multiple values may be separated by commas.
   * @type {Array<string>}
   * @memberof LearningResourcesApiLearningResourcesVectorSimilarList
   */
  readonly course_feature?: Array<string>

  /**
   * The delivery of course/program resources  * &#x60;online&#x60; - Online * &#x60;hybrid&#x60; - Hybrid * &#x60;in_person&#x60; - In person * &#x60;offline&#x60; - Offline
   * @type {Array<Array<'online' | 'hybrid' | 'in_person' | 'offline'>>}
   * @memberof LearningResourcesApiLearningResourcesVectorSimilarList
   */
  readonly delivery?: Array<
    Array<LearningResourcesVectorSimilarListDeliveryEnum>
  >

  /**
   * The department that offers learning resources  * &#x60;1&#x60; - Civil and Environmental Engineering * &#x60;2&#x60; - Mechanical Engineering * &#x60;3&#x60; - Materials Science and Engineering * &#x60;4&#x60; - Architecture * &#x60;5&#x60; - Chemistry * &#x60;6&#x60; - Electrical Engineering and Computer Science * &#x60;7&#x60; - Biology * &#x60;8&#x60; - Physics * &#x60;9&#x60; - Brain and Cognitive Sciences * &#x60;10&#x60; - Chemical Engineering * &#x60;11&#x60; - Urban Studies and Planning * &#x60;12&#x60; - Earth, Atmospheric, and Planetary Sciences * &#x60;14&#x60; - Economics * &#x60;15&#x60; - Management * &#x60;16&#x60; - Aeronautics and Astronautics * &#x60;17&#x60; - Political Science * &#x60;18&#x60; - Mathematics * &#x60;20&#x60; - Biological Engineering * &#x60;21A&#x60; - Anthropology * &#x60;21G&#x60; - Global Languages * &#x60;21H&#x60; - History * &#x60;21L&#x60; - Literature * &#x60;21M&#x60; - Music and Theater Arts * &#x60;22&#x60; - Nuclear Science and Engineering * &#x60;24&#x60; - Linguistics and Philosophy * &#x60;CC&#x60; - Concourse * &#x60;CMS-W&#x60; - Comparative Media Studies/Writing * &#x60;EC&#x60; - Edgerton Center * &#x60;ES&#x60; - Experimental Study Group * &#x60;ESD&#x60; - Engineering Systems Division * &#x60;HST&#x60; - Medical Engineering and Science * &#x60;IDS&#x60; - Data, Systems, and Society * &#x60;MAS&#x60; - Media Arts and Sciences * &#x60;PE&#x60; - Athletics, Physical Education and Recreation * &#x60;SP&#x60; - Special Programs * &#x60;STS&#x60; - Science, Technology, and Society * &#x60;WGS&#x60; - Women\&#39;s and Gender Studies
   * @type {Array<'1' | '10' | '11' | '12' | '14' | '15' | '16' | '17' | '18' | '2' | '20' | '21A' | '21G' | '21H' | '21L' | '21M' | '22' | '24' | '3' | '4' | '5' | '6' | '7' | '8' | '9' | 'CC' | 'CMS-W' | 'EC' | 'ES' | 'ESD' | 'HST' | 'IDS' | 'MAS' | 'PE' | 'SP' | 'STS' | 'WGS'>}
   * @memberof LearningResourcesApiLearningResourcesVectorSimilarList
   */
  readonly department?: Array<LearningResourcesVectorSimilarListDepartmentEnum>

  /**
   * The course/program is offered for free
   * @type {boolean}
   * @memberof LearningResourcesApiLearningResourcesVectorSimilarList
   */
  readonly free?: boolean

  /**
   * The academic level of the resources  * &#x60;undergraduate&#x60; - Undergraduate * &#x60;graduate&#x60; - Graduate * &#x60;high_school&#x60; - High School * &#x60;noncredit&#x60; - Non-Credit * &#x60;advanced&#x60; - Advanced * &#x60;intermediate&#x60; - Intermediate * &#x60;introductory&#x60; - Introductory
   * @type {Array<'advanced' | 'graduate' | 'high_school' | 'intermediate' | 'introductory' | 'noncredit' | 'undergraduate'>}
   * @memberof LearningResourcesApiLearningResourcesVectorSimilarList
   */
  readonly level?: Array<LearningResourcesVectorSimilarListLevelEnum>

  /**
   *
   * @type {number}
   * @memberof LearningResourcesApiLearningResourcesVectorSimilarList
   */
  readonly limit?: number

  /**
   * The organization that offers a learning resource  * &#x60;mitx&#x60; - MITx * &#x60;ocw&#x60; - MIT OpenCourseWare * &#x60;bootcamps&#x60; - Bootcamps * &#x60;xpro&#x60; - MIT xPRO * &#x60;mitpe&#x60; - MIT Professional Education * &#x60;see&#x60; - MIT Sloan Executive Education
   * @type {Array<'bootcamps' | 'mitpe' | 'mitx' | 'ocw' | 'see' | 'xpro'>}
   * @memberof LearningResourcesApiLearningResourcesVectorSimilarList
   */
  readonly offered_by?: Array<LearningResourcesVectorSimilarListOfferedByEnum>

  /**
   * The platform on which learning resources are offered  * &#x60;edx&#x60; - edX * &#x60;ocw&#x60; - MIT OpenCourseWare * &#x60;oll&#x60; - Open Learning Library * &#x60;mitxonline&#x60; - MITx Online * &#x60;bootcamps&#x60; - Bootcamps * &#x60;xpro&#x60; - MIT xPRO * &#x60;csail&#x60; - CSAIL * &#x60;mitpe&#x60; - MIT Professional Education * &#x60;see&#x60; - MIT Sloan Executive Education * &#x60;scc&#x60; - Schwarzman College of Computing * &#x60;ctl&#x60; - Center for Transportation &amp; Logistics * &#x60;whu&#x60; - WHU * &#x60;susskind&#x60; - Susskind * &#x60;globalalumni&#x60; - Global Alumni * &#x60;simplilearn&#x60; - Simplilearn * &#x60;emeritus&#x60; - Emeritus * &#x60;podcast&#x60; - Podcast * &#x60;youtube&#x60; - YouTube
   * @type {Array<'bootcamps' | 'csail' | 'ctl' | 'edx' | 'emeritus' | 'globalalumni' | 'mitpe' | 'mitxonline' | 'ocw' | 'oll' | 'podcast' | 'scc' | 'see' | 'simplilearn' | 'susskind' | 'whu' | 'xpro' | 'youtube'>}
   * @memberof LearningResourcesApiLearningResourcesVectorSimilarList
   */
  readonly platform?: Array<LearningResourcesVectorSimilarListPlatformEnum>

  /**
   *
   * @type {boolean}
   * @memberof LearningResourcesApiLearningResourcesVectorSimilarList
   */
  readonly professional?: boolean

  /**
   * Multiple values may be separated by commas.
   * @type {Array<string>}
   * @memberof LearningResourcesApiLearningResourcesVectorSimilarList
   */
  readonly readable_id?: Array<string>

  /**
   * The resource category of the learning resources  * &#x60;course&#x60; - Course * &#x60;program&#x60; - Program * &#x60;learning_material&#x60; - Learning Material
   * @type {Array<'course' | 'learning_material' | 'program'>}
   * @memberof LearningResourcesApiLearningResourcesVectorSimilarList
   */
  readonly resource_category?: Array<LearningResourcesVectorSimilarListResourceCategoryEnum>

  /**
   * The type of learning resource  * &#x60;course&#x60; - Course * &#x60;program&#x60; - Program * &#x60;learning_path&#x60; - Learning Path * &#x60;podcast&#x60; - Podcast * &#x60;podcast_episode&#x60; - Podcast Episode * &#x60;video&#x60; - Video * &#x60;video_playlist&#x60; - Video Playlist
   * @type {Array<'course' | 'learning_path' | 'podcast' | 'podcast_episode' | 'program' | 'video' | 'video_playlist'>}
   * @memberof LearningResourcesApiLearningResourcesVectorSimilarList
   */
  readonly resource_type?: Array<LearningResourcesVectorSimilarListResourceTypeEnum>

  /**
   * Sort By  * &#x60;id&#x60; - Object ID ascending * &#x60;-id&#x60; - Object ID descending * &#x60;readable_id&#x60; - Readable ID ascending * &#x60;-readable_id&#x60; - Readable ID descending * &#x60;last_modified&#x60; - Last Modified Date ascending * &#x60;-last_modified&#x60; - Last Modified Date descending * &#x60;new&#x60; - Newest resources first * &#x60;start_date&#x60; - Start Date ascending * &#x60;-start_date&#x60; - Start Date descending * &#x60;mitcoursenumber&#x60; - MIT course number ascending * &#x60;-mitcoursenumber&#x60; - MIT course number descending * &#x60;views&#x60; - Popularity ascending * &#x60;-views&#x60; - Popularity descending * &#x60;upcoming&#x60; - Next start date ascending
   * @type {'-id' | '-last_modified' | '-mitcoursenumber' | '-readable_id' | '-start_date' | '-views' | 'id' | 'last_modified' | 'mitcoursenumber' | 'new' | 'readable_id' | 'start_date' | 'upcoming' | 'views'}
   * @memberof LearningResourcesApiLearningResourcesVectorSimilarList
   */
  readonly sortby?: LearningResourcesVectorSimilarListSortbyEnum

  /**
   * Multiple values may be separated by commas.
   * @type {Array<string>}
   * @memberof LearningResourcesApiLearningResourcesVectorSimilarList
   */
  readonly topic?: Array<string>
}

/**
 * LearningResourcesApi - object-oriented interface
 * @export
 * @class LearningResourcesApi
 * @extends {BaseAPI}
 */
export class LearningResourcesApi extends BaseAPI {
  /**
   * Show content files for a learning resource
   * @summary Learning Resource Content File List
   * @param {LearningResourcesApiLearningResourcesContentfilesListRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof LearningResourcesApi
   */
  public learningResourcesContentfilesList(
    requestParameters: LearningResourcesApiLearningResourcesContentfilesListRequest,
    options?: RawAxiosRequestConfig,
  ) {
    return LearningResourcesApiFp(this.configuration)
      .learningResourcesContentfilesList(
        requestParameters.learning_resource_id,
        requestParameters.content_feature_type,
        requestParameters.limit,
        requestParameters.offered_by,
        requestParameters.offset,
        requestParameters.platform,
        requestParameters.resource_id,
        requestParameters.run_id,
        options,
      )
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   * Show content files for a learning resource
   * @summary Learning Resource Content File Retrieve
   * @param {LearningResourcesApiLearningResourcesContentfilesRetrieveRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof LearningResourcesApi
   */
  public learningResourcesContentfilesRetrieve(
    requestParameters: LearningResourcesApiLearningResourcesContentfilesRetrieveRequest,
    options?: RawAxiosRequestConfig,
  ) {
    return LearningResourcesApiFp(this.configuration)
      .learningResourcesContentfilesRetrieve(
        requestParameters.id,
        requestParameters.learning_resource_id,
        options,
      )
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   * Get a list of related learning resources for a learning resource.
   * @summary Nested Learning Resource List
   * @param {LearningResourcesApiLearningResourcesItemsListRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof LearningResourcesApi
   */
  public learningResourcesItemsList(
    requestParameters: LearningResourcesApiLearningResourcesItemsListRequest,
    options?: RawAxiosRequestConfig,
  ) {
    return LearningResourcesApiFp(this.configuration)
      .learningResourcesItemsList(
        requestParameters.learning_resource_id,
        requestParameters.limit,
        requestParameters.offset,
        requestParameters.sortby,
        options,
      )
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   * Get a singe related learning resource for a learning resource.
   * @summary Nested Learning Resource Retrieve
   * @param {LearningResourcesApiLearningResourcesItemsRetrieveRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof LearningResourcesApi
   */
  public learningResourcesItemsRetrieve(
    requestParameters: LearningResourcesApiLearningResourcesItemsRetrieveRequest,
    options?: RawAxiosRequestConfig,
  ) {
    return LearningResourcesApiFp(this.configuration)
      .learningResourcesItemsRetrieve(
        requestParameters.id,
        requestParameters.learning_resource_id,
        options,
      )
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   * Set Learning Path Relationships on a given Learning Resource.
   * @summary Set Learning Path Relationships
   * @param {LearningResourcesApiLearningResourcesLearningPathsPartialUpdateRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof LearningResourcesApi
   */
  public learningResourcesLearningPathsPartialUpdate(
    requestParameters: LearningResourcesApiLearningResourcesLearningPathsPartialUpdateRequest,
    options?: RawAxiosRequestConfig,
  ) {
    return LearningResourcesApiFp(this.configuration)
      .learningResourcesLearningPathsPartialUpdate(
        requestParameters.id,
        requestParameters.learning_path_id,
        requestParameters.PatchedLearningResourceRelationshipRequest,
        options,
      )
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   * Get a paginated list of learning resources.
   * @summary List
   * @param {LearningResourcesApiLearningResourcesListRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof LearningResourcesApi
   */
  public learningResourcesList(
    requestParameters: LearningResourcesApiLearningResourcesListRequest = {},
    options?: RawAxiosRequestConfig,
  ) {
    return LearningResourcesApiFp(this.configuration)
      .learningResourcesList(
        requestParameters.certification,
        requestParameters.certification_type,
        requestParameters.course_feature,
        requestParameters.delivery,
        requestParameters.department,
        requestParameters.free,
        requestParameters.level,
        requestParameters.limit,
        requestParameters.offered_by,
        requestParameters.offset,
        requestParameters.platform,
        requestParameters.professional,
        requestParameters.readable_id,
        requestParameters.resource_category,
        requestParameters.resource_type,
        requestParameters.sortby,
        requestParameters.topic,
        options,
      )
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   * Retrieve a single learning resource.
   * @summary Retrieve
   * @param {LearningResourcesApiLearningResourcesRetrieveRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof LearningResourcesApi
   */
  public learningResourcesRetrieve(
    requestParameters: LearningResourcesApiLearningResourcesRetrieveRequest,
    options?: RawAxiosRequestConfig,
  ) {
    return LearningResourcesApiFp(this.configuration)
      .learningResourcesRetrieve(requestParameters.id, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   * Fetch similar learning resources  Args: id (integer): The id of the learning resource  Returns: QuerySet of similar LearningResource for the resource matching the id parameter
   * @summary Get similar resources
   * @param {LearningResourcesApiLearningResourcesSimilarListRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof LearningResourcesApi
   */
  public learningResourcesSimilarList(
    requestParameters: LearningResourcesApiLearningResourcesSimilarListRequest,
    options?: RawAxiosRequestConfig,
  ) {
    return LearningResourcesApiFp(this.configuration)
      .learningResourcesSimilarList(
        requestParameters.id,
        requestParameters.certification,
        requestParameters.certification_type,
        requestParameters.course_feature,
        requestParameters.delivery,
        requestParameters.department,
        requestParameters.free,
        requestParameters.level,
        requestParameters.limit,
        requestParameters.offered_by,
        requestParameters.platform,
        requestParameters.professional,
        requestParameters.readable_id,
        requestParameters.resource_category,
        requestParameters.resource_type,
        requestParameters.sortby,
        requestParameters.topic,
        options,
      )
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   * Set User List Relationships on a given Learning Resource.
   * @summary Set User List Relationships
   * @param {LearningResourcesApiLearningResourcesUserlistsPartialUpdateRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof LearningResourcesApi
   */
  public learningResourcesUserlistsPartialUpdate(
    requestParameters: LearningResourcesApiLearningResourcesUserlistsPartialUpdateRequest,
    options?: RawAxiosRequestConfig,
  ) {
    return LearningResourcesApiFp(this.configuration)
      .learningResourcesUserlistsPartialUpdate(
        requestParameters.id,
        requestParameters.userlist_id,
        requestParameters.PatchedUserListRelationshipRequest,
        options,
      )
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   * Fetch similar learning resources  Args: id (integer): The id of the learning resource  Returns: QuerySet of similar LearningResource for the resource matching the id parameter
   * @summary Get similar resources using vector embeddings
   * @param {LearningResourcesApiLearningResourcesVectorSimilarListRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof LearningResourcesApi
   */
  public learningResourcesVectorSimilarList(
    requestParameters: LearningResourcesApiLearningResourcesVectorSimilarListRequest,
    options?: RawAxiosRequestConfig,
  ) {
    return LearningResourcesApiFp(this.configuration)
      .learningResourcesVectorSimilarList(
        requestParameters.id,
        requestParameters.certification,
        requestParameters.certification_type,
        requestParameters.course_feature,
        requestParameters.delivery,
        requestParameters.department,
        requestParameters.free,
        requestParameters.level,
        requestParameters.limit,
        requestParameters.offered_by,
        requestParameters.platform,
        requestParameters.professional,
        requestParameters.readable_id,
        requestParameters.resource_category,
        requestParameters.resource_type,
        requestParameters.sortby,
        requestParameters.topic,
        options,
      )
      .then((request) => request(this.axios, this.basePath))
  }
}

/**
 * @export
 */
export const LearningResourcesContentfilesListOfferedByEnum = {
  Bootcamps: "bootcamps",
  Mitpe: "mitpe",
  Mitx: "mitx",
  Ocw: "ocw",
  See: "see",
  Xpro: "xpro",
} as const
export type LearningResourcesContentfilesListOfferedByEnum =
  (typeof LearningResourcesContentfilesListOfferedByEnum)[keyof typeof LearningResourcesContentfilesListOfferedByEnum]
/**
 * @export
 */
export const LearningResourcesContentfilesListPlatformEnum = {
  Bootcamps: "bootcamps",
  Csail: "csail",
  Ctl: "ctl",
  Edx: "edx",
  Emeritus: "emeritus",
  Globalalumni: "globalalumni",
  Mitpe: "mitpe",
  Mitxonline: "mitxonline",
  Ocw: "ocw",
  Oll: "oll",
  Podcast: "podcast",
  Scc: "scc",
  See: "see",
  Simplilearn: "simplilearn",
  Susskind: "susskind",
  Whu: "whu",
  Xpro: "xpro",
  Youtube: "youtube",
} as const
export type LearningResourcesContentfilesListPlatformEnum =
  (typeof LearningResourcesContentfilesListPlatformEnum)[keyof typeof LearningResourcesContentfilesListPlatformEnum]
/**
 * @export
 */
export const LearningResourcesListCertificationTypeEnum = {
  Completion: "completion",
  Micromasters: "micromasters",
  None: "none",
  Professional: "professional",
} as const
export type LearningResourcesListCertificationTypeEnum =
  (typeof LearningResourcesListCertificationTypeEnum)[keyof typeof LearningResourcesListCertificationTypeEnum]
/**
 * @export
 */
export const LearningResourcesListDeliveryEnum = {
  Online: "online",
  Hybrid: "hybrid",
  InPerson: "in_person",
  Offline: "offline",
} as const
export type LearningResourcesListDeliveryEnum =
  (typeof LearningResourcesListDeliveryEnum)[keyof typeof LearningResourcesListDeliveryEnum]
/**
 * @export
 */
export const LearningResourcesListDepartmentEnum = {
  _1: "1",
  _10: "10",
  _11: "11",
  _12: "12",
  _14: "14",
  _15: "15",
  _16: "16",
  _17: "17",
  _18: "18",
  _2: "2",
  _20: "20",
  _21A: "21A",
  _21G: "21G",
  _21H: "21H",
  _21L: "21L",
  _21M: "21M",
  _22: "22",
  _24: "24",
  _3: "3",
  _4: "4",
  _5: "5",
  _6: "6",
  _7: "7",
  _8: "8",
  _9: "9",
  Cc: "CC",
  CmsW: "CMS-W",
  Ec: "EC",
  Es: "ES",
  Esd: "ESD",
  Hst: "HST",
  Ids: "IDS",
  Mas: "MAS",
  Pe: "PE",
  Sp: "SP",
  Sts: "STS",
  Wgs: "WGS",
} as const
export type LearningResourcesListDepartmentEnum =
  (typeof LearningResourcesListDepartmentEnum)[keyof typeof LearningResourcesListDepartmentEnum]
/**
 * @export
 */
export const LearningResourcesListLevelEnum = {
  Advanced: "advanced",
  Graduate: "graduate",
  HighSchool: "high_school",
  Intermediate: "intermediate",
  Introductory: "introductory",
  Noncredit: "noncredit",
  Undergraduate: "undergraduate",
} as const
export type LearningResourcesListLevelEnum =
  (typeof LearningResourcesListLevelEnum)[keyof typeof LearningResourcesListLevelEnum]
/**
 * @export
 */
export const LearningResourcesListOfferedByEnum = {
  Bootcamps: "bootcamps",
  Mitpe: "mitpe",
  Mitx: "mitx",
  Ocw: "ocw",
  See: "see",
  Xpro: "xpro",
} as const
export type LearningResourcesListOfferedByEnum =
  (typeof LearningResourcesListOfferedByEnum)[keyof typeof LearningResourcesListOfferedByEnum]
/**
 * @export
 */
export const LearningResourcesListPlatformEnum = {
  Bootcamps: "bootcamps",
  Csail: "csail",
  Ctl: "ctl",
  Edx: "edx",
  Emeritus: "emeritus",
  Globalalumni: "globalalumni",
  Mitpe: "mitpe",
  Mitxonline: "mitxonline",
  Ocw: "ocw",
  Oll: "oll",
  Podcast: "podcast",
  Scc: "scc",
  See: "see",
  Simplilearn: "simplilearn",
  Susskind: "susskind",
  Whu: "whu",
  Xpro: "xpro",
  Youtube: "youtube",
} as const
export type LearningResourcesListPlatformEnum =
  (typeof LearningResourcesListPlatformEnum)[keyof typeof LearningResourcesListPlatformEnum]
/**
 * @export
 */
export const LearningResourcesListResourceCategoryEnum = {
  Course: "course",
  LearningMaterial: "learning_material",
  Program: "program",
} as const
export type LearningResourcesListResourceCategoryEnum =
  (typeof LearningResourcesListResourceCategoryEnum)[keyof typeof LearningResourcesListResourceCategoryEnum]
/**
 * @export
 */
export const LearningResourcesListResourceTypeEnum = {
  Course: "course",
  LearningPath: "learning_path",
  Podcast: "podcast",
  PodcastEpisode: "podcast_episode",
  Program: "program",
  Video: "video",
  VideoPlaylist: "video_playlist",
} as const
export type LearningResourcesListResourceTypeEnum =
  (typeof LearningResourcesListResourceTypeEnum)[keyof typeof LearningResourcesListResourceTypeEnum]
/**
 * @export
 */
export const LearningResourcesListSortbyEnum = {
  Id: "-id",
  LastModified: "-last_modified",
  Mitcoursenumber: "-mitcoursenumber",
  ReadableId: "-readable_id",
  StartDate: "-start_date",
  Views: "-views",
  Id2: "id",
  LastModified2: "last_modified",
  Mitcoursenumber2: "mitcoursenumber",
  New: "new",
  ReadableId2: "readable_id",
  StartDate2: "start_date",
  Upcoming: "upcoming",
  Views2: "views",
} as const
export type LearningResourcesListSortbyEnum =
  (typeof LearningResourcesListSortbyEnum)[keyof typeof LearningResourcesListSortbyEnum]
/**
 * @export
 */
export const LearningResourcesSimilarListCertificationTypeEnum = {
  Completion: "completion",
  Micromasters: "micromasters",
  None: "none",
  Professional: "professional",
} as const
export type LearningResourcesSimilarListCertificationTypeEnum =
  (typeof LearningResourcesSimilarListCertificationTypeEnum)[keyof typeof LearningResourcesSimilarListCertificationTypeEnum]
/**
 * @export
 */
export const LearningResourcesSimilarListDeliveryEnum = {
  Online: "online",
  Hybrid: "hybrid",
  InPerson: "in_person",
  Offline: "offline",
} as const
export type LearningResourcesSimilarListDeliveryEnum =
  (typeof LearningResourcesSimilarListDeliveryEnum)[keyof typeof LearningResourcesSimilarListDeliveryEnum]
/**
 * @export
 */
export const LearningResourcesSimilarListDepartmentEnum = {
  _1: "1",
  _10: "10",
  _11: "11",
  _12: "12",
  _14: "14",
  _15: "15",
  _16: "16",
  _17: "17",
  _18: "18",
  _2: "2",
  _20: "20",
  _21A: "21A",
  _21G: "21G",
  _21H: "21H",
  _21L: "21L",
  _21M: "21M",
  _22: "22",
  _24: "24",
  _3: "3",
  _4: "4",
  _5: "5",
  _6: "6",
  _7: "7",
  _8: "8",
  _9: "9",
  Cc: "CC",
  CmsW: "CMS-W",
  Ec: "EC",
  Es: "ES",
  Esd: "ESD",
  Hst: "HST",
  Ids: "IDS",
  Mas: "MAS",
  Pe: "PE",
  Sp: "SP",
  Sts: "STS",
  Wgs: "WGS",
} as const
export type LearningResourcesSimilarListDepartmentEnum =
  (typeof LearningResourcesSimilarListDepartmentEnum)[keyof typeof LearningResourcesSimilarListDepartmentEnum]
/**
 * @export
 */
export const LearningResourcesSimilarListLevelEnum = {
  Advanced: "advanced",
  Graduate: "graduate",
  HighSchool: "high_school",
  Intermediate: "intermediate",
  Introductory: "introductory",
  Noncredit: "noncredit",
  Undergraduate: "undergraduate",
} as const
export type LearningResourcesSimilarListLevelEnum =
  (typeof LearningResourcesSimilarListLevelEnum)[keyof typeof LearningResourcesSimilarListLevelEnum]
/**
 * @export
 */
export const LearningResourcesSimilarListOfferedByEnum = {
  Bootcamps: "bootcamps",
  Mitpe: "mitpe",
  Mitx: "mitx",
  Ocw: "ocw",
  See: "see",
  Xpro: "xpro",
} as const
export type LearningResourcesSimilarListOfferedByEnum =
  (typeof LearningResourcesSimilarListOfferedByEnum)[keyof typeof LearningResourcesSimilarListOfferedByEnum]
/**
 * @export
 */
export const LearningResourcesSimilarListPlatformEnum = {
  Bootcamps: "bootcamps",
  Csail: "csail",
  Ctl: "ctl",
  Edx: "edx",
  Emeritus: "emeritus",
  Globalalumni: "globalalumni",
  Mitpe: "mitpe",
  Mitxonline: "mitxonline",
  Ocw: "ocw",
  Oll: "oll",
  Podcast: "podcast",
  Scc: "scc",
  See: "see",
  Simplilearn: "simplilearn",
  Susskind: "susskind",
  Whu: "whu",
  Xpro: "xpro",
  Youtube: "youtube",
} as const
export type LearningResourcesSimilarListPlatformEnum =
  (typeof LearningResourcesSimilarListPlatformEnum)[keyof typeof LearningResourcesSimilarListPlatformEnum]
/**
 * @export
 */
export const LearningResourcesSimilarListResourceCategoryEnum = {
  Course: "course",
  LearningMaterial: "learning_material",
  Program: "program",
} as const
export type LearningResourcesSimilarListResourceCategoryEnum =
  (typeof LearningResourcesSimilarListResourceCategoryEnum)[keyof typeof LearningResourcesSimilarListResourceCategoryEnum]
/**
 * @export
 */
export const LearningResourcesSimilarListResourceTypeEnum = {
  Course: "course",
  LearningPath: "learning_path",
  Podcast: "podcast",
  PodcastEpisode: "podcast_episode",
  Program: "program",
  Video: "video",
  VideoPlaylist: "video_playlist",
} as const
export type LearningResourcesSimilarListResourceTypeEnum =
  (typeof LearningResourcesSimilarListResourceTypeEnum)[keyof typeof LearningResourcesSimilarListResourceTypeEnum]
/**
 * @export
 */
export const LearningResourcesSimilarListSortbyEnum = {
  Id: "-id",
  LastModified: "-last_modified",
  Mitcoursenumber: "-mitcoursenumber",
  ReadableId: "-readable_id",
  StartDate: "-start_date",
  Views: "-views",
  Id2: "id",
  LastModified2: "last_modified",
  Mitcoursenumber2: "mitcoursenumber",
  New: "new",
  ReadableId2: "readable_id",
  StartDate2: "start_date",
  Upcoming: "upcoming",
  Views2: "views",
} as const
export type LearningResourcesSimilarListSortbyEnum =
  (typeof LearningResourcesSimilarListSortbyEnum)[keyof typeof LearningResourcesSimilarListSortbyEnum]
/**
 * @export
 */
export const LearningResourcesVectorSimilarListCertificationTypeEnum = {
  Completion: "completion",
  Micromasters: "micromasters",
  None: "none",
  Professional: "professional",
} as const
export type LearningResourcesVectorSimilarListCertificationTypeEnum =
  (typeof LearningResourcesVectorSimilarListCertificationTypeEnum)[keyof typeof LearningResourcesVectorSimilarListCertificationTypeEnum]
/**
 * @export
 */
export const LearningResourcesVectorSimilarListDeliveryEnum = {
  Online: "online",
  Hybrid: "hybrid",
  InPerson: "in_person",
  Offline: "offline",
} as const
export type LearningResourcesVectorSimilarListDeliveryEnum =
  (typeof LearningResourcesVectorSimilarListDeliveryEnum)[keyof typeof LearningResourcesVectorSimilarListDeliveryEnum]
/**
 * @export
 */
export const LearningResourcesVectorSimilarListDepartmentEnum = {
  _1: "1",
  _10: "10",
  _11: "11",
  _12: "12",
  _14: "14",
  _15: "15",
  _16: "16",
  _17: "17",
  _18: "18",
  _2: "2",
  _20: "20",
  _21A: "21A",
  _21G: "21G",
  _21H: "21H",
  _21L: "21L",
  _21M: "21M",
  _22: "22",
  _24: "24",
  _3: "3",
  _4: "4",
  _5: "5",
  _6: "6",
  _7: "7",
  _8: "8",
  _9: "9",
  Cc: "CC",
  CmsW: "CMS-W",
  Ec: "EC",
  Es: "ES",
  Esd: "ESD",
  Hst: "HST",
  Ids: "IDS",
  Mas: "MAS",
  Pe: "PE",
  Sp: "SP",
  Sts: "STS",
  Wgs: "WGS",
} as const
export type LearningResourcesVectorSimilarListDepartmentEnum =
  (typeof LearningResourcesVectorSimilarListDepartmentEnum)[keyof typeof LearningResourcesVectorSimilarListDepartmentEnum]
/**
 * @export
 */
export const LearningResourcesVectorSimilarListLevelEnum = {
  Advanced: "advanced",
  Graduate: "graduate",
  HighSchool: "high_school",
  Intermediate: "intermediate",
  Introductory: "introductory",
  Noncredit: "noncredit",
  Undergraduate: "undergraduate",
} as const
export type LearningResourcesVectorSimilarListLevelEnum =
  (typeof LearningResourcesVectorSimilarListLevelEnum)[keyof typeof LearningResourcesVectorSimilarListLevelEnum]
/**
 * @export
 */
export const LearningResourcesVectorSimilarListOfferedByEnum = {
  Bootcamps: "bootcamps",
  Mitpe: "mitpe",
  Mitx: "mitx",
  Ocw: "ocw",
  See: "see",
  Xpro: "xpro",
} as const
export type LearningResourcesVectorSimilarListOfferedByEnum =
  (typeof LearningResourcesVectorSimilarListOfferedByEnum)[keyof typeof LearningResourcesVectorSimilarListOfferedByEnum]
/**
 * @export
 */
export const LearningResourcesVectorSimilarListPlatformEnum = {
  Bootcamps: "bootcamps",
  Csail: "csail",
  Ctl: "ctl",
  Edx: "edx",
  Emeritus: "emeritus",
  Globalalumni: "globalalumni",
  Mitpe: "mitpe",
  Mitxonline: "mitxonline",
  Ocw: "ocw",
  Oll: "oll",
  Podcast: "podcast",
  Scc: "scc",
  See: "see",
  Simplilearn: "simplilearn",
  Susskind: "susskind",
  Whu: "whu",
  Xpro: "xpro",
  Youtube: "youtube",
} as const
export type LearningResourcesVectorSimilarListPlatformEnum =
  (typeof LearningResourcesVectorSimilarListPlatformEnum)[keyof typeof LearningResourcesVectorSimilarListPlatformEnum]
/**
 * @export
 */
export const LearningResourcesVectorSimilarListResourceCategoryEnum = {
  Course: "course",
  LearningMaterial: "learning_material",
  Program: "program",
} as const
export type LearningResourcesVectorSimilarListResourceCategoryEnum =
  (typeof LearningResourcesVectorSimilarListResourceCategoryEnum)[keyof typeof LearningResourcesVectorSimilarListResourceCategoryEnum]
/**
 * @export
 */
export const LearningResourcesVectorSimilarListResourceTypeEnum = {
  Course: "course",
  LearningPath: "learning_path",
  Podcast: "podcast",
  PodcastEpisode: "podcast_episode",
  Program: "program",
  Video: "video",
  VideoPlaylist: "video_playlist",
} as const
export type LearningResourcesVectorSimilarListResourceTypeEnum =
  (typeof LearningResourcesVectorSimilarListResourceTypeEnum)[keyof typeof LearningResourcesVectorSimilarListResourceTypeEnum]
/**
 * @export
 */
export const LearningResourcesVectorSimilarListSortbyEnum = {
  Id: "-id",
  LastModified: "-last_modified",
  Mitcoursenumber: "-mitcoursenumber",
  ReadableId: "-readable_id",
  StartDate: "-start_date",
  Views: "-views",
  Id2: "id",
  LastModified2: "last_modified",
  Mitcoursenumber2: "mitcoursenumber",
  New: "new",
  ReadableId2: "readable_id",
  StartDate2: "start_date",
  Upcoming: "upcoming",
  Views2: "views",
} as const
export type LearningResourcesVectorSimilarListSortbyEnum =
  (typeof LearningResourcesVectorSimilarListSortbyEnum)[keyof typeof LearningResourcesVectorSimilarListSortbyEnum]

/**
 * LearningResourcesSearchApi - axios parameter creator
 * @export
 */
export const LearningResourcesSearchApiAxiosParamCreator = function (
  configuration?: Configuration,
) {
  return {
    /**
     * Search for learning resources
     * @summary Search
     * @param {Array<LearningResourcesSearchRetrieveAggregationsEnum>} [aggregations] Show resource counts by category
     * @param {boolean | null} [certification] True if the learning resource offers a certificate
     * @param {Array<LearningResourcesSearchRetrieveCertificationTypeEnum>} [certification_type] The type of certificate               * &#x60;micromasters&#x60; - MicroMasters Credential * &#x60;professional&#x60; - Professional Certificate * &#x60;completion&#x60; - Certificate of Completion * &#x60;none&#x60; - No Certificate
     * @param {number | null} [content_file_score_weight] Score weight for content file data.  1 is the default. 0 means content files are ignored
     * @param {Array<string>} [course_feature] The course feature. Possible options are at api/v1/course_features/
     * @param {Array<LearningResourcesSearchRetrieveDeliveryEnum>} [delivery] The delivery options in which the learning resource is offered               * &#x60;online&#x60; - Online * &#x60;hybrid&#x60; - Hybrid * &#x60;in_person&#x60; - In person * &#x60;offline&#x60; - Offline
     * @param {Array<LearningResourcesSearchRetrieveDepartmentEnum>} [department] The department that offers the learning resource               * &#x60;1&#x60; - Civil and Environmental Engineering * &#x60;2&#x60; - Mechanical Engineering * &#x60;3&#x60; - Materials Science and Engineering * &#x60;4&#x60; - Architecture * &#x60;5&#x60; - Chemistry * &#x60;6&#x60; - Electrical Engineering and Computer Science * &#x60;7&#x60; - Biology * &#x60;8&#x60; - Physics * &#x60;9&#x60; - Brain and Cognitive Sciences * &#x60;10&#x60; - Chemical Engineering * &#x60;11&#x60; - Urban Studies and Planning * &#x60;12&#x60; - Earth, Atmospheric, and Planetary Sciences * &#x60;14&#x60; - Economics * &#x60;15&#x60; - Management * &#x60;16&#x60; - Aeronautics and Astronautics * &#x60;17&#x60; - Political Science * &#x60;18&#x60; - Mathematics * &#x60;20&#x60; - Biological Engineering * &#x60;21A&#x60; - Anthropology * &#x60;21G&#x60; - Global Languages * &#x60;21H&#x60; - History * &#x60;21L&#x60; - Literature * &#x60;21M&#x60; - Music and Theater Arts * &#x60;22&#x60; - Nuclear Science and Engineering * &#x60;24&#x60; - Linguistics and Philosophy * &#x60;CC&#x60; - Concourse * &#x60;CMS-W&#x60; - Comparative Media Studies/Writing * &#x60;EC&#x60; - Edgerton Center * &#x60;ES&#x60; - Experimental Study Group * &#x60;ESD&#x60; - Engineering Systems Division * &#x60;HST&#x60; - Medical Engineering and Science * &#x60;IDS&#x60; - Data, Systems, and Society * &#x60;MAS&#x60; - Media Arts and Sciences * &#x60;PE&#x60; - Athletics, Physical Education and Recreation * &#x60;SP&#x60; - Special Programs * &#x60;STS&#x60; - Science, Technology, and Society * &#x60;WGS&#x60; - Women\&#39;s and Gender Studies
     * @param {boolean | null} [dev_mode] If true return raw open search results with score explanations
     * @param {boolean | null} [free]
     * @param {Array<number>} [id] The id value for the learning resource
     * @param {Array<LearningResourcesSearchRetrieveLevelEnum>} [level]
     * @param {number} [limit] Number of results to return per page
     * @param {number | null} [max_incompleteness_penalty] Maximum score penalty for incomplete OCW courses in percent. An OCW course with completeness &#x3D; 0 will have this score penalty. Partially complete courses have a linear penalty proportional to the degree of incompleteness. Only affects results if there is a search term.
     * @param {number | null} [min_score] Minimum score value a text query result needs to have to be displayed
     * @param {Array<string>} [ocw_topic] The ocw topic name.
     * @param {Array<LearningResourcesSearchRetrieveOfferedByEnum>} [offered_by] The organization that offers the learning resource               * &#x60;mitx&#x60; - MITx * &#x60;ocw&#x60; - MIT OpenCourseWare * &#x60;bootcamps&#x60; - Bootcamps * &#x60;xpro&#x60; - MIT xPRO * &#x60;mitpe&#x60; - MIT Professional Education * &#x60;see&#x60; - MIT Sloan Executive Education
     * @param {number} [offset] The initial index from which to return the results
     * @param {Array<LearningResourcesSearchRetrievePlatformEnum>} [platform] The platform on which the learning resource is offered               * &#x60;edx&#x60; - edX * &#x60;ocw&#x60; - MIT OpenCourseWare * &#x60;oll&#x60; - Open Learning Library * &#x60;mitxonline&#x60; - MITx Online * &#x60;bootcamps&#x60; - Bootcamps * &#x60;xpro&#x60; - MIT xPRO * &#x60;csail&#x60; - CSAIL * &#x60;mitpe&#x60; - MIT Professional Education * &#x60;see&#x60; - MIT Sloan Executive Education * &#x60;scc&#x60; - Schwarzman College of Computing * &#x60;ctl&#x60; - Center for Transportation &amp; Logistics * &#x60;whu&#x60; - WHU * &#x60;susskind&#x60; - Susskind * &#x60;globalalumni&#x60; - Global Alumni * &#x60;simplilearn&#x60; - Simplilearn * &#x60;emeritus&#x60; - Emeritus * &#x60;podcast&#x60; - Podcast * &#x60;youtube&#x60; - YouTube
     * @param {boolean | null} [professional]
     * @param {string} [q] The search text
     * @param {Array<LearningResourcesSearchRetrieveResourceCategoryEnum>} [resource_category] The category of learning resource               * &#x60;course&#x60; - Course * &#x60;program&#x60; - Program * &#x60;learning_material&#x60; - Learning Material
     * @param {Array<LearningResourcesSearchRetrieveResourceTypeEnum>} [resource_type] The type of learning resource               * &#x60;course&#x60; - course * &#x60;program&#x60; - program * &#x60;learning_path&#x60; - learning path * &#x60;podcast&#x60; - podcast * &#x60;podcast_episode&#x60; - podcast episode * &#x60;video&#x60; - video * &#x60;video_playlist&#x60; - video playlist
     * @param {LearningResourcesSearchRetrieveSearchModeEnum} [search_mode] The open search search type for text queries               * &#x60;best_fields&#x60; - best_fields * &#x60;most_fields&#x60; - most_fields * &#x60;phrase&#x60; - phrase  * &#x60;best_fields&#x60; - best_fields * &#x60;most_fields&#x60; - most_fields * &#x60;phrase&#x60; - phrase
     * @param {number | null} [slop] Allowed distance for phrase search
     * @param {LearningResourcesSearchRetrieveSortbyEnum} [sortby] If the parameter starts with \&#39;-\&#39; the sort is in descending order  * &#x60;featured&#x60; - Featured * &#x60;id&#x60; - Object ID ascending * &#x60;-id&#x60; - Object ID descending * &#x60;readable_id&#x60; - Readable ID ascending * &#x60;-readable_id&#x60; - Readable ID descending * &#x60;last_modified&#x60; - Last Modified Date ascending * &#x60;-last_modified&#x60; - Last Modified Date descending * &#x60;new&#x60; - Newest resources first * &#x60;start_date&#x60; - Start Date ascending * &#x60;-start_date&#x60; - Start Date descending * &#x60;mitcoursenumber&#x60; - MIT course number ascending * &#x60;-mitcoursenumber&#x60; - MIT course number descending * &#x60;views&#x60; - Popularity ascending * &#x60;-views&#x60; - Popularity descending * &#x60;upcoming&#x60; - Next start date ascending
     * @param {Array<string>} [topic] The topic name. To see a list of options go to api/v1/topics/
     * @param {number | null} [yearly_decay_percent] Relevance score penalty percent per year for for resources without upcoming runs. Only affects results if there is a search term.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    learningResourcesSearchRetrieve: async (
      aggregations?: Array<LearningResourcesSearchRetrieveAggregationsEnum>,
      certification?: boolean | null,
      certification_type?: Array<LearningResourcesSearchRetrieveCertificationTypeEnum>,
      content_file_score_weight?: number | null,
      course_feature?: Array<string>,
      delivery?: Array<LearningResourcesSearchRetrieveDeliveryEnum>,
      department?: Array<LearningResourcesSearchRetrieveDepartmentEnum>,
      dev_mode?: boolean | null,
      free?: boolean | null,
      id?: Array<number>,
      level?: Array<LearningResourcesSearchRetrieveLevelEnum>,
      limit?: number,
      max_incompleteness_penalty?: number | null,
      min_score?: number | null,
      ocw_topic?: Array<string>,
      offered_by?: Array<LearningResourcesSearchRetrieveOfferedByEnum>,
      offset?: number,
      platform?: Array<LearningResourcesSearchRetrievePlatformEnum>,
      professional?: boolean | null,
      q?: string,
      resource_category?: Array<LearningResourcesSearchRetrieveResourceCategoryEnum>,
      resource_type?: Array<LearningResourcesSearchRetrieveResourceTypeEnum>,
      search_mode?: LearningResourcesSearchRetrieveSearchModeEnum,
      slop?: number | null,
      sortby?: LearningResourcesSearchRetrieveSortbyEnum,
      topic?: Array<string>,
      yearly_decay_percent?: number | null,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/api/v1/learning_resources_search/`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      if (aggregations) {
        localVarQueryParameter["aggregations"] = aggregations
      }

      if (certification !== undefined) {
        localVarQueryParameter["certification"] = certification
      }

      if (certification_type) {
        localVarQueryParameter["certification_type"] = certification_type
      }

      if (content_file_score_weight !== undefined) {
        localVarQueryParameter["content_file_score_weight"] =
          content_file_score_weight
      }

      if (course_feature) {
        localVarQueryParameter["course_feature"] = course_feature
      }

      if (delivery) {
        localVarQueryParameter["delivery"] = delivery
      }

      if (department) {
        localVarQueryParameter["department"] = department
      }

      if (dev_mode !== undefined) {
        localVarQueryParameter["dev_mode"] = dev_mode
      }

      if (free !== undefined) {
        localVarQueryParameter["free"] = free
      }

      if (id) {
        localVarQueryParameter["id"] = id
      }

      if (level) {
        localVarQueryParameter["level"] = level
      }

      if (limit !== undefined) {
        localVarQueryParameter["limit"] = limit
      }

      if (max_incompleteness_penalty !== undefined) {
        localVarQueryParameter["max_incompleteness_penalty"] =
          max_incompleteness_penalty
      }

      if (min_score !== undefined) {
        localVarQueryParameter["min_score"] = min_score
      }

      if (ocw_topic) {
        localVarQueryParameter["ocw_topic"] = ocw_topic
      }

      if (offered_by) {
        localVarQueryParameter["offered_by"] = offered_by
      }

      if (offset !== undefined) {
        localVarQueryParameter["offset"] = offset
      }

      if (platform) {
        localVarQueryParameter["platform"] = platform
      }

      if (professional !== undefined) {
        localVarQueryParameter["professional"] = professional
      }

      if (q !== undefined) {
        localVarQueryParameter["q"] = q
      }

      if (resource_category) {
        localVarQueryParameter["resource_category"] = resource_category
      }

      if (resource_type) {
        localVarQueryParameter["resource_type"] = resource_type
      }

      if (search_mode !== undefined) {
        localVarQueryParameter["search_mode"] = search_mode
      }

      if (slop !== undefined) {
        localVarQueryParameter["slop"] = slop
      }

      if (sortby !== undefined) {
        localVarQueryParameter["sortby"] = sortby
      }

      if (topic) {
        localVarQueryParameter["topic"] = topic
      }

      if (yearly_decay_percent !== undefined) {
        localVarQueryParameter["yearly_decay_percent"] = yearly_decay_percent
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
  }
}

/**
 * LearningResourcesSearchApi - functional programming interface
 * @export
 */
export const LearningResourcesSearchApiFp = function (
  configuration?: Configuration,
) {
  const localVarAxiosParamCreator =
    LearningResourcesSearchApiAxiosParamCreator(configuration)
  return {
    /**
     * Search for learning resources
     * @summary Search
     * @param {Array<LearningResourcesSearchRetrieveAggregationsEnum>} [aggregations] Show resource counts by category
     * @param {boolean | null} [certification] True if the learning resource offers a certificate
     * @param {Array<LearningResourcesSearchRetrieveCertificationTypeEnum>} [certification_type] The type of certificate               * &#x60;micromasters&#x60; - MicroMasters Credential * &#x60;professional&#x60; - Professional Certificate * &#x60;completion&#x60; - Certificate of Completion * &#x60;none&#x60; - No Certificate
     * @param {number | null} [content_file_score_weight] Score weight for content file data.  1 is the default. 0 means content files are ignored
     * @param {Array<string>} [course_feature] The course feature. Possible options are at api/v1/course_features/
     * @param {Array<LearningResourcesSearchRetrieveDeliveryEnum>} [delivery] The delivery options in which the learning resource is offered               * &#x60;online&#x60; - Online * &#x60;hybrid&#x60; - Hybrid * &#x60;in_person&#x60; - In person * &#x60;offline&#x60; - Offline
     * @param {Array<LearningResourcesSearchRetrieveDepartmentEnum>} [department] The department that offers the learning resource               * &#x60;1&#x60; - Civil and Environmental Engineering * &#x60;2&#x60; - Mechanical Engineering * &#x60;3&#x60; - Materials Science and Engineering * &#x60;4&#x60; - Architecture * &#x60;5&#x60; - Chemistry * &#x60;6&#x60; - Electrical Engineering and Computer Science * &#x60;7&#x60; - Biology * &#x60;8&#x60; - Physics * &#x60;9&#x60; - Brain and Cognitive Sciences * &#x60;10&#x60; - Chemical Engineering * &#x60;11&#x60; - Urban Studies and Planning * &#x60;12&#x60; - Earth, Atmospheric, and Planetary Sciences * &#x60;14&#x60; - Economics * &#x60;15&#x60; - Management * &#x60;16&#x60; - Aeronautics and Astronautics * &#x60;17&#x60; - Political Science * &#x60;18&#x60; - Mathematics * &#x60;20&#x60; - Biological Engineering * &#x60;21A&#x60; - Anthropology * &#x60;21G&#x60; - Global Languages * &#x60;21H&#x60; - History * &#x60;21L&#x60; - Literature * &#x60;21M&#x60; - Music and Theater Arts * &#x60;22&#x60; - Nuclear Science and Engineering * &#x60;24&#x60; - Linguistics and Philosophy * &#x60;CC&#x60; - Concourse * &#x60;CMS-W&#x60; - Comparative Media Studies/Writing * &#x60;EC&#x60; - Edgerton Center * &#x60;ES&#x60; - Experimental Study Group * &#x60;ESD&#x60; - Engineering Systems Division * &#x60;HST&#x60; - Medical Engineering and Science * &#x60;IDS&#x60; - Data, Systems, and Society * &#x60;MAS&#x60; - Media Arts and Sciences * &#x60;PE&#x60; - Athletics, Physical Education and Recreation * &#x60;SP&#x60; - Special Programs * &#x60;STS&#x60; - Science, Technology, and Society * &#x60;WGS&#x60; - Women\&#39;s and Gender Studies
     * @param {boolean | null} [dev_mode] If true return raw open search results with score explanations
     * @param {boolean | null} [free]
     * @param {Array<number>} [id] The id value for the learning resource
     * @param {Array<LearningResourcesSearchRetrieveLevelEnum>} [level]
     * @param {number} [limit] Number of results to return per page
     * @param {number | null} [max_incompleteness_penalty] Maximum score penalty for incomplete OCW courses in percent. An OCW course with completeness &#x3D; 0 will have this score penalty. Partially complete courses have a linear penalty proportional to the degree of incompleteness. Only affects results if there is a search term.
     * @param {number | null} [min_score] Minimum score value a text query result needs to have to be displayed
     * @param {Array<string>} [ocw_topic] The ocw topic name.
     * @param {Array<LearningResourcesSearchRetrieveOfferedByEnum>} [offered_by] The organization that offers the learning resource               * &#x60;mitx&#x60; - MITx * &#x60;ocw&#x60; - MIT OpenCourseWare * &#x60;bootcamps&#x60; - Bootcamps * &#x60;xpro&#x60; - MIT xPRO * &#x60;mitpe&#x60; - MIT Professional Education * &#x60;see&#x60; - MIT Sloan Executive Education
     * @param {number} [offset] The initial index from which to return the results
     * @param {Array<LearningResourcesSearchRetrievePlatformEnum>} [platform] The platform on which the learning resource is offered               * &#x60;edx&#x60; - edX * &#x60;ocw&#x60; - MIT OpenCourseWare * &#x60;oll&#x60; - Open Learning Library * &#x60;mitxonline&#x60; - MITx Online * &#x60;bootcamps&#x60; - Bootcamps * &#x60;xpro&#x60; - MIT xPRO * &#x60;csail&#x60; - CSAIL * &#x60;mitpe&#x60; - MIT Professional Education * &#x60;see&#x60; - MIT Sloan Executive Education * &#x60;scc&#x60; - Schwarzman College of Computing * &#x60;ctl&#x60; - Center for Transportation &amp; Logistics * &#x60;whu&#x60; - WHU * &#x60;susskind&#x60; - Susskind * &#x60;globalalumni&#x60; - Global Alumni * &#x60;simplilearn&#x60; - Simplilearn * &#x60;emeritus&#x60; - Emeritus * &#x60;podcast&#x60; - Podcast * &#x60;youtube&#x60; - YouTube
     * @param {boolean | null} [professional]
     * @param {string} [q] The search text
     * @param {Array<LearningResourcesSearchRetrieveResourceCategoryEnum>} [resource_category] The category of learning resource               * &#x60;course&#x60; - Course * &#x60;program&#x60; - Program * &#x60;learning_material&#x60; - Learning Material
     * @param {Array<LearningResourcesSearchRetrieveResourceTypeEnum>} [resource_type] The type of learning resource               * &#x60;course&#x60; - course * &#x60;program&#x60; - program * &#x60;learning_path&#x60; - learning path * &#x60;podcast&#x60; - podcast * &#x60;podcast_episode&#x60; - podcast episode * &#x60;video&#x60; - video * &#x60;video_playlist&#x60; - video playlist
     * @param {LearningResourcesSearchRetrieveSearchModeEnum} [search_mode] The open search search type for text queries               * &#x60;best_fields&#x60; - best_fields * &#x60;most_fields&#x60; - most_fields * &#x60;phrase&#x60; - phrase  * &#x60;best_fields&#x60; - best_fields * &#x60;most_fields&#x60; - most_fields * &#x60;phrase&#x60; - phrase
     * @param {number | null} [slop] Allowed distance for phrase search
     * @param {LearningResourcesSearchRetrieveSortbyEnum} [sortby] If the parameter starts with \&#39;-\&#39; the sort is in descending order  * &#x60;featured&#x60; - Featured * &#x60;id&#x60; - Object ID ascending * &#x60;-id&#x60; - Object ID descending * &#x60;readable_id&#x60; - Readable ID ascending * &#x60;-readable_id&#x60; - Readable ID descending * &#x60;last_modified&#x60; - Last Modified Date ascending * &#x60;-last_modified&#x60; - Last Modified Date descending * &#x60;new&#x60; - Newest resources first * &#x60;start_date&#x60; - Start Date ascending * &#x60;-start_date&#x60; - Start Date descending * &#x60;mitcoursenumber&#x60; - MIT course number ascending * &#x60;-mitcoursenumber&#x60; - MIT course number descending * &#x60;views&#x60; - Popularity ascending * &#x60;-views&#x60; - Popularity descending * &#x60;upcoming&#x60; - Next start date ascending
     * @param {Array<string>} [topic] The topic name. To see a list of options go to api/v1/topics/
     * @param {number | null} [yearly_decay_percent] Relevance score penalty percent per year for for resources without upcoming runs. Only affects results if there is a search term.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async learningResourcesSearchRetrieve(
      aggregations?: Array<LearningResourcesSearchRetrieveAggregationsEnum>,
      certification?: boolean | null,
      certification_type?: Array<LearningResourcesSearchRetrieveCertificationTypeEnum>,
      content_file_score_weight?: number | null,
      course_feature?: Array<string>,
      delivery?: Array<LearningResourcesSearchRetrieveDeliveryEnum>,
      department?: Array<LearningResourcesSearchRetrieveDepartmentEnum>,
      dev_mode?: boolean | null,
      free?: boolean | null,
      id?: Array<number>,
      level?: Array<LearningResourcesSearchRetrieveLevelEnum>,
      limit?: number,
      max_incompleteness_penalty?: number | null,
      min_score?: number | null,
      ocw_topic?: Array<string>,
      offered_by?: Array<LearningResourcesSearchRetrieveOfferedByEnum>,
      offset?: number,
      platform?: Array<LearningResourcesSearchRetrievePlatformEnum>,
      professional?: boolean | null,
      q?: string,
      resource_category?: Array<LearningResourcesSearchRetrieveResourceCategoryEnum>,
      resource_type?: Array<LearningResourcesSearchRetrieveResourceTypeEnum>,
      search_mode?: LearningResourcesSearchRetrieveSearchModeEnum,
      slop?: number | null,
      sortby?: LearningResourcesSearchRetrieveSortbyEnum,
      topic?: Array<string>,
      yearly_decay_percent?: number | null,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<LearningResourcesSearchResponse>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.learningResourcesSearchRetrieve(
          aggregations,
          certification,
          certification_type,
          content_file_score_weight,
          course_feature,
          delivery,
          department,
          dev_mode,
          free,
          id,
          level,
          limit,
          max_incompleteness_penalty,
          min_score,
          ocw_topic,
          offered_by,
          offset,
          platform,
          professional,
          q,
          resource_category,
          resource_type,
          search_mode,
          slop,
          sortby,
          topic,
          yearly_decay_percent,
          options,
        )
      const index = configuration?.serverIndex ?? 0
      const operationBasePath =
        operationServerMap[
          "LearningResourcesSearchApi.learningResourcesSearchRetrieve"
        ]?.[index]?.url
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, operationBasePath || basePath)
    },
  }
}

/**
 * LearningResourcesSearchApi - factory interface
 * @export
 */
export const LearningResourcesSearchApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = LearningResourcesSearchApiFp(configuration)
  return {
    /**
     * Search for learning resources
     * @summary Search
     * @param {LearningResourcesSearchApiLearningResourcesSearchRetrieveRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    learningResourcesSearchRetrieve(
      requestParameters: LearningResourcesSearchApiLearningResourcesSearchRetrieveRequest = {},
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<LearningResourcesSearchResponse> {
      return localVarFp
        .learningResourcesSearchRetrieve(
          requestParameters.aggregations,
          requestParameters.certification,
          requestParameters.certification_type,
          requestParameters.content_file_score_weight,
          requestParameters.course_feature,
          requestParameters.delivery,
          requestParameters.department,
          requestParameters.dev_mode,
          requestParameters.free,
          requestParameters.id,
          requestParameters.level,
          requestParameters.limit,
          requestParameters.max_incompleteness_penalty,
          requestParameters.min_score,
          requestParameters.ocw_topic,
          requestParameters.offered_by,
          requestParameters.offset,
          requestParameters.platform,
          requestParameters.professional,
          requestParameters.q,
          requestParameters.resource_category,
          requestParameters.resource_type,
          requestParameters.search_mode,
          requestParameters.slop,
          requestParameters.sortby,
          requestParameters.topic,
          requestParameters.yearly_decay_percent,
          options,
        )
        .then((request) => request(axios, basePath))
    },
  }
}

/**
 * Request parameters for learningResourcesSearchRetrieve operation in LearningResourcesSearchApi.
 * @export
 * @interface LearningResourcesSearchApiLearningResourcesSearchRetrieveRequest
 */
export interface LearningResourcesSearchApiLearningResourcesSearchRetrieveRequest {
  /**
   * Show resource counts by category
   * @type {Array<'resource_type' | 'certification' | 'certification_type' | 'offered_by' | 'platform' | 'topic' | 'department' | 'level' | 'course_feature' | 'professional' | 'free' | 'delivery' | 'resource_category'>}
   * @memberof LearningResourcesSearchApiLearningResourcesSearchRetrieve
   */
  readonly aggregations?: Array<LearningResourcesSearchRetrieveAggregationsEnum>

  /**
   * True if the learning resource offers a certificate
   * @type {boolean}
   * @memberof LearningResourcesSearchApiLearningResourcesSearchRetrieve
   */
  readonly certification?: boolean | null

  /**
   * The type of certificate               * &#x60;micromasters&#x60; - MicroMasters Credential * &#x60;professional&#x60; - Professional Certificate * &#x60;completion&#x60; - Certificate of Completion * &#x60;none&#x60; - No Certificate
   * @type {Array<'micromasters' | 'professional' | 'completion' | 'none'>}
   * @memberof LearningResourcesSearchApiLearningResourcesSearchRetrieve
   */
  readonly certification_type?: Array<LearningResourcesSearchRetrieveCertificationTypeEnum>

  /**
   * Score weight for content file data.  1 is the default. 0 means content files are ignored
   * @type {number}
   * @memberof LearningResourcesSearchApiLearningResourcesSearchRetrieve
   */
  readonly content_file_score_weight?: number | null

  /**
   * The course feature. Possible options are at api/v1/course_features/
   * @type {Array<string>}
   * @memberof LearningResourcesSearchApiLearningResourcesSearchRetrieve
   */
  readonly course_feature?: Array<string>

  /**
   * The delivery options in which the learning resource is offered               * &#x60;online&#x60; - Online * &#x60;hybrid&#x60; - Hybrid * &#x60;in_person&#x60; - In person * &#x60;offline&#x60; - Offline
   * @type {Array<'online' | 'hybrid' | 'in_person' | 'offline'>}
   * @memberof LearningResourcesSearchApiLearningResourcesSearchRetrieve
   */
  readonly delivery?: Array<LearningResourcesSearchRetrieveDeliveryEnum>

  /**
   * The department that offers the learning resource               * &#x60;1&#x60; - Civil and Environmental Engineering * &#x60;2&#x60; - Mechanical Engineering * &#x60;3&#x60; - Materials Science and Engineering * &#x60;4&#x60; - Architecture * &#x60;5&#x60; - Chemistry * &#x60;6&#x60; - Electrical Engineering and Computer Science * &#x60;7&#x60; - Biology * &#x60;8&#x60; - Physics * &#x60;9&#x60; - Brain and Cognitive Sciences * &#x60;10&#x60; - Chemical Engineering * &#x60;11&#x60; - Urban Studies and Planning * &#x60;12&#x60; - Earth, Atmospheric, and Planetary Sciences * &#x60;14&#x60; - Economics * &#x60;15&#x60; - Management * &#x60;16&#x60; - Aeronautics and Astronautics * &#x60;17&#x60; - Political Science * &#x60;18&#x60; - Mathematics * &#x60;20&#x60; - Biological Engineering * &#x60;21A&#x60; - Anthropology * &#x60;21G&#x60; - Global Languages * &#x60;21H&#x60; - History * &#x60;21L&#x60; - Literature * &#x60;21M&#x60; - Music and Theater Arts * &#x60;22&#x60; - Nuclear Science and Engineering * &#x60;24&#x60; - Linguistics and Philosophy * &#x60;CC&#x60; - Concourse * &#x60;CMS-W&#x60; - Comparative Media Studies/Writing * &#x60;EC&#x60; - Edgerton Center * &#x60;ES&#x60; - Experimental Study Group * &#x60;ESD&#x60; - Engineering Systems Division * &#x60;HST&#x60; - Medical Engineering and Science * &#x60;IDS&#x60; - Data, Systems, and Society * &#x60;MAS&#x60; - Media Arts and Sciences * &#x60;PE&#x60; - Athletics, Physical Education and Recreation * &#x60;SP&#x60; - Special Programs * &#x60;STS&#x60; - Science, Technology, and Society * &#x60;WGS&#x60; - Women\&#39;s and Gender Studies
   * @type {Array<'1' | '2' | '3' | '4' | '5' | '6' | '7' | '8' | '9' | '10' | '11' | '12' | '14' | '15' | '16' | '17' | '18' | '20' | '21A' | '21G' | '21H' | '21L' | '21M' | '22' | '24' | 'CC' | 'CMS-W' | 'EC' | 'ES' | 'ESD' | 'HST' | 'IDS' | 'MAS' | 'PE' | 'SP' | 'STS' | 'WGS'>}
   * @memberof LearningResourcesSearchApiLearningResourcesSearchRetrieve
   */
  readonly department?: Array<LearningResourcesSearchRetrieveDepartmentEnum>

  /**
   * If true return raw open search results with score explanations
   * @type {boolean}
   * @memberof LearningResourcesSearchApiLearningResourcesSearchRetrieve
   */
  readonly dev_mode?: boolean | null

  /**
   *
   * @type {boolean}
   * @memberof LearningResourcesSearchApiLearningResourcesSearchRetrieve
   */
  readonly free?: boolean | null

  /**
   * The id value for the learning resource
   * @type {Array<number>}
   * @memberof LearningResourcesSearchApiLearningResourcesSearchRetrieve
   */
  readonly id?: Array<number>

  /**
   *
   * @type {Array<'undergraduate' | 'graduate' | 'high_school' | 'noncredit' | 'advanced' | 'intermediate' | 'introductory'>}
   * @memberof LearningResourcesSearchApiLearningResourcesSearchRetrieve
   */
  readonly level?: Array<LearningResourcesSearchRetrieveLevelEnum>

  /**
   * Number of results to return per page
   * @type {number}
   * @memberof LearningResourcesSearchApiLearningResourcesSearchRetrieve
   */
  readonly limit?: number

  /**
   * Maximum score penalty for incomplete OCW courses in percent. An OCW course with completeness &#x3D; 0 will have this score penalty. Partially complete courses have a linear penalty proportional to the degree of incompleteness. Only affects results if there is a search term.
   * @type {number}
   * @memberof LearningResourcesSearchApiLearningResourcesSearchRetrieve
   */
  readonly max_incompleteness_penalty?: number | null

  /**
   * Minimum score value a text query result needs to have to be displayed
   * @type {number}
   * @memberof LearningResourcesSearchApiLearningResourcesSearchRetrieve
   */
  readonly min_score?: number | null

  /**
   * The ocw topic name.
   * @type {Array<string>}
   * @memberof LearningResourcesSearchApiLearningResourcesSearchRetrieve
   */
  readonly ocw_topic?: Array<string>

  /**
   * The organization that offers the learning resource               * &#x60;mitx&#x60; - MITx * &#x60;ocw&#x60; - MIT OpenCourseWare * &#x60;bootcamps&#x60; - Bootcamps * &#x60;xpro&#x60; - MIT xPRO * &#x60;mitpe&#x60; - MIT Professional Education * &#x60;see&#x60; - MIT Sloan Executive Education
   * @type {Array<'mitx' | 'ocw' | 'bootcamps' | 'xpro' | 'mitpe' | 'see'>}
   * @memberof LearningResourcesSearchApiLearningResourcesSearchRetrieve
   */
  readonly offered_by?: Array<LearningResourcesSearchRetrieveOfferedByEnum>

  /**
   * The initial index from which to return the results
   * @type {number}
   * @memberof LearningResourcesSearchApiLearningResourcesSearchRetrieve
   */
  readonly offset?: number

  /**
   * The platform on which the learning resource is offered               * &#x60;edx&#x60; - edX * &#x60;ocw&#x60; - MIT OpenCourseWare * &#x60;oll&#x60; - Open Learning Library * &#x60;mitxonline&#x60; - MITx Online * &#x60;bootcamps&#x60; - Bootcamps * &#x60;xpro&#x60; - MIT xPRO * &#x60;csail&#x60; - CSAIL * &#x60;mitpe&#x60; - MIT Professional Education * &#x60;see&#x60; - MIT Sloan Executive Education * &#x60;scc&#x60; - Schwarzman College of Computing * &#x60;ctl&#x60; - Center for Transportation &amp; Logistics * &#x60;whu&#x60; - WHU * &#x60;susskind&#x60; - Susskind * &#x60;globalalumni&#x60; - Global Alumni * &#x60;simplilearn&#x60; - Simplilearn * &#x60;emeritus&#x60; - Emeritus * &#x60;podcast&#x60; - Podcast * &#x60;youtube&#x60; - YouTube
   * @type {Array<'edx' | 'ocw' | 'oll' | 'mitxonline' | 'bootcamps' | 'xpro' | 'csail' | 'mitpe' | 'see' | 'scc' | 'ctl' | 'whu' | 'susskind' | 'globalalumni' | 'simplilearn' | 'emeritus' | 'podcast' | 'youtube'>}
   * @memberof LearningResourcesSearchApiLearningResourcesSearchRetrieve
   */
  readonly platform?: Array<LearningResourcesSearchRetrievePlatformEnum>

  /**
   *
   * @type {boolean}
   * @memberof LearningResourcesSearchApiLearningResourcesSearchRetrieve
   */
  readonly professional?: boolean | null

  /**
   * The search text
   * @type {string}
   * @memberof LearningResourcesSearchApiLearningResourcesSearchRetrieve
   */
  readonly q?: string

  /**
   * The category of learning resource               * &#x60;course&#x60; - Course * &#x60;program&#x60; - Program * &#x60;learning_material&#x60; - Learning Material
   * @type {Array<'course' | 'program' | 'learning_material'>}
   * @memberof LearningResourcesSearchApiLearningResourcesSearchRetrieve
   */
  readonly resource_category?: Array<LearningResourcesSearchRetrieveResourceCategoryEnum>

  /**
   * The type of learning resource               * &#x60;course&#x60; - course * &#x60;program&#x60; - program * &#x60;learning_path&#x60; - learning path * &#x60;podcast&#x60; - podcast * &#x60;podcast_episode&#x60; - podcast episode * &#x60;video&#x60; - video * &#x60;video_playlist&#x60; - video playlist
   * @type {Array<'course' | 'program' | 'learning_path' | 'podcast' | 'podcast_episode' | 'video' | 'video_playlist'>}
   * @memberof LearningResourcesSearchApiLearningResourcesSearchRetrieve
   */
  readonly resource_type?: Array<LearningResourcesSearchRetrieveResourceTypeEnum>

  /**
   * The open search search type for text queries               * &#x60;best_fields&#x60; - best_fields * &#x60;most_fields&#x60; - most_fields * &#x60;phrase&#x60; - phrase  * &#x60;best_fields&#x60; - best_fields * &#x60;most_fields&#x60; - most_fields * &#x60;phrase&#x60; - phrase
   * @type {'best_fields' | 'most_fields' | 'phrase'}
   * @memberof LearningResourcesSearchApiLearningResourcesSearchRetrieve
   */
  readonly search_mode?: LearningResourcesSearchRetrieveSearchModeEnum

  /**
   * Allowed distance for phrase search
   * @type {number}
   * @memberof LearningResourcesSearchApiLearningResourcesSearchRetrieve
   */
  readonly slop?: number | null

  /**
   * If the parameter starts with \&#39;-\&#39; the sort is in descending order  * &#x60;featured&#x60; - Featured * &#x60;id&#x60; - Object ID ascending * &#x60;-id&#x60; - Object ID descending * &#x60;readable_id&#x60; - Readable ID ascending * &#x60;-readable_id&#x60; - Readable ID descending * &#x60;last_modified&#x60; - Last Modified Date ascending * &#x60;-last_modified&#x60; - Last Modified Date descending * &#x60;new&#x60; - Newest resources first * &#x60;start_date&#x60; - Start Date ascending * &#x60;-start_date&#x60; - Start Date descending * &#x60;mitcoursenumber&#x60; - MIT course number ascending * &#x60;-mitcoursenumber&#x60; - MIT course number descending * &#x60;views&#x60; - Popularity ascending * &#x60;-views&#x60; - Popularity descending * &#x60;upcoming&#x60; - Next start date ascending
   * @type {'featured' | 'id' | '-id' | 'readable_id' | '-readable_id' | 'last_modified' | '-last_modified' | 'new' | 'start_date' | '-start_date' | 'mitcoursenumber' | '-mitcoursenumber' | 'views' | '-views' | 'upcoming'}
   * @memberof LearningResourcesSearchApiLearningResourcesSearchRetrieve
   */
  readonly sortby?: LearningResourcesSearchRetrieveSortbyEnum

  /**
   * The topic name. To see a list of options go to api/v1/topics/
   * @type {Array<string>}
   * @memberof LearningResourcesSearchApiLearningResourcesSearchRetrieve
   */
  readonly topic?: Array<string>

  /**
   * Relevance score penalty percent per year for for resources without upcoming runs. Only affects results if there is a search term.
   * @type {number}
   * @memberof LearningResourcesSearchApiLearningResourcesSearchRetrieve
   */
  readonly yearly_decay_percent?: number | null
}

/**
 * LearningResourcesSearchApi - object-oriented interface
 * @export
 * @class LearningResourcesSearchApi
 * @extends {BaseAPI}
 */
export class LearningResourcesSearchApi extends BaseAPI {
  /**
   * Search for learning resources
   * @summary Search
   * @param {LearningResourcesSearchApiLearningResourcesSearchRetrieveRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof LearningResourcesSearchApi
   */
  public learningResourcesSearchRetrieve(
    requestParameters: LearningResourcesSearchApiLearningResourcesSearchRetrieveRequest = {},
    options?: RawAxiosRequestConfig,
  ) {
    return LearningResourcesSearchApiFp(this.configuration)
      .learningResourcesSearchRetrieve(
        requestParameters.aggregations,
        requestParameters.certification,
        requestParameters.certification_type,
        requestParameters.content_file_score_weight,
        requestParameters.course_feature,
        requestParameters.delivery,
        requestParameters.department,
        requestParameters.dev_mode,
        requestParameters.free,
        requestParameters.id,
        requestParameters.level,
        requestParameters.limit,
        requestParameters.max_incompleteness_penalty,
        requestParameters.min_score,
        requestParameters.ocw_topic,
        requestParameters.offered_by,
        requestParameters.offset,
        requestParameters.platform,
        requestParameters.professional,
        requestParameters.q,
        requestParameters.resource_category,
        requestParameters.resource_type,
        requestParameters.search_mode,
        requestParameters.slop,
        requestParameters.sortby,
        requestParameters.topic,
        requestParameters.yearly_decay_percent,
        options,
      )
      .then((request) => request(this.axios, this.basePath))
  }
}

/**
 * @export
 */
export const LearningResourcesSearchRetrieveAggregationsEnum = {
  ResourceType: "resource_type",
  Certification: "certification",
  CertificationType: "certification_type",
  OfferedBy: "offered_by",
  Platform: "platform",
  Topic: "topic",
  Department: "department",
  Level: "level",
  CourseFeature: "course_feature",
  Professional: "professional",
  Free: "free",
  Delivery: "delivery",
  ResourceCategory: "resource_category",
} as const
export type LearningResourcesSearchRetrieveAggregationsEnum =
  (typeof LearningResourcesSearchRetrieveAggregationsEnum)[keyof typeof LearningResourcesSearchRetrieveAggregationsEnum]
/**
 * @export
 */
export const LearningResourcesSearchRetrieveCertificationTypeEnum = {
  Micromasters: "micromasters",
  Professional: "professional",
  Completion: "completion",
  None: "none",
} as const
export type LearningResourcesSearchRetrieveCertificationTypeEnum =
  (typeof LearningResourcesSearchRetrieveCertificationTypeEnum)[keyof typeof LearningResourcesSearchRetrieveCertificationTypeEnum]
/**
 * @export
 */
export const LearningResourcesSearchRetrieveDeliveryEnum = {
  Online: "online",
  Hybrid: "hybrid",
  InPerson: "in_person",
  Offline: "offline",
} as const
export type LearningResourcesSearchRetrieveDeliveryEnum =
  (typeof LearningResourcesSearchRetrieveDeliveryEnum)[keyof typeof LearningResourcesSearchRetrieveDeliveryEnum]
/**
 * @export
 */
export const LearningResourcesSearchRetrieveDepartmentEnum = {
  _1: "1",
  _2: "2",
  _3: "3",
  _4: "4",
  _5: "5",
  _6: "6",
  _7: "7",
  _8: "8",
  _9: "9",
  _10: "10",
  _11: "11",
  _12: "12",
  _14: "14",
  _15: "15",
  _16: "16",
  _17: "17",
  _18: "18",
  _20: "20",
  _21A: "21A",
  _21G: "21G",
  _21H: "21H",
  _21L: "21L",
  _21M: "21M",
  _22: "22",
  _24: "24",
  Cc: "CC",
  CmsW: "CMS-W",
  Ec: "EC",
  Es: "ES",
  Esd: "ESD",
  Hst: "HST",
  Ids: "IDS",
  Mas: "MAS",
  Pe: "PE",
  Sp: "SP",
  Sts: "STS",
  Wgs: "WGS",
} as const
export type LearningResourcesSearchRetrieveDepartmentEnum =
  (typeof LearningResourcesSearchRetrieveDepartmentEnum)[keyof typeof LearningResourcesSearchRetrieveDepartmentEnum]
/**
 * @export
 */
export const LearningResourcesSearchRetrieveLevelEnum = {
  Undergraduate: "undergraduate",
  Graduate: "graduate",
  HighSchool: "high_school",
  Noncredit: "noncredit",
  Advanced: "advanced",
  Intermediate: "intermediate",
  Introductory: "introductory",
} as const
export type LearningResourcesSearchRetrieveLevelEnum =
  (typeof LearningResourcesSearchRetrieveLevelEnum)[keyof typeof LearningResourcesSearchRetrieveLevelEnum]
/**
 * @export
 */
export const LearningResourcesSearchRetrieveOfferedByEnum = {
  Mitx: "mitx",
  Ocw: "ocw",
  Bootcamps: "bootcamps",
  Xpro: "xpro",
  Mitpe: "mitpe",
  See: "see",
} as const
export type LearningResourcesSearchRetrieveOfferedByEnum =
  (typeof LearningResourcesSearchRetrieveOfferedByEnum)[keyof typeof LearningResourcesSearchRetrieveOfferedByEnum]
/**
 * @export
 */
export const LearningResourcesSearchRetrievePlatformEnum = {
  Edx: "edx",
  Ocw: "ocw",
  Oll: "oll",
  Mitxonline: "mitxonline",
  Bootcamps: "bootcamps",
  Xpro: "xpro",
  Csail: "csail",
  Mitpe: "mitpe",
  See: "see",
  Scc: "scc",
  Ctl: "ctl",
  Whu: "whu",
  Susskind: "susskind",
  Globalalumni: "globalalumni",
  Simplilearn: "simplilearn",
  Emeritus: "emeritus",
  Podcast: "podcast",
  Youtube: "youtube",
} as const
export type LearningResourcesSearchRetrievePlatformEnum =
  (typeof LearningResourcesSearchRetrievePlatformEnum)[keyof typeof LearningResourcesSearchRetrievePlatformEnum]
/**
 * @export
 */
export const LearningResourcesSearchRetrieveResourceCategoryEnum = {
  Course: "course",
  Program: "program",
  LearningMaterial: "learning_material",
} as const
export type LearningResourcesSearchRetrieveResourceCategoryEnum =
  (typeof LearningResourcesSearchRetrieveResourceCategoryEnum)[keyof typeof LearningResourcesSearchRetrieveResourceCategoryEnum]
/**
 * @export
 */
export const LearningResourcesSearchRetrieveResourceTypeEnum = {
  Course: "course",
  Program: "program",
  LearningPath: "learning_path",
  Podcast: "podcast",
  PodcastEpisode: "podcast_episode",
  Video: "video",
  VideoPlaylist: "video_playlist",
} as const
export type LearningResourcesSearchRetrieveResourceTypeEnum =
  (typeof LearningResourcesSearchRetrieveResourceTypeEnum)[keyof typeof LearningResourcesSearchRetrieveResourceTypeEnum]
/**
 * @export
 */
export const LearningResourcesSearchRetrieveSearchModeEnum = {
  BestFields: "best_fields",
  MostFields: "most_fields",
  Phrase: "phrase",
} as const
export type LearningResourcesSearchRetrieveSearchModeEnum =
  (typeof LearningResourcesSearchRetrieveSearchModeEnum)[keyof typeof LearningResourcesSearchRetrieveSearchModeEnum]
/**
 * @export
 */
export const LearningResourcesSearchRetrieveSortbyEnum = {
  Featured: "featured",
  Id: "id",
  Id2: "-id",
  ReadableId: "readable_id",
  ReadableId2: "-readable_id",
  LastModified: "last_modified",
  LastModified2: "-last_modified",
  New: "new",
  StartDate: "start_date",
  StartDate2: "-start_date",
  Mitcoursenumber: "mitcoursenumber",
  Mitcoursenumber2: "-mitcoursenumber",
  Views: "views",
  Views2: "-views",
  Upcoming: "upcoming",
} as const
export type LearningResourcesSearchRetrieveSortbyEnum =
  (typeof LearningResourcesSearchRetrieveSortbyEnum)[keyof typeof LearningResourcesSearchRetrieveSortbyEnum]

/**
 * LearningResourcesUserSubscriptionApi - axios parameter creator
 * @export
 */
export const LearningResourcesUserSubscriptionApiAxiosParamCreator = function (
  configuration?: Configuration,
) {
  return {
    /**
     * View for listing percolate query subscriptions for a user
     * @summary Check if a user is subscribed to a specific query
     * @param {Array<LearningResourcesUserSubscriptionCheckListAggregationsEnum>} [aggregations] Show resource counts by category
     * @param {boolean | null} [certification] True if the learning resource offers a certificate
     * @param {Array<LearningResourcesUserSubscriptionCheckListCertificationTypeEnum>} [certification_type] The type of certificate               * &#x60;micromasters&#x60; - MicroMasters Credential * &#x60;professional&#x60; - Professional Certificate * &#x60;completion&#x60; - Certificate of Completion * &#x60;none&#x60; - No Certificate
     * @param {number | null} [content_file_score_weight] Score weight for content file data.  1 is the default. 0 means content files are ignored
     * @param {Array<string>} [course_feature] The course feature. Possible options are at api/v1/course_features/
     * @param {Array<LearningResourcesUserSubscriptionCheckListDeliveryEnum>} [delivery] The delivery options in which the learning resource is offered               * &#x60;online&#x60; - Online * &#x60;hybrid&#x60; - Hybrid * &#x60;in_person&#x60; - In person * &#x60;offline&#x60; - Offline
     * @param {Array<LearningResourcesUserSubscriptionCheckListDepartmentEnum>} [department] The department that offers the learning resource               * &#x60;1&#x60; - Civil and Environmental Engineering * &#x60;2&#x60; - Mechanical Engineering * &#x60;3&#x60; - Materials Science and Engineering * &#x60;4&#x60; - Architecture * &#x60;5&#x60; - Chemistry * &#x60;6&#x60; - Electrical Engineering and Computer Science * &#x60;7&#x60; - Biology * &#x60;8&#x60; - Physics * &#x60;9&#x60; - Brain and Cognitive Sciences * &#x60;10&#x60; - Chemical Engineering * &#x60;11&#x60; - Urban Studies and Planning * &#x60;12&#x60; - Earth, Atmospheric, and Planetary Sciences * &#x60;14&#x60; - Economics * &#x60;15&#x60; - Management * &#x60;16&#x60; - Aeronautics and Astronautics * &#x60;17&#x60; - Political Science * &#x60;18&#x60; - Mathematics * &#x60;20&#x60; - Biological Engineering * &#x60;21A&#x60; - Anthropology * &#x60;21G&#x60; - Global Languages * &#x60;21H&#x60; - History * &#x60;21L&#x60; - Literature * &#x60;21M&#x60; - Music and Theater Arts * &#x60;22&#x60; - Nuclear Science and Engineering * &#x60;24&#x60; - Linguistics and Philosophy * &#x60;CC&#x60; - Concourse * &#x60;CMS-W&#x60; - Comparative Media Studies/Writing * &#x60;EC&#x60; - Edgerton Center * &#x60;ES&#x60; - Experimental Study Group * &#x60;ESD&#x60; - Engineering Systems Division * &#x60;HST&#x60; - Medical Engineering and Science * &#x60;IDS&#x60; - Data, Systems, and Society * &#x60;MAS&#x60; - Media Arts and Sciences * &#x60;PE&#x60; - Athletics, Physical Education and Recreation * &#x60;SP&#x60; - Special Programs * &#x60;STS&#x60; - Science, Technology, and Society * &#x60;WGS&#x60; - Women\&#39;s and Gender Studies
     * @param {boolean | null} [dev_mode] If true return raw open search results with score explanations
     * @param {boolean | null} [free]
     * @param {Array<number>} [id] The id value for the learning resource
     * @param {Array<LearningResourcesUserSubscriptionCheckListLevelEnum>} [level]
     * @param {number} [limit] Number of results to return per page
     * @param {number | null} [max_incompleteness_penalty] Maximum score penalty for incomplete OCW courses in percent. An OCW course with completeness &#x3D; 0 will have this score penalty. Partially complete courses have a linear penalty proportional to the degree of incompleteness. Only affects results if there is a search term.
     * @param {number | null} [min_score] Minimum score value a text query result needs to have to be displayed
     * @param {Array<string>} [ocw_topic] The ocw topic name.
     * @param {Array<LearningResourcesUserSubscriptionCheckListOfferedByEnum>} [offered_by] The organization that offers the learning resource               * &#x60;mitx&#x60; - MITx * &#x60;ocw&#x60; - MIT OpenCourseWare * &#x60;bootcamps&#x60; - Bootcamps * &#x60;xpro&#x60; - MIT xPRO * &#x60;mitpe&#x60; - MIT Professional Education * &#x60;see&#x60; - MIT Sloan Executive Education
     * @param {number} [offset] The initial index from which to return the results
     * @param {Array<LearningResourcesUserSubscriptionCheckListPlatformEnum>} [platform] The platform on which the learning resource is offered               * &#x60;edx&#x60; - edX * &#x60;ocw&#x60; - MIT OpenCourseWare * &#x60;oll&#x60; - Open Learning Library * &#x60;mitxonline&#x60; - MITx Online * &#x60;bootcamps&#x60; - Bootcamps * &#x60;xpro&#x60; - MIT xPRO * &#x60;csail&#x60; - CSAIL * &#x60;mitpe&#x60; - MIT Professional Education * &#x60;see&#x60; - MIT Sloan Executive Education * &#x60;scc&#x60; - Schwarzman College of Computing * &#x60;ctl&#x60; - Center for Transportation &amp; Logistics * &#x60;whu&#x60; - WHU * &#x60;susskind&#x60; - Susskind * &#x60;globalalumni&#x60; - Global Alumni * &#x60;simplilearn&#x60; - Simplilearn * &#x60;emeritus&#x60; - Emeritus * &#x60;podcast&#x60; - Podcast * &#x60;youtube&#x60; - YouTube
     * @param {boolean | null} [professional]
     * @param {string} [q] The search text
     * @param {Array<LearningResourcesUserSubscriptionCheckListResourceCategoryEnum>} [resource_category] The category of learning resource               * &#x60;course&#x60; - Course * &#x60;program&#x60; - Program * &#x60;learning_material&#x60; - Learning Material
     * @param {Array<LearningResourcesUserSubscriptionCheckListResourceTypeEnum>} [resource_type] The type of learning resource               * &#x60;course&#x60; - course * &#x60;program&#x60; - program * &#x60;learning_path&#x60; - learning path * &#x60;podcast&#x60; - podcast * &#x60;podcast_episode&#x60; - podcast episode * &#x60;video&#x60; - video * &#x60;video_playlist&#x60; - video playlist
     * @param {LearningResourcesUserSubscriptionCheckListSearchModeEnum} [search_mode] The open search search type for text queries               * &#x60;best_fields&#x60; - best_fields * &#x60;most_fields&#x60; - most_fields * &#x60;phrase&#x60; - phrase  * &#x60;best_fields&#x60; - best_fields * &#x60;most_fields&#x60; - most_fields * &#x60;phrase&#x60; - phrase
     * @param {number | null} [slop] Allowed distance for phrase search
     * @param {LearningResourcesUserSubscriptionCheckListSortbyEnum} [sortby] If the parameter starts with \&#39;-\&#39; the sort is in descending order  * &#x60;featured&#x60; - Featured * &#x60;id&#x60; - Object ID ascending * &#x60;-id&#x60; - Object ID descending * &#x60;readable_id&#x60; - Readable ID ascending * &#x60;-readable_id&#x60; - Readable ID descending * &#x60;last_modified&#x60; - Last Modified Date ascending * &#x60;-last_modified&#x60; - Last Modified Date descending * &#x60;new&#x60; - Newest resources first * &#x60;start_date&#x60; - Start Date ascending * &#x60;-start_date&#x60; - Start Date descending * &#x60;mitcoursenumber&#x60; - MIT course number ascending * &#x60;-mitcoursenumber&#x60; - MIT course number descending * &#x60;views&#x60; - Popularity ascending * &#x60;-views&#x60; - Popularity descending * &#x60;upcoming&#x60; - Next start date ascending
     * @param {LearningResourcesUserSubscriptionCheckListSourceTypeEnum} [source_type] The subscription type  * &#x60;search_subscription_type&#x60; - search_subscription_type * &#x60;channel_subscription_type&#x60; - channel_subscription_type
     * @param {Array<string>} [topic] The topic name. To see a list of options go to api/v1/topics/
     * @param {number | null} [yearly_decay_percent] Relevance score penalty percent per year for for resources without upcoming runs. Only affects results if there is a search term.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    learningResourcesUserSubscriptionCheckList: async (
      aggregations?: Array<LearningResourcesUserSubscriptionCheckListAggregationsEnum>,
      certification?: boolean | null,
      certification_type?: Array<LearningResourcesUserSubscriptionCheckListCertificationTypeEnum>,
      content_file_score_weight?: number | null,
      course_feature?: Array<string>,
      delivery?: Array<LearningResourcesUserSubscriptionCheckListDeliveryEnum>,
      department?: Array<LearningResourcesUserSubscriptionCheckListDepartmentEnum>,
      dev_mode?: boolean | null,
      free?: boolean | null,
      id?: Array<number>,
      level?: Array<LearningResourcesUserSubscriptionCheckListLevelEnum>,
      limit?: number,
      max_incompleteness_penalty?: number | null,
      min_score?: number | null,
      ocw_topic?: Array<string>,
      offered_by?: Array<LearningResourcesUserSubscriptionCheckListOfferedByEnum>,
      offset?: number,
      platform?: Array<LearningResourcesUserSubscriptionCheckListPlatformEnum>,
      professional?: boolean | null,
      q?: string,
      resource_category?: Array<LearningResourcesUserSubscriptionCheckListResourceCategoryEnum>,
      resource_type?: Array<LearningResourcesUserSubscriptionCheckListResourceTypeEnum>,
      search_mode?: LearningResourcesUserSubscriptionCheckListSearchModeEnum,
      slop?: number | null,
      sortby?: LearningResourcesUserSubscriptionCheckListSortbyEnum,
      source_type?: LearningResourcesUserSubscriptionCheckListSourceTypeEnum,
      topic?: Array<string>,
      yearly_decay_percent?: number | null,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/api/v1/learning_resources_user_subscription/check/`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      if (aggregations) {
        localVarQueryParameter["aggregations"] = aggregations
      }

      if (certification !== undefined) {
        localVarQueryParameter["certification"] = certification
      }

      if (certification_type) {
        localVarQueryParameter["certification_type"] = certification_type
      }

      if (content_file_score_weight !== undefined) {
        localVarQueryParameter["content_file_score_weight"] =
          content_file_score_weight
      }

      if (course_feature) {
        localVarQueryParameter["course_feature"] = course_feature
      }

      if (delivery) {
        localVarQueryParameter["delivery"] = delivery
      }

      if (department) {
        localVarQueryParameter["department"] = department
      }

      if (dev_mode !== undefined) {
        localVarQueryParameter["dev_mode"] = dev_mode
      }

      if (free !== undefined) {
        localVarQueryParameter["free"] = free
      }

      if (id) {
        localVarQueryParameter["id"] = id
      }

      if (level) {
        localVarQueryParameter["level"] = level
      }

      if (limit !== undefined) {
        localVarQueryParameter["limit"] = limit
      }

      if (max_incompleteness_penalty !== undefined) {
        localVarQueryParameter["max_incompleteness_penalty"] =
          max_incompleteness_penalty
      }

      if (min_score !== undefined) {
        localVarQueryParameter["min_score"] = min_score
      }

      if (ocw_topic) {
        localVarQueryParameter["ocw_topic"] = ocw_topic
      }

      if (offered_by) {
        localVarQueryParameter["offered_by"] = offered_by
      }

      if (offset !== undefined) {
        localVarQueryParameter["offset"] = offset
      }

      if (platform) {
        localVarQueryParameter["platform"] = platform
      }

      if (professional !== undefined) {
        localVarQueryParameter["professional"] = professional
      }

      if (q !== undefined) {
        localVarQueryParameter["q"] = q
      }

      if (resource_category) {
        localVarQueryParameter["resource_category"] = resource_category
      }

      if (resource_type) {
        localVarQueryParameter["resource_type"] = resource_type
      }

      if (search_mode !== undefined) {
        localVarQueryParameter["search_mode"] = search_mode
      }

      if (slop !== undefined) {
        localVarQueryParameter["slop"] = slop
      }

      if (sortby !== undefined) {
        localVarQueryParameter["sortby"] = sortby
      }

      if (source_type !== undefined) {
        localVarQueryParameter["source_type"] = source_type
      }

      if (topic) {
        localVarQueryParameter["topic"] = topic
      }

      if (yearly_decay_percent !== undefined) {
        localVarQueryParameter["yearly_decay_percent"] = yearly_decay_percent
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     * View for listing percolate query subscriptions for a user
     * @summary List subscribed queries
     * @param {Array<LearningResourcesUserSubscriptionListAggregationsEnum>} [aggregations] Show resource counts by category
     * @param {boolean | null} [certification] True if the learning resource offers a certificate
     * @param {Array<LearningResourcesUserSubscriptionListCertificationTypeEnum>} [certification_type] The type of certificate               * &#x60;micromasters&#x60; - MicroMasters Credential * &#x60;professional&#x60; - Professional Certificate * &#x60;completion&#x60; - Certificate of Completion * &#x60;none&#x60; - No Certificate
     * @param {number | null} [content_file_score_weight] Score weight for content file data.  1 is the default. 0 means content files are ignored
     * @param {Array<string>} [course_feature] The course feature. Possible options are at api/v1/course_features/
     * @param {Array<LearningResourcesUserSubscriptionListDeliveryEnum>} [delivery] The delivery options in which the learning resource is offered               * &#x60;online&#x60; - Online * &#x60;hybrid&#x60; - Hybrid * &#x60;in_person&#x60; - In person * &#x60;offline&#x60; - Offline
     * @param {Array<LearningResourcesUserSubscriptionListDepartmentEnum>} [department] The department that offers the learning resource               * &#x60;1&#x60; - Civil and Environmental Engineering * &#x60;2&#x60; - Mechanical Engineering * &#x60;3&#x60; - Materials Science and Engineering * &#x60;4&#x60; - Architecture * &#x60;5&#x60; - Chemistry * &#x60;6&#x60; - Electrical Engineering and Computer Science * &#x60;7&#x60; - Biology * &#x60;8&#x60; - Physics * &#x60;9&#x60; - Brain and Cognitive Sciences * &#x60;10&#x60; - Chemical Engineering * &#x60;11&#x60; - Urban Studies and Planning * &#x60;12&#x60; - Earth, Atmospheric, and Planetary Sciences * &#x60;14&#x60; - Economics * &#x60;15&#x60; - Management * &#x60;16&#x60; - Aeronautics and Astronautics * &#x60;17&#x60; - Political Science * &#x60;18&#x60; - Mathematics * &#x60;20&#x60; - Biological Engineering * &#x60;21A&#x60; - Anthropology * &#x60;21G&#x60; - Global Languages * &#x60;21H&#x60; - History * &#x60;21L&#x60; - Literature * &#x60;21M&#x60; - Music and Theater Arts * &#x60;22&#x60; - Nuclear Science and Engineering * &#x60;24&#x60; - Linguistics and Philosophy * &#x60;CC&#x60; - Concourse * &#x60;CMS-W&#x60; - Comparative Media Studies/Writing * &#x60;EC&#x60; - Edgerton Center * &#x60;ES&#x60; - Experimental Study Group * &#x60;ESD&#x60; - Engineering Systems Division * &#x60;HST&#x60; - Medical Engineering and Science * &#x60;IDS&#x60; - Data, Systems, and Society * &#x60;MAS&#x60; - Media Arts and Sciences * &#x60;PE&#x60; - Athletics, Physical Education and Recreation * &#x60;SP&#x60; - Special Programs * &#x60;STS&#x60; - Science, Technology, and Society * &#x60;WGS&#x60; - Women\&#39;s and Gender Studies
     * @param {boolean | null} [dev_mode] If true return raw open search results with score explanations
     * @param {boolean | null} [free]
     * @param {Array<number>} [id] The id value for the learning resource
     * @param {Array<LearningResourcesUserSubscriptionListLevelEnum>} [level]
     * @param {number} [limit] Number of results to return per page
     * @param {number | null} [max_incompleteness_penalty] Maximum score penalty for incomplete OCW courses in percent. An OCW course with completeness &#x3D; 0 will have this score penalty. Partially complete courses have a linear penalty proportional to the degree of incompleteness. Only affects results if there is a search term.
     * @param {number | null} [min_score] Minimum score value a text query result needs to have to be displayed
     * @param {Array<string>} [ocw_topic] The ocw topic name.
     * @param {Array<LearningResourcesUserSubscriptionListOfferedByEnum>} [offered_by] The organization that offers the learning resource               * &#x60;mitx&#x60; - MITx * &#x60;ocw&#x60; - MIT OpenCourseWare * &#x60;bootcamps&#x60; - Bootcamps * &#x60;xpro&#x60; - MIT xPRO * &#x60;mitpe&#x60; - MIT Professional Education * &#x60;see&#x60; - MIT Sloan Executive Education
     * @param {number} [offset] The initial index from which to return the results
     * @param {Array<LearningResourcesUserSubscriptionListPlatformEnum>} [platform] The platform on which the learning resource is offered               * &#x60;edx&#x60; - edX * &#x60;ocw&#x60; - MIT OpenCourseWare * &#x60;oll&#x60; - Open Learning Library * &#x60;mitxonline&#x60; - MITx Online * &#x60;bootcamps&#x60; - Bootcamps * &#x60;xpro&#x60; - MIT xPRO * &#x60;csail&#x60; - CSAIL * &#x60;mitpe&#x60; - MIT Professional Education * &#x60;see&#x60; - MIT Sloan Executive Education * &#x60;scc&#x60; - Schwarzman College of Computing * &#x60;ctl&#x60; - Center for Transportation &amp; Logistics * &#x60;whu&#x60; - WHU * &#x60;susskind&#x60; - Susskind * &#x60;globalalumni&#x60; - Global Alumni * &#x60;simplilearn&#x60; - Simplilearn * &#x60;emeritus&#x60; - Emeritus * &#x60;podcast&#x60; - Podcast * &#x60;youtube&#x60; - YouTube
     * @param {boolean | null} [professional]
     * @param {string} [q] The search text
     * @param {Array<LearningResourcesUserSubscriptionListResourceCategoryEnum>} [resource_category] The category of learning resource               * &#x60;course&#x60; - Course * &#x60;program&#x60; - Program * &#x60;learning_material&#x60; - Learning Material
     * @param {Array<LearningResourcesUserSubscriptionListResourceTypeEnum>} [resource_type] The type of learning resource               * &#x60;course&#x60; - course * &#x60;program&#x60; - program * &#x60;learning_path&#x60; - learning path * &#x60;podcast&#x60; - podcast * &#x60;podcast_episode&#x60; - podcast episode * &#x60;video&#x60; - video * &#x60;video_playlist&#x60; - video playlist
     * @param {LearningResourcesUserSubscriptionListSearchModeEnum} [search_mode] The open search search type for text queries               * &#x60;best_fields&#x60; - best_fields * &#x60;most_fields&#x60; - most_fields * &#x60;phrase&#x60; - phrase  * &#x60;best_fields&#x60; - best_fields * &#x60;most_fields&#x60; - most_fields * &#x60;phrase&#x60; - phrase
     * @param {number | null} [slop] Allowed distance for phrase search
     * @param {LearningResourcesUserSubscriptionListSortbyEnum} [sortby] If the parameter starts with \&#39;-\&#39; the sort is in descending order  * &#x60;featured&#x60; - Featured * &#x60;id&#x60; - Object ID ascending * &#x60;-id&#x60; - Object ID descending * &#x60;readable_id&#x60; - Readable ID ascending * &#x60;-readable_id&#x60; - Readable ID descending * &#x60;last_modified&#x60; - Last Modified Date ascending * &#x60;-last_modified&#x60; - Last Modified Date descending * &#x60;new&#x60; - Newest resources first * &#x60;start_date&#x60; - Start Date ascending * &#x60;-start_date&#x60; - Start Date descending * &#x60;mitcoursenumber&#x60; - MIT course number ascending * &#x60;-mitcoursenumber&#x60; - MIT course number descending * &#x60;views&#x60; - Popularity ascending * &#x60;-views&#x60; - Popularity descending * &#x60;upcoming&#x60; - Next start date ascending
     * @param {Array<string>} [topic] The topic name. To see a list of options go to api/v1/topics/
     * @param {number | null} [yearly_decay_percent] Relevance score penalty percent per year for for resources without upcoming runs. Only affects results if there is a search term.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    learningResourcesUserSubscriptionList: async (
      aggregations?: Array<LearningResourcesUserSubscriptionListAggregationsEnum>,
      certification?: boolean | null,
      certification_type?: Array<LearningResourcesUserSubscriptionListCertificationTypeEnum>,
      content_file_score_weight?: number | null,
      course_feature?: Array<string>,
      delivery?: Array<LearningResourcesUserSubscriptionListDeliveryEnum>,
      department?: Array<LearningResourcesUserSubscriptionListDepartmentEnum>,
      dev_mode?: boolean | null,
      free?: boolean | null,
      id?: Array<number>,
      level?: Array<LearningResourcesUserSubscriptionListLevelEnum>,
      limit?: number,
      max_incompleteness_penalty?: number | null,
      min_score?: number | null,
      ocw_topic?: Array<string>,
      offered_by?: Array<LearningResourcesUserSubscriptionListOfferedByEnum>,
      offset?: number,
      platform?: Array<LearningResourcesUserSubscriptionListPlatformEnum>,
      professional?: boolean | null,
      q?: string,
      resource_category?: Array<LearningResourcesUserSubscriptionListResourceCategoryEnum>,
      resource_type?: Array<LearningResourcesUserSubscriptionListResourceTypeEnum>,
      search_mode?: LearningResourcesUserSubscriptionListSearchModeEnum,
      slop?: number | null,
      sortby?: LearningResourcesUserSubscriptionListSortbyEnum,
      topic?: Array<string>,
      yearly_decay_percent?: number | null,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/api/v1/learning_resources_user_subscription/`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      if (aggregations) {
        localVarQueryParameter["aggregations"] = aggregations
      }

      if (certification !== undefined) {
        localVarQueryParameter["certification"] = certification
      }

      if (certification_type) {
        localVarQueryParameter["certification_type"] = certification_type
      }

      if (content_file_score_weight !== undefined) {
        localVarQueryParameter["content_file_score_weight"] =
          content_file_score_weight
      }

      if (course_feature) {
        localVarQueryParameter["course_feature"] = course_feature
      }

      if (delivery) {
        localVarQueryParameter["delivery"] = delivery
      }

      if (department) {
        localVarQueryParameter["department"] = department
      }

      if (dev_mode !== undefined) {
        localVarQueryParameter["dev_mode"] = dev_mode
      }

      if (free !== undefined) {
        localVarQueryParameter["free"] = free
      }

      if (id) {
        localVarQueryParameter["id"] = id
      }

      if (level) {
        localVarQueryParameter["level"] = level
      }

      if (limit !== undefined) {
        localVarQueryParameter["limit"] = limit
      }

      if (max_incompleteness_penalty !== undefined) {
        localVarQueryParameter["max_incompleteness_penalty"] =
          max_incompleteness_penalty
      }

      if (min_score !== undefined) {
        localVarQueryParameter["min_score"] = min_score
      }

      if (ocw_topic) {
        localVarQueryParameter["ocw_topic"] = ocw_topic
      }

      if (offered_by) {
        localVarQueryParameter["offered_by"] = offered_by
      }

      if (offset !== undefined) {
        localVarQueryParameter["offset"] = offset
      }

      if (platform) {
        localVarQueryParameter["platform"] = platform
      }

      if (professional !== undefined) {
        localVarQueryParameter["professional"] = professional
      }

      if (q !== undefined) {
        localVarQueryParameter["q"] = q
      }

      if (resource_category) {
        localVarQueryParameter["resource_category"] = resource_category
      }

      if (resource_type) {
        localVarQueryParameter["resource_type"] = resource_type
      }

      if (search_mode !== undefined) {
        localVarQueryParameter["search_mode"] = search_mode
      }

      if (slop !== undefined) {
        localVarQueryParameter["slop"] = slop
      }

      if (sortby !== undefined) {
        localVarQueryParameter["sortby"] = sortby
      }

      if (topic) {
        localVarQueryParameter["topic"] = topic
      }

      if (yearly_decay_percent !== undefined) {
        localVarQueryParameter["yearly_decay_percent"] = yearly_decay_percent
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     * Subscribe a user to query
     * @summary Subscribe user to query
     * @param {Array<LearningResourcesUserSubscriptionSubscribeCreateAggregationsEnum>} [aggregations] Show resource counts by category
     * @param {boolean | null} [certification] True if the learning resource offers a certificate
     * @param {Array<LearningResourcesUserSubscriptionSubscribeCreateCertificationTypeEnum>} [certification_type] The type of certificate               * &#x60;micromasters&#x60; - MicroMasters Credential * &#x60;professional&#x60; - Professional Certificate * &#x60;completion&#x60; - Certificate of Completion * &#x60;none&#x60; - No Certificate
     * @param {number | null} [content_file_score_weight] Score weight for content file data.  1 is the default. 0 means content files are ignored
     * @param {Array<string>} [course_feature] The course feature. Possible options are at api/v1/course_features/
     * @param {Array<LearningResourcesUserSubscriptionSubscribeCreateDeliveryEnum>} [delivery] The delivery options in which the learning resource is offered               * &#x60;online&#x60; - Online * &#x60;hybrid&#x60; - Hybrid * &#x60;in_person&#x60; - In person * &#x60;offline&#x60; - Offline
     * @param {Array<LearningResourcesUserSubscriptionSubscribeCreateDepartmentEnum>} [department] The department that offers the learning resource               * &#x60;1&#x60; - Civil and Environmental Engineering * &#x60;2&#x60; - Mechanical Engineering * &#x60;3&#x60; - Materials Science and Engineering * &#x60;4&#x60; - Architecture * &#x60;5&#x60; - Chemistry * &#x60;6&#x60; - Electrical Engineering and Computer Science * &#x60;7&#x60; - Biology * &#x60;8&#x60; - Physics * &#x60;9&#x60; - Brain and Cognitive Sciences * &#x60;10&#x60; - Chemical Engineering * &#x60;11&#x60; - Urban Studies and Planning * &#x60;12&#x60; - Earth, Atmospheric, and Planetary Sciences * &#x60;14&#x60; - Economics * &#x60;15&#x60; - Management * &#x60;16&#x60; - Aeronautics and Astronautics * &#x60;17&#x60; - Political Science * &#x60;18&#x60; - Mathematics * &#x60;20&#x60; - Biological Engineering * &#x60;21A&#x60; - Anthropology * &#x60;21G&#x60; - Global Languages * &#x60;21H&#x60; - History * &#x60;21L&#x60; - Literature * &#x60;21M&#x60; - Music and Theater Arts * &#x60;22&#x60; - Nuclear Science and Engineering * &#x60;24&#x60; - Linguistics and Philosophy * &#x60;CC&#x60; - Concourse * &#x60;CMS-W&#x60; - Comparative Media Studies/Writing * &#x60;EC&#x60; - Edgerton Center * &#x60;ES&#x60; - Experimental Study Group * &#x60;ESD&#x60; - Engineering Systems Division * &#x60;HST&#x60; - Medical Engineering and Science * &#x60;IDS&#x60; - Data, Systems, and Society * &#x60;MAS&#x60; - Media Arts and Sciences * &#x60;PE&#x60; - Athletics, Physical Education and Recreation * &#x60;SP&#x60; - Special Programs * &#x60;STS&#x60; - Science, Technology, and Society * &#x60;WGS&#x60; - Women\&#39;s and Gender Studies
     * @param {boolean | null} [dev_mode] If true return raw open search results with score explanations
     * @param {boolean | null} [free]
     * @param {Array<number>} [id] The id value for the learning resource
     * @param {Array<LearningResourcesUserSubscriptionSubscribeCreateLevelEnum>} [level]
     * @param {number} [limit] Number of results to return per page
     * @param {number | null} [max_incompleteness_penalty] Maximum score penalty for incomplete OCW courses in percent. An OCW course with completeness &#x3D; 0 will have this score penalty. Partially complete courses have a linear penalty proportional to the degree of incompleteness. Only affects results if there is a search term.
     * @param {number | null} [min_score] Minimum score value a text query result needs to have to be displayed
     * @param {Array<string>} [ocw_topic] The ocw topic name.
     * @param {Array<LearningResourcesUserSubscriptionSubscribeCreateOfferedByEnum>} [offered_by] The organization that offers the learning resource               * &#x60;mitx&#x60; - MITx * &#x60;ocw&#x60; - MIT OpenCourseWare * &#x60;bootcamps&#x60; - Bootcamps * &#x60;xpro&#x60; - MIT xPRO * &#x60;mitpe&#x60; - MIT Professional Education * &#x60;see&#x60; - MIT Sloan Executive Education
     * @param {number} [offset] The initial index from which to return the results
     * @param {Array<LearningResourcesUserSubscriptionSubscribeCreatePlatformEnum>} [platform] The platform on which the learning resource is offered               * &#x60;edx&#x60; - edX * &#x60;ocw&#x60; - MIT OpenCourseWare * &#x60;oll&#x60; - Open Learning Library * &#x60;mitxonline&#x60; - MITx Online * &#x60;bootcamps&#x60; - Bootcamps * &#x60;xpro&#x60; - MIT xPRO * &#x60;csail&#x60; - CSAIL * &#x60;mitpe&#x60; - MIT Professional Education * &#x60;see&#x60; - MIT Sloan Executive Education * &#x60;scc&#x60; - Schwarzman College of Computing * &#x60;ctl&#x60; - Center for Transportation &amp; Logistics * &#x60;whu&#x60; - WHU * &#x60;susskind&#x60; - Susskind * &#x60;globalalumni&#x60; - Global Alumni * &#x60;simplilearn&#x60; - Simplilearn * &#x60;emeritus&#x60; - Emeritus * &#x60;podcast&#x60; - Podcast * &#x60;youtube&#x60; - YouTube
     * @param {boolean | null} [professional]
     * @param {string} [q] The search text
     * @param {Array<LearningResourcesUserSubscriptionSubscribeCreateResourceCategoryEnum>} [resource_category] The category of learning resource               * &#x60;course&#x60; - Course * &#x60;program&#x60; - Program * &#x60;learning_material&#x60; - Learning Material
     * @param {Array<LearningResourcesUserSubscriptionSubscribeCreateResourceTypeEnum>} [resource_type] The type of learning resource               * &#x60;course&#x60; - course * &#x60;program&#x60; - program * &#x60;learning_path&#x60; - learning path * &#x60;podcast&#x60; - podcast * &#x60;podcast_episode&#x60; - podcast episode * &#x60;video&#x60; - video * &#x60;video_playlist&#x60; - video playlist
     * @param {LearningResourcesUserSubscriptionSubscribeCreateSearchModeEnum} [search_mode] The open search search type for text queries               * &#x60;best_fields&#x60; - best_fields * &#x60;most_fields&#x60; - most_fields * &#x60;phrase&#x60; - phrase  * &#x60;best_fields&#x60; - best_fields * &#x60;most_fields&#x60; - most_fields * &#x60;phrase&#x60; - phrase
     * @param {number | null} [slop] Allowed distance for phrase search
     * @param {LearningResourcesUserSubscriptionSubscribeCreateSortbyEnum} [sortby] If the parameter starts with \&#39;-\&#39; the sort is in descending order  * &#x60;featured&#x60; - Featured * &#x60;id&#x60; - Object ID ascending * &#x60;-id&#x60; - Object ID descending * &#x60;readable_id&#x60; - Readable ID ascending * &#x60;-readable_id&#x60; - Readable ID descending * &#x60;last_modified&#x60; - Last Modified Date ascending * &#x60;-last_modified&#x60; - Last Modified Date descending * &#x60;new&#x60; - Newest resources first * &#x60;start_date&#x60; - Start Date ascending * &#x60;-start_date&#x60; - Start Date descending * &#x60;mitcoursenumber&#x60; - MIT course number ascending * &#x60;-mitcoursenumber&#x60; - MIT course number descending * &#x60;views&#x60; - Popularity ascending * &#x60;-views&#x60; - Popularity descending * &#x60;upcoming&#x60; - Next start date ascending
     * @param {LearningResourcesUserSubscriptionSubscribeCreateSourceTypeEnum} [source_type] The subscription type  * &#x60;search_subscription_type&#x60; - search_subscription_type * &#x60;channel_subscription_type&#x60; - channel_subscription_type
     * @param {Array<string>} [topic] The topic name. To see a list of options go to api/v1/topics/
     * @param {number | null} [yearly_decay_percent] Relevance score penalty percent per year for for resources without upcoming runs. Only affects results if there is a search term.
     * @param {PercolateQuerySubscriptionRequestRequest} [PercolateQuerySubscriptionRequestRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    learningResourcesUserSubscriptionSubscribeCreate: async (
      aggregations?: Array<LearningResourcesUserSubscriptionSubscribeCreateAggregationsEnum>,
      certification?: boolean | null,
      certification_type?: Array<LearningResourcesUserSubscriptionSubscribeCreateCertificationTypeEnum>,
      content_file_score_weight?: number | null,
      course_feature?: Array<string>,
      delivery?: Array<LearningResourcesUserSubscriptionSubscribeCreateDeliveryEnum>,
      department?: Array<LearningResourcesUserSubscriptionSubscribeCreateDepartmentEnum>,
      dev_mode?: boolean | null,
      free?: boolean | null,
      id?: Array<number>,
      level?: Array<LearningResourcesUserSubscriptionSubscribeCreateLevelEnum>,
      limit?: number,
      max_incompleteness_penalty?: number | null,
      min_score?: number | null,
      ocw_topic?: Array<string>,
      offered_by?: Array<LearningResourcesUserSubscriptionSubscribeCreateOfferedByEnum>,
      offset?: number,
      platform?: Array<LearningResourcesUserSubscriptionSubscribeCreatePlatformEnum>,
      professional?: boolean | null,
      q?: string,
      resource_category?: Array<LearningResourcesUserSubscriptionSubscribeCreateResourceCategoryEnum>,
      resource_type?: Array<LearningResourcesUserSubscriptionSubscribeCreateResourceTypeEnum>,
      search_mode?: LearningResourcesUserSubscriptionSubscribeCreateSearchModeEnum,
      slop?: number | null,
      sortby?: LearningResourcesUserSubscriptionSubscribeCreateSortbyEnum,
      source_type?: LearningResourcesUserSubscriptionSubscribeCreateSourceTypeEnum,
      topic?: Array<string>,
      yearly_decay_percent?: number | null,
      PercolateQuerySubscriptionRequestRequest?: PercolateQuerySubscriptionRequestRequest,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/api/v1/learning_resources_user_subscription/subscribe/`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = {
        method: "POST",
        ...baseOptions,
        ...options,
      }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      if (aggregations) {
        localVarQueryParameter["aggregations"] = aggregations
      }

      if (certification !== undefined) {
        localVarQueryParameter["certification"] = certification
      }

      if (certification_type) {
        localVarQueryParameter["certification_type"] = certification_type
      }

      if (content_file_score_weight !== undefined) {
        localVarQueryParameter["content_file_score_weight"] =
          content_file_score_weight
      }

      if (course_feature) {
        localVarQueryParameter["course_feature"] = course_feature
      }

      if (delivery) {
        localVarQueryParameter["delivery"] = delivery
      }

      if (department) {
        localVarQueryParameter["department"] = department
      }

      if (dev_mode !== undefined) {
        localVarQueryParameter["dev_mode"] = dev_mode
      }

      if (free !== undefined) {
        localVarQueryParameter["free"] = free
      }

      if (id) {
        localVarQueryParameter["id"] = id
      }

      if (level) {
        localVarQueryParameter["level"] = level
      }

      if (limit !== undefined) {
        localVarQueryParameter["limit"] = limit
      }

      if (max_incompleteness_penalty !== undefined) {
        localVarQueryParameter["max_incompleteness_penalty"] =
          max_incompleteness_penalty
      }

      if (min_score !== undefined) {
        localVarQueryParameter["min_score"] = min_score
      }

      if (ocw_topic) {
        localVarQueryParameter["ocw_topic"] = ocw_topic
      }

      if (offered_by) {
        localVarQueryParameter["offered_by"] = offered_by
      }

      if (offset !== undefined) {
        localVarQueryParameter["offset"] = offset
      }

      if (platform) {
        localVarQueryParameter["platform"] = platform
      }

      if (professional !== undefined) {
        localVarQueryParameter["professional"] = professional
      }

      if (q !== undefined) {
        localVarQueryParameter["q"] = q
      }

      if (resource_category) {
        localVarQueryParameter["resource_category"] = resource_category
      }

      if (resource_type) {
        localVarQueryParameter["resource_type"] = resource_type
      }

      if (search_mode !== undefined) {
        localVarQueryParameter["search_mode"] = search_mode
      }

      if (slop !== undefined) {
        localVarQueryParameter["slop"] = slop
      }

      if (sortby !== undefined) {
        localVarQueryParameter["sortby"] = sortby
      }

      if (source_type !== undefined) {
        localVarQueryParameter["source_type"] = source_type
      }

      if (topic) {
        localVarQueryParameter["topic"] = topic
      }

      if (yearly_decay_percent !== undefined) {
        localVarQueryParameter["yearly_decay_percent"] = yearly_decay_percent
      }

      localVarHeaderParameter["Content-Type"] = "application/json"

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }
      localVarRequestOptions.data = serializeDataIfNeeded(
        PercolateQuerySubscriptionRequestRequest,
        localVarRequestOptions,
        configuration,
      )

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     * Unsubscribe a user from a query  Args: pk (integer): The id of the query  Returns: PercolateQuerySerializer: The percolate query
     * @summary Unsubscribe user from query
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    learningResourcesUserSubscriptionUnsubscribeDestroy: async (
      id: number,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists(
        "learningResourcesUserSubscriptionUnsubscribeDestroy",
        "id",
        id,
      )
      const localVarPath =
        `/api/v1/learning_resources_user_subscription/{id}/unsubscribe/`.replace(
          `{${"id"}}`,
          encodeURIComponent(String(id)),
        )
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = {
        method: "DELETE",
        ...baseOptions,
        ...options,
      }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
  }
}

/**
 * LearningResourcesUserSubscriptionApi - functional programming interface
 * @export
 */
export const LearningResourcesUserSubscriptionApiFp = function (
  configuration?: Configuration,
) {
  const localVarAxiosParamCreator =
    LearningResourcesUserSubscriptionApiAxiosParamCreator(configuration)
  return {
    /**
     * View for listing percolate query subscriptions for a user
     * @summary Check if a user is subscribed to a specific query
     * @param {Array<LearningResourcesUserSubscriptionCheckListAggregationsEnum>} [aggregations] Show resource counts by category
     * @param {boolean | null} [certification] True if the learning resource offers a certificate
     * @param {Array<LearningResourcesUserSubscriptionCheckListCertificationTypeEnum>} [certification_type] The type of certificate               * &#x60;micromasters&#x60; - MicroMasters Credential * &#x60;professional&#x60; - Professional Certificate * &#x60;completion&#x60; - Certificate of Completion * &#x60;none&#x60; - No Certificate
     * @param {number | null} [content_file_score_weight] Score weight for content file data.  1 is the default. 0 means content files are ignored
     * @param {Array<string>} [course_feature] The course feature. Possible options are at api/v1/course_features/
     * @param {Array<LearningResourcesUserSubscriptionCheckListDeliveryEnum>} [delivery] The delivery options in which the learning resource is offered               * &#x60;online&#x60; - Online * &#x60;hybrid&#x60; - Hybrid * &#x60;in_person&#x60; - In person * &#x60;offline&#x60; - Offline
     * @param {Array<LearningResourcesUserSubscriptionCheckListDepartmentEnum>} [department] The department that offers the learning resource               * &#x60;1&#x60; - Civil and Environmental Engineering * &#x60;2&#x60; - Mechanical Engineering * &#x60;3&#x60; - Materials Science and Engineering * &#x60;4&#x60; - Architecture * &#x60;5&#x60; - Chemistry * &#x60;6&#x60; - Electrical Engineering and Computer Science * &#x60;7&#x60; - Biology * &#x60;8&#x60; - Physics * &#x60;9&#x60; - Brain and Cognitive Sciences * &#x60;10&#x60; - Chemical Engineering * &#x60;11&#x60; - Urban Studies and Planning * &#x60;12&#x60; - Earth, Atmospheric, and Planetary Sciences * &#x60;14&#x60; - Economics * &#x60;15&#x60; - Management * &#x60;16&#x60; - Aeronautics and Astronautics * &#x60;17&#x60; - Political Science * &#x60;18&#x60; - Mathematics * &#x60;20&#x60; - Biological Engineering * &#x60;21A&#x60; - Anthropology * &#x60;21G&#x60; - Global Languages * &#x60;21H&#x60; - History * &#x60;21L&#x60; - Literature * &#x60;21M&#x60; - Music and Theater Arts * &#x60;22&#x60; - Nuclear Science and Engineering * &#x60;24&#x60; - Linguistics and Philosophy * &#x60;CC&#x60; - Concourse * &#x60;CMS-W&#x60; - Comparative Media Studies/Writing * &#x60;EC&#x60; - Edgerton Center * &#x60;ES&#x60; - Experimental Study Group * &#x60;ESD&#x60; - Engineering Systems Division * &#x60;HST&#x60; - Medical Engineering and Science * &#x60;IDS&#x60; - Data, Systems, and Society * &#x60;MAS&#x60; - Media Arts and Sciences * &#x60;PE&#x60; - Athletics, Physical Education and Recreation * &#x60;SP&#x60; - Special Programs * &#x60;STS&#x60; - Science, Technology, and Society * &#x60;WGS&#x60; - Women\&#39;s and Gender Studies
     * @param {boolean | null} [dev_mode] If true return raw open search results with score explanations
     * @param {boolean | null} [free]
     * @param {Array<number>} [id] The id value for the learning resource
     * @param {Array<LearningResourcesUserSubscriptionCheckListLevelEnum>} [level]
     * @param {number} [limit] Number of results to return per page
     * @param {number | null} [max_incompleteness_penalty] Maximum score penalty for incomplete OCW courses in percent. An OCW course with completeness &#x3D; 0 will have this score penalty. Partially complete courses have a linear penalty proportional to the degree of incompleteness. Only affects results if there is a search term.
     * @param {number | null} [min_score] Minimum score value a text query result needs to have to be displayed
     * @param {Array<string>} [ocw_topic] The ocw topic name.
     * @param {Array<LearningResourcesUserSubscriptionCheckListOfferedByEnum>} [offered_by] The organization that offers the learning resource               * &#x60;mitx&#x60; - MITx * &#x60;ocw&#x60; - MIT OpenCourseWare * &#x60;bootcamps&#x60; - Bootcamps * &#x60;xpro&#x60; - MIT xPRO * &#x60;mitpe&#x60; - MIT Professional Education * &#x60;see&#x60; - MIT Sloan Executive Education
     * @param {number} [offset] The initial index from which to return the results
     * @param {Array<LearningResourcesUserSubscriptionCheckListPlatformEnum>} [platform] The platform on which the learning resource is offered               * &#x60;edx&#x60; - edX * &#x60;ocw&#x60; - MIT OpenCourseWare * &#x60;oll&#x60; - Open Learning Library * &#x60;mitxonline&#x60; - MITx Online * &#x60;bootcamps&#x60; - Bootcamps * &#x60;xpro&#x60; - MIT xPRO * &#x60;csail&#x60; - CSAIL * &#x60;mitpe&#x60; - MIT Professional Education * &#x60;see&#x60; - MIT Sloan Executive Education * &#x60;scc&#x60; - Schwarzman College of Computing * &#x60;ctl&#x60; - Center for Transportation &amp; Logistics * &#x60;whu&#x60; - WHU * &#x60;susskind&#x60; - Susskind * &#x60;globalalumni&#x60; - Global Alumni * &#x60;simplilearn&#x60; - Simplilearn * &#x60;emeritus&#x60; - Emeritus * &#x60;podcast&#x60; - Podcast * &#x60;youtube&#x60; - YouTube
     * @param {boolean | null} [professional]
     * @param {string} [q] The search text
     * @param {Array<LearningResourcesUserSubscriptionCheckListResourceCategoryEnum>} [resource_category] The category of learning resource               * &#x60;course&#x60; - Course * &#x60;program&#x60; - Program * &#x60;learning_material&#x60; - Learning Material
     * @param {Array<LearningResourcesUserSubscriptionCheckListResourceTypeEnum>} [resource_type] The type of learning resource               * &#x60;course&#x60; - course * &#x60;program&#x60; - program * &#x60;learning_path&#x60; - learning path * &#x60;podcast&#x60; - podcast * &#x60;podcast_episode&#x60; - podcast episode * &#x60;video&#x60; - video * &#x60;video_playlist&#x60; - video playlist
     * @param {LearningResourcesUserSubscriptionCheckListSearchModeEnum} [search_mode] The open search search type for text queries               * &#x60;best_fields&#x60; - best_fields * &#x60;most_fields&#x60; - most_fields * &#x60;phrase&#x60; - phrase  * &#x60;best_fields&#x60; - best_fields * &#x60;most_fields&#x60; - most_fields * &#x60;phrase&#x60; - phrase
     * @param {number | null} [slop] Allowed distance for phrase search
     * @param {LearningResourcesUserSubscriptionCheckListSortbyEnum} [sortby] If the parameter starts with \&#39;-\&#39; the sort is in descending order  * &#x60;featured&#x60; - Featured * &#x60;id&#x60; - Object ID ascending * &#x60;-id&#x60; - Object ID descending * &#x60;readable_id&#x60; - Readable ID ascending * &#x60;-readable_id&#x60; - Readable ID descending * &#x60;last_modified&#x60; - Last Modified Date ascending * &#x60;-last_modified&#x60; - Last Modified Date descending * &#x60;new&#x60; - Newest resources first * &#x60;start_date&#x60; - Start Date ascending * &#x60;-start_date&#x60; - Start Date descending * &#x60;mitcoursenumber&#x60; - MIT course number ascending * &#x60;-mitcoursenumber&#x60; - MIT course number descending * &#x60;views&#x60; - Popularity ascending * &#x60;-views&#x60; - Popularity descending * &#x60;upcoming&#x60; - Next start date ascending
     * @param {LearningResourcesUserSubscriptionCheckListSourceTypeEnum} [source_type] The subscription type  * &#x60;search_subscription_type&#x60; - search_subscription_type * &#x60;channel_subscription_type&#x60; - channel_subscription_type
     * @param {Array<string>} [topic] The topic name. To see a list of options go to api/v1/topics/
     * @param {number | null} [yearly_decay_percent] Relevance score penalty percent per year for for resources without upcoming runs. Only affects results if there is a search term.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async learningResourcesUserSubscriptionCheckList(
      aggregations?: Array<LearningResourcesUserSubscriptionCheckListAggregationsEnum>,
      certification?: boolean | null,
      certification_type?: Array<LearningResourcesUserSubscriptionCheckListCertificationTypeEnum>,
      content_file_score_weight?: number | null,
      course_feature?: Array<string>,
      delivery?: Array<LearningResourcesUserSubscriptionCheckListDeliveryEnum>,
      department?: Array<LearningResourcesUserSubscriptionCheckListDepartmentEnum>,
      dev_mode?: boolean | null,
      free?: boolean | null,
      id?: Array<number>,
      level?: Array<LearningResourcesUserSubscriptionCheckListLevelEnum>,
      limit?: number,
      max_incompleteness_penalty?: number | null,
      min_score?: number | null,
      ocw_topic?: Array<string>,
      offered_by?: Array<LearningResourcesUserSubscriptionCheckListOfferedByEnum>,
      offset?: number,
      platform?: Array<LearningResourcesUserSubscriptionCheckListPlatformEnum>,
      professional?: boolean | null,
      q?: string,
      resource_category?: Array<LearningResourcesUserSubscriptionCheckListResourceCategoryEnum>,
      resource_type?: Array<LearningResourcesUserSubscriptionCheckListResourceTypeEnum>,
      search_mode?: LearningResourcesUserSubscriptionCheckListSearchModeEnum,
      slop?: number | null,
      sortby?: LearningResourcesUserSubscriptionCheckListSortbyEnum,
      source_type?: LearningResourcesUserSubscriptionCheckListSourceTypeEnum,
      topic?: Array<string>,
      yearly_decay_percent?: number | null,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<Array<PercolateQuery>>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.learningResourcesUserSubscriptionCheckList(
          aggregations,
          certification,
          certification_type,
          content_file_score_weight,
          course_feature,
          delivery,
          department,
          dev_mode,
          free,
          id,
          level,
          limit,
          max_incompleteness_penalty,
          min_score,
          ocw_topic,
          offered_by,
          offset,
          platform,
          professional,
          q,
          resource_category,
          resource_type,
          search_mode,
          slop,
          sortby,
          source_type,
          topic,
          yearly_decay_percent,
          options,
        )
      const index = configuration?.serverIndex ?? 0
      const operationBasePath =
        operationServerMap[
          "LearningResourcesUserSubscriptionApi.learningResourcesUserSubscriptionCheckList"
        ]?.[index]?.url
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, operationBasePath || basePath)
    },
    /**
     * View for listing percolate query subscriptions for a user
     * @summary List subscribed queries
     * @param {Array<LearningResourcesUserSubscriptionListAggregationsEnum>} [aggregations] Show resource counts by category
     * @param {boolean | null} [certification] True if the learning resource offers a certificate
     * @param {Array<LearningResourcesUserSubscriptionListCertificationTypeEnum>} [certification_type] The type of certificate               * &#x60;micromasters&#x60; - MicroMasters Credential * &#x60;professional&#x60; - Professional Certificate * &#x60;completion&#x60; - Certificate of Completion * &#x60;none&#x60; - No Certificate
     * @param {number | null} [content_file_score_weight] Score weight for content file data.  1 is the default. 0 means content files are ignored
     * @param {Array<string>} [course_feature] The course feature. Possible options are at api/v1/course_features/
     * @param {Array<LearningResourcesUserSubscriptionListDeliveryEnum>} [delivery] The delivery options in which the learning resource is offered               * &#x60;online&#x60; - Online * &#x60;hybrid&#x60; - Hybrid * &#x60;in_person&#x60; - In person * &#x60;offline&#x60; - Offline
     * @param {Array<LearningResourcesUserSubscriptionListDepartmentEnum>} [department] The department that offers the learning resource               * &#x60;1&#x60; - Civil and Environmental Engineering * &#x60;2&#x60; - Mechanical Engineering * &#x60;3&#x60; - Materials Science and Engineering * &#x60;4&#x60; - Architecture * &#x60;5&#x60; - Chemistry * &#x60;6&#x60; - Electrical Engineering and Computer Science * &#x60;7&#x60; - Biology * &#x60;8&#x60; - Physics * &#x60;9&#x60; - Brain and Cognitive Sciences * &#x60;10&#x60; - Chemical Engineering * &#x60;11&#x60; - Urban Studies and Planning * &#x60;12&#x60; - Earth, Atmospheric, and Planetary Sciences * &#x60;14&#x60; - Economics * &#x60;15&#x60; - Management * &#x60;16&#x60; - Aeronautics and Astronautics * &#x60;17&#x60; - Political Science * &#x60;18&#x60; - Mathematics * &#x60;20&#x60; - Biological Engineering * &#x60;21A&#x60; - Anthropology * &#x60;21G&#x60; - Global Languages * &#x60;21H&#x60; - History * &#x60;21L&#x60; - Literature * &#x60;21M&#x60; - Music and Theater Arts * &#x60;22&#x60; - Nuclear Science and Engineering * &#x60;24&#x60; - Linguistics and Philosophy * &#x60;CC&#x60; - Concourse * &#x60;CMS-W&#x60; - Comparative Media Studies/Writing * &#x60;EC&#x60; - Edgerton Center * &#x60;ES&#x60; - Experimental Study Group * &#x60;ESD&#x60; - Engineering Systems Division * &#x60;HST&#x60; - Medical Engineering and Science * &#x60;IDS&#x60; - Data, Systems, and Society * &#x60;MAS&#x60; - Media Arts and Sciences * &#x60;PE&#x60; - Athletics, Physical Education and Recreation * &#x60;SP&#x60; - Special Programs * &#x60;STS&#x60; - Science, Technology, and Society * &#x60;WGS&#x60; - Women\&#39;s and Gender Studies
     * @param {boolean | null} [dev_mode] If true return raw open search results with score explanations
     * @param {boolean | null} [free]
     * @param {Array<number>} [id] The id value for the learning resource
     * @param {Array<LearningResourcesUserSubscriptionListLevelEnum>} [level]
     * @param {number} [limit] Number of results to return per page
     * @param {number | null} [max_incompleteness_penalty] Maximum score penalty for incomplete OCW courses in percent. An OCW course with completeness &#x3D; 0 will have this score penalty. Partially complete courses have a linear penalty proportional to the degree of incompleteness. Only affects results if there is a search term.
     * @param {number | null} [min_score] Minimum score value a text query result needs to have to be displayed
     * @param {Array<string>} [ocw_topic] The ocw topic name.
     * @param {Array<LearningResourcesUserSubscriptionListOfferedByEnum>} [offered_by] The organization that offers the learning resource               * &#x60;mitx&#x60; - MITx * &#x60;ocw&#x60; - MIT OpenCourseWare * &#x60;bootcamps&#x60; - Bootcamps * &#x60;xpro&#x60; - MIT xPRO * &#x60;mitpe&#x60; - MIT Professional Education * &#x60;see&#x60; - MIT Sloan Executive Education
     * @param {number} [offset] The initial index from which to return the results
     * @param {Array<LearningResourcesUserSubscriptionListPlatformEnum>} [platform] The platform on which the learning resource is offered               * &#x60;edx&#x60; - edX * &#x60;ocw&#x60; - MIT OpenCourseWare * &#x60;oll&#x60; - Open Learning Library * &#x60;mitxonline&#x60; - MITx Online * &#x60;bootcamps&#x60; - Bootcamps * &#x60;xpro&#x60; - MIT xPRO * &#x60;csail&#x60; - CSAIL * &#x60;mitpe&#x60; - MIT Professional Education * &#x60;see&#x60; - MIT Sloan Executive Education * &#x60;scc&#x60; - Schwarzman College of Computing * &#x60;ctl&#x60; - Center for Transportation &amp; Logistics * &#x60;whu&#x60; - WHU * &#x60;susskind&#x60; - Susskind * &#x60;globalalumni&#x60; - Global Alumni * &#x60;simplilearn&#x60; - Simplilearn * &#x60;emeritus&#x60; - Emeritus * &#x60;podcast&#x60; - Podcast * &#x60;youtube&#x60; - YouTube
     * @param {boolean | null} [professional]
     * @param {string} [q] The search text
     * @param {Array<LearningResourcesUserSubscriptionListResourceCategoryEnum>} [resource_category] The category of learning resource               * &#x60;course&#x60; - Course * &#x60;program&#x60; - Program * &#x60;learning_material&#x60; - Learning Material
     * @param {Array<LearningResourcesUserSubscriptionListResourceTypeEnum>} [resource_type] The type of learning resource               * &#x60;course&#x60; - course * &#x60;program&#x60; - program * &#x60;learning_path&#x60; - learning path * &#x60;podcast&#x60; - podcast * &#x60;podcast_episode&#x60; - podcast episode * &#x60;video&#x60; - video * &#x60;video_playlist&#x60; - video playlist
     * @param {LearningResourcesUserSubscriptionListSearchModeEnum} [search_mode] The open search search type for text queries               * &#x60;best_fields&#x60; - best_fields * &#x60;most_fields&#x60; - most_fields * &#x60;phrase&#x60; - phrase  * &#x60;best_fields&#x60; - best_fields * &#x60;most_fields&#x60; - most_fields * &#x60;phrase&#x60; - phrase
     * @param {number | null} [slop] Allowed distance for phrase search
     * @param {LearningResourcesUserSubscriptionListSortbyEnum} [sortby] If the parameter starts with \&#39;-\&#39; the sort is in descending order  * &#x60;featured&#x60; - Featured * &#x60;id&#x60; - Object ID ascending * &#x60;-id&#x60; - Object ID descending * &#x60;readable_id&#x60; - Readable ID ascending * &#x60;-readable_id&#x60; - Readable ID descending * &#x60;last_modified&#x60; - Last Modified Date ascending * &#x60;-last_modified&#x60; - Last Modified Date descending * &#x60;new&#x60; - Newest resources first * &#x60;start_date&#x60; - Start Date ascending * &#x60;-start_date&#x60; - Start Date descending * &#x60;mitcoursenumber&#x60; - MIT course number ascending * &#x60;-mitcoursenumber&#x60; - MIT course number descending * &#x60;views&#x60; - Popularity ascending * &#x60;-views&#x60; - Popularity descending * &#x60;upcoming&#x60; - Next start date ascending
     * @param {Array<string>} [topic] The topic name. To see a list of options go to api/v1/topics/
     * @param {number | null} [yearly_decay_percent] Relevance score penalty percent per year for for resources without upcoming runs. Only affects results if there is a search term.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async learningResourcesUserSubscriptionList(
      aggregations?: Array<LearningResourcesUserSubscriptionListAggregationsEnum>,
      certification?: boolean | null,
      certification_type?: Array<LearningResourcesUserSubscriptionListCertificationTypeEnum>,
      content_file_score_weight?: number | null,
      course_feature?: Array<string>,
      delivery?: Array<LearningResourcesUserSubscriptionListDeliveryEnum>,
      department?: Array<LearningResourcesUserSubscriptionListDepartmentEnum>,
      dev_mode?: boolean | null,
      free?: boolean | null,
      id?: Array<number>,
      level?: Array<LearningResourcesUserSubscriptionListLevelEnum>,
      limit?: number,
      max_incompleteness_penalty?: number | null,
      min_score?: number | null,
      ocw_topic?: Array<string>,
      offered_by?: Array<LearningResourcesUserSubscriptionListOfferedByEnum>,
      offset?: number,
      platform?: Array<LearningResourcesUserSubscriptionListPlatformEnum>,
      professional?: boolean | null,
      q?: string,
      resource_category?: Array<LearningResourcesUserSubscriptionListResourceCategoryEnum>,
      resource_type?: Array<LearningResourcesUserSubscriptionListResourceTypeEnum>,
      search_mode?: LearningResourcesUserSubscriptionListSearchModeEnum,
      slop?: number | null,
      sortby?: LearningResourcesUserSubscriptionListSortbyEnum,
      topic?: Array<string>,
      yearly_decay_percent?: number | null,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<Array<PercolateQuery>>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.learningResourcesUserSubscriptionList(
          aggregations,
          certification,
          certification_type,
          content_file_score_weight,
          course_feature,
          delivery,
          department,
          dev_mode,
          free,
          id,
          level,
          limit,
          max_incompleteness_penalty,
          min_score,
          ocw_topic,
          offered_by,
          offset,
          platform,
          professional,
          q,
          resource_category,
          resource_type,
          search_mode,
          slop,
          sortby,
          topic,
          yearly_decay_percent,
          options,
        )
      const index = configuration?.serverIndex ?? 0
      const operationBasePath =
        operationServerMap[
          "LearningResourcesUserSubscriptionApi.learningResourcesUserSubscriptionList"
        ]?.[index]?.url
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, operationBasePath || basePath)
    },
    /**
     * Subscribe a user to query
     * @summary Subscribe user to query
     * @param {Array<LearningResourcesUserSubscriptionSubscribeCreateAggregationsEnum>} [aggregations] Show resource counts by category
     * @param {boolean | null} [certification] True if the learning resource offers a certificate
     * @param {Array<LearningResourcesUserSubscriptionSubscribeCreateCertificationTypeEnum>} [certification_type] The type of certificate               * &#x60;micromasters&#x60; - MicroMasters Credential * &#x60;professional&#x60; - Professional Certificate * &#x60;completion&#x60; - Certificate of Completion * &#x60;none&#x60; - No Certificate
     * @param {number | null} [content_file_score_weight] Score weight for content file data.  1 is the default. 0 means content files are ignored
     * @param {Array<string>} [course_feature] The course feature. Possible options are at api/v1/course_features/
     * @param {Array<LearningResourcesUserSubscriptionSubscribeCreateDeliveryEnum>} [delivery] The delivery options in which the learning resource is offered               * &#x60;online&#x60; - Online * &#x60;hybrid&#x60; - Hybrid * &#x60;in_person&#x60; - In person * &#x60;offline&#x60; - Offline
     * @param {Array<LearningResourcesUserSubscriptionSubscribeCreateDepartmentEnum>} [department] The department that offers the learning resource               * &#x60;1&#x60; - Civil and Environmental Engineering * &#x60;2&#x60; - Mechanical Engineering * &#x60;3&#x60; - Materials Science and Engineering * &#x60;4&#x60; - Architecture * &#x60;5&#x60; - Chemistry * &#x60;6&#x60; - Electrical Engineering and Computer Science * &#x60;7&#x60; - Biology * &#x60;8&#x60; - Physics * &#x60;9&#x60; - Brain and Cognitive Sciences * &#x60;10&#x60; - Chemical Engineering * &#x60;11&#x60; - Urban Studies and Planning * &#x60;12&#x60; - Earth, Atmospheric, and Planetary Sciences * &#x60;14&#x60; - Economics * &#x60;15&#x60; - Management * &#x60;16&#x60; - Aeronautics and Astronautics * &#x60;17&#x60; - Political Science * &#x60;18&#x60; - Mathematics * &#x60;20&#x60; - Biological Engineering * &#x60;21A&#x60; - Anthropology * &#x60;21G&#x60; - Global Languages * &#x60;21H&#x60; - History * &#x60;21L&#x60; - Literature * &#x60;21M&#x60; - Music and Theater Arts * &#x60;22&#x60; - Nuclear Science and Engineering * &#x60;24&#x60; - Linguistics and Philosophy * &#x60;CC&#x60; - Concourse * &#x60;CMS-W&#x60; - Comparative Media Studies/Writing * &#x60;EC&#x60; - Edgerton Center * &#x60;ES&#x60; - Experimental Study Group * &#x60;ESD&#x60; - Engineering Systems Division * &#x60;HST&#x60; - Medical Engineering and Science * &#x60;IDS&#x60; - Data, Systems, and Society * &#x60;MAS&#x60; - Media Arts and Sciences * &#x60;PE&#x60; - Athletics, Physical Education and Recreation * &#x60;SP&#x60; - Special Programs * &#x60;STS&#x60; - Science, Technology, and Society * &#x60;WGS&#x60; - Women\&#39;s and Gender Studies
     * @param {boolean | null} [dev_mode] If true return raw open search results with score explanations
     * @param {boolean | null} [free]
     * @param {Array<number>} [id] The id value for the learning resource
     * @param {Array<LearningResourcesUserSubscriptionSubscribeCreateLevelEnum>} [level]
     * @param {number} [limit] Number of results to return per page
     * @param {number | null} [max_incompleteness_penalty] Maximum score penalty for incomplete OCW courses in percent. An OCW course with completeness &#x3D; 0 will have this score penalty. Partially complete courses have a linear penalty proportional to the degree of incompleteness. Only affects results if there is a search term.
     * @param {number | null} [min_score] Minimum score value a text query result needs to have to be displayed
     * @param {Array<string>} [ocw_topic] The ocw topic name.
     * @param {Array<LearningResourcesUserSubscriptionSubscribeCreateOfferedByEnum>} [offered_by] The organization that offers the learning resource               * &#x60;mitx&#x60; - MITx * &#x60;ocw&#x60; - MIT OpenCourseWare * &#x60;bootcamps&#x60; - Bootcamps * &#x60;xpro&#x60; - MIT xPRO * &#x60;mitpe&#x60; - MIT Professional Education * &#x60;see&#x60; - MIT Sloan Executive Education
     * @param {number} [offset] The initial index from which to return the results
     * @param {Array<LearningResourcesUserSubscriptionSubscribeCreatePlatformEnum>} [platform] The platform on which the learning resource is offered               * &#x60;edx&#x60; - edX * &#x60;ocw&#x60; - MIT OpenCourseWare * &#x60;oll&#x60; - Open Learning Library * &#x60;mitxonline&#x60; - MITx Online * &#x60;bootcamps&#x60; - Bootcamps * &#x60;xpro&#x60; - MIT xPRO * &#x60;csail&#x60; - CSAIL * &#x60;mitpe&#x60; - MIT Professional Education * &#x60;see&#x60; - MIT Sloan Executive Education * &#x60;scc&#x60; - Schwarzman College of Computing * &#x60;ctl&#x60; - Center for Transportation &amp; Logistics * &#x60;whu&#x60; - WHU * &#x60;susskind&#x60; - Susskind * &#x60;globalalumni&#x60; - Global Alumni * &#x60;simplilearn&#x60; - Simplilearn * &#x60;emeritus&#x60; - Emeritus * &#x60;podcast&#x60; - Podcast * &#x60;youtube&#x60; - YouTube
     * @param {boolean | null} [professional]
     * @param {string} [q] The search text
     * @param {Array<LearningResourcesUserSubscriptionSubscribeCreateResourceCategoryEnum>} [resource_category] The category of learning resource               * &#x60;course&#x60; - Course * &#x60;program&#x60; - Program * &#x60;learning_material&#x60; - Learning Material
     * @param {Array<LearningResourcesUserSubscriptionSubscribeCreateResourceTypeEnum>} [resource_type] The type of learning resource               * &#x60;course&#x60; - course * &#x60;program&#x60; - program * &#x60;learning_path&#x60; - learning path * &#x60;podcast&#x60; - podcast * &#x60;podcast_episode&#x60; - podcast episode * &#x60;video&#x60; - video * &#x60;video_playlist&#x60; - video playlist
     * @param {LearningResourcesUserSubscriptionSubscribeCreateSearchModeEnum} [search_mode] The open search search type for text queries               * &#x60;best_fields&#x60; - best_fields * &#x60;most_fields&#x60; - most_fields * &#x60;phrase&#x60; - phrase  * &#x60;best_fields&#x60; - best_fields * &#x60;most_fields&#x60; - most_fields * &#x60;phrase&#x60; - phrase
     * @param {number | null} [slop] Allowed distance for phrase search
     * @param {LearningResourcesUserSubscriptionSubscribeCreateSortbyEnum} [sortby] If the parameter starts with \&#39;-\&#39; the sort is in descending order  * &#x60;featured&#x60; - Featured * &#x60;id&#x60; - Object ID ascending * &#x60;-id&#x60; - Object ID descending * &#x60;readable_id&#x60; - Readable ID ascending * &#x60;-readable_id&#x60; - Readable ID descending * &#x60;last_modified&#x60; - Last Modified Date ascending * &#x60;-last_modified&#x60; - Last Modified Date descending * &#x60;new&#x60; - Newest resources first * &#x60;start_date&#x60; - Start Date ascending * &#x60;-start_date&#x60; - Start Date descending * &#x60;mitcoursenumber&#x60; - MIT course number ascending * &#x60;-mitcoursenumber&#x60; - MIT course number descending * &#x60;views&#x60; - Popularity ascending * &#x60;-views&#x60; - Popularity descending * &#x60;upcoming&#x60; - Next start date ascending
     * @param {LearningResourcesUserSubscriptionSubscribeCreateSourceTypeEnum} [source_type] The subscription type  * &#x60;search_subscription_type&#x60; - search_subscription_type * &#x60;channel_subscription_type&#x60; - channel_subscription_type
     * @param {Array<string>} [topic] The topic name. To see a list of options go to api/v1/topics/
     * @param {number | null} [yearly_decay_percent] Relevance score penalty percent per year for for resources without upcoming runs. Only affects results if there is a search term.
     * @param {PercolateQuerySubscriptionRequestRequest} [PercolateQuerySubscriptionRequestRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async learningResourcesUserSubscriptionSubscribeCreate(
      aggregations?: Array<LearningResourcesUserSubscriptionSubscribeCreateAggregationsEnum>,
      certification?: boolean | null,
      certification_type?: Array<LearningResourcesUserSubscriptionSubscribeCreateCertificationTypeEnum>,
      content_file_score_weight?: number | null,
      course_feature?: Array<string>,
      delivery?: Array<LearningResourcesUserSubscriptionSubscribeCreateDeliveryEnum>,
      department?: Array<LearningResourcesUserSubscriptionSubscribeCreateDepartmentEnum>,
      dev_mode?: boolean | null,
      free?: boolean | null,
      id?: Array<number>,
      level?: Array<LearningResourcesUserSubscriptionSubscribeCreateLevelEnum>,
      limit?: number,
      max_incompleteness_penalty?: number | null,
      min_score?: number | null,
      ocw_topic?: Array<string>,
      offered_by?: Array<LearningResourcesUserSubscriptionSubscribeCreateOfferedByEnum>,
      offset?: number,
      platform?: Array<LearningResourcesUserSubscriptionSubscribeCreatePlatformEnum>,
      professional?: boolean | null,
      q?: string,
      resource_category?: Array<LearningResourcesUserSubscriptionSubscribeCreateResourceCategoryEnum>,
      resource_type?: Array<LearningResourcesUserSubscriptionSubscribeCreateResourceTypeEnum>,
      search_mode?: LearningResourcesUserSubscriptionSubscribeCreateSearchModeEnum,
      slop?: number | null,
      sortby?: LearningResourcesUserSubscriptionSubscribeCreateSortbyEnum,
      source_type?: LearningResourcesUserSubscriptionSubscribeCreateSourceTypeEnum,
      topic?: Array<string>,
      yearly_decay_percent?: number | null,
      PercolateQuerySubscriptionRequestRequest?: PercolateQuerySubscriptionRequestRequest,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<PercolateQuery>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.learningResourcesUserSubscriptionSubscribeCreate(
          aggregations,
          certification,
          certification_type,
          content_file_score_weight,
          course_feature,
          delivery,
          department,
          dev_mode,
          free,
          id,
          level,
          limit,
          max_incompleteness_penalty,
          min_score,
          ocw_topic,
          offered_by,
          offset,
          platform,
          professional,
          q,
          resource_category,
          resource_type,
          search_mode,
          slop,
          sortby,
          source_type,
          topic,
          yearly_decay_percent,
          PercolateQuerySubscriptionRequestRequest,
          options,
        )
      const index = configuration?.serverIndex ?? 0
      const operationBasePath =
        operationServerMap[
          "LearningResourcesUserSubscriptionApi.learningResourcesUserSubscriptionSubscribeCreate"
        ]?.[index]?.url
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, operationBasePath || basePath)
    },
    /**
     * Unsubscribe a user from a query  Args: pk (integer): The id of the query  Returns: PercolateQuerySerializer: The percolate query
     * @summary Unsubscribe user from query
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async learningResourcesUserSubscriptionUnsubscribeDestroy(
      id: number,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.learningResourcesUserSubscriptionUnsubscribeDestroy(
          id,
          options,
        )
      const index = configuration?.serverIndex ?? 0
      const operationBasePath =
        operationServerMap[
          "LearningResourcesUserSubscriptionApi.learningResourcesUserSubscriptionUnsubscribeDestroy"
        ]?.[index]?.url
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, operationBasePath || basePath)
    },
  }
}

/**
 * LearningResourcesUserSubscriptionApi - factory interface
 * @export
 */
export const LearningResourcesUserSubscriptionApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = LearningResourcesUserSubscriptionApiFp(configuration)
  return {
    /**
     * View for listing percolate query subscriptions for a user
     * @summary Check if a user is subscribed to a specific query
     * @param {LearningResourcesUserSubscriptionApiLearningResourcesUserSubscriptionCheckListRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    learningResourcesUserSubscriptionCheckList(
      requestParameters: LearningResourcesUserSubscriptionApiLearningResourcesUserSubscriptionCheckListRequest = {},
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<Array<PercolateQuery>> {
      return localVarFp
        .learningResourcesUserSubscriptionCheckList(
          requestParameters.aggregations,
          requestParameters.certification,
          requestParameters.certification_type,
          requestParameters.content_file_score_weight,
          requestParameters.course_feature,
          requestParameters.delivery,
          requestParameters.department,
          requestParameters.dev_mode,
          requestParameters.free,
          requestParameters.id,
          requestParameters.level,
          requestParameters.limit,
          requestParameters.max_incompleteness_penalty,
          requestParameters.min_score,
          requestParameters.ocw_topic,
          requestParameters.offered_by,
          requestParameters.offset,
          requestParameters.platform,
          requestParameters.professional,
          requestParameters.q,
          requestParameters.resource_category,
          requestParameters.resource_type,
          requestParameters.search_mode,
          requestParameters.slop,
          requestParameters.sortby,
          requestParameters.source_type,
          requestParameters.topic,
          requestParameters.yearly_decay_percent,
          options,
        )
        .then((request) => request(axios, basePath))
    },
    /**
     * View for listing percolate query subscriptions for a user
     * @summary List subscribed queries
     * @param {LearningResourcesUserSubscriptionApiLearningResourcesUserSubscriptionListRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    learningResourcesUserSubscriptionList(
      requestParameters: LearningResourcesUserSubscriptionApiLearningResourcesUserSubscriptionListRequest = {},
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<Array<PercolateQuery>> {
      return localVarFp
        .learningResourcesUserSubscriptionList(
          requestParameters.aggregations,
          requestParameters.certification,
          requestParameters.certification_type,
          requestParameters.content_file_score_weight,
          requestParameters.course_feature,
          requestParameters.delivery,
          requestParameters.department,
          requestParameters.dev_mode,
          requestParameters.free,
          requestParameters.id,
          requestParameters.level,
          requestParameters.limit,
          requestParameters.max_incompleteness_penalty,
          requestParameters.min_score,
          requestParameters.ocw_topic,
          requestParameters.offered_by,
          requestParameters.offset,
          requestParameters.platform,
          requestParameters.professional,
          requestParameters.q,
          requestParameters.resource_category,
          requestParameters.resource_type,
          requestParameters.search_mode,
          requestParameters.slop,
          requestParameters.sortby,
          requestParameters.topic,
          requestParameters.yearly_decay_percent,
          options,
        )
        .then((request) => request(axios, basePath))
    },
    /**
     * Subscribe a user to query
     * @summary Subscribe user to query
     * @param {LearningResourcesUserSubscriptionApiLearningResourcesUserSubscriptionSubscribeCreateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    learningResourcesUserSubscriptionSubscribeCreate(
      requestParameters: LearningResourcesUserSubscriptionApiLearningResourcesUserSubscriptionSubscribeCreateRequest = {},
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<PercolateQuery> {
      return localVarFp
        .learningResourcesUserSubscriptionSubscribeCreate(
          requestParameters.aggregations,
          requestParameters.certification,
          requestParameters.certification_type,
          requestParameters.content_file_score_weight,
          requestParameters.course_feature,
          requestParameters.delivery,
          requestParameters.department,
          requestParameters.dev_mode,
          requestParameters.free,
          requestParameters.id,
          requestParameters.level,
          requestParameters.limit,
          requestParameters.max_incompleteness_penalty,
          requestParameters.min_score,
          requestParameters.ocw_topic,
          requestParameters.offered_by,
          requestParameters.offset,
          requestParameters.platform,
          requestParameters.professional,
          requestParameters.q,
          requestParameters.resource_category,
          requestParameters.resource_type,
          requestParameters.search_mode,
          requestParameters.slop,
          requestParameters.sortby,
          requestParameters.source_type,
          requestParameters.topic,
          requestParameters.yearly_decay_percent,
          requestParameters.PercolateQuerySubscriptionRequestRequest,
          options,
        )
        .then((request) => request(axios, basePath))
    },
    /**
     * Unsubscribe a user from a query  Args: pk (integer): The id of the query  Returns: PercolateQuerySerializer: The percolate query
     * @summary Unsubscribe user from query
     * @param {LearningResourcesUserSubscriptionApiLearningResourcesUserSubscriptionUnsubscribeDestroyRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    learningResourcesUserSubscriptionUnsubscribeDestroy(
      requestParameters: LearningResourcesUserSubscriptionApiLearningResourcesUserSubscriptionUnsubscribeDestroyRequest,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<void> {
      return localVarFp
        .learningResourcesUserSubscriptionUnsubscribeDestroy(
          requestParameters.id,
          options,
        )
        .then((request) => request(axios, basePath))
    },
  }
}

/**
 * Request parameters for learningResourcesUserSubscriptionCheckList operation in LearningResourcesUserSubscriptionApi.
 * @export
 * @interface LearningResourcesUserSubscriptionApiLearningResourcesUserSubscriptionCheckListRequest
 */
export interface LearningResourcesUserSubscriptionApiLearningResourcesUserSubscriptionCheckListRequest {
  /**
   * Show resource counts by category
   * @type {Array<'resource_type' | 'certification' | 'certification_type' | 'offered_by' | 'platform' | 'topic' | 'department' | 'level' | 'course_feature' | 'professional' | 'free' | 'delivery' | 'resource_category'>}
   * @memberof LearningResourcesUserSubscriptionApiLearningResourcesUserSubscriptionCheckList
   */
  readonly aggregations?: Array<LearningResourcesUserSubscriptionCheckListAggregationsEnum>

  /**
   * True if the learning resource offers a certificate
   * @type {boolean}
   * @memberof LearningResourcesUserSubscriptionApiLearningResourcesUserSubscriptionCheckList
   */
  readonly certification?: boolean | null

  /**
   * The type of certificate               * &#x60;micromasters&#x60; - MicroMasters Credential * &#x60;professional&#x60; - Professional Certificate * &#x60;completion&#x60; - Certificate of Completion * &#x60;none&#x60; - No Certificate
   * @type {Array<'micromasters' | 'professional' | 'completion' | 'none'>}
   * @memberof LearningResourcesUserSubscriptionApiLearningResourcesUserSubscriptionCheckList
   */
  readonly certification_type?: Array<LearningResourcesUserSubscriptionCheckListCertificationTypeEnum>

  /**
   * Score weight for content file data.  1 is the default. 0 means content files are ignored
   * @type {number}
   * @memberof LearningResourcesUserSubscriptionApiLearningResourcesUserSubscriptionCheckList
   */
  readonly content_file_score_weight?: number | null

  /**
   * The course feature. Possible options are at api/v1/course_features/
   * @type {Array<string>}
   * @memberof LearningResourcesUserSubscriptionApiLearningResourcesUserSubscriptionCheckList
   */
  readonly course_feature?: Array<string>

  /**
   * The delivery options in which the learning resource is offered               * &#x60;online&#x60; - Online * &#x60;hybrid&#x60; - Hybrid * &#x60;in_person&#x60; - In person * &#x60;offline&#x60; - Offline
   * @type {Array<'online' | 'hybrid' | 'in_person' | 'offline'>}
   * @memberof LearningResourcesUserSubscriptionApiLearningResourcesUserSubscriptionCheckList
   */
  readonly delivery?: Array<LearningResourcesUserSubscriptionCheckListDeliveryEnum>

  /**
   * The department that offers the learning resource               * &#x60;1&#x60; - Civil and Environmental Engineering * &#x60;2&#x60; - Mechanical Engineering * &#x60;3&#x60; - Materials Science and Engineering * &#x60;4&#x60; - Architecture * &#x60;5&#x60; - Chemistry * &#x60;6&#x60; - Electrical Engineering and Computer Science * &#x60;7&#x60; - Biology * &#x60;8&#x60; - Physics * &#x60;9&#x60; - Brain and Cognitive Sciences * &#x60;10&#x60; - Chemical Engineering * &#x60;11&#x60; - Urban Studies and Planning * &#x60;12&#x60; - Earth, Atmospheric, and Planetary Sciences * &#x60;14&#x60; - Economics * &#x60;15&#x60; - Management * &#x60;16&#x60; - Aeronautics and Astronautics * &#x60;17&#x60; - Political Science * &#x60;18&#x60; - Mathematics * &#x60;20&#x60; - Biological Engineering * &#x60;21A&#x60; - Anthropology * &#x60;21G&#x60; - Global Languages * &#x60;21H&#x60; - History * &#x60;21L&#x60; - Literature * &#x60;21M&#x60; - Music and Theater Arts * &#x60;22&#x60; - Nuclear Science and Engineering * &#x60;24&#x60; - Linguistics and Philosophy * &#x60;CC&#x60; - Concourse * &#x60;CMS-W&#x60; - Comparative Media Studies/Writing * &#x60;EC&#x60; - Edgerton Center * &#x60;ES&#x60; - Experimental Study Group * &#x60;ESD&#x60; - Engineering Systems Division * &#x60;HST&#x60; - Medical Engineering and Science * &#x60;IDS&#x60; - Data, Systems, and Society * &#x60;MAS&#x60; - Media Arts and Sciences * &#x60;PE&#x60; - Athletics, Physical Education and Recreation * &#x60;SP&#x60; - Special Programs * &#x60;STS&#x60; - Science, Technology, and Society * &#x60;WGS&#x60; - Women\&#39;s and Gender Studies
   * @type {Array<'1' | '2' | '3' | '4' | '5' | '6' | '7' | '8' | '9' | '10' | '11' | '12' | '14' | '15' | '16' | '17' | '18' | '20' | '21A' | '21G' | '21H' | '21L' | '21M' | '22' | '24' | 'CC' | 'CMS-W' | 'EC' | 'ES' | 'ESD' | 'HST' | 'IDS' | 'MAS' | 'PE' | 'SP' | 'STS' | 'WGS'>}
   * @memberof LearningResourcesUserSubscriptionApiLearningResourcesUserSubscriptionCheckList
   */
  readonly department?: Array<LearningResourcesUserSubscriptionCheckListDepartmentEnum>

  /**
   * If true return raw open search results with score explanations
   * @type {boolean}
   * @memberof LearningResourcesUserSubscriptionApiLearningResourcesUserSubscriptionCheckList
   */
  readonly dev_mode?: boolean | null

  /**
   *
   * @type {boolean}
   * @memberof LearningResourcesUserSubscriptionApiLearningResourcesUserSubscriptionCheckList
   */
  readonly free?: boolean | null

  /**
   * The id value for the learning resource
   * @type {Array<number>}
   * @memberof LearningResourcesUserSubscriptionApiLearningResourcesUserSubscriptionCheckList
   */
  readonly id?: Array<number>

  /**
   *
   * @type {Array<'undergraduate' | 'graduate' | 'high_school' | 'noncredit' | 'advanced' | 'intermediate' | 'introductory'>}
   * @memberof LearningResourcesUserSubscriptionApiLearningResourcesUserSubscriptionCheckList
   */
  readonly level?: Array<LearningResourcesUserSubscriptionCheckListLevelEnum>

  /**
   * Number of results to return per page
   * @type {number}
   * @memberof LearningResourcesUserSubscriptionApiLearningResourcesUserSubscriptionCheckList
   */
  readonly limit?: number

  /**
   * Maximum score penalty for incomplete OCW courses in percent. An OCW course with completeness &#x3D; 0 will have this score penalty. Partially complete courses have a linear penalty proportional to the degree of incompleteness. Only affects results if there is a search term.
   * @type {number}
   * @memberof LearningResourcesUserSubscriptionApiLearningResourcesUserSubscriptionCheckList
   */
  readonly max_incompleteness_penalty?: number | null

  /**
   * Minimum score value a text query result needs to have to be displayed
   * @type {number}
   * @memberof LearningResourcesUserSubscriptionApiLearningResourcesUserSubscriptionCheckList
   */
  readonly min_score?: number | null

  /**
   * The ocw topic name.
   * @type {Array<string>}
   * @memberof LearningResourcesUserSubscriptionApiLearningResourcesUserSubscriptionCheckList
   */
  readonly ocw_topic?: Array<string>

  /**
   * The organization that offers the learning resource               * &#x60;mitx&#x60; - MITx * &#x60;ocw&#x60; - MIT OpenCourseWare * &#x60;bootcamps&#x60; - Bootcamps * &#x60;xpro&#x60; - MIT xPRO * &#x60;mitpe&#x60; - MIT Professional Education * &#x60;see&#x60; - MIT Sloan Executive Education
   * @type {Array<'mitx' | 'ocw' | 'bootcamps' | 'xpro' | 'mitpe' | 'see'>}
   * @memberof LearningResourcesUserSubscriptionApiLearningResourcesUserSubscriptionCheckList
   */
  readonly offered_by?: Array<LearningResourcesUserSubscriptionCheckListOfferedByEnum>

  /**
   * The initial index from which to return the results
   * @type {number}
   * @memberof LearningResourcesUserSubscriptionApiLearningResourcesUserSubscriptionCheckList
   */
  readonly offset?: number

  /**
   * The platform on which the learning resource is offered               * &#x60;edx&#x60; - edX * &#x60;ocw&#x60; - MIT OpenCourseWare * &#x60;oll&#x60; - Open Learning Library * &#x60;mitxonline&#x60; - MITx Online * &#x60;bootcamps&#x60; - Bootcamps * &#x60;xpro&#x60; - MIT xPRO * &#x60;csail&#x60; - CSAIL * &#x60;mitpe&#x60; - MIT Professional Education * &#x60;see&#x60; - MIT Sloan Executive Education * &#x60;scc&#x60; - Schwarzman College of Computing * &#x60;ctl&#x60; - Center for Transportation &amp; Logistics * &#x60;whu&#x60; - WHU * &#x60;susskind&#x60; - Susskind * &#x60;globalalumni&#x60; - Global Alumni * &#x60;simplilearn&#x60; - Simplilearn * &#x60;emeritus&#x60; - Emeritus * &#x60;podcast&#x60; - Podcast * &#x60;youtube&#x60; - YouTube
   * @type {Array<'edx' | 'ocw' | 'oll' | 'mitxonline' | 'bootcamps' | 'xpro' | 'csail' | 'mitpe' | 'see' | 'scc' | 'ctl' | 'whu' | 'susskind' | 'globalalumni' | 'simplilearn' | 'emeritus' | 'podcast' | 'youtube'>}
   * @memberof LearningResourcesUserSubscriptionApiLearningResourcesUserSubscriptionCheckList
   */
  readonly platform?: Array<LearningResourcesUserSubscriptionCheckListPlatformEnum>

  /**
   *
   * @type {boolean}
   * @memberof LearningResourcesUserSubscriptionApiLearningResourcesUserSubscriptionCheckList
   */
  readonly professional?: boolean | null

  /**
   * The search text
   * @type {string}
   * @memberof LearningResourcesUserSubscriptionApiLearningResourcesUserSubscriptionCheckList
   */
  readonly q?: string

  /**
   * The category of learning resource               * &#x60;course&#x60; - Course * &#x60;program&#x60; - Program * &#x60;learning_material&#x60; - Learning Material
   * @type {Array<'course' | 'program' | 'learning_material'>}
   * @memberof LearningResourcesUserSubscriptionApiLearningResourcesUserSubscriptionCheckList
   */
  readonly resource_category?: Array<LearningResourcesUserSubscriptionCheckListResourceCategoryEnum>

  /**
   * The type of learning resource               * &#x60;course&#x60; - course * &#x60;program&#x60; - program * &#x60;learning_path&#x60; - learning path * &#x60;podcast&#x60; - podcast * &#x60;podcast_episode&#x60; - podcast episode * &#x60;video&#x60; - video * &#x60;video_playlist&#x60; - video playlist
   * @type {Array<'course' | 'program' | 'learning_path' | 'podcast' | 'podcast_episode' | 'video' | 'video_playlist'>}
   * @memberof LearningResourcesUserSubscriptionApiLearningResourcesUserSubscriptionCheckList
   */
  readonly resource_type?: Array<LearningResourcesUserSubscriptionCheckListResourceTypeEnum>

  /**
   * The open search search type for text queries               * &#x60;best_fields&#x60; - best_fields * &#x60;most_fields&#x60; - most_fields * &#x60;phrase&#x60; - phrase  * &#x60;best_fields&#x60; - best_fields * &#x60;most_fields&#x60; - most_fields * &#x60;phrase&#x60; - phrase
   * @type {'best_fields' | 'most_fields' | 'phrase'}
   * @memberof LearningResourcesUserSubscriptionApiLearningResourcesUserSubscriptionCheckList
   */
  readonly search_mode?: LearningResourcesUserSubscriptionCheckListSearchModeEnum

  /**
   * Allowed distance for phrase search
   * @type {number}
   * @memberof LearningResourcesUserSubscriptionApiLearningResourcesUserSubscriptionCheckList
   */
  readonly slop?: number | null

  /**
   * If the parameter starts with \&#39;-\&#39; the sort is in descending order  * &#x60;featured&#x60; - Featured * &#x60;id&#x60; - Object ID ascending * &#x60;-id&#x60; - Object ID descending * &#x60;readable_id&#x60; - Readable ID ascending * &#x60;-readable_id&#x60; - Readable ID descending * &#x60;last_modified&#x60; - Last Modified Date ascending * &#x60;-last_modified&#x60; - Last Modified Date descending * &#x60;new&#x60; - Newest resources first * &#x60;start_date&#x60; - Start Date ascending * &#x60;-start_date&#x60; - Start Date descending * &#x60;mitcoursenumber&#x60; - MIT course number ascending * &#x60;-mitcoursenumber&#x60; - MIT course number descending * &#x60;views&#x60; - Popularity ascending * &#x60;-views&#x60; - Popularity descending * &#x60;upcoming&#x60; - Next start date ascending
   * @type {'featured' | 'id' | '-id' | 'readable_id' | '-readable_id' | 'last_modified' | '-last_modified' | 'new' | 'start_date' | '-start_date' | 'mitcoursenumber' | '-mitcoursenumber' | 'views' | '-views' | 'upcoming'}
   * @memberof LearningResourcesUserSubscriptionApiLearningResourcesUserSubscriptionCheckList
   */
  readonly sortby?: LearningResourcesUserSubscriptionCheckListSortbyEnum

  /**
   * The subscription type  * &#x60;search_subscription_type&#x60; - search_subscription_type * &#x60;channel_subscription_type&#x60; - channel_subscription_type
   * @type {'search_subscription_type' | 'channel_subscription_type'}
   * @memberof LearningResourcesUserSubscriptionApiLearningResourcesUserSubscriptionCheckList
   */
  readonly source_type?: LearningResourcesUserSubscriptionCheckListSourceTypeEnum

  /**
   * The topic name. To see a list of options go to api/v1/topics/
   * @type {Array<string>}
   * @memberof LearningResourcesUserSubscriptionApiLearningResourcesUserSubscriptionCheckList
   */
  readonly topic?: Array<string>

  /**
   * Relevance score penalty percent per year for for resources without upcoming runs. Only affects results if there is a search term.
   * @type {number}
   * @memberof LearningResourcesUserSubscriptionApiLearningResourcesUserSubscriptionCheckList
   */
  readonly yearly_decay_percent?: number | null
}

/**
 * Request parameters for learningResourcesUserSubscriptionList operation in LearningResourcesUserSubscriptionApi.
 * @export
 * @interface LearningResourcesUserSubscriptionApiLearningResourcesUserSubscriptionListRequest
 */
export interface LearningResourcesUserSubscriptionApiLearningResourcesUserSubscriptionListRequest {
  /**
   * Show resource counts by category
   * @type {Array<'resource_type' | 'certification' | 'certification_type' | 'offered_by' | 'platform' | 'topic' | 'department' | 'level' | 'course_feature' | 'professional' | 'free' | 'delivery' | 'resource_category'>}
   * @memberof LearningResourcesUserSubscriptionApiLearningResourcesUserSubscriptionList
   */
  readonly aggregations?: Array<LearningResourcesUserSubscriptionListAggregationsEnum>

  /**
   * True if the learning resource offers a certificate
   * @type {boolean}
   * @memberof LearningResourcesUserSubscriptionApiLearningResourcesUserSubscriptionList
   */
  readonly certification?: boolean | null

  /**
   * The type of certificate               * &#x60;micromasters&#x60; - MicroMasters Credential * &#x60;professional&#x60; - Professional Certificate * &#x60;completion&#x60; - Certificate of Completion * &#x60;none&#x60; - No Certificate
   * @type {Array<'micromasters' | 'professional' | 'completion' | 'none'>}
   * @memberof LearningResourcesUserSubscriptionApiLearningResourcesUserSubscriptionList
   */
  readonly certification_type?: Array<LearningResourcesUserSubscriptionListCertificationTypeEnum>

  /**
   * Score weight for content file data.  1 is the default. 0 means content files are ignored
   * @type {number}
   * @memberof LearningResourcesUserSubscriptionApiLearningResourcesUserSubscriptionList
   */
  readonly content_file_score_weight?: number | null

  /**
   * The course feature. Possible options are at api/v1/course_features/
   * @type {Array<string>}
   * @memberof LearningResourcesUserSubscriptionApiLearningResourcesUserSubscriptionList
   */
  readonly course_feature?: Array<string>

  /**
   * The delivery options in which the learning resource is offered               * &#x60;online&#x60; - Online * &#x60;hybrid&#x60; - Hybrid * &#x60;in_person&#x60; - In person * &#x60;offline&#x60; - Offline
   * @type {Array<'online' | 'hybrid' | 'in_person' | 'offline'>}
   * @memberof LearningResourcesUserSubscriptionApiLearningResourcesUserSubscriptionList
   */
  readonly delivery?: Array<LearningResourcesUserSubscriptionListDeliveryEnum>

  /**
   * The department that offers the learning resource               * &#x60;1&#x60; - Civil and Environmental Engineering * &#x60;2&#x60; - Mechanical Engineering * &#x60;3&#x60; - Materials Science and Engineering * &#x60;4&#x60; - Architecture * &#x60;5&#x60; - Chemistry * &#x60;6&#x60; - Electrical Engineering and Computer Science * &#x60;7&#x60; - Biology * &#x60;8&#x60; - Physics * &#x60;9&#x60; - Brain and Cognitive Sciences * &#x60;10&#x60; - Chemical Engineering * &#x60;11&#x60; - Urban Studies and Planning * &#x60;12&#x60; - Earth, Atmospheric, and Planetary Sciences * &#x60;14&#x60; - Economics * &#x60;15&#x60; - Management * &#x60;16&#x60; - Aeronautics and Astronautics * &#x60;17&#x60; - Political Science * &#x60;18&#x60; - Mathematics * &#x60;20&#x60; - Biological Engineering * &#x60;21A&#x60; - Anthropology * &#x60;21G&#x60; - Global Languages * &#x60;21H&#x60; - History * &#x60;21L&#x60; - Literature * &#x60;21M&#x60; - Music and Theater Arts * &#x60;22&#x60; - Nuclear Science and Engineering * &#x60;24&#x60; - Linguistics and Philosophy * &#x60;CC&#x60; - Concourse * &#x60;CMS-W&#x60; - Comparative Media Studies/Writing * &#x60;EC&#x60; - Edgerton Center * &#x60;ES&#x60; - Experimental Study Group * &#x60;ESD&#x60; - Engineering Systems Division * &#x60;HST&#x60; - Medical Engineering and Science * &#x60;IDS&#x60; - Data, Systems, and Society * &#x60;MAS&#x60; - Media Arts and Sciences * &#x60;PE&#x60; - Athletics, Physical Education and Recreation * &#x60;SP&#x60; - Special Programs * &#x60;STS&#x60; - Science, Technology, and Society * &#x60;WGS&#x60; - Women\&#39;s and Gender Studies
   * @type {Array<'1' | '2' | '3' | '4' | '5' | '6' | '7' | '8' | '9' | '10' | '11' | '12' | '14' | '15' | '16' | '17' | '18' | '20' | '21A' | '21G' | '21H' | '21L' | '21M' | '22' | '24' | 'CC' | 'CMS-W' | 'EC' | 'ES' | 'ESD' | 'HST' | 'IDS' | 'MAS' | 'PE' | 'SP' | 'STS' | 'WGS'>}
   * @memberof LearningResourcesUserSubscriptionApiLearningResourcesUserSubscriptionList
   */
  readonly department?: Array<LearningResourcesUserSubscriptionListDepartmentEnum>

  /**
   * If true return raw open search results with score explanations
   * @type {boolean}
   * @memberof LearningResourcesUserSubscriptionApiLearningResourcesUserSubscriptionList
   */
  readonly dev_mode?: boolean | null

  /**
   *
   * @type {boolean}
   * @memberof LearningResourcesUserSubscriptionApiLearningResourcesUserSubscriptionList
   */
  readonly free?: boolean | null

  /**
   * The id value for the learning resource
   * @type {Array<number>}
   * @memberof LearningResourcesUserSubscriptionApiLearningResourcesUserSubscriptionList
   */
  readonly id?: Array<number>

  /**
   *
   * @type {Array<'undergraduate' | 'graduate' | 'high_school' | 'noncredit' | 'advanced' | 'intermediate' | 'introductory'>}
   * @memberof LearningResourcesUserSubscriptionApiLearningResourcesUserSubscriptionList
   */
  readonly level?: Array<LearningResourcesUserSubscriptionListLevelEnum>

  /**
   * Number of results to return per page
   * @type {number}
   * @memberof LearningResourcesUserSubscriptionApiLearningResourcesUserSubscriptionList
   */
  readonly limit?: number

  /**
   * Maximum score penalty for incomplete OCW courses in percent. An OCW course with completeness &#x3D; 0 will have this score penalty. Partially complete courses have a linear penalty proportional to the degree of incompleteness. Only affects results if there is a search term.
   * @type {number}
   * @memberof LearningResourcesUserSubscriptionApiLearningResourcesUserSubscriptionList
   */
  readonly max_incompleteness_penalty?: number | null

  /**
   * Minimum score value a text query result needs to have to be displayed
   * @type {number}
   * @memberof LearningResourcesUserSubscriptionApiLearningResourcesUserSubscriptionList
   */
  readonly min_score?: number | null

  /**
   * The ocw topic name.
   * @type {Array<string>}
   * @memberof LearningResourcesUserSubscriptionApiLearningResourcesUserSubscriptionList
   */
  readonly ocw_topic?: Array<string>

  /**
   * The organization that offers the learning resource               * &#x60;mitx&#x60; - MITx * &#x60;ocw&#x60; - MIT OpenCourseWare * &#x60;bootcamps&#x60; - Bootcamps * &#x60;xpro&#x60; - MIT xPRO * &#x60;mitpe&#x60; - MIT Professional Education * &#x60;see&#x60; - MIT Sloan Executive Education
   * @type {Array<'mitx' | 'ocw' | 'bootcamps' | 'xpro' | 'mitpe' | 'see'>}
   * @memberof LearningResourcesUserSubscriptionApiLearningResourcesUserSubscriptionList
   */
  readonly offered_by?: Array<LearningResourcesUserSubscriptionListOfferedByEnum>

  /**
   * The initial index from which to return the results
   * @type {number}
   * @memberof LearningResourcesUserSubscriptionApiLearningResourcesUserSubscriptionList
   */
  readonly offset?: number

  /**
   * The platform on which the learning resource is offered               * &#x60;edx&#x60; - edX * &#x60;ocw&#x60; - MIT OpenCourseWare * &#x60;oll&#x60; - Open Learning Library * &#x60;mitxonline&#x60; - MITx Online * &#x60;bootcamps&#x60; - Bootcamps * &#x60;xpro&#x60; - MIT xPRO * &#x60;csail&#x60; - CSAIL * &#x60;mitpe&#x60; - MIT Professional Education * &#x60;see&#x60; - MIT Sloan Executive Education * &#x60;scc&#x60; - Schwarzman College of Computing * &#x60;ctl&#x60; - Center for Transportation &amp; Logistics * &#x60;whu&#x60; - WHU * &#x60;susskind&#x60; - Susskind * &#x60;globalalumni&#x60; - Global Alumni * &#x60;simplilearn&#x60; - Simplilearn * &#x60;emeritus&#x60; - Emeritus * &#x60;podcast&#x60; - Podcast * &#x60;youtube&#x60; - YouTube
   * @type {Array<'edx' | 'ocw' | 'oll' | 'mitxonline' | 'bootcamps' | 'xpro' | 'csail' | 'mitpe' | 'see' | 'scc' | 'ctl' | 'whu' | 'susskind' | 'globalalumni' | 'simplilearn' | 'emeritus' | 'podcast' | 'youtube'>}
   * @memberof LearningResourcesUserSubscriptionApiLearningResourcesUserSubscriptionList
   */
  readonly platform?: Array<LearningResourcesUserSubscriptionListPlatformEnum>

  /**
   *
   * @type {boolean}
   * @memberof LearningResourcesUserSubscriptionApiLearningResourcesUserSubscriptionList
   */
  readonly professional?: boolean | null

  /**
   * The search text
   * @type {string}
   * @memberof LearningResourcesUserSubscriptionApiLearningResourcesUserSubscriptionList
   */
  readonly q?: string

  /**
   * The category of learning resource               * &#x60;course&#x60; - Course * &#x60;program&#x60; - Program * &#x60;learning_material&#x60; - Learning Material
   * @type {Array<'course' | 'program' | 'learning_material'>}
   * @memberof LearningResourcesUserSubscriptionApiLearningResourcesUserSubscriptionList
   */
  readonly resource_category?: Array<LearningResourcesUserSubscriptionListResourceCategoryEnum>

  /**
   * The type of learning resource               * &#x60;course&#x60; - course * &#x60;program&#x60; - program * &#x60;learning_path&#x60; - learning path * &#x60;podcast&#x60; - podcast * &#x60;podcast_episode&#x60; - podcast episode * &#x60;video&#x60; - video * &#x60;video_playlist&#x60; - video playlist
   * @type {Array<'course' | 'program' | 'learning_path' | 'podcast' | 'podcast_episode' | 'video' | 'video_playlist'>}
   * @memberof LearningResourcesUserSubscriptionApiLearningResourcesUserSubscriptionList
   */
  readonly resource_type?: Array<LearningResourcesUserSubscriptionListResourceTypeEnum>

  /**
   * The open search search type for text queries               * &#x60;best_fields&#x60; - best_fields * &#x60;most_fields&#x60; - most_fields * &#x60;phrase&#x60; - phrase  * &#x60;best_fields&#x60; - best_fields * &#x60;most_fields&#x60; - most_fields * &#x60;phrase&#x60; - phrase
   * @type {'best_fields' | 'most_fields' | 'phrase'}
   * @memberof LearningResourcesUserSubscriptionApiLearningResourcesUserSubscriptionList
   */
  readonly search_mode?: LearningResourcesUserSubscriptionListSearchModeEnum

  /**
   * Allowed distance for phrase search
   * @type {number}
   * @memberof LearningResourcesUserSubscriptionApiLearningResourcesUserSubscriptionList
   */
  readonly slop?: number | null

  /**
   * If the parameter starts with \&#39;-\&#39; the sort is in descending order  * &#x60;featured&#x60; - Featured * &#x60;id&#x60; - Object ID ascending * &#x60;-id&#x60; - Object ID descending * &#x60;readable_id&#x60; - Readable ID ascending * &#x60;-readable_id&#x60; - Readable ID descending * &#x60;last_modified&#x60; - Last Modified Date ascending * &#x60;-last_modified&#x60; - Last Modified Date descending * &#x60;new&#x60; - Newest resources first * &#x60;start_date&#x60; - Start Date ascending * &#x60;-start_date&#x60; - Start Date descending * &#x60;mitcoursenumber&#x60; - MIT course number ascending * &#x60;-mitcoursenumber&#x60; - MIT course number descending * &#x60;views&#x60; - Popularity ascending * &#x60;-views&#x60; - Popularity descending * &#x60;upcoming&#x60; - Next start date ascending
   * @type {'featured' | 'id' | '-id' | 'readable_id' | '-readable_id' | 'last_modified' | '-last_modified' | 'new' | 'start_date' | '-start_date' | 'mitcoursenumber' | '-mitcoursenumber' | 'views' | '-views' | 'upcoming'}
   * @memberof LearningResourcesUserSubscriptionApiLearningResourcesUserSubscriptionList
   */
  readonly sortby?: LearningResourcesUserSubscriptionListSortbyEnum

  /**
   * The topic name. To see a list of options go to api/v1/topics/
   * @type {Array<string>}
   * @memberof LearningResourcesUserSubscriptionApiLearningResourcesUserSubscriptionList
   */
  readonly topic?: Array<string>

  /**
   * Relevance score penalty percent per year for for resources without upcoming runs. Only affects results if there is a search term.
   * @type {number}
   * @memberof LearningResourcesUserSubscriptionApiLearningResourcesUserSubscriptionList
   */
  readonly yearly_decay_percent?: number | null
}

/**
 * Request parameters for learningResourcesUserSubscriptionSubscribeCreate operation in LearningResourcesUserSubscriptionApi.
 * @export
 * @interface LearningResourcesUserSubscriptionApiLearningResourcesUserSubscriptionSubscribeCreateRequest
 */
export interface LearningResourcesUserSubscriptionApiLearningResourcesUserSubscriptionSubscribeCreateRequest {
  /**
   * Show resource counts by category
   * @type {Array<'resource_type' | 'certification' | 'certification_type' | 'offered_by' | 'platform' | 'topic' | 'department' | 'level' | 'course_feature' | 'professional' | 'free' | 'delivery' | 'resource_category'>}
   * @memberof LearningResourcesUserSubscriptionApiLearningResourcesUserSubscriptionSubscribeCreate
   */
  readonly aggregations?: Array<LearningResourcesUserSubscriptionSubscribeCreateAggregationsEnum>

  /**
   * True if the learning resource offers a certificate
   * @type {boolean}
   * @memberof LearningResourcesUserSubscriptionApiLearningResourcesUserSubscriptionSubscribeCreate
   */
  readonly certification?: boolean | null

  /**
   * The type of certificate               * &#x60;micromasters&#x60; - MicroMasters Credential * &#x60;professional&#x60; - Professional Certificate * &#x60;completion&#x60; - Certificate of Completion * &#x60;none&#x60; - No Certificate
   * @type {Array<'micromasters' | 'professional' | 'completion' | 'none'>}
   * @memberof LearningResourcesUserSubscriptionApiLearningResourcesUserSubscriptionSubscribeCreate
   */
  readonly certification_type?: Array<LearningResourcesUserSubscriptionSubscribeCreateCertificationTypeEnum>

  /**
   * Score weight for content file data.  1 is the default. 0 means content files are ignored
   * @type {number}
   * @memberof LearningResourcesUserSubscriptionApiLearningResourcesUserSubscriptionSubscribeCreate
   */
  readonly content_file_score_weight?: number | null

  /**
   * The course feature. Possible options are at api/v1/course_features/
   * @type {Array<string>}
   * @memberof LearningResourcesUserSubscriptionApiLearningResourcesUserSubscriptionSubscribeCreate
   */
  readonly course_feature?: Array<string>

  /**
   * The delivery options in which the learning resource is offered               * &#x60;online&#x60; - Online * &#x60;hybrid&#x60; - Hybrid * &#x60;in_person&#x60; - In person * &#x60;offline&#x60; - Offline
   * @type {Array<'online' | 'hybrid' | 'in_person' | 'offline'>}
   * @memberof LearningResourcesUserSubscriptionApiLearningResourcesUserSubscriptionSubscribeCreate
   */
  readonly delivery?: Array<LearningResourcesUserSubscriptionSubscribeCreateDeliveryEnum>

  /**
   * The department that offers the learning resource               * &#x60;1&#x60; - Civil and Environmental Engineering * &#x60;2&#x60; - Mechanical Engineering * &#x60;3&#x60; - Materials Science and Engineering * &#x60;4&#x60; - Architecture * &#x60;5&#x60; - Chemistry * &#x60;6&#x60; - Electrical Engineering and Computer Science * &#x60;7&#x60; - Biology * &#x60;8&#x60; - Physics * &#x60;9&#x60; - Brain and Cognitive Sciences * &#x60;10&#x60; - Chemical Engineering * &#x60;11&#x60; - Urban Studies and Planning * &#x60;12&#x60; - Earth, Atmospheric, and Planetary Sciences * &#x60;14&#x60; - Economics * &#x60;15&#x60; - Management * &#x60;16&#x60; - Aeronautics and Astronautics * &#x60;17&#x60; - Political Science * &#x60;18&#x60; - Mathematics * &#x60;20&#x60; - Biological Engineering * &#x60;21A&#x60; - Anthropology * &#x60;21G&#x60; - Global Languages * &#x60;21H&#x60; - History * &#x60;21L&#x60; - Literature * &#x60;21M&#x60; - Music and Theater Arts * &#x60;22&#x60; - Nuclear Science and Engineering * &#x60;24&#x60; - Linguistics and Philosophy * &#x60;CC&#x60; - Concourse * &#x60;CMS-W&#x60; - Comparative Media Studies/Writing * &#x60;EC&#x60; - Edgerton Center * &#x60;ES&#x60; - Experimental Study Group * &#x60;ESD&#x60; - Engineering Systems Division * &#x60;HST&#x60; - Medical Engineering and Science * &#x60;IDS&#x60; - Data, Systems, and Society * &#x60;MAS&#x60; - Media Arts and Sciences * &#x60;PE&#x60; - Athletics, Physical Education and Recreation * &#x60;SP&#x60; - Special Programs * &#x60;STS&#x60; - Science, Technology, and Society * &#x60;WGS&#x60; - Women\&#39;s and Gender Studies
   * @type {Array<'1' | '2' | '3' | '4' | '5' | '6' | '7' | '8' | '9' | '10' | '11' | '12' | '14' | '15' | '16' | '17' | '18' | '20' | '21A' | '21G' | '21H' | '21L' | '21M' | '22' | '24' | 'CC' | 'CMS-W' | 'EC' | 'ES' | 'ESD' | 'HST' | 'IDS' | 'MAS' | 'PE' | 'SP' | 'STS' | 'WGS'>}
   * @memberof LearningResourcesUserSubscriptionApiLearningResourcesUserSubscriptionSubscribeCreate
   */
  readonly department?: Array<LearningResourcesUserSubscriptionSubscribeCreateDepartmentEnum>

  /**
   * If true return raw open search results with score explanations
   * @type {boolean}
   * @memberof LearningResourcesUserSubscriptionApiLearningResourcesUserSubscriptionSubscribeCreate
   */
  readonly dev_mode?: boolean | null

  /**
   *
   * @type {boolean}
   * @memberof LearningResourcesUserSubscriptionApiLearningResourcesUserSubscriptionSubscribeCreate
   */
  readonly free?: boolean | null

  /**
   * The id value for the learning resource
   * @type {Array<number>}
   * @memberof LearningResourcesUserSubscriptionApiLearningResourcesUserSubscriptionSubscribeCreate
   */
  readonly id?: Array<number>

  /**
   *
   * @type {Array<'undergraduate' | 'graduate' | 'high_school' | 'noncredit' | 'advanced' | 'intermediate' | 'introductory'>}
   * @memberof LearningResourcesUserSubscriptionApiLearningResourcesUserSubscriptionSubscribeCreate
   */
  readonly level?: Array<LearningResourcesUserSubscriptionSubscribeCreateLevelEnum>

  /**
   * Number of results to return per page
   * @type {number}
   * @memberof LearningResourcesUserSubscriptionApiLearningResourcesUserSubscriptionSubscribeCreate
   */
  readonly limit?: number

  /**
   * Maximum score penalty for incomplete OCW courses in percent. An OCW course with completeness &#x3D; 0 will have this score penalty. Partially complete courses have a linear penalty proportional to the degree of incompleteness. Only affects results if there is a search term.
   * @type {number}
   * @memberof LearningResourcesUserSubscriptionApiLearningResourcesUserSubscriptionSubscribeCreate
   */
  readonly max_incompleteness_penalty?: number | null

  /**
   * Minimum score value a text query result needs to have to be displayed
   * @type {number}
   * @memberof LearningResourcesUserSubscriptionApiLearningResourcesUserSubscriptionSubscribeCreate
   */
  readonly min_score?: number | null

  /**
   * The ocw topic name.
   * @type {Array<string>}
   * @memberof LearningResourcesUserSubscriptionApiLearningResourcesUserSubscriptionSubscribeCreate
   */
  readonly ocw_topic?: Array<string>

  /**
   * The organization that offers the learning resource               * &#x60;mitx&#x60; - MITx * &#x60;ocw&#x60; - MIT OpenCourseWare * &#x60;bootcamps&#x60; - Bootcamps * &#x60;xpro&#x60; - MIT xPRO * &#x60;mitpe&#x60; - MIT Professional Education * &#x60;see&#x60; - MIT Sloan Executive Education
   * @type {Array<'mitx' | 'ocw' | 'bootcamps' | 'xpro' | 'mitpe' | 'see'>}
   * @memberof LearningResourcesUserSubscriptionApiLearningResourcesUserSubscriptionSubscribeCreate
   */
  readonly offered_by?: Array<LearningResourcesUserSubscriptionSubscribeCreateOfferedByEnum>

  /**
   * The initial index from which to return the results
   * @type {number}
   * @memberof LearningResourcesUserSubscriptionApiLearningResourcesUserSubscriptionSubscribeCreate
   */
  readonly offset?: number

  /**
   * The platform on which the learning resource is offered               * &#x60;edx&#x60; - edX * &#x60;ocw&#x60; - MIT OpenCourseWare * &#x60;oll&#x60; - Open Learning Library * &#x60;mitxonline&#x60; - MITx Online * &#x60;bootcamps&#x60; - Bootcamps * &#x60;xpro&#x60; - MIT xPRO * &#x60;csail&#x60; - CSAIL * &#x60;mitpe&#x60; - MIT Professional Education * &#x60;see&#x60; - MIT Sloan Executive Education * &#x60;scc&#x60; - Schwarzman College of Computing * &#x60;ctl&#x60; - Center for Transportation &amp; Logistics * &#x60;whu&#x60; - WHU * &#x60;susskind&#x60; - Susskind * &#x60;globalalumni&#x60; - Global Alumni * &#x60;simplilearn&#x60; - Simplilearn * &#x60;emeritus&#x60; - Emeritus * &#x60;podcast&#x60; - Podcast * &#x60;youtube&#x60; - YouTube
   * @type {Array<'edx' | 'ocw' | 'oll' | 'mitxonline' | 'bootcamps' | 'xpro' | 'csail' | 'mitpe' | 'see' | 'scc' | 'ctl' | 'whu' | 'susskind' | 'globalalumni' | 'simplilearn' | 'emeritus' | 'podcast' | 'youtube'>}
   * @memberof LearningResourcesUserSubscriptionApiLearningResourcesUserSubscriptionSubscribeCreate
   */
  readonly platform?: Array<LearningResourcesUserSubscriptionSubscribeCreatePlatformEnum>

  /**
   *
   * @type {boolean}
   * @memberof LearningResourcesUserSubscriptionApiLearningResourcesUserSubscriptionSubscribeCreate
   */
  readonly professional?: boolean | null

  /**
   * The search text
   * @type {string}
   * @memberof LearningResourcesUserSubscriptionApiLearningResourcesUserSubscriptionSubscribeCreate
   */
  readonly q?: string

  /**
   * The category of learning resource               * &#x60;course&#x60; - Course * &#x60;program&#x60; - Program * &#x60;learning_material&#x60; - Learning Material
   * @type {Array<'course' | 'program' | 'learning_material'>}
   * @memberof LearningResourcesUserSubscriptionApiLearningResourcesUserSubscriptionSubscribeCreate
   */
  readonly resource_category?: Array<LearningResourcesUserSubscriptionSubscribeCreateResourceCategoryEnum>

  /**
   * The type of learning resource               * &#x60;course&#x60; - course * &#x60;program&#x60; - program * &#x60;learning_path&#x60; - learning path * &#x60;podcast&#x60; - podcast * &#x60;podcast_episode&#x60; - podcast episode * &#x60;video&#x60; - video * &#x60;video_playlist&#x60; - video playlist
   * @type {Array<'course' | 'program' | 'learning_path' | 'podcast' | 'podcast_episode' | 'video' | 'video_playlist'>}
   * @memberof LearningResourcesUserSubscriptionApiLearningResourcesUserSubscriptionSubscribeCreate
   */
  readonly resource_type?: Array<LearningResourcesUserSubscriptionSubscribeCreateResourceTypeEnum>

  /**
   * The open search search type for text queries               * &#x60;best_fields&#x60; - best_fields * &#x60;most_fields&#x60; - most_fields * &#x60;phrase&#x60; - phrase  * &#x60;best_fields&#x60; - best_fields * &#x60;most_fields&#x60; - most_fields * &#x60;phrase&#x60; - phrase
   * @type {'best_fields' | 'most_fields' | 'phrase'}
   * @memberof LearningResourcesUserSubscriptionApiLearningResourcesUserSubscriptionSubscribeCreate
   */
  readonly search_mode?: LearningResourcesUserSubscriptionSubscribeCreateSearchModeEnum

  /**
   * Allowed distance for phrase search
   * @type {number}
   * @memberof LearningResourcesUserSubscriptionApiLearningResourcesUserSubscriptionSubscribeCreate
   */
  readonly slop?: number | null

  /**
   * If the parameter starts with \&#39;-\&#39; the sort is in descending order  * &#x60;featured&#x60; - Featured * &#x60;id&#x60; - Object ID ascending * &#x60;-id&#x60; - Object ID descending * &#x60;readable_id&#x60; - Readable ID ascending * &#x60;-readable_id&#x60; - Readable ID descending * &#x60;last_modified&#x60; - Last Modified Date ascending * &#x60;-last_modified&#x60; - Last Modified Date descending * &#x60;new&#x60; - Newest resources first * &#x60;start_date&#x60; - Start Date ascending * &#x60;-start_date&#x60; - Start Date descending * &#x60;mitcoursenumber&#x60; - MIT course number ascending * &#x60;-mitcoursenumber&#x60; - MIT course number descending * &#x60;views&#x60; - Popularity ascending * &#x60;-views&#x60; - Popularity descending * &#x60;upcoming&#x60; - Next start date ascending
   * @type {'featured' | 'id' | '-id' | 'readable_id' | '-readable_id' | 'last_modified' | '-last_modified' | 'new' | 'start_date' | '-start_date' | 'mitcoursenumber' | '-mitcoursenumber' | 'views' | '-views' | 'upcoming'}
   * @memberof LearningResourcesUserSubscriptionApiLearningResourcesUserSubscriptionSubscribeCreate
   */
  readonly sortby?: LearningResourcesUserSubscriptionSubscribeCreateSortbyEnum

  /**
   * The subscription type  * &#x60;search_subscription_type&#x60; - search_subscription_type * &#x60;channel_subscription_type&#x60; - channel_subscription_type
   * @type {'search_subscription_type' | 'channel_subscription_type'}
   * @memberof LearningResourcesUserSubscriptionApiLearningResourcesUserSubscriptionSubscribeCreate
   */
  readonly source_type?: LearningResourcesUserSubscriptionSubscribeCreateSourceTypeEnum

  /**
   * The topic name. To see a list of options go to api/v1/topics/
   * @type {Array<string>}
   * @memberof LearningResourcesUserSubscriptionApiLearningResourcesUserSubscriptionSubscribeCreate
   */
  readonly topic?: Array<string>

  /**
   * Relevance score penalty percent per year for for resources without upcoming runs. Only affects results if there is a search term.
   * @type {number}
   * @memberof LearningResourcesUserSubscriptionApiLearningResourcesUserSubscriptionSubscribeCreate
   */
  readonly yearly_decay_percent?: number | null

  /**
   *
   * @type {PercolateQuerySubscriptionRequestRequest}
   * @memberof LearningResourcesUserSubscriptionApiLearningResourcesUserSubscriptionSubscribeCreate
   */
  readonly PercolateQuerySubscriptionRequestRequest?: PercolateQuerySubscriptionRequestRequest
}

/**
 * Request parameters for learningResourcesUserSubscriptionUnsubscribeDestroy operation in LearningResourcesUserSubscriptionApi.
 * @export
 * @interface LearningResourcesUserSubscriptionApiLearningResourcesUserSubscriptionUnsubscribeDestroyRequest
 */
export interface LearningResourcesUserSubscriptionApiLearningResourcesUserSubscriptionUnsubscribeDestroyRequest {
  /**
   *
   * @type {number}
   * @memberof LearningResourcesUserSubscriptionApiLearningResourcesUserSubscriptionUnsubscribeDestroy
   */
  readonly id: number
}

/**
 * LearningResourcesUserSubscriptionApi - object-oriented interface
 * @export
 * @class LearningResourcesUserSubscriptionApi
 * @extends {BaseAPI}
 */
export class LearningResourcesUserSubscriptionApi extends BaseAPI {
  /**
   * View for listing percolate query subscriptions for a user
   * @summary Check if a user is subscribed to a specific query
   * @param {LearningResourcesUserSubscriptionApiLearningResourcesUserSubscriptionCheckListRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof LearningResourcesUserSubscriptionApi
   */
  public learningResourcesUserSubscriptionCheckList(
    requestParameters: LearningResourcesUserSubscriptionApiLearningResourcesUserSubscriptionCheckListRequest = {},
    options?: RawAxiosRequestConfig,
  ) {
    return LearningResourcesUserSubscriptionApiFp(this.configuration)
      .learningResourcesUserSubscriptionCheckList(
        requestParameters.aggregations,
        requestParameters.certification,
        requestParameters.certification_type,
        requestParameters.content_file_score_weight,
        requestParameters.course_feature,
        requestParameters.delivery,
        requestParameters.department,
        requestParameters.dev_mode,
        requestParameters.free,
        requestParameters.id,
        requestParameters.level,
        requestParameters.limit,
        requestParameters.max_incompleteness_penalty,
        requestParameters.min_score,
        requestParameters.ocw_topic,
        requestParameters.offered_by,
        requestParameters.offset,
        requestParameters.platform,
        requestParameters.professional,
        requestParameters.q,
        requestParameters.resource_category,
        requestParameters.resource_type,
        requestParameters.search_mode,
        requestParameters.slop,
        requestParameters.sortby,
        requestParameters.source_type,
        requestParameters.topic,
        requestParameters.yearly_decay_percent,
        options,
      )
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   * View for listing percolate query subscriptions for a user
   * @summary List subscribed queries
   * @param {LearningResourcesUserSubscriptionApiLearningResourcesUserSubscriptionListRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof LearningResourcesUserSubscriptionApi
   */
  public learningResourcesUserSubscriptionList(
    requestParameters: LearningResourcesUserSubscriptionApiLearningResourcesUserSubscriptionListRequest = {},
    options?: RawAxiosRequestConfig,
  ) {
    return LearningResourcesUserSubscriptionApiFp(this.configuration)
      .learningResourcesUserSubscriptionList(
        requestParameters.aggregations,
        requestParameters.certification,
        requestParameters.certification_type,
        requestParameters.content_file_score_weight,
        requestParameters.course_feature,
        requestParameters.delivery,
        requestParameters.department,
        requestParameters.dev_mode,
        requestParameters.free,
        requestParameters.id,
        requestParameters.level,
        requestParameters.limit,
        requestParameters.max_incompleteness_penalty,
        requestParameters.min_score,
        requestParameters.ocw_topic,
        requestParameters.offered_by,
        requestParameters.offset,
        requestParameters.platform,
        requestParameters.professional,
        requestParameters.q,
        requestParameters.resource_category,
        requestParameters.resource_type,
        requestParameters.search_mode,
        requestParameters.slop,
        requestParameters.sortby,
        requestParameters.topic,
        requestParameters.yearly_decay_percent,
        options,
      )
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   * Subscribe a user to query
   * @summary Subscribe user to query
   * @param {LearningResourcesUserSubscriptionApiLearningResourcesUserSubscriptionSubscribeCreateRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof LearningResourcesUserSubscriptionApi
   */
  public learningResourcesUserSubscriptionSubscribeCreate(
    requestParameters: LearningResourcesUserSubscriptionApiLearningResourcesUserSubscriptionSubscribeCreateRequest = {},
    options?: RawAxiosRequestConfig,
  ) {
    return LearningResourcesUserSubscriptionApiFp(this.configuration)
      .learningResourcesUserSubscriptionSubscribeCreate(
        requestParameters.aggregations,
        requestParameters.certification,
        requestParameters.certification_type,
        requestParameters.content_file_score_weight,
        requestParameters.course_feature,
        requestParameters.delivery,
        requestParameters.department,
        requestParameters.dev_mode,
        requestParameters.free,
        requestParameters.id,
        requestParameters.level,
        requestParameters.limit,
        requestParameters.max_incompleteness_penalty,
        requestParameters.min_score,
        requestParameters.ocw_topic,
        requestParameters.offered_by,
        requestParameters.offset,
        requestParameters.platform,
        requestParameters.professional,
        requestParameters.q,
        requestParameters.resource_category,
        requestParameters.resource_type,
        requestParameters.search_mode,
        requestParameters.slop,
        requestParameters.sortby,
        requestParameters.source_type,
        requestParameters.topic,
        requestParameters.yearly_decay_percent,
        requestParameters.PercolateQuerySubscriptionRequestRequest,
        options,
      )
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   * Unsubscribe a user from a query  Args: pk (integer): The id of the query  Returns: PercolateQuerySerializer: The percolate query
   * @summary Unsubscribe user from query
   * @param {LearningResourcesUserSubscriptionApiLearningResourcesUserSubscriptionUnsubscribeDestroyRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof LearningResourcesUserSubscriptionApi
   */
  public learningResourcesUserSubscriptionUnsubscribeDestroy(
    requestParameters: LearningResourcesUserSubscriptionApiLearningResourcesUserSubscriptionUnsubscribeDestroyRequest,
    options?: RawAxiosRequestConfig,
  ) {
    return LearningResourcesUserSubscriptionApiFp(this.configuration)
      .learningResourcesUserSubscriptionUnsubscribeDestroy(
        requestParameters.id,
        options,
      )
      .then((request) => request(this.axios, this.basePath))
  }
}

/**
 * @export
 */
export const LearningResourcesUserSubscriptionCheckListAggregationsEnum = {
  ResourceType: "resource_type",
  Certification: "certification",
  CertificationType: "certification_type",
  OfferedBy: "offered_by",
  Platform: "platform",
  Topic: "topic",
  Department: "department",
  Level: "level",
  CourseFeature: "course_feature",
  Professional: "professional",
  Free: "free",
  Delivery: "delivery",
  ResourceCategory: "resource_category",
} as const
export type LearningResourcesUserSubscriptionCheckListAggregationsEnum =
  (typeof LearningResourcesUserSubscriptionCheckListAggregationsEnum)[keyof typeof LearningResourcesUserSubscriptionCheckListAggregationsEnum]
/**
 * @export
 */
export const LearningResourcesUserSubscriptionCheckListCertificationTypeEnum = {
  Micromasters: "micromasters",
  Professional: "professional",
  Completion: "completion",
  None: "none",
} as const
export type LearningResourcesUserSubscriptionCheckListCertificationTypeEnum =
  (typeof LearningResourcesUserSubscriptionCheckListCertificationTypeEnum)[keyof typeof LearningResourcesUserSubscriptionCheckListCertificationTypeEnum]
/**
 * @export
 */
export const LearningResourcesUserSubscriptionCheckListDeliveryEnum = {
  Online: "online",
  Hybrid: "hybrid",
  InPerson: "in_person",
  Offline: "offline",
} as const
export type LearningResourcesUserSubscriptionCheckListDeliveryEnum =
  (typeof LearningResourcesUserSubscriptionCheckListDeliveryEnum)[keyof typeof LearningResourcesUserSubscriptionCheckListDeliveryEnum]
/**
 * @export
 */
export const LearningResourcesUserSubscriptionCheckListDepartmentEnum = {
  _1: "1",
  _2: "2",
  _3: "3",
  _4: "4",
  _5: "5",
  _6: "6",
  _7: "7",
  _8: "8",
  _9: "9",
  _10: "10",
  _11: "11",
  _12: "12",
  _14: "14",
  _15: "15",
  _16: "16",
  _17: "17",
  _18: "18",
  _20: "20",
  _21A: "21A",
  _21G: "21G",
  _21H: "21H",
  _21L: "21L",
  _21M: "21M",
  _22: "22",
  _24: "24",
  Cc: "CC",
  CmsW: "CMS-W",
  Ec: "EC",
  Es: "ES",
  Esd: "ESD",
  Hst: "HST",
  Ids: "IDS",
  Mas: "MAS",
  Pe: "PE",
  Sp: "SP",
  Sts: "STS",
  Wgs: "WGS",
} as const
export type LearningResourcesUserSubscriptionCheckListDepartmentEnum =
  (typeof LearningResourcesUserSubscriptionCheckListDepartmentEnum)[keyof typeof LearningResourcesUserSubscriptionCheckListDepartmentEnum]
/**
 * @export
 */
export const LearningResourcesUserSubscriptionCheckListLevelEnum = {
  Undergraduate: "undergraduate",
  Graduate: "graduate",
  HighSchool: "high_school",
  Noncredit: "noncredit",
  Advanced: "advanced",
  Intermediate: "intermediate",
  Introductory: "introductory",
} as const
export type LearningResourcesUserSubscriptionCheckListLevelEnum =
  (typeof LearningResourcesUserSubscriptionCheckListLevelEnum)[keyof typeof LearningResourcesUserSubscriptionCheckListLevelEnum]
/**
 * @export
 */
export const LearningResourcesUserSubscriptionCheckListOfferedByEnum = {
  Mitx: "mitx",
  Ocw: "ocw",
  Bootcamps: "bootcamps",
  Xpro: "xpro",
  Mitpe: "mitpe",
  See: "see",
} as const
export type LearningResourcesUserSubscriptionCheckListOfferedByEnum =
  (typeof LearningResourcesUserSubscriptionCheckListOfferedByEnum)[keyof typeof LearningResourcesUserSubscriptionCheckListOfferedByEnum]
/**
 * @export
 */
export const LearningResourcesUserSubscriptionCheckListPlatformEnum = {
  Edx: "edx",
  Ocw: "ocw",
  Oll: "oll",
  Mitxonline: "mitxonline",
  Bootcamps: "bootcamps",
  Xpro: "xpro",
  Csail: "csail",
  Mitpe: "mitpe",
  See: "see",
  Scc: "scc",
  Ctl: "ctl",
  Whu: "whu",
  Susskind: "susskind",
  Globalalumni: "globalalumni",
  Simplilearn: "simplilearn",
  Emeritus: "emeritus",
  Podcast: "podcast",
  Youtube: "youtube",
} as const
export type LearningResourcesUserSubscriptionCheckListPlatformEnum =
  (typeof LearningResourcesUserSubscriptionCheckListPlatformEnum)[keyof typeof LearningResourcesUserSubscriptionCheckListPlatformEnum]
/**
 * @export
 */
export const LearningResourcesUserSubscriptionCheckListResourceCategoryEnum = {
  Course: "course",
  Program: "program",
  LearningMaterial: "learning_material",
} as const
export type LearningResourcesUserSubscriptionCheckListResourceCategoryEnum =
  (typeof LearningResourcesUserSubscriptionCheckListResourceCategoryEnum)[keyof typeof LearningResourcesUserSubscriptionCheckListResourceCategoryEnum]
/**
 * @export
 */
export const LearningResourcesUserSubscriptionCheckListResourceTypeEnum = {
  Course: "course",
  Program: "program",
  LearningPath: "learning_path",
  Podcast: "podcast",
  PodcastEpisode: "podcast_episode",
  Video: "video",
  VideoPlaylist: "video_playlist",
} as const
export type LearningResourcesUserSubscriptionCheckListResourceTypeEnum =
  (typeof LearningResourcesUserSubscriptionCheckListResourceTypeEnum)[keyof typeof LearningResourcesUserSubscriptionCheckListResourceTypeEnum]
/**
 * @export
 */
export const LearningResourcesUserSubscriptionCheckListSearchModeEnum = {
  BestFields: "best_fields",
  MostFields: "most_fields",
  Phrase: "phrase",
} as const
export type LearningResourcesUserSubscriptionCheckListSearchModeEnum =
  (typeof LearningResourcesUserSubscriptionCheckListSearchModeEnum)[keyof typeof LearningResourcesUserSubscriptionCheckListSearchModeEnum]
/**
 * @export
 */
export const LearningResourcesUserSubscriptionCheckListSortbyEnum = {
  Featured: "featured",
  Id: "id",
  Id2: "-id",
  ReadableId: "readable_id",
  ReadableId2: "-readable_id",
  LastModified: "last_modified",
  LastModified2: "-last_modified",
  New: "new",
  StartDate: "start_date",
  StartDate2: "-start_date",
  Mitcoursenumber: "mitcoursenumber",
  Mitcoursenumber2: "-mitcoursenumber",
  Views: "views",
  Views2: "-views",
  Upcoming: "upcoming",
} as const
export type LearningResourcesUserSubscriptionCheckListSortbyEnum =
  (typeof LearningResourcesUserSubscriptionCheckListSortbyEnum)[keyof typeof LearningResourcesUserSubscriptionCheckListSortbyEnum]
/**
 * @export
 */
export const LearningResourcesUserSubscriptionCheckListSourceTypeEnum = {
  SearchSubscriptionType: "search_subscription_type",
  ChannelSubscriptionType: "channel_subscription_type",
} as const
export type LearningResourcesUserSubscriptionCheckListSourceTypeEnum =
  (typeof LearningResourcesUserSubscriptionCheckListSourceTypeEnum)[keyof typeof LearningResourcesUserSubscriptionCheckListSourceTypeEnum]
/**
 * @export
 */
export const LearningResourcesUserSubscriptionListAggregationsEnum = {
  ResourceType: "resource_type",
  Certification: "certification",
  CertificationType: "certification_type",
  OfferedBy: "offered_by",
  Platform: "platform",
  Topic: "topic",
  Department: "department",
  Level: "level",
  CourseFeature: "course_feature",
  Professional: "professional",
  Free: "free",
  Delivery: "delivery",
  ResourceCategory: "resource_category",
} as const
export type LearningResourcesUserSubscriptionListAggregationsEnum =
  (typeof LearningResourcesUserSubscriptionListAggregationsEnum)[keyof typeof LearningResourcesUserSubscriptionListAggregationsEnum]
/**
 * @export
 */
export const LearningResourcesUserSubscriptionListCertificationTypeEnum = {
  Micromasters: "micromasters",
  Professional: "professional",
  Completion: "completion",
  None: "none",
} as const
export type LearningResourcesUserSubscriptionListCertificationTypeEnum =
  (typeof LearningResourcesUserSubscriptionListCertificationTypeEnum)[keyof typeof LearningResourcesUserSubscriptionListCertificationTypeEnum]
/**
 * @export
 */
export const LearningResourcesUserSubscriptionListDeliveryEnum = {
  Online: "online",
  Hybrid: "hybrid",
  InPerson: "in_person",
  Offline: "offline",
} as const
export type LearningResourcesUserSubscriptionListDeliveryEnum =
  (typeof LearningResourcesUserSubscriptionListDeliveryEnum)[keyof typeof LearningResourcesUserSubscriptionListDeliveryEnum]
/**
 * @export
 */
export const LearningResourcesUserSubscriptionListDepartmentEnum = {
  _1: "1",
  _2: "2",
  _3: "3",
  _4: "4",
  _5: "5",
  _6: "6",
  _7: "7",
  _8: "8",
  _9: "9",
  _10: "10",
  _11: "11",
  _12: "12",
  _14: "14",
  _15: "15",
  _16: "16",
  _17: "17",
  _18: "18",
  _20: "20",
  _21A: "21A",
  _21G: "21G",
  _21H: "21H",
  _21L: "21L",
  _21M: "21M",
  _22: "22",
  _24: "24",
  Cc: "CC",
  CmsW: "CMS-W",
  Ec: "EC",
  Es: "ES",
  Esd: "ESD",
  Hst: "HST",
  Ids: "IDS",
  Mas: "MAS",
  Pe: "PE",
  Sp: "SP",
  Sts: "STS",
  Wgs: "WGS",
} as const
export type LearningResourcesUserSubscriptionListDepartmentEnum =
  (typeof LearningResourcesUserSubscriptionListDepartmentEnum)[keyof typeof LearningResourcesUserSubscriptionListDepartmentEnum]
/**
 * @export
 */
export const LearningResourcesUserSubscriptionListLevelEnum = {
  Undergraduate: "undergraduate",
  Graduate: "graduate",
  HighSchool: "high_school",
  Noncredit: "noncredit",
  Advanced: "advanced",
  Intermediate: "intermediate",
  Introductory: "introductory",
} as const
export type LearningResourcesUserSubscriptionListLevelEnum =
  (typeof LearningResourcesUserSubscriptionListLevelEnum)[keyof typeof LearningResourcesUserSubscriptionListLevelEnum]
/**
 * @export
 */
export const LearningResourcesUserSubscriptionListOfferedByEnum = {
  Mitx: "mitx",
  Ocw: "ocw",
  Bootcamps: "bootcamps",
  Xpro: "xpro",
  Mitpe: "mitpe",
  See: "see",
} as const
export type LearningResourcesUserSubscriptionListOfferedByEnum =
  (typeof LearningResourcesUserSubscriptionListOfferedByEnum)[keyof typeof LearningResourcesUserSubscriptionListOfferedByEnum]
/**
 * @export
 */
export const LearningResourcesUserSubscriptionListPlatformEnum = {
  Edx: "edx",
  Ocw: "ocw",
  Oll: "oll",
  Mitxonline: "mitxonline",
  Bootcamps: "bootcamps",
  Xpro: "xpro",
  Csail: "csail",
  Mitpe: "mitpe",
  See: "see",
  Scc: "scc",
  Ctl: "ctl",
  Whu: "whu",
  Susskind: "susskind",
  Globalalumni: "globalalumni",
  Simplilearn: "simplilearn",
  Emeritus: "emeritus",
  Podcast: "podcast",
  Youtube: "youtube",
} as const
export type LearningResourcesUserSubscriptionListPlatformEnum =
  (typeof LearningResourcesUserSubscriptionListPlatformEnum)[keyof typeof LearningResourcesUserSubscriptionListPlatformEnum]
/**
 * @export
 */
export const LearningResourcesUserSubscriptionListResourceCategoryEnum = {
  Course: "course",
  Program: "program",
  LearningMaterial: "learning_material",
} as const
export type LearningResourcesUserSubscriptionListResourceCategoryEnum =
  (typeof LearningResourcesUserSubscriptionListResourceCategoryEnum)[keyof typeof LearningResourcesUserSubscriptionListResourceCategoryEnum]
/**
 * @export
 */
export const LearningResourcesUserSubscriptionListResourceTypeEnum = {
  Course: "course",
  Program: "program",
  LearningPath: "learning_path",
  Podcast: "podcast",
  PodcastEpisode: "podcast_episode",
  Video: "video",
  VideoPlaylist: "video_playlist",
} as const
export type LearningResourcesUserSubscriptionListResourceTypeEnum =
  (typeof LearningResourcesUserSubscriptionListResourceTypeEnum)[keyof typeof LearningResourcesUserSubscriptionListResourceTypeEnum]
/**
 * @export
 */
export const LearningResourcesUserSubscriptionListSearchModeEnum = {
  BestFields: "best_fields",
  MostFields: "most_fields",
  Phrase: "phrase",
} as const
export type LearningResourcesUserSubscriptionListSearchModeEnum =
  (typeof LearningResourcesUserSubscriptionListSearchModeEnum)[keyof typeof LearningResourcesUserSubscriptionListSearchModeEnum]
/**
 * @export
 */
export const LearningResourcesUserSubscriptionListSortbyEnum = {
  Featured: "featured",
  Id: "id",
  Id2: "-id",
  ReadableId: "readable_id",
  ReadableId2: "-readable_id",
  LastModified: "last_modified",
  LastModified2: "-last_modified",
  New: "new",
  StartDate: "start_date",
  StartDate2: "-start_date",
  Mitcoursenumber: "mitcoursenumber",
  Mitcoursenumber2: "-mitcoursenumber",
  Views: "views",
  Views2: "-views",
  Upcoming: "upcoming",
} as const
export type LearningResourcesUserSubscriptionListSortbyEnum =
  (typeof LearningResourcesUserSubscriptionListSortbyEnum)[keyof typeof LearningResourcesUserSubscriptionListSortbyEnum]
/**
 * @export
 */
export const LearningResourcesUserSubscriptionSubscribeCreateAggregationsEnum =
  {
    ResourceType: "resource_type",
    Certification: "certification",
    CertificationType: "certification_type",
    OfferedBy: "offered_by",
    Platform: "platform",
    Topic: "topic",
    Department: "department",
    Level: "level",
    CourseFeature: "course_feature",
    Professional: "professional",
    Free: "free",
    Delivery: "delivery",
    ResourceCategory: "resource_category",
  } as const
export type LearningResourcesUserSubscriptionSubscribeCreateAggregationsEnum =
  (typeof LearningResourcesUserSubscriptionSubscribeCreateAggregationsEnum)[keyof typeof LearningResourcesUserSubscriptionSubscribeCreateAggregationsEnum]
/**
 * @export
 */
export const LearningResourcesUserSubscriptionSubscribeCreateCertificationTypeEnum =
  {
    Micromasters: "micromasters",
    Professional: "professional",
    Completion: "completion",
    None: "none",
  } as const
export type LearningResourcesUserSubscriptionSubscribeCreateCertificationTypeEnum =
  (typeof LearningResourcesUserSubscriptionSubscribeCreateCertificationTypeEnum)[keyof typeof LearningResourcesUserSubscriptionSubscribeCreateCertificationTypeEnum]
/**
 * @export
 */
export const LearningResourcesUserSubscriptionSubscribeCreateDeliveryEnum = {
  Online: "online",
  Hybrid: "hybrid",
  InPerson: "in_person",
  Offline: "offline",
} as const
export type LearningResourcesUserSubscriptionSubscribeCreateDeliveryEnum =
  (typeof LearningResourcesUserSubscriptionSubscribeCreateDeliveryEnum)[keyof typeof LearningResourcesUserSubscriptionSubscribeCreateDeliveryEnum]
/**
 * @export
 */
export const LearningResourcesUserSubscriptionSubscribeCreateDepartmentEnum = {
  _1: "1",
  _2: "2",
  _3: "3",
  _4: "4",
  _5: "5",
  _6: "6",
  _7: "7",
  _8: "8",
  _9: "9",
  _10: "10",
  _11: "11",
  _12: "12",
  _14: "14",
  _15: "15",
  _16: "16",
  _17: "17",
  _18: "18",
  _20: "20",
  _21A: "21A",
  _21G: "21G",
  _21H: "21H",
  _21L: "21L",
  _21M: "21M",
  _22: "22",
  _24: "24",
  Cc: "CC",
  CmsW: "CMS-W",
  Ec: "EC",
  Es: "ES",
  Esd: "ESD",
  Hst: "HST",
  Ids: "IDS",
  Mas: "MAS",
  Pe: "PE",
  Sp: "SP",
  Sts: "STS",
  Wgs: "WGS",
} as const
export type LearningResourcesUserSubscriptionSubscribeCreateDepartmentEnum =
  (typeof LearningResourcesUserSubscriptionSubscribeCreateDepartmentEnum)[keyof typeof LearningResourcesUserSubscriptionSubscribeCreateDepartmentEnum]
/**
 * @export
 */
export const LearningResourcesUserSubscriptionSubscribeCreateLevelEnum = {
  Undergraduate: "undergraduate",
  Graduate: "graduate",
  HighSchool: "high_school",
  Noncredit: "noncredit",
  Advanced: "advanced",
  Intermediate: "intermediate",
  Introductory: "introductory",
} as const
export type LearningResourcesUserSubscriptionSubscribeCreateLevelEnum =
  (typeof LearningResourcesUserSubscriptionSubscribeCreateLevelEnum)[keyof typeof LearningResourcesUserSubscriptionSubscribeCreateLevelEnum]
/**
 * @export
 */
export const LearningResourcesUserSubscriptionSubscribeCreateOfferedByEnum = {
  Mitx: "mitx",
  Ocw: "ocw",
  Bootcamps: "bootcamps",
  Xpro: "xpro",
  Mitpe: "mitpe",
  See: "see",
} as const
export type LearningResourcesUserSubscriptionSubscribeCreateOfferedByEnum =
  (typeof LearningResourcesUserSubscriptionSubscribeCreateOfferedByEnum)[keyof typeof LearningResourcesUserSubscriptionSubscribeCreateOfferedByEnum]
/**
 * @export
 */
export const LearningResourcesUserSubscriptionSubscribeCreatePlatformEnum = {
  Edx: "edx",
  Ocw: "ocw",
  Oll: "oll",
  Mitxonline: "mitxonline",
  Bootcamps: "bootcamps",
  Xpro: "xpro",
  Csail: "csail",
  Mitpe: "mitpe",
  See: "see",
  Scc: "scc",
  Ctl: "ctl",
  Whu: "whu",
  Susskind: "susskind",
  Globalalumni: "globalalumni",
  Simplilearn: "simplilearn",
  Emeritus: "emeritus",
  Podcast: "podcast",
  Youtube: "youtube",
} as const
export type LearningResourcesUserSubscriptionSubscribeCreatePlatformEnum =
  (typeof LearningResourcesUserSubscriptionSubscribeCreatePlatformEnum)[keyof typeof LearningResourcesUserSubscriptionSubscribeCreatePlatformEnum]
/**
 * @export
 */
export const LearningResourcesUserSubscriptionSubscribeCreateResourceCategoryEnum =
  {
    Course: "course",
    Program: "program",
    LearningMaterial: "learning_material",
  } as const
export type LearningResourcesUserSubscriptionSubscribeCreateResourceCategoryEnum =
  (typeof LearningResourcesUserSubscriptionSubscribeCreateResourceCategoryEnum)[keyof typeof LearningResourcesUserSubscriptionSubscribeCreateResourceCategoryEnum]
/**
 * @export
 */
export const LearningResourcesUserSubscriptionSubscribeCreateResourceTypeEnum =
  {
    Course: "course",
    Program: "program",
    LearningPath: "learning_path",
    Podcast: "podcast",
    PodcastEpisode: "podcast_episode",
    Video: "video",
    VideoPlaylist: "video_playlist",
  } as const
export type LearningResourcesUserSubscriptionSubscribeCreateResourceTypeEnum =
  (typeof LearningResourcesUserSubscriptionSubscribeCreateResourceTypeEnum)[keyof typeof LearningResourcesUserSubscriptionSubscribeCreateResourceTypeEnum]
/**
 * @export
 */
export const LearningResourcesUserSubscriptionSubscribeCreateSearchModeEnum = {
  BestFields: "best_fields",
  MostFields: "most_fields",
  Phrase: "phrase",
} as const
export type LearningResourcesUserSubscriptionSubscribeCreateSearchModeEnum =
  (typeof LearningResourcesUserSubscriptionSubscribeCreateSearchModeEnum)[keyof typeof LearningResourcesUserSubscriptionSubscribeCreateSearchModeEnum]
/**
 * @export
 */
export const LearningResourcesUserSubscriptionSubscribeCreateSortbyEnum = {
  Featured: "featured",
  Id: "id",
  Id2: "-id",
  ReadableId: "readable_id",
  ReadableId2: "-readable_id",
  LastModified: "last_modified",
  LastModified2: "-last_modified",
  New: "new",
  StartDate: "start_date",
  StartDate2: "-start_date",
  Mitcoursenumber: "mitcoursenumber",
  Mitcoursenumber2: "-mitcoursenumber",
  Views: "views",
  Views2: "-views",
  Upcoming: "upcoming",
} as const
export type LearningResourcesUserSubscriptionSubscribeCreateSortbyEnum =
  (typeof LearningResourcesUserSubscriptionSubscribeCreateSortbyEnum)[keyof typeof LearningResourcesUserSubscriptionSubscribeCreateSortbyEnum]
/**
 * @export
 */
export const LearningResourcesUserSubscriptionSubscribeCreateSourceTypeEnum = {
  SearchSubscriptionType: "search_subscription_type",
  ChannelSubscriptionType: "channel_subscription_type",
} as const
export type LearningResourcesUserSubscriptionSubscribeCreateSourceTypeEnum =
  (typeof LearningResourcesUserSubscriptionSubscribeCreateSourceTypeEnum)[keyof typeof LearningResourcesUserSubscriptionSubscribeCreateSourceTypeEnum]

/**
 * LearningpathsApi - axios parameter creator
 * @export
 */
export const LearningpathsApiAxiosParamCreator = function (
  configuration?: Configuration,
) {
  return {
    /**
     * Create a learning path
     * @summary Create
     * @param {LearningPathResourceRequest} LearningPathResourceRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    learningpathsCreate: async (
      LearningPathResourceRequest: LearningPathResourceRequest,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'LearningPathResourceRequest' is not null or undefined
      assertParamExists(
        "learningpathsCreate",
        "LearningPathResourceRequest",
        LearningPathResourceRequest,
      )
      const localVarPath = `/api/v1/learningpaths/`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = {
        method: "POST",
        ...baseOptions,
        ...options,
      }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      localVarHeaderParameter["Content-Type"] = "application/json"

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }
      localVarRequestOptions.data = serializeDataIfNeeded(
        LearningPathResourceRequest,
        localVarRequestOptions,
        configuration,
      )

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     * Remove a learning path
     * @summary Destroy
     * @param {number} id A unique integer value identifying this learning resource.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    learningpathsDestroy: async (
      id: number,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists("learningpathsDestroy", "id", id)
      const localVarPath = `/api/v1/learningpaths/{id}/`.replace(
        `{${"id"}}`,
        encodeURIComponent(String(id)),
      )
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = {
        method: "DELETE",
        ...baseOptions,
        ...options,
      }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     * Viewset for LearningPath related resources
     * @summary Learning Path Resource Relationship Add
     * @param {number} learning_resource_id The learning resource id of the learning path
     * @param {LearningPathRelationshipRequest} LearningPathRelationshipRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    learningpathsItemsCreate: async (
      learning_resource_id: number,
      LearningPathRelationshipRequest: LearningPathRelationshipRequest,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'learning_resource_id' is not null or undefined
      assertParamExists(
        "learningpathsItemsCreate",
        "learning_resource_id",
        learning_resource_id,
      )
      // verify required parameter 'LearningPathRelationshipRequest' is not null or undefined
      assertParamExists(
        "learningpathsItemsCreate",
        "LearningPathRelationshipRequest",
        LearningPathRelationshipRequest,
      )
      const localVarPath =
        `/api/v1/learningpaths/{learning_resource_id}/items/`.replace(
          `{${"learning_resource_id"}}`,
          encodeURIComponent(String(learning_resource_id)),
        )
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = {
        method: "POST",
        ...baseOptions,
        ...options,
      }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      localVarHeaderParameter["Content-Type"] = "application/json"

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }
      localVarRequestOptions.data = serializeDataIfNeeded(
        LearningPathRelationshipRequest,
        localVarRequestOptions,
        configuration,
      )

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     * Viewset for LearningPath related resources
     * @summary Learning Path Resource Relationship Remove
     * @param {number} id A unique integer value identifying this learning resource relationship.
     * @param {number} learning_resource_id The learning resource id of the learning path
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    learningpathsItemsDestroy: async (
      id: number,
      learning_resource_id: number,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists("learningpathsItemsDestroy", "id", id)
      // verify required parameter 'learning_resource_id' is not null or undefined
      assertParamExists(
        "learningpathsItemsDestroy",
        "learning_resource_id",
        learning_resource_id,
      )
      const localVarPath =
        `/api/v1/learningpaths/{learning_resource_id}/items/{id}/`
          .replace(`{${"id"}}`, encodeURIComponent(String(id)))
          .replace(
            `{${"learning_resource_id"}}`,
            encodeURIComponent(String(learning_resource_id)),
          )
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = {
        method: "DELETE",
        ...baseOptions,
        ...options,
      }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     * Viewset for LearningPath related resources
     * @param {number} learning_resource_id The learning resource id of the learning path
     * @param {number} [limit] Number of results to return per page.
     * @param {number} [offset] The initial index from which to return the results.
     * @param {string} [sortby] Which field to use when ordering the results.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    learningpathsItemsList: async (
      learning_resource_id: number,
      limit?: number,
      offset?: number,
      sortby?: string,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'learning_resource_id' is not null or undefined
      assertParamExists(
        "learningpathsItemsList",
        "learning_resource_id",
        learning_resource_id,
      )
      const localVarPath =
        `/api/v1/learningpaths/{learning_resource_id}/items/`.replace(
          `{${"learning_resource_id"}}`,
          encodeURIComponent(String(learning_resource_id)),
        )
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      if (limit !== undefined) {
        localVarQueryParameter["limit"] = limit
      }

      if (offset !== undefined) {
        localVarQueryParameter["offset"] = offset
      }

      if (sortby !== undefined) {
        localVarQueryParameter["sortby"] = sortby
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     * Viewset for LearningPath related resources
     * @summary Learning Path Resource Relationship Update
     * @param {number} id A unique integer value identifying this learning resource relationship.
     * @param {number} learning_resource_id The learning resource id of the learning path
     * @param {PatchedLearningPathRelationshipRequest} [PatchedLearningPathRelationshipRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    learningpathsItemsPartialUpdate: async (
      id: number,
      learning_resource_id: number,
      PatchedLearningPathRelationshipRequest?: PatchedLearningPathRelationshipRequest,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists("learningpathsItemsPartialUpdate", "id", id)
      // verify required parameter 'learning_resource_id' is not null or undefined
      assertParamExists(
        "learningpathsItemsPartialUpdate",
        "learning_resource_id",
        learning_resource_id,
      )
      const localVarPath =
        `/api/v1/learningpaths/{learning_resource_id}/items/{id}/`
          .replace(`{${"id"}}`, encodeURIComponent(String(id)))
          .replace(
            `{${"learning_resource_id"}}`,
            encodeURIComponent(String(learning_resource_id)),
          )
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = {
        method: "PATCH",
        ...baseOptions,
        ...options,
      }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      localVarHeaderParameter["Content-Type"] = "application/json"

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }
      localVarRequestOptions.data = serializeDataIfNeeded(
        PatchedLearningPathRelationshipRequest,
        localVarRequestOptions,
        configuration,
      )

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     * Get a singe related learning resource for a learning resource.
     * @summary Nested Learning Resource Retrieve
     * @param {number} id A unique integer value identifying this learning resource relationship.
     * @param {number} learning_resource_id The learning resource id of the learning path
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    learningpathsItemsRetrieve: async (
      id: number,
      learning_resource_id: number,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists("learningpathsItemsRetrieve", "id", id)
      // verify required parameter 'learning_resource_id' is not null or undefined
      assertParamExists(
        "learningpathsItemsRetrieve",
        "learning_resource_id",
        learning_resource_id,
      )
      const localVarPath =
        `/api/v1/learningpaths/{learning_resource_id}/items/{id}/`
          .replace(`{${"id"}}`, encodeURIComponent(String(id)))
          .replace(
            `{${"learning_resource_id"}}`,
            encodeURIComponent(String(learning_resource_id)),
          )
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     * Get a paginated list of learning paths
     * @summary List
     * @param {boolean} [certification]
     * @param {Array<LearningpathsListCertificationTypeEnum>} [certification_type] The type of certification offered  * &#x60;micromasters&#x60; - MicroMasters Credential * &#x60;professional&#x60; - Professional Certificate * &#x60;completion&#x60; - Certificate of Completion * &#x60;none&#x60; - No Certificate
     * @param {Array<string>} [course_feature] Multiple values may be separated by commas.
     * @param {Array<Array<LearningpathsListDeliveryEnum>>} [delivery] The delivery of course/program resources  * &#x60;online&#x60; - Online * &#x60;hybrid&#x60; - Hybrid * &#x60;in_person&#x60; - In person * &#x60;offline&#x60; - Offline
     * @param {Array<LearningpathsListDepartmentEnum>} [department] The department that offers learning resources  * &#x60;1&#x60; - Civil and Environmental Engineering * &#x60;2&#x60; - Mechanical Engineering * &#x60;3&#x60; - Materials Science and Engineering * &#x60;4&#x60; - Architecture * &#x60;5&#x60; - Chemistry * &#x60;6&#x60; - Electrical Engineering and Computer Science * &#x60;7&#x60; - Biology * &#x60;8&#x60; - Physics * &#x60;9&#x60; - Brain and Cognitive Sciences * &#x60;10&#x60; - Chemical Engineering * &#x60;11&#x60; - Urban Studies and Planning * &#x60;12&#x60; - Earth, Atmospheric, and Planetary Sciences * &#x60;14&#x60; - Economics * &#x60;15&#x60; - Management * &#x60;16&#x60; - Aeronautics and Astronautics * &#x60;17&#x60; - Political Science * &#x60;18&#x60; - Mathematics * &#x60;20&#x60; - Biological Engineering * &#x60;21A&#x60; - Anthropology * &#x60;21G&#x60; - Global Languages * &#x60;21H&#x60; - History * &#x60;21L&#x60; - Literature * &#x60;21M&#x60; - Music and Theater Arts * &#x60;22&#x60; - Nuclear Science and Engineering * &#x60;24&#x60; - Linguistics and Philosophy * &#x60;CC&#x60; - Concourse * &#x60;CMS-W&#x60; - Comparative Media Studies/Writing * &#x60;EC&#x60; - Edgerton Center * &#x60;ES&#x60; - Experimental Study Group * &#x60;ESD&#x60; - Engineering Systems Division * &#x60;HST&#x60; - Medical Engineering and Science * &#x60;IDS&#x60; - Data, Systems, and Society * &#x60;MAS&#x60; - Media Arts and Sciences * &#x60;PE&#x60; - Athletics, Physical Education and Recreation * &#x60;SP&#x60; - Special Programs * &#x60;STS&#x60; - Science, Technology, and Society * &#x60;WGS&#x60; - Women\&#39;s and Gender Studies
     * @param {boolean} [free] The course/program is offered for free
     * @param {Array<LearningpathsListLevelEnum>} [level] The academic level of the resources  * &#x60;undergraduate&#x60; - Undergraduate * &#x60;graduate&#x60; - Graduate * &#x60;high_school&#x60; - High School * &#x60;noncredit&#x60; - Non-Credit * &#x60;advanced&#x60; - Advanced * &#x60;intermediate&#x60; - Intermediate * &#x60;introductory&#x60; - Introductory
     * @param {number} [limit] Number of results to return per page.
     * @param {Array<LearningpathsListOfferedByEnum>} [offered_by] The organization that offers a learning resource  * &#x60;mitx&#x60; - MITx * &#x60;ocw&#x60; - MIT OpenCourseWare * &#x60;bootcamps&#x60; - Bootcamps * &#x60;xpro&#x60; - MIT xPRO * &#x60;mitpe&#x60; - MIT Professional Education * &#x60;see&#x60; - MIT Sloan Executive Education
     * @param {number} [offset] The initial index from which to return the results.
     * @param {Array<LearningpathsListPlatformEnum>} [platform] The platform on which learning resources are offered  * &#x60;edx&#x60; - edX * &#x60;ocw&#x60; - MIT OpenCourseWare * &#x60;oll&#x60; - Open Learning Library * &#x60;mitxonline&#x60; - MITx Online * &#x60;bootcamps&#x60; - Bootcamps * &#x60;xpro&#x60; - MIT xPRO * &#x60;csail&#x60; - CSAIL * &#x60;mitpe&#x60; - MIT Professional Education * &#x60;see&#x60; - MIT Sloan Executive Education * &#x60;scc&#x60; - Schwarzman College of Computing * &#x60;ctl&#x60; - Center for Transportation &amp; Logistics * &#x60;whu&#x60; - WHU * &#x60;susskind&#x60; - Susskind * &#x60;globalalumni&#x60; - Global Alumni * &#x60;simplilearn&#x60; - Simplilearn * &#x60;emeritus&#x60; - Emeritus * &#x60;podcast&#x60; - Podcast * &#x60;youtube&#x60; - YouTube
     * @param {boolean} [professional]
     * @param {Array<string>} [readable_id] Multiple values may be separated by commas.
     * @param {Array<LearningpathsListResourceCategoryEnum>} [resource_category] The resource category of the learning resources  * &#x60;course&#x60; - Course * &#x60;program&#x60; - Program * &#x60;learning_material&#x60; - Learning Material
     * @param {Array<LearningpathsListResourceTypeEnum>} [resource_type] The type of learning resource  * &#x60;course&#x60; - Course * &#x60;program&#x60; - Program * &#x60;learning_path&#x60; - Learning Path * &#x60;podcast&#x60; - Podcast * &#x60;podcast_episode&#x60; - Podcast Episode * &#x60;video&#x60; - Video * &#x60;video_playlist&#x60; - Video Playlist
     * @param {LearningpathsListSortbyEnum} [sortby] Sort By  * &#x60;id&#x60; - Object ID ascending * &#x60;-id&#x60; - Object ID descending * &#x60;readable_id&#x60; - Readable ID ascending * &#x60;-readable_id&#x60; - Readable ID descending * &#x60;last_modified&#x60; - Last Modified Date ascending * &#x60;-last_modified&#x60; - Last Modified Date descending * &#x60;new&#x60; - Newest resources first * &#x60;start_date&#x60; - Start Date ascending * &#x60;-start_date&#x60; - Start Date descending * &#x60;mitcoursenumber&#x60; - MIT course number ascending * &#x60;-mitcoursenumber&#x60; - MIT course number descending * &#x60;views&#x60; - Popularity ascending * &#x60;-views&#x60; - Popularity descending * &#x60;upcoming&#x60; - Next start date ascending
     * @param {Array<string>} [topic] Multiple values may be separated by commas.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    learningpathsList: async (
      certification?: boolean,
      certification_type?: Array<LearningpathsListCertificationTypeEnum>,
      course_feature?: Array<string>,
      delivery?: Array<Array<LearningpathsListDeliveryEnum>>,
      department?: Array<LearningpathsListDepartmentEnum>,
      free?: boolean,
      level?: Array<LearningpathsListLevelEnum>,
      limit?: number,
      offered_by?: Array<LearningpathsListOfferedByEnum>,
      offset?: number,
      platform?: Array<LearningpathsListPlatformEnum>,
      professional?: boolean,
      readable_id?: Array<string>,
      resource_category?: Array<LearningpathsListResourceCategoryEnum>,
      resource_type?: Array<LearningpathsListResourceTypeEnum>,
      sortby?: LearningpathsListSortbyEnum,
      topic?: Array<string>,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/api/v1/learningpaths/`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      if (certification !== undefined) {
        localVarQueryParameter["certification"] = certification
      }

      if (certification_type) {
        localVarQueryParameter["certification_type"] = certification_type
      }

      if (course_feature) {
        localVarQueryParameter["course_feature"] = course_feature.join(
          COLLECTION_FORMATS.csv,
        )
      }

      if (delivery) {
        localVarQueryParameter["delivery"] = delivery
      }

      if (department) {
        localVarQueryParameter["department"] = department
      }

      if (free !== undefined) {
        localVarQueryParameter["free"] = free
      }

      if (level) {
        localVarQueryParameter["level"] = level
      }

      if (limit !== undefined) {
        localVarQueryParameter["limit"] = limit
      }

      if (offered_by) {
        localVarQueryParameter["offered_by"] = offered_by
      }

      if (offset !== undefined) {
        localVarQueryParameter["offset"] = offset
      }

      if (platform) {
        localVarQueryParameter["platform"] = platform
      }

      if (professional !== undefined) {
        localVarQueryParameter["professional"] = professional
      }

      if (readable_id) {
        localVarQueryParameter["readable_id"] = readable_id.join(
          COLLECTION_FORMATS.csv,
        )
      }

      if (resource_category) {
        localVarQueryParameter["resource_category"] = resource_category
      }

      if (resource_type) {
        localVarQueryParameter["resource_type"] = resource_type
      }

      if (sortby !== undefined) {
        localVarQueryParameter["sortby"] = sortby
      }

      if (topic) {
        localVarQueryParameter["topic"] = topic.join(COLLECTION_FORMATS.csv)
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     * Get a list of all learning path items
     * @summary List
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    learningpathsMembershipList: async (
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/api/v1/learningpaths/membership/`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     * Update individual fields of a learning path
     * @summary Update
     * @param {number} id A unique integer value identifying this learning resource.
     * @param {PatchedLearningPathResourceRequest} [PatchedLearningPathResourceRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    learningpathsPartialUpdate: async (
      id: number,
      PatchedLearningPathResourceRequest?: PatchedLearningPathResourceRequest,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists("learningpathsPartialUpdate", "id", id)
      const localVarPath = `/api/v1/learningpaths/{id}/`.replace(
        `{${"id"}}`,
        encodeURIComponent(String(id)),
      )
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = {
        method: "PATCH",
        ...baseOptions,
        ...options,
      }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      localVarHeaderParameter["Content-Type"] = "application/json"

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }
      localVarRequestOptions.data = serializeDataIfNeeded(
        PatchedLearningPathResourceRequest,
        localVarRequestOptions,
        configuration,
      )

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     * Retrive a single learning path
     * @summary Retrieve
     * @param {number} id A unique integer value identifying this learning resource.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    learningpathsRetrieve: async (
      id: number,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists("learningpathsRetrieve", "id", id)
      const localVarPath = `/api/v1/learningpaths/{id}/`.replace(
        `{${"id"}}`,
        encodeURIComponent(String(id)),
      )
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
  }
}

/**
 * LearningpathsApi - functional programming interface
 * @export
 */
export const LearningpathsApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator =
    LearningpathsApiAxiosParamCreator(configuration)
  return {
    /**
     * Create a learning path
     * @summary Create
     * @param {LearningPathResourceRequest} LearningPathResourceRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async learningpathsCreate(
      LearningPathResourceRequest: LearningPathResourceRequest,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<LearningPathResource>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.learningpathsCreate(
          LearningPathResourceRequest,
          options,
        )
      const index = configuration?.serverIndex ?? 0
      const operationBasePath =
        operationServerMap["LearningpathsApi.learningpathsCreate"]?.[index]?.url
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, operationBasePath || basePath)
    },
    /**
     * Remove a learning path
     * @summary Destroy
     * @param {number} id A unique integer value identifying this learning resource.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async learningpathsDestroy(
      id: number,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.learningpathsDestroy(id, options)
      const index = configuration?.serverIndex ?? 0
      const operationBasePath =
        operationServerMap["LearningpathsApi.learningpathsDestroy"]?.[index]
          ?.url
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, operationBasePath || basePath)
    },
    /**
     * Viewset for LearningPath related resources
     * @summary Learning Path Resource Relationship Add
     * @param {number} learning_resource_id The learning resource id of the learning path
     * @param {LearningPathRelationshipRequest} LearningPathRelationshipRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async learningpathsItemsCreate(
      learning_resource_id: number,
      LearningPathRelationshipRequest: LearningPathRelationshipRequest,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<LearningPathRelationship>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.learningpathsItemsCreate(
          learning_resource_id,
          LearningPathRelationshipRequest,
          options,
        )
      const index = configuration?.serverIndex ?? 0
      const operationBasePath =
        operationServerMap["LearningpathsApi.learningpathsItemsCreate"]?.[index]
          ?.url
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, operationBasePath || basePath)
    },
    /**
     * Viewset for LearningPath related resources
     * @summary Learning Path Resource Relationship Remove
     * @param {number} id A unique integer value identifying this learning resource relationship.
     * @param {number} learning_resource_id The learning resource id of the learning path
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async learningpathsItemsDestroy(
      id: number,
      learning_resource_id: number,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.learningpathsItemsDestroy(
          id,
          learning_resource_id,
          options,
        )
      const index = configuration?.serverIndex ?? 0
      const operationBasePath =
        operationServerMap["LearningpathsApi.learningpathsItemsDestroy"]?.[
          index
        ]?.url
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, operationBasePath || basePath)
    },
    /**
     * Viewset for LearningPath related resources
     * @param {number} learning_resource_id The learning resource id of the learning path
     * @param {number} [limit] Number of results to return per page.
     * @param {number} [offset] The initial index from which to return the results.
     * @param {string} [sortby] Which field to use when ordering the results.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async learningpathsItemsList(
      learning_resource_id: number,
      limit?: number,
      offset?: number,
      sortby?: string,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<PaginatedLearningPathRelationshipList>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.learningpathsItemsList(
          learning_resource_id,
          limit,
          offset,
          sortby,
          options,
        )
      const index = configuration?.serverIndex ?? 0
      const operationBasePath =
        operationServerMap["LearningpathsApi.learningpathsItemsList"]?.[index]
          ?.url
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, operationBasePath || basePath)
    },
    /**
     * Viewset for LearningPath related resources
     * @summary Learning Path Resource Relationship Update
     * @param {number} id A unique integer value identifying this learning resource relationship.
     * @param {number} learning_resource_id The learning resource id of the learning path
     * @param {PatchedLearningPathRelationshipRequest} [PatchedLearningPathRelationshipRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async learningpathsItemsPartialUpdate(
      id: number,
      learning_resource_id: number,
      PatchedLearningPathRelationshipRequest?: PatchedLearningPathRelationshipRequest,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<LearningPathRelationship>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.learningpathsItemsPartialUpdate(
          id,
          learning_resource_id,
          PatchedLearningPathRelationshipRequest,
          options,
        )
      const index = configuration?.serverIndex ?? 0
      const operationBasePath =
        operationServerMap[
          "LearningpathsApi.learningpathsItemsPartialUpdate"
        ]?.[index]?.url
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, operationBasePath || basePath)
    },
    /**
     * Get a singe related learning resource for a learning resource.
     * @summary Nested Learning Resource Retrieve
     * @param {number} id A unique integer value identifying this learning resource relationship.
     * @param {number} learning_resource_id The learning resource id of the learning path
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async learningpathsItemsRetrieve(
      id: number,
      learning_resource_id: number,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<LearningPathRelationship>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.learningpathsItemsRetrieve(
          id,
          learning_resource_id,
          options,
        )
      const index = configuration?.serverIndex ?? 0
      const operationBasePath =
        operationServerMap["LearningpathsApi.learningpathsItemsRetrieve"]?.[
          index
        ]?.url
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, operationBasePath || basePath)
    },
    /**
     * Get a paginated list of learning paths
     * @summary List
     * @param {boolean} [certification]
     * @param {Array<LearningpathsListCertificationTypeEnum>} [certification_type] The type of certification offered  * &#x60;micromasters&#x60; - MicroMasters Credential * &#x60;professional&#x60; - Professional Certificate * &#x60;completion&#x60; - Certificate of Completion * &#x60;none&#x60; - No Certificate
     * @param {Array<string>} [course_feature] Multiple values may be separated by commas.
     * @param {Array<Array<LearningpathsListDeliveryEnum>>} [delivery] The delivery of course/program resources  * &#x60;online&#x60; - Online * &#x60;hybrid&#x60; - Hybrid * &#x60;in_person&#x60; - In person * &#x60;offline&#x60; - Offline
     * @param {Array<LearningpathsListDepartmentEnum>} [department] The department that offers learning resources  * &#x60;1&#x60; - Civil and Environmental Engineering * &#x60;2&#x60; - Mechanical Engineering * &#x60;3&#x60; - Materials Science and Engineering * &#x60;4&#x60; - Architecture * &#x60;5&#x60; - Chemistry * &#x60;6&#x60; - Electrical Engineering and Computer Science * &#x60;7&#x60; - Biology * &#x60;8&#x60; - Physics * &#x60;9&#x60; - Brain and Cognitive Sciences * &#x60;10&#x60; - Chemical Engineering * &#x60;11&#x60; - Urban Studies and Planning * &#x60;12&#x60; - Earth, Atmospheric, and Planetary Sciences * &#x60;14&#x60; - Economics * &#x60;15&#x60; - Management * &#x60;16&#x60; - Aeronautics and Astronautics * &#x60;17&#x60; - Political Science * &#x60;18&#x60; - Mathematics * &#x60;20&#x60; - Biological Engineering * &#x60;21A&#x60; - Anthropology * &#x60;21G&#x60; - Global Languages * &#x60;21H&#x60; - History * &#x60;21L&#x60; - Literature * &#x60;21M&#x60; - Music and Theater Arts * &#x60;22&#x60; - Nuclear Science and Engineering * &#x60;24&#x60; - Linguistics and Philosophy * &#x60;CC&#x60; - Concourse * &#x60;CMS-W&#x60; - Comparative Media Studies/Writing * &#x60;EC&#x60; - Edgerton Center * &#x60;ES&#x60; - Experimental Study Group * &#x60;ESD&#x60; - Engineering Systems Division * &#x60;HST&#x60; - Medical Engineering and Science * &#x60;IDS&#x60; - Data, Systems, and Society * &#x60;MAS&#x60; - Media Arts and Sciences * &#x60;PE&#x60; - Athletics, Physical Education and Recreation * &#x60;SP&#x60; - Special Programs * &#x60;STS&#x60; - Science, Technology, and Society * &#x60;WGS&#x60; - Women\&#39;s and Gender Studies
     * @param {boolean} [free] The course/program is offered for free
     * @param {Array<LearningpathsListLevelEnum>} [level] The academic level of the resources  * &#x60;undergraduate&#x60; - Undergraduate * &#x60;graduate&#x60; - Graduate * &#x60;high_school&#x60; - High School * &#x60;noncredit&#x60; - Non-Credit * &#x60;advanced&#x60; - Advanced * &#x60;intermediate&#x60; - Intermediate * &#x60;introductory&#x60; - Introductory
     * @param {number} [limit] Number of results to return per page.
     * @param {Array<LearningpathsListOfferedByEnum>} [offered_by] The organization that offers a learning resource  * &#x60;mitx&#x60; - MITx * &#x60;ocw&#x60; - MIT OpenCourseWare * &#x60;bootcamps&#x60; - Bootcamps * &#x60;xpro&#x60; - MIT xPRO * &#x60;mitpe&#x60; - MIT Professional Education * &#x60;see&#x60; - MIT Sloan Executive Education
     * @param {number} [offset] The initial index from which to return the results.
     * @param {Array<LearningpathsListPlatformEnum>} [platform] The platform on which learning resources are offered  * &#x60;edx&#x60; - edX * &#x60;ocw&#x60; - MIT OpenCourseWare * &#x60;oll&#x60; - Open Learning Library * &#x60;mitxonline&#x60; - MITx Online * &#x60;bootcamps&#x60; - Bootcamps * &#x60;xpro&#x60; - MIT xPRO * &#x60;csail&#x60; - CSAIL * &#x60;mitpe&#x60; - MIT Professional Education * &#x60;see&#x60; - MIT Sloan Executive Education * &#x60;scc&#x60; - Schwarzman College of Computing * &#x60;ctl&#x60; - Center for Transportation &amp; Logistics * &#x60;whu&#x60; - WHU * &#x60;susskind&#x60; - Susskind * &#x60;globalalumni&#x60; - Global Alumni * &#x60;simplilearn&#x60; - Simplilearn * &#x60;emeritus&#x60; - Emeritus * &#x60;podcast&#x60; - Podcast * &#x60;youtube&#x60; - YouTube
     * @param {boolean} [professional]
     * @param {Array<string>} [readable_id] Multiple values may be separated by commas.
     * @param {Array<LearningpathsListResourceCategoryEnum>} [resource_category] The resource category of the learning resources  * &#x60;course&#x60; - Course * &#x60;program&#x60; - Program * &#x60;learning_material&#x60; - Learning Material
     * @param {Array<LearningpathsListResourceTypeEnum>} [resource_type] The type of learning resource  * &#x60;course&#x60; - Course * &#x60;program&#x60; - Program * &#x60;learning_path&#x60; - Learning Path * &#x60;podcast&#x60; - Podcast * &#x60;podcast_episode&#x60; - Podcast Episode * &#x60;video&#x60; - Video * &#x60;video_playlist&#x60; - Video Playlist
     * @param {LearningpathsListSortbyEnum} [sortby] Sort By  * &#x60;id&#x60; - Object ID ascending * &#x60;-id&#x60; - Object ID descending * &#x60;readable_id&#x60; - Readable ID ascending * &#x60;-readable_id&#x60; - Readable ID descending * &#x60;last_modified&#x60; - Last Modified Date ascending * &#x60;-last_modified&#x60; - Last Modified Date descending * &#x60;new&#x60; - Newest resources first * &#x60;start_date&#x60; - Start Date ascending * &#x60;-start_date&#x60; - Start Date descending * &#x60;mitcoursenumber&#x60; - MIT course number ascending * &#x60;-mitcoursenumber&#x60; - MIT course number descending * &#x60;views&#x60; - Popularity ascending * &#x60;-views&#x60; - Popularity descending * &#x60;upcoming&#x60; - Next start date ascending
     * @param {Array<string>} [topic] Multiple values may be separated by commas.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async learningpathsList(
      certification?: boolean,
      certification_type?: Array<LearningpathsListCertificationTypeEnum>,
      course_feature?: Array<string>,
      delivery?: Array<Array<LearningpathsListDeliveryEnum>>,
      department?: Array<LearningpathsListDepartmentEnum>,
      free?: boolean,
      level?: Array<LearningpathsListLevelEnum>,
      limit?: number,
      offered_by?: Array<LearningpathsListOfferedByEnum>,
      offset?: number,
      platform?: Array<LearningpathsListPlatformEnum>,
      professional?: boolean,
      readable_id?: Array<string>,
      resource_category?: Array<LearningpathsListResourceCategoryEnum>,
      resource_type?: Array<LearningpathsListResourceTypeEnum>,
      sortby?: LearningpathsListSortbyEnum,
      topic?: Array<string>,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<PaginatedLearningPathResourceList>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.learningpathsList(
          certification,
          certification_type,
          course_feature,
          delivery,
          department,
          free,
          level,
          limit,
          offered_by,
          offset,
          platform,
          professional,
          readable_id,
          resource_category,
          resource_type,
          sortby,
          topic,
          options,
        )
      const index = configuration?.serverIndex ?? 0
      const operationBasePath =
        operationServerMap["LearningpathsApi.learningpathsList"]?.[index]?.url
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, operationBasePath || basePath)
    },
    /**
     * Get a list of all learning path items
     * @summary List
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async learningpathsMembershipList(
      options?: RawAxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<Array<MicroLearningPathRelationship>>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.learningpathsMembershipList(options)
      const index = configuration?.serverIndex ?? 0
      const operationBasePath =
        operationServerMap["LearningpathsApi.learningpathsMembershipList"]?.[
          index
        ]?.url
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, operationBasePath || basePath)
    },
    /**
     * Update individual fields of a learning path
     * @summary Update
     * @param {number} id A unique integer value identifying this learning resource.
     * @param {PatchedLearningPathResourceRequest} [PatchedLearningPathResourceRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async learningpathsPartialUpdate(
      id: number,
      PatchedLearningPathResourceRequest?: PatchedLearningPathResourceRequest,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<LearningPathResource>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.learningpathsPartialUpdate(
          id,
          PatchedLearningPathResourceRequest,
          options,
        )
      const index = configuration?.serverIndex ?? 0
      const operationBasePath =
        operationServerMap["LearningpathsApi.learningpathsPartialUpdate"]?.[
          index
        ]?.url
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, operationBasePath || basePath)
    },
    /**
     * Retrive a single learning path
     * @summary Retrieve
     * @param {number} id A unique integer value identifying this learning resource.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async learningpathsRetrieve(
      id: number,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<LearningPathResource>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.learningpathsRetrieve(id, options)
      const index = configuration?.serverIndex ?? 0
      const operationBasePath =
        operationServerMap["LearningpathsApi.learningpathsRetrieve"]?.[index]
          ?.url
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, operationBasePath || basePath)
    },
  }
}

/**
 * LearningpathsApi - factory interface
 * @export
 */
export const LearningpathsApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = LearningpathsApiFp(configuration)
  return {
    /**
     * Create a learning path
     * @summary Create
     * @param {LearningpathsApiLearningpathsCreateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    learningpathsCreate(
      requestParameters: LearningpathsApiLearningpathsCreateRequest,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<LearningPathResource> {
      return localVarFp
        .learningpathsCreate(
          requestParameters.LearningPathResourceRequest,
          options,
        )
        .then((request) => request(axios, basePath))
    },
    /**
     * Remove a learning path
     * @summary Destroy
     * @param {LearningpathsApiLearningpathsDestroyRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    learningpathsDestroy(
      requestParameters: LearningpathsApiLearningpathsDestroyRequest,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<void> {
      return localVarFp
        .learningpathsDestroy(requestParameters.id, options)
        .then((request) => request(axios, basePath))
    },
    /**
     * Viewset for LearningPath related resources
     * @summary Learning Path Resource Relationship Add
     * @param {LearningpathsApiLearningpathsItemsCreateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    learningpathsItemsCreate(
      requestParameters: LearningpathsApiLearningpathsItemsCreateRequest,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<LearningPathRelationship> {
      return localVarFp
        .learningpathsItemsCreate(
          requestParameters.learning_resource_id,
          requestParameters.LearningPathRelationshipRequest,
          options,
        )
        .then((request) => request(axios, basePath))
    },
    /**
     * Viewset for LearningPath related resources
     * @summary Learning Path Resource Relationship Remove
     * @param {LearningpathsApiLearningpathsItemsDestroyRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    learningpathsItemsDestroy(
      requestParameters: LearningpathsApiLearningpathsItemsDestroyRequest,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<void> {
      return localVarFp
        .learningpathsItemsDestroy(
          requestParameters.id,
          requestParameters.learning_resource_id,
          options,
        )
        .then((request) => request(axios, basePath))
    },
    /**
     * Viewset for LearningPath related resources
     * @param {LearningpathsApiLearningpathsItemsListRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    learningpathsItemsList(
      requestParameters: LearningpathsApiLearningpathsItemsListRequest,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<PaginatedLearningPathRelationshipList> {
      return localVarFp
        .learningpathsItemsList(
          requestParameters.learning_resource_id,
          requestParameters.limit,
          requestParameters.offset,
          requestParameters.sortby,
          options,
        )
        .then((request) => request(axios, basePath))
    },
    /**
     * Viewset for LearningPath related resources
     * @summary Learning Path Resource Relationship Update
     * @param {LearningpathsApiLearningpathsItemsPartialUpdateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    learningpathsItemsPartialUpdate(
      requestParameters: LearningpathsApiLearningpathsItemsPartialUpdateRequest,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<LearningPathRelationship> {
      return localVarFp
        .learningpathsItemsPartialUpdate(
          requestParameters.id,
          requestParameters.learning_resource_id,
          requestParameters.PatchedLearningPathRelationshipRequest,
          options,
        )
        .then((request) => request(axios, basePath))
    },
    /**
     * Get a singe related learning resource for a learning resource.
     * @summary Nested Learning Resource Retrieve
     * @param {LearningpathsApiLearningpathsItemsRetrieveRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    learningpathsItemsRetrieve(
      requestParameters: LearningpathsApiLearningpathsItemsRetrieveRequest,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<LearningPathRelationship> {
      return localVarFp
        .learningpathsItemsRetrieve(
          requestParameters.id,
          requestParameters.learning_resource_id,
          options,
        )
        .then((request) => request(axios, basePath))
    },
    /**
     * Get a paginated list of learning paths
     * @summary List
     * @param {LearningpathsApiLearningpathsListRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    learningpathsList(
      requestParameters: LearningpathsApiLearningpathsListRequest = {},
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<PaginatedLearningPathResourceList> {
      return localVarFp
        .learningpathsList(
          requestParameters.certification,
          requestParameters.certification_type,
          requestParameters.course_feature,
          requestParameters.delivery,
          requestParameters.department,
          requestParameters.free,
          requestParameters.level,
          requestParameters.limit,
          requestParameters.offered_by,
          requestParameters.offset,
          requestParameters.platform,
          requestParameters.professional,
          requestParameters.readable_id,
          requestParameters.resource_category,
          requestParameters.resource_type,
          requestParameters.sortby,
          requestParameters.topic,
          options,
        )
        .then((request) => request(axios, basePath))
    },
    /**
     * Get a list of all learning path items
     * @summary List
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    learningpathsMembershipList(
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<Array<MicroLearningPathRelationship>> {
      return localVarFp
        .learningpathsMembershipList(options)
        .then((request) => request(axios, basePath))
    },
    /**
     * Update individual fields of a learning path
     * @summary Update
     * @param {LearningpathsApiLearningpathsPartialUpdateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    learningpathsPartialUpdate(
      requestParameters: LearningpathsApiLearningpathsPartialUpdateRequest,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<LearningPathResource> {
      return localVarFp
        .learningpathsPartialUpdate(
          requestParameters.id,
          requestParameters.PatchedLearningPathResourceRequest,
          options,
        )
        .then((request) => request(axios, basePath))
    },
    /**
     * Retrive a single learning path
     * @summary Retrieve
     * @param {LearningpathsApiLearningpathsRetrieveRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    learningpathsRetrieve(
      requestParameters: LearningpathsApiLearningpathsRetrieveRequest,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<LearningPathResource> {
      return localVarFp
        .learningpathsRetrieve(requestParameters.id, options)
        .then((request) => request(axios, basePath))
    },
  }
}

/**
 * Request parameters for learningpathsCreate operation in LearningpathsApi.
 * @export
 * @interface LearningpathsApiLearningpathsCreateRequest
 */
export interface LearningpathsApiLearningpathsCreateRequest {
  /**
   *
   * @type {LearningPathResourceRequest}
   * @memberof LearningpathsApiLearningpathsCreate
   */
  readonly LearningPathResourceRequest: LearningPathResourceRequest
}

/**
 * Request parameters for learningpathsDestroy operation in LearningpathsApi.
 * @export
 * @interface LearningpathsApiLearningpathsDestroyRequest
 */
export interface LearningpathsApiLearningpathsDestroyRequest {
  /**
   * A unique integer value identifying this learning resource.
   * @type {number}
   * @memberof LearningpathsApiLearningpathsDestroy
   */
  readonly id: number
}

/**
 * Request parameters for learningpathsItemsCreate operation in LearningpathsApi.
 * @export
 * @interface LearningpathsApiLearningpathsItemsCreateRequest
 */
export interface LearningpathsApiLearningpathsItemsCreateRequest {
  /**
   * The learning resource id of the learning path
   * @type {number}
   * @memberof LearningpathsApiLearningpathsItemsCreate
   */
  readonly learning_resource_id: number

  /**
   *
   * @type {LearningPathRelationshipRequest}
   * @memberof LearningpathsApiLearningpathsItemsCreate
   */
  readonly LearningPathRelationshipRequest: LearningPathRelationshipRequest
}

/**
 * Request parameters for learningpathsItemsDestroy operation in LearningpathsApi.
 * @export
 * @interface LearningpathsApiLearningpathsItemsDestroyRequest
 */
export interface LearningpathsApiLearningpathsItemsDestroyRequest {
  /**
   * A unique integer value identifying this learning resource relationship.
   * @type {number}
   * @memberof LearningpathsApiLearningpathsItemsDestroy
   */
  readonly id: number

  /**
   * The learning resource id of the learning path
   * @type {number}
   * @memberof LearningpathsApiLearningpathsItemsDestroy
   */
  readonly learning_resource_id: number
}

/**
 * Request parameters for learningpathsItemsList operation in LearningpathsApi.
 * @export
 * @interface LearningpathsApiLearningpathsItemsListRequest
 */
export interface LearningpathsApiLearningpathsItemsListRequest {
  /**
   * The learning resource id of the learning path
   * @type {number}
   * @memberof LearningpathsApiLearningpathsItemsList
   */
  readonly learning_resource_id: number

  /**
   * Number of results to return per page.
   * @type {number}
   * @memberof LearningpathsApiLearningpathsItemsList
   */
  readonly limit?: number

  /**
   * The initial index from which to return the results.
   * @type {number}
   * @memberof LearningpathsApiLearningpathsItemsList
   */
  readonly offset?: number

  /**
   * Which field to use when ordering the results.
   * @type {string}
   * @memberof LearningpathsApiLearningpathsItemsList
   */
  readonly sortby?: string
}

/**
 * Request parameters for learningpathsItemsPartialUpdate operation in LearningpathsApi.
 * @export
 * @interface LearningpathsApiLearningpathsItemsPartialUpdateRequest
 */
export interface LearningpathsApiLearningpathsItemsPartialUpdateRequest {
  /**
   * A unique integer value identifying this learning resource relationship.
   * @type {number}
   * @memberof LearningpathsApiLearningpathsItemsPartialUpdate
   */
  readonly id: number

  /**
   * The learning resource id of the learning path
   * @type {number}
   * @memberof LearningpathsApiLearningpathsItemsPartialUpdate
   */
  readonly learning_resource_id: number

  /**
   *
   * @type {PatchedLearningPathRelationshipRequest}
   * @memberof LearningpathsApiLearningpathsItemsPartialUpdate
   */
  readonly PatchedLearningPathRelationshipRequest?: PatchedLearningPathRelationshipRequest
}

/**
 * Request parameters for learningpathsItemsRetrieve operation in LearningpathsApi.
 * @export
 * @interface LearningpathsApiLearningpathsItemsRetrieveRequest
 */
export interface LearningpathsApiLearningpathsItemsRetrieveRequest {
  /**
   * A unique integer value identifying this learning resource relationship.
   * @type {number}
   * @memberof LearningpathsApiLearningpathsItemsRetrieve
   */
  readonly id: number

  /**
   * The learning resource id of the learning path
   * @type {number}
   * @memberof LearningpathsApiLearningpathsItemsRetrieve
   */
  readonly learning_resource_id: number
}

/**
 * Request parameters for learningpathsList operation in LearningpathsApi.
 * @export
 * @interface LearningpathsApiLearningpathsListRequest
 */
export interface LearningpathsApiLearningpathsListRequest {
  /**
   *
   * @type {boolean}
   * @memberof LearningpathsApiLearningpathsList
   */
  readonly certification?: boolean

  /**
   * The type of certification offered  * &#x60;micromasters&#x60; - MicroMasters Credential * &#x60;professional&#x60; - Professional Certificate * &#x60;completion&#x60; - Certificate of Completion * &#x60;none&#x60; - No Certificate
   * @type {Array<'completion' | 'micromasters' | 'none' | 'professional'>}
   * @memberof LearningpathsApiLearningpathsList
   */
  readonly certification_type?: Array<LearningpathsListCertificationTypeEnum>

  /**
   * Multiple values may be separated by commas.
   * @type {Array<string>}
   * @memberof LearningpathsApiLearningpathsList
   */
  readonly course_feature?: Array<string>

  /**
   * The delivery of course/program resources  * &#x60;online&#x60; - Online * &#x60;hybrid&#x60; - Hybrid * &#x60;in_person&#x60; - In person * &#x60;offline&#x60; - Offline
   * @type {Array<Array<'online' | 'hybrid' | 'in_person' | 'offline'>>}
   * @memberof LearningpathsApiLearningpathsList
   */
  readonly delivery?: Array<Array<LearningpathsListDeliveryEnum>>

  /**
   * The department that offers learning resources  * &#x60;1&#x60; - Civil and Environmental Engineering * &#x60;2&#x60; - Mechanical Engineering * &#x60;3&#x60; - Materials Science and Engineering * &#x60;4&#x60; - Architecture * &#x60;5&#x60; - Chemistry * &#x60;6&#x60; - Electrical Engineering and Computer Science * &#x60;7&#x60; - Biology * &#x60;8&#x60; - Physics * &#x60;9&#x60; - Brain and Cognitive Sciences * &#x60;10&#x60; - Chemical Engineering * &#x60;11&#x60; - Urban Studies and Planning * &#x60;12&#x60; - Earth, Atmospheric, and Planetary Sciences * &#x60;14&#x60; - Economics * &#x60;15&#x60; - Management * &#x60;16&#x60; - Aeronautics and Astronautics * &#x60;17&#x60; - Political Science * &#x60;18&#x60; - Mathematics * &#x60;20&#x60; - Biological Engineering * &#x60;21A&#x60; - Anthropology * &#x60;21G&#x60; - Global Languages * &#x60;21H&#x60; - History * &#x60;21L&#x60; - Literature * &#x60;21M&#x60; - Music and Theater Arts * &#x60;22&#x60; - Nuclear Science and Engineering * &#x60;24&#x60; - Linguistics and Philosophy * &#x60;CC&#x60; - Concourse * &#x60;CMS-W&#x60; - Comparative Media Studies/Writing * &#x60;EC&#x60; - Edgerton Center * &#x60;ES&#x60; - Experimental Study Group * &#x60;ESD&#x60; - Engineering Systems Division * &#x60;HST&#x60; - Medical Engineering and Science * &#x60;IDS&#x60; - Data, Systems, and Society * &#x60;MAS&#x60; - Media Arts and Sciences * &#x60;PE&#x60; - Athletics, Physical Education and Recreation * &#x60;SP&#x60; - Special Programs * &#x60;STS&#x60; - Science, Technology, and Society * &#x60;WGS&#x60; - Women\&#39;s and Gender Studies
   * @type {Array<'1' | '10' | '11' | '12' | '14' | '15' | '16' | '17' | '18' | '2' | '20' | '21A' | '21G' | '21H' | '21L' | '21M' | '22' | '24' | '3' | '4' | '5' | '6' | '7' | '8' | '9' | 'CC' | 'CMS-W' | 'EC' | 'ES' | 'ESD' | 'HST' | 'IDS' | 'MAS' | 'PE' | 'SP' | 'STS' | 'WGS'>}
   * @memberof LearningpathsApiLearningpathsList
   */
  readonly department?: Array<LearningpathsListDepartmentEnum>

  /**
   * The course/program is offered for free
   * @type {boolean}
   * @memberof LearningpathsApiLearningpathsList
   */
  readonly free?: boolean

  /**
   * The academic level of the resources  * &#x60;undergraduate&#x60; - Undergraduate * &#x60;graduate&#x60; - Graduate * &#x60;high_school&#x60; - High School * &#x60;noncredit&#x60; - Non-Credit * &#x60;advanced&#x60; - Advanced * &#x60;intermediate&#x60; - Intermediate * &#x60;introductory&#x60; - Introductory
   * @type {Array<'advanced' | 'graduate' | 'high_school' | 'intermediate' | 'introductory' | 'noncredit' | 'undergraduate'>}
   * @memberof LearningpathsApiLearningpathsList
   */
  readonly level?: Array<LearningpathsListLevelEnum>

  /**
   * Number of results to return per page.
   * @type {number}
   * @memberof LearningpathsApiLearningpathsList
   */
  readonly limit?: number

  /**
   * The organization that offers a learning resource  * &#x60;mitx&#x60; - MITx * &#x60;ocw&#x60; - MIT OpenCourseWare * &#x60;bootcamps&#x60; - Bootcamps * &#x60;xpro&#x60; - MIT xPRO * &#x60;mitpe&#x60; - MIT Professional Education * &#x60;see&#x60; - MIT Sloan Executive Education
   * @type {Array<'bootcamps' | 'mitpe' | 'mitx' | 'ocw' | 'see' | 'xpro'>}
   * @memberof LearningpathsApiLearningpathsList
   */
  readonly offered_by?: Array<LearningpathsListOfferedByEnum>

  /**
   * The initial index from which to return the results.
   * @type {number}
   * @memberof LearningpathsApiLearningpathsList
   */
  readonly offset?: number

  /**
   * The platform on which learning resources are offered  * &#x60;edx&#x60; - edX * &#x60;ocw&#x60; - MIT OpenCourseWare * &#x60;oll&#x60; - Open Learning Library * &#x60;mitxonline&#x60; - MITx Online * &#x60;bootcamps&#x60; - Bootcamps * &#x60;xpro&#x60; - MIT xPRO * &#x60;csail&#x60; - CSAIL * &#x60;mitpe&#x60; - MIT Professional Education * &#x60;see&#x60; - MIT Sloan Executive Education * &#x60;scc&#x60; - Schwarzman College of Computing * &#x60;ctl&#x60; - Center for Transportation &amp; Logistics * &#x60;whu&#x60; - WHU * &#x60;susskind&#x60; - Susskind * &#x60;globalalumni&#x60; - Global Alumni * &#x60;simplilearn&#x60; - Simplilearn * &#x60;emeritus&#x60; - Emeritus * &#x60;podcast&#x60; - Podcast * &#x60;youtube&#x60; - YouTube
   * @type {Array<'bootcamps' | 'csail' | 'ctl' | 'edx' | 'emeritus' | 'globalalumni' | 'mitpe' | 'mitxonline' | 'ocw' | 'oll' | 'podcast' | 'scc' | 'see' | 'simplilearn' | 'susskind' | 'whu' | 'xpro' | 'youtube'>}
   * @memberof LearningpathsApiLearningpathsList
   */
  readonly platform?: Array<LearningpathsListPlatformEnum>

  /**
   *
   * @type {boolean}
   * @memberof LearningpathsApiLearningpathsList
   */
  readonly professional?: boolean

  /**
   * Multiple values may be separated by commas.
   * @type {Array<string>}
   * @memberof LearningpathsApiLearningpathsList
   */
  readonly readable_id?: Array<string>

  /**
   * The resource category of the learning resources  * &#x60;course&#x60; - Course * &#x60;program&#x60; - Program * &#x60;learning_material&#x60; - Learning Material
   * @type {Array<'course' | 'learning_material' | 'program'>}
   * @memberof LearningpathsApiLearningpathsList
   */
  readonly resource_category?: Array<LearningpathsListResourceCategoryEnum>

  /**
   * The type of learning resource  * &#x60;course&#x60; - Course * &#x60;program&#x60; - Program * &#x60;learning_path&#x60; - Learning Path * &#x60;podcast&#x60; - Podcast * &#x60;podcast_episode&#x60; - Podcast Episode * &#x60;video&#x60; - Video * &#x60;video_playlist&#x60; - Video Playlist
   * @type {Array<'course' | 'learning_path' | 'podcast' | 'podcast_episode' | 'program' | 'video' | 'video_playlist'>}
   * @memberof LearningpathsApiLearningpathsList
   */
  readonly resource_type?: Array<LearningpathsListResourceTypeEnum>

  /**
   * Sort By  * &#x60;id&#x60; - Object ID ascending * &#x60;-id&#x60; - Object ID descending * &#x60;readable_id&#x60; - Readable ID ascending * &#x60;-readable_id&#x60; - Readable ID descending * &#x60;last_modified&#x60; - Last Modified Date ascending * &#x60;-last_modified&#x60; - Last Modified Date descending * &#x60;new&#x60; - Newest resources first * &#x60;start_date&#x60; - Start Date ascending * &#x60;-start_date&#x60; - Start Date descending * &#x60;mitcoursenumber&#x60; - MIT course number ascending * &#x60;-mitcoursenumber&#x60; - MIT course number descending * &#x60;views&#x60; - Popularity ascending * &#x60;-views&#x60; - Popularity descending * &#x60;upcoming&#x60; - Next start date ascending
   * @type {'-id' | '-last_modified' | '-mitcoursenumber' | '-readable_id' | '-start_date' | '-views' | 'id' | 'last_modified' | 'mitcoursenumber' | 'new' | 'readable_id' | 'start_date' | 'upcoming' | 'views'}
   * @memberof LearningpathsApiLearningpathsList
   */
  readonly sortby?: LearningpathsListSortbyEnum

  /**
   * Multiple values may be separated by commas.
   * @type {Array<string>}
   * @memberof LearningpathsApiLearningpathsList
   */
  readonly topic?: Array<string>
}

/**
 * Request parameters for learningpathsPartialUpdate operation in LearningpathsApi.
 * @export
 * @interface LearningpathsApiLearningpathsPartialUpdateRequest
 */
export interface LearningpathsApiLearningpathsPartialUpdateRequest {
  /**
   * A unique integer value identifying this learning resource.
   * @type {number}
   * @memberof LearningpathsApiLearningpathsPartialUpdate
   */
  readonly id: number

  /**
   *
   * @type {PatchedLearningPathResourceRequest}
   * @memberof LearningpathsApiLearningpathsPartialUpdate
   */
  readonly PatchedLearningPathResourceRequest?: PatchedLearningPathResourceRequest
}

/**
 * Request parameters for learningpathsRetrieve operation in LearningpathsApi.
 * @export
 * @interface LearningpathsApiLearningpathsRetrieveRequest
 */
export interface LearningpathsApiLearningpathsRetrieveRequest {
  /**
   * A unique integer value identifying this learning resource.
   * @type {number}
   * @memberof LearningpathsApiLearningpathsRetrieve
   */
  readonly id: number
}

/**
 * LearningpathsApi - object-oriented interface
 * @export
 * @class LearningpathsApi
 * @extends {BaseAPI}
 */
export class LearningpathsApi extends BaseAPI {
  /**
   * Create a learning path
   * @summary Create
   * @param {LearningpathsApiLearningpathsCreateRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof LearningpathsApi
   */
  public learningpathsCreate(
    requestParameters: LearningpathsApiLearningpathsCreateRequest,
    options?: RawAxiosRequestConfig,
  ) {
    return LearningpathsApiFp(this.configuration)
      .learningpathsCreate(
        requestParameters.LearningPathResourceRequest,
        options,
      )
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   * Remove a learning path
   * @summary Destroy
   * @param {LearningpathsApiLearningpathsDestroyRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof LearningpathsApi
   */
  public learningpathsDestroy(
    requestParameters: LearningpathsApiLearningpathsDestroyRequest,
    options?: RawAxiosRequestConfig,
  ) {
    return LearningpathsApiFp(this.configuration)
      .learningpathsDestroy(requestParameters.id, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   * Viewset for LearningPath related resources
   * @summary Learning Path Resource Relationship Add
   * @param {LearningpathsApiLearningpathsItemsCreateRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof LearningpathsApi
   */
  public learningpathsItemsCreate(
    requestParameters: LearningpathsApiLearningpathsItemsCreateRequest,
    options?: RawAxiosRequestConfig,
  ) {
    return LearningpathsApiFp(this.configuration)
      .learningpathsItemsCreate(
        requestParameters.learning_resource_id,
        requestParameters.LearningPathRelationshipRequest,
        options,
      )
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   * Viewset for LearningPath related resources
   * @summary Learning Path Resource Relationship Remove
   * @param {LearningpathsApiLearningpathsItemsDestroyRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof LearningpathsApi
   */
  public learningpathsItemsDestroy(
    requestParameters: LearningpathsApiLearningpathsItemsDestroyRequest,
    options?: RawAxiosRequestConfig,
  ) {
    return LearningpathsApiFp(this.configuration)
      .learningpathsItemsDestroy(
        requestParameters.id,
        requestParameters.learning_resource_id,
        options,
      )
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   * Viewset for LearningPath related resources
   * @param {LearningpathsApiLearningpathsItemsListRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof LearningpathsApi
   */
  public learningpathsItemsList(
    requestParameters: LearningpathsApiLearningpathsItemsListRequest,
    options?: RawAxiosRequestConfig,
  ) {
    return LearningpathsApiFp(this.configuration)
      .learningpathsItemsList(
        requestParameters.learning_resource_id,
        requestParameters.limit,
        requestParameters.offset,
        requestParameters.sortby,
        options,
      )
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   * Viewset for LearningPath related resources
   * @summary Learning Path Resource Relationship Update
   * @param {LearningpathsApiLearningpathsItemsPartialUpdateRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof LearningpathsApi
   */
  public learningpathsItemsPartialUpdate(
    requestParameters: LearningpathsApiLearningpathsItemsPartialUpdateRequest,
    options?: RawAxiosRequestConfig,
  ) {
    return LearningpathsApiFp(this.configuration)
      .learningpathsItemsPartialUpdate(
        requestParameters.id,
        requestParameters.learning_resource_id,
        requestParameters.PatchedLearningPathRelationshipRequest,
        options,
      )
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   * Get a singe related learning resource for a learning resource.
   * @summary Nested Learning Resource Retrieve
   * @param {LearningpathsApiLearningpathsItemsRetrieveRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof LearningpathsApi
   */
  public learningpathsItemsRetrieve(
    requestParameters: LearningpathsApiLearningpathsItemsRetrieveRequest,
    options?: RawAxiosRequestConfig,
  ) {
    return LearningpathsApiFp(this.configuration)
      .learningpathsItemsRetrieve(
        requestParameters.id,
        requestParameters.learning_resource_id,
        options,
      )
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   * Get a paginated list of learning paths
   * @summary List
   * @param {LearningpathsApiLearningpathsListRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof LearningpathsApi
   */
  public learningpathsList(
    requestParameters: LearningpathsApiLearningpathsListRequest = {},
    options?: RawAxiosRequestConfig,
  ) {
    return LearningpathsApiFp(this.configuration)
      .learningpathsList(
        requestParameters.certification,
        requestParameters.certification_type,
        requestParameters.course_feature,
        requestParameters.delivery,
        requestParameters.department,
        requestParameters.free,
        requestParameters.level,
        requestParameters.limit,
        requestParameters.offered_by,
        requestParameters.offset,
        requestParameters.platform,
        requestParameters.professional,
        requestParameters.readable_id,
        requestParameters.resource_category,
        requestParameters.resource_type,
        requestParameters.sortby,
        requestParameters.topic,
        options,
      )
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   * Get a list of all learning path items
   * @summary List
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof LearningpathsApi
   */
  public learningpathsMembershipList(options?: RawAxiosRequestConfig) {
    return LearningpathsApiFp(this.configuration)
      .learningpathsMembershipList(options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   * Update individual fields of a learning path
   * @summary Update
   * @param {LearningpathsApiLearningpathsPartialUpdateRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof LearningpathsApi
   */
  public learningpathsPartialUpdate(
    requestParameters: LearningpathsApiLearningpathsPartialUpdateRequest,
    options?: RawAxiosRequestConfig,
  ) {
    return LearningpathsApiFp(this.configuration)
      .learningpathsPartialUpdate(
        requestParameters.id,
        requestParameters.PatchedLearningPathResourceRequest,
        options,
      )
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   * Retrive a single learning path
   * @summary Retrieve
   * @param {LearningpathsApiLearningpathsRetrieveRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof LearningpathsApi
   */
  public learningpathsRetrieve(
    requestParameters: LearningpathsApiLearningpathsRetrieveRequest,
    options?: RawAxiosRequestConfig,
  ) {
    return LearningpathsApiFp(this.configuration)
      .learningpathsRetrieve(requestParameters.id, options)
      .then((request) => request(this.axios, this.basePath))
  }
}

/**
 * @export
 */
export const LearningpathsListCertificationTypeEnum = {
  Completion: "completion",
  Micromasters: "micromasters",
  None: "none",
  Professional: "professional",
} as const
export type LearningpathsListCertificationTypeEnum =
  (typeof LearningpathsListCertificationTypeEnum)[keyof typeof LearningpathsListCertificationTypeEnum]
/**
 * @export
 */
export const LearningpathsListDeliveryEnum = {
  Online: "online",
  Hybrid: "hybrid",
  InPerson: "in_person",
  Offline: "offline",
} as const
export type LearningpathsListDeliveryEnum =
  (typeof LearningpathsListDeliveryEnum)[keyof typeof LearningpathsListDeliveryEnum]
/**
 * @export
 */
export const LearningpathsListDepartmentEnum = {
  _1: "1",
  _10: "10",
  _11: "11",
  _12: "12",
  _14: "14",
  _15: "15",
  _16: "16",
  _17: "17",
  _18: "18",
  _2: "2",
  _20: "20",
  _21A: "21A",
  _21G: "21G",
  _21H: "21H",
  _21L: "21L",
  _21M: "21M",
  _22: "22",
  _24: "24",
  _3: "3",
  _4: "4",
  _5: "5",
  _6: "6",
  _7: "7",
  _8: "8",
  _9: "9",
  Cc: "CC",
  CmsW: "CMS-W",
  Ec: "EC",
  Es: "ES",
  Esd: "ESD",
  Hst: "HST",
  Ids: "IDS",
  Mas: "MAS",
  Pe: "PE",
  Sp: "SP",
  Sts: "STS",
  Wgs: "WGS",
} as const
export type LearningpathsListDepartmentEnum =
  (typeof LearningpathsListDepartmentEnum)[keyof typeof LearningpathsListDepartmentEnum]
/**
 * @export
 */
export const LearningpathsListLevelEnum = {
  Advanced: "advanced",
  Graduate: "graduate",
  HighSchool: "high_school",
  Intermediate: "intermediate",
  Introductory: "introductory",
  Noncredit: "noncredit",
  Undergraduate: "undergraduate",
} as const
export type LearningpathsListLevelEnum =
  (typeof LearningpathsListLevelEnum)[keyof typeof LearningpathsListLevelEnum]
/**
 * @export
 */
export const LearningpathsListOfferedByEnum = {
  Bootcamps: "bootcamps",
  Mitpe: "mitpe",
  Mitx: "mitx",
  Ocw: "ocw",
  See: "see",
  Xpro: "xpro",
} as const
export type LearningpathsListOfferedByEnum =
  (typeof LearningpathsListOfferedByEnum)[keyof typeof LearningpathsListOfferedByEnum]
/**
 * @export
 */
export const LearningpathsListPlatformEnum = {
  Bootcamps: "bootcamps",
  Csail: "csail",
  Ctl: "ctl",
  Edx: "edx",
  Emeritus: "emeritus",
  Globalalumni: "globalalumni",
  Mitpe: "mitpe",
  Mitxonline: "mitxonline",
  Ocw: "ocw",
  Oll: "oll",
  Podcast: "podcast",
  Scc: "scc",
  See: "see",
  Simplilearn: "simplilearn",
  Susskind: "susskind",
  Whu: "whu",
  Xpro: "xpro",
  Youtube: "youtube",
} as const
export type LearningpathsListPlatformEnum =
  (typeof LearningpathsListPlatformEnum)[keyof typeof LearningpathsListPlatformEnum]
/**
 * @export
 */
export const LearningpathsListResourceCategoryEnum = {
  Course: "course",
  LearningMaterial: "learning_material",
  Program: "program",
} as const
export type LearningpathsListResourceCategoryEnum =
  (typeof LearningpathsListResourceCategoryEnum)[keyof typeof LearningpathsListResourceCategoryEnum]
/**
 * @export
 */
export const LearningpathsListResourceTypeEnum = {
  Course: "course",
  LearningPath: "learning_path",
  Podcast: "podcast",
  PodcastEpisode: "podcast_episode",
  Program: "program",
  Video: "video",
  VideoPlaylist: "video_playlist",
} as const
export type LearningpathsListResourceTypeEnum =
  (typeof LearningpathsListResourceTypeEnum)[keyof typeof LearningpathsListResourceTypeEnum]
/**
 * @export
 */
export const LearningpathsListSortbyEnum = {
  Id: "-id",
  LastModified: "-last_modified",
  Mitcoursenumber: "-mitcoursenumber",
  ReadableId: "-readable_id",
  StartDate: "-start_date",
  Views: "-views",
  Id2: "id",
  LastModified2: "last_modified",
  Mitcoursenumber2: "mitcoursenumber",
  New: "new",
  ReadableId2: "readable_id",
  StartDate2: "start_date",
  Upcoming: "upcoming",
  Views2: "views",
} as const
export type LearningpathsListSortbyEnum =
  (typeof LearningpathsListSortbyEnum)[keyof typeof LearningpathsListSortbyEnum]

/**
 * OfferorsApi - axios parameter creator
 * @export
 */
export const OfferorsApiAxiosParamCreator = function (
  configuration?: Configuration,
) {
  return {
    /**
     * MIT organizations that offer learning resources
     * @summary List
     * @param {number} [limit] Number of results to return per page.
     * @param {number} [offset] The initial index from which to return the results.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    offerorsList: async (
      limit?: number,
      offset?: number,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/api/v1/offerors/`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      if (limit !== undefined) {
        localVarQueryParameter["limit"] = limit
      }

      if (offset !== undefined) {
        localVarQueryParameter["offset"] = offset
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     * MIT organizations that offer learning resources
     * @summary Retrieve
     * @param {string} code A unique value identifying this learning resource offeror.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    offerorsRetrieve: async (
      code: string,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'code' is not null or undefined
      assertParamExists("offerorsRetrieve", "code", code)
      const localVarPath = `/api/v1/offerors/{code}/`.replace(
        `{${"code"}}`,
        encodeURIComponent(String(code)),
      )
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
  }
}

/**
 * OfferorsApi - functional programming interface
 * @export
 */
export const OfferorsApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = OfferorsApiAxiosParamCreator(configuration)
  return {
    /**
     * MIT organizations that offer learning resources
     * @summary List
     * @param {number} [limit] Number of results to return per page.
     * @param {number} [offset] The initial index from which to return the results.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async offerorsList(
      limit?: number,
      offset?: number,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<PaginatedLearningResourceOfferorDetailList>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.offerorsList(
        limit,
        offset,
        options,
      )
      const index = configuration?.serverIndex ?? 0
      const operationBasePath =
        operationServerMap["OfferorsApi.offerorsList"]?.[index]?.url
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, operationBasePath || basePath)
    },
    /**
     * MIT organizations that offer learning resources
     * @summary Retrieve
     * @param {string} code A unique value identifying this learning resource offeror.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async offerorsRetrieve(
      code: string,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<LearningResourceOfferorDetail>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.offerorsRetrieve(code, options)
      const index = configuration?.serverIndex ?? 0
      const operationBasePath =
        operationServerMap["OfferorsApi.offerorsRetrieve"]?.[index]?.url
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, operationBasePath || basePath)
    },
  }
}

/**
 * OfferorsApi - factory interface
 * @export
 */
export const OfferorsApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = OfferorsApiFp(configuration)
  return {
    /**
     * MIT organizations that offer learning resources
     * @summary List
     * @param {OfferorsApiOfferorsListRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    offerorsList(
      requestParameters: OfferorsApiOfferorsListRequest = {},
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<PaginatedLearningResourceOfferorDetailList> {
      return localVarFp
        .offerorsList(
          requestParameters.limit,
          requestParameters.offset,
          options,
        )
        .then((request) => request(axios, basePath))
    },
    /**
     * MIT organizations that offer learning resources
     * @summary Retrieve
     * @param {OfferorsApiOfferorsRetrieveRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    offerorsRetrieve(
      requestParameters: OfferorsApiOfferorsRetrieveRequest,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<LearningResourceOfferorDetail> {
      return localVarFp
        .offerorsRetrieve(requestParameters.code, options)
        .then((request) => request(axios, basePath))
    },
  }
}

/**
 * Request parameters for offerorsList operation in OfferorsApi.
 * @export
 * @interface OfferorsApiOfferorsListRequest
 */
export interface OfferorsApiOfferorsListRequest {
  /**
   * Number of results to return per page.
   * @type {number}
   * @memberof OfferorsApiOfferorsList
   */
  readonly limit?: number

  /**
   * The initial index from which to return the results.
   * @type {number}
   * @memberof OfferorsApiOfferorsList
   */
  readonly offset?: number
}

/**
 * Request parameters for offerorsRetrieve operation in OfferorsApi.
 * @export
 * @interface OfferorsApiOfferorsRetrieveRequest
 */
export interface OfferorsApiOfferorsRetrieveRequest {
  /**
   * A unique value identifying this learning resource offeror.
   * @type {string}
   * @memberof OfferorsApiOfferorsRetrieve
   */
  readonly code: string
}

/**
 * OfferorsApi - object-oriented interface
 * @export
 * @class OfferorsApi
 * @extends {BaseAPI}
 */
export class OfferorsApi extends BaseAPI {
  /**
   * MIT organizations that offer learning resources
   * @summary List
   * @param {OfferorsApiOfferorsListRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof OfferorsApi
   */
  public offerorsList(
    requestParameters: OfferorsApiOfferorsListRequest = {},
    options?: RawAxiosRequestConfig,
  ) {
    return OfferorsApiFp(this.configuration)
      .offerorsList(requestParameters.limit, requestParameters.offset, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   * MIT organizations that offer learning resources
   * @summary Retrieve
   * @param {OfferorsApiOfferorsRetrieveRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof OfferorsApi
   */
  public offerorsRetrieve(
    requestParameters: OfferorsApiOfferorsRetrieveRequest,
    options?: RawAxiosRequestConfig,
  ) {
    return OfferorsApiFp(this.configuration)
      .offerorsRetrieve(requestParameters.code, options)
      .then((request) => request(this.axios, this.basePath))
  }
}

/**
 * PlatformsApi - axios parameter creator
 * @export
 */
export const PlatformsApiAxiosParamCreator = function (
  configuration?: Configuration,
) {
  return {
    /**
     * Platforms on which learning resources are hosted
     * @summary List
     * @param {number} [limit] Number of results to return per page.
     * @param {number} [offset] The initial index from which to return the results.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    platformsList: async (
      limit?: number,
      offset?: number,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/api/v1/platforms/`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      if (limit !== undefined) {
        localVarQueryParameter["limit"] = limit
      }

      if (offset !== undefined) {
        localVarQueryParameter["offset"] = offset
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     * Platforms on which learning resources are hosted
     * @summary Retrieve
     * @param {string} code A unique value identifying this learning resource platform.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    platformsRetrieve: async (
      code: string,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'code' is not null or undefined
      assertParamExists("platformsRetrieve", "code", code)
      const localVarPath = `/api/v1/platforms/{code}/`.replace(
        `{${"code"}}`,
        encodeURIComponent(String(code)),
      )
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
  }
}

/**
 * PlatformsApi - functional programming interface
 * @export
 */
export const PlatformsApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = PlatformsApiAxiosParamCreator(configuration)
  return {
    /**
     * Platforms on which learning resources are hosted
     * @summary List
     * @param {number} [limit] Number of results to return per page.
     * @param {number} [offset] The initial index from which to return the results.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async platformsList(
      limit?: number,
      offset?: number,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<PaginatedLearningResourcePlatformList>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.platformsList(
        limit,
        offset,
        options,
      )
      const index = configuration?.serverIndex ?? 0
      const operationBasePath =
        operationServerMap["PlatformsApi.platformsList"]?.[index]?.url
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, operationBasePath || basePath)
    },
    /**
     * Platforms on which learning resources are hosted
     * @summary Retrieve
     * @param {string} code A unique value identifying this learning resource platform.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async platformsRetrieve(
      code: string,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<LearningResourcePlatform>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.platformsRetrieve(code, options)
      const index = configuration?.serverIndex ?? 0
      const operationBasePath =
        operationServerMap["PlatformsApi.platformsRetrieve"]?.[index]?.url
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, operationBasePath || basePath)
    },
  }
}

/**
 * PlatformsApi - factory interface
 * @export
 */
export const PlatformsApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = PlatformsApiFp(configuration)
  return {
    /**
     * Platforms on which learning resources are hosted
     * @summary List
     * @param {PlatformsApiPlatformsListRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    platformsList(
      requestParameters: PlatformsApiPlatformsListRequest = {},
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<PaginatedLearningResourcePlatformList> {
      return localVarFp
        .platformsList(
          requestParameters.limit,
          requestParameters.offset,
          options,
        )
        .then((request) => request(axios, basePath))
    },
    /**
     * Platforms on which learning resources are hosted
     * @summary Retrieve
     * @param {PlatformsApiPlatformsRetrieveRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    platformsRetrieve(
      requestParameters: PlatformsApiPlatformsRetrieveRequest,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<LearningResourcePlatform> {
      return localVarFp
        .platformsRetrieve(requestParameters.code, options)
        .then((request) => request(axios, basePath))
    },
  }
}

/**
 * Request parameters for platformsList operation in PlatformsApi.
 * @export
 * @interface PlatformsApiPlatformsListRequest
 */
export interface PlatformsApiPlatformsListRequest {
  /**
   * Number of results to return per page.
   * @type {number}
   * @memberof PlatformsApiPlatformsList
   */
  readonly limit?: number

  /**
   * The initial index from which to return the results.
   * @type {number}
   * @memberof PlatformsApiPlatformsList
   */
  readonly offset?: number
}

/**
 * Request parameters for platformsRetrieve operation in PlatformsApi.
 * @export
 * @interface PlatformsApiPlatformsRetrieveRequest
 */
export interface PlatformsApiPlatformsRetrieveRequest {
  /**
   * A unique value identifying this learning resource platform.
   * @type {string}
   * @memberof PlatformsApiPlatformsRetrieve
   */
  readonly code: string
}

/**
 * PlatformsApi - object-oriented interface
 * @export
 * @class PlatformsApi
 * @extends {BaseAPI}
 */
export class PlatformsApi extends BaseAPI {
  /**
   * Platforms on which learning resources are hosted
   * @summary List
   * @param {PlatformsApiPlatformsListRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PlatformsApi
   */
  public platformsList(
    requestParameters: PlatformsApiPlatformsListRequest = {},
    options?: RawAxiosRequestConfig,
  ) {
    return PlatformsApiFp(this.configuration)
      .platformsList(requestParameters.limit, requestParameters.offset, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   * Platforms on which learning resources are hosted
   * @summary Retrieve
   * @param {PlatformsApiPlatformsRetrieveRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PlatformsApi
   */
  public platformsRetrieve(
    requestParameters: PlatformsApiPlatformsRetrieveRequest,
    options?: RawAxiosRequestConfig,
  ) {
    return PlatformsApiFp(this.configuration)
      .platformsRetrieve(requestParameters.code, options)
      .then((request) => request(this.axios, this.basePath))
  }
}

/**
 * PodcastEpisodesApi - axios parameter creator
 * @export
 */
export const PodcastEpisodesApiAxiosParamCreator = function (
  configuration?: Configuration,
) {
  return {
    /**
     * Get a paginated list of podcast episodes
     * @summary List
     * @param {boolean} [certification]
     * @param {Array<PodcastEpisodesListCertificationTypeEnum>} [certification_type] The type of certification offered  * &#x60;micromasters&#x60; - MicroMasters Credential * &#x60;professional&#x60; - Professional Certificate * &#x60;completion&#x60; - Certificate of Completion * &#x60;none&#x60; - No Certificate
     * @param {Array<string>} [course_feature] Multiple values may be separated by commas.
     * @param {Array<Array<PodcastEpisodesListDeliveryEnum>>} [delivery] The delivery of course/program resources  * &#x60;online&#x60; - Online * &#x60;hybrid&#x60; - Hybrid * &#x60;in_person&#x60; - In person * &#x60;offline&#x60; - Offline
     * @param {Array<PodcastEpisodesListDepartmentEnum>} [department] The department that offers learning resources  * &#x60;1&#x60; - Civil and Environmental Engineering * &#x60;2&#x60; - Mechanical Engineering * &#x60;3&#x60; - Materials Science and Engineering * &#x60;4&#x60; - Architecture * &#x60;5&#x60; - Chemistry * &#x60;6&#x60; - Electrical Engineering and Computer Science * &#x60;7&#x60; - Biology * &#x60;8&#x60; - Physics * &#x60;9&#x60; - Brain and Cognitive Sciences * &#x60;10&#x60; - Chemical Engineering * &#x60;11&#x60; - Urban Studies and Planning * &#x60;12&#x60; - Earth, Atmospheric, and Planetary Sciences * &#x60;14&#x60; - Economics * &#x60;15&#x60; - Management * &#x60;16&#x60; - Aeronautics and Astronautics * &#x60;17&#x60; - Political Science * &#x60;18&#x60; - Mathematics * &#x60;20&#x60; - Biological Engineering * &#x60;21A&#x60; - Anthropology * &#x60;21G&#x60; - Global Languages * &#x60;21H&#x60; - History * &#x60;21L&#x60; - Literature * &#x60;21M&#x60; - Music and Theater Arts * &#x60;22&#x60; - Nuclear Science and Engineering * &#x60;24&#x60; - Linguistics and Philosophy * &#x60;CC&#x60; - Concourse * &#x60;CMS-W&#x60; - Comparative Media Studies/Writing * &#x60;EC&#x60; - Edgerton Center * &#x60;ES&#x60; - Experimental Study Group * &#x60;ESD&#x60; - Engineering Systems Division * &#x60;HST&#x60; - Medical Engineering and Science * &#x60;IDS&#x60; - Data, Systems, and Society * &#x60;MAS&#x60; - Media Arts and Sciences * &#x60;PE&#x60; - Athletics, Physical Education and Recreation * &#x60;SP&#x60; - Special Programs * &#x60;STS&#x60; - Science, Technology, and Society * &#x60;WGS&#x60; - Women\&#39;s and Gender Studies
     * @param {boolean} [free] The course/program is offered for free
     * @param {Array<PodcastEpisodesListLevelEnum>} [level] The academic level of the resources  * &#x60;undergraduate&#x60; - Undergraduate * &#x60;graduate&#x60; - Graduate * &#x60;high_school&#x60; - High School * &#x60;noncredit&#x60; - Non-Credit * &#x60;advanced&#x60; - Advanced * &#x60;intermediate&#x60; - Intermediate * &#x60;introductory&#x60; - Introductory
     * @param {number} [limit] Number of results to return per page.
     * @param {Array<PodcastEpisodesListOfferedByEnum>} [offered_by] The organization that offers a learning resource  * &#x60;mitx&#x60; - MITx * &#x60;ocw&#x60; - MIT OpenCourseWare * &#x60;bootcamps&#x60; - Bootcamps * &#x60;xpro&#x60; - MIT xPRO * &#x60;mitpe&#x60; - MIT Professional Education * &#x60;see&#x60; - MIT Sloan Executive Education
     * @param {number} [offset] The initial index from which to return the results.
     * @param {Array<PodcastEpisodesListPlatformEnum>} [platform] The platform on which learning resources are offered  * &#x60;edx&#x60; - edX * &#x60;ocw&#x60; - MIT OpenCourseWare * &#x60;oll&#x60; - Open Learning Library * &#x60;mitxonline&#x60; - MITx Online * &#x60;bootcamps&#x60; - Bootcamps * &#x60;xpro&#x60; - MIT xPRO * &#x60;csail&#x60; - CSAIL * &#x60;mitpe&#x60; - MIT Professional Education * &#x60;see&#x60; - MIT Sloan Executive Education * &#x60;scc&#x60; - Schwarzman College of Computing * &#x60;ctl&#x60; - Center for Transportation &amp; Logistics * &#x60;whu&#x60; - WHU * &#x60;susskind&#x60; - Susskind * &#x60;globalalumni&#x60; - Global Alumni * &#x60;simplilearn&#x60; - Simplilearn * &#x60;emeritus&#x60; - Emeritus * &#x60;podcast&#x60; - Podcast * &#x60;youtube&#x60; - YouTube
     * @param {boolean} [professional]
     * @param {Array<string>} [readable_id] Multiple values may be separated by commas.
     * @param {Array<PodcastEpisodesListResourceCategoryEnum>} [resource_category] The resource category of the learning resources  * &#x60;course&#x60; - Course * &#x60;program&#x60; - Program * &#x60;learning_material&#x60; - Learning Material
     * @param {Array<PodcastEpisodesListResourceTypeEnum>} [resource_type] The type of learning resource  * &#x60;course&#x60; - Course * &#x60;program&#x60; - Program * &#x60;learning_path&#x60; - Learning Path * &#x60;podcast&#x60; - Podcast * &#x60;podcast_episode&#x60; - Podcast Episode * &#x60;video&#x60; - Video * &#x60;video_playlist&#x60; - Video Playlist
     * @param {PodcastEpisodesListSortbyEnum} [sortby] Sort By  * &#x60;id&#x60; - Object ID ascending * &#x60;-id&#x60; - Object ID descending * &#x60;readable_id&#x60; - Readable ID ascending * &#x60;-readable_id&#x60; - Readable ID descending * &#x60;last_modified&#x60; - Last Modified Date ascending * &#x60;-last_modified&#x60; - Last Modified Date descending * &#x60;new&#x60; - Newest resources first * &#x60;start_date&#x60; - Start Date ascending * &#x60;-start_date&#x60; - Start Date descending * &#x60;mitcoursenumber&#x60; - MIT course number ascending * &#x60;-mitcoursenumber&#x60; - MIT course number descending * &#x60;views&#x60; - Popularity ascending * &#x60;-views&#x60; - Popularity descending * &#x60;upcoming&#x60; - Next start date ascending
     * @param {Array<string>} [topic] Multiple values may be separated by commas.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    podcastEpisodesList: async (
      certification?: boolean,
      certification_type?: Array<PodcastEpisodesListCertificationTypeEnum>,
      course_feature?: Array<string>,
      delivery?: Array<Array<PodcastEpisodesListDeliveryEnum>>,
      department?: Array<PodcastEpisodesListDepartmentEnum>,
      free?: boolean,
      level?: Array<PodcastEpisodesListLevelEnum>,
      limit?: number,
      offered_by?: Array<PodcastEpisodesListOfferedByEnum>,
      offset?: number,
      platform?: Array<PodcastEpisodesListPlatformEnum>,
      professional?: boolean,
      readable_id?: Array<string>,
      resource_category?: Array<PodcastEpisodesListResourceCategoryEnum>,
      resource_type?: Array<PodcastEpisodesListResourceTypeEnum>,
      sortby?: PodcastEpisodesListSortbyEnum,
      topic?: Array<string>,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/api/v1/podcast_episodes/`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      if (certification !== undefined) {
        localVarQueryParameter["certification"] = certification
      }

      if (certification_type) {
        localVarQueryParameter["certification_type"] = certification_type
      }

      if (course_feature) {
        localVarQueryParameter["course_feature"] = course_feature.join(
          COLLECTION_FORMATS.csv,
        )
      }

      if (delivery) {
        localVarQueryParameter["delivery"] = delivery
      }

      if (department) {
        localVarQueryParameter["department"] = department
      }

      if (free !== undefined) {
        localVarQueryParameter["free"] = free
      }

      if (level) {
        localVarQueryParameter["level"] = level
      }

      if (limit !== undefined) {
        localVarQueryParameter["limit"] = limit
      }

      if (offered_by) {
        localVarQueryParameter["offered_by"] = offered_by
      }

      if (offset !== undefined) {
        localVarQueryParameter["offset"] = offset
      }

      if (platform) {
        localVarQueryParameter["platform"] = platform
      }

      if (professional !== undefined) {
        localVarQueryParameter["professional"] = professional
      }

      if (readable_id) {
        localVarQueryParameter["readable_id"] = readable_id.join(
          COLLECTION_FORMATS.csv,
        )
      }

      if (resource_category) {
        localVarQueryParameter["resource_category"] = resource_category
      }

      if (resource_type) {
        localVarQueryParameter["resource_type"] = resource_type
      }

      if (sortby !== undefined) {
        localVarQueryParameter["sortby"] = sortby
      }

      if (topic) {
        localVarQueryParameter["topic"] = topic.join(COLLECTION_FORMATS.csv)
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     * Retrieve a single podcast episode
     * @summary Retrieve
     * @param {number} id A unique integer value identifying this learning resource.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    podcastEpisodesRetrieve: async (
      id: number,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists("podcastEpisodesRetrieve", "id", id)
      const localVarPath = `/api/v1/podcast_episodes/{id}/`.replace(
        `{${"id"}}`,
        encodeURIComponent(String(id)),
      )
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
  }
}

/**
 * PodcastEpisodesApi - functional programming interface
 * @export
 */
export const PodcastEpisodesApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator =
    PodcastEpisodesApiAxiosParamCreator(configuration)
  return {
    /**
     * Get a paginated list of podcast episodes
     * @summary List
     * @param {boolean} [certification]
     * @param {Array<PodcastEpisodesListCertificationTypeEnum>} [certification_type] The type of certification offered  * &#x60;micromasters&#x60; - MicroMasters Credential * &#x60;professional&#x60; - Professional Certificate * &#x60;completion&#x60; - Certificate of Completion * &#x60;none&#x60; - No Certificate
     * @param {Array<string>} [course_feature] Multiple values may be separated by commas.
     * @param {Array<Array<PodcastEpisodesListDeliveryEnum>>} [delivery] The delivery of course/program resources  * &#x60;online&#x60; - Online * &#x60;hybrid&#x60; - Hybrid * &#x60;in_person&#x60; - In person * &#x60;offline&#x60; - Offline
     * @param {Array<PodcastEpisodesListDepartmentEnum>} [department] The department that offers learning resources  * &#x60;1&#x60; - Civil and Environmental Engineering * &#x60;2&#x60; - Mechanical Engineering * &#x60;3&#x60; - Materials Science and Engineering * &#x60;4&#x60; - Architecture * &#x60;5&#x60; - Chemistry * &#x60;6&#x60; - Electrical Engineering and Computer Science * &#x60;7&#x60; - Biology * &#x60;8&#x60; - Physics * &#x60;9&#x60; - Brain and Cognitive Sciences * &#x60;10&#x60; - Chemical Engineering * &#x60;11&#x60; - Urban Studies and Planning * &#x60;12&#x60; - Earth, Atmospheric, and Planetary Sciences * &#x60;14&#x60; - Economics * &#x60;15&#x60; - Management * &#x60;16&#x60; - Aeronautics and Astronautics * &#x60;17&#x60; - Political Science * &#x60;18&#x60; - Mathematics * &#x60;20&#x60; - Biological Engineering * &#x60;21A&#x60; - Anthropology * &#x60;21G&#x60; - Global Languages * &#x60;21H&#x60; - History * &#x60;21L&#x60; - Literature * &#x60;21M&#x60; - Music and Theater Arts * &#x60;22&#x60; - Nuclear Science and Engineering * &#x60;24&#x60; - Linguistics and Philosophy * &#x60;CC&#x60; - Concourse * &#x60;CMS-W&#x60; - Comparative Media Studies/Writing * &#x60;EC&#x60; - Edgerton Center * &#x60;ES&#x60; - Experimental Study Group * &#x60;ESD&#x60; - Engineering Systems Division * &#x60;HST&#x60; - Medical Engineering and Science * &#x60;IDS&#x60; - Data, Systems, and Society * &#x60;MAS&#x60; - Media Arts and Sciences * &#x60;PE&#x60; - Athletics, Physical Education and Recreation * &#x60;SP&#x60; - Special Programs * &#x60;STS&#x60; - Science, Technology, and Society * &#x60;WGS&#x60; - Women\&#39;s and Gender Studies
     * @param {boolean} [free] The course/program is offered for free
     * @param {Array<PodcastEpisodesListLevelEnum>} [level] The academic level of the resources  * &#x60;undergraduate&#x60; - Undergraduate * &#x60;graduate&#x60; - Graduate * &#x60;high_school&#x60; - High School * &#x60;noncredit&#x60; - Non-Credit * &#x60;advanced&#x60; - Advanced * &#x60;intermediate&#x60; - Intermediate * &#x60;introductory&#x60; - Introductory
     * @param {number} [limit] Number of results to return per page.
     * @param {Array<PodcastEpisodesListOfferedByEnum>} [offered_by] The organization that offers a learning resource  * &#x60;mitx&#x60; - MITx * &#x60;ocw&#x60; - MIT OpenCourseWare * &#x60;bootcamps&#x60; - Bootcamps * &#x60;xpro&#x60; - MIT xPRO * &#x60;mitpe&#x60; - MIT Professional Education * &#x60;see&#x60; - MIT Sloan Executive Education
     * @param {number} [offset] The initial index from which to return the results.
     * @param {Array<PodcastEpisodesListPlatformEnum>} [platform] The platform on which learning resources are offered  * &#x60;edx&#x60; - edX * &#x60;ocw&#x60; - MIT OpenCourseWare * &#x60;oll&#x60; - Open Learning Library * &#x60;mitxonline&#x60; - MITx Online * &#x60;bootcamps&#x60; - Bootcamps * &#x60;xpro&#x60; - MIT xPRO * &#x60;csail&#x60; - CSAIL * &#x60;mitpe&#x60; - MIT Professional Education * &#x60;see&#x60; - MIT Sloan Executive Education * &#x60;scc&#x60; - Schwarzman College of Computing * &#x60;ctl&#x60; - Center for Transportation &amp; Logistics * &#x60;whu&#x60; - WHU * &#x60;susskind&#x60; - Susskind * &#x60;globalalumni&#x60; - Global Alumni * &#x60;simplilearn&#x60; - Simplilearn * &#x60;emeritus&#x60; - Emeritus * &#x60;podcast&#x60; - Podcast * &#x60;youtube&#x60; - YouTube
     * @param {boolean} [professional]
     * @param {Array<string>} [readable_id] Multiple values may be separated by commas.
     * @param {Array<PodcastEpisodesListResourceCategoryEnum>} [resource_category] The resource category of the learning resources  * &#x60;course&#x60; - Course * &#x60;program&#x60; - Program * &#x60;learning_material&#x60; - Learning Material
     * @param {Array<PodcastEpisodesListResourceTypeEnum>} [resource_type] The type of learning resource  * &#x60;course&#x60; - Course * &#x60;program&#x60; - Program * &#x60;learning_path&#x60; - Learning Path * &#x60;podcast&#x60; - Podcast * &#x60;podcast_episode&#x60; - Podcast Episode * &#x60;video&#x60; - Video * &#x60;video_playlist&#x60; - Video Playlist
     * @param {PodcastEpisodesListSortbyEnum} [sortby] Sort By  * &#x60;id&#x60; - Object ID ascending * &#x60;-id&#x60; - Object ID descending * &#x60;readable_id&#x60; - Readable ID ascending * &#x60;-readable_id&#x60; - Readable ID descending * &#x60;last_modified&#x60; - Last Modified Date ascending * &#x60;-last_modified&#x60; - Last Modified Date descending * &#x60;new&#x60; - Newest resources first * &#x60;start_date&#x60; - Start Date ascending * &#x60;-start_date&#x60; - Start Date descending * &#x60;mitcoursenumber&#x60; - MIT course number ascending * &#x60;-mitcoursenumber&#x60; - MIT course number descending * &#x60;views&#x60; - Popularity ascending * &#x60;-views&#x60; - Popularity descending * &#x60;upcoming&#x60; - Next start date ascending
     * @param {Array<string>} [topic] Multiple values may be separated by commas.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async podcastEpisodesList(
      certification?: boolean,
      certification_type?: Array<PodcastEpisodesListCertificationTypeEnum>,
      course_feature?: Array<string>,
      delivery?: Array<Array<PodcastEpisodesListDeliveryEnum>>,
      department?: Array<PodcastEpisodesListDepartmentEnum>,
      free?: boolean,
      level?: Array<PodcastEpisodesListLevelEnum>,
      limit?: number,
      offered_by?: Array<PodcastEpisodesListOfferedByEnum>,
      offset?: number,
      platform?: Array<PodcastEpisodesListPlatformEnum>,
      professional?: boolean,
      readable_id?: Array<string>,
      resource_category?: Array<PodcastEpisodesListResourceCategoryEnum>,
      resource_type?: Array<PodcastEpisodesListResourceTypeEnum>,
      sortby?: PodcastEpisodesListSortbyEnum,
      topic?: Array<string>,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<PaginatedPodcastEpisodeResourceList>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.podcastEpisodesList(
          certification,
          certification_type,
          course_feature,
          delivery,
          department,
          free,
          level,
          limit,
          offered_by,
          offset,
          platform,
          professional,
          readable_id,
          resource_category,
          resource_type,
          sortby,
          topic,
          options,
        )
      const index = configuration?.serverIndex ?? 0
      const operationBasePath =
        operationServerMap["PodcastEpisodesApi.podcastEpisodesList"]?.[index]
          ?.url
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, operationBasePath || basePath)
    },
    /**
     * Retrieve a single podcast episode
     * @summary Retrieve
     * @param {number} id A unique integer value identifying this learning resource.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async podcastEpisodesRetrieve(
      id: number,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<PodcastEpisodeResource>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.podcastEpisodesRetrieve(id, options)
      const index = configuration?.serverIndex ?? 0
      const operationBasePath =
        operationServerMap["PodcastEpisodesApi.podcastEpisodesRetrieve"]?.[
          index
        ]?.url
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, operationBasePath || basePath)
    },
  }
}

/**
 * PodcastEpisodesApi - factory interface
 * @export
 */
export const PodcastEpisodesApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = PodcastEpisodesApiFp(configuration)
  return {
    /**
     * Get a paginated list of podcast episodes
     * @summary List
     * @param {PodcastEpisodesApiPodcastEpisodesListRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    podcastEpisodesList(
      requestParameters: PodcastEpisodesApiPodcastEpisodesListRequest = {},
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<PaginatedPodcastEpisodeResourceList> {
      return localVarFp
        .podcastEpisodesList(
          requestParameters.certification,
          requestParameters.certification_type,
          requestParameters.course_feature,
          requestParameters.delivery,
          requestParameters.department,
          requestParameters.free,
          requestParameters.level,
          requestParameters.limit,
          requestParameters.offered_by,
          requestParameters.offset,
          requestParameters.platform,
          requestParameters.professional,
          requestParameters.readable_id,
          requestParameters.resource_category,
          requestParameters.resource_type,
          requestParameters.sortby,
          requestParameters.topic,
          options,
        )
        .then((request) => request(axios, basePath))
    },
    /**
     * Retrieve a single podcast episode
     * @summary Retrieve
     * @param {PodcastEpisodesApiPodcastEpisodesRetrieveRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    podcastEpisodesRetrieve(
      requestParameters: PodcastEpisodesApiPodcastEpisodesRetrieveRequest,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<PodcastEpisodeResource> {
      return localVarFp
        .podcastEpisodesRetrieve(requestParameters.id, options)
        .then((request) => request(axios, basePath))
    },
  }
}

/**
 * Request parameters for podcastEpisodesList operation in PodcastEpisodesApi.
 * @export
 * @interface PodcastEpisodesApiPodcastEpisodesListRequest
 */
export interface PodcastEpisodesApiPodcastEpisodesListRequest {
  /**
   *
   * @type {boolean}
   * @memberof PodcastEpisodesApiPodcastEpisodesList
   */
  readonly certification?: boolean

  /**
   * The type of certification offered  * &#x60;micromasters&#x60; - MicroMasters Credential * &#x60;professional&#x60; - Professional Certificate * &#x60;completion&#x60; - Certificate of Completion * &#x60;none&#x60; - No Certificate
   * @type {Array<'completion' | 'micromasters' | 'none' | 'professional'>}
   * @memberof PodcastEpisodesApiPodcastEpisodesList
   */
  readonly certification_type?: Array<PodcastEpisodesListCertificationTypeEnum>

  /**
   * Multiple values may be separated by commas.
   * @type {Array<string>}
   * @memberof PodcastEpisodesApiPodcastEpisodesList
   */
  readonly course_feature?: Array<string>

  /**
   * The delivery of course/program resources  * &#x60;online&#x60; - Online * &#x60;hybrid&#x60; - Hybrid * &#x60;in_person&#x60; - In person * &#x60;offline&#x60; - Offline
   * @type {Array<Array<'online' | 'hybrid' | 'in_person' | 'offline'>>}
   * @memberof PodcastEpisodesApiPodcastEpisodesList
   */
  readonly delivery?: Array<Array<PodcastEpisodesListDeliveryEnum>>

  /**
   * The department that offers learning resources  * &#x60;1&#x60; - Civil and Environmental Engineering * &#x60;2&#x60; - Mechanical Engineering * &#x60;3&#x60; - Materials Science and Engineering * &#x60;4&#x60; - Architecture * &#x60;5&#x60; - Chemistry * &#x60;6&#x60; - Electrical Engineering and Computer Science * &#x60;7&#x60; - Biology * &#x60;8&#x60; - Physics * &#x60;9&#x60; - Brain and Cognitive Sciences * &#x60;10&#x60; - Chemical Engineering * &#x60;11&#x60; - Urban Studies and Planning * &#x60;12&#x60; - Earth, Atmospheric, and Planetary Sciences * &#x60;14&#x60; - Economics * &#x60;15&#x60; - Management * &#x60;16&#x60; - Aeronautics and Astronautics * &#x60;17&#x60; - Political Science * &#x60;18&#x60; - Mathematics * &#x60;20&#x60; - Biological Engineering * &#x60;21A&#x60; - Anthropology * &#x60;21G&#x60; - Global Languages * &#x60;21H&#x60; - History * &#x60;21L&#x60; - Literature * &#x60;21M&#x60; - Music and Theater Arts * &#x60;22&#x60; - Nuclear Science and Engineering * &#x60;24&#x60; - Linguistics and Philosophy * &#x60;CC&#x60; - Concourse * &#x60;CMS-W&#x60; - Comparative Media Studies/Writing * &#x60;EC&#x60; - Edgerton Center * &#x60;ES&#x60; - Experimental Study Group * &#x60;ESD&#x60; - Engineering Systems Division * &#x60;HST&#x60; - Medical Engineering and Science * &#x60;IDS&#x60; - Data, Systems, and Society * &#x60;MAS&#x60; - Media Arts and Sciences * &#x60;PE&#x60; - Athletics, Physical Education and Recreation * &#x60;SP&#x60; - Special Programs * &#x60;STS&#x60; - Science, Technology, and Society * &#x60;WGS&#x60; - Women\&#39;s and Gender Studies
   * @type {Array<'1' | '10' | '11' | '12' | '14' | '15' | '16' | '17' | '18' | '2' | '20' | '21A' | '21G' | '21H' | '21L' | '21M' | '22' | '24' | '3' | '4' | '5' | '6' | '7' | '8' | '9' | 'CC' | 'CMS-W' | 'EC' | 'ES' | 'ESD' | 'HST' | 'IDS' | 'MAS' | 'PE' | 'SP' | 'STS' | 'WGS'>}
   * @memberof PodcastEpisodesApiPodcastEpisodesList
   */
  readonly department?: Array<PodcastEpisodesListDepartmentEnum>

  /**
   * The course/program is offered for free
   * @type {boolean}
   * @memberof PodcastEpisodesApiPodcastEpisodesList
   */
  readonly free?: boolean

  /**
   * The academic level of the resources  * &#x60;undergraduate&#x60; - Undergraduate * &#x60;graduate&#x60; - Graduate * &#x60;high_school&#x60; - High School * &#x60;noncredit&#x60; - Non-Credit * &#x60;advanced&#x60; - Advanced * &#x60;intermediate&#x60; - Intermediate * &#x60;introductory&#x60; - Introductory
   * @type {Array<'advanced' | 'graduate' | 'high_school' | 'intermediate' | 'introductory' | 'noncredit' | 'undergraduate'>}
   * @memberof PodcastEpisodesApiPodcastEpisodesList
   */
  readonly level?: Array<PodcastEpisodesListLevelEnum>

  /**
   * Number of results to return per page.
   * @type {number}
   * @memberof PodcastEpisodesApiPodcastEpisodesList
   */
  readonly limit?: number

  /**
   * The organization that offers a learning resource  * &#x60;mitx&#x60; - MITx * &#x60;ocw&#x60; - MIT OpenCourseWare * &#x60;bootcamps&#x60; - Bootcamps * &#x60;xpro&#x60; - MIT xPRO * &#x60;mitpe&#x60; - MIT Professional Education * &#x60;see&#x60; - MIT Sloan Executive Education
   * @type {Array<'bootcamps' | 'mitpe' | 'mitx' | 'ocw' | 'see' | 'xpro'>}
   * @memberof PodcastEpisodesApiPodcastEpisodesList
   */
  readonly offered_by?: Array<PodcastEpisodesListOfferedByEnum>

  /**
   * The initial index from which to return the results.
   * @type {number}
   * @memberof PodcastEpisodesApiPodcastEpisodesList
   */
  readonly offset?: number

  /**
   * The platform on which learning resources are offered  * &#x60;edx&#x60; - edX * &#x60;ocw&#x60; - MIT OpenCourseWare * &#x60;oll&#x60; - Open Learning Library * &#x60;mitxonline&#x60; - MITx Online * &#x60;bootcamps&#x60; - Bootcamps * &#x60;xpro&#x60; - MIT xPRO * &#x60;csail&#x60; - CSAIL * &#x60;mitpe&#x60; - MIT Professional Education * &#x60;see&#x60; - MIT Sloan Executive Education * &#x60;scc&#x60; - Schwarzman College of Computing * &#x60;ctl&#x60; - Center for Transportation &amp; Logistics * &#x60;whu&#x60; - WHU * &#x60;susskind&#x60; - Susskind * &#x60;globalalumni&#x60; - Global Alumni * &#x60;simplilearn&#x60; - Simplilearn * &#x60;emeritus&#x60; - Emeritus * &#x60;podcast&#x60; - Podcast * &#x60;youtube&#x60; - YouTube
   * @type {Array<'bootcamps' | 'csail' | 'ctl' | 'edx' | 'emeritus' | 'globalalumni' | 'mitpe' | 'mitxonline' | 'ocw' | 'oll' | 'podcast' | 'scc' | 'see' | 'simplilearn' | 'susskind' | 'whu' | 'xpro' | 'youtube'>}
   * @memberof PodcastEpisodesApiPodcastEpisodesList
   */
  readonly platform?: Array<PodcastEpisodesListPlatformEnum>

  /**
   *
   * @type {boolean}
   * @memberof PodcastEpisodesApiPodcastEpisodesList
   */
  readonly professional?: boolean

  /**
   * Multiple values may be separated by commas.
   * @type {Array<string>}
   * @memberof PodcastEpisodesApiPodcastEpisodesList
   */
  readonly readable_id?: Array<string>

  /**
   * The resource category of the learning resources  * &#x60;course&#x60; - Course * &#x60;program&#x60; - Program * &#x60;learning_material&#x60; - Learning Material
   * @type {Array<'course' | 'learning_material' | 'program'>}
   * @memberof PodcastEpisodesApiPodcastEpisodesList
   */
  readonly resource_category?: Array<PodcastEpisodesListResourceCategoryEnum>

  /**
   * The type of learning resource  * &#x60;course&#x60; - Course * &#x60;program&#x60; - Program * &#x60;learning_path&#x60; - Learning Path * &#x60;podcast&#x60; - Podcast * &#x60;podcast_episode&#x60; - Podcast Episode * &#x60;video&#x60; - Video * &#x60;video_playlist&#x60; - Video Playlist
   * @type {Array<'course' | 'learning_path' | 'podcast' | 'podcast_episode' | 'program' | 'video' | 'video_playlist'>}
   * @memberof PodcastEpisodesApiPodcastEpisodesList
   */
  readonly resource_type?: Array<PodcastEpisodesListResourceTypeEnum>

  /**
   * Sort By  * &#x60;id&#x60; - Object ID ascending * &#x60;-id&#x60; - Object ID descending * &#x60;readable_id&#x60; - Readable ID ascending * &#x60;-readable_id&#x60; - Readable ID descending * &#x60;last_modified&#x60; - Last Modified Date ascending * &#x60;-last_modified&#x60; - Last Modified Date descending * &#x60;new&#x60; - Newest resources first * &#x60;start_date&#x60; - Start Date ascending * &#x60;-start_date&#x60; - Start Date descending * &#x60;mitcoursenumber&#x60; - MIT course number ascending * &#x60;-mitcoursenumber&#x60; - MIT course number descending * &#x60;views&#x60; - Popularity ascending * &#x60;-views&#x60; - Popularity descending * &#x60;upcoming&#x60; - Next start date ascending
   * @type {'-id' | '-last_modified' | '-mitcoursenumber' | '-readable_id' | '-start_date' | '-views' | 'id' | 'last_modified' | 'mitcoursenumber' | 'new' | 'readable_id' | 'start_date' | 'upcoming' | 'views'}
   * @memberof PodcastEpisodesApiPodcastEpisodesList
   */
  readonly sortby?: PodcastEpisodesListSortbyEnum

  /**
   * Multiple values may be separated by commas.
   * @type {Array<string>}
   * @memberof PodcastEpisodesApiPodcastEpisodesList
   */
  readonly topic?: Array<string>
}

/**
 * Request parameters for podcastEpisodesRetrieve operation in PodcastEpisodesApi.
 * @export
 * @interface PodcastEpisodesApiPodcastEpisodesRetrieveRequest
 */
export interface PodcastEpisodesApiPodcastEpisodesRetrieveRequest {
  /**
   * A unique integer value identifying this learning resource.
   * @type {number}
   * @memberof PodcastEpisodesApiPodcastEpisodesRetrieve
   */
  readonly id: number
}

/**
 * PodcastEpisodesApi - object-oriented interface
 * @export
 * @class PodcastEpisodesApi
 * @extends {BaseAPI}
 */
export class PodcastEpisodesApi extends BaseAPI {
  /**
   * Get a paginated list of podcast episodes
   * @summary List
   * @param {PodcastEpisodesApiPodcastEpisodesListRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PodcastEpisodesApi
   */
  public podcastEpisodesList(
    requestParameters: PodcastEpisodesApiPodcastEpisodesListRequest = {},
    options?: RawAxiosRequestConfig,
  ) {
    return PodcastEpisodesApiFp(this.configuration)
      .podcastEpisodesList(
        requestParameters.certification,
        requestParameters.certification_type,
        requestParameters.course_feature,
        requestParameters.delivery,
        requestParameters.department,
        requestParameters.free,
        requestParameters.level,
        requestParameters.limit,
        requestParameters.offered_by,
        requestParameters.offset,
        requestParameters.platform,
        requestParameters.professional,
        requestParameters.readable_id,
        requestParameters.resource_category,
        requestParameters.resource_type,
        requestParameters.sortby,
        requestParameters.topic,
        options,
      )
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   * Retrieve a single podcast episode
   * @summary Retrieve
   * @param {PodcastEpisodesApiPodcastEpisodesRetrieveRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PodcastEpisodesApi
   */
  public podcastEpisodesRetrieve(
    requestParameters: PodcastEpisodesApiPodcastEpisodesRetrieveRequest,
    options?: RawAxiosRequestConfig,
  ) {
    return PodcastEpisodesApiFp(this.configuration)
      .podcastEpisodesRetrieve(requestParameters.id, options)
      .then((request) => request(this.axios, this.basePath))
  }
}

/**
 * @export
 */
export const PodcastEpisodesListCertificationTypeEnum = {
  Completion: "completion",
  Micromasters: "micromasters",
  None: "none",
  Professional: "professional",
} as const
export type PodcastEpisodesListCertificationTypeEnum =
  (typeof PodcastEpisodesListCertificationTypeEnum)[keyof typeof PodcastEpisodesListCertificationTypeEnum]
/**
 * @export
 */
export const PodcastEpisodesListDeliveryEnum = {
  Online: "online",
  Hybrid: "hybrid",
  InPerson: "in_person",
  Offline: "offline",
} as const
export type PodcastEpisodesListDeliveryEnum =
  (typeof PodcastEpisodesListDeliveryEnum)[keyof typeof PodcastEpisodesListDeliveryEnum]
/**
 * @export
 */
export const PodcastEpisodesListDepartmentEnum = {
  _1: "1",
  _10: "10",
  _11: "11",
  _12: "12",
  _14: "14",
  _15: "15",
  _16: "16",
  _17: "17",
  _18: "18",
  _2: "2",
  _20: "20",
  _21A: "21A",
  _21G: "21G",
  _21H: "21H",
  _21L: "21L",
  _21M: "21M",
  _22: "22",
  _24: "24",
  _3: "3",
  _4: "4",
  _5: "5",
  _6: "6",
  _7: "7",
  _8: "8",
  _9: "9",
  Cc: "CC",
  CmsW: "CMS-W",
  Ec: "EC",
  Es: "ES",
  Esd: "ESD",
  Hst: "HST",
  Ids: "IDS",
  Mas: "MAS",
  Pe: "PE",
  Sp: "SP",
  Sts: "STS",
  Wgs: "WGS",
} as const
export type PodcastEpisodesListDepartmentEnum =
  (typeof PodcastEpisodesListDepartmentEnum)[keyof typeof PodcastEpisodesListDepartmentEnum]
/**
 * @export
 */
export const PodcastEpisodesListLevelEnum = {
  Advanced: "advanced",
  Graduate: "graduate",
  HighSchool: "high_school",
  Intermediate: "intermediate",
  Introductory: "introductory",
  Noncredit: "noncredit",
  Undergraduate: "undergraduate",
} as const
export type PodcastEpisodesListLevelEnum =
  (typeof PodcastEpisodesListLevelEnum)[keyof typeof PodcastEpisodesListLevelEnum]
/**
 * @export
 */
export const PodcastEpisodesListOfferedByEnum = {
  Bootcamps: "bootcamps",
  Mitpe: "mitpe",
  Mitx: "mitx",
  Ocw: "ocw",
  See: "see",
  Xpro: "xpro",
} as const
export type PodcastEpisodesListOfferedByEnum =
  (typeof PodcastEpisodesListOfferedByEnum)[keyof typeof PodcastEpisodesListOfferedByEnum]
/**
 * @export
 */
export const PodcastEpisodesListPlatformEnum = {
  Bootcamps: "bootcamps",
  Csail: "csail",
  Ctl: "ctl",
  Edx: "edx",
  Emeritus: "emeritus",
  Globalalumni: "globalalumni",
  Mitpe: "mitpe",
  Mitxonline: "mitxonline",
  Ocw: "ocw",
  Oll: "oll",
  Podcast: "podcast",
  Scc: "scc",
  See: "see",
  Simplilearn: "simplilearn",
  Susskind: "susskind",
  Whu: "whu",
  Xpro: "xpro",
  Youtube: "youtube",
} as const
export type PodcastEpisodesListPlatformEnum =
  (typeof PodcastEpisodesListPlatformEnum)[keyof typeof PodcastEpisodesListPlatformEnum]
/**
 * @export
 */
export const PodcastEpisodesListResourceCategoryEnum = {
  Course: "course",
  LearningMaterial: "learning_material",
  Program: "program",
} as const
export type PodcastEpisodesListResourceCategoryEnum =
  (typeof PodcastEpisodesListResourceCategoryEnum)[keyof typeof PodcastEpisodesListResourceCategoryEnum]
/**
 * @export
 */
export const PodcastEpisodesListResourceTypeEnum = {
  Course: "course",
  LearningPath: "learning_path",
  Podcast: "podcast",
  PodcastEpisode: "podcast_episode",
  Program: "program",
  Video: "video",
  VideoPlaylist: "video_playlist",
} as const
export type PodcastEpisodesListResourceTypeEnum =
  (typeof PodcastEpisodesListResourceTypeEnum)[keyof typeof PodcastEpisodesListResourceTypeEnum]
/**
 * @export
 */
export const PodcastEpisodesListSortbyEnum = {
  Id: "-id",
  LastModified: "-last_modified",
  Mitcoursenumber: "-mitcoursenumber",
  ReadableId: "-readable_id",
  StartDate: "-start_date",
  Views: "-views",
  Id2: "id",
  LastModified2: "last_modified",
  Mitcoursenumber2: "mitcoursenumber",
  New: "new",
  ReadableId2: "readable_id",
  StartDate2: "start_date",
  Upcoming: "upcoming",
  Views2: "views",
} as const
export type PodcastEpisodesListSortbyEnum =
  (typeof PodcastEpisodesListSortbyEnum)[keyof typeof PodcastEpisodesListSortbyEnum]

/**
 * PodcastsApi - axios parameter creator
 * @export
 */
export const PodcastsApiAxiosParamCreator = function (
  configuration?: Configuration,
) {
  return {
    /**
     * Get a list of related learning resources for a learning resource.
     * @summary Nested Learning Resource List
     * @param {number} learning_resource_id id of the parent learning resource
     * @param {number} [limit] Number of results to return per page.
     * @param {number} [offset] The initial index from which to return the results.
     * @param {string} [sortby] Which field to use when ordering the results.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    podcastsItemsList: async (
      learning_resource_id: number,
      limit?: number,
      offset?: number,
      sortby?: string,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'learning_resource_id' is not null or undefined
      assertParamExists(
        "podcastsItemsList",
        "learning_resource_id",
        learning_resource_id,
      )
      const localVarPath =
        `/api/v1/podcasts/{learning_resource_id}/items/`.replace(
          `{${"learning_resource_id"}}`,
          encodeURIComponent(String(learning_resource_id)),
        )
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      if (limit !== undefined) {
        localVarQueryParameter["limit"] = limit
      }

      if (offset !== undefined) {
        localVarQueryParameter["offset"] = offset
      }

      if (sortby !== undefined) {
        localVarQueryParameter["sortby"] = sortby
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     * Get a singe related learning resource for a learning resource.
     * @summary Nested Learning Resource Retrieve
     * @param {number} id A unique integer value identifying this learning resource relationship.
     * @param {number} learning_resource_id id of the parent learning resource
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    podcastsItemsRetrieve: async (
      id: number,
      learning_resource_id: number,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists("podcastsItemsRetrieve", "id", id)
      // verify required parameter 'learning_resource_id' is not null or undefined
      assertParamExists(
        "podcastsItemsRetrieve",
        "learning_resource_id",
        learning_resource_id,
      )
      const localVarPath = `/api/v1/podcasts/{learning_resource_id}/items/{id}/`
        .replace(`{${"id"}}`, encodeURIComponent(String(id)))
        .replace(
          `{${"learning_resource_id"}}`,
          encodeURIComponent(String(learning_resource_id)),
        )
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     * Get a paginated list of podcasts
     * @summary List
     * @param {boolean} [certification]
     * @param {Array<PodcastsListCertificationTypeEnum>} [certification_type] The type of certification offered  * &#x60;micromasters&#x60; - MicroMasters Credential * &#x60;professional&#x60; - Professional Certificate * &#x60;completion&#x60; - Certificate of Completion * &#x60;none&#x60; - No Certificate
     * @param {Array<string>} [course_feature] Multiple values may be separated by commas.
     * @param {Array<Array<PodcastsListDeliveryEnum>>} [delivery] The delivery of course/program resources  * &#x60;online&#x60; - Online * &#x60;hybrid&#x60; - Hybrid * &#x60;in_person&#x60; - In person * &#x60;offline&#x60; - Offline
     * @param {Array<PodcastsListDepartmentEnum>} [department] The department that offers learning resources  * &#x60;1&#x60; - Civil and Environmental Engineering * &#x60;2&#x60; - Mechanical Engineering * &#x60;3&#x60; - Materials Science and Engineering * &#x60;4&#x60; - Architecture * &#x60;5&#x60; - Chemistry * &#x60;6&#x60; - Electrical Engineering and Computer Science * &#x60;7&#x60; - Biology * &#x60;8&#x60; - Physics * &#x60;9&#x60; - Brain and Cognitive Sciences * &#x60;10&#x60; - Chemical Engineering * &#x60;11&#x60; - Urban Studies and Planning * &#x60;12&#x60; - Earth, Atmospheric, and Planetary Sciences * &#x60;14&#x60; - Economics * &#x60;15&#x60; - Management * &#x60;16&#x60; - Aeronautics and Astronautics * &#x60;17&#x60; - Political Science * &#x60;18&#x60; - Mathematics * &#x60;20&#x60; - Biological Engineering * &#x60;21A&#x60; - Anthropology * &#x60;21G&#x60; - Global Languages * &#x60;21H&#x60; - History * &#x60;21L&#x60; - Literature * &#x60;21M&#x60; - Music and Theater Arts * &#x60;22&#x60; - Nuclear Science and Engineering * &#x60;24&#x60; - Linguistics and Philosophy * &#x60;CC&#x60; - Concourse * &#x60;CMS-W&#x60; - Comparative Media Studies/Writing * &#x60;EC&#x60; - Edgerton Center * &#x60;ES&#x60; - Experimental Study Group * &#x60;ESD&#x60; - Engineering Systems Division * &#x60;HST&#x60; - Medical Engineering and Science * &#x60;IDS&#x60; - Data, Systems, and Society * &#x60;MAS&#x60; - Media Arts and Sciences * &#x60;PE&#x60; - Athletics, Physical Education and Recreation * &#x60;SP&#x60; - Special Programs * &#x60;STS&#x60; - Science, Technology, and Society * &#x60;WGS&#x60; - Women\&#39;s and Gender Studies
     * @param {boolean} [free] The course/program is offered for free
     * @param {Array<PodcastsListLevelEnum>} [level] The academic level of the resources  * &#x60;undergraduate&#x60; - Undergraduate * &#x60;graduate&#x60; - Graduate * &#x60;high_school&#x60; - High School * &#x60;noncredit&#x60; - Non-Credit * &#x60;advanced&#x60; - Advanced * &#x60;intermediate&#x60; - Intermediate * &#x60;introductory&#x60; - Introductory
     * @param {number} [limit] Number of results to return per page.
     * @param {Array<PodcastsListOfferedByEnum>} [offered_by] The organization that offers a learning resource  * &#x60;mitx&#x60; - MITx * &#x60;ocw&#x60; - MIT OpenCourseWare * &#x60;bootcamps&#x60; - Bootcamps * &#x60;xpro&#x60; - MIT xPRO * &#x60;mitpe&#x60; - MIT Professional Education * &#x60;see&#x60; - MIT Sloan Executive Education
     * @param {number} [offset] The initial index from which to return the results.
     * @param {Array<PodcastsListPlatformEnum>} [platform] The platform on which learning resources are offered  * &#x60;edx&#x60; - edX * &#x60;ocw&#x60; - MIT OpenCourseWare * &#x60;oll&#x60; - Open Learning Library * &#x60;mitxonline&#x60; - MITx Online * &#x60;bootcamps&#x60; - Bootcamps * &#x60;xpro&#x60; - MIT xPRO * &#x60;csail&#x60; - CSAIL * &#x60;mitpe&#x60; - MIT Professional Education * &#x60;see&#x60; - MIT Sloan Executive Education * &#x60;scc&#x60; - Schwarzman College of Computing * &#x60;ctl&#x60; - Center for Transportation &amp; Logistics * &#x60;whu&#x60; - WHU * &#x60;susskind&#x60; - Susskind * &#x60;globalalumni&#x60; - Global Alumni * &#x60;simplilearn&#x60; - Simplilearn * &#x60;emeritus&#x60; - Emeritus * &#x60;podcast&#x60; - Podcast * &#x60;youtube&#x60; - YouTube
     * @param {boolean} [professional]
     * @param {Array<string>} [readable_id] Multiple values may be separated by commas.
     * @param {Array<PodcastsListResourceCategoryEnum>} [resource_category] The resource category of the learning resources  * &#x60;course&#x60; - Course * &#x60;program&#x60; - Program * &#x60;learning_material&#x60; - Learning Material
     * @param {Array<PodcastsListResourceTypeEnum>} [resource_type] The type of learning resource  * &#x60;course&#x60; - Course * &#x60;program&#x60; - Program * &#x60;learning_path&#x60; - Learning Path * &#x60;podcast&#x60; - Podcast * &#x60;podcast_episode&#x60; - Podcast Episode * &#x60;video&#x60; - Video * &#x60;video_playlist&#x60; - Video Playlist
     * @param {PodcastsListSortbyEnum} [sortby] Sort By  * &#x60;id&#x60; - Object ID ascending * &#x60;-id&#x60; - Object ID descending * &#x60;readable_id&#x60; - Readable ID ascending * &#x60;-readable_id&#x60; - Readable ID descending * &#x60;last_modified&#x60; - Last Modified Date ascending * &#x60;-last_modified&#x60; - Last Modified Date descending * &#x60;new&#x60; - Newest resources first * &#x60;start_date&#x60; - Start Date ascending * &#x60;-start_date&#x60; - Start Date descending * &#x60;mitcoursenumber&#x60; - MIT course number ascending * &#x60;-mitcoursenumber&#x60; - MIT course number descending * &#x60;views&#x60; - Popularity ascending * &#x60;-views&#x60; - Popularity descending * &#x60;upcoming&#x60; - Next start date ascending
     * @param {Array<string>} [topic] Multiple values may be separated by commas.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    podcastsList: async (
      certification?: boolean,
      certification_type?: Array<PodcastsListCertificationTypeEnum>,
      course_feature?: Array<string>,
      delivery?: Array<Array<PodcastsListDeliveryEnum>>,
      department?: Array<PodcastsListDepartmentEnum>,
      free?: boolean,
      level?: Array<PodcastsListLevelEnum>,
      limit?: number,
      offered_by?: Array<PodcastsListOfferedByEnum>,
      offset?: number,
      platform?: Array<PodcastsListPlatformEnum>,
      professional?: boolean,
      readable_id?: Array<string>,
      resource_category?: Array<PodcastsListResourceCategoryEnum>,
      resource_type?: Array<PodcastsListResourceTypeEnum>,
      sortby?: PodcastsListSortbyEnum,
      topic?: Array<string>,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/api/v1/podcasts/`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      if (certification !== undefined) {
        localVarQueryParameter["certification"] = certification
      }

      if (certification_type) {
        localVarQueryParameter["certification_type"] = certification_type
      }

      if (course_feature) {
        localVarQueryParameter["course_feature"] = course_feature.join(
          COLLECTION_FORMATS.csv,
        )
      }

      if (delivery) {
        localVarQueryParameter["delivery"] = delivery
      }

      if (department) {
        localVarQueryParameter["department"] = department
      }

      if (free !== undefined) {
        localVarQueryParameter["free"] = free
      }

      if (level) {
        localVarQueryParameter["level"] = level
      }

      if (limit !== undefined) {
        localVarQueryParameter["limit"] = limit
      }

      if (offered_by) {
        localVarQueryParameter["offered_by"] = offered_by
      }

      if (offset !== undefined) {
        localVarQueryParameter["offset"] = offset
      }

      if (platform) {
        localVarQueryParameter["platform"] = platform
      }

      if (professional !== undefined) {
        localVarQueryParameter["professional"] = professional
      }

      if (readable_id) {
        localVarQueryParameter["readable_id"] = readable_id.join(
          COLLECTION_FORMATS.csv,
        )
      }

      if (resource_category) {
        localVarQueryParameter["resource_category"] = resource_category
      }

      if (resource_type) {
        localVarQueryParameter["resource_type"] = resource_type
      }

      if (sortby !== undefined) {
        localVarQueryParameter["sortby"] = sortby
      }

      if (topic) {
        localVarQueryParameter["topic"] = topic.join(COLLECTION_FORMATS.csv)
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     * Retrieve a single podcast
     * @summary Retrieve
     * @param {number} id A unique integer value identifying this learning resource.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    podcastsRetrieve: async (
      id: number,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists("podcastsRetrieve", "id", id)
      const localVarPath = `/api/v1/podcasts/{id}/`.replace(
        `{${"id"}}`,
        encodeURIComponent(String(id)),
      )
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
  }
}

/**
 * PodcastsApi - functional programming interface
 * @export
 */
export const PodcastsApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = PodcastsApiAxiosParamCreator(configuration)
  return {
    /**
     * Get a list of related learning resources for a learning resource.
     * @summary Nested Learning Resource List
     * @param {number} learning_resource_id id of the parent learning resource
     * @param {number} [limit] Number of results to return per page.
     * @param {number} [offset] The initial index from which to return the results.
     * @param {string} [sortby] Which field to use when ordering the results.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async podcastsItemsList(
      learning_resource_id: number,
      limit?: number,
      offset?: number,
      sortby?: string,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<PaginatedLearningResourceRelationshipList>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.podcastsItemsList(
          learning_resource_id,
          limit,
          offset,
          sortby,
          options,
        )
      const index = configuration?.serverIndex ?? 0
      const operationBasePath =
        operationServerMap["PodcastsApi.podcastsItemsList"]?.[index]?.url
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, operationBasePath || basePath)
    },
    /**
     * Get a singe related learning resource for a learning resource.
     * @summary Nested Learning Resource Retrieve
     * @param {number} id A unique integer value identifying this learning resource relationship.
     * @param {number} learning_resource_id id of the parent learning resource
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async podcastsItemsRetrieve(
      id: number,
      learning_resource_id: number,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<LearningResourceRelationship>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.podcastsItemsRetrieve(
          id,
          learning_resource_id,
          options,
        )
      const index = configuration?.serverIndex ?? 0
      const operationBasePath =
        operationServerMap["PodcastsApi.podcastsItemsRetrieve"]?.[index]?.url
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, operationBasePath || basePath)
    },
    /**
     * Get a paginated list of podcasts
     * @summary List
     * @param {boolean} [certification]
     * @param {Array<PodcastsListCertificationTypeEnum>} [certification_type] The type of certification offered  * &#x60;micromasters&#x60; - MicroMasters Credential * &#x60;professional&#x60; - Professional Certificate * &#x60;completion&#x60; - Certificate of Completion * &#x60;none&#x60; - No Certificate
     * @param {Array<string>} [course_feature] Multiple values may be separated by commas.
     * @param {Array<Array<PodcastsListDeliveryEnum>>} [delivery] The delivery of course/program resources  * &#x60;online&#x60; - Online * &#x60;hybrid&#x60; - Hybrid * &#x60;in_person&#x60; - In person * &#x60;offline&#x60; - Offline
     * @param {Array<PodcastsListDepartmentEnum>} [department] The department that offers learning resources  * &#x60;1&#x60; - Civil and Environmental Engineering * &#x60;2&#x60; - Mechanical Engineering * &#x60;3&#x60; - Materials Science and Engineering * &#x60;4&#x60; - Architecture * &#x60;5&#x60; - Chemistry * &#x60;6&#x60; - Electrical Engineering and Computer Science * &#x60;7&#x60; - Biology * &#x60;8&#x60; - Physics * &#x60;9&#x60; - Brain and Cognitive Sciences * &#x60;10&#x60; - Chemical Engineering * &#x60;11&#x60; - Urban Studies and Planning * &#x60;12&#x60; - Earth, Atmospheric, and Planetary Sciences * &#x60;14&#x60; - Economics * &#x60;15&#x60; - Management * &#x60;16&#x60; - Aeronautics and Astronautics * &#x60;17&#x60; - Political Science * &#x60;18&#x60; - Mathematics * &#x60;20&#x60; - Biological Engineering * &#x60;21A&#x60; - Anthropology * &#x60;21G&#x60; - Global Languages * &#x60;21H&#x60; - History * &#x60;21L&#x60; - Literature * &#x60;21M&#x60; - Music and Theater Arts * &#x60;22&#x60; - Nuclear Science and Engineering * &#x60;24&#x60; - Linguistics and Philosophy * &#x60;CC&#x60; - Concourse * &#x60;CMS-W&#x60; - Comparative Media Studies/Writing * &#x60;EC&#x60; - Edgerton Center * &#x60;ES&#x60; - Experimental Study Group * &#x60;ESD&#x60; - Engineering Systems Division * &#x60;HST&#x60; - Medical Engineering and Science * &#x60;IDS&#x60; - Data, Systems, and Society * &#x60;MAS&#x60; - Media Arts and Sciences * &#x60;PE&#x60; - Athletics, Physical Education and Recreation * &#x60;SP&#x60; - Special Programs * &#x60;STS&#x60; - Science, Technology, and Society * &#x60;WGS&#x60; - Women\&#39;s and Gender Studies
     * @param {boolean} [free] The course/program is offered for free
     * @param {Array<PodcastsListLevelEnum>} [level] The academic level of the resources  * &#x60;undergraduate&#x60; - Undergraduate * &#x60;graduate&#x60; - Graduate * &#x60;high_school&#x60; - High School * &#x60;noncredit&#x60; - Non-Credit * &#x60;advanced&#x60; - Advanced * &#x60;intermediate&#x60; - Intermediate * &#x60;introductory&#x60; - Introductory
     * @param {number} [limit] Number of results to return per page.
     * @param {Array<PodcastsListOfferedByEnum>} [offered_by] The organization that offers a learning resource  * &#x60;mitx&#x60; - MITx * &#x60;ocw&#x60; - MIT OpenCourseWare * &#x60;bootcamps&#x60; - Bootcamps * &#x60;xpro&#x60; - MIT xPRO * &#x60;mitpe&#x60; - MIT Professional Education * &#x60;see&#x60; - MIT Sloan Executive Education
     * @param {number} [offset] The initial index from which to return the results.
     * @param {Array<PodcastsListPlatformEnum>} [platform] The platform on which learning resources are offered  * &#x60;edx&#x60; - edX * &#x60;ocw&#x60; - MIT OpenCourseWare * &#x60;oll&#x60; - Open Learning Library * &#x60;mitxonline&#x60; - MITx Online * &#x60;bootcamps&#x60; - Bootcamps * &#x60;xpro&#x60; - MIT xPRO * &#x60;csail&#x60; - CSAIL * &#x60;mitpe&#x60; - MIT Professional Education * &#x60;see&#x60; - MIT Sloan Executive Education * &#x60;scc&#x60; - Schwarzman College of Computing * &#x60;ctl&#x60; - Center for Transportation &amp; Logistics * &#x60;whu&#x60; - WHU * &#x60;susskind&#x60; - Susskind * &#x60;globalalumni&#x60; - Global Alumni * &#x60;simplilearn&#x60; - Simplilearn * &#x60;emeritus&#x60; - Emeritus * &#x60;podcast&#x60; - Podcast * &#x60;youtube&#x60; - YouTube
     * @param {boolean} [professional]
     * @param {Array<string>} [readable_id] Multiple values may be separated by commas.
     * @param {Array<PodcastsListResourceCategoryEnum>} [resource_category] The resource category of the learning resources  * &#x60;course&#x60; - Course * &#x60;program&#x60; - Program * &#x60;learning_material&#x60; - Learning Material
     * @param {Array<PodcastsListResourceTypeEnum>} [resource_type] The type of learning resource  * &#x60;course&#x60; - Course * &#x60;program&#x60; - Program * &#x60;learning_path&#x60; - Learning Path * &#x60;podcast&#x60; - Podcast * &#x60;podcast_episode&#x60; - Podcast Episode * &#x60;video&#x60; - Video * &#x60;video_playlist&#x60; - Video Playlist
     * @param {PodcastsListSortbyEnum} [sortby] Sort By  * &#x60;id&#x60; - Object ID ascending * &#x60;-id&#x60; - Object ID descending * &#x60;readable_id&#x60; - Readable ID ascending * &#x60;-readable_id&#x60; - Readable ID descending * &#x60;last_modified&#x60; - Last Modified Date ascending * &#x60;-last_modified&#x60; - Last Modified Date descending * &#x60;new&#x60; - Newest resources first * &#x60;start_date&#x60; - Start Date ascending * &#x60;-start_date&#x60; - Start Date descending * &#x60;mitcoursenumber&#x60; - MIT course number ascending * &#x60;-mitcoursenumber&#x60; - MIT course number descending * &#x60;views&#x60; - Popularity ascending * &#x60;-views&#x60; - Popularity descending * &#x60;upcoming&#x60; - Next start date ascending
     * @param {Array<string>} [topic] Multiple values may be separated by commas.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async podcastsList(
      certification?: boolean,
      certification_type?: Array<PodcastsListCertificationTypeEnum>,
      course_feature?: Array<string>,
      delivery?: Array<Array<PodcastsListDeliveryEnum>>,
      department?: Array<PodcastsListDepartmentEnum>,
      free?: boolean,
      level?: Array<PodcastsListLevelEnum>,
      limit?: number,
      offered_by?: Array<PodcastsListOfferedByEnum>,
      offset?: number,
      platform?: Array<PodcastsListPlatformEnum>,
      professional?: boolean,
      readable_id?: Array<string>,
      resource_category?: Array<PodcastsListResourceCategoryEnum>,
      resource_type?: Array<PodcastsListResourceTypeEnum>,
      sortby?: PodcastsListSortbyEnum,
      topic?: Array<string>,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<PaginatedPodcastResourceList>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.podcastsList(
        certification,
        certification_type,
        course_feature,
        delivery,
        department,
        free,
        level,
        limit,
        offered_by,
        offset,
        platform,
        professional,
        readable_id,
        resource_category,
        resource_type,
        sortby,
        topic,
        options,
      )
      const index = configuration?.serverIndex ?? 0
      const operationBasePath =
        operationServerMap["PodcastsApi.podcastsList"]?.[index]?.url
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, operationBasePath || basePath)
    },
    /**
     * Retrieve a single podcast
     * @summary Retrieve
     * @param {number} id A unique integer value identifying this learning resource.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async podcastsRetrieve(
      id: number,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<PodcastResource>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.podcastsRetrieve(id, options)
      const index = configuration?.serverIndex ?? 0
      const operationBasePath =
        operationServerMap["PodcastsApi.podcastsRetrieve"]?.[index]?.url
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, operationBasePath || basePath)
    },
  }
}

/**
 * PodcastsApi - factory interface
 * @export
 */
export const PodcastsApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = PodcastsApiFp(configuration)
  return {
    /**
     * Get a list of related learning resources for a learning resource.
     * @summary Nested Learning Resource List
     * @param {PodcastsApiPodcastsItemsListRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    podcastsItemsList(
      requestParameters: PodcastsApiPodcastsItemsListRequest,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<PaginatedLearningResourceRelationshipList> {
      return localVarFp
        .podcastsItemsList(
          requestParameters.learning_resource_id,
          requestParameters.limit,
          requestParameters.offset,
          requestParameters.sortby,
          options,
        )
        .then((request) => request(axios, basePath))
    },
    /**
     * Get a singe related learning resource for a learning resource.
     * @summary Nested Learning Resource Retrieve
     * @param {PodcastsApiPodcastsItemsRetrieveRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    podcastsItemsRetrieve(
      requestParameters: PodcastsApiPodcastsItemsRetrieveRequest,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<LearningResourceRelationship> {
      return localVarFp
        .podcastsItemsRetrieve(
          requestParameters.id,
          requestParameters.learning_resource_id,
          options,
        )
        .then((request) => request(axios, basePath))
    },
    /**
     * Get a paginated list of podcasts
     * @summary List
     * @param {PodcastsApiPodcastsListRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    podcastsList(
      requestParameters: PodcastsApiPodcastsListRequest = {},
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<PaginatedPodcastResourceList> {
      return localVarFp
        .podcastsList(
          requestParameters.certification,
          requestParameters.certification_type,
          requestParameters.course_feature,
          requestParameters.delivery,
          requestParameters.department,
          requestParameters.free,
          requestParameters.level,
          requestParameters.limit,
          requestParameters.offered_by,
          requestParameters.offset,
          requestParameters.platform,
          requestParameters.professional,
          requestParameters.readable_id,
          requestParameters.resource_category,
          requestParameters.resource_type,
          requestParameters.sortby,
          requestParameters.topic,
          options,
        )
        .then((request) => request(axios, basePath))
    },
    /**
     * Retrieve a single podcast
     * @summary Retrieve
     * @param {PodcastsApiPodcastsRetrieveRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    podcastsRetrieve(
      requestParameters: PodcastsApiPodcastsRetrieveRequest,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<PodcastResource> {
      return localVarFp
        .podcastsRetrieve(requestParameters.id, options)
        .then((request) => request(axios, basePath))
    },
  }
}

/**
 * Request parameters for podcastsItemsList operation in PodcastsApi.
 * @export
 * @interface PodcastsApiPodcastsItemsListRequest
 */
export interface PodcastsApiPodcastsItemsListRequest {
  /**
   * id of the parent learning resource
   * @type {number}
   * @memberof PodcastsApiPodcastsItemsList
   */
  readonly learning_resource_id: number

  /**
   * Number of results to return per page.
   * @type {number}
   * @memberof PodcastsApiPodcastsItemsList
   */
  readonly limit?: number

  /**
   * The initial index from which to return the results.
   * @type {number}
   * @memberof PodcastsApiPodcastsItemsList
   */
  readonly offset?: number

  /**
   * Which field to use when ordering the results.
   * @type {string}
   * @memberof PodcastsApiPodcastsItemsList
   */
  readonly sortby?: string
}

/**
 * Request parameters for podcastsItemsRetrieve operation in PodcastsApi.
 * @export
 * @interface PodcastsApiPodcastsItemsRetrieveRequest
 */
export interface PodcastsApiPodcastsItemsRetrieveRequest {
  /**
   * A unique integer value identifying this learning resource relationship.
   * @type {number}
   * @memberof PodcastsApiPodcastsItemsRetrieve
   */
  readonly id: number

  /**
   * id of the parent learning resource
   * @type {number}
   * @memberof PodcastsApiPodcastsItemsRetrieve
   */
  readonly learning_resource_id: number
}

/**
 * Request parameters for podcastsList operation in PodcastsApi.
 * @export
 * @interface PodcastsApiPodcastsListRequest
 */
export interface PodcastsApiPodcastsListRequest {
  /**
   *
   * @type {boolean}
   * @memberof PodcastsApiPodcastsList
   */
  readonly certification?: boolean

  /**
   * The type of certification offered  * &#x60;micromasters&#x60; - MicroMasters Credential * &#x60;professional&#x60; - Professional Certificate * &#x60;completion&#x60; - Certificate of Completion * &#x60;none&#x60; - No Certificate
   * @type {Array<'completion' | 'micromasters' | 'none' | 'professional'>}
   * @memberof PodcastsApiPodcastsList
   */
  readonly certification_type?: Array<PodcastsListCertificationTypeEnum>

  /**
   * Multiple values may be separated by commas.
   * @type {Array<string>}
   * @memberof PodcastsApiPodcastsList
   */
  readonly course_feature?: Array<string>

  /**
   * The delivery of course/program resources  * &#x60;online&#x60; - Online * &#x60;hybrid&#x60; - Hybrid * &#x60;in_person&#x60; - In person * &#x60;offline&#x60; - Offline
   * @type {Array<Array<'online' | 'hybrid' | 'in_person' | 'offline'>>}
   * @memberof PodcastsApiPodcastsList
   */
  readonly delivery?: Array<Array<PodcastsListDeliveryEnum>>

  /**
   * The department that offers learning resources  * &#x60;1&#x60; - Civil and Environmental Engineering * &#x60;2&#x60; - Mechanical Engineering * &#x60;3&#x60; - Materials Science and Engineering * &#x60;4&#x60; - Architecture * &#x60;5&#x60; - Chemistry * &#x60;6&#x60; - Electrical Engineering and Computer Science * &#x60;7&#x60; - Biology * &#x60;8&#x60; - Physics * &#x60;9&#x60; - Brain and Cognitive Sciences * &#x60;10&#x60; - Chemical Engineering * &#x60;11&#x60; - Urban Studies and Planning * &#x60;12&#x60; - Earth, Atmospheric, and Planetary Sciences * &#x60;14&#x60; - Economics * &#x60;15&#x60; - Management * &#x60;16&#x60; - Aeronautics and Astronautics * &#x60;17&#x60; - Political Science * &#x60;18&#x60; - Mathematics * &#x60;20&#x60; - Biological Engineering * &#x60;21A&#x60; - Anthropology * &#x60;21G&#x60; - Global Languages * &#x60;21H&#x60; - History * &#x60;21L&#x60; - Literature * &#x60;21M&#x60; - Music and Theater Arts * &#x60;22&#x60; - Nuclear Science and Engineering * &#x60;24&#x60; - Linguistics and Philosophy * &#x60;CC&#x60; - Concourse * &#x60;CMS-W&#x60; - Comparative Media Studies/Writing * &#x60;EC&#x60; - Edgerton Center * &#x60;ES&#x60; - Experimental Study Group * &#x60;ESD&#x60; - Engineering Systems Division * &#x60;HST&#x60; - Medical Engineering and Science * &#x60;IDS&#x60; - Data, Systems, and Society * &#x60;MAS&#x60; - Media Arts and Sciences * &#x60;PE&#x60; - Athletics, Physical Education and Recreation * &#x60;SP&#x60; - Special Programs * &#x60;STS&#x60; - Science, Technology, and Society * &#x60;WGS&#x60; - Women\&#39;s and Gender Studies
   * @type {Array<'1' | '10' | '11' | '12' | '14' | '15' | '16' | '17' | '18' | '2' | '20' | '21A' | '21G' | '21H' | '21L' | '21M' | '22' | '24' | '3' | '4' | '5' | '6' | '7' | '8' | '9' | 'CC' | 'CMS-W' | 'EC' | 'ES' | 'ESD' | 'HST' | 'IDS' | 'MAS' | 'PE' | 'SP' | 'STS' | 'WGS'>}
   * @memberof PodcastsApiPodcastsList
   */
  readonly department?: Array<PodcastsListDepartmentEnum>

  /**
   * The course/program is offered for free
   * @type {boolean}
   * @memberof PodcastsApiPodcastsList
   */
  readonly free?: boolean

  /**
   * The academic level of the resources  * &#x60;undergraduate&#x60; - Undergraduate * &#x60;graduate&#x60; - Graduate * &#x60;high_school&#x60; - High School * &#x60;noncredit&#x60; - Non-Credit * &#x60;advanced&#x60; - Advanced * &#x60;intermediate&#x60; - Intermediate * &#x60;introductory&#x60; - Introductory
   * @type {Array<'advanced' | 'graduate' | 'high_school' | 'intermediate' | 'introductory' | 'noncredit' | 'undergraduate'>}
   * @memberof PodcastsApiPodcastsList
   */
  readonly level?: Array<PodcastsListLevelEnum>

  /**
   * Number of results to return per page.
   * @type {number}
   * @memberof PodcastsApiPodcastsList
   */
  readonly limit?: number

  /**
   * The organization that offers a learning resource  * &#x60;mitx&#x60; - MITx * &#x60;ocw&#x60; - MIT OpenCourseWare * &#x60;bootcamps&#x60; - Bootcamps * &#x60;xpro&#x60; - MIT xPRO * &#x60;mitpe&#x60; - MIT Professional Education * &#x60;see&#x60; - MIT Sloan Executive Education
   * @type {Array<'bootcamps' | 'mitpe' | 'mitx' | 'ocw' | 'see' | 'xpro'>}
   * @memberof PodcastsApiPodcastsList
   */
  readonly offered_by?: Array<PodcastsListOfferedByEnum>

  /**
   * The initial index from which to return the results.
   * @type {number}
   * @memberof PodcastsApiPodcastsList
   */
  readonly offset?: number

  /**
   * The platform on which learning resources are offered  * &#x60;edx&#x60; - edX * &#x60;ocw&#x60; - MIT OpenCourseWare * &#x60;oll&#x60; - Open Learning Library * &#x60;mitxonline&#x60; - MITx Online * &#x60;bootcamps&#x60; - Bootcamps * &#x60;xpro&#x60; - MIT xPRO * &#x60;csail&#x60; - CSAIL * &#x60;mitpe&#x60; - MIT Professional Education * &#x60;see&#x60; - MIT Sloan Executive Education * &#x60;scc&#x60; - Schwarzman College of Computing * &#x60;ctl&#x60; - Center for Transportation &amp; Logistics * &#x60;whu&#x60; - WHU * &#x60;susskind&#x60; - Susskind * &#x60;globalalumni&#x60; - Global Alumni * &#x60;simplilearn&#x60; - Simplilearn * &#x60;emeritus&#x60; - Emeritus * &#x60;podcast&#x60; - Podcast * &#x60;youtube&#x60; - YouTube
   * @type {Array<'bootcamps' | 'csail' | 'ctl' | 'edx' | 'emeritus' | 'globalalumni' | 'mitpe' | 'mitxonline' | 'ocw' | 'oll' | 'podcast' | 'scc' | 'see' | 'simplilearn' | 'susskind' | 'whu' | 'xpro' | 'youtube'>}
   * @memberof PodcastsApiPodcastsList
   */
  readonly platform?: Array<PodcastsListPlatformEnum>

  /**
   *
   * @type {boolean}
   * @memberof PodcastsApiPodcastsList
   */
  readonly professional?: boolean

  /**
   * Multiple values may be separated by commas.
   * @type {Array<string>}
   * @memberof PodcastsApiPodcastsList
   */
  readonly readable_id?: Array<string>

  /**
   * The resource category of the learning resources  * &#x60;course&#x60; - Course * &#x60;program&#x60; - Program * &#x60;learning_material&#x60; - Learning Material
   * @type {Array<'course' | 'learning_material' | 'program'>}
   * @memberof PodcastsApiPodcastsList
   */
  readonly resource_category?: Array<PodcastsListResourceCategoryEnum>

  /**
   * The type of learning resource  * &#x60;course&#x60; - Course * &#x60;program&#x60; - Program * &#x60;learning_path&#x60; - Learning Path * &#x60;podcast&#x60; - Podcast * &#x60;podcast_episode&#x60; - Podcast Episode * &#x60;video&#x60; - Video * &#x60;video_playlist&#x60; - Video Playlist
   * @type {Array<'course' | 'learning_path' | 'podcast' | 'podcast_episode' | 'program' | 'video' | 'video_playlist'>}
   * @memberof PodcastsApiPodcastsList
   */
  readonly resource_type?: Array<PodcastsListResourceTypeEnum>

  /**
   * Sort By  * &#x60;id&#x60; - Object ID ascending * &#x60;-id&#x60; - Object ID descending * &#x60;readable_id&#x60; - Readable ID ascending * &#x60;-readable_id&#x60; - Readable ID descending * &#x60;last_modified&#x60; - Last Modified Date ascending * &#x60;-last_modified&#x60; - Last Modified Date descending * &#x60;new&#x60; - Newest resources first * &#x60;start_date&#x60; - Start Date ascending * &#x60;-start_date&#x60; - Start Date descending * &#x60;mitcoursenumber&#x60; - MIT course number ascending * &#x60;-mitcoursenumber&#x60; - MIT course number descending * &#x60;views&#x60; - Popularity ascending * &#x60;-views&#x60; - Popularity descending * &#x60;upcoming&#x60; - Next start date ascending
   * @type {'-id' | '-last_modified' | '-mitcoursenumber' | '-readable_id' | '-start_date' | '-views' | 'id' | 'last_modified' | 'mitcoursenumber' | 'new' | 'readable_id' | 'start_date' | 'upcoming' | 'views'}
   * @memberof PodcastsApiPodcastsList
   */
  readonly sortby?: PodcastsListSortbyEnum

  /**
   * Multiple values may be separated by commas.
   * @type {Array<string>}
   * @memberof PodcastsApiPodcastsList
   */
  readonly topic?: Array<string>
}

/**
 * Request parameters for podcastsRetrieve operation in PodcastsApi.
 * @export
 * @interface PodcastsApiPodcastsRetrieveRequest
 */
export interface PodcastsApiPodcastsRetrieveRequest {
  /**
   * A unique integer value identifying this learning resource.
   * @type {number}
   * @memberof PodcastsApiPodcastsRetrieve
   */
  readonly id: number
}

/**
 * PodcastsApi - object-oriented interface
 * @export
 * @class PodcastsApi
 * @extends {BaseAPI}
 */
export class PodcastsApi extends BaseAPI {
  /**
   * Get a list of related learning resources for a learning resource.
   * @summary Nested Learning Resource List
   * @param {PodcastsApiPodcastsItemsListRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PodcastsApi
   */
  public podcastsItemsList(
    requestParameters: PodcastsApiPodcastsItemsListRequest,
    options?: RawAxiosRequestConfig,
  ) {
    return PodcastsApiFp(this.configuration)
      .podcastsItemsList(
        requestParameters.learning_resource_id,
        requestParameters.limit,
        requestParameters.offset,
        requestParameters.sortby,
        options,
      )
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   * Get a singe related learning resource for a learning resource.
   * @summary Nested Learning Resource Retrieve
   * @param {PodcastsApiPodcastsItemsRetrieveRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PodcastsApi
   */
  public podcastsItemsRetrieve(
    requestParameters: PodcastsApiPodcastsItemsRetrieveRequest,
    options?: RawAxiosRequestConfig,
  ) {
    return PodcastsApiFp(this.configuration)
      .podcastsItemsRetrieve(
        requestParameters.id,
        requestParameters.learning_resource_id,
        options,
      )
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   * Get a paginated list of podcasts
   * @summary List
   * @param {PodcastsApiPodcastsListRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PodcastsApi
   */
  public podcastsList(
    requestParameters: PodcastsApiPodcastsListRequest = {},
    options?: RawAxiosRequestConfig,
  ) {
    return PodcastsApiFp(this.configuration)
      .podcastsList(
        requestParameters.certification,
        requestParameters.certification_type,
        requestParameters.course_feature,
        requestParameters.delivery,
        requestParameters.department,
        requestParameters.free,
        requestParameters.level,
        requestParameters.limit,
        requestParameters.offered_by,
        requestParameters.offset,
        requestParameters.platform,
        requestParameters.professional,
        requestParameters.readable_id,
        requestParameters.resource_category,
        requestParameters.resource_type,
        requestParameters.sortby,
        requestParameters.topic,
        options,
      )
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   * Retrieve a single podcast
   * @summary Retrieve
   * @param {PodcastsApiPodcastsRetrieveRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PodcastsApi
   */
  public podcastsRetrieve(
    requestParameters: PodcastsApiPodcastsRetrieveRequest,
    options?: RawAxiosRequestConfig,
  ) {
    return PodcastsApiFp(this.configuration)
      .podcastsRetrieve(requestParameters.id, options)
      .then((request) => request(this.axios, this.basePath))
  }
}

/**
 * @export
 */
export const PodcastsListCertificationTypeEnum = {
  Completion: "completion",
  Micromasters: "micromasters",
  None: "none",
  Professional: "professional",
} as const
export type PodcastsListCertificationTypeEnum =
  (typeof PodcastsListCertificationTypeEnum)[keyof typeof PodcastsListCertificationTypeEnum]
/**
 * @export
 */
export const PodcastsListDeliveryEnum = {
  Online: "online",
  Hybrid: "hybrid",
  InPerson: "in_person",
  Offline: "offline",
} as const
export type PodcastsListDeliveryEnum =
  (typeof PodcastsListDeliveryEnum)[keyof typeof PodcastsListDeliveryEnum]
/**
 * @export
 */
export const PodcastsListDepartmentEnum = {
  _1: "1",
  _10: "10",
  _11: "11",
  _12: "12",
  _14: "14",
  _15: "15",
  _16: "16",
  _17: "17",
  _18: "18",
  _2: "2",
  _20: "20",
  _21A: "21A",
  _21G: "21G",
  _21H: "21H",
  _21L: "21L",
  _21M: "21M",
  _22: "22",
  _24: "24",
  _3: "3",
  _4: "4",
  _5: "5",
  _6: "6",
  _7: "7",
  _8: "8",
  _9: "9",
  Cc: "CC",
  CmsW: "CMS-W",
  Ec: "EC",
  Es: "ES",
  Esd: "ESD",
  Hst: "HST",
  Ids: "IDS",
  Mas: "MAS",
  Pe: "PE",
  Sp: "SP",
  Sts: "STS",
  Wgs: "WGS",
} as const
export type PodcastsListDepartmentEnum =
  (typeof PodcastsListDepartmentEnum)[keyof typeof PodcastsListDepartmentEnum]
/**
 * @export
 */
export const PodcastsListLevelEnum = {
  Advanced: "advanced",
  Graduate: "graduate",
  HighSchool: "high_school",
  Intermediate: "intermediate",
  Introductory: "introductory",
  Noncredit: "noncredit",
  Undergraduate: "undergraduate",
} as const
export type PodcastsListLevelEnum =
  (typeof PodcastsListLevelEnum)[keyof typeof PodcastsListLevelEnum]
/**
 * @export
 */
export const PodcastsListOfferedByEnum = {
  Bootcamps: "bootcamps",
  Mitpe: "mitpe",
  Mitx: "mitx",
  Ocw: "ocw",
  See: "see",
  Xpro: "xpro",
} as const
export type PodcastsListOfferedByEnum =
  (typeof PodcastsListOfferedByEnum)[keyof typeof PodcastsListOfferedByEnum]
/**
 * @export
 */
export const PodcastsListPlatformEnum = {
  Bootcamps: "bootcamps",
  Csail: "csail",
  Ctl: "ctl",
  Edx: "edx",
  Emeritus: "emeritus",
  Globalalumni: "globalalumni",
  Mitpe: "mitpe",
  Mitxonline: "mitxonline",
  Ocw: "ocw",
  Oll: "oll",
  Podcast: "podcast",
  Scc: "scc",
  See: "see",
  Simplilearn: "simplilearn",
  Susskind: "susskind",
  Whu: "whu",
  Xpro: "xpro",
  Youtube: "youtube",
} as const
export type PodcastsListPlatformEnum =
  (typeof PodcastsListPlatformEnum)[keyof typeof PodcastsListPlatformEnum]
/**
 * @export
 */
export const PodcastsListResourceCategoryEnum = {
  Course: "course",
  LearningMaterial: "learning_material",
  Program: "program",
} as const
export type PodcastsListResourceCategoryEnum =
  (typeof PodcastsListResourceCategoryEnum)[keyof typeof PodcastsListResourceCategoryEnum]
/**
 * @export
 */
export const PodcastsListResourceTypeEnum = {
  Course: "course",
  LearningPath: "learning_path",
  Podcast: "podcast",
  PodcastEpisode: "podcast_episode",
  Program: "program",
  Video: "video",
  VideoPlaylist: "video_playlist",
} as const
export type PodcastsListResourceTypeEnum =
  (typeof PodcastsListResourceTypeEnum)[keyof typeof PodcastsListResourceTypeEnum]
/**
 * @export
 */
export const PodcastsListSortbyEnum = {
  Id: "-id",
  LastModified: "-last_modified",
  Mitcoursenumber: "-mitcoursenumber",
  ReadableId: "-readable_id",
  StartDate: "-start_date",
  Views: "-views",
  Id2: "id",
  LastModified2: "last_modified",
  Mitcoursenumber2: "mitcoursenumber",
  New: "new",
  ReadableId2: "readable_id",
  StartDate2: "start_date",
  Upcoming: "upcoming",
  Views2: "views",
} as const
export type PodcastsListSortbyEnum =
  (typeof PodcastsListSortbyEnum)[keyof typeof PodcastsListSortbyEnum]

/**
 * ProgramLettersApi - axios parameter creator
 * @export
 */
export const ProgramLettersApiAxiosParamCreator = function (
  configuration?: Configuration,
) {
  return {
    /**
     * Detail only View for program letters
     * @param {string} id A UUID string identifying this program letter.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    programLettersRetrieve: async (
      id: string,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists("programLettersRetrieve", "id", id)
      const localVarPath = `/api/v1/program_letters/{id}/`.replace(
        `{${"id"}}`,
        encodeURIComponent(String(id)),
      )
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
  }
}

/**
 * ProgramLettersApi - functional programming interface
 * @export
 */
export const ProgramLettersApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator =
    ProgramLettersApiAxiosParamCreator(configuration)
  return {
    /**
     * Detail only View for program letters
     * @param {string} id A UUID string identifying this program letter.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async programLettersRetrieve(
      id: string,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProgramLetter>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.programLettersRetrieve(id, options)
      const index = configuration?.serverIndex ?? 0
      const operationBasePath =
        operationServerMap["ProgramLettersApi.programLettersRetrieve"]?.[index]
          ?.url
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, operationBasePath || basePath)
    },
  }
}

/**
 * ProgramLettersApi - factory interface
 * @export
 */
export const ProgramLettersApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = ProgramLettersApiFp(configuration)
  return {
    /**
     * Detail only View for program letters
     * @param {ProgramLettersApiProgramLettersRetrieveRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    programLettersRetrieve(
      requestParameters: ProgramLettersApiProgramLettersRetrieveRequest,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<ProgramLetter> {
      return localVarFp
        .programLettersRetrieve(requestParameters.id, options)
        .then((request) => request(axios, basePath))
    },
  }
}

/**
 * Request parameters for programLettersRetrieve operation in ProgramLettersApi.
 * @export
 * @interface ProgramLettersApiProgramLettersRetrieveRequest
 */
export interface ProgramLettersApiProgramLettersRetrieveRequest {
  /**
   * A UUID string identifying this program letter.
   * @type {string}
   * @memberof ProgramLettersApiProgramLettersRetrieve
   */
  readonly id: string
}

/**
 * ProgramLettersApi - object-oriented interface
 * @export
 * @class ProgramLettersApi
 * @extends {BaseAPI}
 */
export class ProgramLettersApi extends BaseAPI {
  /**
   * Detail only View for program letters
   * @param {ProgramLettersApiProgramLettersRetrieveRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ProgramLettersApi
   */
  public programLettersRetrieve(
    requestParameters: ProgramLettersApiProgramLettersRetrieveRequest,
    options?: RawAxiosRequestConfig,
  ) {
    return ProgramLettersApiFp(this.configuration)
      .programLettersRetrieve(requestParameters.id, options)
      .then((request) => request(this.axios, this.basePath))
  }
}

/**
 * ProgramsApi - axios parameter creator
 * @export
 */
export const ProgramsApiAxiosParamCreator = function (
  configuration?: Configuration,
) {
  return {
    /**
     * Get a paginated list of programs
     * @summary List
     * @param {boolean} [certification]
     * @param {Array<ProgramsListCertificationTypeEnum>} [certification_type] The type of certification offered  * &#x60;micromasters&#x60; - MicroMasters Credential * &#x60;professional&#x60; - Professional Certificate * &#x60;completion&#x60; - Certificate of Completion * &#x60;none&#x60; - No Certificate
     * @param {Array<string>} [course_feature] Multiple values may be separated by commas.
     * @param {Array<Array<ProgramsListDeliveryEnum>>} [delivery] The delivery of course/program resources  * &#x60;online&#x60; - Online * &#x60;hybrid&#x60; - Hybrid * &#x60;in_person&#x60; - In person * &#x60;offline&#x60; - Offline
     * @param {Array<ProgramsListDepartmentEnum>} [department] The department that offers learning resources  * &#x60;1&#x60; - Civil and Environmental Engineering * &#x60;2&#x60; - Mechanical Engineering * &#x60;3&#x60; - Materials Science and Engineering * &#x60;4&#x60; - Architecture * &#x60;5&#x60; - Chemistry * &#x60;6&#x60; - Electrical Engineering and Computer Science * &#x60;7&#x60; - Biology * &#x60;8&#x60; - Physics * &#x60;9&#x60; - Brain and Cognitive Sciences * &#x60;10&#x60; - Chemical Engineering * &#x60;11&#x60; - Urban Studies and Planning * &#x60;12&#x60; - Earth, Atmospheric, and Planetary Sciences * &#x60;14&#x60; - Economics * &#x60;15&#x60; - Management * &#x60;16&#x60; - Aeronautics and Astronautics * &#x60;17&#x60; - Political Science * &#x60;18&#x60; - Mathematics * &#x60;20&#x60; - Biological Engineering * &#x60;21A&#x60; - Anthropology * &#x60;21G&#x60; - Global Languages * &#x60;21H&#x60; - History * &#x60;21L&#x60; - Literature * &#x60;21M&#x60; - Music and Theater Arts * &#x60;22&#x60; - Nuclear Science and Engineering * &#x60;24&#x60; - Linguistics and Philosophy * &#x60;CC&#x60; - Concourse * &#x60;CMS-W&#x60; - Comparative Media Studies/Writing * &#x60;EC&#x60; - Edgerton Center * &#x60;ES&#x60; - Experimental Study Group * &#x60;ESD&#x60; - Engineering Systems Division * &#x60;HST&#x60; - Medical Engineering and Science * &#x60;IDS&#x60; - Data, Systems, and Society * &#x60;MAS&#x60; - Media Arts and Sciences * &#x60;PE&#x60; - Athletics, Physical Education and Recreation * &#x60;SP&#x60; - Special Programs * &#x60;STS&#x60; - Science, Technology, and Society * &#x60;WGS&#x60; - Women\&#39;s and Gender Studies
     * @param {boolean} [free] The course/program is offered for free
     * @param {Array<ProgramsListLevelEnum>} [level] The academic level of the resources  * &#x60;undergraduate&#x60; - Undergraduate * &#x60;graduate&#x60; - Graduate * &#x60;high_school&#x60; - High School * &#x60;noncredit&#x60; - Non-Credit * &#x60;advanced&#x60; - Advanced * &#x60;intermediate&#x60; - Intermediate * &#x60;introductory&#x60; - Introductory
     * @param {number} [limit] Number of results to return per page.
     * @param {Array<ProgramsListOfferedByEnum>} [offered_by] The organization that offers a learning resource  * &#x60;mitx&#x60; - MITx * &#x60;ocw&#x60; - MIT OpenCourseWare * &#x60;bootcamps&#x60; - Bootcamps * &#x60;xpro&#x60; - MIT xPRO * &#x60;mitpe&#x60; - MIT Professional Education * &#x60;see&#x60; - MIT Sloan Executive Education
     * @param {number} [offset] The initial index from which to return the results.
     * @param {Array<ProgramsListPlatformEnum>} [platform] The platform on which learning resources are offered  * &#x60;edx&#x60; - edX * &#x60;ocw&#x60; - MIT OpenCourseWare * &#x60;oll&#x60; - Open Learning Library * &#x60;mitxonline&#x60; - MITx Online * &#x60;bootcamps&#x60; - Bootcamps * &#x60;xpro&#x60; - MIT xPRO * &#x60;csail&#x60; - CSAIL * &#x60;mitpe&#x60; - MIT Professional Education * &#x60;see&#x60; - MIT Sloan Executive Education * &#x60;scc&#x60; - Schwarzman College of Computing * &#x60;ctl&#x60; - Center for Transportation &amp; Logistics * &#x60;whu&#x60; - WHU * &#x60;susskind&#x60; - Susskind * &#x60;globalalumni&#x60; - Global Alumni * &#x60;simplilearn&#x60; - Simplilearn * &#x60;emeritus&#x60; - Emeritus * &#x60;podcast&#x60; - Podcast * &#x60;youtube&#x60; - YouTube
     * @param {boolean} [professional]
     * @param {Array<string>} [readable_id] Multiple values may be separated by commas.
     * @param {Array<ProgramsListResourceCategoryEnum>} [resource_category] The resource category of the learning resources  * &#x60;course&#x60; - Course * &#x60;program&#x60; - Program * &#x60;learning_material&#x60; - Learning Material
     * @param {Array<ProgramsListResourceTypeEnum>} [resource_type] The type of learning resource  * &#x60;course&#x60; - Course * &#x60;program&#x60; - Program * &#x60;learning_path&#x60; - Learning Path * &#x60;podcast&#x60; - Podcast * &#x60;podcast_episode&#x60; - Podcast Episode * &#x60;video&#x60; - Video * &#x60;video_playlist&#x60; - Video Playlist
     * @param {ProgramsListSortbyEnum} [sortby] Sort By  * &#x60;id&#x60; - Object ID ascending * &#x60;-id&#x60; - Object ID descending * &#x60;readable_id&#x60; - Readable ID ascending * &#x60;-readable_id&#x60; - Readable ID descending * &#x60;last_modified&#x60; - Last Modified Date ascending * &#x60;-last_modified&#x60; - Last Modified Date descending * &#x60;new&#x60; - Newest resources first * &#x60;start_date&#x60; - Start Date ascending * &#x60;-start_date&#x60; - Start Date descending * &#x60;mitcoursenumber&#x60; - MIT course number ascending * &#x60;-mitcoursenumber&#x60; - MIT course number descending * &#x60;views&#x60; - Popularity ascending * &#x60;-views&#x60; - Popularity descending * &#x60;upcoming&#x60; - Next start date ascending
     * @param {Array<string>} [topic] Multiple values may be separated by commas.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    programsList: async (
      certification?: boolean,
      certification_type?: Array<ProgramsListCertificationTypeEnum>,
      course_feature?: Array<string>,
      delivery?: Array<Array<ProgramsListDeliveryEnum>>,
      department?: Array<ProgramsListDepartmentEnum>,
      free?: boolean,
      level?: Array<ProgramsListLevelEnum>,
      limit?: number,
      offered_by?: Array<ProgramsListOfferedByEnum>,
      offset?: number,
      platform?: Array<ProgramsListPlatformEnum>,
      professional?: boolean,
      readable_id?: Array<string>,
      resource_category?: Array<ProgramsListResourceCategoryEnum>,
      resource_type?: Array<ProgramsListResourceTypeEnum>,
      sortby?: ProgramsListSortbyEnum,
      topic?: Array<string>,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/api/v1/programs/`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      if (certification !== undefined) {
        localVarQueryParameter["certification"] = certification
      }

      if (certification_type) {
        localVarQueryParameter["certification_type"] = certification_type
      }

      if (course_feature) {
        localVarQueryParameter["course_feature"] = course_feature.join(
          COLLECTION_FORMATS.csv,
        )
      }

      if (delivery) {
        localVarQueryParameter["delivery"] = delivery
      }

      if (department) {
        localVarQueryParameter["department"] = department
      }

      if (free !== undefined) {
        localVarQueryParameter["free"] = free
      }

      if (level) {
        localVarQueryParameter["level"] = level
      }

      if (limit !== undefined) {
        localVarQueryParameter["limit"] = limit
      }

      if (offered_by) {
        localVarQueryParameter["offered_by"] = offered_by
      }

      if (offset !== undefined) {
        localVarQueryParameter["offset"] = offset
      }

      if (platform) {
        localVarQueryParameter["platform"] = platform
      }

      if (professional !== undefined) {
        localVarQueryParameter["professional"] = professional
      }

      if (readable_id) {
        localVarQueryParameter["readable_id"] = readable_id.join(
          COLLECTION_FORMATS.csv,
        )
      }

      if (resource_category) {
        localVarQueryParameter["resource_category"] = resource_category
      }

      if (resource_type) {
        localVarQueryParameter["resource_type"] = resource_type
      }

      if (sortby !== undefined) {
        localVarQueryParameter["sortby"] = sortby
      }

      if (topic) {
        localVarQueryParameter["topic"] = topic.join(COLLECTION_FORMATS.csv)
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     * Retrieve a single program
     * @summary Retrieve
     * @param {number} id A unique integer value identifying this learning resource.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    programsRetrieve: async (
      id: number,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists("programsRetrieve", "id", id)
      const localVarPath = `/api/v1/programs/{id}/`.replace(
        `{${"id"}}`,
        encodeURIComponent(String(id)),
      )
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
  }
}

/**
 * ProgramsApi - functional programming interface
 * @export
 */
export const ProgramsApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = ProgramsApiAxiosParamCreator(configuration)
  return {
    /**
     * Get a paginated list of programs
     * @summary List
     * @param {boolean} [certification]
     * @param {Array<ProgramsListCertificationTypeEnum>} [certification_type] The type of certification offered  * &#x60;micromasters&#x60; - MicroMasters Credential * &#x60;professional&#x60; - Professional Certificate * &#x60;completion&#x60; - Certificate of Completion * &#x60;none&#x60; - No Certificate
     * @param {Array<string>} [course_feature] Multiple values may be separated by commas.
     * @param {Array<Array<ProgramsListDeliveryEnum>>} [delivery] The delivery of course/program resources  * &#x60;online&#x60; - Online * &#x60;hybrid&#x60; - Hybrid * &#x60;in_person&#x60; - In person * &#x60;offline&#x60; - Offline
     * @param {Array<ProgramsListDepartmentEnum>} [department] The department that offers learning resources  * &#x60;1&#x60; - Civil and Environmental Engineering * &#x60;2&#x60; - Mechanical Engineering * &#x60;3&#x60; - Materials Science and Engineering * &#x60;4&#x60; - Architecture * &#x60;5&#x60; - Chemistry * &#x60;6&#x60; - Electrical Engineering and Computer Science * &#x60;7&#x60; - Biology * &#x60;8&#x60; - Physics * &#x60;9&#x60; - Brain and Cognitive Sciences * &#x60;10&#x60; - Chemical Engineering * &#x60;11&#x60; - Urban Studies and Planning * &#x60;12&#x60; - Earth, Atmospheric, and Planetary Sciences * &#x60;14&#x60; - Economics * &#x60;15&#x60; - Management * &#x60;16&#x60; - Aeronautics and Astronautics * &#x60;17&#x60; - Political Science * &#x60;18&#x60; - Mathematics * &#x60;20&#x60; - Biological Engineering * &#x60;21A&#x60; - Anthropology * &#x60;21G&#x60; - Global Languages * &#x60;21H&#x60; - History * &#x60;21L&#x60; - Literature * &#x60;21M&#x60; - Music and Theater Arts * &#x60;22&#x60; - Nuclear Science and Engineering * &#x60;24&#x60; - Linguistics and Philosophy * &#x60;CC&#x60; - Concourse * &#x60;CMS-W&#x60; - Comparative Media Studies/Writing * &#x60;EC&#x60; - Edgerton Center * &#x60;ES&#x60; - Experimental Study Group * &#x60;ESD&#x60; - Engineering Systems Division * &#x60;HST&#x60; - Medical Engineering and Science * &#x60;IDS&#x60; - Data, Systems, and Society * &#x60;MAS&#x60; - Media Arts and Sciences * &#x60;PE&#x60; - Athletics, Physical Education and Recreation * &#x60;SP&#x60; - Special Programs * &#x60;STS&#x60; - Science, Technology, and Society * &#x60;WGS&#x60; - Women\&#39;s and Gender Studies
     * @param {boolean} [free] The course/program is offered for free
     * @param {Array<ProgramsListLevelEnum>} [level] The academic level of the resources  * &#x60;undergraduate&#x60; - Undergraduate * &#x60;graduate&#x60; - Graduate * &#x60;high_school&#x60; - High School * &#x60;noncredit&#x60; - Non-Credit * &#x60;advanced&#x60; - Advanced * &#x60;intermediate&#x60; - Intermediate * &#x60;introductory&#x60; - Introductory
     * @param {number} [limit] Number of results to return per page.
     * @param {Array<ProgramsListOfferedByEnum>} [offered_by] The organization that offers a learning resource  * &#x60;mitx&#x60; - MITx * &#x60;ocw&#x60; - MIT OpenCourseWare * &#x60;bootcamps&#x60; - Bootcamps * &#x60;xpro&#x60; - MIT xPRO * &#x60;mitpe&#x60; - MIT Professional Education * &#x60;see&#x60; - MIT Sloan Executive Education
     * @param {number} [offset] The initial index from which to return the results.
     * @param {Array<ProgramsListPlatformEnum>} [platform] The platform on which learning resources are offered  * &#x60;edx&#x60; - edX * &#x60;ocw&#x60; - MIT OpenCourseWare * &#x60;oll&#x60; - Open Learning Library * &#x60;mitxonline&#x60; - MITx Online * &#x60;bootcamps&#x60; - Bootcamps * &#x60;xpro&#x60; - MIT xPRO * &#x60;csail&#x60; - CSAIL * &#x60;mitpe&#x60; - MIT Professional Education * &#x60;see&#x60; - MIT Sloan Executive Education * &#x60;scc&#x60; - Schwarzman College of Computing * &#x60;ctl&#x60; - Center for Transportation &amp; Logistics * &#x60;whu&#x60; - WHU * &#x60;susskind&#x60; - Susskind * &#x60;globalalumni&#x60; - Global Alumni * &#x60;simplilearn&#x60; - Simplilearn * &#x60;emeritus&#x60; - Emeritus * &#x60;podcast&#x60; - Podcast * &#x60;youtube&#x60; - YouTube
     * @param {boolean} [professional]
     * @param {Array<string>} [readable_id] Multiple values may be separated by commas.
     * @param {Array<ProgramsListResourceCategoryEnum>} [resource_category] The resource category of the learning resources  * &#x60;course&#x60; - Course * &#x60;program&#x60; - Program * &#x60;learning_material&#x60; - Learning Material
     * @param {Array<ProgramsListResourceTypeEnum>} [resource_type] The type of learning resource  * &#x60;course&#x60; - Course * &#x60;program&#x60; - Program * &#x60;learning_path&#x60; - Learning Path * &#x60;podcast&#x60; - Podcast * &#x60;podcast_episode&#x60; - Podcast Episode * &#x60;video&#x60; - Video * &#x60;video_playlist&#x60; - Video Playlist
     * @param {ProgramsListSortbyEnum} [sortby] Sort By  * &#x60;id&#x60; - Object ID ascending * &#x60;-id&#x60; - Object ID descending * &#x60;readable_id&#x60; - Readable ID ascending * &#x60;-readable_id&#x60; - Readable ID descending * &#x60;last_modified&#x60; - Last Modified Date ascending * &#x60;-last_modified&#x60; - Last Modified Date descending * &#x60;new&#x60; - Newest resources first * &#x60;start_date&#x60; - Start Date ascending * &#x60;-start_date&#x60; - Start Date descending * &#x60;mitcoursenumber&#x60; - MIT course number ascending * &#x60;-mitcoursenumber&#x60; - MIT course number descending * &#x60;views&#x60; - Popularity ascending * &#x60;-views&#x60; - Popularity descending * &#x60;upcoming&#x60; - Next start date ascending
     * @param {Array<string>} [topic] Multiple values may be separated by commas.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async programsList(
      certification?: boolean,
      certification_type?: Array<ProgramsListCertificationTypeEnum>,
      course_feature?: Array<string>,
      delivery?: Array<Array<ProgramsListDeliveryEnum>>,
      department?: Array<ProgramsListDepartmentEnum>,
      free?: boolean,
      level?: Array<ProgramsListLevelEnum>,
      limit?: number,
      offered_by?: Array<ProgramsListOfferedByEnum>,
      offset?: number,
      platform?: Array<ProgramsListPlatformEnum>,
      professional?: boolean,
      readable_id?: Array<string>,
      resource_category?: Array<ProgramsListResourceCategoryEnum>,
      resource_type?: Array<ProgramsListResourceTypeEnum>,
      sortby?: ProgramsListSortbyEnum,
      topic?: Array<string>,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<PaginatedProgramResourceList>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.programsList(
        certification,
        certification_type,
        course_feature,
        delivery,
        department,
        free,
        level,
        limit,
        offered_by,
        offset,
        platform,
        professional,
        readable_id,
        resource_category,
        resource_type,
        sortby,
        topic,
        options,
      )
      const index = configuration?.serverIndex ?? 0
      const operationBasePath =
        operationServerMap["ProgramsApi.programsList"]?.[index]?.url
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, operationBasePath || basePath)
    },
    /**
     * Retrieve a single program
     * @summary Retrieve
     * @param {number} id A unique integer value identifying this learning resource.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async programsRetrieve(
      id: number,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<ProgramResource>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.programsRetrieve(id, options)
      const index = configuration?.serverIndex ?? 0
      const operationBasePath =
        operationServerMap["ProgramsApi.programsRetrieve"]?.[index]?.url
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, operationBasePath || basePath)
    },
  }
}

/**
 * ProgramsApi - factory interface
 * @export
 */
export const ProgramsApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = ProgramsApiFp(configuration)
  return {
    /**
     * Get a paginated list of programs
     * @summary List
     * @param {ProgramsApiProgramsListRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    programsList(
      requestParameters: ProgramsApiProgramsListRequest = {},
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<PaginatedProgramResourceList> {
      return localVarFp
        .programsList(
          requestParameters.certification,
          requestParameters.certification_type,
          requestParameters.course_feature,
          requestParameters.delivery,
          requestParameters.department,
          requestParameters.free,
          requestParameters.level,
          requestParameters.limit,
          requestParameters.offered_by,
          requestParameters.offset,
          requestParameters.platform,
          requestParameters.professional,
          requestParameters.readable_id,
          requestParameters.resource_category,
          requestParameters.resource_type,
          requestParameters.sortby,
          requestParameters.topic,
          options,
        )
        .then((request) => request(axios, basePath))
    },
    /**
     * Retrieve a single program
     * @summary Retrieve
     * @param {ProgramsApiProgramsRetrieveRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    programsRetrieve(
      requestParameters: ProgramsApiProgramsRetrieveRequest,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<ProgramResource> {
      return localVarFp
        .programsRetrieve(requestParameters.id, options)
        .then((request) => request(axios, basePath))
    },
  }
}

/**
 * Request parameters for programsList operation in ProgramsApi.
 * @export
 * @interface ProgramsApiProgramsListRequest
 */
export interface ProgramsApiProgramsListRequest {
  /**
   *
   * @type {boolean}
   * @memberof ProgramsApiProgramsList
   */
  readonly certification?: boolean

  /**
   * The type of certification offered  * &#x60;micromasters&#x60; - MicroMasters Credential * &#x60;professional&#x60; - Professional Certificate * &#x60;completion&#x60; - Certificate of Completion * &#x60;none&#x60; - No Certificate
   * @type {Array<'completion' | 'micromasters' | 'none' | 'professional'>}
   * @memberof ProgramsApiProgramsList
   */
  readonly certification_type?: Array<ProgramsListCertificationTypeEnum>

  /**
   * Multiple values may be separated by commas.
   * @type {Array<string>}
   * @memberof ProgramsApiProgramsList
   */
  readonly course_feature?: Array<string>

  /**
   * The delivery of course/program resources  * &#x60;online&#x60; - Online * &#x60;hybrid&#x60; - Hybrid * &#x60;in_person&#x60; - In person * &#x60;offline&#x60; - Offline
   * @type {Array<Array<'online' | 'hybrid' | 'in_person' | 'offline'>>}
   * @memberof ProgramsApiProgramsList
   */
  readonly delivery?: Array<Array<ProgramsListDeliveryEnum>>

  /**
   * The department that offers learning resources  * &#x60;1&#x60; - Civil and Environmental Engineering * &#x60;2&#x60; - Mechanical Engineering * &#x60;3&#x60; - Materials Science and Engineering * &#x60;4&#x60; - Architecture * &#x60;5&#x60; - Chemistry * &#x60;6&#x60; - Electrical Engineering and Computer Science * &#x60;7&#x60; - Biology * &#x60;8&#x60; - Physics * &#x60;9&#x60; - Brain and Cognitive Sciences * &#x60;10&#x60; - Chemical Engineering * &#x60;11&#x60; - Urban Studies and Planning * &#x60;12&#x60; - Earth, Atmospheric, and Planetary Sciences * &#x60;14&#x60; - Economics * &#x60;15&#x60; - Management * &#x60;16&#x60; - Aeronautics and Astronautics * &#x60;17&#x60; - Political Science * &#x60;18&#x60; - Mathematics * &#x60;20&#x60; - Biological Engineering * &#x60;21A&#x60; - Anthropology * &#x60;21G&#x60; - Global Languages * &#x60;21H&#x60; - History * &#x60;21L&#x60; - Literature * &#x60;21M&#x60; - Music and Theater Arts * &#x60;22&#x60; - Nuclear Science and Engineering * &#x60;24&#x60; - Linguistics and Philosophy * &#x60;CC&#x60; - Concourse * &#x60;CMS-W&#x60; - Comparative Media Studies/Writing * &#x60;EC&#x60; - Edgerton Center * &#x60;ES&#x60; - Experimental Study Group * &#x60;ESD&#x60; - Engineering Systems Division * &#x60;HST&#x60; - Medical Engineering and Science * &#x60;IDS&#x60; - Data, Systems, and Society * &#x60;MAS&#x60; - Media Arts and Sciences * &#x60;PE&#x60; - Athletics, Physical Education and Recreation * &#x60;SP&#x60; - Special Programs * &#x60;STS&#x60; - Science, Technology, and Society * &#x60;WGS&#x60; - Women\&#39;s and Gender Studies
   * @type {Array<'1' | '10' | '11' | '12' | '14' | '15' | '16' | '17' | '18' | '2' | '20' | '21A' | '21G' | '21H' | '21L' | '21M' | '22' | '24' | '3' | '4' | '5' | '6' | '7' | '8' | '9' | 'CC' | 'CMS-W' | 'EC' | 'ES' | 'ESD' | 'HST' | 'IDS' | 'MAS' | 'PE' | 'SP' | 'STS' | 'WGS'>}
   * @memberof ProgramsApiProgramsList
   */
  readonly department?: Array<ProgramsListDepartmentEnum>

  /**
   * The course/program is offered for free
   * @type {boolean}
   * @memberof ProgramsApiProgramsList
   */
  readonly free?: boolean

  /**
   * The academic level of the resources  * &#x60;undergraduate&#x60; - Undergraduate * &#x60;graduate&#x60; - Graduate * &#x60;high_school&#x60; - High School * &#x60;noncredit&#x60; - Non-Credit * &#x60;advanced&#x60; - Advanced * &#x60;intermediate&#x60; - Intermediate * &#x60;introductory&#x60; - Introductory
   * @type {Array<'advanced' | 'graduate' | 'high_school' | 'intermediate' | 'introductory' | 'noncredit' | 'undergraduate'>}
   * @memberof ProgramsApiProgramsList
   */
  readonly level?: Array<ProgramsListLevelEnum>

  /**
   * Number of results to return per page.
   * @type {number}
   * @memberof ProgramsApiProgramsList
   */
  readonly limit?: number

  /**
   * The organization that offers a learning resource  * &#x60;mitx&#x60; - MITx * &#x60;ocw&#x60; - MIT OpenCourseWare * &#x60;bootcamps&#x60; - Bootcamps * &#x60;xpro&#x60; - MIT xPRO * &#x60;mitpe&#x60; - MIT Professional Education * &#x60;see&#x60; - MIT Sloan Executive Education
   * @type {Array<'bootcamps' | 'mitpe' | 'mitx' | 'ocw' | 'see' | 'xpro'>}
   * @memberof ProgramsApiProgramsList
   */
  readonly offered_by?: Array<ProgramsListOfferedByEnum>

  /**
   * The initial index from which to return the results.
   * @type {number}
   * @memberof ProgramsApiProgramsList
   */
  readonly offset?: number

  /**
   * The platform on which learning resources are offered  * &#x60;edx&#x60; - edX * &#x60;ocw&#x60; - MIT OpenCourseWare * &#x60;oll&#x60; - Open Learning Library * &#x60;mitxonline&#x60; - MITx Online * &#x60;bootcamps&#x60; - Bootcamps * &#x60;xpro&#x60; - MIT xPRO * &#x60;csail&#x60; - CSAIL * &#x60;mitpe&#x60; - MIT Professional Education * &#x60;see&#x60; - MIT Sloan Executive Education * &#x60;scc&#x60; - Schwarzman College of Computing * &#x60;ctl&#x60; - Center for Transportation &amp; Logistics * &#x60;whu&#x60; - WHU * &#x60;susskind&#x60; - Susskind * &#x60;globalalumni&#x60; - Global Alumni * &#x60;simplilearn&#x60; - Simplilearn * &#x60;emeritus&#x60; - Emeritus * &#x60;podcast&#x60; - Podcast * &#x60;youtube&#x60; - YouTube
   * @type {Array<'bootcamps' | 'csail' | 'ctl' | 'edx' | 'emeritus' | 'globalalumni' | 'mitpe' | 'mitxonline' | 'ocw' | 'oll' | 'podcast' | 'scc' | 'see' | 'simplilearn' | 'susskind' | 'whu' | 'xpro' | 'youtube'>}
   * @memberof ProgramsApiProgramsList
   */
  readonly platform?: Array<ProgramsListPlatformEnum>

  /**
   *
   * @type {boolean}
   * @memberof ProgramsApiProgramsList
   */
  readonly professional?: boolean

  /**
   * Multiple values may be separated by commas.
   * @type {Array<string>}
   * @memberof ProgramsApiProgramsList
   */
  readonly readable_id?: Array<string>

  /**
   * The resource category of the learning resources  * &#x60;course&#x60; - Course * &#x60;program&#x60; - Program * &#x60;learning_material&#x60; - Learning Material
   * @type {Array<'course' | 'learning_material' | 'program'>}
   * @memberof ProgramsApiProgramsList
   */
  readonly resource_category?: Array<ProgramsListResourceCategoryEnum>

  /**
   * The type of learning resource  * &#x60;course&#x60; - Course * &#x60;program&#x60; - Program * &#x60;learning_path&#x60; - Learning Path * &#x60;podcast&#x60; - Podcast * &#x60;podcast_episode&#x60; - Podcast Episode * &#x60;video&#x60; - Video * &#x60;video_playlist&#x60; - Video Playlist
   * @type {Array<'course' | 'learning_path' | 'podcast' | 'podcast_episode' | 'program' | 'video' | 'video_playlist'>}
   * @memberof ProgramsApiProgramsList
   */
  readonly resource_type?: Array<ProgramsListResourceTypeEnum>

  /**
   * Sort By  * &#x60;id&#x60; - Object ID ascending * &#x60;-id&#x60; - Object ID descending * &#x60;readable_id&#x60; - Readable ID ascending * &#x60;-readable_id&#x60; - Readable ID descending * &#x60;last_modified&#x60; - Last Modified Date ascending * &#x60;-last_modified&#x60; - Last Modified Date descending * &#x60;new&#x60; - Newest resources first * &#x60;start_date&#x60; - Start Date ascending * &#x60;-start_date&#x60; - Start Date descending * &#x60;mitcoursenumber&#x60; - MIT course number ascending * &#x60;-mitcoursenumber&#x60; - MIT course number descending * &#x60;views&#x60; - Popularity ascending * &#x60;-views&#x60; - Popularity descending * &#x60;upcoming&#x60; - Next start date ascending
   * @type {'-id' | '-last_modified' | '-mitcoursenumber' | '-readable_id' | '-start_date' | '-views' | 'id' | 'last_modified' | 'mitcoursenumber' | 'new' | 'readable_id' | 'start_date' | 'upcoming' | 'views'}
   * @memberof ProgramsApiProgramsList
   */
  readonly sortby?: ProgramsListSortbyEnum

  /**
   * Multiple values may be separated by commas.
   * @type {Array<string>}
   * @memberof ProgramsApiProgramsList
   */
  readonly topic?: Array<string>
}

/**
 * Request parameters for programsRetrieve operation in ProgramsApi.
 * @export
 * @interface ProgramsApiProgramsRetrieveRequest
 */
export interface ProgramsApiProgramsRetrieveRequest {
  /**
   * A unique integer value identifying this learning resource.
   * @type {number}
   * @memberof ProgramsApiProgramsRetrieve
   */
  readonly id: number
}

/**
 * ProgramsApi - object-oriented interface
 * @export
 * @class ProgramsApi
 * @extends {BaseAPI}
 */
export class ProgramsApi extends BaseAPI {
  /**
   * Get a paginated list of programs
   * @summary List
   * @param {ProgramsApiProgramsListRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ProgramsApi
   */
  public programsList(
    requestParameters: ProgramsApiProgramsListRequest = {},
    options?: RawAxiosRequestConfig,
  ) {
    return ProgramsApiFp(this.configuration)
      .programsList(
        requestParameters.certification,
        requestParameters.certification_type,
        requestParameters.course_feature,
        requestParameters.delivery,
        requestParameters.department,
        requestParameters.free,
        requestParameters.level,
        requestParameters.limit,
        requestParameters.offered_by,
        requestParameters.offset,
        requestParameters.platform,
        requestParameters.professional,
        requestParameters.readable_id,
        requestParameters.resource_category,
        requestParameters.resource_type,
        requestParameters.sortby,
        requestParameters.topic,
        options,
      )
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   * Retrieve a single program
   * @summary Retrieve
   * @param {ProgramsApiProgramsRetrieveRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ProgramsApi
   */
  public programsRetrieve(
    requestParameters: ProgramsApiProgramsRetrieveRequest,
    options?: RawAxiosRequestConfig,
  ) {
    return ProgramsApiFp(this.configuration)
      .programsRetrieve(requestParameters.id, options)
      .then((request) => request(this.axios, this.basePath))
  }
}

/**
 * @export
 */
export const ProgramsListCertificationTypeEnum = {
  Completion: "completion",
  Micromasters: "micromasters",
  None: "none",
  Professional: "professional",
} as const
export type ProgramsListCertificationTypeEnum =
  (typeof ProgramsListCertificationTypeEnum)[keyof typeof ProgramsListCertificationTypeEnum]
/**
 * @export
 */
export const ProgramsListDeliveryEnum = {
  Online: "online",
  Hybrid: "hybrid",
  InPerson: "in_person",
  Offline: "offline",
} as const
export type ProgramsListDeliveryEnum =
  (typeof ProgramsListDeliveryEnum)[keyof typeof ProgramsListDeliveryEnum]
/**
 * @export
 */
export const ProgramsListDepartmentEnum = {
  _1: "1",
  _10: "10",
  _11: "11",
  _12: "12",
  _14: "14",
  _15: "15",
  _16: "16",
  _17: "17",
  _18: "18",
  _2: "2",
  _20: "20",
  _21A: "21A",
  _21G: "21G",
  _21H: "21H",
  _21L: "21L",
  _21M: "21M",
  _22: "22",
  _24: "24",
  _3: "3",
  _4: "4",
  _5: "5",
  _6: "6",
  _7: "7",
  _8: "8",
  _9: "9",
  Cc: "CC",
  CmsW: "CMS-W",
  Ec: "EC",
  Es: "ES",
  Esd: "ESD",
  Hst: "HST",
  Ids: "IDS",
  Mas: "MAS",
  Pe: "PE",
  Sp: "SP",
  Sts: "STS",
  Wgs: "WGS",
} as const
export type ProgramsListDepartmentEnum =
  (typeof ProgramsListDepartmentEnum)[keyof typeof ProgramsListDepartmentEnum]
/**
 * @export
 */
export const ProgramsListLevelEnum = {
  Advanced: "advanced",
  Graduate: "graduate",
  HighSchool: "high_school",
  Intermediate: "intermediate",
  Introductory: "introductory",
  Noncredit: "noncredit",
  Undergraduate: "undergraduate",
} as const
export type ProgramsListLevelEnum =
  (typeof ProgramsListLevelEnum)[keyof typeof ProgramsListLevelEnum]
/**
 * @export
 */
export const ProgramsListOfferedByEnum = {
  Bootcamps: "bootcamps",
  Mitpe: "mitpe",
  Mitx: "mitx",
  Ocw: "ocw",
  See: "see",
  Xpro: "xpro",
} as const
export type ProgramsListOfferedByEnum =
  (typeof ProgramsListOfferedByEnum)[keyof typeof ProgramsListOfferedByEnum]
/**
 * @export
 */
export const ProgramsListPlatformEnum = {
  Bootcamps: "bootcamps",
  Csail: "csail",
  Ctl: "ctl",
  Edx: "edx",
  Emeritus: "emeritus",
  Globalalumni: "globalalumni",
  Mitpe: "mitpe",
  Mitxonline: "mitxonline",
  Ocw: "ocw",
  Oll: "oll",
  Podcast: "podcast",
  Scc: "scc",
  See: "see",
  Simplilearn: "simplilearn",
  Susskind: "susskind",
  Whu: "whu",
  Xpro: "xpro",
  Youtube: "youtube",
} as const
export type ProgramsListPlatformEnum =
  (typeof ProgramsListPlatformEnum)[keyof typeof ProgramsListPlatformEnum]
/**
 * @export
 */
export const ProgramsListResourceCategoryEnum = {
  Course: "course",
  LearningMaterial: "learning_material",
  Program: "program",
} as const
export type ProgramsListResourceCategoryEnum =
  (typeof ProgramsListResourceCategoryEnum)[keyof typeof ProgramsListResourceCategoryEnum]
/**
 * @export
 */
export const ProgramsListResourceTypeEnum = {
  Course: "course",
  LearningPath: "learning_path",
  Podcast: "podcast",
  PodcastEpisode: "podcast_episode",
  Program: "program",
  Video: "video",
  VideoPlaylist: "video_playlist",
} as const
export type ProgramsListResourceTypeEnum =
  (typeof ProgramsListResourceTypeEnum)[keyof typeof ProgramsListResourceTypeEnum]
/**
 * @export
 */
export const ProgramsListSortbyEnum = {
  Id: "-id",
  LastModified: "-last_modified",
  Mitcoursenumber: "-mitcoursenumber",
  ReadableId: "-readable_id",
  StartDate: "-start_date",
  Views: "-views",
  Id2: "id",
  LastModified2: "last_modified",
  Mitcoursenumber2: "mitcoursenumber",
  New: "new",
  ReadableId2: "readable_id",
  StartDate2: "start_date",
  Upcoming: "upcoming",
  Views2: "views",
} as const
export type ProgramsListSortbyEnum =
  (typeof ProgramsListSortbyEnum)[keyof typeof ProgramsListSortbyEnum]

/**
 * SchoolsApi - axios parameter creator
 * @export
 */
export const SchoolsApiAxiosParamCreator = function (
  configuration?: Configuration,
) {
  return {
    /**
     * MIT schools
     * @summary List
     * @param {number} [limit] Number of results to return per page.
     * @param {number} [offset] The initial index from which to return the results.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    schoolsList: async (
      limit?: number,
      offset?: number,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/api/v1/schools/`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      if (limit !== undefined) {
        localVarQueryParameter["limit"] = limit
      }

      if (offset !== undefined) {
        localVarQueryParameter["offset"] = offset
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     * MIT schools
     * @summary Retrieve
     * @param {number} id A unique integer value identifying this learning resource school.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    schoolsRetrieve: async (
      id: number,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists("schoolsRetrieve", "id", id)
      const localVarPath = `/api/v1/schools/{id}/`.replace(
        `{${"id"}}`,
        encodeURIComponent(String(id)),
      )
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
  }
}

/**
 * SchoolsApi - functional programming interface
 * @export
 */
export const SchoolsApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = SchoolsApiAxiosParamCreator(configuration)
  return {
    /**
     * MIT schools
     * @summary List
     * @param {number} [limit] Number of results to return per page.
     * @param {number} [offset] The initial index from which to return the results.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async schoolsList(
      limit?: number,
      offset?: number,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<PaginatedLearningResourceSchoolList>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.schoolsList(
        limit,
        offset,
        options,
      )
      const index = configuration?.serverIndex ?? 0
      const operationBasePath =
        operationServerMap["SchoolsApi.schoolsList"]?.[index]?.url
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, operationBasePath || basePath)
    },
    /**
     * MIT schools
     * @summary Retrieve
     * @param {number} id A unique integer value identifying this learning resource school.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async schoolsRetrieve(
      id: number,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<LearningResourceSchool>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.schoolsRetrieve(
        id,
        options,
      )
      const index = configuration?.serverIndex ?? 0
      const operationBasePath =
        operationServerMap["SchoolsApi.schoolsRetrieve"]?.[index]?.url
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, operationBasePath || basePath)
    },
  }
}

/**
 * SchoolsApi - factory interface
 * @export
 */
export const SchoolsApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = SchoolsApiFp(configuration)
  return {
    /**
     * MIT schools
     * @summary List
     * @param {SchoolsApiSchoolsListRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    schoolsList(
      requestParameters: SchoolsApiSchoolsListRequest = {},
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<PaginatedLearningResourceSchoolList> {
      return localVarFp
        .schoolsList(requestParameters.limit, requestParameters.offset, options)
        .then((request) => request(axios, basePath))
    },
    /**
     * MIT schools
     * @summary Retrieve
     * @param {SchoolsApiSchoolsRetrieveRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    schoolsRetrieve(
      requestParameters: SchoolsApiSchoolsRetrieveRequest,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<LearningResourceSchool> {
      return localVarFp
        .schoolsRetrieve(requestParameters.id, options)
        .then((request) => request(axios, basePath))
    },
  }
}

/**
 * Request parameters for schoolsList operation in SchoolsApi.
 * @export
 * @interface SchoolsApiSchoolsListRequest
 */
export interface SchoolsApiSchoolsListRequest {
  /**
   * Number of results to return per page.
   * @type {number}
   * @memberof SchoolsApiSchoolsList
   */
  readonly limit?: number

  /**
   * The initial index from which to return the results.
   * @type {number}
   * @memberof SchoolsApiSchoolsList
   */
  readonly offset?: number
}

/**
 * Request parameters for schoolsRetrieve operation in SchoolsApi.
 * @export
 * @interface SchoolsApiSchoolsRetrieveRequest
 */
export interface SchoolsApiSchoolsRetrieveRequest {
  /**
   * A unique integer value identifying this learning resource school.
   * @type {number}
   * @memberof SchoolsApiSchoolsRetrieve
   */
  readonly id: number
}

/**
 * SchoolsApi - object-oriented interface
 * @export
 * @class SchoolsApi
 * @extends {BaseAPI}
 */
export class SchoolsApi extends BaseAPI {
  /**
   * MIT schools
   * @summary List
   * @param {SchoolsApiSchoolsListRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof SchoolsApi
   */
  public schoolsList(
    requestParameters: SchoolsApiSchoolsListRequest = {},
    options?: RawAxiosRequestConfig,
  ) {
    return SchoolsApiFp(this.configuration)
      .schoolsList(requestParameters.limit, requestParameters.offset, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   * MIT schools
   * @summary Retrieve
   * @param {SchoolsApiSchoolsRetrieveRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof SchoolsApi
   */
  public schoolsRetrieve(
    requestParameters: SchoolsApiSchoolsRetrieveRequest,
    options?: RawAxiosRequestConfig,
  ) {
    return SchoolsApiFp(this.configuration)
      .schoolsRetrieve(requestParameters.id, options)
      .then((request) => request(this.axios, this.basePath))
  }
}

/**
 * TopicsApi - axios parameter creator
 * @export
 */
export const TopicsApiAxiosParamCreator = function (
  configuration?: Configuration,
) {
  return {
    /**
     * Topics covered by learning resources
     * @summary List
     * @param {boolean} [is_toplevel] Filter top-level topics
     * @param {number} [limit] Number of results to return per page.
     * @param {Array<string>} [name] Multiple values may be separated by commas.
     * @param {number} [offset] The initial index from which to return the results.
     * @param {Array<number>} [parent_topic_id] Multiple values may be separated by commas.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    topicsList: async (
      is_toplevel?: boolean,
      limit?: number,
      name?: Array<string>,
      offset?: number,
      parent_topic_id?: Array<number>,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/api/v1/topics/`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      if (is_toplevel !== undefined) {
        localVarQueryParameter["is_toplevel"] = is_toplevel
      }

      if (limit !== undefined) {
        localVarQueryParameter["limit"] = limit
      }

      if (name) {
        localVarQueryParameter["name"] = name.join(COLLECTION_FORMATS.csv)
      }

      if (offset !== undefined) {
        localVarQueryParameter["offset"] = offset
      }

      if (parent_topic_id) {
        localVarQueryParameter["parent_topic_id"] = parent_topic_id.join(
          COLLECTION_FORMATS.csv,
        )
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     * Topics covered by learning resources
     * @summary Retrieve
     * @param {number} id A unique integer value identifying this learning resource topic.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    topicsRetrieve: async (
      id: number,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists("topicsRetrieve", "id", id)
      const localVarPath = `/api/v1/topics/{id}/`.replace(
        `{${"id"}}`,
        encodeURIComponent(String(id)),
      )
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
  }
}

/**
 * TopicsApi - functional programming interface
 * @export
 */
export const TopicsApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = TopicsApiAxiosParamCreator(configuration)
  return {
    /**
     * Topics covered by learning resources
     * @summary List
     * @param {boolean} [is_toplevel] Filter top-level topics
     * @param {number} [limit] Number of results to return per page.
     * @param {Array<string>} [name] Multiple values may be separated by commas.
     * @param {number} [offset] The initial index from which to return the results.
     * @param {Array<number>} [parent_topic_id] Multiple values may be separated by commas.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async topicsList(
      is_toplevel?: boolean,
      limit?: number,
      name?: Array<string>,
      offset?: number,
      parent_topic_id?: Array<number>,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<PaginatedLearningResourceTopicList>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.topicsList(
        is_toplevel,
        limit,
        name,
        offset,
        parent_topic_id,
        options,
      )
      const index = configuration?.serverIndex ?? 0
      const operationBasePath =
        operationServerMap["TopicsApi.topicsList"]?.[index]?.url
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, operationBasePath || basePath)
    },
    /**
     * Topics covered by learning resources
     * @summary Retrieve
     * @param {number} id A unique integer value identifying this learning resource topic.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async topicsRetrieve(
      id: number,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<LearningResourceTopic>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.topicsRetrieve(
        id,
        options,
      )
      const index = configuration?.serverIndex ?? 0
      const operationBasePath =
        operationServerMap["TopicsApi.topicsRetrieve"]?.[index]?.url
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, operationBasePath || basePath)
    },
  }
}

/**
 * TopicsApi - factory interface
 * @export
 */
export const TopicsApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = TopicsApiFp(configuration)
  return {
    /**
     * Topics covered by learning resources
     * @summary List
     * @param {TopicsApiTopicsListRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    topicsList(
      requestParameters: TopicsApiTopicsListRequest = {},
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<PaginatedLearningResourceTopicList> {
      return localVarFp
        .topicsList(
          requestParameters.is_toplevel,
          requestParameters.limit,
          requestParameters.name,
          requestParameters.offset,
          requestParameters.parent_topic_id,
          options,
        )
        .then((request) => request(axios, basePath))
    },
    /**
     * Topics covered by learning resources
     * @summary Retrieve
     * @param {TopicsApiTopicsRetrieveRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    topicsRetrieve(
      requestParameters: TopicsApiTopicsRetrieveRequest,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<LearningResourceTopic> {
      return localVarFp
        .topicsRetrieve(requestParameters.id, options)
        .then((request) => request(axios, basePath))
    },
  }
}

/**
 * Request parameters for topicsList operation in TopicsApi.
 * @export
 * @interface TopicsApiTopicsListRequest
 */
export interface TopicsApiTopicsListRequest {
  /**
   * Filter top-level topics
   * @type {boolean}
   * @memberof TopicsApiTopicsList
   */
  readonly is_toplevel?: boolean

  /**
   * Number of results to return per page.
   * @type {number}
   * @memberof TopicsApiTopicsList
   */
  readonly limit?: number

  /**
   * Multiple values may be separated by commas.
   * @type {Array<string>}
   * @memberof TopicsApiTopicsList
   */
  readonly name?: Array<string>

  /**
   * The initial index from which to return the results.
   * @type {number}
   * @memberof TopicsApiTopicsList
   */
  readonly offset?: number

  /**
   * Multiple values may be separated by commas.
   * @type {Array<number>}
   * @memberof TopicsApiTopicsList
   */
  readonly parent_topic_id?: Array<number>
}

/**
 * Request parameters for topicsRetrieve operation in TopicsApi.
 * @export
 * @interface TopicsApiTopicsRetrieveRequest
 */
export interface TopicsApiTopicsRetrieveRequest {
  /**
   * A unique integer value identifying this learning resource topic.
   * @type {number}
   * @memberof TopicsApiTopicsRetrieve
   */
  readonly id: number
}

/**
 * TopicsApi - object-oriented interface
 * @export
 * @class TopicsApi
 * @extends {BaseAPI}
 */
export class TopicsApi extends BaseAPI {
  /**
   * Topics covered by learning resources
   * @summary List
   * @param {TopicsApiTopicsListRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof TopicsApi
   */
  public topicsList(
    requestParameters: TopicsApiTopicsListRequest = {},
    options?: RawAxiosRequestConfig,
  ) {
    return TopicsApiFp(this.configuration)
      .topicsList(
        requestParameters.is_toplevel,
        requestParameters.limit,
        requestParameters.name,
        requestParameters.offset,
        requestParameters.parent_topic_id,
        options,
      )
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   * Topics covered by learning resources
   * @summary Retrieve
   * @param {TopicsApiTopicsRetrieveRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof TopicsApi
   */
  public topicsRetrieve(
    requestParameters: TopicsApiTopicsRetrieveRequest,
    options?: RawAxiosRequestConfig,
  ) {
    return TopicsApiFp(this.configuration)
      .topicsRetrieve(requestParameters.id, options)
      .then((request) => request(this.axios, this.basePath))
  }
}

/**
 * UserlistsApi - axios parameter creator
 * @export
 */
export const UserlistsApiAxiosParamCreator = function (
  configuration?: Configuration,
) {
  return {
    /**
     * Viewset for UserLists
     * @summary Create
     * @param {UserListRequest} UserListRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    userlistsCreate: async (
      UserListRequest: UserListRequest,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'UserListRequest' is not null or undefined
      assertParamExists("userlistsCreate", "UserListRequest", UserListRequest)
      const localVarPath = `/api/v1/userlists/`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = {
        method: "POST",
        ...baseOptions,
        ...options,
      }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      localVarHeaderParameter["Content-Type"] = "application/json"

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }
      localVarRequestOptions.data = serializeDataIfNeeded(
        UserListRequest,
        localVarRequestOptions,
        configuration,
      )

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     * Viewset for UserLists
     * @summary Destroy
     * @param {number} id A unique integer value identifying this user list.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    userlistsDestroy: async (
      id: number,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists("userlistsDestroy", "id", id)
      const localVarPath = `/api/v1/userlists/{id}/`.replace(
        `{${"id"}}`,
        encodeURIComponent(String(id)),
      )
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = {
        method: "DELETE",
        ...baseOptions,
        ...options,
      }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     * Viewset for UserListRelationships
     * @summary User List Resource Relationship Add
     * @param {number} userlist_id id of the parent user list
     * @param {UserListRelationshipRequest} UserListRelationshipRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    userlistsItemsCreate: async (
      userlist_id: number,
      UserListRelationshipRequest: UserListRelationshipRequest,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'userlist_id' is not null or undefined
      assertParamExists("userlistsItemsCreate", "userlist_id", userlist_id)
      // verify required parameter 'UserListRelationshipRequest' is not null or undefined
      assertParamExists(
        "userlistsItemsCreate",
        "UserListRelationshipRequest",
        UserListRelationshipRequest,
      )
      const localVarPath = `/api/v1/userlists/{userlist_id}/items/`.replace(
        `{${"userlist_id"}}`,
        encodeURIComponent(String(userlist_id)),
      )
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = {
        method: "POST",
        ...baseOptions,
        ...options,
      }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      localVarHeaderParameter["Content-Type"] = "application/json"

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }
      localVarRequestOptions.data = serializeDataIfNeeded(
        UserListRelationshipRequest,
        localVarRequestOptions,
        configuration,
      )

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     * Viewset for UserListRelationships
     * @summary User List Resource Relationship Remove
     * @param {number} id A unique integer value identifying this user list relationship.
     * @param {number} userlist_id id of the parent user list
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    userlistsItemsDestroy: async (
      id: number,
      userlist_id: number,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists("userlistsItemsDestroy", "id", id)
      // verify required parameter 'userlist_id' is not null or undefined
      assertParamExists("userlistsItemsDestroy", "userlist_id", userlist_id)
      const localVarPath = `/api/v1/userlists/{userlist_id}/items/{id}/`
        .replace(`{${"id"}}`, encodeURIComponent(String(id)))
        .replace(`{${"userlist_id"}}`, encodeURIComponent(String(userlist_id)))
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = {
        method: "DELETE",
        ...baseOptions,
        ...options,
      }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     * Viewset for UserListRelationships
     * @summary User List Resources List
     * @param {number} userlist_id id of the parent user list
     * @param {number} [limit] Number of results to return per page.
     * @param {number} [offset] The initial index from which to return the results.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    userlistsItemsList: async (
      userlist_id: number,
      limit?: number,
      offset?: number,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'userlist_id' is not null or undefined
      assertParamExists("userlistsItemsList", "userlist_id", userlist_id)
      const localVarPath = `/api/v1/userlists/{userlist_id}/items/`.replace(
        `{${"userlist_id"}}`,
        encodeURIComponent(String(userlist_id)),
      )
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      if (limit !== undefined) {
        localVarQueryParameter["limit"] = limit
      }

      if (offset !== undefined) {
        localVarQueryParameter["offset"] = offset
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     * Viewset for UserListRelationships
     * @summary User List Resource Relationship Update
     * @param {number} id A unique integer value identifying this user list relationship.
     * @param {number} userlist_id id of the parent user list
     * @param {PatchedUserListRelationshipRequest} [PatchedUserListRelationshipRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    userlistsItemsPartialUpdate: async (
      id: number,
      userlist_id: number,
      PatchedUserListRelationshipRequest?: PatchedUserListRelationshipRequest,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists("userlistsItemsPartialUpdate", "id", id)
      // verify required parameter 'userlist_id' is not null or undefined
      assertParamExists(
        "userlistsItemsPartialUpdate",
        "userlist_id",
        userlist_id,
      )
      const localVarPath = `/api/v1/userlists/{userlist_id}/items/{id}/`
        .replace(`{${"id"}}`, encodeURIComponent(String(id)))
        .replace(`{${"userlist_id"}}`, encodeURIComponent(String(userlist_id)))
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = {
        method: "PATCH",
        ...baseOptions,
        ...options,
      }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      localVarHeaderParameter["Content-Type"] = "application/json"

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }
      localVarRequestOptions.data = serializeDataIfNeeded(
        PatchedUserListRelationshipRequest,
        localVarRequestOptions,
        configuration,
      )

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     * Viewset for UserListRelationships
     * @summary User List Resources Retrieve
     * @param {number} id A unique integer value identifying this user list relationship.
     * @param {number} userlist_id id of the parent user list
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    userlistsItemsRetrieve: async (
      id: number,
      userlist_id: number,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists("userlistsItemsRetrieve", "id", id)
      // verify required parameter 'userlist_id' is not null or undefined
      assertParamExists("userlistsItemsRetrieve", "userlist_id", userlist_id)
      const localVarPath = `/api/v1/userlists/{userlist_id}/items/{id}/`
        .replace(`{${"id"}}`, encodeURIComponent(String(id)))
        .replace(`{${"userlist_id"}}`, encodeURIComponent(String(userlist_id)))
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     * Viewset for UserLists
     * @summary List
     * @param {number} [limit] Number of results to return per page.
     * @param {number} [offset] The initial index from which to return the results.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    userlistsList: async (
      limit?: number,
      offset?: number,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/api/v1/userlists/`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      if (limit !== undefined) {
        localVarQueryParameter["limit"] = limit
      }

      if (offset !== undefined) {
        localVarQueryParameter["offset"] = offset
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     * Get a list of all userlist items for a user
     * @summary List
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    userlistsMembershipList: async (
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/api/v1/userlists/membership/`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     * Viewset for UserLists
     * @summary Update
     * @param {number} id A unique integer value identifying this user list.
     * @param {PatchedUserListRequest} [PatchedUserListRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    userlistsPartialUpdate: async (
      id: number,
      PatchedUserListRequest?: PatchedUserListRequest,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists("userlistsPartialUpdate", "id", id)
      const localVarPath = `/api/v1/userlists/{id}/`.replace(
        `{${"id"}}`,
        encodeURIComponent(String(id)),
      )
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = {
        method: "PATCH",
        ...baseOptions,
        ...options,
      }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      localVarHeaderParameter["Content-Type"] = "application/json"

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }
      localVarRequestOptions.data = serializeDataIfNeeded(
        PatchedUserListRequest,
        localVarRequestOptions,
        configuration,
      )

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     * Viewset for UserLists
     * @summary Retrieve
     * @param {number} id A unique integer value identifying this user list.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    userlistsRetrieve: async (
      id: number,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists("userlistsRetrieve", "id", id)
      const localVarPath = `/api/v1/userlists/{id}/`.replace(
        `{${"id"}}`,
        encodeURIComponent(String(id)),
      )
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
  }
}

/**
 * UserlistsApi - functional programming interface
 * @export
 */
export const UserlistsApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = UserlistsApiAxiosParamCreator(configuration)
  return {
    /**
     * Viewset for UserLists
     * @summary Create
     * @param {UserListRequest} UserListRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async userlistsCreate(
      UserListRequest: UserListRequest,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserList>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.userlistsCreate(
        UserListRequest,
        options,
      )
      const index = configuration?.serverIndex ?? 0
      const operationBasePath =
        operationServerMap["UserlistsApi.userlistsCreate"]?.[index]?.url
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, operationBasePath || basePath)
    },
    /**
     * Viewset for UserLists
     * @summary Destroy
     * @param {number} id A unique integer value identifying this user list.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async userlistsDestroy(
      id: number,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.userlistsDestroy(id, options)
      const index = configuration?.serverIndex ?? 0
      const operationBasePath =
        operationServerMap["UserlistsApi.userlistsDestroy"]?.[index]?.url
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, operationBasePath || basePath)
    },
    /**
     * Viewset for UserListRelationships
     * @summary User List Resource Relationship Add
     * @param {number} userlist_id id of the parent user list
     * @param {UserListRelationshipRequest} UserListRelationshipRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async userlistsItemsCreate(
      userlist_id: number,
      UserListRelationshipRequest: UserListRelationshipRequest,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<UserListRelationship>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.userlistsItemsCreate(
          userlist_id,
          UserListRelationshipRequest,
          options,
        )
      const index = configuration?.serverIndex ?? 0
      const operationBasePath =
        operationServerMap["UserlistsApi.userlistsItemsCreate"]?.[index]?.url
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, operationBasePath || basePath)
    },
    /**
     * Viewset for UserListRelationships
     * @summary User List Resource Relationship Remove
     * @param {number} id A unique integer value identifying this user list relationship.
     * @param {number} userlist_id id of the parent user list
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async userlistsItemsDestroy(
      id: number,
      userlist_id: number,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.userlistsItemsDestroy(
          id,
          userlist_id,
          options,
        )
      const index = configuration?.serverIndex ?? 0
      const operationBasePath =
        operationServerMap["UserlistsApi.userlistsItemsDestroy"]?.[index]?.url
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, operationBasePath || basePath)
    },
    /**
     * Viewset for UserListRelationships
     * @summary User List Resources List
     * @param {number} userlist_id id of the parent user list
     * @param {number} [limit] Number of results to return per page.
     * @param {number} [offset] The initial index from which to return the results.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async userlistsItemsList(
      userlist_id: number,
      limit?: number,
      offset?: number,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<PaginatedUserListRelationshipList>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.userlistsItemsList(
          userlist_id,
          limit,
          offset,
          options,
        )
      const index = configuration?.serverIndex ?? 0
      const operationBasePath =
        operationServerMap["UserlistsApi.userlistsItemsList"]?.[index]?.url
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, operationBasePath || basePath)
    },
    /**
     * Viewset for UserListRelationships
     * @summary User List Resource Relationship Update
     * @param {number} id A unique integer value identifying this user list relationship.
     * @param {number} userlist_id id of the parent user list
     * @param {PatchedUserListRelationshipRequest} [PatchedUserListRelationshipRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async userlistsItemsPartialUpdate(
      id: number,
      userlist_id: number,
      PatchedUserListRelationshipRequest?: PatchedUserListRelationshipRequest,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<UserListRelationship>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.userlistsItemsPartialUpdate(
          id,
          userlist_id,
          PatchedUserListRelationshipRequest,
          options,
        )
      const index = configuration?.serverIndex ?? 0
      const operationBasePath =
        operationServerMap["UserlistsApi.userlistsItemsPartialUpdate"]?.[index]
          ?.url
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, operationBasePath || basePath)
    },
    /**
     * Viewset for UserListRelationships
     * @summary User List Resources Retrieve
     * @param {number} id A unique integer value identifying this user list relationship.
     * @param {number} userlist_id id of the parent user list
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async userlistsItemsRetrieve(
      id: number,
      userlist_id: number,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<UserListRelationship>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.userlistsItemsRetrieve(
          id,
          userlist_id,
          options,
        )
      const index = configuration?.serverIndex ?? 0
      const operationBasePath =
        operationServerMap["UserlistsApi.userlistsItemsRetrieve"]?.[index]?.url
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, operationBasePath || basePath)
    },
    /**
     * Viewset for UserLists
     * @summary List
     * @param {number} [limit] Number of results to return per page.
     * @param {number} [offset] The initial index from which to return the results.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async userlistsList(
      limit?: number,
      offset?: number,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<PaginatedUserListList>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.userlistsList(
        limit,
        offset,
        options,
      )
      const index = configuration?.serverIndex ?? 0
      const operationBasePath =
        operationServerMap["UserlistsApi.userlistsList"]?.[index]?.url
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, operationBasePath || basePath)
    },
    /**
     * Get a list of all userlist items for a user
     * @summary List
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async userlistsMembershipList(
      options?: RawAxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<Array<MicroUserListRelationship>>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.userlistsMembershipList(options)
      const index = configuration?.serverIndex ?? 0
      const operationBasePath =
        operationServerMap["UserlistsApi.userlistsMembershipList"]?.[index]?.url
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, operationBasePath || basePath)
    },
    /**
     * Viewset for UserLists
     * @summary Update
     * @param {number} id A unique integer value identifying this user list.
     * @param {PatchedUserListRequest} [PatchedUserListRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async userlistsPartialUpdate(
      id: number,
      PatchedUserListRequest?: PatchedUserListRequest,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserList>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.userlistsPartialUpdate(
          id,
          PatchedUserListRequest,
          options,
        )
      const index = configuration?.serverIndex ?? 0
      const operationBasePath =
        operationServerMap["UserlistsApi.userlistsPartialUpdate"]?.[index]?.url
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, operationBasePath || basePath)
    },
    /**
     * Viewset for UserLists
     * @summary Retrieve
     * @param {number} id A unique integer value identifying this user list.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async userlistsRetrieve(
      id: number,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserList>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.userlistsRetrieve(id, options)
      const index = configuration?.serverIndex ?? 0
      const operationBasePath =
        operationServerMap["UserlistsApi.userlistsRetrieve"]?.[index]?.url
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, operationBasePath || basePath)
    },
  }
}

/**
 * UserlistsApi - factory interface
 * @export
 */
export const UserlistsApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = UserlistsApiFp(configuration)
  return {
    /**
     * Viewset for UserLists
     * @summary Create
     * @param {UserlistsApiUserlistsCreateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    userlistsCreate(
      requestParameters: UserlistsApiUserlistsCreateRequest,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<UserList> {
      return localVarFp
        .userlistsCreate(requestParameters.UserListRequest, options)
        .then((request) => request(axios, basePath))
    },
    /**
     * Viewset for UserLists
     * @summary Destroy
     * @param {UserlistsApiUserlistsDestroyRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    userlistsDestroy(
      requestParameters: UserlistsApiUserlistsDestroyRequest,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<void> {
      return localVarFp
        .userlistsDestroy(requestParameters.id, options)
        .then((request) => request(axios, basePath))
    },
    /**
     * Viewset for UserListRelationships
     * @summary User List Resource Relationship Add
     * @param {UserlistsApiUserlistsItemsCreateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    userlistsItemsCreate(
      requestParameters: UserlistsApiUserlistsItemsCreateRequest,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<UserListRelationship> {
      return localVarFp
        .userlistsItemsCreate(
          requestParameters.userlist_id,
          requestParameters.UserListRelationshipRequest,
          options,
        )
        .then((request) => request(axios, basePath))
    },
    /**
     * Viewset for UserListRelationships
     * @summary User List Resource Relationship Remove
     * @param {UserlistsApiUserlistsItemsDestroyRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    userlistsItemsDestroy(
      requestParameters: UserlistsApiUserlistsItemsDestroyRequest,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<void> {
      return localVarFp
        .userlistsItemsDestroy(
          requestParameters.id,
          requestParameters.userlist_id,
          options,
        )
        .then((request) => request(axios, basePath))
    },
    /**
     * Viewset for UserListRelationships
     * @summary User List Resources List
     * @param {UserlistsApiUserlistsItemsListRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    userlistsItemsList(
      requestParameters: UserlistsApiUserlistsItemsListRequest,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<PaginatedUserListRelationshipList> {
      return localVarFp
        .userlistsItemsList(
          requestParameters.userlist_id,
          requestParameters.limit,
          requestParameters.offset,
          options,
        )
        .then((request) => request(axios, basePath))
    },
    /**
     * Viewset for UserListRelationships
     * @summary User List Resource Relationship Update
     * @param {UserlistsApiUserlistsItemsPartialUpdateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    userlistsItemsPartialUpdate(
      requestParameters: UserlistsApiUserlistsItemsPartialUpdateRequest,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<UserListRelationship> {
      return localVarFp
        .userlistsItemsPartialUpdate(
          requestParameters.id,
          requestParameters.userlist_id,
          requestParameters.PatchedUserListRelationshipRequest,
          options,
        )
        .then((request) => request(axios, basePath))
    },
    /**
     * Viewset for UserListRelationships
     * @summary User List Resources Retrieve
     * @param {UserlistsApiUserlistsItemsRetrieveRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    userlistsItemsRetrieve(
      requestParameters: UserlistsApiUserlistsItemsRetrieveRequest,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<UserListRelationship> {
      return localVarFp
        .userlistsItemsRetrieve(
          requestParameters.id,
          requestParameters.userlist_id,
          options,
        )
        .then((request) => request(axios, basePath))
    },
    /**
     * Viewset for UserLists
     * @summary List
     * @param {UserlistsApiUserlistsListRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    userlistsList(
      requestParameters: UserlistsApiUserlistsListRequest = {},
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<PaginatedUserListList> {
      return localVarFp
        .userlistsList(
          requestParameters.limit,
          requestParameters.offset,
          options,
        )
        .then((request) => request(axios, basePath))
    },
    /**
     * Get a list of all userlist items for a user
     * @summary List
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    userlistsMembershipList(
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<Array<MicroUserListRelationship>> {
      return localVarFp
        .userlistsMembershipList(options)
        .then((request) => request(axios, basePath))
    },
    /**
     * Viewset for UserLists
     * @summary Update
     * @param {UserlistsApiUserlistsPartialUpdateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    userlistsPartialUpdate(
      requestParameters: UserlistsApiUserlistsPartialUpdateRequest,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<UserList> {
      return localVarFp
        .userlistsPartialUpdate(
          requestParameters.id,
          requestParameters.PatchedUserListRequest,
          options,
        )
        .then((request) => request(axios, basePath))
    },
    /**
     * Viewset for UserLists
     * @summary Retrieve
     * @param {UserlistsApiUserlistsRetrieveRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    userlistsRetrieve(
      requestParameters: UserlistsApiUserlistsRetrieveRequest,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<UserList> {
      return localVarFp
        .userlistsRetrieve(requestParameters.id, options)
        .then((request) => request(axios, basePath))
    },
  }
}

/**
 * Request parameters for userlistsCreate operation in UserlistsApi.
 * @export
 * @interface UserlistsApiUserlistsCreateRequest
 */
export interface UserlistsApiUserlistsCreateRequest {
  /**
   *
   * @type {UserListRequest}
   * @memberof UserlistsApiUserlistsCreate
   */
  readonly UserListRequest: UserListRequest
}

/**
 * Request parameters for userlistsDestroy operation in UserlistsApi.
 * @export
 * @interface UserlistsApiUserlistsDestroyRequest
 */
export interface UserlistsApiUserlistsDestroyRequest {
  /**
   * A unique integer value identifying this user list.
   * @type {number}
   * @memberof UserlistsApiUserlistsDestroy
   */
  readonly id: number
}

/**
 * Request parameters for userlistsItemsCreate operation in UserlistsApi.
 * @export
 * @interface UserlistsApiUserlistsItemsCreateRequest
 */
export interface UserlistsApiUserlistsItemsCreateRequest {
  /**
   * id of the parent user list
   * @type {number}
   * @memberof UserlistsApiUserlistsItemsCreate
   */
  readonly userlist_id: number

  /**
   *
   * @type {UserListRelationshipRequest}
   * @memberof UserlistsApiUserlistsItemsCreate
   */
  readonly UserListRelationshipRequest: UserListRelationshipRequest
}

/**
 * Request parameters for userlistsItemsDestroy operation in UserlistsApi.
 * @export
 * @interface UserlistsApiUserlistsItemsDestroyRequest
 */
export interface UserlistsApiUserlistsItemsDestroyRequest {
  /**
   * A unique integer value identifying this user list relationship.
   * @type {number}
   * @memberof UserlistsApiUserlistsItemsDestroy
   */
  readonly id: number

  /**
   * id of the parent user list
   * @type {number}
   * @memberof UserlistsApiUserlistsItemsDestroy
   */
  readonly userlist_id: number
}

/**
 * Request parameters for userlistsItemsList operation in UserlistsApi.
 * @export
 * @interface UserlistsApiUserlistsItemsListRequest
 */
export interface UserlistsApiUserlistsItemsListRequest {
  /**
   * id of the parent user list
   * @type {number}
   * @memberof UserlistsApiUserlistsItemsList
   */
  readonly userlist_id: number

  /**
   * Number of results to return per page.
   * @type {number}
   * @memberof UserlistsApiUserlistsItemsList
   */
  readonly limit?: number

  /**
   * The initial index from which to return the results.
   * @type {number}
   * @memberof UserlistsApiUserlistsItemsList
   */
  readonly offset?: number
}

/**
 * Request parameters for userlistsItemsPartialUpdate operation in UserlistsApi.
 * @export
 * @interface UserlistsApiUserlistsItemsPartialUpdateRequest
 */
export interface UserlistsApiUserlistsItemsPartialUpdateRequest {
  /**
   * A unique integer value identifying this user list relationship.
   * @type {number}
   * @memberof UserlistsApiUserlistsItemsPartialUpdate
   */
  readonly id: number

  /**
   * id of the parent user list
   * @type {number}
   * @memberof UserlistsApiUserlistsItemsPartialUpdate
   */
  readonly userlist_id: number

  /**
   *
   * @type {PatchedUserListRelationshipRequest}
   * @memberof UserlistsApiUserlistsItemsPartialUpdate
   */
  readonly PatchedUserListRelationshipRequest?: PatchedUserListRelationshipRequest
}

/**
 * Request parameters for userlistsItemsRetrieve operation in UserlistsApi.
 * @export
 * @interface UserlistsApiUserlistsItemsRetrieveRequest
 */
export interface UserlistsApiUserlistsItemsRetrieveRequest {
  /**
   * A unique integer value identifying this user list relationship.
   * @type {number}
   * @memberof UserlistsApiUserlistsItemsRetrieve
   */
  readonly id: number

  /**
   * id of the parent user list
   * @type {number}
   * @memberof UserlistsApiUserlistsItemsRetrieve
   */
  readonly userlist_id: number
}

/**
 * Request parameters for userlistsList operation in UserlistsApi.
 * @export
 * @interface UserlistsApiUserlistsListRequest
 */
export interface UserlistsApiUserlistsListRequest {
  /**
   * Number of results to return per page.
   * @type {number}
   * @memberof UserlistsApiUserlistsList
   */
  readonly limit?: number

  /**
   * The initial index from which to return the results.
   * @type {number}
   * @memberof UserlistsApiUserlistsList
   */
  readonly offset?: number
}

/**
 * Request parameters for userlistsPartialUpdate operation in UserlistsApi.
 * @export
 * @interface UserlistsApiUserlistsPartialUpdateRequest
 */
export interface UserlistsApiUserlistsPartialUpdateRequest {
  /**
   * A unique integer value identifying this user list.
   * @type {number}
   * @memberof UserlistsApiUserlistsPartialUpdate
   */
  readonly id: number

  /**
   *
   * @type {PatchedUserListRequest}
   * @memberof UserlistsApiUserlistsPartialUpdate
   */
  readonly PatchedUserListRequest?: PatchedUserListRequest
}

/**
 * Request parameters for userlistsRetrieve operation in UserlistsApi.
 * @export
 * @interface UserlistsApiUserlistsRetrieveRequest
 */
export interface UserlistsApiUserlistsRetrieveRequest {
  /**
   * A unique integer value identifying this user list.
   * @type {number}
   * @memberof UserlistsApiUserlistsRetrieve
   */
  readonly id: number
}

/**
 * UserlistsApi - object-oriented interface
 * @export
 * @class UserlistsApi
 * @extends {BaseAPI}
 */
export class UserlistsApi extends BaseAPI {
  /**
   * Viewset for UserLists
   * @summary Create
   * @param {UserlistsApiUserlistsCreateRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UserlistsApi
   */
  public userlistsCreate(
    requestParameters: UserlistsApiUserlistsCreateRequest,
    options?: RawAxiosRequestConfig,
  ) {
    return UserlistsApiFp(this.configuration)
      .userlistsCreate(requestParameters.UserListRequest, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   * Viewset for UserLists
   * @summary Destroy
   * @param {UserlistsApiUserlistsDestroyRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UserlistsApi
   */
  public userlistsDestroy(
    requestParameters: UserlistsApiUserlistsDestroyRequest,
    options?: RawAxiosRequestConfig,
  ) {
    return UserlistsApiFp(this.configuration)
      .userlistsDestroy(requestParameters.id, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   * Viewset for UserListRelationships
   * @summary User List Resource Relationship Add
   * @param {UserlistsApiUserlistsItemsCreateRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UserlistsApi
   */
  public userlistsItemsCreate(
    requestParameters: UserlistsApiUserlistsItemsCreateRequest,
    options?: RawAxiosRequestConfig,
  ) {
    return UserlistsApiFp(this.configuration)
      .userlistsItemsCreate(
        requestParameters.userlist_id,
        requestParameters.UserListRelationshipRequest,
        options,
      )
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   * Viewset for UserListRelationships
   * @summary User List Resource Relationship Remove
   * @param {UserlistsApiUserlistsItemsDestroyRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UserlistsApi
   */
  public userlistsItemsDestroy(
    requestParameters: UserlistsApiUserlistsItemsDestroyRequest,
    options?: RawAxiosRequestConfig,
  ) {
    return UserlistsApiFp(this.configuration)
      .userlistsItemsDestroy(
        requestParameters.id,
        requestParameters.userlist_id,
        options,
      )
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   * Viewset for UserListRelationships
   * @summary User List Resources List
   * @param {UserlistsApiUserlistsItemsListRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UserlistsApi
   */
  public userlistsItemsList(
    requestParameters: UserlistsApiUserlistsItemsListRequest,
    options?: RawAxiosRequestConfig,
  ) {
    return UserlistsApiFp(this.configuration)
      .userlistsItemsList(
        requestParameters.userlist_id,
        requestParameters.limit,
        requestParameters.offset,
        options,
      )
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   * Viewset for UserListRelationships
   * @summary User List Resource Relationship Update
   * @param {UserlistsApiUserlistsItemsPartialUpdateRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UserlistsApi
   */
  public userlistsItemsPartialUpdate(
    requestParameters: UserlistsApiUserlistsItemsPartialUpdateRequest,
    options?: RawAxiosRequestConfig,
  ) {
    return UserlistsApiFp(this.configuration)
      .userlistsItemsPartialUpdate(
        requestParameters.id,
        requestParameters.userlist_id,
        requestParameters.PatchedUserListRelationshipRequest,
        options,
      )
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   * Viewset for UserListRelationships
   * @summary User List Resources Retrieve
   * @param {UserlistsApiUserlistsItemsRetrieveRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UserlistsApi
   */
  public userlistsItemsRetrieve(
    requestParameters: UserlistsApiUserlistsItemsRetrieveRequest,
    options?: RawAxiosRequestConfig,
  ) {
    return UserlistsApiFp(this.configuration)
      .userlistsItemsRetrieve(
        requestParameters.id,
        requestParameters.userlist_id,
        options,
      )
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   * Viewset for UserLists
   * @summary List
   * @param {UserlistsApiUserlistsListRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UserlistsApi
   */
  public userlistsList(
    requestParameters: UserlistsApiUserlistsListRequest = {},
    options?: RawAxiosRequestConfig,
  ) {
    return UserlistsApiFp(this.configuration)
      .userlistsList(requestParameters.limit, requestParameters.offset, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   * Get a list of all userlist items for a user
   * @summary List
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UserlistsApi
   */
  public userlistsMembershipList(options?: RawAxiosRequestConfig) {
    return UserlistsApiFp(this.configuration)
      .userlistsMembershipList(options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   * Viewset for UserLists
   * @summary Update
   * @param {UserlistsApiUserlistsPartialUpdateRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UserlistsApi
   */
  public userlistsPartialUpdate(
    requestParameters: UserlistsApiUserlistsPartialUpdateRequest,
    options?: RawAxiosRequestConfig,
  ) {
    return UserlistsApiFp(this.configuration)
      .userlistsPartialUpdate(
        requestParameters.id,
        requestParameters.PatchedUserListRequest,
        options,
      )
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   * Viewset for UserLists
   * @summary Retrieve
   * @param {UserlistsApiUserlistsRetrieveRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UserlistsApi
   */
  public userlistsRetrieve(
    requestParameters: UserlistsApiUserlistsRetrieveRequest,
    options?: RawAxiosRequestConfig,
  ) {
    return UserlistsApiFp(this.configuration)
      .userlistsRetrieve(requestParameters.id, options)
      .then((request) => request(this.axios, this.basePath))
  }
}

/**
 * VideoPlaylistsApi - axios parameter creator
 * @export
 */
export const VideoPlaylistsApiAxiosParamCreator = function (
  configuration?: Configuration,
) {
  return {
    /**
     * Get a list of related learning resources for a learning resource.
     * @summary Nested Learning Resource List
     * @param {number} learning_resource_id id of the parent learning resource
     * @param {number} [limit] Number of results to return per page.
     * @param {number} [offset] The initial index from which to return the results.
     * @param {string} [sortby] Which field to use when ordering the results.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    videoPlaylistsItemsList: async (
      learning_resource_id: number,
      limit?: number,
      offset?: number,
      sortby?: string,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'learning_resource_id' is not null or undefined
      assertParamExists(
        "videoPlaylistsItemsList",
        "learning_resource_id",
        learning_resource_id,
      )
      const localVarPath =
        `/api/v1/video_playlists/{learning_resource_id}/items/`.replace(
          `{${"learning_resource_id"}}`,
          encodeURIComponent(String(learning_resource_id)),
        )
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      if (limit !== undefined) {
        localVarQueryParameter["limit"] = limit
      }

      if (offset !== undefined) {
        localVarQueryParameter["offset"] = offset
      }

      if (sortby !== undefined) {
        localVarQueryParameter["sortby"] = sortby
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     * Get a singe related learning resource for a learning resource.
     * @summary Nested Learning Resource Retrieve
     * @param {number} id A unique integer value identifying this learning resource relationship.
     * @param {number} learning_resource_id id of the parent learning resource
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    videoPlaylistsItemsRetrieve: async (
      id: number,
      learning_resource_id: number,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists("videoPlaylistsItemsRetrieve", "id", id)
      // verify required parameter 'learning_resource_id' is not null or undefined
      assertParamExists(
        "videoPlaylistsItemsRetrieve",
        "learning_resource_id",
        learning_resource_id,
      )
      const localVarPath =
        `/api/v1/video_playlists/{learning_resource_id}/items/{id}/`
          .replace(`{${"id"}}`, encodeURIComponent(String(id)))
          .replace(
            `{${"learning_resource_id"}}`,
            encodeURIComponent(String(learning_resource_id)),
          )
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     * Get a paginated list of video playlists
     * @summary List
     * @param {boolean} [certification]
     * @param {Array<VideoPlaylistsListCertificationTypeEnum>} [certification_type] The type of certification offered  * &#x60;micromasters&#x60; - MicroMasters Credential * &#x60;professional&#x60; - Professional Certificate * &#x60;completion&#x60; - Certificate of Completion * &#x60;none&#x60; - No Certificate
     * @param {Array<string>} [course_feature] Multiple values may be separated by commas.
     * @param {Array<Array<VideoPlaylistsListDeliveryEnum>>} [delivery] The delivery of course/program resources  * &#x60;online&#x60; - Online * &#x60;hybrid&#x60; - Hybrid * &#x60;in_person&#x60; - In person * &#x60;offline&#x60; - Offline
     * @param {Array<VideoPlaylistsListDepartmentEnum>} [department] The department that offers learning resources  * &#x60;1&#x60; - Civil and Environmental Engineering * &#x60;2&#x60; - Mechanical Engineering * &#x60;3&#x60; - Materials Science and Engineering * &#x60;4&#x60; - Architecture * &#x60;5&#x60; - Chemistry * &#x60;6&#x60; - Electrical Engineering and Computer Science * &#x60;7&#x60; - Biology * &#x60;8&#x60; - Physics * &#x60;9&#x60; - Brain and Cognitive Sciences * &#x60;10&#x60; - Chemical Engineering * &#x60;11&#x60; - Urban Studies and Planning * &#x60;12&#x60; - Earth, Atmospheric, and Planetary Sciences * &#x60;14&#x60; - Economics * &#x60;15&#x60; - Management * &#x60;16&#x60; - Aeronautics and Astronautics * &#x60;17&#x60; - Political Science * &#x60;18&#x60; - Mathematics * &#x60;20&#x60; - Biological Engineering * &#x60;21A&#x60; - Anthropology * &#x60;21G&#x60; - Global Languages * &#x60;21H&#x60; - History * &#x60;21L&#x60; - Literature * &#x60;21M&#x60; - Music and Theater Arts * &#x60;22&#x60; - Nuclear Science and Engineering * &#x60;24&#x60; - Linguistics and Philosophy * &#x60;CC&#x60; - Concourse * &#x60;CMS-W&#x60; - Comparative Media Studies/Writing * &#x60;EC&#x60; - Edgerton Center * &#x60;ES&#x60; - Experimental Study Group * &#x60;ESD&#x60; - Engineering Systems Division * &#x60;HST&#x60; - Medical Engineering and Science * &#x60;IDS&#x60; - Data, Systems, and Society * &#x60;MAS&#x60; - Media Arts and Sciences * &#x60;PE&#x60; - Athletics, Physical Education and Recreation * &#x60;SP&#x60; - Special Programs * &#x60;STS&#x60; - Science, Technology, and Society * &#x60;WGS&#x60; - Women\&#39;s and Gender Studies
     * @param {boolean} [free] The course/program is offered for free
     * @param {Array<VideoPlaylistsListLevelEnum>} [level] The academic level of the resources  * &#x60;undergraduate&#x60; - Undergraduate * &#x60;graduate&#x60; - Graduate * &#x60;high_school&#x60; - High School * &#x60;noncredit&#x60; - Non-Credit * &#x60;advanced&#x60; - Advanced * &#x60;intermediate&#x60; - Intermediate * &#x60;introductory&#x60; - Introductory
     * @param {number} [limit] Number of results to return per page.
     * @param {Array<VideoPlaylistsListOfferedByEnum>} [offered_by] The organization that offers a learning resource  * &#x60;mitx&#x60; - MITx * &#x60;ocw&#x60; - MIT OpenCourseWare * &#x60;bootcamps&#x60; - Bootcamps * &#x60;xpro&#x60; - MIT xPRO * &#x60;mitpe&#x60; - MIT Professional Education * &#x60;see&#x60; - MIT Sloan Executive Education
     * @param {number} [offset] The initial index from which to return the results.
     * @param {Array<VideoPlaylistsListPlatformEnum>} [platform] The platform on which learning resources are offered  * &#x60;edx&#x60; - edX * &#x60;ocw&#x60; - MIT OpenCourseWare * &#x60;oll&#x60; - Open Learning Library * &#x60;mitxonline&#x60; - MITx Online * &#x60;bootcamps&#x60; - Bootcamps * &#x60;xpro&#x60; - MIT xPRO * &#x60;csail&#x60; - CSAIL * &#x60;mitpe&#x60; - MIT Professional Education * &#x60;see&#x60; - MIT Sloan Executive Education * &#x60;scc&#x60; - Schwarzman College of Computing * &#x60;ctl&#x60; - Center for Transportation &amp; Logistics * &#x60;whu&#x60; - WHU * &#x60;susskind&#x60; - Susskind * &#x60;globalalumni&#x60; - Global Alumni * &#x60;simplilearn&#x60; - Simplilearn * &#x60;emeritus&#x60; - Emeritus * &#x60;podcast&#x60; - Podcast * &#x60;youtube&#x60; - YouTube
     * @param {boolean} [professional]
     * @param {Array<string>} [readable_id] Multiple values may be separated by commas.
     * @param {Array<VideoPlaylistsListResourceCategoryEnum>} [resource_category] The resource category of the learning resources  * &#x60;course&#x60; - Course * &#x60;program&#x60; - Program * &#x60;learning_material&#x60; - Learning Material
     * @param {Array<VideoPlaylistsListResourceTypeEnum>} [resource_type] The type of learning resource  * &#x60;course&#x60; - Course * &#x60;program&#x60; - Program * &#x60;learning_path&#x60; - Learning Path * &#x60;podcast&#x60; - Podcast * &#x60;podcast_episode&#x60; - Podcast Episode * &#x60;video&#x60; - Video * &#x60;video_playlist&#x60; - Video Playlist
     * @param {VideoPlaylistsListSortbyEnum} [sortby] Sort By  * &#x60;id&#x60; - Object ID ascending * &#x60;-id&#x60; - Object ID descending * &#x60;readable_id&#x60; - Readable ID ascending * &#x60;-readable_id&#x60; - Readable ID descending * &#x60;last_modified&#x60; - Last Modified Date ascending * &#x60;-last_modified&#x60; - Last Modified Date descending * &#x60;new&#x60; - Newest resources first * &#x60;start_date&#x60; - Start Date ascending * &#x60;-start_date&#x60; - Start Date descending * &#x60;mitcoursenumber&#x60; - MIT course number ascending * &#x60;-mitcoursenumber&#x60; - MIT course number descending * &#x60;views&#x60; - Popularity ascending * &#x60;-views&#x60; - Popularity descending * &#x60;upcoming&#x60; - Next start date ascending
     * @param {Array<string>} [topic] Multiple values may be separated by commas.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    videoPlaylistsList: async (
      certification?: boolean,
      certification_type?: Array<VideoPlaylistsListCertificationTypeEnum>,
      course_feature?: Array<string>,
      delivery?: Array<Array<VideoPlaylistsListDeliveryEnum>>,
      department?: Array<VideoPlaylistsListDepartmentEnum>,
      free?: boolean,
      level?: Array<VideoPlaylistsListLevelEnum>,
      limit?: number,
      offered_by?: Array<VideoPlaylistsListOfferedByEnum>,
      offset?: number,
      platform?: Array<VideoPlaylistsListPlatformEnum>,
      professional?: boolean,
      readable_id?: Array<string>,
      resource_category?: Array<VideoPlaylistsListResourceCategoryEnum>,
      resource_type?: Array<VideoPlaylistsListResourceTypeEnum>,
      sortby?: VideoPlaylistsListSortbyEnum,
      topic?: Array<string>,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/api/v1/video_playlists/`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      if (certification !== undefined) {
        localVarQueryParameter["certification"] = certification
      }

      if (certification_type) {
        localVarQueryParameter["certification_type"] = certification_type
      }

      if (course_feature) {
        localVarQueryParameter["course_feature"] = course_feature.join(
          COLLECTION_FORMATS.csv,
        )
      }

      if (delivery) {
        localVarQueryParameter["delivery"] = delivery
      }

      if (department) {
        localVarQueryParameter["department"] = department
      }

      if (free !== undefined) {
        localVarQueryParameter["free"] = free
      }

      if (level) {
        localVarQueryParameter["level"] = level
      }

      if (limit !== undefined) {
        localVarQueryParameter["limit"] = limit
      }

      if (offered_by) {
        localVarQueryParameter["offered_by"] = offered_by
      }

      if (offset !== undefined) {
        localVarQueryParameter["offset"] = offset
      }

      if (platform) {
        localVarQueryParameter["platform"] = platform
      }

      if (professional !== undefined) {
        localVarQueryParameter["professional"] = professional
      }

      if (readable_id) {
        localVarQueryParameter["readable_id"] = readable_id.join(
          COLLECTION_FORMATS.csv,
        )
      }

      if (resource_category) {
        localVarQueryParameter["resource_category"] = resource_category
      }

      if (resource_type) {
        localVarQueryParameter["resource_type"] = resource_type
      }

      if (sortby !== undefined) {
        localVarQueryParameter["sortby"] = sortby
      }

      if (topic) {
        localVarQueryParameter["topic"] = topic.join(COLLECTION_FORMATS.csv)
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     * Retrieve a single video playlist
     * @summary Retrieve
     * @param {number} id A unique integer value identifying this learning resource.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    videoPlaylistsRetrieve: async (
      id: number,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists("videoPlaylistsRetrieve", "id", id)
      const localVarPath = `/api/v1/video_playlists/{id}/`.replace(
        `{${"id"}}`,
        encodeURIComponent(String(id)),
      )
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
  }
}

/**
 * VideoPlaylistsApi - functional programming interface
 * @export
 */
export const VideoPlaylistsApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator =
    VideoPlaylistsApiAxiosParamCreator(configuration)
  return {
    /**
     * Get a list of related learning resources for a learning resource.
     * @summary Nested Learning Resource List
     * @param {number} learning_resource_id id of the parent learning resource
     * @param {number} [limit] Number of results to return per page.
     * @param {number} [offset] The initial index from which to return the results.
     * @param {string} [sortby] Which field to use when ordering the results.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async videoPlaylistsItemsList(
      learning_resource_id: number,
      limit?: number,
      offset?: number,
      sortby?: string,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<PaginatedLearningResourceRelationshipList>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.videoPlaylistsItemsList(
          learning_resource_id,
          limit,
          offset,
          sortby,
          options,
        )
      const index = configuration?.serverIndex ?? 0
      const operationBasePath =
        operationServerMap["VideoPlaylistsApi.videoPlaylistsItemsList"]?.[index]
          ?.url
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, operationBasePath || basePath)
    },
    /**
     * Get a singe related learning resource for a learning resource.
     * @summary Nested Learning Resource Retrieve
     * @param {number} id A unique integer value identifying this learning resource relationship.
     * @param {number} learning_resource_id id of the parent learning resource
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async videoPlaylistsItemsRetrieve(
      id: number,
      learning_resource_id: number,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<LearningResourceRelationship>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.videoPlaylistsItemsRetrieve(
          id,
          learning_resource_id,
          options,
        )
      const index = configuration?.serverIndex ?? 0
      const operationBasePath =
        operationServerMap["VideoPlaylistsApi.videoPlaylistsItemsRetrieve"]?.[
          index
        ]?.url
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, operationBasePath || basePath)
    },
    /**
     * Get a paginated list of video playlists
     * @summary List
     * @param {boolean} [certification]
     * @param {Array<VideoPlaylistsListCertificationTypeEnum>} [certification_type] The type of certification offered  * &#x60;micromasters&#x60; - MicroMasters Credential * &#x60;professional&#x60; - Professional Certificate * &#x60;completion&#x60; - Certificate of Completion * &#x60;none&#x60; - No Certificate
     * @param {Array<string>} [course_feature] Multiple values may be separated by commas.
     * @param {Array<Array<VideoPlaylistsListDeliveryEnum>>} [delivery] The delivery of course/program resources  * &#x60;online&#x60; - Online * &#x60;hybrid&#x60; - Hybrid * &#x60;in_person&#x60; - In person * &#x60;offline&#x60; - Offline
     * @param {Array<VideoPlaylistsListDepartmentEnum>} [department] The department that offers learning resources  * &#x60;1&#x60; - Civil and Environmental Engineering * &#x60;2&#x60; - Mechanical Engineering * &#x60;3&#x60; - Materials Science and Engineering * &#x60;4&#x60; - Architecture * &#x60;5&#x60; - Chemistry * &#x60;6&#x60; - Electrical Engineering and Computer Science * &#x60;7&#x60; - Biology * &#x60;8&#x60; - Physics * &#x60;9&#x60; - Brain and Cognitive Sciences * &#x60;10&#x60; - Chemical Engineering * &#x60;11&#x60; - Urban Studies and Planning * &#x60;12&#x60; - Earth, Atmospheric, and Planetary Sciences * &#x60;14&#x60; - Economics * &#x60;15&#x60; - Management * &#x60;16&#x60; - Aeronautics and Astronautics * &#x60;17&#x60; - Political Science * &#x60;18&#x60; - Mathematics * &#x60;20&#x60; - Biological Engineering * &#x60;21A&#x60; - Anthropology * &#x60;21G&#x60; - Global Languages * &#x60;21H&#x60; - History * &#x60;21L&#x60; - Literature * &#x60;21M&#x60; - Music and Theater Arts * &#x60;22&#x60; - Nuclear Science and Engineering * &#x60;24&#x60; - Linguistics and Philosophy * &#x60;CC&#x60; - Concourse * &#x60;CMS-W&#x60; - Comparative Media Studies/Writing * &#x60;EC&#x60; - Edgerton Center * &#x60;ES&#x60; - Experimental Study Group * &#x60;ESD&#x60; - Engineering Systems Division * &#x60;HST&#x60; - Medical Engineering and Science * &#x60;IDS&#x60; - Data, Systems, and Society * &#x60;MAS&#x60; - Media Arts and Sciences * &#x60;PE&#x60; - Athletics, Physical Education and Recreation * &#x60;SP&#x60; - Special Programs * &#x60;STS&#x60; - Science, Technology, and Society * &#x60;WGS&#x60; - Women\&#39;s and Gender Studies
     * @param {boolean} [free] The course/program is offered for free
     * @param {Array<VideoPlaylistsListLevelEnum>} [level] The academic level of the resources  * &#x60;undergraduate&#x60; - Undergraduate * &#x60;graduate&#x60; - Graduate * &#x60;high_school&#x60; - High School * &#x60;noncredit&#x60; - Non-Credit * &#x60;advanced&#x60; - Advanced * &#x60;intermediate&#x60; - Intermediate * &#x60;introductory&#x60; - Introductory
     * @param {number} [limit] Number of results to return per page.
     * @param {Array<VideoPlaylistsListOfferedByEnum>} [offered_by] The organization that offers a learning resource  * &#x60;mitx&#x60; - MITx * &#x60;ocw&#x60; - MIT OpenCourseWare * &#x60;bootcamps&#x60; - Bootcamps * &#x60;xpro&#x60; - MIT xPRO * &#x60;mitpe&#x60; - MIT Professional Education * &#x60;see&#x60; - MIT Sloan Executive Education
     * @param {number} [offset] The initial index from which to return the results.
     * @param {Array<VideoPlaylistsListPlatformEnum>} [platform] The platform on which learning resources are offered  * &#x60;edx&#x60; - edX * &#x60;ocw&#x60; - MIT OpenCourseWare * &#x60;oll&#x60; - Open Learning Library * &#x60;mitxonline&#x60; - MITx Online * &#x60;bootcamps&#x60; - Bootcamps * &#x60;xpro&#x60; - MIT xPRO * &#x60;csail&#x60; - CSAIL * &#x60;mitpe&#x60; - MIT Professional Education * &#x60;see&#x60; - MIT Sloan Executive Education * &#x60;scc&#x60; - Schwarzman College of Computing * &#x60;ctl&#x60; - Center for Transportation &amp; Logistics * &#x60;whu&#x60; - WHU * &#x60;susskind&#x60; - Susskind * &#x60;globalalumni&#x60; - Global Alumni * &#x60;simplilearn&#x60; - Simplilearn * &#x60;emeritus&#x60; - Emeritus * &#x60;podcast&#x60; - Podcast * &#x60;youtube&#x60; - YouTube
     * @param {boolean} [professional]
     * @param {Array<string>} [readable_id] Multiple values may be separated by commas.
     * @param {Array<VideoPlaylistsListResourceCategoryEnum>} [resource_category] The resource category of the learning resources  * &#x60;course&#x60; - Course * &#x60;program&#x60; - Program * &#x60;learning_material&#x60; - Learning Material
     * @param {Array<VideoPlaylistsListResourceTypeEnum>} [resource_type] The type of learning resource  * &#x60;course&#x60; - Course * &#x60;program&#x60; - Program * &#x60;learning_path&#x60; - Learning Path * &#x60;podcast&#x60; - Podcast * &#x60;podcast_episode&#x60; - Podcast Episode * &#x60;video&#x60; - Video * &#x60;video_playlist&#x60; - Video Playlist
     * @param {VideoPlaylistsListSortbyEnum} [sortby] Sort By  * &#x60;id&#x60; - Object ID ascending * &#x60;-id&#x60; - Object ID descending * &#x60;readable_id&#x60; - Readable ID ascending * &#x60;-readable_id&#x60; - Readable ID descending * &#x60;last_modified&#x60; - Last Modified Date ascending * &#x60;-last_modified&#x60; - Last Modified Date descending * &#x60;new&#x60; - Newest resources first * &#x60;start_date&#x60; - Start Date ascending * &#x60;-start_date&#x60; - Start Date descending * &#x60;mitcoursenumber&#x60; - MIT course number ascending * &#x60;-mitcoursenumber&#x60; - MIT course number descending * &#x60;views&#x60; - Popularity ascending * &#x60;-views&#x60; - Popularity descending * &#x60;upcoming&#x60; - Next start date ascending
     * @param {Array<string>} [topic] Multiple values may be separated by commas.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async videoPlaylistsList(
      certification?: boolean,
      certification_type?: Array<VideoPlaylistsListCertificationTypeEnum>,
      course_feature?: Array<string>,
      delivery?: Array<Array<VideoPlaylistsListDeliveryEnum>>,
      department?: Array<VideoPlaylistsListDepartmentEnum>,
      free?: boolean,
      level?: Array<VideoPlaylistsListLevelEnum>,
      limit?: number,
      offered_by?: Array<VideoPlaylistsListOfferedByEnum>,
      offset?: number,
      platform?: Array<VideoPlaylistsListPlatformEnum>,
      professional?: boolean,
      readable_id?: Array<string>,
      resource_category?: Array<VideoPlaylistsListResourceCategoryEnum>,
      resource_type?: Array<VideoPlaylistsListResourceTypeEnum>,
      sortby?: VideoPlaylistsListSortbyEnum,
      topic?: Array<string>,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<PaginatedVideoPlaylistResourceList>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.videoPlaylistsList(
          certification,
          certification_type,
          course_feature,
          delivery,
          department,
          free,
          level,
          limit,
          offered_by,
          offset,
          platform,
          professional,
          readable_id,
          resource_category,
          resource_type,
          sortby,
          topic,
          options,
        )
      const index = configuration?.serverIndex ?? 0
      const operationBasePath =
        operationServerMap["VideoPlaylistsApi.videoPlaylistsList"]?.[index]?.url
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, operationBasePath || basePath)
    },
    /**
     * Retrieve a single video playlist
     * @summary Retrieve
     * @param {number} id A unique integer value identifying this learning resource.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async videoPlaylistsRetrieve(
      id: number,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<VideoPlaylistResource>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.videoPlaylistsRetrieve(id, options)
      const index = configuration?.serverIndex ?? 0
      const operationBasePath =
        operationServerMap["VideoPlaylistsApi.videoPlaylistsRetrieve"]?.[index]
          ?.url
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, operationBasePath || basePath)
    },
  }
}

/**
 * VideoPlaylistsApi - factory interface
 * @export
 */
export const VideoPlaylistsApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = VideoPlaylistsApiFp(configuration)
  return {
    /**
     * Get a list of related learning resources for a learning resource.
     * @summary Nested Learning Resource List
     * @param {VideoPlaylistsApiVideoPlaylistsItemsListRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    videoPlaylistsItemsList(
      requestParameters: VideoPlaylistsApiVideoPlaylistsItemsListRequest,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<PaginatedLearningResourceRelationshipList> {
      return localVarFp
        .videoPlaylistsItemsList(
          requestParameters.learning_resource_id,
          requestParameters.limit,
          requestParameters.offset,
          requestParameters.sortby,
          options,
        )
        .then((request) => request(axios, basePath))
    },
    /**
     * Get a singe related learning resource for a learning resource.
     * @summary Nested Learning Resource Retrieve
     * @param {VideoPlaylistsApiVideoPlaylistsItemsRetrieveRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    videoPlaylistsItemsRetrieve(
      requestParameters: VideoPlaylistsApiVideoPlaylistsItemsRetrieveRequest,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<LearningResourceRelationship> {
      return localVarFp
        .videoPlaylistsItemsRetrieve(
          requestParameters.id,
          requestParameters.learning_resource_id,
          options,
        )
        .then((request) => request(axios, basePath))
    },
    /**
     * Get a paginated list of video playlists
     * @summary List
     * @param {VideoPlaylistsApiVideoPlaylistsListRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    videoPlaylistsList(
      requestParameters: VideoPlaylistsApiVideoPlaylistsListRequest = {},
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<PaginatedVideoPlaylistResourceList> {
      return localVarFp
        .videoPlaylistsList(
          requestParameters.certification,
          requestParameters.certification_type,
          requestParameters.course_feature,
          requestParameters.delivery,
          requestParameters.department,
          requestParameters.free,
          requestParameters.level,
          requestParameters.limit,
          requestParameters.offered_by,
          requestParameters.offset,
          requestParameters.platform,
          requestParameters.professional,
          requestParameters.readable_id,
          requestParameters.resource_category,
          requestParameters.resource_type,
          requestParameters.sortby,
          requestParameters.topic,
          options,
        )
        .then((request) => request(axios, basePath))
    },
    /**
     * Retrieve a single video playlist
     * @summary Retrieve
     * @param {VideoPlaylistsApiVideoPlaylistsRetrieveRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    videoPlaylistsRetrieve(
      requestParameters: VideoPlaylistsApiVideoPlaylistsRetrieveRequest,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<VideoPlaylistResource> {
      return localVarFp
        .videoPlaylistsRetrieve(requestParameters.id, options)
        .then((request) => request(axios, basePath))
    },
  }
}

/**
 * Request parameters for videoPlaylistsItemsList operation in VideoPlaylistsApi.
 * @export
 * @interface VideoPlaylistsApiVideoPlaylistsItemsListRequest
 */
export interface VideoPlaylistsApiVideoPlaylistsItemsListRequest {
  /**
   * id of the parent learning resource
   * @type {number}
   * @memberof VideoPlaylistsApiVideoPlaylistsItemsList
   */
  readonly learning_resource_id: number

  /**
   * Number of results to return per page.
   * @type {number}
   * @memberof VideoPlaylistsApiVideoPlaylistsItemsList
   */
  readonly limit?: number

  /**
   * The initial index from which to return the results.
   * @type {number}
   * @memberof VideoPlaylistsApiVideoPlaylistsItemsList
   */
  readonly offset?: number

  /**
   * Which field to use when ordering the results.
   * @type {string}
   * @memberof VideoPlaylistsApiVideoPlaylistsItemsList
   */
  readonly sortby?: string
}

/**
 * Request parameters for videoPlaylistsItemsRetrieve operation in VideoPlaylistsApi.
 * @export
 * @interface VideoPlaylistsApiVideoPlaylistsItemsRetrieveRequest
 */
export interface VideoPlaylistsApiVideoPlaylistsItemsRetrieveRequest {
  /**
   * A unique integer value identifying this learning resource relationship.
   * @type {number}
   * @memberof VideoPlaylistsApiVideoPlaylistsItemsRetrieve
   */
  readonly id: number

  /**
   * id of the parent learning resource
   * @type {number}
   * @memberof VideoPlaylistsApiVideoPlaylistsItemsRetrieve
   */
  readonly learning_resource_id: number
}

/**
 * Request parameters for videoPlaylistsList operation in VideoPlaylistsApi.
 * @export
 * @interface VideoPlaylistsApiVideoPlaylistsListRequest
 */
export interface VideoPlaylistsApiVideoPlaylistsListRequest {
  /**
   *
   * @type {boolean}
   * @memberof VideoPlaylistsApiVideoPlaylistsList
   */
  readonly certification?: boolean

  /**
   * The type of certification offered  * &#x60;micromasters&#x60; - MicroMasters Credential * &#x60;professional&#x60; - Professional Certificate * &#x60;completion&#x60; - Certificate of Completion * &#x60;none&#x60; - No Certificate
   * @type {Array<'completion' | 'micromasters' | 'none' | 'professional'>}
   * @memberof VideoPlaylistsApiVideoPlaylistsList
   */
  readonly certification_type?: Array<VideoPlaylistsListCertificationTypeEnum>

  /**
   * Multiple values may be separated by commas.
   * @type {Array<string>}
   * @memberof VideoPlaylistsApiVideoPlaylistsList
   */
  readonly course_feature?: Array<string>

  /**
   * The delivery of course/program resources  * &#x60;online&#x60; - Online * &#x60;hybrid&#x60; - Hybrid * &#x60;in_person&#x60; - In person * &#x60;offline&#x60; - Offline
   * @type {Array<Array<'online' | 'hybrid' | 'in_person' | 'offline'>>}
   * @memberof VideoPlaylistsApiVideoPlaylistsList
   */
  readonly delivery?: Array<Array<VideoPlaylistsListDeliveryEnum>>

  /**
   * The department that offers learning resources  * &#x60;1&#x60; - Civil and Environmental Engineering * &#x60;2&#x60; - Mechanical Engineering * &#x60;3&#x60; - Materials Science and Engineering * &#x60;4&#x60; - Architecture * &#x60;5&#x60; - Chemistry * &#x60;6&#x60; - Electrical Engineering and Computer Science * &#x60;7&#x60; - Biology * &#x60;8&#x60; - Physics * &#x60;9&#x60; - Brain and Cognitive Sciences * &#x60;10&#x60; - Chemical Engineering * &#x60;11&#x60; - Urban Studies and Planning * &#x60;12&#x60; - Earth, Atmospheric, and Planetary Sciences * &#x60;14&#x60; - Economics * &#x60;15&#x60; - Management * &#x60;16&#x60; - Aeronautics and Astronautics * &#x60;17&#x60; - Political Science * &#x60;18&#x60; - Mathematics * &#x60;20&#x60; - Biological Engineering * &#x60;21A&#x60; - Anthropology * &#x60;21G&#x60; - Global Languages * &#x60;21H&#x60; - History * &#x60;21L&#x60; - Literature * &#x60;21M&#x60; - Music and Theater Arts * &#x60;22&#x60; - Nuclear Science and Engineering * &#x60;24&#x60; - Linguistics and Philosophy * &#x60;CC&#x60; - Concourse * &#x60;CMS-W&#x60; - Comparative Media Studies/Writing * &#x60;EC&#x60; - Edgerton Center * &#x60;ES&#x60; - Experimental Study Group * &#x60;ESD&#x60; - Engineering Systems Division * &#x60;HST&#x60; - Medical Engineering and Science * &#x60;IDS&#x60; - Data, Systems, and Society * &#x60;MAS&#x60; - Media Arts and Sciences * &#x60;PE&#x60; - Athletics, Physical Education and Recreation * &#x60;SP&#x60; - Special Programs * &#x60;STS&#x60; - Science, Technology, and Society * &#x60;WGS&#x60; - Women\&#39;s and Gender Studies
   * @type {Array<'1' | '10' | '11' | '12' | '14' | '15' | '16' | '17' | '18' | '2' | '20' | '21A' | '21G' | '21H' | '21L' | '21M' | '22' | '24' | '3' | '4' | '5' | '6' | '7' | '8' | '9' | 'CC' | 'CMS-W' | 'EC' | 'ES' | 'ESD' | 'HST' | 'IDS' | 'MAS' | 'PE' | 'SP' | 'STS' | 'WGS'>}
   * @memberof VideoPlaylistsApiVideoPlaylistsList
   */
  readonly department?: Array<VideoPlaylistsListDepartmentEnum>

  /**
   * The course/program is offered for free
   * @type {boolean}
   * @memberof VideoPlaylistsApiVideoPlaylistsList
   */
  readonly free?: boolean

  /**
   * The academic level of the resources  * &#x60;undergraduate&#x60; - Undergraduate * &#x60;graduate&#x60; - Graduate * &#x60;high_school&#x60; - High School * &#x60;noncredit&#x60; - Non-Credit * &#x60;advanced&#x60; - Advanced * &#x60;intermediate&#x60; - Intermediate * &#x60;introductory&#x60; - Introductory
   * @type {Array<'advanced' | 'graduate' | 'high_school' | 'intermediate' | 'introductory' | 'noncredit' | 'undergraduate'>}
   * @memberof VideoPlaylistsApiVideoPlaylistsList
   */
  readonly level?: Array<VideoPlaylistsListLevelEnum>

  /**
   * Number of results to return per page.
   * @type {number}
   * @memberof VideoPlaylistsApiVideoPlaylistsList
   */
  readonly limit?: number

  /**
   * The organization that offers a learning resource  * &#x60;mitx&#x60; - MITx * &#x60;ocw&#x60; - MIT OpenCourseWare * &#x60;bootcamps&#x60; - Bootcamps * &#x60;xpro&#x60; - MIT xPRO * &#x60;mitpe&#x60; - MIT Professional Education * &#x60;see&#x60; - MIT Sloan Executive Education
   * @type {Array<'bootcamps' | 'mitpe' | 'mitx' | 'ocw' | 'see' | 'xpro'>}
   * @memberof VideoPlaylistsApiVideoPlaylistsList
   */
  readonly offered_by?: Array<VideoPlaylistsListOfferedByEnum>

  /**
   * The initial index from which to return the results.
   * @type {number}
   * @memberof VideoPlaylistsApiVideoPlaylistsList
   */
  readonly offset?: number

  /**
   * The platform on which learning resources are offered  * &#x60;edx&#x60; - edX * &#x60;ocw&#x60; - MIT OpenCourseWare * &#x60;oll&#x60; - Open Learning Library * &#x60;mitxonline&#x60; - MITx Online * &#x60;bootcamps&#x60; - Bootcamps * &#x60;xpro&#x60; - MIT xPRO * &#x60;csail&#x60; - CSAIL * &#x60;mitpe&#x60; - MIT Professional Education * &#x60;see&#x60; - MIT Sloan Executive Education * &#x60;scc&#x60; - Schwarzman College of Computing * &#x60;ctl&#x60; - Center for Transportation &amp; Logistics * &#x60;whu&#x60; - WHU * &#x60;susskind&#x60; - Susskind * &#x60;globalalumni&#x60; - Global Alumni * &#x60;simplilearn&#x60; - Simplilearn * &#x60;emeritus&#x60; - Emeritus * &#x60;podcast&#x60; - Podcast * &#x60;youtube&#x60; - YouTube
   * @type {Array<'bootcamps' | 'csail' | 'ctl' | 'edx' | 'emeritus' | 'globalalumni' | 'mitpe' | 'mitxonline' | 'ocw' | 'oll' | 'podcast' | 'scc' | 'see' | 'simplilearn' | 'susskind' | 'whu' | 'xpro' | 'youtube'>}
   * @memberof VideoPlaylistsApiVideoPlaylistsList
   */
  readonly platform?: Array<VideoPlaylistsListPlatformEnum>

  /**
   *
   * @type {boolean}
   * @memberof VideoPlaylistsApiVideoPlaylistsList
   */
  readonly professional?: boolean

  /**
   * Multiple values may be separated by commas.
   * @type {Array<string>}
   * @memberof VideoPlaylistsApiVideoPlaylistsList
   */
  readonly readable_id?: Array<string>

  /**
   * The resource category of the learning resources  * &#x60;course&#x60; - Course * &#x60;program&#x60; - Program * &#x60;learning_material&#x60; - Learning Material
   * @type {Array<'course' | 'learning_material' | 'program'>}
   * @memberof VideoPlaylistsApiVideoPlaylistsList
   */
  readonly resource_category?: Array<VideoPlaylistsListResourceCategoryEnum>

  /**
   * The type of learning resource  * &#x60;course&#x60; - Course * &#x60;program&#x60; - Program * &#x60;learning_path&#x60; - Learning Path * &#x60;podcast&#x60; - Podcast * &#x60;podcast_episode&#x60; - Podcast Episode * &#x60;video&#x60; - Video * &#x60;video_playlist&#x60; - Video Playlist
   * @type {Array<'course' | 'learning_path' | 'podcast' | 'podcast_episode' | 'program' | 'video' | 'video_playlist'>}
   * @memberof VideoPlaylistsApiVideoPlaylistsList
   */
  readonly resource_type?: Array<VideoPlaylistsListResourceTypeEnum>

  /**
   * Sort By  * &#x60;id&#x60; - Object ID ascending * &#x60;-id&#x60; - Object ID descending * &#x60;readable_id&#x60; - Readable ID ascending * &#x60;-readable_id&#x60; - Readable ID descending * &#x60;last_modified&#x60; - Last Modified Date ascending * &#x60;-last_modified&#x60; - Last Modified Date descending * &#x60;new&#x60; - Newest resources first * &#x60;start_date&#x60; - Start Date ascending * &#x60;-start_date&#x60; - Start Date descending * &#x60;mitcoursenumber&#x60; - MIT course number ascending * &#x60;-mitcoursenumber&#x60; - MIT course number descending * &#x60;views&#x60; - Popularity ascending * &#x60;-views&#x60; - Popularity descending * &#x60;upcoming&#x60; - Next start date ascending
   * @type {'-id' | '-last_modified' | '-mitcoursenumber' | '-readable_id' | '-start_date' | '-views' | 'id' | 'last_modified' | 'mitcoursenumber' | 'new' | 'readable_id' | 'start_date' | 'upcoming' | 'views'}
   * @memberof VideoPlaylistsApiVideoPlaylistsList
   */
  readonly sortby?: VideoPlaylistsListSortbyEnum

  /**
   * Multiple values may be separated by commas.
   * @type {Array<string>}
   * @memberof VideoPlaylistsApiVideoPlaylistsList
   */
  readonly topic?: Array<string>
}

/**
 * Request parameters for videoPlaylistsRetrieve operation in VideoPlaylistsApi.
 * @export
 * @interface VideoPlaylistsApiVideoPlaylistsRetrieveRequest
 */
export interface VideoPlaylistsApiVideoPlaylistsRetrieveRequest {
  /**
   * A unique integer value identifying this learning resource.
   * @type {number}
   * @memberof VideoPlaylistsApiVideoPlaylistsRetrieve
   */
  readonly id: number
}

/**
 * VideoPlaylistsApi - object-oriented interface
 * @export
 * @class VideoPlaylistsApi
 * @extends {BaseAPI}
 */
export class VideoPlaylistsApi extends BaseAPI {
  /**
   * Get a list of related learning resources for a learning resource.
   * @summary Nested Learning Resource List
   * @param {VideoPlaylistsApiVideoPlaylistsItemsListRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof VideoPlaylistsApi
   */
  public videoPlaylistsItemsList(
    requestParameters: VideoPlaylistsApiVideoPlaylistsItemsListRequest,
    options?: RawAxiosRequestConfig,
  ) {
    return VideoPlaylistsApiFp(this.configuration)
      .videoPlaylistsItemsList(
        requestParameters.learning_resource_id,
        requestParameters.limit,
        requestParameters.offset,
        requestParameters.sortby,
        options,
      )
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   * Get a singe related learning resource for a learning resource.
   * @summary Nested Learning Resource Retrieve
   * @param {VideoPlaylistsApiVideoPlaylistsItemsRetrieveRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof VideoPlaylistsApi
   */
  public videoPlaylistsItemsRetrieve(
    requestParameters: VideoPlaylistsApiVideoPlaylistsItemsRetrieveRequest,
    options?: RawAxiosRequestConfig,
  ) {
    return VideoPlaylistsApiFp(this.configuration)
      .videoPlaylistsItemsRetrieve(
        requestParameters.id,
        requestParameters.learning_resource_id,
        options,
      )
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   * Get a paginated list of video playlists
   * @summary List
   * @param {VideoPlaylistsApiVideoPlaylistsListRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof VideoPlaylistsApi
   */
  public videoPlaylistsList(
    requestParameters: VideoPlaylistsApiVideoPlaylistsListRequest = {},
    options?: RawAxiosRequestConfig,
  ) {
    return VideoPlaylistsApiFp(this.configuration)
      .videoPlaylistsList(
        requestParameters.certification,
        requestParameters.certification_type,
        requestParameters.course_feature,
        requestParameters.delivery,
        requestParameters.department,
        requestParameters.free,
        requestParameters.level,
        requestParameters.limit,
        requestParameters.offered_by,
        requestParameters.offset,
        requestParameters.platform,
        requestParameters.professional,
        requestParameters.readable_id,
        requestParameters.resource_category,
        requestParameters.resource_type,
        requestParameters.sortby,
        requestParameters.topic,
        options,
      )
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   * Retrieve a single video playlist
   * @summary Retrieve
   * @param {VideoPlaylistsApiVideoPlaylistsRetrieveRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof VideoPlaylistsApi
   */
  public videoPlaylistsRetrieve(
    requestParameters: VideoPlaylistsApiVideoPlaylistsRetrieveRequest,
    options?: RawAxiosRequestConfig,
  ) {
    return VideoPlaylistsApiFp(this.configuration)
      .videoPlaylistsRetrieve(requestParameters.id, options)
      .then((request) => request(this.axios, this.basePath))
  }
}

/**
 * @export
 */
export const VideoPlaylistsListCertificationTypeEnum = {
  Completion: "completion",
  Micromasters: "micromasters",
  None: "none",
  Professional: "professional",
} as const
export type VideoPlaylistsListCertificationTypeEnum =
  (typeof VideoPlaylistsListCertificationTypeEnum)[keyof typeof VideoPlaylistsListCertificationTypeEnum]
/**
 * @export
 */
export const VideoPlaylistsListDeliveryEnum = {
  Online: "online",
  Hybrid: "hybrid",
  InPerson: "in_person",
  Offline: "offline",
} as const
export type VideoPlaylistsListDeliveryEnum =
  (typeof VideoPlaylistsListDeliveryEnum)[keyof typeof VideoPlaylistsListDeliveryEnum]
/**
 * @export
 */
export const VideoPlaylistsListDepartmentEnum = {
  _1: "1",
  _10: "10",
  _11: "11",
  _12: "12",
  _14: "14",
  _15: "15",
  _16: "16",
  _17: "17",
  _18: "18",
  _2: "2",
  _20: "20",
  _21A: "21A",
  _21G: "21G",
  _21H: "21H",
  _21L: "21L",
  _21M: "21M",
  _22: "22",
  _24: "24",
  _3: "3",
  _4: "4",
  _5: "5",
  _6: "6",
  _7: "7",
  _8: "8",
  _9: "9",
  Cc: "CC",
  CmsW: "CMS-W",
  Ec: "EC",
  Es: "ES",
  Esd: "ESD",
  Hst: "HST",
  Ids: "IDS",
  Mas: "MAS",
  Pe: "PE",
  Sp: "SP",
  Sts: "STS",
  Wgs: "WGS",
} as const
export type VideoPlaylistsListDepartmentEnum =
  (typeof VideoPlaylistsListDepartmentEnum)[keyof typeof VideoPlaylistsListDepartmentEnum]
/**
 * @export
 */
export const VideoPlaylistsListLevelEnum = {
  Advanced: "advanced",
  Graduate: "graduate",
  HighSchool: "high_school",
  Intermediate: "intermediate",
  Introductory: "introductory",
  Noncredit: "noncredit",
  Undergraduate: "undergraduate",
} as const
export type VideoPlaylistsListLevelEnum =
  (typeof VideoPlaylistsListLevelEnum)[keyof typeof VideoPlaylistsListLevelEnum]
/**
 * @export
 */
export const VideoPlaylistsListOfferedByEnum = {
  Bootcamps: "bootcamps",
  Mitpe: "mitpe",
  Mitx: "mitx",
  Ocw: "ocw",
  See: "see",
  Xpro: "xpro",
} as const
export type VideoPlaylistsListOfferedByEnum =
  (typeof VideoPlaylistsListOfferedByEnum)[keyof typeof VideoPlaylistsListOfferedByEnum]
/**
 * @export
 */
export const VideoPlaylistsListPlatformEnum = {
  Bootcamps: "bootcamps",
  Csail: "csail",
  Ctl: "ctl",
  Edx: "edx",
  Emeritus: "emeritus",
  Globalalumni: "globalalumni",
  Mitpe: "mitpe",
  Mitxonline: "mitxonline",
  Ocw: "ocw",
  Oll: "oll",
  Podcast: "podcast",
  Scc: "scc",
  See: "see",
  Simplilearn: "simplilearn",
  Susskind: "susskind",
  Whu: "whu",
  Xpro: "xpro",
  Youtube: "youtube",
} as const
export type VideoPlaylistsListPlatformEnum =
  (typeof VideoPlaylistsListPlatformEnum)[keyof typeof VideoPlaylistsListPlatformEnum]
/**
 * @export
 */
export const VideoPlaylistsListResourceCategoryEnum = {
  Course: "course",
  LearningMaterial: "learning_material",
  Program: "program",
} as const
export type VideoPlaylistsListResourceCategoryEnum =
  (typeof VideoPlaylistsListResourceCategoryEnum)[keyof typeof VideoPlaylistsListResourceCategoryEnum]
/**
 * @export
 */
export const VideoPlaylistsListResourceTypeEnum = {
  Course: "course",
  LearningPath: "learning_path",
  Podcast: "podcast",
  PodcastEpisode: "podcast_episode",
  Program: "program",
  Video: "video",
  VideoPlaylist: "video_playlist",
} as const
export type VideoPlaylistsListResourceTypeEnum =
  (typeof VideoPlaylistsListResourceTypeEnum)[keyof typeof VideoPlaylistsListResourceTypeEnum]
/**
 * @export
 */
export const VideoPlaylistsListSortbyEnum = {
  Id: "-id",
  LastModified: "-last_modified",
  Mitcoursenumber: "-mitcoursenumber",
  ReadableId: "-readable_id",
  StartDate: "-start_date",
  Views: "-views",
  Id2: "id",
  LastModified2: "last_modified",
  Mitcoursenumber2: "mitcoursenumber",
  New: "new",
  ReadableId2: "readable_id",
  StartDate2: "start_date",
  Upcoming: "upcoming",
  Views2: "views",
} as const
export type VideoPlaylistsListSortbyEnum =
  (typeof VideoPlaylistsListSortbyEnum)[keyof typeof VideoPlaylistsListSortbyEnum]

/**
 * VideosApi - axios parameter creator
 * @export
 */
export const VideosApiAxiosParamCreator = function (
  configuration?: Configuration,
) {
  return {
    /**
     * Get a paginated list of videos
     * @summary List
     * @param {boolean} [certification]
     * @param {Array<VideosListCertificationTypeEnum>} [certification_type] The type of certification offered  * &#x60;micromasters&#x60; - MicroMasters Credential * &#x60;professional&#x60; - Professional Certificate * &#x60;completion&#x60; - Certificate of Completion * &#x60;none&#x60; - No Certificate
     * @param {Array<string>} [course_feature] Multiple values may be separated by commas.
     * @param {Array<Array<VideosListDeliveryEnum>>} [delivery] The delivery of course/program resources  * &#x60;online&#x60; - Online * &#x60;hybrid&#x60; - Hybrid * &#x60;in_person&#x60; - In person * &#x60;offline&#x60; - Offline
     * @param {Array<VideosListDepartmentEnum>} [department] The department that offers learning resources  * &#x60;1&#x60; - Civil and Environmental Engineering * &#x60;2&#x60; - Mechanical Engineering * &#x60;3&#x60; - Materials Science and Engineering * &#x60;4&#x60; - Architecture * &#x60;5&#x60; - Chemistry * &#x60;6&#x60; - Electrical Engineering and Computer Science * &#x60;7&#x60; - Biology * &#x60;8&#x60; - Physics * &#x60;9&#x60; - Brain and Cognitive Sciences * &#x60;10&#x60; - Chemical Engineering * &#x60;11&#x60; - Urban Studies and Planning * &#x60;12&#x60; - Earth, Atmospheric, and Planetary Sciences * &#x60;14&#x60; - Economics * &#x60;15&#x60; - Management * &#x60;16&#x60; - Aeronautics and Astronautics * &#x60;17&#x60; - Political Science * &#x60;18&#x60; - Mathematics * &#x60;20&#x60; - Biological Engineering * &#x60;21A&#x60; - Anthropology * &#x60;21G&#x60; - Global Languages * &#x60;21H&#x60; - History * &#x60;21L&#x60; - Literature * &#x60;21M&#x60; - Music and Theater Arts * &#x60;22&#x60; - Nuclear Science and Engineering * &#x60;24&#x60; - Linguistics and Philosophy * &#x60;CC&#x60; - Concourse * &#x60;CMS-W&#x60; - Comparative Media Studies/Writing * &#x60;EC&#x60; - Edgerton Center * &#x60;ES&#x60; - Experimental Study Group * &#x60;ESD&#x60; - Engineering Systems Division * &#x60;HST&#x60; - Medical Engineering and Science * &#x60;IDS&#x60; - Data, Systems, and Society * &#x60;MAS&#x60; - Media Arts and Sciences * &#x60;PE&#x60; - Athletics, Physical Education and Recreation * &#x60;SP&#x60; - Special Programs * &#x60;STS&#x60; - Science, Technology, and Society * &#x60;WGS&#x60; - Women\&#39;s and Gender Studies
     * @param {boolean} [free] The course/program is offered for free
     * @param {Array<VideosListLevelEnum>} [level] The academic level of the resources  * &#x60;undergraduate&#x60; - Undergraduate * &#x60;graduate&#x60; - Graduate * &#x60;high_school&#x60; - High School * &#x60;noncredit&#x60; - Non-Credit * &#x60;advanced&#x60; - Advanced * &#x60;intermediate&#x60; - Intermediate * &#x60;introductory&#x60; - Introductory
     * @param {number} [limit] Number of results to return per page.
     * @param {Array<VideosListOfferedByEnum>} [offered_by] The organization that offers a learning resource  * &#x60;mitx&#x60; - MITx * &#x60;ocw&#x60; - MIT OpenCourseWare * &#x60;bootcamps&#x60; - Bootcamps * &#x60;xpro&#x60; - MIT xPRO * &#x60;mitpe&#x60; - MIT Professional Education * &#x60;see&#x60; - MIT Sloan Executive Education
     * @param {number} [offset] The initial index from which to return the results.
     * @param {Array<VideosListPlatformEnum>} [platform] The platform on which learning resources are offered  * &#x60;edx&#x60; - edX * &#x60;ocw&#x60; - MIT OpenCourseWare * &#x60;oll&#x60; - Open Learning Library * &#x60;mitxonline&#x60; - MITx Online * &#x60;bootcamps&#x60; - Bootcamps * &#x60;xpro&#x60; - MIT xPRO * &#x60;csail&#x60; - CSAIL * &#x60;mitpe&#x60; - MIT Professional Education * &#x60;see&#x60; - MIT Sloan Executive Education * &#x60;scc&#x60; - Schwarzman College of Computing * &#x60;ctl&#x60; - Center for Transportation &amp; Logistics * &#x60;whu&#x60; - WHU * &#x60;susskind&#x60; - Susskind * &#x60;globalalumni&#x60; - Global Alumni * &#x60;simplilearn&#x60; - Simplilearn * &#x60;emeritus&#x60; - Emeritus * &#x60;podcast&#x60; - Podcast * &#x60;youtube&#x60; - YouTube
     * @param {boolean} [professional]
     * @param {Array<string>} [readable_id] Multiple values may be separated by commas.
     * @param {Array<VideosListResourceCategoryEnum>} [resource_category] The resource category of the learning resources  * &#x60;course&#x60; - Course * &#x60;program&#x60; - Program * &#x60;learning_material&#x60; - Learning Material
     * @param {Array<VideosListResourceTypeEnum>} [resource_type] The type of learning resource  * &#x60;course&#x60; - Course * &#x60;program&#x60; - Program * &#x60;learning_path&#x60; - Learning Path * &#x60;podcast&#x60; - Podcast * &#x60;podcast_episode&#x60; - Podcast Episode * &#x60;video&#x60; - Video * &#x60;video_playlist&#x60; - Video Playlist
     * @param {VideosListSortbyEnum} [sortby] Sort By  * &#x60;id&#x60; - Object ID ascending * &#x60;-id&#x60; - Object ID descending * &#x60;readable_id&#x60; - Readable ID ascending * &#x60;-readable_id&#x60; - Readable ID descending * &#x60;last_modified&#x60; - Last Modified Date ascending * &#x60;-last_modified&#x60; - Last Modified Date descending * &#x60;new&#x60; - Newest resources first * &#x60;start_date&#x60; - Start Date ascending * &#x60;-start_date&#x60; - Start Date descending * &#x60;mitcoursenumber&#x60; - MIT course number ascending * &#x60;-mitcoursenumber&#x60; - MIT course number descending * &#x60;views&#x60; - Popularity ascending * &#x60;-views&#x60; - Popularity descending * &#x60;upcoming&#x60; - Next start date ascending
     * @param {Array<string>} [topic] Multiple values may be separated by commas.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    videosList: async (
      certification?: boolean,
      certification_type?: Array<VideosListCertificationTypeEnum>,
      course_feature?: Array<string>,
      delivery?: Array<Array<VideosListDeliveryEnum>>,
      department?: Array<VideosListDepartmentEnum>,
      free?: boolean,
      level?: Array<VideosListLevelEnum>,
      limit?: number,
      offered_by?: Array<VideosListOfferedByEnum>,
      offset?: number,
      platform?: Array<VideosListPlatformEnum>,
      professional?: boolean,
      readable_id?: Array<string>,
      resource_category?: Array<VideosListResourceCategoryEnum>,
      resource_type?: Array<VideosListResourceTypeEnum>,
      sortby?: VideosListSortbyEnum,
      topic?: Array<string>,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/api/v1/videos/`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      if (certification !== undefined) {
        localVarQueryParameter["certification"] = certification
      }

      if (certification_type) {
        localVarQueryParameter["certification_type"] = certification_type
      }

      if (course_feature) {
        localVarQueryParameter["course_feature"] = course_feature.join(
          COLLECTION_FORMATS.csv,
        )
      }

      if (delivery) {
        localVarQueryParameter["delivery"] = delivery
      }

      if (department) {
        localVarQueryParameter["department"] = department
      }

      if (free !== undefined) {
        localVarQueryParameter["free"] = free
      }

      if (level) {
        localVarQueryParameter["level"] = level
      }

      if (limit !== undefined) {
        localVarQueryParameter["limit"] = limit
      }

      if (offered_by) {
        localVarQueryParameter["offered_by"] = offered_by
      }

      if (offset !== undefined) {
        localVarQueryParameter["offset"] = offset
      }

      if (platform) {
        localVarQueryParameter["platform"] = platform
      }

      if (professional !== undefined) {
        localVarQueryParameter["professional"] = professional
      }

      if (readable_id) {
        localVarQueryParameter["readable_id"] = readable_id.join(
          COLLECTION_FORMATS.csv,
        )
      }

      if (resource_category) {
        localVarQueryParameter["resource_category"] = resource_category
      }

      if (resource_type) {
        localVarQueryParameter["resource_type"] = resource_type
      }

      if (sortby !== undefined) {
        localVarQueryParameter["sortby"] = sortby
      }

      if (topic) {
        localVarQueryParameter["topic"] = topic.join(COLLECTION_FORMATS.csv)
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     * Retrieve a single video
     * @summary Retrieve
     * @param {number} id A unique integer value identifying this learning resource.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    videosRetrieve: async (
      id: number,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists("videosRetrieve", "id", id)
      const localVarPath = `/api/v1/videos/{id}/`.replace(
        `{${"id"}}`,
        encodeURIComponent(String(id)),
      )
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
  }
}

/**
 * VideosApi - functional programming interface
 * @export
 */
export const VideosApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = VideosApiAxiosParamCreator(configuration)
  return {
    /**
     * Get a paginated list of videos
     * @summary List
     * @param {boolean} [certification]
     * @param {Array<VideosListCertificationTypeEnum>} [certification_type] The type of certification offered  * &#x60;micromasters&#x60; - MicroMasters Credential * &#x60;professional&#x60; - Professional Certificate * &#x60;completion&#x60; - Certificate of Completion * &#x60;none&#x60; - No Certificate
     * @param {Array<string>} [course_feature] Multiple values may be separated by commas.
     * @param {Array<Array<VideosListDeliveryEnum>>} [delivery] The delivery of course/program resources  * &#x60;online&#x60; - Online * &#x60;hybrid&#x60; - Hybrid * &#x60;in_person&#x60; - In person * &#x60;offline&#x60; - Offline
     * @param {Array<VideosListDepartmentEnum>} [department] The department that offers learning resources  * &#x60;1&#x60; - Civil and Environmental Engineering * &#x60;2&#x60; - Mechanical Engineering * &#x60;3&#x60; - Materials Science and Engineering * &#x60;4&#x60; - Architecture * &#x60;5&#x60; - Chemistry * &#x60;6&#x60; - Electrical Engineering and Computer Science * &#x60;7&#x60; - Biology * &#x60;8&#x60; - Physics * &#x60;9&#x60; - Brain and Cognitive Sciences * &#x60;10&#x60; - Chemical Engineering * &#x60;11&#x60; - Urban Studies and Planning * &#x60;12&#x60; - Earth, Atmospheric, and Planetary Sciences * &#x60;14&#x60; - Economics * &#x60;15&#x60; - Management * &#x60;16&#x60; - Aeronautics and Astronautics * &#x60;17&#x60; - Political Science * &#x60;18&#x60; - Mathematics * &#x60;20&#x60; - Biological Engineering * &#x60;21A&#x60; - Anthropology * &#x60;21G&#x60; - Global Languages * &#x60;21H&#x60; - History * &#x60;21L&#x60; - Literature * &#x60;21M&#x60; - Music and Theater Arts * &#x60;22&#x60; - Nuclear Science and Engineering * &#x60;24&#x60; - Linguistics and Philosophy * &#x60;CC&#x60; - Concourse * &#x60;CMS-W&#x60; - Comparative Media Studies/Writing * &#x60;EC&#x60; - Edgerton Center * &#x60;ES&#x60; - Experimental Study Group * &#x60;ESD&#x60; - Engineering Systems Division * &#x60;HST&#x60; - Medical Engineering and Science * &#x60;IDS&#x60; - Data, Systems, and Society * &#x60;MAS&#x60; - Media Arts and Sciences * &#x60;PE&#x60; - Athletics, Physical Education and Recreation * &#x60;SP&#x60; - Special Programs * &#x60;STS&#x60; - Science, Technology, and Society * &#x60;WGS&#x60; - Women\&#39;s and Gender Studies
     * @param {boolean} [free] The course/program is offered for free
     * @param {Array<VideosListLevelEnum>} [level] The academic level of the resources  * &#x60;undergraduate&#x60; - Undergraduate * &#x60;graduate&#x60; - Graduate * &#x60;high_school&#x60; - High School * &#x60;noncredit&#x60; - Non-Credit * &#x60;advanced&#x60; - Advanced * &#x60;intermediate&#x60; - Intermediate * &#x60;introductory&#x60; - Introductory
     * @param {number} [limit] Number of results to return per page.
     * @param {Array<VideosListOfferedByEnum>} [offered_by] The organization that offers a learning resource  * &#x60;mitx&#x60; - MITx * &#x60;ocw&#x60; - MIT OpenCourseWare * &#x60;bootcamps&#x60; - Bootcamps * &#x60;xpro&#x60; - MIT xPRO * &#x60;mitpe&#x60; - MIT Professional Education * &#x60;see&#x60; - MIT Sloan Executive Education
     * @param {number} [offset] The initial index from which to return the results.
     * @param {Array<VideosListPlatformEnum>} [platform] The platform on which learning resources are offered  * &#x60;edx&#x60; - edX * &#x60;ocw&#x60; - MIT OpenCourseWare * &#x60;oll&#x60; - Open Learning Library * &#x60;mitxonline&#x60; - MITx Online * &#x60;bootcamps&#x60; - Bootcamps * &#x60;xpro&#x60; - MIT xPRO * &#x60;csail&#x60; - CSAIL * &#x60;mitpe&#x60; - MIT Professional Education * &#x60;see&#x60; - MIT Sloan Executive Education * &#x60;scc&#x60; - Schwarzman College of Computing * &#x60;ctl&#x60; - Center for Transportation &amp; Logistics * &#x60;whu&#x60; - WHU * &#x60;susskind&#x60; - Susskind * &#x60;globalalumni&#x60; - Global Alumni * &#x60;simplilearn&#x60; - Simplilearn * &#x60;emeritus&#x60; - Emeritus * &#x60;podcast&#x60; - Podcast * &#x60;youtube&#x60; - YouTube
     * @param {boolean} [professional]
     * @param {Array<string>} [readable_id] Multiple values may be separated by commas.
     * @param {Array<VideosListResourceCategoryEnum>} [resource_category] The resource category of the learning resources  * &#x60;course&#x60; - Course * &#x60;program&#x60; - Program * &#x60;learning_material&#x60; - Learning Material
     * @param {Array<VideosListResourceTypeEnum>} [resource_type] The type of learning resource  * &#x60;course&#x60; - Course * &#x60;program&#x60; - Program * &#x60;learning_path&#x60; - Learning Path * &#x60;podcast&#x60; - Podcast * &#x60;podcast_episode&#x60; - Podcast Episode * &#x60;video&#x60; - Video * &#x60;video_playlist&#x60; - Video Playlist
     * @param {VideosListSortbyEnum} [sortby] Sort By  * &#x60;id&#x60; - Object ID ascending * &#x60;-id&#x60; - Object ID descending * &#x60;readable_id&#x60; - Readable ID ascending * &#x60;-readable_id&#x60; - Readable ID descending * &#x60;last_modified&#x60; - Last Modified Date ascending * &#x60;-last_modified&#x60; - Last Modified Date descending * &#x60;new&#x60; - Newest resources first * &#x60;start_date&#x60; - Start Date ascending * &#x60;-start_date&#x60; - Start Date descending * &#x60;mitcoursenumber&#x60; - MIT course number ascending * &#x60;-mitcoursenumber&#x60; - MIT course number descending * &#x60;views&#x60; - Popularity ascending * &#x60;-views&#x60; - Popularity descending * &#x60;upcoming&#x60; - Next start date ascending
     * @param {Array<string>} [topic] Multiple values may be separated by commas.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async videosList(
      certification?: boolean,
      certification_type?: Array<VideosListCertificationTypeEnum>,
      course_feature?: Array<string>,
      delivery?: Array<Array<VideosListDeliveryEnum>>,
      department?: Array<VideosListDepartmentEnum>,
      free?: boolean,
      level?: Array<VideosListLevelEnum>,
      limit?: number,
      offered_by?: Array<VideosListOfferedByEnum>,
      offset?: number,
      platform?: Array<VideosListPlatformEnum>,
      professional?: boolean,
      readable_id?: Array<string>,
      resource_category?: Array<VideosListResourceCategoryEnum>,
      resource_type?: Array<VideosListResourceTypeEnum>,
      sortby?: VideosListSortbyEnum,
      topic?: Array<string>,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<PaginatedVideoResourceList>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.videosList(
        certification,
        certification_type,
        course_feature,
        delivery,
        department,
        free,
        level,
        limit,
        offered_by,
        offset,
        platform,
        professional,
        readable_id,
        resource_category,
        resource_type,
        sortby,
        topic,
        options,
      )
      const index = configuration?.serverIndex ?? 0
      const operationBasePath =
        operationServerMap["VideosApi.videosList"]?.[index]?.url
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, operationBasePath || basePath)
    },
    /**
     * Retrieve a single video
     * @summary Retrieve
     * @param {number} id A unique integer value identifying this learning resource.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async videosRetrieve(
      id: number,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<VideoResource>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.videosRetrieve(
        id,
        options,
      )
      const index = configuration?.serverIndex ?? 0
      const operationBasePath =
        operationServerMap["VideosApi.videosRetrieve"]?.[index]?.url
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, operationBasePath || basePath)
    },
  }
}

/**
 * VideosApi - factory interface
 * @export
 */
export const VideosApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = VideosApiFp(configuration)
  return {
    /**
     * Get a paginated list of videos
     * @summary List
     * @param {VideosApiVideosListRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    videosList(
      requestParameters: VideosApiVideosListRequest = {},
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<PaginatedVideoResourceList> {
      return localVarFp
        .videosList(
          requestParameters.certification,
          requestParameters.certification_type,
          requestParameters.course_feature,
          requestParameters.delivery,
          requestParameters.department,
          requestParameters.free,
          requestParameters.level,
          requestParameters.limit,
          requestParameters.offered_by,
          requestParameters.offset,
          requestParameters.platform,
          requestParameters.professional,
          requestParameters.readable_id,
          requestParameters.resource_category,
          requestParameters.resource_type,
          requestParameters.sortby,
          requestParameters.topic,
          options,
        )
        .then((request) => request(axios, basePath))
    },
    /**
     * Retrieve a single video
     * @summary Retrieve
     * @param {VideosApiVideosRetrieveRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    videosRetrieve(
      requestParameters: VideosApiVideosRetrieveRequest,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<VideoResource> {
      return localVarFp
        .videosRetrieve(requestParameters.id, options)
        .then((request) => request(axios, basePath))
    },
  }
}

/**
 * Request parameters for videosList operation in VideosApi.
 * @export
 * @interface VideosApiVideosListRequest
 */
export interface VideosApiVideosListRequest {
  /**
   *
   * @type {boolean}
   * @memberof VideosApiVideosList
   */
  readonly certification?: boolean

  /**
   * The type of certification offered  * &#x60;micromasters&#x60; - MicroMasters Credential * &#x60;professional&#x60; - Professional Certificate * &#x60;completion&#x60; - Certificate of Completion * &#x60;none&#x60; - No Certificate
   * @type {Array<'completion' | 'micromasters' | 'none' | 'professional'>}
   * @memberof VideosApiVideosList
   */
  readonly certification_type?: Array<VideosListCertificationTypeEnum>

  /**
   * Multiple values may be separated by commas.
   * @type {Array<string>}
   * @memberof VideosApiVideosList
   */
  readonly course_feature?: Array<string>

  /**
   * The delivery of course/program resources  * &#x60;online&#x60; - Online * &#x60;hybrid&#x60; - Hybrid * &#x60;in_person&#x60; - In person * &#x60;offline&#x60; - Offline
   * @type {Array<Array<'online' | 'hybrid' | 'in_person' | 'offline'>>}
   * @memberof VideosApiVideosList
   */
  readonly delivery?: Array<Array<VideosListDeliveryEnum>>

  /**
   * The department that offers learning resources  * &#x60;1&#x60; - Civil and Environmental Engineering * &#x60;2&#x60; - Mechanical Engineering * &#x60;3&#x60; - Materials Science and Engineering * &#x60;4&#x60; - Architecture * &#x60;5&#x60; - Chemistry * &#x60;6&#x60; - Electrical Engineering and Computer Science * &#x60;7&#x60; - Biology * &#x60;8&#x60; - Physics * &#x60;9&#x60; - Brain and Cognitive Sciences * &#x60;10&#x60; - Chemical Engineering * &#x60;11&#x60; - Urban Studies and Planning * &#x60;12&#x60; - Earth, Atmospheric, and Planetary Sciences * &#x60;14&#x60; - Economics * &#x60;15&#x60; - Management * &#x60;16&#x60; - Aeronautics and Astronautics * &#x60;17&#x60; - Political Science * &#x60;18&#x60; - Mathematics * &#x60;20&#x60; - Biological Engineering * &#x60;21A&#x60; - Anthropology * &#x60;21G&#x60; - Global Languages * &#x60;21H&#x60; - History * &#x60;21L&#x60; - Literature * &#x60;21M&#x60; - Music and Theater Arts * &#x60;22&#x60; - Nuclear Science and Engineering * &#x60;24&#x60; - Linguistics and Philosophy * &#x60;CC&#x60; - Concourse * &#x60;CMS-W&#x60; - Comparative Media Studies/Writing * &#x60;EC&#x60; - Edgerton Center * &#x60;ES&#x60; - Experimental Study Group * &#x60;ESD&#x60; - Engineering Systems Division * &#x60;HST&#x60; - Medical Engineering and Science * &#x60;IDS&#x60; - Data, Systems, and Society * &#x60;MAS&#x60; - Media Arts and Sciences * &#x60;PE&#x60; - Athletics, Physical Education and Recreation * &#x60;SP&#x60; - Special Programs * &#x60;STS&#x60; - Science, Technology, and Society * &#x60;WGS&#x60; - Women\&#39;s and Gender Studies
   * @type {Array<'1' | '10' | '11' | '12' | '14' | '15' | '16' | '17' | '18' | '2' | '20' | '21A' | '21G' | '21H' | '21L' | '21M' | '22' | '24' | '3' | '4' | '5' | '6' | '7' | '8' | '9' | 'CC' | 'CMS-W' | 'EC' | 'ES' | 'ESD' | 'HST' | 'IDS' | 'MAS' | 'PE' | 'SP' | 'STS' | 'WGS'>}
   * @memberof VideosApiVideosList
   */
  readonly department?: Array<VideosListDepartmentEnum>

  /**
   * The course/program is offered for free
   * @type {boolean}
   * @memberof VideosApiVideosList
   */
  readonly free?: boolean

  /**
   * The academic level of the resources  * &#x60;undergraduate&#x60; - Undergraduate * &#x60;graduate&#x60; - Graduate * &#x60;high_school&#x60; - High School * &#x60;noncredit&#x60; - Non-Credit * &#x60;advanced&#x60; - Advanced * &#x60;intermediate&#x60; - Intermediate * &#x60;introductory&#x60; - Introductory
   * @type {Array<'advanced' | 'graduate' | 'high_school' | 'intermediate' | 'introductory' | 'noncredit' | 'undergraduate'>}
   * @memberof VideosApiVideosList
   */
  readonly level?: Array<VideosListLevelEnum>

  /**
   * Number of results to return per page.
   * @type {number}
   * @memberof VideosApiVideosList
   */
  readonly limit?: number

  /**
   * The organization that offers a learning resource  * &#x60;mitx&#x60; - MITx * &#x60;ocw&#x60; - MIT OpenCourseWare * &#x60;bootcamps&#x60; - Bootcamps * &#x60;xpro&#x60; - MIT xPRO * &#x60;mitpe&#x60; - MIT Professional Education * &#x60;see&#x60; - MIT Sloan Executive Education
   * @type {Array<'bootcamps' | 'mitpe' | 'mitx' | 'ocw' | 'see' | 'xpro'>}
   * @memberof VideosApiVideosList
   */
  readonly offered_by?: Array<VideosListOfferedByEnum>

  /**
   * The initial index from which to return the results.
   * @type {number}
   * @memberof VideosApiVideosList
   */
  readonly offset?: number

  /**
   * The platform on which learning resources are offered  * &#x60;edx&#x60; - edX * &#x60;ocw&#x60; - MIT OpenCourseWare * &#x60;oll&#x60; - Open Learning Library * &#x60;mitxonline&#x60; - MITx Online * &#x60;bootcamps&#x60; - Bootcamps * &#x60;xpro&#x60; - MIT xPRO * &#x60;csail&#x60; - CSAIL * &#x60;mitpe&#x60; - MIT Professional Education * &#x60;see&#x60; - MIT Sloan Executive Education * &#x60;scc&#x60; - Schwarzman College of Computing * &#x60;ctl&#x60; - Center for Transportation &amp; Logistics * &#x60;whu&#x60; - WHU * &#x60;susskind&#x60; - Susskind * &#x60;globalalumni&#x60; - Global Alumni * &#x60;simplilearn&#x60; - Simplilearn * &#x60;emeritus&#x60; - Emeritus * &#x60;podcast&#x60; - Podcast * &#x60;youtube&#x60; - YouTube
   * @type {Array<'bootcamps' | 'csail' | 'ctl' | 'edx' | 'emeritus' | 'globalalumni' | 'mitpe' | 'mitxonline' | 'ocw' | 'oll' | 'podcast' | 'scc' | 'see' | 'simplilearn' | 'susskind' | 'whu' | 'xpro' | 'youtube'>}
   * @memberof VideosApiVideosList
   */
  readonly platform?: Array<VideosListPlatformEnum>

  /**
   *
   * @type {boolean}
   * @memberof VideosApiVideosList
   */
  readonly professional?: boolean

  /**
   * Multiple values may be separated by commas.
   * @type {Array<string>}
   * @memberof VideosApiVideosList
   */
  readonly readable_id?: Array<string>

  /**
   * The resource category of the learning resources  * &#x60;course&#x60; - Course * &#x60;program&#x60; - Program * &#x60;learning_material&#x60; - Learning Material
   * @type {Array<'course' | 'learning_material' | 'program'>}
   * @memberof VideosApiVideosList
   */
  readonly resource_category?: Array<VideosListResourceCategoryEnum>

  /**
   * The type of learning resource  * &#x60;course&#x60; - Course * &#x60;program&#x60; - Program * &#x60;learning_path&#x60; - Learning Path * &#x60;podcast&#x60; - Podcast * &#x60;podcast_episode&#x60; - Podcast Episode * &#x60;video&#x60; - Video * &#x60;video_playlist&#x60; - Video Playlist
   * @type {Array<'course' | 'learning_path' | 'podcast' | 'podcast_episode' | 'program' | 'video' | 'video_playlist'>}
   * @memberof VideosApiVideosList
   */
  readonly resource_type?: Array<VideosListResourceTypeEnum>

  /**
   * Sort By  * &#x60;id&#x60; - Object ID ascending * &#x60;-id&#x60; - Object ID descending * &#x60;readable_id&#x60; - Readable ID ascending * &#x60;-readable_id&#x60; - Readable ID descending * &#x60;last_modified&#x60; - Last Modified Date ascending * &#x60;-last_modified&#x60; - Last Modified Date descending * &#x60;new&#x60; - Newest resources first * &#x60;start_date&#x60; - Start Date ascending * &#x60;-start_date&#x60; - Start Date descending * &#x60;mitcoursenumber&#x60; - MIT course number ascending * &#x60;-mitcoursenumber&#x60; - MIT course number descending * &#x60;views&#x60; - Popularity ascending * &#x60;-views&#x60; - Popularity descending * &#x60;upcoming&#x60; - Next start date ascending
   * @type {'-id' | '-last_modified' | '-mitcoursenumber' | '-readable_id' | '-start_date' | '-views' | 'id' | 'last_modified' | 'mitcoursenumber' | 'new' | 'readable_id' | 'start_date' | 'upcoming' | 'views'}
   * @memberof VideosApiVideosList
   */
  readonly sortby?: VideosListSortbyEnum

  /**
   * Multiple values may be separated by commas.
   * @type {Array<string>}
   * @memberof VideosApiVideosList
   */
  readonly topic?: Array<string>
}

/**
 * Request parameters for videosRetrieve operation in VideosApi.
 * @export
 * @interface VideosApiVideosRetrieveRequest
 */
export interface VideosApiVideosRetrieveRequest {
  /**
   * A unique integer value identifying this learning resource.
   * @type {number}
   * @memberof VideosApiVideosRetrieve
   */
  readonly id: number
}

/**
 * VideosApi - object-oriented interface
 * @export
 * @class VideosApi
 * @extends {BaseAPI}
 */
export class VideosApi extends BaseAPI {
  /**
   * Get a paginated list of videos
   * @summary List
   * @param {VideosApiVideosListRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof VideosApi
   */
  public videosList(
    requestParameters: VideosApiVideosListRequest = {},
    options?: RawAxiosRequestConfig,
  ) {
    return VideosApiFp(this.configuration)
      .videosList(
        requestParameters.certification,
        requestParameters.certification_type,
        requestParameters.course_feature,
        requestParameters.delivery,
        requestParameters.department,
        requestParameters.free,
        requestParameters.level,
        requestParameters.limit,
        requestParameters.offered_by,
        requestParameters.offset,
        requestParameters.platform,
        requestParameters.professional,
        requestParameters.readable_id,
        requestParameters.resource_category,
        requestParameters.resource_type,
        requestParameters.sortby,
        requestParameters.topic,
        options,
      )
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   * Retrieve a single video
   * @summary Retrieve
   * @param {VideosApiVideosRetrieveRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof VideosApi
   */
  public videosRetrieve(
    requestParameters: VideosApiVideosRetrieveRequest,
    options?: RawAxiosRequestConfig,
  ) {
    return VideosApiFp(this.configuration)
      .videosRetrieve(requestParameters.id, options)
      .then((request) => request(this.axios, this.basePath))
  }
}

/**
 * @export
 */
export const VideosListCertificationTypeEnum = {
  Completion: "completion",
  Micromasters: "micromasters",
  None: "none",
  Professional: "professional",
} as const
export type VideosListCertificationTypeEnum =
  (typeof VideosListCertificationTypeEnum)[keyof typeof VideosListCertificationTypeEnum]
/**
 * @export
 */
export const VideosListDeliveryEnum = {
  Online: "online",
  Hybrid: "hybrid",
  InPerson: "in_person",
  Offline: "offline",
} as const
export type VideosListDeliveryEnum =
  (typeof VideosListDeliveryEnum)[keyof typeof VideosListDeliveryEnum]
/**
 * @export
 */
export const VideosListDepartmentEnum = {
  _1: "1",
  _10: "10",
  _11: "11",
  _12: "12",
  _14: "14",
  _15: "15",
  _16: "16",
  _17: "17",
  _18: "18",
  _2: "2",
  _20: "20",
  _21A: "21A",
  _21G: "21G",
  _21H: "21H",
  _21L: "21L",
  _21M: "21M",
  _22: "22",
  _24: "24",
  _3: "3",
  _4: "4",
  _5: "5",
  _6: "6",
  _7: "7",
  _8: "8",
  _9: "9",
  Cc: "CC",
  CmsW: "CMS-W",
  Ec: "EC",
  Es: "ES",
  Esd: "ESD",
  Hst: "HST",
  Ids: "IDS",
  Mas: "MAS",
  Pe: "PE",
  Sp: "SP",
  Sts: "STS",
  Wgs: "WGS",
} as const
export type VideosListDepartmentEnum =
  (typeof VideosListDepartmentEnum)[keyof typeof VideosListDepartmentEnum]
/**
 * @export
 */
export const VideosListLevelEnum = {
  Advanced: "advanced",
  Graduate: "graduate",
  HighSchool: "high_school",
  Intermediate: "intermediate",
  Introductory: "introductory",
  Noncredit: "noncredit",
  Undergraduate: "undergraduate",
} as const
export type VideosListLevelEnum =
  (typeof VideosListLevelEnum)[keyof typeof VideosListLevelEnum]
/**
 * @export
 */
export const VideosListOfferedByEnum = {
  Bootcamps: "bootcamps",
  Mitpe: "mitpe",
  Mitx: "mitx",
  Ocw: "ocw",
  See: "see",
  Xpro: "xpro",
} as const
export type VideosListOfferedByEnum =
  (typeof VideosListOfferedByEnum)[keyof typeof VideosListOfferedByEnum]
/**
 * @export
 */
export const VideosListPlatformEnum = {
  Bootcamps: "bootcamps",
  Csail: "csail",
  Ctl: "ctl",
  Edx: "edx",
  Emeritus: "emeritus",
  Globalalumni: "globalalumni",
  Mitpe: "mitpe",
  Mitxonline: "mitxonline",
  Ocw: "ocw",
  Oll: "oll",
  Podcast: "podcast",
  Scc: "scc",
  See: "see",
  Simplilearn: "simplilearn",
  Susskind: "susskind",
  Whu: "whu",
  Xpro: "xpro",
  Youtube: "youtube",
} as const
export type VideosListPlatformEnum =
  (typeof VideosListPlatformEnum)[keyof typeof VideosListPlatformEnum]
/**
 * @export
 */
export const VideosListResourceCategoryEnum = {
  Course: "course",
  LearningMaterial: "learning_material",
  Program: "program",
} as const
export type VideosListResourceCategoryEnum =
  (typeof VideosListResourceCategoryEnum)[keyof typeof VideosListResourceCategoryEnum]
/**
 * @export
 */
export const VideosListResourceTypeEnum = {
  Course: "course",
  LearningPath: "learning_path",
  Podcast: "podcast",
  PodcastEpisode: "podcast_episode",
  Program: "program",
  Video: "video",
  VideoPlaylist: "video_playlist",
} as const
export type VideosListResourceTypeEnum =
  (typeof VideosListResourceTypeEnum)[keyof typeof VideosListResourceTypeEnum]
/**
 * @export
 */
export const VideosListSortbyEnum = {
  Id: "-id",
  LastModified: "-last_modified",
  Mitcoursenumber: "-mitcoursenumber",
  ReadableId: "-readable_id",
  StartDate: "-start_date",
  Views: "-views",
  Id2: "id",
  LastModified2: "last_modified",
  Mitcoursenumber2: "mitcoursenumber",
  New: "new",
  ReadableId2: "readable_id",
  StartDate2: "start_date",
  Upcoming: "upcoming",
  Views2: "views",
} as const
export type VideosListSortbyEnum =
  (typeof VideosListSortbyEnum)[keyof typeof VideosListSortbyEnum]
